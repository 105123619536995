import { __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { thirdPartyCaseActions, thirdPartyCaseConstants as events, } from 'store/thirdpartyCase/thirdpartycase.actions';
import { sendApi } from '../sagas';
function thirdpartyCaseWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.listPage.request, sendApi(api.thirdpartyCase.list, thirdPartyCaseActions.listPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.detail.request, sendApi(api.thirdpartyCase.detail, thirdPartyCaseActions.detailRes))];
            case 2: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 3:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(thirdpartyCaseWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
