import { __read } from "tslib";
import { useIgnoreDuplicates } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './DuplicatesClientListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DuplicatesClientListItem(_a) {
    var item = _a.item, action = _a.action, refetch = _a.refetch;
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), hover = _b[0], setHover = _b[1];
    var history = useHistory();
    var id = item.id, checked = item.checked, debtors = item.debtors;
    var goDetail = function () {
        history.push("/client-to-merge/".concat(id));
    };
    var ignoreDuplicates = useIgnoreDuplicates().mutate;
    var ignore = function (e) {
        e.stopPropagation();
        ignoreDuplicates({
            id: id,
        }, {
            onSuccess: function () {
                refetch();
            },
        });
    };
    return (React.createElement("tr", { className: styles('client-list-item', hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, onClick: goDetail },
        React.createElement("td", null, action && (React.createElement("div", { onClick: action, className: styles('box', 'checkbox', checked && 'checked') }))),
        debtors.map(function (_a) {
            var debtorId = _a.id, _b = _a.attributes, full_name = _b.full_name, emails = _b.emails;
            return (React.createElement("td", { key: debtorId },
                full_name,
                React.createElement("br", null),
                React.createElement("span", { className: styles('email') }, emails[0])));
        }),
        React.createElement("td", { className: styles('Actions') },
            React.createElement(Button, { onClick: ignore, color: ButtonColor.GREY, className: styles('delete-imported'), label: t(i18nKeys.CLIENT.MERGE.IGNORE), noMargin: true, small: true }))));
}
