import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export function archiveReportFn(axiosInstance, id) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    data = instance.put("/reports/export_tasks/".concat(id, "/archive"));
                    return [2 /*return*/, data];
            }
        });
    });
}
export function useArchiveReport() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (id) { return archiveReportFn(axiosInstance, id); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['reports']);
        },
    });
    return addActionNameToMutationResult('archiveReport', mutation);
}
