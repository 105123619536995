import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import styleIdentifiers from './invoiceJournal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceJournal(_a) {
    var formArray = _a.formArray;
    var t = useTranslation().t;
    var _b = useFormContext(), register = _b.register, watch = _b.watch, errors = _b.formState.errors, reset = _b.reset;
    var billing_logs_attributes = watch().billing_logs_attributes;
    var removeLine = function (index) { return function () {
        var lines = billing_logs_attributes;
        lines.splice(index, 1);
        formArray.remove(index);
        // reset(
        //     {
        //         billing_logs_attributes: lines,
        //     },
        //     {
        //         isDirty: true,
        //     }
        // );
    }; };
    return (React.createElement("div", { className: styles('InvoiceJournal') }, formArray.fields.map(function (item, index) {
        var _a;
        var line = billing_logs_attributes && billing_logs_attributes[index];
        var prefix = line === null || line === void 0 ? void 0 : line.prefix;
        var counter = line === null || line === void 0 ? void 0 : line.counter;
        counter = counter ? +counter : 0;
        counter += 1;
        var next;
        if (prefix)
            next = "".concat(prefix, " - ").concat(counter);
        return (React.createElement("div", { className: styles('line', 'grid-row'), key: item.rhfId },
            React.createElement("div", { className: styles('col-2') },
                React.createElement(Input, { errorMessage: ((_a = errors.billing_logs_attributes) === null || _a === void 0 ? void 0 : _a[index]) &&
                        errors.billing_logs_attributes[index].prefix, register: register("billing_logs_attributes.".concat(index, ".prefix"), {
                        required: true,
                    }), defaultValue: line === null || line === void 0 ? void 0 : line.prefix, className: styles('input'), noMargin: true, label: t(i18nKeys.FORM.INVOICING.PREFIX), type: "text" })),
            React.createElement("input", __assign({}, register("billing_logs_attributes.".concat(index, ".id")), { defaultValue: line === null || line === void 0 ? void 0 : line.id, className: "invisible-input" })),
            React.createElement("div", { className: styles('col-3') },
                React.createElement(Input, { register: register("billing_logs_attributes.".concat(index, ".counter")), className: styles('input'), noMargin: true, defaultValue: line === null || line === void 0 ? void 0 : line.counter, type: "number", 
                    // min={counter}
                    label: t(i18nKeys.FORM.INVOICING.CURRENT_COUNTER) })),
            React.createElement("div", { className: styles('col-5', 'next') },
                React.createElement("div", { className: styles('label') }, t(i18nKeys.FORM.INVOICING.NEXT_INVOICE_REFRENCE)),
                React.createElement("div", { className: styles('value-wrapper') },
                    React.createElement("span", null, next)),
                React.createElement("div", { className: styles('remove'), onClick: removeLine(index) },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "16px" })))));
    })));
}
