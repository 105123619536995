import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useContext } from 'react';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import Percentage from 'shared/components/Percentage';
import InvoiceDetailContext from '../InvoiceDetail.context';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceDebtorSituation() {
    var _a, _b;
    var t = useTranslation().t;
    var _c = useContext(InvoiceDetailContext), data = _c.data, clientData = _c.clientData;
    var terms_and_conditions = data.terms_and_conditions, currency = data.currency;
    return (React.createElement(Card, { className: styles('box'), title: t(i18nKeys.INVOICE.CLIENT_SUMMARY) },
        React.createElement("div", { className: styles('item') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.AVERAGE_AUTHORIZED_DELAY)),
            React.createElement("div", { className: styles('value', 'bigger', 'blue') }, "".concat((clientData === null || clientData === void 0 ? void 0 : clientData.average_payment_delay) || '-', " ").concat(t(i18nKeys.DAY), " / ").concat((terms_and_conditions === null || terms_and_conditions === void 0 ? void 0 : terms_and_conditions.minimum_payment_terms_delay) || '-', " ").concat(t(i18nKeys.DAY)))),
        React.createElement("div", { className: styles('item') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.DETAIL.LATE_PAYMENT_RATE)),
            React.createElement(Percentage, { value: (clientData === null || clientData === void 0 ? void 0 : clientData.delay_rate) || 0, className: styles('value', 'bigger', 'blue') })),
        React.createElement("div", { className: styles('item') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.REVENUES)),
            React.createElement(Amount, { value: (_a = clientData === null || clientData === void 0 ? void 0 : clientData.financial_data) === null || _a === void 0 ? void 0 : _a.annual_ca, suffix: currency, className: styles('value', 'blue', 'bigger') })),
        React.createElement("div", { className: styles('item') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.AMOUNT_DUE)),
            React.createElement(Amount, { value: (_b = clientData === null || clientData === void 0 ? void 0 : clientData.financial_data) === null || _b === void 0 ? void 0 : _b.overdue_amount, suffix: currency, className: styles('value', 'bigger') }))));
}
