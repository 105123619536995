import { __makeTemplateObject, __read, __spreadArray } from "tslib";
import React from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router';
import { chunk, isEmpty, partition } from 'remeda';
import { useArchiveReport, useLoadLightUsers } from 'shared/hooks';
import { useLoadReport } from 'shared/hooks/use-load-report';
import { PageTitle } from 'shared/layout';
import { css } from '@emotion/css';
import { ActionIcon, Anchor, Box, Button, Card, Center, Divider, Group, List, Pagination, Space, Table, Text, useMantineTheme, } from '@mantine/core';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { IconArrowLeft, IconAt, IconCalendarMonth, IconEdit, IconFileDownload, IconReport, IconRotate, IconTrash, IconUser, } from '@tabler/icons-react';
import { EditReportNameModal, EditReportRecipientsModal, EditReportRecurrenceModal, } from './components';
var DAY_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
var styles = {
    listIconFix: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .mantine-List-itemWrapper {\n      align-items: normal;\n    }\n  "], ["\n    .mantine-List-itemWrapper {\n      align-items: normal;\n    }\n  "]))),
};
export var Report = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var theme = useMantineTheme();
    var reportId = useParams().reportId;
    var _a = useLoadReport(reportId), report = _a.report, isReportLoading = _a.isReportLoading;
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var _c = useArchiveReport(), archiveReport = _c.archiveReport, isArchiveReportLoading = _c.isArchiveReportLoading;
    var _d = __read(React.useState(false), 2), isEmailListUnfolded = _d[0], setIsEmailListUnfolded = _d[1];
    var _e = __read(React.useState(1), 2), page = _e[0], setPage = _e[1];
    var _f = __read(useDisclosure(false), 2), isEditNameModalOpen = _f[0], editNameModalHandlers = _f[1];
    var _g = __read(useDisclosure(false), 2), isEditRecurrenceModalOpen = _g[0], editRecurrenceModalHandlers = _g[1];
    var _h = __read(useDisclosure(false), 2), isEditRecipientsModalOpen = _h[0], editRecipientsModalHandlers = _h[1];
    if (isReportLoading) {
        return React.createElement("div", null, "Loading...");
    }
    if (report == null) {
        console.error('Report empty even though loading is done');
        return null;
    }
    var handleDelete = function () {
        modals.openConfirmModal({
            centered: true,
            title: t(i18nKeys.REPORTS.SHOW.DELETE_REPORT),
            children: React.createElement(Text, null, t(i18nKeys.REPORTS.SHOW.AYS_DELETE)),
            labels: { cancel: t(i18nKeys.CANCEL), confirm: t(i18nKeys.DELETE) },
            confirmProps: { color: 'red', loading: isArchiveReportLoading },
            onConfirm: function () { return archiveReport(report.id); },
            onCancel: modals.closeAll,
        });
    };
    var days = report.periodicity.type === 'weekly'
        ? report.periodicity.days
            .map(function (day) { return t(i18nKeys.DATES.DAY_NAMES[DAY_OF_WEEK[day - 1].toUpperCase()]); })
            .join(', ')
        : report.periodicity.days
            .map(function (day) {
            var fakeDate = dayjs().month(0).date(day);
            return fakeDate.format('Do');
        })
            .join(', ');
    var userEmails = users.map(function (user) { return user.email; });
    var _j = __read(partition(report.emails, function (email) {
        return userEmails.includes(email);
    }), 2), internalEmails = _j[0], externalEmails = _j[1];
    var sortedEmails = __spreadArray(__spreadArray([], __read(internalEmails), false), __read(externalEmails), false);
    var iconExternalEmail = React.createElement(IconAt, { stroke: 1.25, size: "1rem", color: theme.colors.gray[5] });
    var exportsSorted = __spreadArray([], __read(report.exports), false).reverse();
    var pages = chunk(exportsSorted, 20);
    return (React.createElement(React.Fragment, null,
        React.createElement(EditReportNameModal, { isOpen: isEditNameModalOpen, onClose: editNameModalHandlers.close, report: report }),
        React.createElement(EditReportRecurrenceModal, { isOpen: isEditRecurrenceModalOpen, onClose: editRecurrenceModalHandlers.close, report: report }),
        React.createElement(EditReportRecipientsModal, { isOpen: isEditRecipientsModalOpen, onClose: editRecipientsModalHandlers.close, report: report }),
        React.createElement(PageTitle, null,
            React.createElement(ActionIcon, { onClick: function () { return history.push('/reports'); }, size: "lg", variant: "light" },
                React.createElement(IconArrowLeft, { stroke: 1.5 })),
            report.name,
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { onClick: handleDelete, color: "red", rightSection: React.createElement(IconTrash, { size: 18, stroke: 1.5 }) }, t(i18nKeys.DELETE)))),
        React.createElement(Card, { radius: "md", shadow: "sm" },
            React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", mb: "md" },
                React.createElement(Text, { fw: 500, size: "lg", ff: "Roboto" }, t(i18nKeys.REPORTS.SHOW.DETAILS))),
            React.createElement(Group, { align: "start" },
                React.createElement(Box, { mt: -5, mr: "lg" },
                    React.createElement(IconReport, { size: "120", stroke: 0.5, color: theme.colors.blue[6] })),
                React.createElement(Box, { flex: 1 },
                    React.createElement(Group, { align: "top", justify: "space-between" },
                        React.createElement(Text, { size: "lg", c: "dimmed", fw: 500, mb: "sm" }, t(i18nKeys.REPORTS.SHOW.NAME)),
                        React.createElement(Button, { onClick: editNameModalHandlers.open, size: "xs", color: "blue.4", variant: "light", leftSection: React.createElement(IconEdit, { size: 16, stroke: 1.5 }) }, t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT))),
                    React.createElement(Text, null, report.name)),
                React.createElement(Divider, { orientation: "vertical" }),
                React.createElement(Box, { flex: 1.5 },
                    React.createElement(Group, { align: "top", justify: "space-between" },
                        React.createElement(Text, { size: "lg", c: "dimmed", fw: 500, mb: "sm" }, t(i18nKeys.REPORTS.SHOW.RECURRENCE)),
                        React.createElement(Button, { onClick: editRecurrenceModalHandlers.open, size: "xs", color: "blue.4", variant: "light", leftSection: React.createElement(IconEdit, { size: 16, stroke: 1.5 }) }, t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT))),
                    React.createElement(Group, { mb: "xs", gap: "xs" },
                        React.createElement(IconRotate, { color: theme.colors.gray[5], stroke: 1.7, size: "1.25rem" }),
                        React.createElement(Text, { c: "gray.8" }, t(i18nKeys.REPORTS.RECURRENCE[report.periodicity.type.toUpperCase()]))),
                    React.createElement(Group, { gap: "xs", wrap: "nowrap", align: "start" },
                        React.createElement("div", null,
                            React.createElement(IconCalendarMonth, { color: theme.colors.gray[5], stroke: 1.7, size: 20, style: { marginTop: '2px' } })),
                        React.createElement(Text, { c: "gray.8", span: true }, days))),
                React.createElement(Divider, { orientation: "vertical" }),
                React.createElement(Box, { flex: 1.5 },
                    React.createElement(Group, { align: "top", justify: "space-between" },
                        React.createElement(Text, { size: "lg", c: "dimmed", fw: 500, mb: "sm" }, t(i18nKeys.REPORTS.SHOW.RECIPIENTS)),
                        React.createElement(Button, { onClick: editRecipientsModalHandlers.open, size: "xs", color: "blue.4", variant: "light", leftSection: React.createElement(IconEdit, { size: 16, stroke: 1.5 }) }, t(i18nKeys.REPORTS.INDEX.ACTIONS.EDIT))),
                    React.createElement(List, { className: styles.listIconFix, mb: 4, spacing: 6, c: "gray.8", size: "sm", center: true, icon: React.createElement(IconUser, { size: "1rem", stroke: 1.25, color: theme.colors.gray[5] }) }, sortedEmails.slice(0, isEmailListUnfolded ? Infinity : 4).map(function (email) { return (React.createElement(List.Item, { key: email, icon: externalEmails.includes(email) ? iconExternalEmail : undefined }, email)); })),
                    sortedEmails.length > 4 && (React.createElement(Anchor, { onClick: function () { return setIsEmailListUnfolded(function (state) { return !state; }); }, size: "sm" }, isEmailListUnfolded ? 'See less' : "See ".concat(sortedEmails.length - 4, " more")))))),
        React.createElement(Space, { h: "lg" }),
        React.createElement(Card, { radius: "md", shadow: "sm" },
            React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs" },
                React.createElement(Text, { fw: 500, size: "lg", ff: "Roboto" }, "Exports")),
            React.createElement(Card.Section, { withBorder: !isEmpty(pages) }, isEmpty(pages) ? (React.createElement(Center, { h: 200 },
                React.createElement(Text, null, t(i18nKeys.REPORTS.SHOW.NO_EXPORTS_YET)))) : (React.createElement(Table, { verticalSpacing: "xs", striped: true },
                React.createElement(Table.Tbody, null, pages[page - 1].map(function (_a) {
                    var executionDate = _a.executionDate, file = _a.file;
                    return (React.createElement(Table.Tr, { key: executionDate.toString() },
                        React.createElement(Table.Td, null,
                            React.createElement(Text, null, upperFirst(executionDate.format('dddd MMMM D, YYYY [at] hh:mm')))),
                        React.createElement(Table.Td, { ta: "right" },
                            React.createElement(Button, { component: "a", href: file, size: "xs", variant: "light", rightSection: React.createElement(IconFileDownload, { stroke: 1.5, size: "1.2rem" }) }, t(i18nKeys.DOWNLOAD)))));
                }))))),
            React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", style: { display: isEmpty(report.exports) ? 'none' : 'block' } },
                React.createElement(Group, { w: "100%", justify: "flex-end" },
                    React.createElement(Pagination, { disabled: pages.length === 1, total: pages.length, value: page, onChange: setPage, size: "sm" }))))));
};
var templateObject_1;
