import { __assign } from "tslib";
import { TimelineTypes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { omit } from 'lodash-es';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, CustomSelect, DateSelector } from 'shared/io';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimelineFilter = function (_a) {
    var params = _a.params, setParams = _a.setParams;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: omit(params, ['page', 'page_limit', 'sort_by', 'sort_order']),
    });
    var types = Object.values(TimelineTypes).map(function (type) { return ({
        value: type,
        description: t(i18nKeys.TIMELINE[type.toUpperCase()]),
    }); });
    var activity_categories = useSelector(function (state) { return state.app.constants; }).activity_categories;
    var allParams = omit(params, ['page', 'page_limit', 'sort_by', 'sort_order']);
    var filtersNumber = Object.keys(allParams).filter(function (value) { return value; }).length || 0;
    var resetFilters = function () {
        form.reset({});
        setParams({});
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('timeline-filter-form') },
            React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "start_date", endName: "end_date", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, noMinDate: true, label: t(i18nKeys.DATE), handleChange: function (value) {
                    setParams(__assign(__assign({}, params), { start_date: value }));
                }, handleEndChange: function (value) {
                    setParams(__assign(__assign({}, params), { end_date: value }));
                }, noMargin: true }),
            React.createElement(Controller, { name: "types", render: function () { return (React.createElement(CustomSelect, { className: styles('large'), name: "types", keyText: "description", keyValue: "value", items: types, label: t(i18nKeys.FORM.TYPE), withBorder: true, noMargin: true, multiple: true, onValueChanged: function (value) {
                        setParams(__assign(__assign({}, params), { types: value }));
                    } })); } }),
            React.createElement(Controller, { defaultValue: "", name: "category", render: function () { return (React.createElement(CustomSelect, { className: styles('large'), name: "category", keyText: "description", keyValue: "value", items: activity_categories, label: t(i18nKeys.FORM.CATEGORY), withBorder: true, noMargin: true, multiple: true, onValueChanged: function (value) {
                        setParams(__assign(__assign({}, params), { category: value }));
                    } })); } }),
            filtersNumber > 0 && (React.createElement(Button, { className: styles('remove-filter', 'filter'), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, onClick: resetFilters },
                React.createElement(Icon, { name: IconName.FILTER }),
                React.createElement(Icon, { name: IconName.SMALL_REMOVE }))))));
};
