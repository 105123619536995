import React from 'react';
import classNames from 'classnames/bind';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var LightInvoiceItem = function (_a) {
    var _b = _a.item, reference = _b.reference, total_tvac = _b.total_tvac, remaining_balance_with_fees = _b.remaining_balance_with_fees, company = _a.company, constants = _a.constants, currency = _a.currency;
    return (React.createElement("div", { className: styles('invoice-item') },
        React.createElement("div", { className: styles('reference') }, reference),
        React.createElement("div", { className: styles('comp') },
            total_tvac && (React.createElement("span", null, formatAmount(total_tvac, ',', '.', 2, currencySymbol(currency, company)))),
            total_tvac && remaining_balance_with_fees && ' / ',
            remaining_balance_with_fees && (React.createElement("b", null, formatAmount(-remaining_balance_with_fees, ',', '.', 2, currencySymbol(currency, company)))))));
};
