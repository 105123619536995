import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import { useTranslation } from 'locales/';
import { get } from 'lodash-es';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { validationMessage } from 'shared/utils/validation';
import styleIdentifiers from './DateSelector.scss';
registerLocale('fr', fr);
registerLocale('nl', nl);
registerLocale('en', en);
var styles = classNames.bind(styleIdentifiers);
export var DateSelector = function (_a) {
    var centered = _a.centered, className = _a.className, dateFormat = _a.dateFormat, disabled = _a.disabled, endName = _a.endName, endPlaceholder = _a.endPlaceholder, errorClassName = _a.errorClassName, forcePlacement = _a.forcePlacement, handleChange = _a.handleChange, handleEndChange = _a.handleEndChange, inputClassName = _a.inputClassName, label = _a.label, minDate = _a.minDate, name = _a.name, noBorder = _a.noBorder, noErrorText = _a.noErrorText, noMargin = _a.noMargin, noMinDate = _a.noMinDate, placeholder = _a.placeholder, required = _a.required, shadow = _a.shadow, withBorder = _a.withBorder;
    var _b = useFormContext(), watch = _b.watch, register = _b.register, unregister = _b.unregister, errors = _b.formState.errors;
    var error = get(errors, name);
    useEffect(function () {
        register(name, { required: required });
        endName && register(endName);
        return function () {
            unregister(name);
            endName && unregister(endName);
        };
    }, []);
    var date = watch(name);
    var endDate = endName && watch(endName);
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var onSelectDate = function (value, isEndDate) {
        value = moment(new Date(value)).startOf('day');
        var newDate = moment(new Date(value).getTime() + new Date(value).getTimezoneOffset() * -60000).toISOString();
        if (isEndDate) {
            if (handleEndChange)
                handleEndChange(newDate || null);
        }
        else if (handleChange)
            handleChange(newDate || null);
    };
    return (React.createElement("div", { className: styles('DateSelector', noMargin && 'no-margin', withBorder && 'with-border', shadow && 'shadow', className, error && 'error', centered && 'centered') },
        React.createElement("div", { className: styles('input-wrapper', !noBorder && 'bordered', error && errorClassName) },
            label && React.createElement("div", { className: styles('label-input') }, label),
            React.createElement("div", { className: styles('date-wrapper') },
                React.createElement(DatePicker, { className: inputClassName, locale: currentLang, calendarClassName: "calendar", minDate: minDate ? new Date(minDate) : null, dateFormat: dateFormat || 'dd/MM/yyyy', onChange: function (date) { return onSelectDate(date); }, selected: Date.parse(date), placeholderText: placeholder || label, showMonthDropdown: true, showYearDropdown: true, disabled: disabled }),
                endName && (React.createElement(DatePicker, { className: inputClassName, locale: currentLang, calendarClassName: "calendar", minDate: noMinDate ? null : new Date(date), dateFormat: dateFormat || 'dd/MM/yyyy', onChange: function (date) { return onSelectDate(date, true); }, selected: Date.parse(endDate), placeholderText: endPlaceholder, showMonthDropdown: true, showYearDropdown: true, popperPlacement: "bottom-".concat(forcePlacement ? 'end' : 'start'), disabled: disabled })))),
        !noErrorText && error && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[error.type])))));
};
