import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { useLoadAgedBalanceCompact } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { BaseTicker } from '../BaseTicker';
function getAmountAtRisk(agedBalanceCompact, formatter) {
    var netOver90 = agedBalanceCompact.debits['90'] - agedBalanceCompact.credits['90'];
    return formatter.format(netOver90);
}
function getPercentageAtRisk(agedBalanceCompact) {
    var netAgedBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var totalReceivable = netAgedBalance.notDue +
        netAgedBalance['0'] +
        netAgedBalance['30'] +
        netAgedBalance['60'] +
        netAgedBalance['90'];
    var amountAtRisk = netAgedBalance['90'];
    var percentage = (amountAtRisk / totalReceivable) * 100;
    return percentage.toFixed(2);
}
export var AtRiskTicker = function (_a) {
    var format = _a.format;
    var t = useTranslation().t;
    var _b = useLoadAgedBalanceCompact(), agedBalanceCompact = _b.agedBalanceCompact, isAgedBalanceCompactLoading = _b.isAgedBalanceCompactLoading;
    var formatter = useSafeLocalizedCompactCurrencyFormatter(true);
    if (isAgedBalanceCompactLoading || agedBalanceCompact == null) {
        return React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.AT_RISK.TITLE) });
    }
    var value = format === 'percentage'
        ? getPercentageAtRisk(agedBalanceCompact)
        : getAmountAtRisk(agedBalanceCompact, formatter);
    return (React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.AT_RISK.TITLE), value: value, suffix: format === 'percentage' ? '%' : '€', url: "invoices/listing?min_days_late=90&status=unpaid,not_lost" }));
};
