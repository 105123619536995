import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Button } from 'shared/io';
import { CustomPreferencesPartialForm } from './CustomPreferencesPartialForm/CustomPreferencesPartialForm';
import { GeneralConditionsPartialSendTos } from './GeneralConditionsPartialForm/GeneralConditionsPartialSendTos';
import { PaymentPlanPartialForm } from './PaymentPlanPartialForm/PaymentPlanPartialForm';
import { PostalPreferencesPartialForm } from './PostalPreferencesPartialForm/PostalPreferencesPartialForm';
import styleIdentifiers from './PreferencesForm.scss';
import { SalePreferencesPartialForm } from './SalePreferencesPartialForm/SalePreferencesPartialForm';
var styles = classNames.bind(styleIdentifiers);
export var PreferencesForm = function (_a) {
    var company = _a.company, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({
        defaultValues: company,
    });
    var dirtyFields = form.formState.dirtyFields, reset = form.reset, handleSubmit = form.handleSubmit;
    useEffect(function () {
        reset(__assign({}, company));
    }, [company, reset]);
    var isDirty = !!Object.keys(dirtyFields).length;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement(NavigationPrompt, { when: isDirty, onSaveAndQuit: handleSubmit(onSubmit) }),
        React.createElement("form", { className: styles('PreferencesForm'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("h3", null, t(i18nKeys.SALE_CONDITION)),
            React.createElement(SalePreferencesPartialForm, null),
            React.createElement(GeneralConditionsPartialSendTos, null),
            React.createElement("h3", { className: styles('margin') }, t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)),
            React.createElement(PaymentPlanPartialForm, null),
            React.createElement("h3", { className: styles('margin') }, t(i18nKeys.FORM.PREFERENCES.PREFERENCES_SEND)),
            React.createElement("h4", { className: styles('margin') }, t(i18nKeys.EMAIL)),
            React.createElement(CustomPreferencesPartialForm, null),
            React.createElement("h4", { className: styles('margin') }, t(i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND)),
            React.createElement(PostalPreferencesPartialForm, null),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), disabled: !isDirty, noShadow: true })))));
};
