import { __assign } from "tslib";
import axios from 'axios';
import { omit } from 'remeda';
import apiService from 'shared/service/api.service';
export var REDUX_AXIOS_INSTANCE = axios.create();
// MARK: axios call
function axiosCall(url, request) {
    return REDUX_AXIOS_INSTANCE(url, request)
        .then(function (response) { return response; })
        .catch(function (error) { return error.response; });
}
// MARK: API call logic
export function call(path, type, options) {
    var url = !options.raw ? apiService.baseUrl + path : path;
    var headers = __assign({ 'Content-Type': 'application/json', tenant: apiService.tenant }, omit(options.headers, ['content-length']));
    var data = options.data;
    if (options.fileUpload) {
        headers = __assign(__assign({}, headers), { Accept: 'application/json', 'Content-Type': 'multipart/form-data;' });
        var formData = new FormData();
        var keys = Object.keys(data);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            formData.append(key, data[key]);
        }
        data = formData;
    }
    // Request
    var request = {};
    request.method = type;
    request.headers = headers;
    request.data = data;
    request.params = type === 'GET' ? options.data : options.params;
    if (options.fileUpload) {
        request.onUploadProgress = function (event) {
            if (typeof options.progress === 'function') {
                var progress = Math.round((event.loaded / event.total) * 100);
                options.progress(progress);
            }
        };
    }
    return axiosCall(url, request);
}
// MARK: - Download logic
export function downloadBlob(path, method, options) {
    var headers = options.headers || {};
    if (options.public)
        headers.tenant = 'public';
    else
        headers.tenant = apiService.tenant;
    // Request
    var request = {};
    request.method = method || 'GET';
    request.headers = omit(headers, ['content-length']);
    request.responseType = 'blob';
    request.data = options.data;
    request.params = options.params;
    return axiosCall(apiService.baseUrl + path, request);
}
export function download(path, method, options) {
    var url = apiService.baseUrl + path;
    var headers = options.headers || {};
    if (options.public)
        headers.tenant = 'public';
    else
        headers.tenant = apiService.tenant;
    // Request
    var request = {};
    request.method = method || 'GET';
    request.headers = omit(headers, ['content-length']);
    request.responseType = 'blob';
    request.data = options.data;
    request.params = options.params;
    // TODO: This method of triggering the download does not seem to work on some embedded browsers,
    // like the ones produced by opening a link fron inside Slack or an email client
    return axiosCall(url, request);
}
// MARK: - CRUD
export var crud = function (name) { return ({
    list: function (options) { return call("".concat(name), 'GET', options); },
    detail: function (options) { return call("".concat(name, "/").concat(options.id), 'GET', options); },
    add: function (options) { return call("".concat(name), 'POST', options); },
    update: function (options) { return call("".concat(name, "/").concat(options.id), 'PUT', options); },
    delete: function (options) { return call("".concat(name, "/").concat(options.id), 'DELETE', options); },
}); };
