import { __awaiter, __generator } from "tslib";
import { i18nKeys, useTranslation } from 'locales';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export function refreshBalanceFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('analytics/aged-balance/refresh')];
            }
        });
    });
}
export function useRefreshBalance() {
    var t = useTranslation().t;
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function () { return refreshBalanceFn(axiosInstance); },
        onSuccess: function (response) {
            setTimeout(function () {
                client.refetchQueries({ queryKey: ['aged-balance'] });
            }, 5 * 60 * 1000);
            notifications.show({
                message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_SUCCESS, {
                    email: response.data.notifiedEmail,
                }),
                color: 'blue',
            });
        },
        onError: function () {
            notifications.show({
                message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_ERROR),
                color: 'blue',
            });
        },
    });
    return addActionNameToMutationResult('refreshBalance', mutation);
}
