import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import styleIdentifiers from './RadioButton.scss';
var styles = classNames.bind(styleIdentifiers);
export var RadioButton = function (_a) {
    var onChange = _a.onChange, name = _a.name, noMargin = _a.noMargin, error = _a.error, items = _a.items, className = _a.className, disabled = _a.disabled, canRemove = _a.canRemove, labelStyle = _a.labelStyle, value = _a.value;
    var t = useTranslation().t;
    var form = useFormContext();
    var val = value !== undefined ? value : form.watch(name);
    var onSetValue = function (newValue) { return function () {
        onChange(canRemove && val === items[0].value ? null : newValue);
    }; };
    return (React.createElement("div", { className: styles('RadioButton', className, error && 'error', disabled && 'disabled', noMargin && 'no-margin') },
        items.map(function (radioItem, index) { return (React.createElement("div", { className: styles('label'), key: radioItem.value || index, onClick: onSetValue(radioItem.value) },
            React.createElement("div", { className: styles('radio-button-circle', radioItem.value == val && 'checked') }),
            radioItem.label && (React.createElement("span", { className: styles('textLabel', labelStyle) }, radioItem.label)))); }),
        error && React.createElement("div", { className: styles('error-message') },
            " ",
            t(error))));
};
