import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, Input } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './RecalculateDatesModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function RecalculateDatesModal(_a) {
    var text = _a.text, onConfirm = _a.onConfirm, _b = _a.initialDelay, initialDelay = _b === void 0 ? 31 : _b;
    var t = useTranslation().t;
    var form = useForm({
        defaultValues: { newDelay: initialDelay },
    });
    var watch = form.watch, register = form.register;
    var handleSubmit = function (confirm) { return function (e) {
        e.preventDefault();
        dialogHide(DialogShowId.CONFIRM);
        if (!confirm)
            return;
        onConfirm(watch('newDelay'));
    }; };
    return (React.createElement("div", { className: styles('recalculate-dates-modal') },
        text,
        React.createElement(FormProvider, __assign({}, form),
            React.createElement("form", null,
                React.createElement(Input, { className: styles('delay-input'), register: register('newDelay', { required: true, min: 1 }), type: "number", min: "1", step: "1", withBorder: true, noMargin: true }),
                React.createElement("div", { className: styles('buttons-wrapper') },
                    React.createElement(Button, { color: ButtonColor.GREY, type: "submit", label: t(i18nKeys.NO), onClick: handleSubmit(false) }),
                    React.createElement(Button, { type: "submit", label: t(i18nKeys.YES), onClick: handleSubmit(true) }))))));
}
