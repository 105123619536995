import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './StepsCalculation.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentPlanInvoiceItem(_a) {
    var item = _a.item, onCheck = _a.onCheck, invoiceIds = _a.invoiceIds;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var id = item.id, _c = item.attributes, currency = _c.currency, reference = _c.reference, issue_date = _c.issue_date, total_tvac = _c.total_tvac, amount_already_paid = _c.amount_already_paid, remaining_balance_with_fees = _c.remaining_balance_with_fees, localized_money_object = _c.localized_money_object;
    var checked = invoiceIds.includes(id);
    return (React.createElement("tr", { key: id, className: styles('choose-invoice-item', checked && 'active') },
        React.createElement("td", null,
            React.createElement(Checkbox, { name: "", className: styles('checkbox'), checked: checked, onChange: function () { return onCheck(id); } })),
        React.createElement("td", { className: styles('reference') }, reference),
        React.createElement("td", null, formattedDate(issue_date, currentLang)),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: total_tvac, value: localized_money_object.total_tvac, suffix: currency, className: styles('value', 'bigger') })),
        React.createElement("td", null, amount_already_paid && (React.createElement(Amount, { localizedValue: amount_already_paid, value: localized_money_object.amount_already_paid, suffix: currency, className: styles('value', 'bigger') }))),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: remaining_balance_with_fees, value: localized_money_object.remaining_balance_with_fees, suffix: currency, className: styles('value', 'bigger') }))));
}
