import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import styleIdentifiers from '../PaymentPlanDetailDebtor.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentPlanInvoicesDebtor(_a) {
    var withFees = _a.withFees, invoices = _a.invoices, currency = _a.currency;
    var t = useTranslation().t;
    var renderInvoice = function (reference, remainingBalance) { return (React.createElement("div", { className: styles('key-text') },
        React.createElement("div", { className: styles('attribute') }, reference),
        React.createElement("div", { className: styles('value') },
            React.createElement(Amount, { localizedValue: remainingBalance, value: remainingBalance, suffix: currency })))); };
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, t(i18nKeys.INVOICES)),
        invoices.details.map(function (_a) {
            var _b = _a.attributes, remaining_balance = _b.remaining_balance, remaining_balance_with_fees = _b.remaining_balance_with_fees, reference = _b.reference;
            return (React.createElement(React.Fragment, null, renderInvoice(reference, withFees ? remaining_balance_with_fees : remaining_balance)));
        })));
}
