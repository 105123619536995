import { __assign } from "tslib";
import { i18nKeys } from 'locales';
import { CalculationType, CGV, InterestCalculationType, SalePreferencesCGVFieldsName, SalePreferencesFieldsName, } from '../PreferencesForm.constants';
export var getCalculationTypeOptions = function (t) {
    return Object.keys(CalculationType).map(function (key) { return ({
        description: t(i18nKeys.CALCULATION_TYPE[key]),
        value: CalculationType[key],
    }); });
};
export var getInterestCalculationTypeOptions = function (t) {
    return Object.keys(InterestCalculationType).map(function (key) { return ({
        description: t(i18nKeys.INTEREST_CALCULATION_TYPE[key]),
        value: InterestCalculationType[key],
    }); });
};
export var getFieldErrors = function (errors, index) {
    var cgvErrors = errors[CGV];
    return cgvErrors && Array.isArray(cgvErrors) && cgvErrors[index] ? cgvErrors[index] : {};
};
export var getDefaultFixFee = function () { return ({
    value: 0,
}); };
export var getDefaultInterest = function (asDefault) {
    if (asDefault === void 0) { asDefault = false; }
    return ({
        period: CalculationType.ANNUAL,
        value: asDefault ? 8 : 0,
    });
};
export var getDefaultPenaltyClause = function (asDefault) {
    if (asDefault === void 0) { asDefault = false; }
    return ({
        type: InterestCalculationType.PERCENTAGE,
        minimum: asDefault ? 40 : 0,
        value: asDefault ? 10 : 0,
    });
};
export var getDefaultCgvValues = function (asDefault) {
    var _a, _b;
    if (asDefault === void 0) { asDefault = false; }
    return (__assign((_a = {}, _a[SalePreferencesFieldsName.DEFAULT] = asDefault, _a[SalePreferencesFieldsName.NAME] = asDefault ? 'General' : '', _a[SalePreferencesFieldsName.MINIMUM_DELAY] = asDefault ? 31 : 0, _a[SalePreferencesFieldsName.HAS_PDF] = false, _a), (asDefault
        ? (_b = {},
            _b[SalePreferencesCGVFieldsName.PENALTY_CLAUSE] = getDefaultPenaltyClause(asDefault),
            _b[SalePreferencesCGVFieldsName.INTEREST] = getDefaultInterest(asDefault),
            _b) : undefined)));
};
