import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import styleIdentifiers from '../Integration.scss';
var styles = classNames.bind(styleIdentifiers);
export var Chift = function (_a) {
    var _b = _a.history, goBack = _b.goBack, replace = _b.replace;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _c = getIntegrationRights(Integration.CHIFT, company), isActive = _c.isActive, canActivate = _c.canActivate, isConnected = _c.isConnected;
    useEffect(function () {
        if (!canActivate) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    return (React.createElement("article", { className: styles('chift') },
        React.createElement(IntegrationHeader, { name: Integration.CHIFT, goBack: goBack, isActive: isActive, isConnected: isConnected }),
        React.createElement("div", { style: { width: '100%' } })));
};
