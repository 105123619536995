import React from 'react';
import classNames from 'classnames/bind';
import styleIdentifiers from './UserAvatar.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UserAvatar(_a) {
    var user = _a.user;
    if (user.profilePicture != null) {
        return (React.createElement("div", { className: styles('collaborator-picture'), style: {
                backgroundImage: "url(".concat(user.profilePicture, ")"),
            } }));
    }
    return (React.createElement("div", { className: styles('collaborator-picture') },
        React.createElement("span", { className: styles('initials') }, user.name.initials)));
}
