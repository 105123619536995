import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import ProductForm from 'shared/forms/ProductForm';
import { productActions } from 'store/product/product.actions';
import { showDialog, DialogShowId, DialogShowSize, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { captureMessage } from '@sentry/react';
import styleIdentifiers from './productListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ProductListItem(props) {
    var _a;
    var item = props.item, noCheckbox = props.noCheckbox, action = props.action;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var submitProduct = function (values) {
        productActions.update({
            id: values.id,
            data: values,
            callback: function () {
                sideMenuHide();
            },
        });
    };
    var updateProduct = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(ProductForm, { onSubmit: submitProduct, initialValues: item }),
        });
    };
    var handleDelete = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.INVOICING.PRODUCT.AYS_TO_DELETE), onConfirm: function () { return productActions.delete({ id: item.id }); } })),
        });
    };
    // Sentry 15x
    var taskCategory = constants.tax_categories.find(function (tax_category) { return tax_category.value === item.tax_category_code; });
    if (taskCategory == null) {
        captureMessage('In ProductListItem, taskCategory is null and will be hidden for this field.', {
            extra: { item: item, constants: constants },
        });
    }
    return (React.createElement("tr", { className: styles('ProductListItem'), key: item.id },
        React.createElement("td", null, !noCheckbox && (React.createElement("div", { onClick: action, className: styles('checkbox', noCheckbox && 'small') },
            React.createElement("div", { className: styles('box', item.checked && 'checked') })))),
        React.createElement("td", null, item.id),
        React.createElement("td", { className: styles('name') }, item.name),
        React.createElement("td", { className: styles('description') }, item.description),
        React.createElement("td", { className: styles('price') },
            React.createElement(Amount, { value: item.price_htva })),
        React.createElement("td", { className: styles('tva') }, (_a = taskCategory === null || taskCategory === void 0 ? void 0 : taskCategory.description) !== null && _a !== void 0 ? _a : ''),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: updateProduct },
                React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: handleDelete },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
}
