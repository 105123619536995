import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AddressBlock from 'shared/components/AddressBlock';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, Checkbox, DropdownDirection } from 'shared/io';
import styleIdentifiers from './AttributesSelection.scss';
var styles = classNames.bind(styleIdentifiers);
export var AttributesSelection = function (_a) {
    var _b;
    var baseDebtorId = _a.baseDebtorId, debtors = _a.debtors, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _c = __read(useState(baseDebtorId), 2), vatToKeep = _c[0], setVatToKeep = _c[1];
    var _d = __read(useState([baseDebtorId]), 2), emailsToKeep = _d[0], setEmailsToKeep = _d[1];
    var _e = __read(useState([baseDebtorId]), 2), phonesToKeep = _e[0], setPhonesToKeep = _e[1];
    var _f = __read(useState(baseDebtorId), 2), addressToKeep = _f[0], setAddressToKeep = _f[1];
    var _g = __read(useState([baseDebtorId]), 2), bankAccountsToKeep = _g[0], setBankAccountsToKeep = _g[1];
    var baseDebtor = debtors.find(function (debtor) { return debtor.id === baseDebtorId; });
    var mergedDebtors = debtors.filter(function (debtor) { return debtor.id !== baseDebtorId; });
    var findDebtor = function (id) {
        return debtors.find(function (debtor) { return debtor.id === id; });
    };
    var keptDebtors = function (ids) {
        return debtors.filter(function (debtor) { return ids.includes(debtor.id); });
    };
    var addressToKeepAttributes = (_b = findDebtor(addressToKeep).attributes.address) === null || _b === void 0 ? void 0 : _b.attributes;
    var _h = __assign({}, addressToKeepAttributes), street_name = _h.street_name, street_number = _h.street_number, street_box = _h.street_box, zip_code = _h.zip_code, country = _h.country;
    var streetString = "".concat(street_name, " ").concat(street_number, " ").concat(street_box && t(i18nKeys.FORM.ADDRESS.BOX), " ").concat(street_box, ", ").concat(zip_code, " ").concat(country);
    var renderBic = function (bankAccount, withSpan) {
        var _a;
        if (withSpan === void 0) { withSpan = false; }
        var bic = bankAccount.bic || ((_a = bankAccount.attributes) === null || _a === void 0 ? void 0 : _a.bic);
        if (!bic)
            return '';
        return withSpan ? (React.createElement(React.Fragment, null,
            React.createElement("span", null, "BIC: "),
            bic)) : ("(".concat(bic, ")"));
    };
    return (React.createElement("div", { className: styles('confirm-merge-modal') },
        React.createElement("p", { className: styles('info') }, t(i18nKeys.CLIENT.MERGE.DESCRIPTION)),
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null),
                    debtors.map(function (debtor) { return (React.createElement("th", { key: debtor.id }, debtor.attributes.full_name)); }))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, t(i18nKeys.VAT_NUMBER)),
                    debtors.map(function (debtor) { return (React.createElement("td", { key: debtor.id, className: styles(!debtor.attributes.vat_number && 'empty') }, debtor.attributes.vat_number ? (React.createElement(Checkbox, { noMargin: true, checked: debtor.id === vatToKeep, label: debtor.attributes.vat_number, onChange: function () {
                            setVatToKeep(debtor.id);
                        } })) : ('#N/A'))); })),
                React.createElement("tr", null,
                    React.createElement("td", null, t(i18nKeys.EMAILS)),
                    debtors.map(function (debtor) { return (React.createElement("td", { key: debtor.id, className: styles(debtor.attributes.emails.length === 0 && 'empty') }, debtor.attributes.emails.length > 0 ? (React.createElement(Checkbox, { noMargin: true, checked: emailsToKeep.includes(debtor.id), label: debtor.attributes.emails.join(', '), onChange: function () {
                            var arr = Object.values(emailsToKeep);
                            if (arr.includes(debtor.id)) {
                                arr = arr.filter(function (el) { return el !== debtor.id; });
                            }
                            else {
                                arr.push(debtor.id);
                            }
                            setEmailsToKeep(arr);
                        } })) : ('#N/A'))); })),
                React.createElement("tr", null,
                    React.createElement("td", null, t(i18nKeys.PHONES)),
                    debtors.map(function (debtor) { return (React.createElement("td", { key: debtor.id, className: styles(debtor.attributes.phones.length === 0 && 'empty') }, debtor.attributes.phones.length > 0 ? (React.createElement(Checkbox, { noMargin: true, checked: phonesToKeep.includes(debtor.id), label: debtor.attributes.phones
                            .map(function (phone) { return phone.attributes.number; })
                            .join(', '), onChange: function () {
                            var arr = Object.values(phonesToKeep);
                            if (arr.includes(debtor.id)) {
                                arr = arr.filter(function (el) { return el !== debtor.id; });
                            }
                            else {
                                arr.push(debtor.id);
                            }
                            setPhonesToKeep(arr);
                        } })) : ('#N/A'))); })),
                React.createElement("tr", null,
                    React.createElement("td", null, t(i18nKeys.ADDRESS)),
                    debtors.map(function (debtor) {
                        var _a, _b;
                        return (React.createElement("td", { key: debtor.id, className: styles(!((_a = debtor.attributes.address) === null || _a === void 0 ? void 0 : _a.attributes) && 'empty') }, ((_b = debtor.attributes.address) === null || _b === void 0 ? void 0 : _b.attributes) ? (React.createElement(Checkbox, { noMargin: true, checked: debtor.id === addressToKeep, label: React.createElement(AddressBlock, { address: debtor.attributes.address, className: styles('address'), clean: true }), onChange: function () {
                                setAddressToKeep(debtor.id);
                            } })) : ('#N/A')));
                    })),
                React.createElement("tr", null,
                    React.createElement("td", null, t(i18nKeys.COMMON.BANK_ACCOUNT)),
                    debtors.map(function (debtor) { return (React.createElement("td", { key: debtor.id, className: styles(debtor.attributes.debtor_bank_accounts.length === 0 && 'empty') }, debtor.attributes.debtor_bank_accounts.length > 0 ? (React.createElement(Checkbox, { noMargin: true, checked: bankAccountsToKeep.includes(debtor.id), onChange: function () {
                            var arr = Object.values(bankAccountsToKeep);
                            if (arr.includes(debtor.id)) {
                                arr = arr.filter(function (el) { return el !== debtor.id; });
                            }
                            else {
                                arr.push(debtor.id);
                            }
                            setBankAccountsToKeep(arr);
                        } }, debtor.attributes.debtor_bank_accounts.map(function (bankAccount) {
                        var _a;
                        return (React.createElement("p", { key: bankAccount.id, className: styles('bank-accounts') },
                            React.createElement("span", null, "IBAN: "),
                            bankAccount.iban || ((_a = bankAccount.attributes) === null || _a === void 0 ? void 0 : _a.iban),
                            ' ',
                            renderBic(bankAccount, true)));
                    }))) : ('#N/A'))); })))),
        React.createElement("div", { className: styles('additional-info') },
            React.createElement("h3", null,
                mergedDebtors.map(function (debtor) { return debtor.attributes.full_name; }).join(', '),
                ' ',
                t(i18nKeys.WILL_BE_MERGED.SINGLE),
                " ", baseDebtor === null || baseDebtor === void 0 ? void 0 :
                baseDebtor.attributes.full_name),
            React.createElement("ol", null,
                React.createElement("li", null,
                    React.createElement("div", { style: { textAlign: 'left' } },
                        t(i18nKeys.WILL_BE_MERGED.FINAL_DATA),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement("b", null, t(i18nKeys.VAT_NUMBER)),
                                ": ",
                                findDebtor(vatToKeep).attributes.vat_number),
                            React.createElement("li", null,
                                React.createElement("b", null, t(i18nKeys.EMAILS)),
                                ":",
                                ' ',
                                keptDebtors(emailsToKeep)
                                    .map(function (debtor) { return debtor.attributes.emails.join(', '); })
                                    .join(', ')),
                            React.createElement("li", null,
                                React.createElement("b", null, t(i18nKeys.PHONES)),
                                ":",
                                ' ',
                                keptDebtors(phonesToKeep)
                                    .map(function (debtor) {
                                    return debtor.attributes.phones.map(function (phone) { return phone.attributes.number; }).join(', ');
                                })
                                    .join(', ')),
                            React.createElement("li", null,
                                React.createElement("b", null, t(i18nKeys.ADDRESS)),
                                ": ",
                                addressToKeepAttributes && streetString),
                            React.createElement("li", null,
                                React.createElement("b", null, t(i18nKeys.COMMON.BANK_ACCOUNT)),
                                ":",
                                ' ',
                                keptDebtors(bankAccountsToKeep)
                                    .map(function (debtor) {
                                    return debtor.attributes.debtor_bank_accounts
                                        .map(function (bankAccount) {
                                        return "".concat(bankAccount.iban || bankAccount.attributes.iban, " ").concat(renderBic(bankAccount));
                                    })
                                        .join(', ');
                                })
                                    .join(', '))))),
                React.createElement("li", null,
                    React.createElement("p", null,
                        t(i18nKeys.WILL_BE_MERGED.MODELS_MERGED),
                        ' ',
                        t(i18nKeys.WILL_BE_MERGED.OF_OTHER_CLIENT),
                        ' ',
                        t(i18nKeys.WILL_BE_MERGED.WILL_BE_ASSOCIATED_TO),
                        ' ',
                        React.createElement("b", null, baseDebtor === null || baseDebtor === void 0 ? void 0 : baseDebtor.attributes.full_name))))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Tooltip, { icon: IconName.INFO, iconClassName: styles('icon'), direction: DropdownDirection.TOP }, t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)),
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.CONFIRM), disabled: phonesToKeep.length === 0 ||
                    emailsToKeep.length === 0 ||
                    vatToKeep === '' ||
                    addressToKeep === '', onClick: function () {
                    return onSubmit({
                        base_debtor_id: baseDebtorId,
                        debtors_to_delete_id: debtors.map(function (d) { return d.id; }).filter(function (id) { return id !== baseDebtorId; }),
                        vat_number: vatToKeep,
                        emails: emailsToKeep,
                        phones: phonesToKeep,
                        address: addressToKeep,
                        bank_accounts: bankAccountsToKeep,
                    });
                } }))));
};
