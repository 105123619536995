export var getLinkInvoiceFormFieldLine = function (_a, matchedPayments) {
    var _b;
    var id = _a.id;
    if (matchedPayments === void 0) { matchedPayments = []; }
    return ({
        invoice_id: Number(id),
        amount: ((_b = matchedPayments.find(function (e) { return e.invoice_attributes.id === id; })) === null || _b === void 0 ? void 0 : _b.amount) || 0,
    });
};
export var getInvoicesFieldsTotal = function (invoicesFields) {
    return invoicesFields.reduce(function (acc, e) { return acc + Number(e.amount); }, 0);
};
export var getCurrentValues = function (fields, _a) {
    if (fields === void 0) { fields = []; }
    var total_tvac = _a.total_tvac;
    var currentTotal = fields.reduce(function (acc, e) { return acc + parseFloat(String(e.amount)); }, 0) * 100;
    var maxTotal = Math.abs(total_tvac) * 100;
    var restTotal = maxTotal - currentTotal;
    currentTotal /= 100;
    maxTotal /= 100;
    restTotal /= 100;
    return {
        currentTotal: currentTotal,
        maxTotal: maxTotal,
        restTotal: restTotal > 0 ? restTotal : 0,
        canAdd: currentTotal < maxTotal,
        canSubmit: currentTotal <= maxTotal,
    };
};
