import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { LightUserSchema } from './use-load-light-users';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var ViewSchema = z.object({
    id: z.string(),
    user: LightUserSchema,
    name: z.string(),
    isShared: z.boolean(),
    isDefault: z.boolean(),
    resourceType: z.string(),
    isFavorite: z.boolean(),
    columns: z.array(z.object({
        id: z.string(),
        order: z.number(),
        name: z.string().min(1),
    })),
    filters: z.array(z.object({
        id: z.string(),
        name: z.string().min(1),
    })),
});
var ViewsSchema = z.array(ViewSchema);
export function loadViewsQueryFnV2(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("views/".concat(queryKey[1]))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ViewsSchema.parse(data)];
            }
        });
    });
}
// TODO: create a utility type that contains onSuccess, onError, etc
export function useLoadViewsV2(_a) {
    var resourceType = _a.resourceType, onSuccess = _a.onSuccess;
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['views', resourceType],
        queryFn: function (context) { return loadViewsQueryFnV2(axiosInstance, context); },
        onSuccess: onSuccess,
    });
    return addResourceNameToQueryResult('views', queryResult);
}
