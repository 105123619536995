import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Checkbox } from 'shared/io';
import { GeneralConditionsFieldsName, ReconciliationFieldNames, } from '../PreferencesForm.constants';
export var GeneralConditionsPartialSendTos = function () {
    var t = useTranslation().t;
    var _a = useFormContext(), watch = _a.watch, register = _a.register;
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { register: register(GeneralConditionsFieldsName.SEND_TOS), watch: watch, label: React.createElement("span", null,
                t(i18nKeys.FORM.PREFERENCES.SEND_CONDITION_EACH_INVOICE),
                ' ',
                React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.PREFERENCES.TOOLTIP.SEND_CONDITION_EACH_INVOICE) })) }),
        React.createElement(Checkbox, { register: register(ReconciliationFieldNames.IGNORE_LATE_FEES), watch: watch, label: React.createElement("span", null,
                t(i18nKeys.FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE),
                ' ',
                React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE_TOOLTIP) })) })));
};
