import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { getWorkflowStepRights } from '../WorkflowForm.utils';
import { WorkflowFormStepReminder } from './WorkflowFormStepReminder';
import { WorkflowFormStepTask } from './WorkflowFormStepTask';
import styleIdentifiers from './WorkflowFormStep.scss';
var styles = classNames.bind(styleIdentifiers);
var TYPES = __assign(__assign({}, StepType), { send_reminder: 'send_reminder' });
export var WorkflowFormStep = function (_a) {
    var planType = _a.planType, templates = _a.templates, onSubmit = _a.onSubmit, step = _a.step;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: step,
    });
    var _b = __read(useState(!templates.length && !(step === null || step === void 0 ? void 0 : step.step_type) ? StepType.task_generation : step === null || step === void 0 ? void 0 : step.step_type), 2), type = _b[0], setType = _b[1];
    var _c = getWorkflowStepRights(company, planType), canAddTask = _c.canAddTask, canAddReminder = _c.canAddReminder, canEditTask = _c.canEditTask, canEditReminder = _c.canEditReminder;
    var watch = form.watch, register = form.register, handleSubmit = form.handleSubmit, _d = form.formState, errors = _d.errors, isDirty = _d.isDirty;
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    register('id');
    if (!type) {
        return (React.createElement("div", { className: styles('workflow-step-form') },
            React.createElement("div", { className: styles('head') }, t(i18nKeys.PLAN.ADD_STEP)),
            React.createElement("div", { className: styles('content') },
                !canAddReminder && React.createElement("p", { className: styles('not-included') }, t(i18nKeys.NOT_INCLUDED)),
                React.createElement("button", { className: styles('action'), type: "button", onClick: function () { return setType('send_reminder'); } },
                    React.createElement(Icon, { name: IconName.SEND }),
                    t(i18nKeys.FORM.RECOVER_STEP.TYPE.SEND_REMINDER)),
                !canAddTask && React.createElement("p", { className: styles('not-included') }, t(i18nKeys.NOT_INCLUDED)),
                React.createElement("button", { className: styles('action'), type: "button", onClick: function () { return setType('task_generation'); } },
                    React.createElement(Icon, { name: IconName.COMMENT }),
                    t(i18nKeys.FORM.RECOVER_STEP.TYPE.CREATE_TASK)))));
    }
    var canSubmit = function () {
        switch (watch('step_type')) {
            case StepType.task_generation:
                return step ? canEditTask : canAddTask;
            case StepType.preventive_reminder:
            case StepType.first_reminder:
            case StepType.intermediate_reminder:
            case StepType.last_reminder:
                return step ? canEditReminder : canAddReminder;
            default:
                return false;
        }
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('workflow-step-form'), onSubmit: handleSubmit(onSubmit) },
            type === StepType.task_generation ? (React.createElement(WorkflowFormStepTask, { planType: planType, step: step },
                React.createElement(Input, { type: "number", register: register('delay', { required: true, min: 0 }), label: t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION), errorMessage: get(errors, 'delay'), disabled: step ? !canEditTask : !canAddTask, withBorder: true }))) : (React.createElement(WorkflowFormStepReminder, { planType: planType, availableTemplates: templates, step: step },
                React.createElement(Input, { type: "number", register: register('delay', { required: true, min: 0 }), label: t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION), errorMessage: get(errors, 'delay'), disabled: step ? !canEditReminder : !canAddReminder, withBorder: true }))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), disabled: !canSubmit(), noMargin: true })))));
};
