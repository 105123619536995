import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useResetAccountingIntegration } from 'api';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components';
import { Button, ButtonColor } from 'shared/io';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { INTEGRATIONS_ROUTE } from '../Integrations.constants';
export var ResetButton = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var resetConnection = useResetAccountingIntegration().mutate;
    var lastSettingsScreen = useSelector(function (state) { return state.app.lastSettingsScreen; });
    var handleClick = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.INTEGRATION.RESET_ACCOUNTING_INTEGRATION), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.RESET), onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, resetConnection()];
                            case 1:
                                _a.sent();
                                appActions.reloadCompanyConfiguration({
                                    callback: function () { return history.push("".concat(INTEGRATIONS_ROUTE, "?category=").concat(lastSettingsScreen)); },
                                });
                                return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () {
                    dialogHide(DialogShowId.CONFIRM);
                } })),
        });
    };
    return (React.createElement(Button, { color: ButtonColor.RED, noMargin: true, onClick: handleClick }, t(i18nKeys.RESET)));
};
