import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Icon } from 'shared/components/Icon';
import styleIdentifiers from './Button.scss';
var styles = classNames.bind(styleIdentifiers);
export var ButtonColor;
(function (ButtonColor) {
    ButtonColor["GREY"] = "grey";
    ButtonColor["GREEN"] = "green";
    ButtonColor["RED"] = "red";
    ButtonColor["TRANSPARENT"] = "transparent";
    ButtonColor["WHITE"] = "white";
    ButtonColor["BLUE"] = "blue";
    ButtonColor["MAIN"] = "main-color";
    ButtonColor["ORANGE"] = "orange";
})(ButtonColor || (ButtonColor = {}));
export var Button = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.iconSize, iconSize = _c === void 0 ? '13px' : _c, label = _a.label, color = _a.color, iconLeft = _a.iconLeft, iconRight = _a.iconRight, noMargin = _a.noMargin, noShadow = _a.noShadow, className = _a.className, upperCircle = _a.upperCircle, disabledClick = _a.disabledClick, small = _a.small, disabled = _a.disabled, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, children = _a.children, _e = _a.childrenBefore, childrenBefore = _e === void 0 ? false : _e, rest = __rest(_a, ["type", "iconSize", "label", "color", "iconLeft", "iconRight", "noMargin", "noShadow", "className", "upperCircle", "disabledClick", "small", "disabled", "isLoading", "children", "childrenBefore"]);
    var t = useTranslation().t;
    return (React.createElement("button", __assign({ type: type, disabled: isLoading || (disabled && !disabledClick), className: styles('Button', (iconLeft || iconRight) && 'with-icon', (isLoading || disabled) && 'disabled', noMargin && 'no-margin', isLoading && 'loading', !noShadow && 'shadow', small && 'small', className, color) }, rest),
        iconLeft && (React.createElement("span", { className: styles('icon-left') },
            React.createElement(Icon, { name: iconLeft, size: iconSize }))),
        isLoading ? (React.createElement(React.Fragment, null, t(i18nKeys.LOADING))) : (React.createElement(React.Fragment, null,
            childrenBefore && children,
            label && React.createElement("span", null, label),
            !childrenBefore && children)),
        iconRight && (React.createElement("span", { className: styles('icon-right') },
            React.createElement(Icon, { name: iconRight, size: iconSize }))),
        upperCircle && (React.createElement("span", { className: "".concat(styles('text-circle', 'absolute'), " ").concat(color) }, upperCircle))));
};
