import { __assign, __rest } from "tslib";
import { PlanType, } from 'api/models';
import { get, omit } from 'lodash-es';
import { WorkflowStepsTarget } from '../Workflows.constants';
import { TEMPLATES } from './WorkflowForm.constants';
// TODO: KILL ME ! When FUC**** CustomSelect is destroyed !
export var isTrue = function (e) { return String(e) === 'true'; };
export var getWorkflowRights = function (company, planType) {
    var _a = company.package, can_use_advanced_payment_plans = _a.can_use_advanced_payment_plans, can_use_payment_plans = _a.can_use_payment_plans;
    switch (planType) {
        case PlanType.payment:
            return {
                canAdd: can_use_payment_plans && can_use_advanced_payment_plans,
                canDuplicate: can_use_payment_plans && can_use_advanced_payment_plans,
                canDelete: can_use_payment_plans && can_use_advanced_payment_plans,
                canEdit: can_use_payment_plans,
                canAddStep: can_use_payment_plans,
                canEditStep: can_use_payment_plans,
                canDeleteStep: can_use_payment_plans,
            };
        default:
            return {
                canAdd: false,
                canEdit: false,
                canDuplicate: false,
                canDelete: false,
                canAddStep: false,
                canEditStep: false,
                canDeleteStep: false,
            };
    }
};
export var getWorkflowStepRights = function (company, planType) {
    var _a = company.package, can_use_payment_plans = _a.can_use_payment_plans, can_use_tasks = _a.can_use_tasks;
    switch (planType) {
        case PlanType.payment:
            return {
                canAddTask: can_use_payment_plans && can_use_tasks,
                canEditTask: can_use_payment_plans && can_use_tasks,
                canAddReminder: can_use_payment_plans,
                canEditReminder: can_use_payment_plans,
            };
        default:
            return {
                canAddTask: false,
                canAddReminder: false,
                canEditTask: false,
                canEditReminder: false,
            };
    }
};
var getTemplates = function (planType, name) {
    return get(TEMPLATES, "".concat(planType, ".").concat(name)) || [];
};
export var getAvailableTemplates = function (planType, name, values, index) {
    var _a, _b;
    var templates = getTemplates(planType, name);
    var firstValue = (_a = templates.find(function (e) { return e.first; })) === null || _a === void 0 ? void 0 : _a.value;
    var lastValue = (_b = templates.find(function (e) { return e.last; })) === null || _b === void 0 ? void 0 : _b.value;
    if (firstValue &&
        values.some(function (el) { return el.step_type === firstValue; }) &&
        index <= values.findIndex(function (el) { return el.step_type === firstValue; })) {
        templates = templates.filter(function (e) { return e.value === firstValue; });
    }
    if (lastValue &&
        values.some(function (el) { return el.step_type === lastValue; }) &&
        index > values.findIndex(function (el) { return el.step_type === lastValue; })) {
        templates = templates.filter(function (e) { return e.value === lastValue; });
    }
    templates = templates.filter(function (e) {
        return e.single ? !values.some(function (el) { return el.step_type === e.value; }) : true;
    });
    return templates.map(function (el) { return el.value; });
};
export var isBeforeDueDate = function (name) {
    return name === WorkflowStepsTarget.BEFORE_DUE_DATE;
};
export var isAfterDueDate = function (name) {
    return name === WorkflowStepsTarget.AFTER_DUE_DATE;
};
export var removeStepId = function (step) { return omit(step, 'id'); };
var formatStepsValues = function (step) {
    return (step === null || step === void 0 ? void 0 : step.template)
        ? __assign(__assign({}, omit(step, 'template')), { template_id: step.template.id }) : step;
};
export var formatValues = function (_a) {
    var before_due_date_steps = _a.before_due_date_steps, after_due_date_steps = _a.after_due_date_steps, rest = __rest(_a, ["before_due_date_steps", "after_due_date_steps"]);
    return (__assign(__assign({}, rest), { before_due_date_steps: before_due_date_steps.map(formatStepsValues), after_due_date_steps: after_due_date_steps.map(formatStepsValues) }));
};
var getDefaultStepsValues = function (_a) {
    var id = _a.id, attributes = _a.attributes;
    return (__assign({ id: id }, attributes));
};
export var getDefaultValues = function (planType, worflow) {
    if (!worflow) {
        return {
            name: '',
            plan_type: planType,
            before_due_date_steps: [],
            after_due_date_steps: [],
        };
    }
    var _a = worflow.attributes, before_due_date_steps = _a.before_due_date_steps, after_due_date_steps = _a.after_due_date_steps, rest = __rest(_a, ["before_due_date_steps", "after_due_date_steps"]);
    return __assign(__assign({}, rest), { before_due_date_steps: before_due_date_steps.map(getDefaultStepsValues), after_due_date_steps: after_due_date_steps.map(getDefaultStepsValues) });
};
