import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { accountActions } from 'store/account/account.actions';
export var useSynchroniseIntegration = function () {
    var company = useSelector(function (state) { return state.account.company.data; });
    var _a = company.tp_accounting, can_synchronise_import = _a.can_synchronise_import, last_import_date = _a.last_import_date, manual_import_possible_from = _a.manual_import_possible_from;
    var canSynchronize = useMemo(function () {
        if (manual_import_possible_from) {
            return new Date(manual_import_possible_from).getTime() < new Date().getTime();
        }
        return !!can_synchronise_import;
    }, [can_synchronise_import, manual_import_possible_from]);
    var synchronize = function () {
        if (canSynchronize) {
            accountActions.syncIntegration({
                actionFailure: accountActions.getCompanyRes,
            });
        }
    };
    return {
        synchronize: synchronize,
        canSynchronize: canSynchronize,
        lastImportDate: last_import_date,
        nextPossibleSynchronizeDate: manual_import_possible_from,
    };
};
