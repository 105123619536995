import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import styleIdentifiers from './Emails.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Emails(_a) {
    var className = _a.className, formArray = _a.formArray, setFormDirty = _a.setFormDirty;
    var t = useTranslation().t;
    var _b = useFormContext(), register = _b.register, errors = _b.formState.errors, watch = _b.watch, setValue = _b.setValue, reset = _b.reset, getValues = _b.getValues;
    var deleteLine = function (index) { return function () {
        var emails = formArray.slice();
        emails.splice(index, 1);
        if (emails.length === 0) {
            emails.push({ email: '' });
        }
        reset(__assign(__assign({}, getValues()), { emails: emails }));
        setFormDirty();
    }; };
    var mainEmailIndex = watch('mainEmailIndex');
    return (React.createElement("div", { className: styles('emails') },
        React.createElement("div", { className: styles('description') }, t(i18nKeys.FORM.CLIENT.SELECT_RADIO_BUTTON)), formArray === null || formArray === void 0 ? void 0 :
        formArray.map(function (item, index) {
            var _a, _b;
            return (React.createElement("div", { className: styles('email-item'), key: index },
                React.createElement("div", { className: styles('radio-button-circle', mainEmailIndex == index && 'checked'), onClick: function () {
                        setValue('mainEmailIndex', index, { shouldDirty: true });
                    } }),
                React.createElement(Input, { errorMessage: errors.emails && ((_a = errors.emails[index]) === null || _a === void 0 ? void 0 : _a.email), defaultValue: (_b = formArray[index]) === null || _b === void 0 ? void 0 : _b.email, register: register("emails.".concat(index, ".email"), {
                        validate: { email: email },
                    }), className: className, label: t(i18nKeys.EMAIL), type: "text", withBorder: true }),
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteLine(index) })));
        })));
}
