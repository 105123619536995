import * as Sentry from '@sentry/react';
import { getEnv, isDeployedEnvironment } from '../shared/utils';
Sentry.init({
    enabled: isDeployedEnvironment(),
    dsn: 'https://cc0f95132ecc429e864e4df5e818abe9@o123803.ingest.sentry.io/1429249',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    // These errors are caused by third party libraries or clients and are not actionable
    ignoreErrors: [
        "Unexpected token '<'",
        // Third party, probably intercom or sentry itself
        'postMessage',
        // LastPass
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        // Outlook SafeLink crawlers
        'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllInputs: false,
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: ['recovr'],
        }),
    ],
    environment: getEnv(),
    tracesSampleRate: 1.0,
});
