import { __assign } from "tslib";
import withReduxEnhancer from 'addon-redux/enhancer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'store/root.reducer';
export var browserHistory = createBrowserHistory();
var connectedReducers = function (hist) { return combineReducers(__assign(__assign({}, reducers), { router: connectRouter(hist) })); };
var sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export var store = createStore(connectedReducers(browserHistory), {}, composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory)), withReduxEnhancer));
var storeSetup = {
    store: store,
    sagaRun: sagaMiddleware.run,
};
if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('store/root.reducer', function () {
        var nextConnectedReducers = function () { return combineReducers(__assign({}, require('store/root.reducer'))); };
        store.replaceReducer(nextConnectedReducers());
    });
}
export default storeSetup;
