import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { pick } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect } from 'shared/io';
import { useGetClientState, useGetCompany } from 'shared/utils/selectors';
import styleIdentifiers from './PaymentDelayModal.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentDelayModal = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var company = useGetCompany();
    var isClientLoading = useGetClientState().isLoading;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var CGVItems = company.terms_and_conditions_attributes.map(function (_a) {
        var name = _a.name, id = _a.id, isDefault = _a.default;
        return ({
            description: name,
            value: Number(id),
            default: isDefault,
        });
    });
    var defaultCGV = CGVItems.find(function (e) { return e.default; });
    return (React.createElement("div", { className: styles('payment-delay-modal') },
        React.createElement(FormProvider, __assign({}, form),
            React.createElement(Controller, { defaultValue: defaultCGV === null || defaultCGV === void 0 ? void 0 : defaultCGV.value, name: "terms_and_conditions_id", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('select'), keyText: "description", keyValue: "value", items: CGVItems, name: "terms_and_conditions_id" })); } })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { isLoading: isClientLoading, label: t(i18nKeys.CONFIRM), onClick: function () {
                    onSubmit(pick(form.getValues(), 'terms_and_conditions_id'));
                } }))));
};
