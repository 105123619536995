import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, Input } from 'shared/io';
import styleIdentifiers from './TaskTypeForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskTypeForm = function (_a) {
    var taskType = _a.taskType, onSubmit = _a.onSubmit, onCancel = _a.onCancel;
    var t = useTranslation().t;
    var defaultValues = {
        name: taskType === null || taskType === void 0 ? void 0 : taskType.attributes.name,
    };
    var form = useForm({
        shouldUnregister: true,
        defaultValues: defaultValues,
    });
    var register = form.register, errors = form.formState.errors, handleSubmit = form.handleSubmit;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('task-type-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement(Input, { register: register('name', {
                        required: true,
                    }), errorMessage: get(errors, 'name'), noMargin: true, withBorder: true, label: t(i18nKeys.TASK.ATTRIBUTES.TITLE) })),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.CANCEL), onClick: onCancel }),
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE) })))));
};
