import { __assign } from "tslib";
import React from 'react';
import { ExternalMailActionType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ExternalMailBatchActionsAssign } from './ExternalMailBatchActionsAssign';
import styleIdentifiers from './ExternalMailBatchActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailBatchActions = function (_a) {
    var external_mail_ids = _a.emailIds, onClick = _a.onClick, children = _a.children;
    var t = useTranslation().t;
    var handleAssign = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.EXTERNAL_MAIL.ACTIONS.ASSIGN),
            children: (React.createElement(ExternalMailBatchActionsAssign, { onSubmit: function (data) {
                    return onClick(__assign({ external_mail_ids: external_mail_ids, action_type: ExternalMailActionType.assign }, data));
                } })),
        });
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS["DELETE_EXTERNAL_MAIL".concat(external_mail_ids.length > 1 ? 'S' : '')]), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return onClick({
                        external_mail_ids: external_mail_ids,
                        action_type: ExternalMailActionType.delete,
                    });
                } })),
        });
    };
    return (React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.OTHER_ACTIONS), selectorContent: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.FORM.ACTIONS), className: styles('button'), color: ButtonColor.BLUE, iconRight: IconName.MINIMAL_DOWN }) },
        Object.keys(ExternalMailActionType).map(function (key) { return (React.createElement("div", { key: key, className: styles('dropdown-item'), onClick: function () {
                switch (key) {
                    case ExternalMailActionType.assign:
                        handleAssign();
                        break;
                    case ExternalMailActionType.delete:
                        handleDelete();
                        break;
                    default:
                        onClick({
                            external_mail_ids: external_mail_ids,
                            action_type: ExternalMailActionType[key],
                        });
                        break;
                }
            } }, t(i18nKeys.EXTERNAL_MAIL.ACTIONS[key.toUpperCase()]))); }),
        children));
};
