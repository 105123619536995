import { __assign, __generator, __rest } from "tslib";
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { downloadFile, extractFilename } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { api } from 'store/apis';
import makeApiCall, { sendApi } from '../sagas';
import { settingsActions, settingsConstants } from './settings.actions';
function previewTemplate(_a) {
    var request, filename;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (payload.download) {
                    payload.noFeedback = true;
                    payload.errors = 'ERROR.DOC_NOT_AVAILABLE';
                }
                return [4 /*yield*/, call(makeApiCall, __assign({ api: api.template.preview }, payload))];
            case 1:
                request = _b.sent();
                if (payload.data.template_type === 'post' &&
                    request &&
                    request.status === 200 &&
                    payload.download) {
                    filename = extractFilename(request.headers);
                    downloadFile(request.data, filename || "template'}");
                }
                return [2 /*return*/];
        }
    });
}
function settingsWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(settingsConstants.editDebtCollection.request, sendApi(api.settings.editDebtCollection, accountActions.getCompanyRes))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(settingsConstants.addTwikey.request, sendApi(api.settings.addTwikey))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                // template
                return [4 /*yield*/, takeLatest(settingsConstants.templateListPage.request, sendApi(api.template.list, settingsActions.templateListPageRes, function (_a) {
                        var callback = _a.callback, payload = __rest(_a, ["callback"]);
                        return ({
                            data: payload,
                            callback: callback,
                            noLoading: true,
                        });
                    }))];
            case 3:
                _b = _b.concat([
                    // template
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.templateList.request, sendApi(api.template.list, undefined))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.getTemplate.request, sendApi(api.template.detail))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.addTemplate.request, sendApi(api.template.add))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.setTemplate.request, sendApi(api.template.update, settingsActions.getTemplateRes))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.deleteTemplate.request, sendApi(api.template.delete))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.duplicateTemplate.request, sendApi(api.template.duplicate))];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.previewTemplate.request, previewTemplate)];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                // recovery plan
                return [4 /*yield*/, takeLatest(settingsConstants.recoveryPlanListPage.request, sendApi(api.recoveryPlan.list, settingsActions.recoveryPlanListPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 11:
                _b = _b.concat([
                    // recovery plan
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.recoveryPlanList.request, sendApi(api.recoveryPlan.list, undefined))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(settingsConstants.getRecoveryPlan.request, sendApi(api.recoveryPlan.detail))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.addRecoveryPlan.request, sendApi(api.recoveryPlan.add))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.setRecoveryPlan.request, sendApi(api.recoveryPlan.update))];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.deleteRecoveryPlan.request, sendApi(api.recoveryPlan.delete))];
            case 16:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.duplicateRecoveryPlan.request, sendApi(api.recoveryPlan.duplicate))];
            case 17:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.getPlansInvoices.request, sendApi(api.recoveryPlan.getPlansInvoices))];
            case 18:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.switchPlansInvoices.request, sendApi(api.recoveryPlan.switchPlansInvoices, settingsActions.switchPlansInvoicesRes))];
            case 19:
                _b = _b.concat([
                    _c.sent()
                ]);
                // custom variable
                return [4 /*yield*/, takeLatest(settingsConstants.customVariableListPage.request, sendApi(api.customVariable.list, settingsActions.customVariableListPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 20:
                _b = _b.concat([
                    // custom variable
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.customVariableList.request, sendApi(api.customVariable.list, undefined))];
            case 21:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.addCustomVariable.request, sendApi(api.customVariable.add, settingsActions.customVariableListPage))];
            case 22:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.setCustomVariable.request, sendApi(api.customVariable.update, settingsActions.customVariableListPage))];
            case 23:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(settingsConstants.deleteCustomVariable.request, sendApi(api.customVariable.delete, settingsActions.customVariableListPage))];
            case 24: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 25:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(settingsWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
