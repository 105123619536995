import { __assign, __read } from "tslib";
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ExportCsv } from 'shared/components/ExportCsv';
import { appActions } from 'store/app/app.actions';
import { backgroundLoaderHide } from 'store/view/view.actions';
export function ExportCreditNoteCsv() {
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _a = __read(useState({
        exportFields: constants.credit_note_export_columns.debtor_fields.concat(constants.credit_note_export_columns.credit_note_fields),
        wrappers: [
            {
                name: 'selectInvoice',
                fields: constants.credit_note_export_columns.credit_note_fields,
                text: t(i18nKeys.CREDIT_NOTE),
            },
            {
                name: 'selectDebtor',
                fields: constants.credit_note_export_columns.debtor_fields,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.CLIENT_DATA),
            },
        ],
    }), 1), _b = _a[0], exportFields = _b.exportFields, wrappers = _b.wrappers;
    var downloadCsv = function (data) {
        appActions.export({
            data: __assign(__assign(__assign({}, data), (data.export_with_filters === true ? qs.parse(location.search) : {})), { view_id: location.hash.slice(1), resource_type: 'credit_note', type: 'csv' }),
            noLoading: true,
            actionFailure: function () {
                backgroundLoaderHide();
            },
            callback: function () {
                backgroundLoaderHide();
            },
        });
    };
    return (React.createElement(ExportCsv, { downloadCsv: downloadCsv, allFields: exportFields, wrappers: wrappers, selectAllLabel: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_CREDIT_NOTE), selectFilteredLabel: t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_CREDIT_NOTE') }));
}
