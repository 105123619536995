import { __awaiter, __generator, __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { Button, Checkbox } from 'shared/io';
import { isProduction } from 'shared/utils/environment';
import { useGetInvoiceState } from 'shared/utils/selectors';
import { config } from 'store/constants';
import styleIdentifiers from './confirmBailiffForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ConfirmBailiffForm(_a) {
    var _this = this;
    var _b;
    var onSubmit = _a.onSubmit, bailiffId = _a.bailiffId;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = __read(useState(false), 2), isConfirmationStep = _d[0], setIsConfirmationStep = _d[1];
    var isLoading = useGetInvoiceState().isLoading;
    var _e = useForm({ shouldUnregister: true }), register = _e.register, watch = _e.watch, errors = _e.formState.errors, getValues = _e.getValues, trigger = _e.trigger;
    var bailiffCgLink = isProduction() ? config.bailiffTerms[bailiffId][currentLang] : '';
    var submit = function () { return __awaiter(_this, void 0, void 0, function () {
        var isFormValid, formValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isFormValid = _a.sent();
                    if (isFormValid) {
                        formValues = getValues();
                        if (isConfirmationStep || formValues.notContested) {
                            onSubmit(formValues);
                        }
                        else {
                            setIsConfirmationStep(true);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleConfirmationStep = function () {
        setIsConfirmationStep(!isConfirmationStep);
    };
    return isConfirmationStep ? (React.createElement(AYSModal, { text: t(i18nKeys.FORM.CONFIRM_BAILIFF.AYS_SEND_CONTESTED_INVOICE), onCancel: toggleConfirmationStep, onConfirm: submit })) : (React.createElement("form", { className: styles('ConfirmBailiffForm') },
        React.createElement("div", { className: styles('wrapper') },
            React.createElement("div", { className: styles('text'), 
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML: { __html: t(i18nKeys.FORM.CONFIRM_BAILIFF.CHECK_INCONTESTED) } }),
            React.createElement(Checkbox, { watch: watch, label: t(i18nKeys.FORM.CONFIRM_BAILIFF.INVOICE_UNCONTESTABLE), register: register('notContested') }),
            React.createElement(Checkbox, { watch: watch, register: register('user.has_accepted_gc', { required: true }), errorMessage: (_b = errors.user) === null || _b === void 0 ? void 0 : _b.has_accepted_gc },
                React.createElement("span", null,
                    t(i18nKeys.FORM.CONFIRM_BAILIFF.READ_AND_APPROVE),
                    ' ',
                    React.createElement("a", { href: bailiffCgLink, target: "_blank", rel: "noopener noreferrer" }, t(i18nKeys.FORM.GENERAL_CONDITIONS_OF_USE)))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.CONFIRM), className: styles('button'), onClick: submit })))));
}
