import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useTenant } from 'app/TenantValidator';
import { motion } from 'framer-motion';
import { useBootIntercom, useInitialisePosthog } from 'init';
import { useAtomValue } from 'jotai';
import { Redirect, useHistory } from 'react-router';
import { isDefined, isNonNullish, isNullish } from 'remeda';
import { profileAtom, useEnforceProfile, useLoadCompany, useLoadCompanyV1, useLoadPrivateConstants, } from 'shared/hooks';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingScreen } from './LoadingScreen';
var _PrivateLoader = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var tenant = useTenant();
    // These are the 4 requests that must complete before the app can be considered loaded and children rendered
    var _b = useEnforceProfile(), profile = _b.profile, profileError = _b.profileError;
    var _c = useLoadPrivateConstants(), privateConstantsError = _c.privateConstantsError, isLoadingPrivateConstants = _c.isLoadingPrivateConstants;
    var _d = useLoadCompany(), isCompanyV2Loading = _d.isCompanyLoading, companyV2Error = _d.companyError;
    var isCompanyV1Loaded = useLoadCompanyV1().isCompanyV1Loaded;
    var profileFromAtom = useAtomValue(profileAtom);
    useInitialisePosthog();
    useBootIntercom();
    var _e = __read(useState(false), 2), isAnimationDone = _e[0], setIsAnimationDone = _e[1];
    useEffect(function () {
        setTimeout(function () {
            setIsAnimationDone(true);
        }, 1000); // Must be synced with the animation duration in the LoadingScreen component
    }, []);
    useEffect(function () {
        if (isDefined(profile) &&
            !profile.hasAcceptedGc &&
            !window.location.pathname.includes('terms')) {
            history.push('/terms-and-conditions');
        }
        else if (!tenant.onboarding.isComplete && !window.location.pathname.includes('onboarding')) {
            history.push('/onboarding');
        }
    }, [profile === null || profile === void 0 ? void 0 : profile.hasAcceptedGc, tenant.onboarding.isComplete]);
    var isAppLoading = !isCompanyV1Loaded ||
        isCompanyV2Loading ||
        isLoadingPrivateConstants ||
        isNullish(profileFromAtom);
    var hasLoadingFailed = isNonNullish(companyV2Error) ||
        isNonNullish(profileError) ||
        isNonNullish(privateConstantsError);
    if (hasLoadingFailed)
        return React.createElement(Redirect, { to: "/server-error" });
    if (isAppLoading || !isAnimationDone)
        return React.createElement(LoadingScreen, null);
    return (React.createElement(motion.div, { initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 } }, children));
};
export var PrivateLoader = withAuthenticationRequired(_PrivateLoader);
