import React from 'react';
import { useDeleteTaskType, useUpdateTaskType } from 'api';
import { TaskTypeForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './TaskTypeListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskTypeListItem = function (_a) {
    var item = _a.item, refetch = _a.refetch;
    var t = useTranslation().t;
    var deleteTaskType = useDeleteTaskType().mutate;
    var updateTaskType = useUpdateTaskType().mutate;
    var id = item.id, _b = item.attributes, name = _b.name, is_deletable = _b.is_deletable;
    var handleUpdate = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(item ? i18nKeys.EDIT_TASK_TYPE : i18nKeys.ADD_TASK_TYPE),
            children: (React.createElement(TaskTypeForm, { taskType: item, onCancel: function () { return dialogHide(DialogShowId.CUSTOM); }, onSubmit: function (data) {
                    return updateTaskType({ id: id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            dialogHide(DialogShowId.CUSTOM);
                        },
                    });
                } })),
        });
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_TASK_TYPE), onConfirm: function () {
                    deleteTaskType({ id: id }, {
                        onSuccess: function () {
                            refetch();
                            dialogHide(DialogShowId.CONFIRM);
                        },
                    });
                } })),
        });
    };
    return (React.createElement("tr", { className: styles('custom-variable-item') },
        React.createElement("td", null),
        React.createElement("td", null, id),
        React.createElement("td", null, name),
        React.createElement("td", null, t(!is_deletable
            ? i18nKeys.SETTINGS.TASKS.TASK_DEFAULT
            : i18nKeys.SETTINGS.TASKS.TASK_CUSTOM)),
        React.createElement("td", { className: styles('actions') }, is_deletable && (React.createElement(React.Fragment, null,
            React.createElement("span", { onClick: handleUpdate },
                React.createElement(Icon, { name: IconName.PENCIL, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: handleDelete },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))))));
};
