import { __assign, __rest } from "tslib";
import Axios from 'axios';
import apiService from 'shared/service/api.service';
import { error as onError, success as onSuccess } from 'store/view/view.actions';
var baseUrl = apiService.baseUrl;
// #region Callbacks
var onFulfilled = function (response) {
    var _a;
    if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) {
        onSuccess({
            data: response.data.message,
        });
    }
    return response;
};
var onRejected = function (error) {
    var _a, _b, _c, _d, _e;
    if (Axios.isAxiosError(error)) {
        onError({
            data: 
            // @ts-ignore
            ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_message) ||
                (
                // @ts-ignore
                (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) ||
                ((_e = error.response) === null || _e === void 0 ? void 0 : _e.statusText),
        });
    }
    return Promise.reject(error);
};
// #region Instances
export var ORVAL_AXIOS_INSTANCE = Axios.create({
    baseURL: baseUrl,
});
ORVAL_AXIOS_INSTANCE.interceptors.response.use(onFulfilled, onRejected);
export var customInstance = function (_a) {
    var headers = _a.headers, config = __rest(_a, ["headers"]);
    var promise = ORVAL_AXIOS_INSTANCE(__assign({ headers: __assign(__assign({}, headers), { tenant: apiService.tenant }) }, config)).then(function (_a) {
        var data = _a.data;
        return data;
    });
    return promise;
};
export default customInstance;
