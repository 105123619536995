import React from 'react';
import { PaymentPlanDetail } from 'app/Private/PaymentPlans';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import AddressBlock from 'shared/components/AddressBlock';
import BankAccount from 'shared/components/BankAccount';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import CopyToClipboard from 'shared/components/CopyToClipboard';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import TvaNumber from 'shared/components/TvaNumber';
import { useProfile } from 'shared/hooks';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './TabInfoDebtor.scss';
var styles = classNames.bind(styleIdentifiers);
export var TabInfoDebtor = function (_a) {
    var debtor = _a.debtor, reload = _a.reload;
    var _b = useTranslation(), t = _b.t, availableLang = _b.availableLang, currentLang = _b.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var profile = useProfile();
    var editAccountManager = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
            children: (React.createElement(ChooseCollaboratorModal, { initialValues: debtor.account_manager, onSubmit: function (values) {
                    clientActions.assignToAccManager({
                        id: debtor.id,
                        data: values,
                        callback: function () {
                            reload();
                            dialogHide(DialogShowId.CUSTOM);
                        },
                    });
                } })),
        });
    };
    var goToPlan = function (planId) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
            children: React.createElement(PaymentPlanDetail, { planId: planId, inModal: true }),
        });
    };
    return (React.createElement("div", { className: styles('ClientInfo', 'card') },
        React.createElement("h3", null,
            t(i18nKeys.CLIENT.DETAIL.INFORMATIONS),
            " "),
        React.createElement("div", { className: styles('info-wrapper') },
            React.createElement("div", { className: styles('left') },
                debtor.external_id && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.EXTERNAL_ID)),
                    React.createElement("span", { className: styles('value') }, debtor.external_id))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        " ",
                        t(i18nKeys.ADDRESS)),
                    React.createElement(AddressBlock, { className: styles('value'), address: debtor.address_attributes })),
                debtor.vat_number && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.VAT_NUMBER)),
                    React.createElement(TvaNumber, { className: styles('value'), value: debtor.vat_number }))),
                debtor.currency && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.CURRENCY)),
                    React.createElement("div", { className: styles('value') }, debtor.currency))),
                debtor.reference && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.REFERENCE)),
                    React.createElement("div", { className: styles('value') }, debtor.reference))),
                debtor.debtor_bank_accounts_attributes &&
                    debtor.debtor_bank_accounts_attributes.length > 0 && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMON.BANK_ACCOUNT)),
                    debtor.debtor_bank_accounts_attributes.map(function (bankAccount) { return (React.createElement("div", { className: styles('value'), key: bankAccount.id },
                        React.createElement(BankAccount, { className: styles('iban'), value: bankAccount.iban }),
                        bankAccount.bic && (React.createElement("div", { className: styles('bic') }, "BIC : ".concat(bankAccount.bic))))); }))),
                debtor.default_invoice_template && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEFAULT_INVOICE_TEMPLATE)),
                    React.createElement("div", { className: styles('value') }, debtor.default_invoice_template.name))),
                debtor.default_credit_note_template && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEFAULT_CN_TEMPLATE)),
                    React.createElement("div", { className: styles('value') }, debtor.default_credit_note_template.name)))),
            React.createElement("div", { className: styles('right') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.EMAIL)),
                    debtor.emails.map(function (email) { return (React.createElement("a", { key: email, href: "mailto:".concat(email), className: styles('value', 'block') },
                        React.createElement("span", null, email))); })),
                debtor.locale != null && availableLang != null && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.CLIENT.CONTACT_LANG)),
                    React.createElement("span", { className: styles('value') }, availableLang.find(function (lang) { return lang.value === debtor.locale; }).description))),
                debtor.phones_attributes.length > 0 && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PHONES)),
                    debtor.phones_attributes.map(function (phone, index) { return (React.createElement("a", { key: index, href: "tel:".concat(phone.number), className: styles('value') },
                        React.createElement("div", { className: styles('value') }, phone.number))); }))),
                debtor.co_contractor && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.DETAIL.COCONTRACTOR)),
                    React.createElement("div", { className: styles('value') }, t(i18nKeys.CLIENT.DETAIL.APPLIED_VAT_0)))),
                debtor.intracom && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        " ",
                        t(i18nKeys.INVOICE.INTRACOMMUNITY)),
                    React.createElement("div", { className: styles('value') }, t(i18nKeys.CLIENT.DETAIL.APPLIED_VAT_0)))),
                debtor.sending_media_attributes.length > 0 && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.SENDING_MEDIUMS)),
                    React.createElement("div", { className: styles('value') }, debtor.sending_media_attributes.map(function (sendingMedium, index) {
                        return "".concat(index !== 0 ? ' - ' : '').concat(constants.sending_medium.find(function (item) { return sendingMedium.name === item.value; })
                            .description);
                    })))),
                debtor.recovery_plan && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        " ",
                        t(i18nKeys.RECOVERY_PLAN)),
                    React.createElement("div", { className: styles('value') }, debtor.recovery_plan.name))),
                debtor.days_between_reminder && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        ' ',
                        t(i18nKeys.CLIENT.DETAIL.MIN_DAYS_BETWEEN_REMINDER)),
                    React.createElement("div", { className: styles('value') }, t(i18nKeys.DAY_S, { count: debtor.days_between_reminder })))),
                debtor.minimum_saldo_sending !== 0 && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        ' ',
                        t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
                            text: currencySymbol(undefined, company),
                        })),
                    React.createElement("div", { className: styles('value') }, formatAmount(debtor.minimum_saldo_sending))))),
            React.createElement("div", { className: styles('right') },
                debtor.has_payment_plan && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)),
                    debtor.payment_plans_attributes.map(function (payment_plan) { return (React.createElement("div", { key: payment_plan.id, className: styles('value', 'payment-plan') },
                        React.createElement("p", null,
                            "#",
                            payment_plan.id),
                        React.createElement(Icon, { name: IconName.EXTERNAL_LINK, color: IconColor.BLUE, onClick: function () { return goToPlan(payment_plan.id); } }))); }))),
                debtor.paused && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('subtitle-value') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.PAUSED_REASON)),
                        React.createElement("div", { className: styles('value') }, debtor.reason)),
                    debtor.end_pause_at && (React.createElement("div", { className: styles('subtitle-value') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.END_OF_PAUSE)),
                        React.createElement("div", { className: styles('value') }, formattedDate(new Date(debtor.end_pause_at), currentLang)))))),
                debtor.disputed && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.DISPUTED_REASON)),
                    React.createElement("div", { className: styles('value') }, debtor.reason))),
                debtor.csv_id && (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, "csv id"),
                    React.createElement("div", { className: styles('value') }, debtor.csv_id))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.ACCOUNT_MANAGER)),
                    React.createElement("div", { className: styles('value') },
                        debtor.account_manager
                            ? "".concat(debtor.account_manager.first_name, " ").concat(debtor.account_manager.last_name)
                            : t(i18nKeys.NOT_ASSIGNED),
                        profile.isAdmin && (React.createElement(Icon, { name: IconName.PENCIL, className: styles('ml-10'), onClick: editAccountManager })))),
                debtor.debtor_reaction_link && (React.createElement("div", { className: styles('bottom') },
                    React.createElement(CopyToClipboard, { textToCopy: debtor.debtor_reaction_link, text: t(i18nKeys.CLIENT.PORTAIL_LINK) })))))));
};
