import { __read, __spreadArray } from "tslib";
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WorkflowStepsTarget } from '../../Workflows.constants';
import styleIdentifiers from './WorkflowFormSteps.scss';
import { WorkflowFormStepsItemDetailReminder } from './WorkflowFormStepsItemDetailReminder';
import { WorkflowFormStepsItemDetailTask } from './WorkflowFormStepsItemDetailTask';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsItemDetail = function (_a) {
    var name = _a.name, index = _a.index, children = _a.children;
    var _b = useFormContext(), watch = _b.watch, register = _b.register, setValue = _b.setValue;
    var _c = watch("".concat(name, ".").concat(index)), step_type = _c.step_type, template = _c.template, sending_media = _c.sending_media, task_attributes = _c.task_attributes;
    var order = (name === WorkflowStepsTarget.AFTER_DUE_DATE
        ? __spreadArray([], __read(watch(WorkflowStepsTarget.BEFORE_DUE_DATE)), false).length + index
        : index) + 1;
    register("".concat(name, ".").concat(index, ".order"));
    useEffect(function () {
        setValue("".concat(name, ".").concat(index, ".order"), order - 1);
    }, [name, index, order, setValue]);
    return (React.createElement("article", { className: styles('workflows-form-step') },
        React.createElement("div", { className: styles('order') },
            React.createElement("span", null, order)),
        template && (React.createElement(WorkflowFormStepsItemDetailReminder, { step_type: step_type, template: template, sending_media: sending_media })),
        task_attributes && React.createElement(WorkflowFormStepsItemDetailTask, { task: task_attributes }),
        React.createElement("div", { className: styles('actions') }, children)));
};
