import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getInvoiceFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { invoiceActions } from 'store/invoice/invoice.actions';
import styleIdentifiers from './manage.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ManageInvoice(_a) {
    var params = _a.match.params;
    var invoice = useSelector(function (state) { var _a; return ((_a = state.invoice.current) === null || _a === void 0 ? void 0 : _a.data) || undefined; });
    useEffect(function () {
        if (params.id) {
            invoiceActions.detail({ id: Number(params.id) });
        }
    }, [params]);
    if (!params.id || !invoice) {
        return (React.createElement("div", { className: styles('ManageCreditNote') },
            React.createElement(InvoiceForm, { initialValues: getInvoiceFormValues(), invoice: invoice, submit: function (data) { return invoiceActions.add({ data: data }); } })));
    }
    return (React.createElement("div", { className: styles('ManageInvoice') },
        React.createElement(InvoiceForm, { initialValues: getInvoiceFormValues(invoice), invoice: invoice, submit: function (data) {
                return invoiceActions.update({
                    id: invoice.id,
                    data: data,
                });
            } })));
}
