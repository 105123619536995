function isExcluded(path, excludedPaths) {
    return excludedPaths.some(function (excluded) { return path.startsWith(excluded); });
}
function isEmpty(value) {
    return value === null || value === '' || (Array.isArray(value) && value.length === 0);
}
export function countActiveFilters(filters, excludedPaths) {
    if (excludedPaths === void 0) { excludedPaths = []; }
    var count = 0;
    function recurse(current, path) {
        if (typeof current !== 'object' || current === null || current instanceof Date) {
            if (!isExcluded(path, excludedPaths) && !isEmpty(current)) {
                count++;
            }
            return;
        }
        Object.keys(current).forEach(function (key) {
            if (Object.prototype.hasOwnProperty.call(current, key)) {
                var newPath = path ? "".concat(path, ".").concat(key) : key;
                recurse(current[key], newPath);
            }
        });
    }
    recurse(filters, '');
    return count;
}
