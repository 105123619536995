import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecalculateDatesModal from 'shared/components/RecalculateDatesModal';
import { Button, ButtonColor, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { adjustNextAmounts, adjustNextDates } from '../PaymentPlan.utils';
import { PaymentPlanFormStepsProperties } from './PaymentPlanFormStepsProperties';
import { PaymentPlanFormWrapper } from './PaymentPlanFormWrapper';
import styleIdentifiers from './PaymentPlanForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanFormSteps = function (_a) {
    var recalculateSteps = _a.recalculateSteps, paymentProperties = _a.paymentProperties, paymentSteps = _a.paymentSteps, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var canUseAdvancedPaymentPlans = useSelector(function (state) { return state.account.company.data; }).package.can_use_advanced_payment_plans;
    var due_date = paymentProperties.due_date, delay = paymentProperties.delay;
    var debtor = paymentSteps.debtor, payment_steps_attributes = paymentSteps.payment_steps_attributes, total_amount = paymentSteps.total_amount;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            payment_steps_attributes: payment_steps_attributes,
        },
    });
    var control = form.control, errors = form.formState.errors, watch = form.watch, register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, getValues = form.getValues, reset = form.reset;
    var _b = useFieldArray({
        control: control,
        name: 'payment_steps_attributes',
    }), append = _b.append, fields = _b.fields, remove = _b.remove;
    var steps = watch('payment_steps_attributes') || [];
    var stepsTotal = Math.round(steps.reduce(function (acc, _a) {
        var amount = _a.amount;
        return acc + Number(amount);
    }, 0) * 100) / 100;
    var doAmountsMatch = Number(total_amount) === stepsTotal;
    var getMinDate = function (index) {
        var lastStep = steps[index - 1];
        if (isDefined(lastStep))
            return dayjs(lastStep.due_date).add(1, 'day').toDate();
        return dayjs(due_date).toDate();
    };
    var askRecalculation = function (index) {
        if (index === fields.length - 1)
            return;
        var _a = getValues(), currentSteps = _a.payment_steps_attributes, rest = __rest(_a, ["payment_steps_attributes"]);
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            children: (React.createElement(RecalculateDatesModal, { text: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_DATES), initialDelay: paymentProperties.delay, onConfirm: function (newDelay) {
                    reset(__assign(__assign({}, rest), { payment_steps_attributes: adjustNextDates(currentSteps, index, newDelay) }));
                } })),
        });
    };
    var handleAdjustNextAmounts = function (index) {
        var _a = getValues(), currentSteps = _a.payment_steps_attributes, rest = __rest(_a, ["payment_steps_attributes"]);
        reset(__assign(__assign({}, rest), { payment_steps_attributes: adjustNextAmounts(currentSteps, index, Number(total_amount || 0)) }));
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(PaymentPlanFormWrapper, { actions: React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.MAIN, disabled: !doAmountsMatch, noMargin: true }) },
                React.createElement(PaymentPlanFormStepsProperties, { recalculateSteps: recalculateSteps, paymentProperties: paymentProperties, paymentSteps: paymentSteps }),
                React.createElement(Card, { className: styles('steps-details'), title: t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS), infosRight: React.createElement(Button, { small: true, noMargin: true, noShadow: true, title: !canUseAdvancedPaymentPlans ? t(i18nKeys.NOT_INCLUDED) : '', label: t(i18nKeys.ACTIONS.ADD), iconRight: IconName.PLUS, disabled: !canUseAdvancedPaymentPlans, onClick: function () {
                            return append({
                                amount: Math.round((Number(total_amount) - stepsTotal) * 100) / 100,
                                due_date: dayjs(steps[steps.length - 1].due_date)
                                    .add(delay, 'day')
                                    .toISOString(),
                            });
                        } }) },
                    React.createElement("div", { className: styles('headers') },
                        React.createElement("div", { className: styles('header') }, t(i18nKeys.DATE)),
                        React.createElement("div", { className: styles('header') }, t(i18nKeys.AMOUNT_CURRENCY, {
                            text: currencySymbol(debtor.attributes.currency),
                        })),
                        React.createElement("div", { className: styles('header') }, " ")),
                    React.createElement("div", { className: styles('steps') }, fields.map(function (field, index) {
                        register("payment_steps_attributes.".concat(index, ".due_date"));
                        return (React.createElement("div", { key: field.id, className: styles('step') },
                            React.createElement(DateSelector, { required: true, name: "payment_steps_attributes.".concat(index, ".due_date"), className: styles('due-date-input'), minDate: getMinDate(index), noMargin: true, noBorder: true, disabled: !canUseAdvancedPaymentPlans, withBorder: canUseAdvancedPaymentPlans, centered: true, handleChange: function (value) {
                                    setValue("payment_steps_attributes.".concat(index, ".due_date"), value);
                                    askRecalculation(index);
                                } }),
                            React.createElement(Input, { className: styles('amount-input'), disabled: !canUseAdvancedPaymentPlans, withBorder: canUseAdvancedPaymentPlans, register: register("payment_steps_attributes.".concat(index, ".amount"), {
                                    required: true,
                                    min: 0.01,
                                }), errorMessage: get(errors, "payment_steps_attributes.".concat(index, ".amount")), noMargin: true, type: "number", step: "0.01" }),
                            React.createElement("div", { className: styles('icons-wrapper') }, canUseAdvancedPaymentPlans && fields.length > 1 && (React.createElement(React.Fragment, null,
                                index !== fields.length - 1 ? (React.createElement(Icon, { title: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_AMOUNTS), name: IconName.MINIMAL_DOWN, onClick: function () { return handleAdjustNextAmounts(index); } })) : (React.createElement("span", { className: styles('empty-icon') })),
                                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function () { return remove(index); } }))))));
                    })),
                    React.createElement("div", { className: styles('steps-footer') },
                        React.createElement("div", { className: styles('total', doAmountsMatch ? 'green' : 'red') },
                            React.createElement(Amount, { value: stepsTotal, suffix: debtor.attributes.currency }),
                            React.createElement(React.Fragment, null, doAmountsMatch ? ('') : (React.createElement(Amount, { value: Math.round((stepsTotal - Number(total_amount)) * 100) / 100, suffix: debtor.attributes.currency })))))),
                React.createElement("div", { className: styles('bottom-actions') },
                    React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.MAIN, disabled: !doAmountsMatch, noMargin: true }))))));
};
