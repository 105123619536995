import { __assign } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import HTML from 'shared/components/HTML/HTML';
import { Icon } from 'shared/components/Icon';
import { Dropdown } from 'shared/io';
import styleIdentifiers from './tooltip.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Tooltip(props) {
    var arrow = props.arrow, direction = props.direction, contentClassName = props.contentClassName, children = props.children, text = props.text, item = props.item, icon = props.icon, iconClassName = props.iconClassName, size = props.size, color = props.color, _a = props.shouldExist, shouldExist = _a === void 0 ? true : _a, style = props.style, onClick = props.onClick;
    var element = item;
    if ((!element && icon) || iconClassName) {
        element = (React.createElement(Icon, { onClick: onClick, name: icon, size: size, color: color, className: iconClassName }));
    }
    if (!shouldExist) {
        return React.createElement("div", { style: style }, element);
    }
    return (React.createElement(Dropdown, __assign({ containerStyle: style, selectorContent: element }, props, { noArrow: !arrow, direction: direction, hover: true }),
        React.createElement("div", { className: styles('tooltip', contentClassName) },
            text && React.createElement(HTML, { html: text }),
            children)));
}
