import { i18nKeys, useTranslation } from 'locales';
import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import CustomTable from 'shared/components/CustomTable';
import { getLinkInvoiceFormFieldLine } from './LinkInvoiceForm.utils';
import { LinkInvoiceFormTableItem } from './LinkInvoiceFormTableItem';
var GroupedTable = CustomTable(LinkInvoiceFormTableItem);
export var LinkInvoiceFormTable = function (_a) {
    var creditNote = _a.creditNote, invoices = _a.invoices, className = _a.className, reload = _a.reload;
    var t = useTranslation().t;
    var headers = [
        {
            title: t(i18nKeys.FORM.REFERENCE),
        },
        {
            title: t(i18nKeys.ISSUE_DATE),
        },
        {
            title: t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT),
        },
        {
            title: t(i18nKeys.LATE_FEES_INCLUDED),
        },
        {
            title: '',
        },
        {
            title: t(i18nKeys.AMOUNT),
        },
    ];
    var _b = useFieldArray({
        name: 'invoices_to_link',
    }), fields = _b.fields, replace = _b.replace;
    useEffect(function () {
        replace(invoices.map(function (e) { return getLinkInvoiceFormFieldLine(e, creditNote.matched_payments_attributes); }));
    }, [invoices, creditNote, replace]);
    return (React.createElement(GroupedTable, { className: className, items: fields, itemProps: { creditNote: creditNote, invoices: invoices, reload: reload }, noShadow: true, noMargin: true, noCheckbox: true, headers: headers }));
};
