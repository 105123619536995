import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import HTML from 'shared/components/HTML/HTML';
import { UpdateProfileFormSchema, useProfile, useUpdateProfile, } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { createAreFieldsDirty, createAreFieldsValid, createGetDirtyFields } from 'shared/utils';
import { Avatar, Box, Fieldset, Flex, Grid, Group, Select, Switch, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { MantineSettingsCard } from './MantineSettingsCard';
import SignatureEditorModal from './SignatureEditorModal';
function profileToProfileForm(profile) {
    return {
        firstName: profile.name.first,
        lastName: profile.name.last,
        email: profile.email,
        locale: profile.locale,
        pageLimit: profile.preferences.itemsPerPage,
        receiveDailyEmails: profile.preferences.emails.daily,
        receiveWeeklyEmails: profile.preferences.emails.weekly,
        receiveNotificationEmails: profile.preferences.emails.notifications,
    };
}
export var UserSettings = function () {
    var _a;
    var _b = useTranslation(), t = _b.t, availableLang = _b.availableLang;
    var profile = useProfile();
    var updateProfile = useUpdateProfile().updateProfile;
    var _c = __read(useDisclosure(), 2), isSignatureModalOpen = _c[0], _d = _c[1], openModal = _d.open, closeModal = _d.close;
    var form = useForm({
        validate: zodResolver(UpdateProfileFormSchema),
        validateInputOnBlur: true,
    });
    useEffect(function () {
        form.setInitialValues(profileToProfileForm(profile));
        form.setValues(profileToProfileForm(profile));
    }, [JSON.stringify(profile)]);
    var areFieldsDirty = createAreFieldsDirty(form);
    var getDirtyFields = createGetDirtyFields(form);
    var areFieldsValid = createAreFieldsValid(form);
    var personalInformationFields = [
        'firstName',
        'lastName',
        'email',
    ];
    var personalPreferencesFields = [
        'locale',
        'pageLimit',
        'receiveDailyEmails',
        'receiveWeeklyEmails',
        'receiveNotificationEmails',
    ];
    var paginationOptions = [
        { label: "10 ".concat(t(i18nKeys.PER_PAGE)), value: '10' },
        { label: "20 ".concat(t(i18nKeys.PER_PAGE)), value: '20' },
        { label: "50 ".concat(t(i18nKeys.PER_PAGE)), value: '50' },
        { label: "100 ".concat(t(i18nKeys.PER_PAGE)), value: '100' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.PROFILE.EDIT)),
        React.createElement(Grid, { pb: "lg", pr: "lg" },
            React.createElement(Grid.Col, { span: 5 },
                React.createElement(MantineSettingsCard, { title: t(i18nKeys.PERSONAL_INFO), onSave: areFieldsDirty.apply(void 0, __spreadArray([], __read(personalInformationFields), false)) && areFieldsValid.apply(void 0, __spreadArray([], __read(personalInformationFields), false))
                        ? function () { return updateProfile(getDirtyFields.apply(void 0, __spreadArray([], __read(personalInformationFields), false))); }
                        : undefined },
                    React.createElement(TextInput, __assign({ label: t(i18nKeys.FORM.FIRSTNAME), required: true }, form.getInputProps('firstName'))),
                    React.createElement(TextInput, __assign({ label: t(i18nKeys.FORM.LASTNAME), required: true, mt: "md" }, form.getInputProps('lastName'))),
                    React.createElement(TextInput, __assign({ label: t(i18nKeys.EMAIL), required: true, mt: "md" }, form.getInputProps('email'))))),
            React.createElement(Grid.Col, { span: 4 },
                React.createElement(MantineSettingsCard, { title: t(i18nKeys.SIGNATURE), onSave: openModal, saveButtonLabel: t(i18nKeys.EDIT) }, (profile === null || profile === void 0 ? void 0 : profile.signature) ? (React.createElement(HTML, { html: profile.signature })) : (React.createElement("div", null, t(i18nKeys.NO_SIGNATURE))))),
            React.createElement(Grid.Col, { span: 3 },
                React.createElement(Flex, { h: "100%", align: "center", justify: "flex-start", direction: "column", gap: "xl" },
                    React.createElement(Avatar, { variant: "light", size: "180px", radius: "100%", color: "blue", src: profile.profilePicture }, profile === null || profile === void 0 ? void 0 : profile.name.initials))),
            React.createElement(Grid.Col, { span: 12 },
                React.createElement(MantineSettingsCard, { title: t(i18nKeys.PERSONAL_PREFENCES), onSave: areFieldsDirty.apply(void 0, __spreadArray([], __read(personalPreferencesFields), false)) ? function () { return updateProfile(getDirtyFields.apply(void 0, __spreadArray([], __read(personalPreferencesFields), false))); }
                        : undefined },
                    React.createElement(Group, { justify: "space-between" },
                        React.createElement(Box, { w: "50%" },
                            React.createElement(Select, __assign({ label: t(i18nKeys.FORM.USER_SETTINGS.INTERFACE_LANG), data: availableLang.map(function (item) { return ({
                                    label: item.description,
                                    value: item.value,
                                }); }), allowDeselect: false, checkIconPosition: "right" }, form.getInputProps('locale'))),
                            React.createElement(Select, __assign({ label: t(i18nKeys.FORM.USER_SETTINGS.PAGINATION), allowDeselect: false, checkIconPosition: "right", data: paginationOptions, mt: "md" }, form.getInputProps('pageLimit'), { value: String(form.getInputProps('pageLimit').value) }))),
                        React.createElement(Fieldset, { legend: t(i18nKeys.SETTINGS.STAFF.RECEIVE_EMAIL), w: "40%" },
                            React.createElement(Switch, __assign({ size: "xs", label: t(i18nKeys.SETTINGS.STAFF.DAILY) }, form.getInputProps('receiveDailyEmails', { type: 'checkbox' }))),
                            React.createElement(Switch, __assign({ size: "xs", label: t(i18nKeys.SETTINGS.STAFF.WEEKLY), mt: "md" }, form.getInputProps('receiveWeeklyEmails', { type: 'checkbox' }))),
                            React.createElement(Switch, __assign({ size: "xs", label: t(i18nKeys.SETTINGS.STAFF.NOTIFICATION), mt: "md" }, form.getInputProps('receiveNotificationEmails', { type: 'checkbox' })))))))),
        React.createElement(SignatureEditorModal, { signature: (_a = profile === null || profile === void 0 ? void 0 : profile.signature) !== null && _a !== void 0 ? _a : null, isOpen: isSignatureModalOpen, onClose: closeModal, onSave: function (signature) { return updateProfile({ signature: signature }); } })));
};
