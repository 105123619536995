import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './FiltersSideMenu.scss';
var styles = classNames.bind(styleIdentifiers);
export default function FiltersSideMenu(_a) {
    var children = _a.children, onRemoveFilter = _a.onRemoveFilter;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('filters-side-menu') },
        React.createElement("div", { className: styles('head') }, t(i18nKeys.FILTER)),
        React.createElement("div", { className: styles('content') }, children),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { onClick: onRemoveFilter, color: ButtonColor.GREY, noMargin: true, label: t(i18nKeys.FORM.DELETE_FILTERS) }))));
}
