import { prop } from 'remeda';
// todo: https://stackoverflow.com/questions/40977713/how-to-forbid-a-specific-named-function-with-eslint
export function unimplemented() {
    prop(console, 'log')('Unimplemented');
}
export function unimplementedWith(returnVal) {
    prop(console, 'log')('Unimplemented');
    if (typeof returnVal === 'function')
        return returnVal();
    return returnVal;
}
