import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import styleIdentifiers from './Phones.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Phones(_a) {
    var className = _a.className, formArray = _a.formArray, setFormDirty = _a.setFormDirty;
    var t = useTranslation().t;
    var _b = useFormContext(), register = _b.register, reset = _b.reset, getValues = _b.getValues;
    var deleteLine = function (index) { return function () {
        var phones = formArray.slice();
        phones.splice(index, 1);
        if (phones.length === 0) {
            phones.push({ number: '' });
        }
        reset(__assign(__assign({}, getValues()), { phones_attributes: phones }));
        setFormDirty();
    }; };
    return (React.createElement("div", { className: styles('Phones') }, formArray === null || formArray === void 0 ? void 0 : formArray.map(function (item, index) {
        var _a;
        return (React.createElement("div", { className: styles('phone-item'), key: index },
            React.createElement(Input, { register: register("phones_attributes.".concat(index, ".number")), defaultValue: (_a = formArray[index]) === null || _a === void 0 ? void 0 : _a.number, className: className, label: t(i18nKeys.NUMBER), type: "text", withBorder: true }),
            React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteLine(index) })));
    })));
}
