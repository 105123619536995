import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { useHistory } from 'react-router';
import { isEmpty, times } from 'remeda';
import { useArchiveReport, useLoadReports } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { ActionIcon, Button, Card, Center, Group, Skeleton, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { PeriodicityLabel } from '../components';
export var ReportsIndex = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var reports = useLoadReports().reports;
    var _a = useArchiveReport(), archiveReport = _a.archiveReport, isArchiveReportLoading = _a.isArchiveReportLoading;
    var handleArchiveReport = function (e, id) {
        e.stopPropagation();
        modals.openConfirmModal({
            centered: true,
            title: t(i18nKeys.REPORTS.SHOW.DELETE_REPORT),
            children: React.createElement(Text, null, t(i18nKeys.REPORTS.SHOW.AYS_DELETE)),
            labels: { cancel: t(i18nKeys.CANCEL), confirm: t(i18nKeys.CONFIRM) },
            confirmProps: { color: 'red', loading: isArchiveReportLoading },
            onConfirm: function () { return archiveReport(id); },
            onCancel: modals.closeAll,
        });
    };
    var tableBody = reports
        ? reports.map(function (task, index) { return (React.createElement(Table.Tr, { key: index, style: { cursor: 'pointer' }, onClick: function () { return history.push("/reports/".concat(task.id)); } },
            React.createElement(Table.Td, null,
                React.createElement(Text, { fz: 14 }, upperFirst(task.name))),
            React.createElement(Table.Td, null,
                React.createElement(PeriodicityLabel, { type: task.periodicity.type })),
            React.createElement(Table.Td, null, task.lastExecutedAt ? (task.lastExecutedAt.format('dddd MMMM D, YYYY [at] hh:mm')) : (React.createElement(Text, { fz: 14, fs: "italic", c: "dimmed" }, t(i18nKeys.REPORTS.INDEX.EXECUTION_DATE.NOT_EXECUTED)))),
            React.createElement(Table.Td, null,
                React.createElement(Group, null,
                    React.createElement(ActionIcon, { onClick: function (e) { return handleArchiveReport(e, task.id); }, size: "input-xs", variant: "light", color: "red", h: "100%" },
                        React.createElement(IconTrash, { stroke: 1.5, size: 16 })))))); })
        : times(5, function (index) { return (React.createElement(Table.Tr, { key: index },
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: 20, mr: 15 }),
                React.createElement(Skeleton, { height: 20, w: 20 })))); });
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null,
            t(i18nKeys.REPORTS.INDEX.TITLE),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { onClick: function () {
                        localStorage.removeItem('persist-report-creation');
                        history.push('/reports/new');
                    }, rightSection: React.createElement(IconPlus, { stroke: 1.5 }) }, t(i18nKeys.REPORTS.NEW.ACTION_BUTTON)))),
        React.createElement(Card, { radius: "md", shadow: "xs", mt: "md", p: 0 }, isEmpty(tableBody) ? (React.createElement(Center, { h: 200 },
            React.createElement(Text, null, t(i18nKeys.REPORTS.SHOW.NO_REPORTS_YET)))) : (React.createElement(Table, { highlightOnHover: true, horizontalSpacing: "md", verticalSpacing: "sm" },
            React.createElement(Table.Thead, null,
                React.createElement(Table.Tr, { bg: "#fafafa", style: { borderBottom: '2px solid #e3e3e3' } },
                    React.createElement(Table.Th, null,
                        React.createElement(Text, { fz: 14, fw: 450 }, t(i18nKeys.REPORTS.INDEX.ROWS.NAME))),
                    React.createElement(Table.Th, null,
                        React.createElement(Text, { fz: 14, fw: 450 }, t(i18nKeys.REPORTS.INDEX.ROWS.PERIODICITY))),
                    React.createElement(Table.Th, null,
                        React.createElement(Text, { fz: 14, fw: 450 }, t(i18nKeys.REPORTS.INDEX.ROWS.EXECUTION_DATE))),
                    React.createElement(Table.Th, null,
                        React.createElement(Text, { fz: 14, fw: 450 }, t(i18nKeys.REPORTS.INDEX.ROWS.ACTIONS))))),
            React.createElement(Table.Tbody, null, isEmpty(tableBody) ? (React.createElement(Center, null,
                React.createElement(Text, { m: "lg" }, "Nope"))) : (tableBody)))))));
};
