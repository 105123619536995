import { __read } from "tslib";
import React, { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { concat, flat, map, pipe, prop, uniqueBy } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import styleIdentifiers from './ExternalMailAction.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailActionDebtor = function (_a) {
    var _b;
    var _c = _a.fieldName, fieldName = _c === void 0 ? 'debtor_id' : _c;
    var t = useTranslation().t;
    var form = useFormContext();
    var _d = __read(useState(), 2), selectSearchValue = _d[0], setSelectSearchValue = _d[1];
    var _e = useGetDebtorsInfinite({ name: selectSearchValue }, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), debtorsResponse = _e.data, fetchNextPage = _e.fetchNextPage;
    var pages = (_b = debtorsResponse === null || debtorsResponse === void 0 ? void 0 : debtorsResponse.pages) !== null && _b !== void 0 ? _b : [];
    var selectedDebtor = form.getValues().debtor;
    var selectedDebtorArray = selectedDebtor
        ? [{ id: selectedDebtor.id, full_name: selectedDebtor.attributes.full_name }]
        : [];
    var items = pipe(pages, map(function (e) { return e.data; }), flat(), map(treatClient), concat(selectedDebtorArray), // See (1)
    uniqueBy(prop('id')));
    return (React.createElement(Controller, { name: fieldName, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), filter: true, onChangeFilter: setSelectSearchValue, withBorder: true, noMargin: true, infiniteScroll: true, items: items, keyValue: "id", keyText: "full_name", name: fieldName, label: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR), load: fetchNextPage })); } }));
};
/**
 * (1) The CustomSelect only knows the id of the currently selected debtor. To display its
 * name correctly in the field, it tries to match that id to the objects it has been given
 * as options for the dropdown. This does not work well for paginated data, because we may
 * not have loaded the full object yet. Since the surrounding context knows the full object,
 * we add it to the list of options systematically so that the field may feed from it.
 */
