import { upperFirst } from 'lodash-es';
import { storeSetup } from '../entry';
var dispatch = function (payload) { return storeSetup.store.dispatch(payload); };
export function firstLowerCase(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
export var makeAc = function (type) { return function (payload) {
    return dispatch({
        type: type,
        payload: payload,
    });
}; };
export var makeResAc = function (type) {
    return function (payload, status, requestData, reset, id) {
        return dispatch({
            type: type,
            payload: payload,
            status: status,
            requestData: requestData,
            reset: reset,
            id: id,
        });
    };
};
export var makeReqCons = function (str, reset) {
    var newStr = str.toUpperCase();
    var returnObj = {
        request: "".concat(newStr, "_REQUEST"),
        result: "".concat(newStr, "_RESULT"),
        reset: false,
    };
    if (reset) {
        returnObj.reset = "".concat(newStr, "_RESET");
    }
    return returnObj;
};
export var makeResourceConstants = function (str) { return ({
    listPage: makeReqCons("".concat(str, "_list_page")),
    detail: makeReqCons("".concat(str, "_detail"), true),
    add: makeReqCons("".concat(str, "_add")),
    update: makeReqCons("".concat(str, "_update")),
    delete: makeReqCons("".concat(str, "_delete")),
}); };
// IDEA OF AUTOGENERATED ACTIONS
export var buildActions = function (prefix, listConstants, returnObj, sync) {
    var keys = Object.keys(listConstants);
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var label = firstLowerCase(prefix + upperFirst(keys[i]));
        if (typeof listConstants[key] === 'string' ||
            (listConstants[key] && listConstants[key].result)) {
            if (typeof listConstants[key] === 'string') {
                returnObj[label] = makeAc(listConstants[key]);
            }
            else {
                returnObj[label] = makeAc(listConstants[key].request);
                if (listConstants[keys[i]].reset) {
                    returnObj["".concat(label, "Reset")] = makeAc(listConstants[keys[i]].reset);
                }
                returnObj["".concat(label, "Res")] = makeResAc(listConstants[keys[i]].result);
            }
        }
        else if (typeof listConstants[key] === 'object') {
            buildActions(label, listConstants[key], returnObj, sync);
        }
    }
    return returnObj;
};
