import { useFormContext } from 'react-hook-form';
export var useInvoiceFormTotals = function () {
    var watch = useFormContext().watch;
    var productLines = watch('product_lines_attributes');
    var totalHtva = 0;
    var totalHtvaNoDiscount = 0;
    var totalTvac = 0;
    var totalVat = 0;
    if (productLines == null) {
        return {
            totalTvac: totalTvac,
            totalHtva: totalHtva,
            totalVat: totalVat,
            totalDiscount: totalHtvaNoDiscount - totalHtva,
        };
    }
    productLines.forEach(function (item) {
        if (!item)
            return;
        var quantity = item.quantity || 0;
        var product = item.product_attributes || {};
        var price = product.current_unity_price_htva || 0;
        totalHtvaNoDiscount += price * quantity;
        totalHtva += item.total_htva;
        totalTvac += item.total_tvac;
        totalVat += item.total_tvac - item.total_htva;
    });
    return {
        totalTvac: totalTvac,
        totalHtva: totalHtva,
        totalVat: totalVat,
        totalDiscount: totalHtvaNoDiscount - totalHtva,
    };
};
