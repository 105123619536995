import { __read, __spreadArray } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { Button, ButtonColor } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import styleIdentifiers from './RecoveryStepForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryStepFormSendReminder = function (_a) {
    var availableTemplateTypes = _a.availableTemplateTypes, children = _a.children;
    var t = useTranslation().t;
    var _b = useSelector(function (state) { return state.app.constants; }), _c = _b.sending_medium, sending_medium = _c === void 0 ? [] : _c, _d = _b.recovery_step_types.recovery_plan_steps, templateTypes = _d === void 0 ? [] : _d;
    var _e = __read(useState(), 2), show = _e[0], setShow = _e[1];
    var _f = __read(useState([]), 2), templateList = _f[0], setTemplateList = _f[1];
    var _g = useFormContext(), register = _g.register, setValue = _g.setValue, watch = _g.watch, errors = _g.formState.errors;
    register('step_type', { required: true });
    register('template', { required: true });
    register('template_id', { required: true });
    var template = watch('template');
    var sending_media = watch('sending_media') || [];
    var getTemplates = function (step_type) { return function () {
        settingsActions.templateList({
            data: {
                step_type: step_type,
            },
            noLoading: true,
            callback: function (_a) {
                var data = _a.data;
                setTemplateList(data.map(function (item) { return treatTemplate(item); }));
                setShow(step_type);
            },
        });
    }; };
    var setTemplate = function (newTemplate) {
        setValue('template', newTemplate);
        setValue('template_id', newTemplate === null || newTemplate === void 0 ? void 0 : newTemplate.id);
        setValue('step_type', newTemplate === null || newTemplate === void 0 ? void 0 : newTemplate.step_type);
    };
    var addMedium = function () {
        var defaultSendingMedium = (sending_medium.filter(function (e) { return !sending_media.some(function (el) { return e.value === el.name; }); }) || sending_medium)[0];
        if (sending_media.length < sending_medium.length) {
            setValue('sending_media', __spreadArray(__spreadArray([], __read(sending_media), false), [
                {
                    name: defaultSendingMedium.value,
                    order: sending_media.length + 1,
                    force_sending: 'true',
                },
            ], false), {
                shouldTouch: true,
            });
        }
    };
    var filteredTemplateTypes = templateTypes.filter(function (_a) {
        var value = _a.value;
        return availableTemplateTypes.includes(value);
    });
    useEffect(function () {
        if (template) {
            setValue('template_id', template.id);
        }
    }, [template, setValue]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormSection, { title: t(i18nKeys.DEBTOR.SENDING_MEDIUMS) },
            React.createElement(SendingMedia, { fieldName: "sending_media", className: styles('input') }),
            sending_media.length < sending_medium.length && (React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.ACTIONS.ADD), iconRight: IconName.PLUS, onClick: addMedium, small: true }))),
        children,
        React.createElement(FormSection, { title: t(i18nKeys.COMMON.TEMPLATE), className: styles('form-section') },
            React.createElement("div", { className: styles('actions') },
                errors.template && (React.createElement("div", { className: styles('error-message', 'error') }, t(i18nKeys.PLAN.SELECT_TEMPLATE))),
                filteredTemplateTypes.map(function (_a) {
                    var description = _a.description, value = _a.value;
                    return (React.createElement(Fragment, { key: value },
                        React.createElement("div", { onClick: getTemplates(value), className: styles('action', show && 'show-list', show !== value && 'hide', template && 'selected') },
                            React.createElement("div", { className: styles('template-name') },
                                React.createElement(Icon, { name: IconName.SEND }),
                                React.createElement("span", null, description),
                                React.createElement(Icon, { name: IconName.SMALL_REMOVE, className: styles('remove-icon'), onClick: function () { return setTemplate(); } })),
                            show === value && (React.createElement("div", { className: styles('recovr-list') }, template ? (React.createElement("div", { key: template.id, className: styles('choosed') },
                                React.createElement("div", { className: styles('name') },
                                    " ",
                                    template.name))) : (React.createElement(React.Fragment, null, templateList.length ? (React.createElement(React.Fragment, null, templateList.map(function (item) { return (React.createElement("div", { key: item.id, onClick: function () { return setTemplate(item); } },
                                React.createElement("div", { className: styles('name') }, item.name))); }))) : (React.createElement("div", { className: styles('no-result') }, t(i18nKeys.NO_RESULT))))))))));
                })))));
};
