import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import styleIdentifiers from './WorkflowFormSteps.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsItemDelay = function (_a) {
    var count = _a.delay, onClick = _a.onClick, _b = _a.top, top = _b === void 0 ? false : _b, _c = _a.bottom, bottom = _c === void 0 ? false : _c, _d = _a.after, after = _d === void 0 ? false : _d, _e = _a.before, before = _e === void 0 ? false : _e, children = _a.children;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('delay', top && 'top', bottom && 'bottom', children && 'children') },
        React.createElement("div", null,
            after && (React.createElement(React.Fragment, null, "+ ".concat(t(i18nKeys.DAY_S, {
                count: count,
            })))),
            before && (React.createElement(React.Fragment, null, "- ".concat(t(i18nKeys.DAY_S, {
                count: count,
            })))),
            onClick && React.createElement(Icon, { name: IconName.PENCIL, onClick: onClick }),
            !!children && React.createElement("div", { className: styles('children') }, children))));
};
