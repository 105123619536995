import React from 'react';
import { Group } from '@mantine/core';
import { KanbanCard } from './KanbanCard';
import { KanbanColumn } from './KanbanColumn';
export var Kanban = function (_a) {
    var children = _a.children, _b = _a.minColumnWidth, minColumnWidth = _b === void 0 ? 140 : _b;
    return (React.createElement(Group, { h: "100%", w: "100%", wrap: "nowrap", grow: true, gap: 12, style: {
            overflowX: 'auto',
            '--kanban-min-width': typeof minColumnWidth === 'string' ? minColumnWidth : "".concat(minColumnWidth, "px"),
        } }, children));
};
Kanban.Column = KanbanColumn;
Kanban.Card = KanbanCard;
