/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var DueDateDelay = {
    NUMBER_0: 0,
    NUMBER_3: 3,
    NUMBER_7: 7,
    NUMBER_999: 999,
};
