import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export function setFavoriteViewFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance;
        var viewId = _b.viewId;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [2 /*return*/, instance.put("views/".concat(viewId, "/set-favorite"))];
            }
        });
    });
}
export function useSetFavoriteView() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return setFavoriteViewFn(axiosInstance, variables); },
        onSuccess: function (response, _a) {
            var resourceType = _a.resourceType;
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['views', resourceType]);
        },
    });
    return addActionNameToMutationResult('setFavoriteView', mutation);
}
