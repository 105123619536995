import React, { useEffect } from 'react';
import { useTranslation } from 'locales';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import Modals from 'shared/components/Modals';
import SideMenu from 'shared/components/SideMenu';
import { useAuthenticate } from 'shared/hooks';
import { getEnvIcon } from 'shared/utils/environment';
import { useMediaQuery } from 'shared/utils/hooks';
import { useGetCompany } from 'shared/utils/selectors';
import { onResizePage } from 'store/view/view.actions';
import { OnboardingWrapper } from './Private/OnboardingWrapper';
import { PrivateLoader } from './Private/PrivateLoader';
import { PrivateRoutesWrapper } from './Private/PrivateRoutesWrapper';
import DebtorReaction from './Public/DebtorReaction';
import { Login } from './Public/Login';
import { Logout } from './Public/Logout';
import { PrivateRoutes } from './Private';
export var App = function () {
    useTranslation(); // Removing this call causes the translation system to error out. Whatever the reason is, it's wrong.
    var history = useHistory();
    var location = useLocation();
    var company = useGetCompany();
    var isMobile = useMediaQuery('(max-width: 480px)');
    var _a = useAuthenticate(), canProceedIntoAuthenticatedDomain = _a.canProceedIntoAuthenticatedDomain, isAuth0Loading = _a.isAuth0Loading, isAuthenticated = _a.isAuthenticated;
    useEffect(function () {
        var _a;
        document.title = "".concat(getEnvIcon()).concat((_a = company === null || company === void 0 ? void 0 : company.name) !== null && _a !== void 0 ? _a : 'Recovr', " ");
    }, [company === null || company === void 0 ? void 0 : company.name]);
    useEffect(function () {
        onResizePage({
            isMobile: isMobile,
        });
    }, [isMobile]);
    useEffect(function () {
        //? The debtor reaction portal is a public access page that should have been another app
        if (location.pathname.includes('debtor-reaction'))
            return;
        //? This does not cause an infinite loop because /login immediately performs a redirect to auth0
        if (!isAuth0Loading && !isAuthenticated)
            history.push('/login');
    }, [isAuth0Loading, isAuthenticated, history]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modals, null),
        React.createElement(SideMenu, null),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/logout", component: Logout }),
            React.createElement(Route, { exact: true, path: "/login", component: Login }),
            React.createElement(Route, { exact: true, path: "/invoices/:id/debtor-reaction", component: DebtorReaction }),
            canProceedIntoAuthenticatedDomain && (React.createElement(PrivateLoader, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/onboarding", component: OnboardingWrapper }),
                    React.createElement(Route, { path: "/" },
                        React.createElement(PrivateRoutesWrapper, null,
                            React.createElement(PrivateRoutes, null)))))))));
};
