import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import { validationMessage } from 'shared/utils/validation';
import { useId } from '@mantine/hooks';
import styleIdentifiers from './Checkbox.scss';
var styles = classNames.bind(styleIdentifiers);
export var Checkbox = function (_a) {
    var register = _a.register, noMargin = _a.noMargin, errorMessage = _a.errorMessage, onChange = _a.onChange, label = _a.label, children = _a.children, className = _a.className, checked = _a.checked, tabIndex = _a.tabIndex, disabled = _a.disabled, watch = _a.watch;
    var t = useTranslation().t;
    var id = useId();
    var value = (watch ? watch(register.name) : checked) || false;
    var handleChange = function (e) {
        var val = e.target.checked;
        onChange === null || onChange === void 0 ? void 0 : onChange(val);
        register === null || register === void 0 ? void 0 : register.onChange(e);
    };
    return (React.createElement("div", { className: styles('Checkbox', className, errorMessage && 'error', disabled && 'disabled', noMargin && 'no-margin') },
        React.createElement("label", { className: styles('label'), htmlFor: id },
            React.createElement("div", { className: styles('checkbox', value && 'checked') }),
            React.createElement("input", __assign({ id: id, tabIndex: tabIndex, className: styles('input'), type: "checkbox", checked: watch ? undefined : value, disabled: disabled }, register, { onChange: handleChange })),
            (children || label) && (React.createElement("span", { className: styles({ text: !!label, textLabel: true }) }, children || label))),
        errorMessage && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[errorMessage.type] ||
            errorMessage.message ||
            validationMessage.invalid)))));
};
