import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Button, Group } from '@mantine/core';
export var ModalFooter = function (_a) {
    var onCancel = _a.onCancel, onSave = _a.onSave, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.withOwnPadding, withOwnPadding = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    return (React.createElement(Group, { justify: "flex-end", gap: "md", w: "100%", mt: "md", pr: withOwnPadding ? 'md' : '0px' },
        React.createElement(Button, { color: "orange", variant: "subtle", onClick: onCancel }, t(i18nKeys.CANCEL)),
        React.createElement(Button, { disabled: isDisabled, color: "blue", onClick: onSave }, t(i18nKeys.SAVE))));
};
