import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Badge } from '@mantine/core';
export var InvoiceLabel = function () {
    var t = useTranslation().t;
    return (React.createElement(Badge, { color: "blue", size: "xs" }, t(i18nKeys.COMMON.INVOICE)));
};
export var CreditNoteLabel = function () {
    var t = useTranslation().t;
    return (React.createElement(Badge, { color: "red", size: "xs" }, t(i18nKeys.CREDIT_NOTE_SHORT)));
};
export var PaymentLabel = function () {
    var t = useTranslation().t;
    return (React.createElement(Badge, { color: "yellow", size: "xs" }, t(i18nKeys.PAYMENT)));
};
