import { __read } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, get, useFormContext } from 'react-hook-form';
import { CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './ClientAddressForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ClientAddressForm(_a) {
    var _b = _a.name, name = _b === void 0 ? 'address_attributes' : _b;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = useFormContext(), register = _d.register, errors = _d.formState.errors;
    var countriesForSelect = Object.entries(countries.getNames(currentLang)).map(function (_a) {
        var _b = __read(_a, 2), value = _b[0], description = _b[1];
        return ({ value: value, description: description });
    });
    return (React.createElement("div", { className: styles('address-form') },
        React.createElement("div", { className: styles('col-12') },
            React.createElement(Input, { errorMessage: get(errors, "".concat(name, ".additional_line_1")), className: styles('input'), withBorder: true, maxLength: 35, register: register("".concat(name, ".additional_line_1"), {
                    maxLength: 35,
                }), type: "text", label: t(i18nKeys.ADDITIONAL_LINE_1) })),
        React.createElement("div", { className: styles('col-12') },
            React.createElement(Input, { errorMessage: get(errors, "".concat(name, ".additional_line_2")), className: styles('input'), withBorder: true, register: register("".concat(name, ".additional_line_2"), {
                    maxLength: 35,
                }), type: "text", label: t(i18nKeys.ADDITIONAL_LINE_2) })),
        React.createElement("div", { className: styles('col-6', 'street-name') },
            React.createElement(Input, { errorMessage: get(errors, "".concat(name, ".street_name")), className: styles('input'), withBorder: true, register: register("".concat(name, ".street_name")), type: "text", label: t(i18nKeys.FORM.ADDRESS.STREET) })),
        React.createElement("div", { className: styles('col-3') },
            React.createElement(Input, { className: styles('input'), withBorder: true, register: register("".concat(name, ".street_number")), type: "text", label: t(i18nKeys.NUMBER) })),
        React.createElement("div", { className: styles('col-3') },
            React.createElement(Input, { className: styles('input'), withBorder: true, register: register("".concat(name, ".street_box")), type: "text", label: t(i18nKeys.FORM.ADDRESS.BOX) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, "".concat(name, ".zip_code")), className: styles('input'), withBorder: true, register: register("".concat(name, ".zip_code")), type: "text", label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, "".concat(name, ".city")), className: styles('input'), withBorder: true, register: register("".concat(name, ".city")), type: "text", label: t(i18nKeys.FORM.ADDRESS.CITY) })),
        React.createElement("div", { className: "col-12" },
            React.createElement(Controller, { defaultValue: "", name: "".concat(name, ".country_code"), render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "".concat(name, ".country_code"), filter: true, items: countriesForSelect, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } }))));
}
