import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DateSelector, Input, MaskInput, SwitchInput } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName } from '../Integration.constants';
import { isMaskFilled } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var YukiFormFieldsName;
(function (YukiFormFieldsName) {
    YukiFormFieldsName["EXPORT_INTEGRATION"] = "use_yuki_export_integration";
    YukiFormFieldsName["IMPORT_INTEGRATION"] = "use_yuki_import_integration";
    YukiFormFieldsName["PAYMENT_EXPORT"] = "use_yuki_payment_export";
    YukiFormFieldsName["PAYMENT_MATCHING"] = "use_yuki_payment_matching";
    YukiFormFieldsName["ADMIN_KEY"] = "yuki_admin_key";
    YukiFormFieldsName["API_KEY"] = "yuki_api_key";
    YukiFormFieldsName["CASH_ACCOUNT"] = "yuki_cash_account";
})(YukiFormFieldsName || (YukiFormFieldsName = {}));
export var YukiForm = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, onboarding = _a.onboarding;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = __read(useState(false), 2), preventSubmit = _b[0], setPreventSubmit = _b[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var handleSubmit = form.handleSubmit, register = form.register, control = form.control, watch = form.watch, errors = form.formState.errors, setValue = form.setValue;
    var paymentExport = watch(YukiFormFieldsName.PAYMENT_EXPORT);
    var importIntegration = watch(YukiFormFieldsName.IMPORT_INTEGRATION);
    var debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
    useEffect(function () {
        if (importIntegration) {
            setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                : new Date());
        }
    }, [importIntegration, initialValues, setValue]);
    var submit = function (values) {
        if (preventSubmit) {
            showDialog({
                id: DialogShowId.CONFIRM,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE), onConfirm: function () {
                        setPreventSubmit(false);
                        onSubmit(values);
                    } })),
            });
        }
        else {
            onSubmit(values);
        }
    };
    var _c = company.tp_accounting.additional_parameters, api_key = _c.api_key, admin_key = _c.admin_key;
    return (React.createElement(IntegrationForm, { integration: Integration.YUKI, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.YUKI.SUMMARY, form: form },
        React.createElement("div", { className: styles('grid-row') },
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Input, { defaultValue: "", register: register(YukiFormFieldsName.API_KEY), label: t(i18nKeys.FORM.YUKI.API_KEY), type: "text", placeholder: api_key ? "*****".concat(api_key) : undefined })),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Input, { defaultValue: "", register: register(YukiFormFieldsName.ADMIN_KEY), label: t(i18nKeys.FORM.YUKI.ADMIN_KEY), type: "text", placeholder: admin_key ? "*****".concat(admin_key) : undefined })),
            React.createElement("div", null,
                React.createElement(Controller, { control: control, name: YukiFormFieldsName.IMPORT_INTEGRATION, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                                text: Integration.YUKI,
                            }) })));
                    } }),
                importIntegration && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(DateSelector, { className: styles('issue-date'), noMinDate: true, label: t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT), placeholder: t(i18nKeys.ISSUE_DATE), endPlaceholder: t(i18nKeys.FORM.TO), name: IntegrationFormFieldsName.ISSUE_DATE_LIMIT, required: true, handleChange: function (date) {
                                if (!onboarding) {
                                    setPreventSubmit(true);
                                }
                                setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                            } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.DEBTOR_UPDATE, render: function (_a) {
                                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                                return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button', 'issue-date'), label: t(debtorUpdate
                                        ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                                        : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR) })));
                            } })))),
                React.createElement(Controller, { control: control, name: YukiFormFieldsName.EXPORT_INTEGRATION, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.EXPORT, {
                                text: Integration.YUKI,
                            }) })));
                    } }),
                React.createElement(Controller, { control: control, name: YukiFormFieldsName.PAYMENT_MATCHING, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: React.createElement("span", null,
                                t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                                    text: Integration.YUKI,
                                }),
                                React.createElement(Tooltip, { icon: IconName.INFO, iconClassName: styles('icon'), direction: DropdownDirection.TOP }, t(i18nKeys.FORM.YUKI.BASED_ON))) })));
                    } }),
                React.createElement(Controller, { control: control, name: YukiFormFieldsName.PAYMENT_EXPORT, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.EXPORT_RECOVR_PAYMENTS, {
                                text: Integration.YUKI,
                            }) })));
                    } }),
                paymentExport && (React.createElement("div", null,
                    React.createElement(Controller, { control: control, name: YukiFormFieldsName.CASH_ACCOUNT, rules: { validate: { maskFilled: isMaskFilled } }, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                            return (React.createElement(MaskInput, __assign({}, values, { value: value || '', error: errors[YukiFormFieldsName.CASH_ACCOUNT], className: styles('cash_account_input'), label: t(i18nKeys.FORM.YUKI.CASH_ACCOUNT), length: 3, guide: true, numberOnly: true, prefix: "570" })));
                        } }))),
                React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.AUTOMATIC_SYNC, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, label: t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC), className: styles('switch-button') })));
                    } })))));
};
