import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadDisputedInvoices, } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { BaseTicker } from '../BaseTicker';
function getAmountDisputed(disputedAmount, formatter) {
    return formatter.format(disputedAmount);
}
function getPercentageDisputed(agedBalanceCompact, disputedAmount) {
    var netAgedBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var totalReceivable = netAgedBalance.notDue +
        netAgedBalance['0'] +
        netAgedBalance['30'] +
        netAgedBalance['60'] +
        netAgedBalance['90'];
    var percentage = (disputedAmount / totalReceivable) * 100;
    return percentage.toFixed(2);
}
export var DisputedTicker = function (_a) {
    var format = _a.format;
    var t = useTranslation().t;
    var _b = useLoadAgedBalanceCompact(), agedBalanceCompact = _b.agedBalanceCompact, isAgedBalanceCompactLoading = _b.isAgedBalanceCompactLoading;
    var _c = useLoadDisputedInvoices(), disputedInvoices = _c.disputedInvoices, isDisputedInvoicesLoading = _c.isDisputedInvoicesLoading;
    var formatter = useSafeLocalizedCompactCurrencyFormatter(true);
    if (isAgedBalanceCompactLoading ||
        agedBalanceCompact == null ||
        isDisputedInvoicesLoading ||
        disputedInvoices == null) {
        return React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.DISPUTED.TITLE) });
    }
    var value = format === 'percentage'
        ? getPercentageDisputed(agedBalanceCompact, disputedInvoices)
        : getAmountDisputed(disputedInvoices, formatter);
    return (React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.DISPUTED.TITLE), value: value, suffix: format === 'percentage' ? '%' : '€', url: "/invoices/listing?status=disputed" }));
};
