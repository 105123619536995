import { __assign } from "tslib";
import { formAction } from './form.actions';
var defaultState = {};
export default (function (state, _a) {
    var _b;
    if (state === void 0) { state = defaultState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case formAction.changeValue.request:
            return __assign(__assign({}, state), (_b = {}, _b[payload.key] = payload.value, _b));
        default:
            return state;
    }
});
