import { i18nKeys } from 'locales/';
export var hasSendingMedium = function (mediums, name) {
    return mediums.some(function (e) { return e.name === name; });
};
export var getText = function (name, t, toLowerCase) {
    if (toLowerCase === void 0) { toLowerCase = true; }
    if (!name) {
        return '';
    }
    var text = t(i18nKeys.WILL_BE_SENT[name.toUpperCase()]) || '';
    return toLowerCase && text ? text.toLowerCase() : text;
};
export var getDescription = function (values, t) {
    return values.reduce(function (acc, el, i) {
        return i === 0 ? getText(el.name, t, false) : "".concat(acc, " ").concat(t(i18nKeys.AND), " ").concat(getText(el.name, t));
    }, '');
};
