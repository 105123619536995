import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './ChooseInvoiceItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ChooseGroupedItem(_a) {
    var item = _a.item, formValues = _a.formValues, toggleAdd = _a.toggleAdd;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var id = item.id, type = item.type, remaining_balance = item.remaining_balance, amount = item.amount, itemType = item.itemType, reference = item.reference, payment_modality_translated = item.payment_modality_translated, communication = item.communication, received_at = item.received_at, issue_date = item.issue_date, value_date = item.value_date, total_tvac = item.total_tvac, localized_money_object = item.localized_money_object, currency = item.currency, updated_amounts = item.updated_amounts, late_fees = item.late_fees, already_paid_amount = item.already_paid_amount, paused = item.paused, disputed = item.disputed;
    var isInvoice = type === 'fees_preview_invoice';
    var invoice = useMemo(function () { return formValues[itemType].find(function (value) { return id === value.id; }); }, [formValues]);
    var saldoComputation = function (item, localized) {
        if (localized === void 0) { localized = false; }
        var priceObject = localized ? localized_money_object : item;
        var value = 0;
        if (!isInvoice && type !== 'grouped_credit_note') {
            value = (remaining_balance || amount) * -1;
        }
        else {
            value = priceObject.remaining_balance;
        }
        return value;
    };
    return (React.createElement("tr", { key: "".concat(itemType).concat(id), className: styles('choose-invoice-item', {
            positive: isInvoice,
            active: invoice.add,
        }) },
        React.createElement("td", { className: styles('flex') },
            React.createElement(Checkbox, { name: "", className: styles('checkbox'), checked: invoice.add, onChange: toggleAdd(itemType, id) }),
            paused && React.createElement(Icon, { name: IconName.PAUSE, title: t(i18nKeys.CLIENT.FILTER.PAUSED) }),
            disputed && React.createElement(Icon, { name: IconName.LAW, title: t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT) })),
        React.createElement("td", null, isInvoice
            ? t(i18nKeys.COMMON.INVOICE)
            : type === 'grouped_credit_note'
                ? t(i18nKeys.CREDIT_NOTE)
                : t(i18nKeys.PAYMENT)),
        React.createElement("td", { className: styles('reference') }, reference ||
            payment_modality_translated ||
            communication ||
            formattedDate(received_at, currentLang)),
        React.createElement("td", null, formattedDate(issue_date || value_date, currentLang)),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: total_tvac, value: localized_money_object.total_tvac, suffix: currency, className: styles('value', 'bigger') })),
        React.createElement("td", null, already_paid_amount && (React.createElement(Amount, { localizedValue: already_paid_amount, value: localized_money_object.already_paid_amount, suffix: currency, className: styles('value', 'bigger') }))),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: saldoComputation(item), value: saldoComputation(item, true), suffix: currency, className: styles('value', 'bigger') }))));
}
