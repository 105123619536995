import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { first } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { ThirdPartyType, useLoadThirdParties } from 'shared/hooks';
import { Button } from 'shared/io';
import debtCollectorIcon from './debt-collector.svg';
import styleIdentifiers from './ChooseThirdPartyTypeForm.scss';
var styles = classNames.bind(styleIdentifiers);
var ThirdPartyCard = function (_a) {
    var _isDisabled = _a.isDisabled, title = _a.title, onClick = _a.onClick, isSelected = _a.isSelected, illustration = _a.illustration, errorText = _a.errorText;
    var isDisabled = _isDisabled || errorText != null;
    return (React.createElement("div", { className: styles('card', isDisabled && 'disabled', isSelected && 'selected'), onClick: isDisabled ? function () { } : onClick },
        React.createElement("div", { className: styles('picture') }, illustration),
        React.createElement("div", { className: styles('container-info') },
            React.createElement("div", { className: styles('title') }, title),
            errorText != null && React.createElement("div", { className: styles('error') }, errorText))));
};
export function ChooseThirdPartyTypeForm(_a) {
    var _b;
    var onSubmit = _a.onSubmit, invoices = _a.invoices, inheritedThirdParties = _a.inheritedThirdParties;
    var t = useTranslation().t;
    var _c = __read(useState(), 2), selectedThirdPartyType = _c[0], setSelectedThirdPartyType = _c[1];
    /**
     * When this modal is triggered from the invoice detail page (ToHandleDetail.tsx), the third parties
     * for that invoice have already been loaded and passed to this component as a prop (inheritedThirdParties).
     * When it is triggered from the batch action menu, the third parties have not been loaded yet, so we
     * load the ones available for the set of invoices selected by the user.
     * */
    var isBatchAction = inheritedThirdParties == null;
    var localThirdParties = useLoadThirdParties({
        enabled: isBatchAction,
        ids: invoices.map(function (i) { return i.id; }),
    }).thirdParties;
    var thirdParties = isBatchAction ? localThirdParties : inheritedThirdParties;
    var isSingleInvoice = invoices.length === 1;
    var isOriginalInvoice = isSingleInvoice ? (_b = first(invoices)) === null || _b === void 0 ? void 0 : _b.has_pdf : true;
    // todo: loader
    if (thirdParties == null)
        return null;
    return (React.createElement("div", { className: styles('choose-third-party-form') },
        React.createElement("div", { className: styles('description') },
            React.createElement(HTML, { html: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.DESCRIPTION) })),
        React.createElement("div", { className: styles('thirdparties') },
            React.createElement("div", { className: styles('cards') },
                React.createElement(ThirdPartyCard, { isDisabled: !thirdParties.callcenter.canBeAccessed, title: t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.CALLCENTER), onClick: function () { return setSelectedThirdPartyType(ThirdPartyType.CALL_CENTER); }, isSelected: selectedThirdPartyType === ThirdPartyType.CALL_CENTER, illustration: React.createElement(Icon, { name: IconName.PHONE_CALL, size: "25px" }) }),
                React.createElement(ThirdPartyCard, { isDisabled: !thirdParties.lawyer.canBeAccessed, title: t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.LAWYER), onClick: function () { return setSelectedThirdPartyType(ThirdPartyType.LAWYER); }, isSelected: selectedThirdPartyType === ThirdPartyType.LAWYER, errorText: isOriginalInvoice
                        ? undefined
                        : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED), illustration: React.createElement(Icon, { name: IconName.LAW, size: "25px" }) }),
                React.createElement(ThirdPartyCard, { isDisabled: !thirdParties.bailiff.canBeAccessed, title: t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.BAILIFF), onClick: function () { return setSelectedThirdPartyType(ThirdPartyType.BAILIFF); }, isSelected: selectedThirdPartyType === ThirdPartyType.BAILIFF, errorText: isOriginalInvoice
                        ? undefined
                        : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED), illustration: React.createElement(Icon, { name: IconName.BALANCE, size: "25px" }) }),
                React.createElement(ThirdPartyCard, { isDisabled: !thirdParties.debtCollector.canBeAccessed, title: t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.DEBT_COLLECTOR), onClick: function () { return setSelectedThirdPartyType(ThirdPartyType.DEBT_COLLECTOR); }, isSelected: selectedThirdPartyType === ThirdPartyType.DEBT_COLLECTOR, errorText: isOriginalInvoice
                        ? undefined
                        : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED), illustration: React.createElement("img", { src: debtCollectorIcon, alt: "Debt collector" }) })),
            React.createElement("div", { className: styles('tp-description') }, selectedThirdPartyType && (React.createElement("div", null,
                React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS.WHAT)),
                React.createElement("p", null, t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS[selectedThirdPartyType.toUpperCase()]
                    .FIRST)),
                React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS.WHEN)),
                React.createElement(HTML, { html: t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS[selectedThirdPartyType.toUpperCase()]
                        .SECOND) }))))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { noMargin: true, disabled: selectedThirdPartyType == null, label: t(i18nKeys.FORM.VALIDATE), onClick: function () {
                    onSubmit(selectedThirdPartyType, thirdParties);
                } }))));
}
