import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import CustomVariables from 'app/Private/CustomVariables';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useRouteMatch } from 'react-router-dom';
import Card from 'shared/components/Card';
import DebtorInfo from 'shared/components/DebtorInfo';
import { treatInvoice } from 'shared/serializer';
import { invoiceActions } from 'store/invoice/invoice.actions';
import InvoiceDetailContext from '../ToHandleDetail/InvoiceDetail.context';
import InvoiceInfo from '../ToHandleDetail/InvoiceInfo';
import { LatePaymentInfo } from '../ToHandleDetail/LatePaymentInfo';
import styleIdentifiers from './ImportedInvoiceDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImportedInvoiceDetail(_a) {
    var credit = _a.credit, modal = _a.modal;
    var t = useTranslation().t;
    var params = useRouteMatch().params;
    var _b = __read(useState(), 2), importedInvoice = _b[0], setImportedInvoice = _b[1];
    useEffect(function () {
        invoiceActions.getToConfirm({
            id: params.id,
            callback: function (_a) {
                var data = _a.data;
                setImportedInvoice(treatInvoice(data));
            },
        });
    }, []);
    var reloadInfo = function () {
        invoiceActions.getToConfirm({
            id: params.id,
        });
    };
    var debtor = importedInvoice === null || importedInvoice === void 0 ? void 0 : importedInvoice.debtor_attributes;
    return (React.createElement(InvoiceDetailContext.Provider, { value: {
            data: importedInvoice,
            credit: credit,
            reloadInfo: reloadInfo,
            clientData: debtor,
            noValidate: true,
        } },
        React.createElement("div", { className: styles('imported-invoice-detail', importedInvoice && modal && 'modal') }, importedInvoice && (React.createElement("div", { className: styles('invoice-wrapper') },
            React.createElement("h1", null, importedInvoice.reference || importedInvoice.id),
            React.createElement("div", { className: styles('line') },
                React.createElement(Card, { title: t(credit ? 'INVOICE.CREDIT_NOTE_STATUS' : 'INVOICE.INVOICE_STATUS'), className: styles('box', 'middle'), classContent: styles('status-card') },
                    React.createElement("span", { className: styles('text') }, importedInvoice.status)),
                debtor && React.createElement(DebtorInfo, { imported: true, clientData: debtor, invoiceData: importedInvoice })),
            React.createElement("div", { className: styles('line') },
                React.createElement(InvoiceInfo, null),
                React.createElement(LatePaymentInfo, null)),
            React.createElement("div", { className: styles('line') },
                (importedInvoice === null || importedInvoice === void 0 ? void 0 : importedInvoice.custom_variables) && (React.createElement(React.Fragment, null, Object.keys(importedInvoice.custom_variables).length > 0 && (React.createElement(CustomVariables, { data: importedInvoice.custom_variables, invoiceView: true, cardTitle: " - ".concat(t(i18nKeys.COMMON.INVOICE)) })))),
                (debtor === null || debtor === void 0 ? void 0 : debtor.custom_variables) && (React.createElement(React.Fragment, null, Object.keys(debtor.custom_variables).length > 0 && (React.createElement(CustomVariables, { data: debtor.custom_variables, invoiceView: true, cardTitle: " - ".concat(t(i18nKeys.COMMON.CLIENT)), marginLeft: true }))))))))));
}
