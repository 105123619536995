import { makeAc } from 'store/actions';
export var DialogShowId;
(function (DialogShowId) {
    DialogShowId["CUSTOM"] = "custom";
    DialogShowId["INVOICE"] = "invoice";
    DialogShowId["CONFIRM"] = "confirm";
})(DialogShowId || (DialogShowId = {}));
export var DialogShowSize;
(function (DialogShowSize) {
    DialogShowSize["SMALL"] = "small";
    DialogShowSize["MEDIUM"] = "medium";
    DialogShowSize["LARGE"] = "large";
    DialogShowSize["FULL"] = "full";
})(DialogShowSize || (DialogShowSize = {}));
export var viewActionTypes = {
    dialog: {
        show: 'DIALOG_SHOW',
        hide: 'DIALOG_HIDE',
    },
    notification: {
        show: 'NOTIFICATION_SHOW',
        hide: 'NOTIFICATION_HIDE',
        error: 'NOTIFICATION_ERROR',
        success: 'NOTIFICATION_SUCCESS',
    },
    sideMenu: {
        show: 'SIDE_MENU_SHOW',
        hide: 'SIDE_MENU_HIDE',
        setAskBeforeClose: 'SIDE_MENU_ASK_BEFORE_CLOSE',
    },
    progress: {
        set: 'PROGRESS_SET',
        reset: 'PROGRESS_RESET',
        error: 'PROGRESS_ERROR',
    },
    backgroundLoader: {
        show: 'BACKGROUND_LOADER_SHOW',
        hide: 'BACKGROUND_LOADER_HIDE',
    },
    onResizePage: 'ON_RESIZE_PAGE',
};
export var showDialog = makeAc(viewActionTypes.dialog.show);
export var dialogHide = makeAc(viewActionTypes.dialog.hide);
export var backgroundLoaderShow = makeAc(viewActionTypes.backgroundLoader.show);
export var backgroundLoaderHide = makeAc(viewActionTypes.backgroundLoader.hide);
export var success = makeAc(viewActionTypes.notification.success);
export var error = makeAc(viewActionTypes.notification.error);
export var sideMenuShow = makeAc(viewActionTypes.sideMenu.show);
export var sideMenuSetAskBeforeClose = makeAc(viewActionTypes.sideMenu.setAskBeforeClose);
export var sideMenuHide = makeAc(viewActionTypes.sideMenu.hide);
export var progressSet = makeAc(viewActionTypes.progress.set);
export var progressReset = makeAc(viewActionTypes.progress.reset);
export var progressError = makeAc(viewActionTypes.progress.error);
export var onResizePage = makeAc(viewActionTypes.onResizePage);
