import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, CustomSelect } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './TemplateSendingOptions.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TemplateSendingOptions(_a) {
    var state = _a.state, setState = _a.setState, lang = _a.lang, customDomain = _a.customDomain, defaultFrom = _a.defaultFrom;
    var t = useTranslation().t;
    var values = state[lang.value];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: values,
    });
    var register = form.register, getValues = form.getValues, isDirty = form.formState.isDirty;
    var submit = function () {
        var email = __assign({}, state);
        email[lang.value] = __assign(__assign(__assign({}, email[lang.value]), getValues()), { changed: true });
        setState({
            email: email,
        });
        dialogHide(DialogShowId.CUSTOM);
    };
    var hasCustomDomainConfig = defaultFrom && customDomain;
    var customDomainAddress = hasCustomDomainConfig && "".concat(defaultFrom, "@").concat(customDomain);
    var defaultEmail = hasCustomDomainConfig ? customDomainAddress : 'no-reply@recovr.eu';
    var fromEmailItems = [
        {
            value: 'default',
            description: "".concat(t(i18nKeys.DEFAULT), " (").concat(defaultEmail, ")"),
        },
    ];
    if (hasCustomDomainConfig) {
        fromEmailItems.push({
            value: 'no-reply@recovr.eu',
            description: 'no-reply@recovr.eu',
        }, {
            value: customDomainAddress,
            description: customDomainAddress,
        });
    }
    return (React.createElement("div", { className: styles('template-sending-options') },
        React.createElement("div", { className: styles('email-form') },
            React.createElement("div", { className: styles('label-container') },
                React.createElement("div", { className: styles('subject-label') },
                    t(i18nKeys.SUBJECT).toUpperCase(),
                    React.createElement(Tooltip, { icon: IconName.INFO, direction: DropdownDirection.RIGHT, contentClassName: styles('subject-tooltip') },
                        t(i18nKeys.SUBJECT_VARIABLES),
                        ":",
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.FORM.REFERENCE),
                                ": "),
                            React.createElement("span", null, ' {{reference}}')),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.COMPANY_NAME),
                                ": "),
                            React.createElement("span", null, ' {{company_name}}')),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.DEBTOR_NAME),
                                ": "),
                            React.createElement("span", null, ' {{debtor_name}}')),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.FORM.FIRSTNAME),
                                ": "),
                            React.createElement("span", null, ' {{first_name}}')),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.FORM.LASTNAME),
                                ": "),
                            React.createElement("span", null, ' {{last_name}}')),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                t(i18nKeys.FORM.JOB_TITLE),
                                ": "),
                            React.createElement("span", null, ' {{job_title}}')))),
                React.createElement("div", { className: styles('subject-label') },
                    "FROM",
                    React.createElement(Tooltip, { icon: IconName.INFO, direction: DropdownDirection.RIGHT, contentClassName: styles('subject-tooltip') },
                        t(i18nKeys.TEMPLATE.CAN_USE_VARIABLE),
                        " :",
                        React.createElement("div", null, t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_NAME_FIELD)))),
                React.createElement("div", { className: styles('subject-label') },
                    "REPLY TO",
                    React.createElement(Tooltip, { icon: IconName.INFO, direction: DropdownDirection.RIGHT, contentClassName: styles('subject-tooltip') },
                        t(i18nKeys.TEMPLATE.CAN_USE_VARIABLE),
                        " :",
                        React.createElement("div", null, t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_NAME_FIELD)),
                        React.createElement("div", null, t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_EMAIL_FIELD))))),
            React.createElement("div", { className: styles('input-container') },
                React.createElement("div", null,
                    React.createElement("input", __assign({ type: "text", className: styles(!values.subject && 'error') }, register('subject')))),
                React.createElement("div", { className: styles('double-field') },
                    React.createElement("input", __assign({ type: "text", className: styles(!values.from_name && 'error', 'no-padding-right') }, register('from_name'), { placeholder: t(i18nKeys.NAME) })),
                    React.createElement(FormProvider, __assign({}, form),
                        React.createElement(Controller, { name: "from_email", render: function () { return (React.createElement(CustomSelect, { name: "from_email", keyText: "description", keyValue: "value", selectClassName: styles('from-email'), valueClassName: styles('light-font'), noBorder: true, notAllowed: !hasCustomDomainConfig, items: fromEmailItems, noMargin: true })); } }))),
                React.createElement("div", { className: styles('double-field') },
                    React.createElement("input", __assign({ type: "text" }, register('reply_to_name'), { placeholder: t(i18nKeys.NAME) })),
                    React.createElement("input", __assign({ type: "text" }, register('reply_to_email'), { className: styles(values.reply_to_email_error && 'error'), placeholder: t(i18nKeys.EMAIL) }))))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { disabled: !isDirty, label: t(i18nKeys.CONFIRM), onClick: submit }))));
}
