import { __assign, __awaiter, __generator, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import { Button, ButtonColor, Dropdown, Input } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { recoveryPlanSerializer, treatRecoveryPlan } from 'shared/serializer';
import { usePrevious } from 'shared/utils/hooks';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, showDialog, DialogShowId, DialogShowSize, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import styleIdentifiers from './EditDebtCollectionCustomization.scss';
import RecoveryStepForm from './RecoveryStepForm';
var styles = classNames.bind(styleIdentifiers);
export var RECOVER_ACTION_TYPE;
(function (RECOVER_ACTION_TYPE) {
    RECOVER_ACTION_TYPE["INVOICE"] = "invoice";
    RECOVER_ACTION_TYPE["CREDIT_NOTE"] = "credit_note";
    RECOVER_ACTION_TYPE["PREVENTIVE_REMINDER"] = "preventive_reminder";
    RECOVER_ACTION_TYPE["FIRST_REMINDER"] = "first_reminder";
    RECOVER_ACTION_TYPE["INTERMEDIATE_REMINDER"] = "intermediate_reminder";
    RECOVER_ACTION_TYPE["LAST_REMINDER"] = "last_reminder";
    RECOVER_ACTION_TYPE["ADDITIONAL_REMINDER"] = "additional_reminder";
    RECOVER_ACTION_TYPE["FORMAL_NOTICE"] = "formal_notice";
    RECOVER_ACTION_TYPE["PLAN_CREATION"] = "plan_creation";
    RECOVER_ACTION_TYPE["PLAN_UPDATE"] = "plan_update";
    RECOVER_ACTION_TYPE["PLAN_CANCEL"] = "plan_cancel";
})(RECOVER_ACTION_TYPE || (RECOVER_ACTION_TYPE = {}));
export default function EditDebtCollectionCustomization() {
    var _this = this;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var history = useHistory();
    var location = useLocation();
    var match = useRouteMatch();
    var _a = __read(useState(), 2), dbc = _a[0], setDbc = _a[1];
    var _b = __read(useState(), 2), recoveryPlan = _b[0], setRecoveryPlan = _b[1];
    var _c = __read(useState(null), 2), fee_starting_step = _c[0], setFeeStartingStep = _c[1];
    var form = useForm({ shouldUnregister: true });
    var register = form.register, reset = form.reset, trigger = form.trigger, getValues = form.getValues, errors = form.formState.errors;
    var params = useRouteMatch().params;
    var previousStepLength = usePrevious(dbc === null || dbc === void 0 ? void 0 : dbc.recovery_steps);
    useEffect(function () {
        if (dbc) {
            var newDbc = __assign({}, dbc);
            newDbc.recovery_steps = dbc.recovery_steps.slice();
            var add_fees_1 = false;
            newDbc.recovery_steps = newDbc.recovery_steps.map(function (step) {
                if (fee_starting_step === step.order) {
                    add_fees_1 = true;
                }
                return __assign(__assign({}, step), { fee_starting_step: fee_starting_step === step.order, fee_step_reached: add_fees_1 });
            });
            if (previousStepLength !== undefined) {
                newDbc.isDirty = true;
            }
            setDbc(newDbc);
        }
    }, [fee_starting_step, dbc === null || dbc === void 0 ? void 0 : dbc.recovery_steps.length]);
    useEffect(function () {
        if (match.path.indexOf('create') !== -1) {
            setDbc({
                id: undefined,
                recovery_steps: [],
                name: '',
                isDirty: true,
                invoices_using: 0,
                debtors_using: 0,
            });
        }
        else {
            settingsActions.getRecoveryPlan({
                id: params.id,
                callback: function (_a) {
                    var _b;
                    var data = _a.data;
                    var recoveryPlan = treatRecoveryPlan(data);
                    reset({ name: recoveryPlan.name });
                    setFeeStartingStep(((_b = recoveryPlan.recovery_steps.find(function (step) { return step.fee_step_reached; })) === null || _b === void 0 ? void 0 : _b.order) || null);
                    setDbc(recoveryPlan);
                    setRecoveryPlan(recoveryPlan);
                },
            });
        }
    }, [location]);
    var editStep = function (stepIndex, taskType) { return function () {
        var index = stepIndex === -1 ? 0 : stepIndex;
        sideMenuShow({
            unmount: true,
            content: (React.createElement(RecoveryStepForm, { step: dbc.recovery_steps[index], availableTemplateTypes: [dbc.recovery_steps[index].step_type], onSelectTemplate: function (step) {
                    var newDbc = __assign(__assign({}, dbc), { isDirty: true });
                    newDbc.recovery_steps[index] = __assign(__assign({}, step), { order: index });
                    setDbc(newDbc);
                    sideMenuHide();
                } })),
        });
    }; };
    var goToList = function () {
        history.push('/settings/workflows/recovery-plans');
    };
    var addStep = function (index) { return function (onlyPreventiveReminder, before_due_date) {
        var preventiveReminder = dbc.recovery_steps.find(function (e) { return e.step_type === 'preventive_reminder'; });
        var firstReminder = dbc.recovery_steps.find(function (e) { return e.step_type === 'first_reminder'; });
        var lastReminder = dbc.recovery_steps.find(function (e) { return e.step_type === 'last_reminder'; });
        var availableTemplateTypes = [];
        if (before_due_date && !preventiveReminder) {
            availableTemplateTypes = ['preventive_reminder'];
        }
        else if (!before_due_date && !firstReminder) {
            availableTemplateTypes = ['first_reminder'];
        }
        else if (!before_due_date &&
            firstReminder &&
            !lastReminder &&
            index > dbc.recovery_steps.indexOf(firstReminder)) {
            availableTemplateTypes = ['intermediate_reminder', 'last_reminder'];
        }
        else if (!before_due_date &&
            firstReminder &&
            lastReminder &&
            index > dbc.recovery_steps.indexOf(firstReminder) &&
            index <= dbc.recovery_steps.indexOf(lastReminder)) {
            availableTemplateTypes = ['intermediate_reminder'];
        }
        sideMenuShow({
            unmount: true,
            content: (React.createElement(RecoveryStepForm, { availableTemplateTypes: availableTemplateTypes, onlyTask: !availableTemplateTypes.length, onSelectTemplate: function (step) {
                    step.before_due_date = before_due_date;
                    var newDbc = __assign(__assign({}, dbc), { isDirty: true });
                    var recovery_steps = newDbc.recovery_steps.slice();
                    recovery_steps.splice(index, 0, step);
                    var add_fees = false;
                    var newOrder = null;
                    newDbc.recovery_steps = recovery_steps.map(function (step, index) {
                        if (step.fee_starting_step) {
                            add_fees = true;
                            newOrder = index;
                        }
                        return __assign(__assign({}, step), { fee_starting_step: step.fee_starting_step && step.fee_starting_step === step.fee_step_reached, fee_step_reached: add_fees, order: index });
                    });
                    setDbc(newDbc);
                    setFeeStartingStep(newOrder);
                    sideMenuHide();
                } })),
        });
    }; };
    var updatePlan = function (callback, steps_switch) {
        settingsActions[dbc.id ? 'setRecoveryPlan' : 'addRecoveryPlan']({
            id: dbc.id,
            data: recoveryPlanSerializer(__assign(__assign({}, dbc), { name: getValues().name, steps_switch: steps_switch }), (dbc.id && recoveryPlan) || undefined),
            callback: function (_a) {
                var _b;
                var data = _a.data;
                dialogHide(DialogShowId.CUSTOM);
                if (callback) {
                    callback();
                }
                else if (dbc.id) {
                    var plan = treatRecoveryPlan(data);
                    setFeeStartingStep(((_b = plan.recovery_steps.find(function (step) { return step.fee_step_reached; })) === null || _b === void 0 ? void 0 : _b.order) || null);
                    setRecoveryPlan(plan);
                    setDbc(__assign(__assign({}, plan), { isDirty: false }));
                }
                else {
                    setDbc(__assign(__assign({}, dbc), { isDirty: false }));
                    setTimeout(function () {
                        history.push("/settings/workflows/recovery-plans/edit/".concat(data.id));
                    }, 50);
                }
            },
        });
    };
    var save = function (isSaveAndQuit) { return function (callback) { return __awaiter(_this, void 0, void 0, function () {
        var isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (isValid) {
                        if (recoveryPlan && recoveryPlan.invoices_using > 0 && dbc.step_deleted) {
                            MoveRecoveryPlanInvoices({
                                title: t(i18nKeys.PLAN.INVOICES),
                                newPlan: dbc,
                                oldPlan: recoveryPlan,
                                allStepRequired: true,
                                onSubmit: function (steps_switch) {
                                    updatePlan(isSaveAndQuit && callback, steps_switch);
                                },
                            });
                        }
                        else {
                            updatePlan(isSaveAndQuit && callback);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); }; };
    var deletePlan = function () {
        setDbc(__assign(__assign({}, dbc), { isDirty: false }));
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            keepMountOnExit: true,
            title: t(i18nKeys.CONFIRM),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_RECOVERY_PLAN), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return settingsActions.deleteRecoveryPlan({
                        id: dbc.id,
                        callback: function () {
                            history.push('/settings/workflows/recovery-plans');
                        },
                    });
                } })),
        });
    };
    var deleteStep = function (index) { return function () {
        var newDbc = __assign(__assign({}, dbc), { isDirty: true });
        newDbc.recovery_steps = newDbc.recovery_steps.slice();
        if (newDbc.recovery_steps[index].fee_starting_step) {
            if (newDbc.recovery_steps[index + 1]) {
                newDbc.recovery_steps[index + 1] = __assign(__assign({}, newDbc.recovery_steps[index + 1]), { fee_starting_step: true, fee_step_reached: true });
                setFeeStartingStep(newDbc.recovery_steps[index + 1].order);
            }
            else {
                setFeeStartingStep(null);
            }
        }
        if (newDbc.recovery_steps[index].invoices_count > 0) {
            newDbc.step_deleted = true;
        }
        newDbc.recovery_steps.splice(index, 1);
        setDbc(newDbc);
    }; };
    var duplicate = function () {
        settingsActions.duplicateRecoveryPlan({
            id: dbc.id,
            callback: function (_a) {
                var data = _a.data;
                history.push("/settings/workflows/recovery-plans/edit/".concat(data.id));
            },
        });
    };
    var setDirty = function () {
        setDbc(__assign(__assign({}, dbc), { isDirty: true }));
    };
    return dbc ? (React.createElement("div", { className: styles('edit-debt-collection-customization') },
        React.createElement(PageTitle, null,
            t(i18nKeys.AUTOMATED_PLANS),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), className: styles('actions-dropdown'), toggleContent: true, selectorContent: React.createElement(Button, { className: styles('action'), noMargin: true, label: t(i18nKeys.FORM.ACTIONS), iconRight: IconName.ARROW_BOTTOM_ROUNDED }) },
                    (recoveryPlan === null || recoveryPlan === void 0 ? void 0 : recoveryPlan.id) && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles('dropdown-item'), onClick: deletePlan }, t(i18nKeys.DELETE)),
                        React.createElement("div", { className: styles('dropdown-item'), onClick: duplicate }, t(i18nKeys.DUPLICATE)))),
                    React.createElement("div", { className: styles('dropdown-item'), onClick: save(false) }, t(i18nKeys.SAVE))),
                (recoveryPlan === null || recoveryPlan === void 0 ? void 0 : recoveryPlan.id) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { label: t(i18nKeys.DELETE), noMargin: true, color: ButtonColor.GREY, onClick: deletePlan }),
                    React.createElement(Button, { label: t(i18nKeys.DUPLICATE), noMargin: true, color: ButtonColor.BLUE, onClick: duplicate }))),
                React.createElement(Button, { label: t(i18nKeys.SAVE), noMargin: true, color: ButtonColor.MAIN, onClick: save(false) }))),
        React.createElement(NavigationPrompt, { when: dbc.isDirty, onSaveAndQuit: save(true) }),
        React.createElement("div", { className: styles('card', 'recovery-plan-container') },
            React.createElement("div", { className: styles('head') },
                React.createElement("div", null,
                    React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
                    ' ',
                    React.createElement(Input, { register: register('name', { required: true }), className: styles('input'), placeholder: t(i18nKeys.PLAN.NAME), errorMessage: errors.name, onValueChanged: setDirty, noMargin: true })),
                React.createElement("div", null, recoveryPlan && (React.createElement("span", null,
                    t(i18nKeys.FORM.REFERENCE),
                    " : ",
                    recoveryPlan.id)))),
            React.createElement(FormProvider, __assign({}, form), !company.package.can_edit_plan ? (React.createElement(RecoveryPlan, { onClickOnStep: editStep, setLateFees: setFeeStartingStep, recoverPlan: dbc, editStep: editStep, lateFeesStep: fee_starting_step })) : (React.createElement(RecoveryPlan, { onClickOnStep: editStep, setLateFees: setFeeStartingStep, recoverPlan: dbc, addStep: addStep, editStep: editStep, deleteStep: deleteStep, lateFeesStep: fee_starting_step, editMode: true })))))) : null;
}
