import { useGetWorkflow } from 'api';
import React, { useEffect } from 'react';
export default function UsedWorkflow(_a) {
    var workflowId = _a.workflowId;
    var _b = useGetWorkflow(workflowId), workflowResponse = _b.data, isLoading = _b.isLoading, isError = _b.isError, refetch = _b.refetch;
    useEffect(function () {
        if (workflowId) {
            refetch();
        }
    }, [workflowId, refetch]);
    if (isLoading && !workflowResponse) {
        return React.createElement(React.Fragment, null);
    }
    if (isError || !workflowResponse) {
        return React.createElement("span", null, "Error");
    }
    var workflow = workflowResponse.data;
    var name = workflow.attributes.name;
    return React.createElement(React.Fragment, null, name);
}
