import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Controller } from 'react-hook-form';
import { useLoadLightUsers } from 'shared/hooks';
import { CustomSelect } from 'shared/io';
import UserInfo from './UserInfo';
export var AccountManagerSelector = function (_a) {
    var _b = _a.shadow, shadow = _b === void 0 ? false : _b, _c = _a.name, name = _c === void 0 ? 'accountManagerId' : _c, _d = _a.loadInactive, loadInactive = _d === void 0 ? false : _d, _e = _a.showMargin, showMargin = _e === void 0 ? false : _e;
    var t = useTranslation().t;
    var _f = useLoadLightUsers(loadInactive).users, users = _f === void 0 ? [] : _f;
    return (React.createElement(Controller, { name: name, render: function () { return (React.createElement(CustomSelect, { placeholder: t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER), noMargin: !showMargin, keyValue: "id", withBorder: true, shadow: shadow, itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({ inline: true }, props)); }, name: name, items: users, label: t(i18nKeys.ACCOUNT_MANAGER) })); } }));
};
