import classNames from 'classnames/bind';
import React from 'react';
import { Input } from 'shared/io';
import styleIdentifiers from './IntervalFields.scss';
var styles = classNames.bind(styleIdentifiers);
export var IntervalFields = function (_a) {
    var register = _a.register, label = _a.label, nameFrom = _a.nameFrom, nameTo = _a.nameTo, className = _a.className, placeholderFrom = _a.placeholderFrom, placeholderTo = _a.placeholderTo, onChange = _a.onChange;
    return (React.createElement("div", { className: styles('IntervalFields', className) },
        React.createElement("div", { className: styles('label-input') }, label),
        React.createElement("div", { className: styles('input-wrapper') },
            React.createElement(Input, { register: register(nameFrom), className: styles('value'), defaultValue: "", type: "number", step: "1", min: "0", placeholder: placeholderFrom || 'De', noBorder: true, noMargin: true, onValueChanged: onChange }),
            React.createElement(Input, { register: register(nameTo), className: styles('value'), defaultValue: "", type: "number", step: "1", min: "0", noMargin: true, placeholder: placeholderTo || 'à', noBorder: true, onValueChanged: onChange }))));
};
