import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { currencySymbol } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './AttachedInvoices.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AttachedInvoicesList(_a) {
    var invoices = _a.invoices, multiple = _a.multiple, title = _a.title, withoutFees = _a.withoutFees;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var showInvoice = function (invoice_id) { return function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: invoice_id, modal: true }),
        });
    }; };
    return (React.createElement("div", { className: styles('attached-invoices') },
        React.createElement(Card, { title: title || t("THIRD_PARTY_CASES.INVOICES_OF_CASE.".concat(multiple ? 'MANY' : 'ONE')), className: styles('invoice') },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, t(i18nKeys.FORM.REFERENCE)),
                        React.createElement("th", null, t(i18nKeys.DUE_DATE)),
                        React.createElement("th", null, t(i18nKeys.TOTAL_VAT_PRICE)),
                        !withoutFees && React.createElement("th", null, t(i18nKeys.LATE_FEES)),
                        React.createElement("th", null, t(i18nKeys.INVOICING.ALREADY_PAID)),
                        React.createElement("th", null, t(i18nKeys.BALANCE_CURRENCY, {
                            text: currencySymbol(undefined, company),
                        })))),
                React.createElement("tbody", null, invoices.details.map(function (invoice) { return (React.createElement("tr", { key: invoice.id, className: styles('invoice-line'), onClick: showInvoice(invoice.id) },
                    React.createElement("td", null, invoice.reference),
                    React.createElement("td", null, formattedDate(invoice.due_date, currentLang)),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: invoice.total_tvac, value: invoice.localized_money_object.total_tvac, suffix: invoice.currency })),
                    !withoutFees && (React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: invoice.late_fees, value: invoice.localized_money_object.late_fees, suffix: invoice.currency }))),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: invoice.amount_already_paid || 0, value: invoice.localized_money_object.amount_already_paid || 0, suffix: invoice.currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: withoutFees ? invoice.remaining_balance : invoice.remaining_balance_with_fees, value: withoutFees
                                ? invoice.localized_money_object.remaining_balance
                                : invoice.localized_money_object.remaining_balance_with_fees, suffix: invoice.currency })))); })),
                invoices.details.length > 1 && (React.createElement("tfoot", null,
                    React.createElement("tr", { className: styles('total-line') },
                        React.createElement("td", null),
                        React.createElement("td", null),
                        React.createElement("td", null,
                            React.createElement(Amount, { localizedValue: invoices.total_tvac, value: invoices.total_tvac, suffix: company === null || company === void 0 ? void 0 : company.currency })),
                        !withoutFees && (React.createElement("td", null,
                            React.createElement(Amount, { localizedValue: invoices.total_late_fees, value: invoices.total_late_fees, suffix: company === null || company === void 0 ? void 0 : company.currency }))),
                        React.createElement("td", null,
                            React.createElement(Amount, { localizedValue: invoices.paid_amount, value: invoices.paid_amount, suffix: company === null || company === void 0 ? void 0 : company.currency })),
                        React.createElement("td", null,
                            React.createElement(Amount, { localizedValue: withoutFees ? invoices.total_due_without_fees : invoices.total_due, value: withoutFees ? invoices.total_due_without_fees : invoices.total_due, suffix: company === null || company === void 0 ? void 0 : company.currency })))))))));
}
