import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import { isDefined } from 'remeda';
import Logo from 'shared/components/Logo';
import { useValidateTenant } from 'shared/hooks';
import ApiService from 'shared/service/api.service';
import { Button, Center, Group, TextInput } from '@mantine/core';
import { useField } from '@mantine/form';
import styleIdentifiers from './badCompany.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BadCompany() {
    var history = useHistory();
    var t = useTranslation().t;
    var subdomain = useField({ initialValue: '' });
    var tenant = useValidateTenant(ApiService.tenant).tenant;
    // If for some reason we naviagted to this page explicitly while on a correct tenant
    useEffect(function () {
        if (isDefined(tenant))
            history.push('/dashboard');
    }, [isDefined(tenant)]);
    var onClickNavigateToTenant = function () {
        var urlWithNewTenant = window.location.origin.replace("".concat(ApiService.tenant, "."), "".concat(subdomain.getValue(), "."));
        window.location.assign("".concat(urlWithNewTenant, "/dashboard"));
    };
    return (React.createElement("div", { className: styles('server-error-page') },
        React.createElement("div", { className: styles('content-wrapper') },
            React.createElement("div", { className: styles('content', 'card') },
                React.createElement("h1", null, t(i18nKeys.LOGIN.BAD_SUBDOMAIN)),
                React.createElement("p", null,
                    t(i18nKeys.LOGIN.VERIFY_SUBDOMAIN),
                    " : ",
                    React.createElement("b", null, ApiService.tenant)),
                React.createElement(Center, null,
                    React.createElement("form", { onSubmit: function (e) {
                            e.preventDefault();
                            onClickNavigateToTenant();
                        } },
                        React.createElement(Group, null,
                            React.createElement(TextInput, __assign({ autoFocus: true }, subdomain.getInputProps(), { placeholder: "subdomain" })),
                            React.createElement(Button, { onClick: onClickNavigateToTenant }, "Go"))))),
            React.createElement(Logo, { className: styles('logo'), type: "blue" }))));
}
