import { useEffect } from 'react';
import { useGetPrivateConstants } from 'api';
import { isDefined } from 'remeda';
import { appActions } from 'store/app/app.actions';
import { useProfile } from './use-enforce-profile';
export function useLoadPrivateConstants() {
    var profile = useProfile();
    var _a = useGetPrivateConstants({
        locale: profile === null || profile === void 0 ? void 0 : profile.locale,
    }, { query: { enabled: isDefined(profile === null || profile === void 0 ? void 0 : profile.locale) } }), constantsRes = _a.data, isLoadingPrivateConstants = _a.isLoading, privateConstantsError = _a.error;
    useEffect(function () {
        if (constantsRes != null) {
            appActions.constantsRes(constantsRes, 'fulfilled', constantsRes);
        }
    }, [constantsRes]);
    return {
        privateConstants: constantsRes,
        isLoadingPrivateConstants: isLoadingPrivateConstants,
        privateConstantsError: privateConstantsError,
    };
}
