import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';
import styleIdentifiers from './SendingMedia.scss';
import { getDescription } from './SendingMedia.utils';
var styles = classNames.bind(styleIdentifiers);
export default function SendingMedia(_a) {
    var fieldName = _a.fieldName, className = _a.className, _b = _a.noMargin, noMargin = _b === void 0 ? true : _b, _c = _a.withBorder, withBorder = _c === void 0 ? false : _c, _d = _a.required, required = _d === void 0 ? true : _d;
    var sending_medium = useSelector(function (state) { return state.app.constants; }).sending_medium;
    var _e = useFormContext(), setValue = _e.setValue, watch = _e.watch, errors = _e.formState.errors;
    var t = useTranslation().t;
    var deleteLine = function (currentValues, index) {
        setValue(fieldName, currentValues.filter(function (e, i) { return i !== index; }), {
            shouldTouch: true,
        });
    };
    var onSetMedium = function (currentValues, value, index) {
        setValue(fieldName, currentValues.map(function (e, i) {
            return i === index
                ? __assign(__assign({}, e), { name: value }) : e;
        }), {
            shouldTouch: true,
        });
    };
    var onSetMediumForceSending = function (currentValues, value, index) {
        setValue(fieldName, currentValues.map(function (e, i) {
            return i === index
                ? __assign(__assign({}, e), { force_sending: value }) : e;
        }), {
            shouldTouch: true,
        });
    };
    var values = watch(fieldName) || [];
    return (React.createElement(Controller, { defaultValue: [], name: fieldName, rules: { required: required }, render: function () {
            var forceSendingValues = values.filter(function (medium) { return String(medium.force_sending) === 'true'; });
            var sendingValues = values.filter(function (medium) { return String(medium.force_sending) === 'false'; });
            return (React.createElement("div", { className: styles('SendingMedia') },
                !!values.length && (React.createElement("div", { className: styles('description') }, "".concat(getDescription(forceSendingValues, t), " ").concat(t(forceSendingValues.length > 1
                    ? i18nKeys.WILL_BE_SENT.MULTIPLE
                    : i18nKeys.WILL_BE_SENT.SINGLE), "."),
                    !!sendingValues.length && (React.createElement("div", null, "".concat(t(i18nKeys.IF_NOT), " ").concat(getDescription(sendingValues, t).toLowerCase(), " ").concat(t(sendingValues.length > 1
                        ? i18nKeys.WILL_BE_SENT.MULTIPLE
                        : i18nKeys.WILL_BE_SENT.SINGLE), "."))))),
                values.map(function (item, index) {
                    var name = item.name;
                    var selectName = "".concat(name, "-").concat(index);
                    var availableMedium = sending_medium.filter(function (medium) {
                        return !values.find(function (e) { return e.name === medium.value && e.name !== item.name; });
                    });
                    return (React.createElement(React.Fragment, { key: selectName },
                        index > 0 && (React.createElement("div", { className: styles('field-wrapper', 'force') },
                            React.createElement(CustomSelect, { name: "".concat(fieldName, ".").concat(index, ".force_sending"), keyText: "description", keyValue: "value", items: [
                                    { description: t(i18nKeys.AND), value: 'true' },
                                    { description: t(i18nKeys.OR), value: 'false' },
                                ], selectClassName: className, withBorder: withBorder, noMargin: noMargin, canUnselect: false, onValueChanged: function (value) {
                                    onSetMediumForceSending(values, value, index);
                                } }))),
                        React.createElement("div", { className: styles('field-wrapper') },
                            React.createElement(CustomSelect, { label: t(i18nKeys.DEBTOR.SENDING_MEDIUM), withBorder: withBorder, keyText: "description", keyValue: "value", name: "".concat(fieldName, ".").concat(index, ".name"), items: availableMedium, selectClassName: className, noMargin: noMargin, canUnselect: index !== 0, onValueChanged: function (value) {
                                    if (!value) {
                                        deleteLine(values, index);
                                    }
                                    else {
                                        onSetMedium(values, value || item.name, index);
                                    }
                                } }),
                            React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function (e) {
                                    e.preventDefault();
                                    deleteLine(values, index);
                                } }))));
                }),
                errors[fieldName] && (React.createElement("div", { className: styles('error-message', 'error') }, t(i18nKeys.FORM.CHOOSE_MEDIA)))));
        } }));
}
