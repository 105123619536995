export var CGV = 'terms_and_conditions_attributes';
export var CustomPreferencesFieldsName;
(function (CustomPreferencesFieldsName) {
    CustomPreferencesFieldsName["SEND_COPY"] = "send_copy";
    CustomPreferencesFieldsName["SEND_COPY_EMAILS"] = "send_copy_emails";
    CustomPreferencesFieldsName["SEND_COPY_EMAILS_INPUT"] = "send_copy_emails_input";
    CustomPreferencesFieldsName["SEND_TO_ALL_EMAILS"] = "send_to_all_emails";
    CustomPreferencesFieldsName["ALLOW_PAYMENT_PLANS"] = "allow_payment_plans";
})(CustomPreferencesFieldsName || (CustomPreferencesFieldsName = {}));
export var CustomDomainFieldsName;
(function (CustomDomainFieldsName) {
    CustomDomainFieldsName["CUSTOM_DOMAIN"] = "custom_domain";
    CustomDomainFieldsName["DEFAULT_FROM"] = "default_from";
})(CustomDomainFieldsName || (CustomDomainFieldsName = {}));
export var CustomRecoveryFieldsName;
(function (CustomRecoveryFieldsName) {
    CustomRecoveryFieldsName["SEND_COPY"] = "send_copy";
    CustomRecoveryFieldsName["DAYS_BETWEEN_REMINDER"] = "days_between_reminder";
    CustomRecoveryFieldsName["DEFAULT_FORMAL_NOTICE_TEMPLATE_ID"] = "default_formal_notice_template_id";
    CustomRecoveryFieldsName["DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID"] = "default_additional_reminder_template_id";
    CustomRecoveryFieldsName["FIRST_REMINDER_DELAY"] = "first_reminder_delay";
    CustomRecoveryFieldsName["LAST_REMINDER_DELAY"] = "last_reminder_delay";
    CustomRecoveryFieldsName["THRESHOLD_AMOUNT"] = "threshold_amount";
    CustomRecoveryFieldsName["MINIMUM_SALDO_SENDING"] = "minimum_saldo_sending";
    CustomRecoveryFieldsName["SEND_COPY_EMAILS"] = "send_copy_emails";
    CustomRecoveryFieldsName["SEND_COPY_EMAILS_INPUT"] = "send_copy_emails_input";
})(CustomRecoveryFieldsName || (CustomRecoveryFieldsName = {}));
export var ReconciliationFieldNames;
(function (ReconciliationFieldNames) {
    ReconciliationFieldNames["IGNORE_LATE_FEES"] = "ignore_late_fees";
})(ReconciliationFieldNames || (ReconciliationFieldNames = {}));
export var GeneralConditionsFieldsName;
(function (GeneralConditionsFieldsName) {
    GeneralConditionsFieldsName["SEND_TOS"] = "send_tos";
})(GeneralConditionsFieldsName || (GeneralConditionsFieldsName = {}));
export var PostalPreferencesFieldsName;
(function (PostalPreferencesFieldsName) {
    PostalPreferencesFieldsName["PRINT_COLOR"] = "print_color";
    PostalPreferencesFieldsName["PRINT_MODE"] = "print_mode";
})(PostalPreferencesFieldsName || (PostalPreferencesFieldsName = {}));
export var ReminderPreferencesFieldNames;
(function (ReminderPreferencesFieldNames) {
    ReminderPreferencesFieldNames["REMINDER_THRESHOLD_TYPE"] = "reminder_threshold_type";
})(ReminderPreferencesFieldNames || (ReminderPreferencesFieldNames = {}));
export var SalePreferencesFieldsName;
(function (SalePreferencesFieldsName) {
    SalePreferencesFieldsName["ID"] = "id";
    SalePreferencesFieldsName["NAME"] = "name";
    SalePreferencesFieldsName["MINIMUM_DELAY"] = "minimum_payment_terms_delay";
    SalePreferencesFieldsName["DEFAULT"] = "default";
    SalePreferencesFieldsName["PDF_BLOB"] = "pdf";
    SalePreferencesFieldsName["HAS_PDF"] = "has_pdf";
    SalePreferencesFieldsName["DESTROY"] = "_destroy";
    SalePreferencesFieldsName["DELETABLE"] = "deletable";
    SalePreferencesFieldsName["USE_BELGIAN_LAW"] = "use_belgian_law";
})(SalePreferencesFieldsName || (SalePreferencesFieldsName = {}));
export var SalePreferencesCGVFieldsName;
(function (SalePreferencesCGVFieldsName) {
    SalePreferencesCGVFieldsName["INTEREST"] = "interest";
    SalePreferencesCGVFieldsName["INTEREST_PERIOD"] = "interest.period";
    SalePreferencesCGVFieldsName["INTEREST_VALUE"] = "interest.value";
    SalePreferencesCGVFieldsName["FIX_FEE"] = "fix_fee";
    SalePreferencesCGVFieldsName["FIX_FEE_VALUE"] = "fix_fee.value";
    SalePreferencesCGVFieldsName["PENALTY_CLAUSE"] = "penalty_clause";
    SalePreferencesCGVFieldsName["PENALTY_CLAUSE_TYPE"] = "penalty_clause.type";
    SalePreferencesCGVFieldsName["PENALTY_CLAUSE_MINIMUM"] = "penalty_clause.minimum";
    SalePreferencesCGVFieldsName["PENALTY_CLAUSE_VALUE"] = "penalty_clause.value";
})(SalePreferencesCGVFieldsName || (SalePreferencesCGVFieldsName = {}));
export var CalculationType;
(function (CalculationType) {
    CalculationType["ANNUAL"] = "annual";
    CalculationType["QUARTERLY"] = "quarterly";
    CalculationType["MONTHLY"] = "monthly";
})(CalculationType || (CalculationType = {}));
export var InterestCalculationType;
(function (InterestCalculationType) {
    InterestCalculationType["PERCENTAGE"] = "percentage";
    InterestCalculationType["FIX"] = "fix";
})(InterestCalculationType || (InterestCalculationType = {}));
export var OperatorValues;
(function (OperatorValues) {
    OperatorValues["EQUAL_TO"] = "==";
    OperatorValues["OTHER_THAN"] = "!=";
    OperatorValues["GREATER_THAN"] = ">";
    OperatorValues["GREATER_THAN_OR_EQUAL_TO"] = ">=";
    OperatorValues["LESS_THAN"] = "<";
    OperatorValues["LESS_THAN_OR_EQUAL_TO"] = "<=";
})(OperatorValues || (OperatorValues = {}));
