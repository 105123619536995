import { buildActions, makeReqCons } from 'store/actions';
var prefix = 'settings';
export var settingsConstants = {
    editDebtCollection: makeReqCons("".concat(prefix, "_edit_debt_collection")),
    addTwikey: makeReqCons("".concat(prefix, "_add_twikey")),
    // template
    templateListPage: makeReqCons("".concat(prefix, "_template_list_page")),
    templateList: makeReqCons("".concat(prefix, "_template_list")),
    getTemplate: makeReqCons("".concat(prefix, "_get_template")),
    addTemplate: makeReqCons("".concat(prefix, "_add_template")),
    setTemplate: makeReqCons("".concat(prefix, "_set_template")),
    deleteTemplate: makeReqCons("".concat(prefix, "_delete_template")),
    duplicateTemplate: makeReqCons("".concat(prefix, "_duplicate_template")),
    previewTemplate: makeReqCons("".concat(prefix, "_preview_template")),
    // recovery plan
    recoveryPlanListPage: makeReqCons("".concat(prefix, "_recovery_plan_list_page")),
    recoveryPlanList: makeReqCons("".concat(prefix, "_recovery_plan_list")),
    getRecoveryPlan: makeReqCons("".concat(prefix, "_get_recovery_plan")),
    addRecoveryPlan: makeReqCons("".concat(prefix, "_add_recovery_plan")),
    setRecoveryPlan: makeReqCons("".concat(prefix, "_set_recovery_plan")),
    deleteRecoveryPlan: makeReqCons("".concat(prefix, "_delete_recovery_plan")),
    duplicateRecoveryPlan: makeReqCons("".concat(prefix, "_duplicate_recovery-plan")),
    getPlansInvoices: makeReqCons("".concat(prefix, "_get_plans_invoices")),
    switchPlansInvoices: makeReqCons("".concat(prefix, "_switch_plan_invoices")),
    // custom variable
    customVariableListPage: makeReqCons("".concat(prefix, "_custom_variable_list_page")),
    customVariableList: makeReqCons("".concat(prefix, "_custom_variable_list")),
    getCustomVariable: makeReqCons("".concat(prefix, "_get_custom_variable")),
    addCustomVariable: makeReqCons("".concat(prefix, "_add_custom_variable")),
    setCustomVariable: makeReqCons("".concat(prefix, "_set_custom_variable")),
    deleteCustomVariable: makeReqCons("".concat(prefix, "_delete_custom_variable")),
};
var settingsActions = buildActions('', settingsConstants, {});
export { settingsActions };
