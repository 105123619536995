import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Input } from 'shared/io';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PontoFormFieldsName;
(function (PontoFormFieldsName) {
    PontoFormFieldsName["CLIENT_ID"] = "client_id";
    PontoFormFieldsName["CLIENT_SECRET"] = "client_secret";
})(PontoFormFieldsName || (PontoFormFieldsName = {}));
export var PontoForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = useForm(), handleSubmit = _b.handleSubmit, register = _b.register;
    var _c = company.ponto_integration, ponto_client_secret = _c.ponto_client_secret, ponto_client_id = _c.ponto_client_id;
    return (React.createElement("form", { className: styles('integration-form'), onSubmit: handleSubmit(onSubmit) },
        React.createElement("div", null, t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SUMMARY)),
        React.createElement(Input, { type: "text", label: t(i18nKeys.SETTINGS.CLIENT_ID), register: register(PontoFormFieldsName.CLIENT_ID, { required: true }), placeholder: ponto_client_secret ? "*****".concat(ponto_client_secret) : undefined }),
        React.createElement(Input, { type: "text", label: t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SECRET_CODE), placeholder: ponto_client_id ? "*****".concat(ponto_client_id) : undefined, register: register(PontoFormFieldsName.CLIENT_SECRET, {
                required: true,
            }) }),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { label: t(i18nKeys.SAVE), type: "submit" }))));
};
