import { __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import moment from 'moment';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import FormSection from 'shared/forms/FormSection';
import { dateFromNow, formattedDate } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { styled } from '@stitches/react';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, IconName } from '../Icon';
import Conversation from './Conversation';
import styleIdentifiers from './ActivityDetail.scss';
var styles = classNames.bind(styleIdentifiers);
var EmailStatusHistory = styled('div', {});
var EmailStatusHistoryItem = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    padding: '10px 0px 0px 5px',
});
export default function ActivityDetail(_a) {
    var _b, _c, _d;
    var activity = _a.activity;
    var isActivityLoaded = useSelector(function (state) { return state.account.activity; }).loaded;
    var invoice = useSelector(function (state) { var _a; return (_a = state.invoice.current) === null || _a === void 0 ? void 0 : _a.data; });
    var creditNote = useSelector(function (state) { var _a; return (_a = state.creditNote.current) === null || _a === void 0 ? void 0 : _a.data; });
    var canUseEmailStatus = useSelector(function (state) { return state.account.company.data.package; }).can_use_emails_activity;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    var queryClient = useQueryClient();
    var location = useLocation();
    var history = useHistory();
    var flattenedStatuses = Object.keys((_b = activity.email_statuses) !== null && _b !== void 0 ? _b : {}).reduce(function (memo, currentKey) { return __spreadArray(__spreadArray([], __read(memo), false), __read(activity.email_statuses[currentKey].map(function (date) { return ({
        status: currentKey,
        timestamp: moment(date),
    }); })), false); }, []);
    var sortedStatuses = flattenedStatuses.sort(function (a, b) { return b.timestamp.diff(a.timestamp); });
    var reloadInvoice = function () {
        invoiceActions.activity({
            id: invoice.id,
            noLoading: true,
            noReset: true,
        });
    };
    var reloadCreditNote = function () {
        creditNoteActions.activity({
            id: creditNote.id,
            noLoading: true,
            noReset: true,
        });
    };
    var reloadActivities = function () {
        accountActions.activityPage(qs.parse(location.search));
    };
    var reload = function () {
        if (invoice)
            reloadInvoice();
        if (creditNote)
            reloadCreditNote();
        if (isActivityLoaded)
            reloadActivities();
    };
    useEffect(function () {
        if (activity.new) {
            accountActions.activitySeen({
                id: activity.id,
                noLoading: true,
                noFeedback: true,
                callback: function () {
                    queryClient.invalidateQueries(['notifications', 'count']);
                },
            });
        }
    }, [activity]);
    var detailInvoice = function () {
        dialogHide(DialogShowId.CUSTOM);
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: (React.createElement(ToHandleDetail, { isCreditNote: activity.tracked_type === 'CreditNote', id: activity.tracked_id, modal: true })),
        });
    };
    var detailDebtor = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/clients/".concat(activity.tracked_id));
    };
    var detailTask = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/tasks/".concat(activity.tracked_id));
    };
    var detailPaymentPlan = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/payment-plan/".concat(activity.payment_plan_id));
    };
    var getIcon = function () {
        switch (activity.tracked_type) {
            case 'CreditNote':
                return IconName.CREDIT_NOTE;
            case 'Invoice':
                return IconName.INVOICE;
            case 'Debtor':
                return IconName.CLIENT;
            case 'Question':
                return IconName.ALERT_CIRCLE;
            case 'CompanyDetail':
                return IconName.SETTINGS_GEAR;
            default:
                return IconName.ALERT_CIRCLE;
        }
    };
    var onSubmitMessage = function () {
        reload();
    };
    var downloadDocument = function () {
        invoiceActions.document({
            id: activity.tracked_id,
            type: activity.document_type,
        });
    };
    var date = dateFromNow(activity.date, currentLang);
    var titleKey = (function () {
        if (activity.activity_type === 'lawyer')
            return i18nKeys.INVOICE.LAWYER_CASE;
        if (activity.activity_type === 'callcenter')
            return i18nKeys.INVOICE.CALLCENTER_CASE;
        if (activity.activity_type === 'bailiff')
            return i18nKeys.INVOICE.BAILIFF_CASE;
        if (activity.payment_plan_id)
            return i18nKeys.PAYMENT_PLAN.TITLE.ONE;
        if (activity.tracked_type === 'CreditNote')
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_CREDIT_NOTE;
        if (activity.tracked_type === 'Invoice')
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_INVOICE;
        if (activity.tracked_type === 'Debtor')
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_DEBTOR;
        if (activity.tracked_type === 'CompanyDetail')
            return i18nKeys.ACTIVITIES.EDIT_COMPANY;
        if (activity.tracked_type === 'Task')
            return i18nKeys.ACTIVITIES.UPDATE_STATUS_TASK;
        return i18nKeys.DETAILS;
    })();
    var showPaymentPlan = activity.payment_plan_id;
    var showClient = activity.tracked_type === 'Debtor' && !showPaymentPlan;
    var shouldShowLinkToReminder = (_c = activity === null || activity === void 0 ? void 0 : activity.reminder) === null || _c === void 0 ? void 0 : _c.wasSentByMail;
    return (React.createElement("div", { className: styles('ActivityDetail') },
        React.createElement(Card, { title: t(titleKey), classContent: styles('modal-body') },
            React.createElement("div", null,
                React.createElement("div", { className: styles('content') },
                    React.createElement("div", { className: styles('left') },
                        React.createElement(Icon, { name: getIcon(), "font-size": "24px", className: styles('icon') }),
                        React.createElement("div", { className: styles('text') },
                            React.createElement(HTML, { html: activity.description }),
                            activity.payment_information && (React.createElement(React.Fragment, null,
                                React.createElement("div", { style: { display: 'flex' } },
                                    React.createElement("b", { style: { marginRight: '5px' } },
                                        t(i18nKeys.AMOUNT),
                                        " : "),
                                    React.createElement(Amount, { value: activity.payment_information.localized_money_object.amount, localizedValue: activity.payment_information.amount, suffix: activity.payment_information.currency })),
                                React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.DATE),
                                        " : "),
                                    React.createElement(DateItem, { lg: currentLang, date: activity.payment_information.date })),
                                React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.MODALITY),
                                        " : "),
                                    React.createElement("span", null, activity.payment_information.modality)),
                                activity.payment_information.notes && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.NOTES),
                                        " : "),
                                    React.createElement("span", null, activity.payment_information.notes))),
                                activity.payment_information.counterparty_name && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.ACTIVITIES.COUNTERPART),
                                        " : "),
                                    React.createElement("span", null, activity.payment_information.counterparty_name))),
                                activity.payment_information.external_id && (React.createElement("div", null,
                                    React.createElement("b", null,
                                        t(i18nKeys.ACTIVITIES.EXTERNAL_ID),
                                        " : "),
                                    React.createElement("span", null, activity.payment_information.external_id))))),
                            activity.comment && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.COMMENT),
                                    " : "),
                                React.createElement("span", null, activity.comment))),
                            activity.reason && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.REASON),
                                    " : "),
                                React.createElement("span", null, activity.reason))),
                            activity.end_pause_at && (React.createElement("div", null,
                                React.createElement("b", null,
                                    t(i18nKeys.END_OF_PAUSE),
                                    " : "),
                                React.createElement(DateItem, { lg: currentLang, date: activity.end_pause_at }))))),
                    React.createElement("div", { className: styles('right') },
                        activity.attachment_url && (React.createElement("div", { className: styles('img-wrapper') },
                            React.createElement("img", { src: activity.attachment_url, alt: "avatar" }))),
                        React.createElement("span", { className: styles('text') },
                            date,
                            " ",
                            t(i18nKeys.FROM),
                            " ",
                            React.createElement("span", null, activity.author)))),
                sortedStatuses.length !== 0 && canUseEmailStatus && (React.createElement(EmailStatusHistory, null,
                    React.createElement(FormSection, { title: t(i18nKeys.MAILS.HISTORY) }, sortedStatuses.map(function (_item, index) { return (React.createElement(EmailStatusHistoryItem, { key: index },
                        React.createElement("span", null, t(i18nKeys.MAILS.STATUS[_item.status.toUpperCase()])),
                        React.createElement("span", { style: { fontStyle: 'italic', color: '#bbb' } }, formattedDate(_item.timestamp, currentLang, 'DD/MM/YYYY, LT')))); }))))),
            ((_d = activity.invoice_feedback) === null || _d === void 0 ? void 0 : _d.conversation) && (React.createElement(Conversation, { className: styles('conversation'), invoiceId: activity.tracked_id, conversation: activity.invoice_feedback, onSubmit: onSubmitMessage, canNotComment: activity.invoice_feedback.closed })),
            React.createElement("div", { className: styles('bottom') },
                activity.tracked_type === 'Invoice' && (React.createElement("div", { className: styles('see-detail'), onClick: detailInvoice },
                    t(i18nKeys.ACTIVITIES.SEE_INVOICE),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                shouldShowLinkToReminder && (React.createElement("div", { className: styles('see-detail'), onClick: function () { var _a; return window.open("/reminders/".concat((_a = activity.reminder) === null || _a === void 0 ? void 0 : _a.id), '_blank'); } },
                    t(i18nKeys.REMINDER.SEE_REMINDER_DETAILS),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                activity.tracked_type === 'CreditNote' && (React.createElement("div", { className: styles('see-detail'), onClick: detailInvoice },
                    t(i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                activity.tracked_type === 'Task' && (React.createElement("div", { className: styles('see-detail'), onClick: detailTask },
                    t(i18nKeys.ACTIVITIES.SEE_TASK),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                showPaymentPlan && (React.createElement("div", { className: styles('see-detail'), onClick: detailPaymentPlan },
                    t(i18nKeys.ACTIVITIES.SEE_PAYMENT_PLAN),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                showClient && (React.createElement("div", { className: styles('see-detail'), onClick: detailDebtor },
                    t(i18nKeys.ACTIVITIES.SEE_CLIENT),
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "13px", className: styles('bold') }))),
                activity.has_document && (React.createElement("div", { className: styles('see-detail'), onClick: downloadDocument },
                    t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT),
                    React.createElement(Icon, { name: IconName.PDF, size: "13px", className: styles('bold') })))))));
}
