import React from 'react';
import { useDeleteContactPerson, useUpdateContactPerson } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { ContactPersonDetails } from '../ContactPersonDetails/ContactPersonDetails';
import { ContactPersonForm } from '../ContactPersonForm/ContactPersonForm';
import styleIdentifiers from './ContactPersonsList.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonsListItem = function (_a) {
    var contactPerson = _a.item, debtor = _a.debtor, refetch = _a.refetch;
    var t = useTranslation().t;
    var updateContactPersonMutation = useUpdateContactPerson().mutate;
    var deleteContactPersonMutation = useDeleteContactPerson().mutate;
    var id = contactPerson.id, _b = contactPerson.attributes, main_contact = _b.main_contact, first_name = _b.first_name, last_name = _b.last_name, notifications = _b.notifications, job_title = _b.job_title;
    var showContactPerson = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: React.createElement(ContactPersonDetails, { contactPerson: contactPerson }),
            title: "".concat(first_name, " ").concat(last_name),
        });
    };
    var updateContactPerson = function (e) {
        e.stopPropagation();
        sideMenuShow({
            unmount: true,
            content: (React.createElement(ContactPersonForm, { contactPerson: contactPerson, onSubmit: function (data) {
                    updateContactPersonMutation({ id: debtor.id, contactPersonId: id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var deleteContactPerson = function (e) {
        e.stopPropagation();
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_CONTACT_PERSON), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteContactPersonMutation({ id: debtor.id, contactPersonId: id }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    return (React.createElement("tr", { className: styles('item'), onClick: showContactPerson },
        React.createElement("td", null, main_contact && React.createElement("span", { className: styles('main') }, t(i18nKeys.MAIN))),
        React.createElement("td", null,
            first_name,
            " ",
            last_name),
        React.createElement("td", null, job_title),
        React.createElement("td", null, (notifications === null || notifications === void 0 ? void 0 : notifications.length) ? (React.createElement("div", { className: styles('notifications') }, notifications.map(function (notification) { return (React.createElement("span", { key: notification, className: styles('notification') }, t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[notification.toUpperCase()]))); }))) : ('-')),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: updateContactPerson },
                React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: deleteContactPerson },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
};
