import React from 'react';
import { useGetDomain, useUpdateDomain } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import CopyToClipboard from 'shared/components/CopyToClipboard';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Button, Group, LoadingOverlay } from '@mantine/core';
import styleIdentifiers from '../PreferencesForm.scss';
var styles = classNames.bind(styleIdentifiers);
var PLACEHOLDER_RECORD = {
    data: 'data',
    host: 'host',
    type: 'type',
    valid: false,
};
var PLACEHOLDER_DATA = {
    data: {
        id: '1',
        type: 'email_domain',
        attributes: {
            domain: 'domain.com',
            domain_valid: false,
            dns_records: {
                mail_cname: PLACEHOLDER_RECORD,
                dkim1: PLACEHOLDER_RECORD,
                dkim2: PLACEHOLDER_RECORD,
            },
        },
    },
};
export var DnsSettings = function (_a) {
    var domainId = _a.domainId;
    var t = useTranslation().t;
    var _b = useGetDomain(domainId), domainResponse = _b.data, isError = _b.isError, isLoading = _b.isLoading, refetch = _b.refetch;
    var _c = useUpdateDomain(), updateDomain = _c.mutate, isUpdatingDomain = _c.isLoading;
    if (isError) {
        return React.createElement("span", null, "Error");
    }
    var domainData = (domainResponse !== null && domainResponse !== void 0 ? domainResponse : PLACEHOLDER_DATA).data;
    var _d = domainData.attributes, domain = _d.domain, isDomainValid = _d.domain_valid, dns_records = _d.dns_records;
    var getIconName = function (isValid) {
        if (isUpdatingDomain)
            return 'SYNC';
        return isValid ? 'CHECK' : 'SIMPLE_REMOVE';
    };
    var getIconColor = function (isValid) {
        if (isUpdatingDomain)
            return 'BLUE';
        return isValid ? 'GREEN' : 'RED';
    };
    var renderRecord = function (record) {
        var data = record.data, host = record.host, type = record.type, isRecordValid = record.valid;
        return (React.createElement("div", { className: styles('dns-param') },
            React.createElement("h4", null,
                type.toUpperCase(),
                ' ',
                React.createElement(Icon, { name: IconName[getIconName(isRecordValid)], color: IconColor[getIconColor(isRecordValid)], rotate: isUpdatingDomain })),
            React.createElement("div", { className: styles('key-values') },
                React.createElement("div", { className: styles('key') },
                    React.createElement("p", null, "Nom")),
                React.createElement(CopyToClipboard, { textToCopy: host, text: host, custom: true })),
            React.createElement("div", { className: styles('key-values') },
                React.createElement("div", { className: styles('key') },
                    React.createElement("p", null, "Valeur")),
                React.createElement(CopyToClipboard, { textToCopy: data, text: data, custom: true }))));
    };
    var verifyDomain = function () {
        updateDomain({ id: domainId }, {
            onSuccess: function () { return refetch(); },
        });
    };
    return (React.createElement("div", { className: styles('dns-settings') },
        React.createElement(LoadingOverlay, { visible: isLoading }),
        React.createElement("p", null, t(i18nKeys.CUSTOM_DOMAIN.DNS_INFO)),
        React.createElement("div", { className: styles('domain') },
            React.createElement("p", null, domain),
            React.createElement(Icon, { name: IconName[getIconName(isDomainValid)], color: IconColor[getIconColor(isDomainValid)], rotate: isUpdatingDomain })),
        React.createElement("p", { className: styles('validate') }, t(i18nKeys.CUSTOM_DOMAIN.VALIDATE_DOMAIN)),
        Object.values(dns_records).map(function (record) { return renderRecord(record); }),
        React.createElement(Group, { justify: "end" },
            React.createElement(Button, { size: "md", loading: isUpdatingDomain, onClick: verifyDomain }, t(i18nKeys.CUSTOM_DOMAIN.VERIFY)))));
};
