import { __read } from "tslib";
import React, { useState } from 'react';
import { UpdateFooterSchema, useLoadInvoiceFooter, useUpdateInvoiceFooter } from 'shared/hooks';
import { LoadingOverlay, SimpleGrid } from '@mantine/core';
import { EditFooterItemModal } from './EditFooterItemModal';
import { FooterItem } from './FooterItem';
export default function InvoiceFooterCustomization() {
    var _a = useLoadInvoiceFooter(), invoiceFooter = _a.invoiceFooter, isInvoiceFooterLoading = _a.isInvoiceFooterLoading;
    var updateInvoiceFooter = useUpdateInvoiceFooter().updateInvoiceFooter;
    var _b = __read(useState(null), 2), selectedItemIndex = _b[0], setSelectedItemIndex = _b[1];
    if (isInvoiceFooterLoading || invoiceFooter == null)
        return React.createElement(LoadingOverlay, { visible: true });
    var fields = invoiceFooter.fields;
    return (React.createElement(React.Fragment, null,
        React.createElement(SimpleGrid, { cols: 3, spacing: "lg" }, fields.map(function (item, index) { return (React.createElement(FooterItem, { key: index, item: item, onClickEdit: function () { return setSelectedItemIndex(index); } })); })),
        React.createElement(EditFooterItemModal, { onClose: function () { return setSelectedItemIndex(null); }, isOpen: selectedItemIndex != null, item: selectedItemIndex != null ? fields[selectedItemIndex] : null, onSave: function (newItem) {
                return updateInvoiceFooter(UpdateFooterSchema.parse(invoiceFooter.fields.toSpliced(selectedItemIndex, 1, newItem)), { onSuccess: function () { return setSelectedItemIndex(null); } });
            } })));
}
