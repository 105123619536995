import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import styleIdentifiers from '../Integration.scss';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { CodaboxForm } from './CodaboxForm';
var styles = classNames.bind(styleIdentifiers);
export var Codabox = function (_a) {
    var _b = _a.history, goBack = _b.goBack, replace = _b.replace;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var canActivate = getIntegrationRights(Integration.CODABOX, company).canActivate;
    useEffect(function () {
        if (!canActivate) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var handleSubmit = function (data) {
        onBoardingActions.codaboxToken({
            settings: true,
            data: data,
            callback: function () {
                INTEGRATION_UPDATE_CALLBACK();
                history.push('/settings/integrations');
            },
        });
    };
    var tokenRequest = function () {
        return onBoardingActions.codaboxRequest({
            settings: true,
            callback: function (data) {
                onBoardingActions.changeCodaboxMessage(data.message);
            },
        });
    };
    return (React.createElement("article", { className: styles('codabox') },
        React.createElement(IntegrationHeader, { name: Integration.CODABOX, goBack: goBack }),
        React.createElement(CodaboxForm, { onSubmit: handleSubmit, tokenRequest: tokenRequest })));
};
