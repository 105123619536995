import { __assign } from "tslib";
import { treatClient } from 'shared/serializer';
import { clientConstants as events } from 'store/client/client.actions';
import { baseReducerData, baseReducerListPage, handlePageRequest, handlePageResponse, handleRequest, handleResponse, } from 'store/reducers';
import { sessionConstants as session } from 'store/session/session.actions';
export var initialState = {
    list: baseReducerListPage,
    current: baseReducerData,
    search: null,
    toConfirm: baseReducerListPage,
    isLoading: false,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.batchAction.request:
            return __assign(__assign({}, state), { isLoading: true });
        case events.batchAction.result:
            return __assign(__assign({}, state), { isLoading: false });
        case events.listPage.request:
            return handlePageRequest(state, action, 'list');
        case events.listPage.result:
            return handlePageResponse(state, action, 'list', function (item) { return item; });
        case events.detail.request:
            return handleRequest(state, action, 'current', true);
        case events.detail.result:
            return handleResponse(state, action, 'current', treatClient);
        case events.search.result:
            return handleResponse(state, action, 'search', treatClient);
        case events.update.result:
            return handleResponse(state, action, 'current', treatClient);
        case events.getAllToConfirm.request:
            return handlePageRequest(state, action, 'toConfirm');
        case events.getAllToConfirm.result:
            return handlePageResponse(state, action, 'toConfirm', treatClient);
        case events.getToConfirm.request:
            return handleRequest(state, action, 'current', true);
        case events.getToConfirm.result:
            return handleResponse(state, action, 'current', treatClient);
        case events.validateAllImportedClients.result:
            var newState = __assign({}, state);
            if (action.status === 'fulfilled') {
                newState.toConfirm = baseReducerListPage;
            }
            return newState;
        case session.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
