import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import styleIdentifiers from './ProductsDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ProductsDetailItem(_a) {
    var productLine = _a.productLine;
    var t = useTranslation().t;
    var current_product_name = productLine.current_product_name, quantity = productLine.quantity, current_unity_price_htva = productLine.current_unity_price_htva, total_htva = productLine.total_htva, discount_rate = productLine.discount_rate, current_vat_rate = productLine.current_vat_rate, total_tvac = productLine.total_tvac;
    var isInvoice = !!(productLine === null || productLine === void 0 ? void 0 : productLine.linked_invoice_id);
    return (React.createElement("tr", { className: styles('product-line') },
        React.createElement("td", { className: styles('left') }, current_product_name),
        React.createElement("td", null, !isInvoice ? quantity : '-'),
        React.createElement("td", null,
            React.createElement(Amount, { value: current_unity_price_htva })),
        React.createElement("td", null,
            React.createElement(Amount, { value: total_htva })),
        React.createElement("td", null, !isInvoice ? React.createElement(Percentage, { value: discount_rate }) : '-'),
        React.createElement("td", null, !isInvoice ? React.createElement(Percentage, { value: current_vat_rate }) : ' -'),
        React.createElement("td", { className: styles('right') },
            React.createElement(Amount, { value: total_tvac }))));
}
