import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ColorLogoPartialForm from 'shared/forms/InvoiceSettingsForm/ColorLogoPartialForm/ColorLogoPartialForm';
import { Button } from 'shared/io';
import styleIdentifiers from './ColorLogoForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ColorLogoForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({ shouldUnregister: true });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('color-logo-collection-form'), onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(ColorLogoPartialForm, null),
            React.createElement(Button, { className: styles('button'), label: t(i18nKeys.ONBOARDING.NEXT_STEP), type: "submit" }))));
}
