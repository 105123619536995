import { useEffect } from 'react';
import posthog from 'posthog-js';
import { isProduction } from 'shared/utils';
export function useInitialisePosthog() {
    useEffect(function () {
        if (isProduction()) {
            posthog.init('phc_f3vuQblHJAnPKJA0qKZan0PmalMguSQJdPFSEw0Upsc', {
                api_host: 'https://eu.i.posthog.com',
                person_profiles: 'identified_only',
            });
        }
    }, []);
}
