import { removeAttributes } from 'shared/utils/view';
import { compareItems, flattenItem } from 'store/reducers';
import { treatRecoveryPlan } from './settings.serializer';
export var treatCompany = function (item) {
    // @ts-ignore
    if (item == null)
        return null;
    var newItem = flattenItem(item, [], ['send_copy_emails', 'company_bank_accounts']);
    newItem.invoice_customization.billing_logs = newItem.invoice_customization.billing_logs.map(function (billing_log) { return removeAttributes(billing_log); });
    if (newItem.default_bank_account_id && newItem.bank_accounts_attributes) {
        newItem.bank_account = newItem.bank_accounts_attributes.find(function (it) {
            return compareItems(it, newItem.default_bank_account_id, 'id');
        });
    }
    else if (newItem.can_edit_iban) {
        newItem.bank_account = {
            iban: newItem.iban,
            bic: newItem.bic,
        };
    }
    newItem.recovery_plan = newItem.recovery_plan.data
        ? treatRecoveryPlan(newItem.recovery_plan.data)
        : null;
    newItem.company_bank_accounts = newItem.company_bank_accounts.map(function (bank_account) {
        return removeAttributes(bank_account);
    });
    newItem.isFrenchClient = newItem.address_attributes.country_code === 'FR';
    return newItem;
};
