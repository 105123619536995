import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import SendInvoiceFormEmail from 'shared/forms/SendInvoiceForm/SendInvoiceFormEmail';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { addressesToAttributes, emailValues, getDefaultAddresses, getDefaultEmails, } from 'shared/utils/contacts';
import { settingsActions } from 'store/settings/settings.actions';
import styleIdentifiers from './SendingOptionsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SendingOptionsForm(_a) {
    var _b, _c, _d;
    var customButtonText = _a.customButtonText, customText = _a.customText, debtorSendingOptions = _a.debtorSendingOptions, initialValues = _a.initialValues, invoice = _a.invoice, invoiceActionsData = _a.invoiceActionsData, onSubmit = _a.onSubmit, refetchSendingOptions = _a.refetchSendingOptions, setTemplate = _a.setTemplate, type = _a.type, setIsPostSelected = _a.setIsPostSelected, setIsEmailSelected = _a.setIsEmailSelected;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var isLoading = useSelector(function (state) { return state.invoice.isLoading; });
    var _e = __read(useState([]), 2), templates = _e[0], setTemplates = _e[1];
    var emails = (debtorSendingOptions !== null && debtorSendingOptions !== void 0 ? debtorSendingOptions : { emails: [] }).emails;
    var defaultDebtorEmails = getDefaultEmails(emails, Notification.reminders);
    var defaultEmailValues = emailValues(defaultDebtorEmails);
    var addresses = (debtorSendingOptions !== null && debtorSendingOptions !== void 0 ? debtorSendingOptions : { addresses: [] }).addresses;
    var defaultAddresses = getDefaultAddresses(addresses, Notification.reminders);
    var addressesAttributes = addressesToAttributes(defaultAddresses);
    // Make sure you understand which defaults come from the debtor, and which ones
    // come from the invoice's own settings. Invoice settings should override.
    var defaultSendingMedia = ((_b = initialValues.sending_media_attributes) !== null && _b !== void 0 ? _b : []).map(function (val) { return val.name; });
    var form = useForm({
        defaultValues: __assign(__assign({}, initialValues), { emails: invoice ? invoice.sending_options_attributes.emails : defaultEmailValues, addresses_attributes: addressesAttributes, send_email: defaultSendingMedia.includes(SendingMediaNames.email), send_post: defaultSendingMedia.includes(SendingMediaNames.post), send_registered_post: defaultSendingMedia.includes(SendingMediaNames.registered_post) }),
    });
    var handleSubmit = form.handleSubmit, watch = form.watch, reset = form.reset, register = form.register;
    useEffect(function () {
        settingsActions.templateList({
            data: { step_type: 'additional_reminder' },
            callback: function (_a) {
                var data = _a.data;
                setTemplates(data.map(function (template) { return treatTemplate(template); }));
            },
        });
    }, []);
    var _f = form.getValues(), send_email = _f.send_email, send_post = _f.send_post, send_registered_post = _f.send_registered_post;
    useEffect(function () { return setIsEmailSelected === null || setIsEmailSelected === void 0 ? void 0 : setIsEmailSelected(send_email); }, [send_email]);
    useEffect(function () { return setIsPostSelected === null || setIsPostSelected === void 0 ? void 0 : setIsPostSelected(send_post || send_registered_post); }, [send_post, send_registered_post]);
    useEffect(function () {
        var values = type === 'additional_reminder'
            ? __assign(__assign({}, invoiceActionsData === null || invoiceActionsData === void 0 ? void 0 : invoiceActionsData.sending_options), { addresses_attributes: addressesAttributes }) : initialValues;
        reset(values, { keepValues: true });
    }, [initialValues, invoiceActionsData]);
    var sendEmail = watch('send_email');
    var sendPost = watch('send_post');
    var sendRegisteredPost = watch('send_registered_post');
    var groupedTypes = [
        {
            description: t(i18nKeys.INVOICE.SELECTED_ONLY),
            value: '',
        },
        {
            description: t(i18nKeys.INVOICE.IN_PROGRESS),
            value: 'unpaid_invoices',
        },
        {
            description: t(i18nKeys.INVOICE.LATE_ONLY),
            value: 'overdue_invoices',
        },
        {
            description: t(i18nKeys.CLIENT_BALANCE),
            value: 'debtor_balance',
        },
    ];
    var submitDisabled = isLoading ||
        (sendEmail && ((_c = watch('emails')) !== null && _c !== void 0 ? _c : []).length === 0) ||
        ((sendPost || sendRegisteredPost) && ((_d = watch('addresses_attributes')) !== null && _d !== void 0 ? _d : []).length === 0);
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('sending-options-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('summary') }, customText || t(i18nKeys.INVOICE.SENDING_OPTIONS_SUMMARY)),
                setTemplate && (React.createElement(Controller, { name: "template_id", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('input'), keyText: "name", keyValue: "id", onValueChanged: setTemplate, name: "template_id", items: templates, label: t(i18nKeys.COMMON.TEMPLATE) })); } })),
                !invoice && (React.createElement(React.Fragment, null,
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", name: "grouped_type", items: groupedTypes, label: t(i18nKeys.STATEMENT) })); } }),
                    React.createElement("div", { className: styles('add-fees') },
                        React.createElement(Checkbox, { register: register('add_late_fees'), watch: watch, label: React.createElement("span", null, t(i18nKeys.LATE_FEES_INCLUDED)) })))),
                React.createElement("div", { className: styles('send-by') },
                    React.createElement("div", { className: styles('label-input') }, t(i18nKeys.INVOICE.SEND_REMINDERS_BY)),
                    React.createElement("div", { className: styles('sending-media') }, constants.sending_medium.map(function (_a) {
                        var value = _a.value, description = _a.description;
                        return (React.createElement(Checkbox, { key: value, register: register("send_".concat(value)), watch: watch, label: React.createElement("span", null, description), disabled: (value === SendingMediaNames.post && watch('send_registered_post')) ||
                                (value === SendingMediaNames.registered_post && watch('send_post')) }));
                    }))),
                invoice && sendEmail && React.createElement(SendInvoiceFormEmail, { emails: emails }),
                invoice && refetchSendingOptions && (React.createElement(AddContactPersonButton, { debtorId: invoice.debtor_id, refetch: function () { return refetchSendingOptions(); }, withMargin: true })),
                invoice && (sendPost || sendRegisteredPost) && (React.createElement(AddressPartialForm, { addresses: addresses }))),
            (sendEmail || sendPost || sendRegisteredPost) && (React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { isLoading: isLoading, label: customButtonText || t(i18nKeys.SUBMIT), type: "submit", disabled: invoice && submitDisabled }))))));
}
