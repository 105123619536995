import { __read } from "tslib";
import React, { useReducer } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { isDefined } from 'remeda';
import { reducerState } from 'shared/utils/view';
import { ActionIcon, Center, Group, Stack, Table, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import TableFooter from '../CustomTable/TableFooter';
export default function ListResModal(_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var _b = __read(useReducer(reducerState, {
        showElements: data.slice(0, 20),
        currentPage: 1,
        feedback: data.reduce(function (acc, _a) {
            var error_message = _a.error_message;
            if (error_message) {
                acc.error++;
            }
            else {
                acc.success++;
            }
            return acc;
        }, { error: 0, success: 0 }),
    }), 2), _c = _b[0], showElements = _c.showElements, currentPage = _c.currentPage, feedback = _c.feedback, setState = _b[1];
    var setPage = function (_a) {
        var selected = _a.selected;
        setState({
            currentPage: selected + 1,
            showElements: data.slice(selected * 20, (selected + 1) * 20),
        });
    };
    return (React.createElement(Stack, { align: "stretch", justify: "center", mih: "100px", p: "20px" },
        React.createElement(Group, { w: "100%", justify: "space-around", mb: "sm" },
            feedback.success > 0 && (React.createElement(Text, { c: "green" }, t(i18nKeys.SUCCESSFULL_ACTIONS, { count: feedback.success }))),
            feedback.error > 0 && (React.createElement(Text, { c: "red" }, t(i18nKeys.FAILED_ACTIONS, { count: feedback.error })))),
        React.createElement(Group, { mah: "65vh", style: { overflowY: 'auto' } },
            React.createElement(Table, { withRowBorders: false },
                React.createElement(Table.Tbody, null, showElements.map(function (_a) {
                    var id = _a.id, key = _a.key, error_message = _a.error_message, message = _a.message, title = _a.title;
                    return (React.createElement(Table.Tr, { key: id },
                        React.createElement(Table.Td, { w: "45%", ta: "left" },
                            React.createElement(Text, { fw: 600, fz: isDefined(key) ? 'md' : 'sm', lineClamp: 1 }, key !== null && key !== void 0 ? key : title)),
                        React.createElement(Table.Td, null,
                            React.createElement(Center, null,
                                React.createElement(ActionIcon, { style: { cursor: 'default' }, variant: "transparent", color: error_message ? 'red' : 'green' }, error_message ? React.createElement(IconX, { stroke: 1.5, size: 20 }) : React.createElement(IconCheck, { size: 20 })))),
                        React.createElement(Table.Td, null,
                            React.createElement(Text, { ta: "left", size: "sm", c: "grey.5" }, error_message !== null && error_message !== void 0 ? error_message : message))));
                })))),
        data.length > 20 && (React.createElement(TableFooter, { currentPage: currentPage, noShadow: true, itemsLength: showElements.length, noPageLimit: true, setPage: setPage, pagination: {
                current_page: currentPage,
                last_page: Math.ceil(data.length / 20),
                page_limit: 20,
                total_objects: data.length,
            } }))));
}
