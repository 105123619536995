import { __assign } from "tslib";
import React from 'react';
import { settingsActions } from 'store/settings/settings.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import CustomVariableForm from './CustomVariableForm';
export default function EditCustomVariable(_a) {
    var customVariable = _a.customVariable, sideMenuProps = _a.sideMenuProps;
    var submitCustomVariable = function (values) {
        if (customVariable === null || customVariable === void 0 ? void 0 : customVariable.id) {
            settingsActions.setCustomVariable({
                id: customVariable.id,
                data: __assign({}, values),
                callback: sideMenuHide(),
            });
        }
        else {
            settingsActions.addCustomVariable({
                data: values,
                callback: sideMenuHide(),
            });
        }
    };
    var showSideMenu = function () {
        var init = __assign({}, customVariable);
        sideMenuShow(__assign(__assign({ unmount: true, noBackgroundClose: true }, sideMenuProps), { content: React.createElement(CustomVariableForm, { onSubmit: submitCustomVariable, initialValues: init }) }));
    };
    showSideMenu();
}
