import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Textfit } from 'react-textfit';
import { times } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared/constants';
import { Box, Group, Stack } from '@mantine/core';
export var CollectionStep = function (_a) {
    var step = _a.step;
    var t = useTranslation().t;
    var _b = STEP_TO_STEP_THEME[step], color = _b.color, filledDots = _b.filledDots;
    var getColor = function (index) {
        if (index < filledDots)
            return color;
        return '#343944';
    };
    return (React.createElement(Stack, { gap: "4", fw: 500 },
        React.createElement(Textfit, { mode: "single", max: 16, min: 12 }, t(i18nKeys.FOLLOW_UP.SUMMARY.COLLECTION_STEP[step])),
        React.createElement(Group, { gap: 5 }, times(6, function (index) {
            return (React.createElement(Box, { key: index, h: 4, w: 15, style: { borderRadius: '2px' }, bg: getColor(index) }));
        }))));
};
