import React from 'react';
import Activities from 'app/Private/Activities';
import CreditNotesListing from 'app/Private/CreditNotes/CreditNoteListing';
import { Dashboard } from 'app/Private/Dashboard';
import InvoicesDetail from 'app/Private/Invoices/Detail';
import ImminentReminders from 'app/Private/Invoices/ImminentReminders';
import InvoicesListing from 'app/Private/Invoices/Listing';
import InvoicesToHandle from 'app/Private/Invoices/ToHandle';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import ProductsListing from 'app/Private/Products/Listing';
import SettingsChunk from 'app/Private/Settings';
import ThirdpartyCasesListing from 'app/Private/ThirdpartyCases/ThirdpartyCaseListing';
import TransactionHistory from 'app/Private/Transactions/History';
import Reconciliation from 'app/Private/Transactions/Reconciliation';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales/';
import { upperFirst } from 'lodash-es';
import { Kanban } from 'modules/Kanban';
import { Reminders } from 'modules/Reminders';
import { Reports } from 'modules/Reports';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { Switch, useLocation, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import NavigationPrompt from 'shared/components/BlockNavigation';
import Loading from 'shared/components/Loading';
import { isConnectorActive } from 'shared/utils/connector';
import { success } from 'store/view/view.actions';
import { Analytics } from '../../modules/Analytics';
import { DuplicatesClientDetail } from './Clients/Duplicates/DuplicatesClientDetail';
import { DuplicatesClients } from './Clients/Duplicates/DuplicatesClients';
import ManageCreditNote from './CreditNotes/Manage/Manage';
import ImportedInvoiceDetail from './Invoices/ImportedInvoiceDetail';
import ImportedInvoices from './Invoices/ImportedInvoices';
import InvoiceListToConfirm from './Invoices/InvoiceListToConfirm';
import ManageInvoice from './Invoices/Manage/Manage';
import { AcceptTermsOfService } from './AcceptTermsOfService';
import { ClientDetail, ClientsImportedDetail, ClientsList, ClientsToConfirmList } from './Clients';
import { ExternalMailDetail, ExternalMailList } from './ExternalMailbox';
import ImportPDFInvoice from './ImportPDFInvoice';
import { PaymentPlanDetail, PaymentPlanEditForm, PaymentPlanForm, PaymentPlansList, } from './PaymentPlans';
import { CustomTasksList, TaskDetail } from './Tasks';
import styleIdentifiers from './logged.scss';
var styles = classNames.bind(styleIdentifiers);
var RedirectInvoiceActivity = function () {
    var _a = useRouteMatch(), _b = _a.params, id = _b.id, activityId = _b.activityId, path = _a.path;
    return (React.createElement(Redirect, { to: "/".concat(path.includes('invoices') ? 'invoices' : 'credit-notes', "/").concat(id, "?activityId=").concat(activityId) }));
};
export var PrivateRoutes = function () {
    var t = useTranslation().t;
    var location = useLocation();
    //! The Id contained in the company object above is wrong, and actually a leak of the CompanyConfiguration model's Id.
    //! Use this one instead:
    var backgroundLoader = useSelector(function (state) { return state.view.backgroundLoader; });
    var hasActiveConnector = isConnectorActive();
    return (React.createElement(React.Fragment, null,
        backgroundLoader.active && (React.createElement("div", { className: styles('bg-loader', 'card') },
            React.createElement(NavigationPrompt, { canChangePage: true, when: true, onSaveAndQuit: function () { } }),
            React.createElement("div", { className: styles('bg-loader-title') }, backgroundLoader.title),
            React.createElement("div", { className: styles('bg-loader-body') },
                React.createElement("span", null, backgroundLoader.description),
                React.createElement("div", null,
                    React.createElement(Loading, { active: true, size: "small" }))))),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/dashboard", component: Dashboard }),
            React.createElement(Route, { path: "/kanban", component: Kanban }),
            React.createElement(Route, { exact: true, path: "/clients", component: ClientsList }),
            React.createElement(Route, { path: "/clients/:id", component: ClientDetail }),
            React.createElement(Route, { path: "/client/duplicates", component: DuplicatesClients }),
            React.createElement(Route, { path: "/client/to-confirm", component: ClientsToConfirmList }),
            React.createElement(Route, { path: "/client-to-confirm/:id", component: ClientsImportedDetail }),
            React.createElement(Route, { path: "/client-to-merge/:id", component: DuplicatesClientDetail }),
            React.createElement(Route, { path: "/invoices/actions", component: ImminentReminders }),
            React.createElement(Route, { path: "/invoices/listing", component: InvoicesListing }),
            React.createElement(Route, { path: "/invoices/to-handle", component: InvoicesToHandle }),
            React.createElement(Route, { path: "/invoices/detail/:id", component: InvoicesDetail }),
            React.createElement(Route, { path: "/invoices/import", component: ImportedInvoices }),
            React.createElement(Route, { path: "/invoices/to-confirm", component: InvoiceListToConfirm }),
            React.createElement(Route, { path: "/invoice/to-confirm/:id", component: ImportedInvoiceDetail }),
            React.createElement(Route, { path: "/invoices/create", component: ManageInvoice }),
            React.createElement(Route, { path: "/invoices/edit/:id", component: ManageInvoice }),
            React.createElement(Route, { path: "/invoices/import-pdf", component: ImportPDFInvoice }),
            React.createElement(Route, { path: "/invoices/:id/:activityId", component: RedirectInvoiceActivity }),
            React.createElement(Route, { path: "/invoices/:id", component: ToHandleDetail }),
            React.createElement(Route, { path: "/credit-notes/listing", component: CreditNotesListing }),
            React.createElement(Route, { path: "/credit-notes/create", component: ManageCreditNote }),
            React.createElement(Route, { path: "/credit-notes/edit/:id", component: ManageCreditNote }),
            React.createElement(Route, { path: "/credit-notes/detail/:id", render: function () { return React.createElement(InvoicesDetail, { credit: true }); } }),
            React.createElement(Route, { path: "/credit-notes/:id/:activityId", component: RedirectInvoiceActivity }),
            React.createElement(Route, { path: "/credit-notes/:id", render: function () { return React.createElement(ToHandleDetail, { isCreditNote: true }); } }),
            React.createElement(Route, { path: "/reminders", component: Reminders }),
            React.createElement(Route, { path: "/activities", component: Activities }),
            React.createElement(Route, { path: "/transactions/reconciliation", component: Reconciliation }),
            React.createElement(Route, { path: "/transactions/history", component: TransactionHistory }),
            !hasActiveConnector ? React.createElement(Route, { path: "/products", component: ProductsListing }) : null,
            React.createElement(Route, { path: "/thirdparty_cases", component: ThirdpartyCasesListing }),
            React.createElement(Route, { path: "/payment-plans/listing", component: PaymentPlansList }),
            React.createElement(Route, { path: "/payment-plans/create", component: PaymentPlanForm }),
            React.createElement(Route, { path: "/payment-plan/:id/edit", component: PaymentPlanEditForm }),
            React.createElement(Route, { path: "/payment-plan/:id", component: PaymentPlanDetail }),
            React.createElement(Route, { path: "/mails/:id", component: ExternalMailDetail }),
            React.createElement(Route, { path: "/mails", component: ExternalMailList }),
            React.createElement(Route, { path: "/tasks/:id", component: TaskDetail }),
            React.createElement(Route, { path: "/tasks", component: CustomTasksList }),
            React.createElement(Route, { path: "/analytics", component: Analytics }),
            React.createElement(Route, { path: "/reports", component: Reports }),
            React.createElement(Route, { path: "/terms-and-conditions", component: AcceptTermsOfService }),
            React.createElement(Route, { path: "/settings", component: SettingsChunk }),
            React.createElement(Route, { path: "/callback", render: function () {
                    var queryParams = qs.parse(location.search);
                    var integration = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
                    if (JSON.parse(queryParams.successfulConnection || false)) {
                        success({
                            data: t(i18nKeys.FORM.SUCCESSFULLY_CONNEXION, {
                                text: upperFirst(integration),
                            }),
                        });
                    }
                    return (React.createElement(Redirect, { to: "/settings/integration/".concat(integration, "?").concat(queryParams.errorMessage ? "errorMessage=".concat(queryParams.errorMessage) : '') }));
                } }),
            React.createElement(Route, { path: "/my-followup" },
                React.createElement(Redirect, { to: "/kanban/invoices" })),
            React.createElement(Redirect, { to: "/dashboard" }))));
};
