import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { accountActions } from 'store/account/account.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import styleIdentifiers from '../Integration.scss';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { DigitealForm } from './DigitealForm';
var styles = classNames.bind(styleIdentifiers);
export var Digiteal = function (_a) {
    var _b = _a.history, goBack = _b.goBack, replace = _b.replace;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var canActivate = getIntegrationRights(Integration.DIGITEAL, company).canActivate;
    useEffect(function () {
        if (!canActivate) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var handleSubmit = function () {
        accountActions.setDigitealConfiguration({
            data: {
                activate: true,
                use_digiteal_integration: true,
            },
            callback: INTEGRATION_UPDATE_CALLBACK,
        });
    };
    return (React.createElement("article", { className: styles('digiteal') },
        React.createElement(IntegrationHeader, { name: Integration.DIGITEAL, goBack: goBack }),
        React.createElement(DigitealForm, { onSubmit: handleSubmit })));
};
