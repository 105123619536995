import { styled } from '@stitches/react';
export var BarChartTooltip = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: 'var(--mantine-color-gray-1)',
    boxShadow: 'var(--mantine-shadow-md)',
    fontSize: 'var(--mantine-font-size-sm)',
    variants: {
        multi: {
            true: {
                flexDirection: 'column',
                flexGap: '6px',
                alignItems: 'flex-end',
                '> *:last-child': {
                    textAlign: 'right',
                    marginTop: '6px',
                    paddingTop: '6px',
                    width: '100%',
                    borderTop: '1px black solid',
                    fontWeight: 600,
                },
            },
        },
    },
});
