import { __assign } from "tslib";
import React, { useContext, useEffect, useRef } from 'react';
import { CommentForm, CommentItem } from 'app/Private/Comments';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { omit } from 'lodash-es';
import moment from 'moment';
import Card from 'shared/components/Card';
import { invoiceActions } from 'store/invoice/invoice.actions';
import InvoiceDetailContext from '../InvoiceDetail.context';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceDetailComment() {
    var t = useTranslation().t;
    var _a = useContext(InvoiceDetailContext), data = _a.data, credit = _a.credit;
    var commentCardRef = useRef(null);
    var comments = data.comments_attributes;
    useEffect(function () {
        commentCardRef.current.scrollTop = commentCardRef.current.scrollHeight;
    }, [comments]);
    var submitComment = function (values) {
        if (!data)
            return;
        invoiceActions.actionsComment({
            id: data.id,
            data: values,
        });
    };
    return (React.createElement(Card, { title: React.createElement("div", { className: styles('comment-head') }, t(i18nKeys.NOTES)), className: styles('comments') },
        React.createElement("div", { className: styles('comment-container'), ref: commentCardRef }, comments === null || comments === void 0 ? void 0 :
            comments.map(function (comment) { return (React.createElement(CommentItem, { comment: {
                    id: comment.id,
                    type: comment.type,
                    attributes: __assign(__assign({}, omit(comment, ['id', 'type', 'date'])), { date: moment.isMoment(comment.date) ? comment.date.toISOString() : comment.date }),
                }, key: comment.id })); }),
            !credit && (React.createElement(CommentForm, { onSubmit: submitComment, label: t(i18nKeys.ACTIONS.ADD), labelText: t(i18nKeys.NEW_NOTE) })))));
}
