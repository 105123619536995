import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NavLink } from 'react-router-dom';
import styleIdentifiers from './ExternalMailDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailDetailHead = function (_a) {
    var externalMail = _a.externalMail, _b = _a.fullDetail, fullDetail = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var _c = externalMail.attributes, debtor = _c.debtor, from_mail = _c.from_mail, from_name = _c.from_name, to_mail = _c.to_mail, cc = _c.cc, bcc = _c.bcc, attachments = _c.attachments;
    var wide = (attachments === null || attachments === void 0 ? void 0 : attachments.length) > 0 ? 'wide' : '';
    var restOfAttachments = attachments.length > 1 ? "+".concat(attachments.length - 1) : null;
    var renderAttachment = function (index) {
        var attachment = attachments[index];
        return (React.createElement("p", null,
            React.createElement("a", { href: attachment.url, target: "_blank", rel: "noreferrer" }, attachment.filename)));
    };
    return (React.createElement("div", { className: styles('detail-head') },
        React.createElement("div", { className: styles('grid', wide) },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.FORM.FROM)),
            React.createElement("div", { className: styles('value', 'flex') },
                from_name && React.createElement("span", null, from_name),
                from_name && React.createElement(React.Fragment, null, "<"),
                React.createElement("a", { href: "mailto:".concat(from_mail) }, from_mail),
                from_name && React.createElement(React.Fragment, null, ">"))),
        React.createElement("div", { className: styles('grid', wide) },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.FORM.TO)),
            React.createElement("div", null, to_mail.map(function (mail, idx) { return (React.createElement("a", { key: idx, className: styles('value', 'to-mail'), href: "mailto:".concat(mail) }, mail)); }))),
        fullDetail && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('grid', wide) },
                React.createElement("p", { className: styles('text') }, t(i18nKeys.CC)),
                React.createElement("p", { className: styles('value') }, cc || '-')),
            React.createElement("div", { className: styles('grid', wide) },
                React.createElement("p", { className: styles('text') }, t(i18nKeys.BCC)),
                React.createElement("p", { className: styles('value') }, bcc || '-')))),
        (fullDetail || debtor) && (React.createElement("div", { className: styles('grid', wide) },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.COMMON.CLIENT)),
            React.createElement("div", { className: styles('value') }, debtor ? (React.createElement(NavLink, { to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name)) : ('-')))),
        (attachments === null || attachments === void 0 ? void 0 : attachments.length) > 0 && (React.createElement("div", { className: styles('grid', wide) },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS)),
            React.createElement("div", { className: styles('value') }, fullDetail ? (React.createElement(React.Fragment, null, attachments.map(function (attachment, i) { return renderAttachment(i); }))) : (React.createElement("div", { className: styles('first-attachment') },
                renderAttachment(0),
                " ",
                restOfAttachments)))))));
};
