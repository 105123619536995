import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { DateSelector, SwitchInput } from 'shared/io';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName } from '../Integration.constants';
import { IntegrationForm } from '../IntegrationForm';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var DBasicsFormFieldsName;
(function (DBasicsFormFieldsName) {
    DBasicsFormFieldsName["IMPORT_INTEGRATION"] = "use_dbasics_import_integration";
    DBasicsFormFieldsName["PAYMENT_MATCHING"] = "use_dbasics_payment_matching";
})(DBasicsFormFieldsName || (DBasicsFormFieldsName = {}));
export var DBasicsForm = function (_a) {
    var onSubmit = _a.onSubmit, onboarding = _a.onboarding, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), shouldPreventSubmit = _b[0], setShouldPreventSubmit = _b[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var handleSubmit = form.handleSubmit, control = form.control, watch = form.watch, setValue = form.setValue;
    var submit = function (values) {
        if (shouldPreventSubmit) {
            showDialog({
                id: DialogShowId.CONFIRM,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE), onConfirm: function () {
                        setShouldPreventSubmit(false);
                        onSubmit(values);
                    } })),
            });
        }
        else {
            onSubmit(values);
        }
    };
    var debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
    var importIntegration = watch(DBasicsFormFieldsName.IMPORT_INTEGRATION);
    useEffect(function () {
        if (importIntegration) {
            setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                : new Date());
        }
    }, [importIntegration, initialValues, setValue]);
    useEffect(function () {
        form.reset(initialValues);
    }, [initialValues]);
    return (React.createElement(IntegrationForm, { integration: Integration.DBASICS, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.DBASICS.SUMMARY, form: form },
        React.createElement("div", { className: styles('grid-row') },
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: DBasicsFormFieldsName.IMPORT_INTEGRATION, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                                text: Integration.DBASICS,
                            }) })));
                    } }),
                importIntegration && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(DateSelector, { name: IntegrationFormFieldsName.ISSUE_DATE_LIMIT, className: styles('issue-date'), noMinDate: true, label: t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT), placeholder: t(i18nKeys.ISSUE_DATE), endPlaceholder: t(i18nKeys.FORM.TO), required: true, handleChange: function (date) {
                                if (!onboarding) {
                                    setShouldPreventSubmit(true);
                                }
                                setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                            } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.DEBTOR_UPDATE, render: function (_a) {
                                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                                return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button', 'issue-date'), label: t(debtorUpdate
                                        ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                                        : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR) })));
                            } }))))),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: DBasicsFormFieldsName.PAYMENT_MATCHING, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                                text: Integration.DBASICS,
                            }) })));
                    } })),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.AUTOMATIC_SYNC, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC) })));
                    } })))));
};
