import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { upperFirst } from 'lodash-es';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import { treatTpCase } from 'shared/serializer';
import { dateFromNow } from 'shared/utils/view';
import { thirdPartyCaseActions } from 'store/thirdpartyCase/thirdpartycase.actions';
import Conversation from '../ActivityDetail/Conversation';
import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import styleIdentifiers from './TpCaseDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TpCaseDetail(_a) {
    var _b;
    var item = _a.item;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = __read(useState(), 2), thirdPartyCase = _d[0], setThirdPartyCase = _d[1];
    var caseTypeTranslated = t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE[item.case_type.toUpperCase()]);
    useEffect(function () {
        thirdPartyCaseActions.detail({
            id: item.id,
            callback: function (_case) {
                setThirdPartyCase(treatTpCase(_case));
            },
        });
    }, []);
    var getIcon = function () {
        switch (item.case_type) {
            case 'lawyer':
                return React.createElement(Icon, { name: IconName.LAW, size: "18px", className: styles('icon') });
            case 'bailiff':
                return React.createElement(Icon, { name: IconName.BALANCE, size: "18px", className: styles('icon') });
            case 'debt_collector':
                return React.createElement("img", { width: "24px", height: "24px", src: debtCollectorIcon, alt: "Debt collector" });
            case 'callcenter':
            default:
                return React.createElement(Icon, { name: IconName.PHONE_CALL, size: "18px", className: styles('icon') });
        }
    };
    var reloadTpCase = function () {
        thirdPartyCaseActions.detail({
            id: thirdPartyCase.id,
            case_type: thirdPartyCase.case_type,
            noLoading: true,
            noReset: true,
        });
    };
    var onSubmitMessage = function () {
        reloadTpCase();
    };
    return (React.createElement("div", { className: styles('tp-case-detail') }, thirdPartyCase && (React.createElement("div", { className: styles('content') },
        React.createElement("div", { className: styles('left-container') },
            React.createElement("div", { className: styles('top') },
                React.createElement("div", { className: styles('left') },
                    getIcon(),
                    React.createElement("div", { className: styles('text') },
                        thirdPartyCase.tp_owner && (React.createElement("div", null,
                            React.createElement("b", null,
                                caseTypeTranslated,
                                " : "),
                            React.createElement("span", null, upperFirst(thirdPartyCase.tp_owner)))),
                        thirdPartyCase.case_id && (React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.THIRD_PARTY_CASES.REFERENCE),
                                " : "),
                            React.createElement("span", null, upperFirst(thirdPartyCase.case_id)))),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.CREATION_DATE),
                                " : "),
                            React.createElement(DateItem, { lg: currentLang, date: thirdPartyCase.created_at })),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.COMMON.CLIENT),
                                " : "),
                            React.createElement("span", null, thirdPartyCase.invoice_debtor_name)),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED),
                                " : "),
                            React.createElement(Amount, { value: thirdPartyCase.total_tvac })),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.INVOICING.TOTAL_OUTSTANDING_PAYMENTS),
                                " : "),
                            React.createElement(Amount, { value: thirdPartyCase.total_remaining })))),
                React.createElement("div", { className: styles('right') },
                    React.createElement("span", { className: styles('text') },
                        t(i18nKeys.LAST_UPDATE),
                        ":",
                        React.createElement("br", null),
                        dateFromNow(thirdPartyCase.last_update, currentLang)))),
            React.createElement("div", { className: styles('bottom') }, thirdPartyCase.attached_invoices && (React.createElement(AttachedInvoicesList, { invoices: thirdPartyCase.attached_invoices, multiple: thirdPartyCase.attached_invoices.details.length > 1 })))),
        ((_b = thirdPartyCase.invoice_feedback) === null || _b === void 0 ? void 0 : _b.conversation) && (React.createElement("div", { className: styles('right-container') },
            React.createElement("div", { className: styles('conversation-card') },
                React.createElement("div", { className: styles('head') },
                    React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.CONVERSATION))),
                React.createElement(Conversation, { caseId: thirdPartyCase.id, className: styles('conversation'), invoiceId: thirdPartyCase.invoice_id, conversation: thirdPartyCase.invoice_feedback, onSubmit: onSubmitMessage }))))))));
}
