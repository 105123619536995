import { __awaiter, __generator, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { formatBankAccount } from 'shared/utils/normalization';
import { getBankImage } from 'shared/utils/view';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './bankAccount.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankAccount(_a) {
    var _this = this;
    var className = _a.className, small = _a.small, image = _a.image, _b = _a.value, value = _b === void 0 ? '' : _b;
    var _c = __read(useState(null), 2), imageSrc = _c[0], setImageSrc = _c[1];
    useEffect(function () {
        var fetchImageSrc = function () { return __awaiter(_this, void 0, void 0, function () {
            var bankImage, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!image) {
                            setImageSrc(null);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getBankImage(image)];
                    case 2:
                        bankImage = _a.sent();
                        setImageSrc(bankImage || null);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching bank image:', error_1);
                        setImageSrc(null);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchImageSrc();
    }, [image]);
    return (React.createElement("div", { className: styles('bank-account', className) },
        image && (React.createElement("div", { className: styles('bank-image') }, imageSrc ? React.createElement("img", { src: imageSrc, alt: "Bank logo" }) : React.createElement(Icon, { name: IconName.BANK }))),
        React.createElement("span", { className: styles('iban') }, small
            ? "".concat(value.substring(0, 4), " **** ").concat(value.substring(value.length - 4))
            : formatBankAccount(value))));
}
