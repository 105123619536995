import React from 'react';
import classNames from 'classnames/bind';
import { CodaboxForm } from 'shared/components/Integrations';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import styleIdentifiers from './Codabox.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Codabox(_a) {
    var onSubmit = _a.onSubmit;
    var tokenRequest = function () {
        onBoardingActions.codaboxRequest({
            callback: function (data) {
                onBoardingActions.changeCodaboxMessage(data.message);
            },
        });
    };
    return (React.createElement("div", { className: styles('Codabox') },
        React.createElement(CodaboxForm, { onSubmit: onSubmit, tokenRequest: tokenRequest })));
}
