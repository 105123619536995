import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isEmpty } from 'remeda';
import { useLoadLightUsers } from 'shared/hooks';
import { Avatar, Group, MultiSelect, TagsInput, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
export var RecipientsStep = function (_a) {
    var form = _a.form;
    var t = useTranslation().t;
    var users = useLoadLightUsers().users;
    var renderMultiSelectUser = function (_a) {
        var option = _a.option, checked = _a.checked;
        var user = users === null || users === void 0 ? void 0 : users.find(function (u) { return u.email === option.value; });
        return (React.createElement(Group, { w: "100%", gap: "sm", justify: "space-between" },
            React.createElement(Group, null,
                React.createElement(Avatar, { color: "blue", src: user.profilePicture, size: 36, radius: "xl" }, user.name.initials),
                React.createElement("div", null,
                    React.createElement(Text, { size: "sm" }, user.name.full),
                    React.createElement(Text, { size: "xs", opacity: 0.5 }, user.email))),
            checked && React.createElement(IconCheck, { color: "var(--mantine-color-blue-6)", size: 24 })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { size: "lg", fw: 500 }, t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.TITLE)),
        React.createElement(MultiSelect, __assign({ searchable: true }, form.getInputProps('collaborators'), { renderOption: renderMultiSelectUser, styles: {
                label: { fontWeight: 400, marginBottom: '5px' },
            }, placeholder: isEmpty(form.values.collaborators)
                ? t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS_PLACEHOLDER)
                : undefined, w: "100%", label: t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS), data: users === null || users === void 0 ? void 0 : users.map(function (user) { return ({ value: user.email, label: user.name.full }); }) })),
        React.createElement("div", null,
            React.createElement(TagsInput, __assign({}, form.getInputProps('emails'), { styles: {
                    label: { fontWeight: 400, marginBottom: '5px' },
                }, placeholder: isEmpty(form.values.emails)
                    ? t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_PLACEHOLDER)
                    : undefined, w: "100%", label: t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL) })),
            React.createElement(Text, { c: "dimmed", size: "sm", mt: 4 }, t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_SUBMIT_HINT)))));
};
