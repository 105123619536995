import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Button, ButtonColor } from 'shared/io';
import { formatAmount } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './ReconciliationMobileModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ReconciliationMobileModal(_a) {
    var selectedInvoices = _a.selectedInvoices, selectedPayments = _a.selectedPayments, submit = _a.submit;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    return (React.createElement("div", { className: styles('reconciliation-modal') },
        React.createElement("h3", null, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)),
        selectedPayments.map(function (payment) { return (React.createElement("div", { key: payment.id, className: styles('item') },
            React.createElement("div", null,
                React.createElement("span", { className: styles('blue') },
                    payment.counterparty_name,
                    " "),
                ' ',
                React.createElement("span", { className: styles('no-wrap') }, formatAmount(payment.amount)),
                ' '),
            React.createElement("div", { className: styles('bold') },
                t(i18nKeys.COMMUNICATION),
                " :"),
            React.createElement("div", null, payment.communication))); }),
        React.createElement("h3", null, t(i18nKeys.INVOICES)),
        selectedInvoices.map(function (invoice) { return (React.createElement("div", { key: invoice.id, className: styles('item') },
            React.createElement("div", null,
                React.createElement("span", { className: styles('blue') },
                    invoice.debtor_name,
                    " "),
                ' ',
                React.createElement("span", { className: styles('no-wrap') }, formatAmount(invoice.remaining_balance))),
            React.createElement("div", null,
                React.createElement("span", { className: styles('bold') },
                    " ",
                    invoice.reference,
                    " "),
                ' ',
                React.createElement("span", null, formattedDate(invoice.issue_date, currentLang))))); }),
        React.createElement(Button, { className: styles('variable', 'reconciliation-button'), label: t(i18nKeys.RECONCILE), noMargin: true, color: ButtonColor.GREEN, disabled: !(selectedInvoices.length > 0 && selectedPayments.length > 0), onClick: submit })));
}
