import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import React from 'react';
import { validationMessage } from 'shared/utils/validation';
import styleIdentifiers from './Textarea.scss';
var styles = classNames.bind(styleIdentifiers);
export var Textarea = function (_a) {
    var onFocus = _a.onFocus, label = _a.label, noBorder = _a.noBorder, withBorder = _a.withBorder, noMargin = _a.noMargin, rows = _a.rows, className = _a.className, oneLine = _a.oneLine, wrapperClassName = _a.wrapperClassName, inputClassName = _a.inputClassName, error = _a.error, ref = _a.ref, register = _a.register, shadow = _a.shadow, props = __rest(_a, ["onFocus", "label", "noBorder", "withBorder", "noMargin", "rows", "className", "oneLine", "wrapperClassName", "inputClassName", "error", "ref", "register", "shadow"]);
    var t = useTranslation().t;
    var handleInputFocus = function (event) {
        if (onFocus) {
            onFocus(event);
        }
    };
    return (React.createElement("div", { className: styles('Textarea', withBorder && 'with-border', error && 'error', !noBorder && 'bordered', noMargin && 'no-margin', shadow && 'shadow', className, oneLine && 'one-line') },
        React.createElement("div", { className: styles(wrapperClassName, 'input-wrapper') },
            React.createElement("div", { className: styles('label-input') }, label),
            React.createElement("textarea", __assign({}, register, { className: inputClassName, rows: rows || 3, onFocus: handleInputFocus }, props))),
        error && React.createElement("div", { className: styles('error-message') },
            " ",
            t(validationMessage[error.type]))));
};
