import { __assign, __rest, __values } from "tslib";
import { cloneDeep } from 'lodash-es';
import { removeAttributes } from 'shared/utils/view';
import { flattenItem } from 'store/reducers';
import { treatClient } from './debtor.serializer';
import { treatProduct } from './product.serializer';
export var invoiceFormToData = function (data, constants) {
    var e_1, _a;
    var _b;
    var toSend = __assign({}, cloneDeep(data));
    if (toSend.invoices_to_link) {
        delete toSend.invoices_to_link;
    }
    if (data.debtor_attributes) {
        toSend.debtor_id = data.debtor_attributes.id;
        delete toSend.debtor_attributes;
    }
    if ((_b = data.invoice_id) === null || _b === void 0 ? void 0 : _b.id) {
        toSend.invoice_id = data.invoice_id.id;
    }
    if (!toSend.id) {
        delete toSend.id;
    }
    if (toSend.product_lines_attributes) {
        var _loop_1 = function (productLine) {
            delete productLine.isInvoice;
            productLine.current_unity_price_htva =
                +productLine.product_attributes.current_unity_price_htva;
            delete productLine.product_attributes.current_unity_price_htva;
            productLine.total_htva =
                productLine.quantity *
                    productLine.current_unity_price_htva *
                    (1 - (productLine.discount_rate || 0) / 100);
            var tvaRate = constants.tax_categories.find(function (taxCategory) { return taxCategory.value === productLine.product_attributes.tax_category_code; }).vat_value;
            productLine.total_tvac =
                productLine.total_htva *
                    (data.debtor_attributes.intracom || data.debtor_attributes.co_contractor
                        ? 1
                        : 1 + +tvaRate / 100);
            if (productLine.product_id) {
                delete productLine.product_attributes;
            }
            else {
                delete productLine.product_id;
                productLine.product_attributes.price_htva = +productLine.current_unity_price_htva;
            }
            if (productLine.id) {
                delete productLine.product_attributes;
                delete productLine.product_id;
            }
            else {
                delete productLine.id;
            }
        };
        try {
            for (var _c = __values(toSend.product_lines_attributes), _d = _c.next(); !_d.done; _d = _c.next()) {
                var productLine = _d.value;
                _loop_1(productLine);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return toSend;
};
export var treatInvoice = function (item) {
    var _a, _b;
    var excludedKeys = ['debtor'];
    var data = flattenItem(item, excludedKeys, [
        'actions',
        'manual_payments',
        'external_payments',
        'account_manager',
    ]);
    if (data === null || data === void 0 ? void 0 : data.account_manager) {
        data.account_manager = removeAttributes(data.account_manager);
    }
    if ((_a = data === null || data === void 0 ? void 0 : data.next_step_information) === null || _a === void 0 ? void 0 : _a.conversation) {
        data.next_step_information.conversation = removeAttributes(data.next_step_information.conversation);
        data.next_step_information.conversation.messages =
            data.next_step_information.conversation.messages.map(function (message) { return removeAttributes(message); });
    }
    if ((_b = item === null || item === void 0 ? void 0 : item.attributes) === null || _b === void 0 ? void 0 : _b.debtor) {
        data.debtor_attributes = treatClient(item.attributes.debtor);
    }
    if (data === null || data === void 0 ? void 0 : data.manual_payments) {
        data.manual_payments = data.manual_payments.map(function (payments) { return removeAttributes(payments); });
    }
    if (data === null || data === void 0 ? void 0 : data.external_payments) {
        data.external_payments = data.external_payments.map(function (payment) {
            payment = removeAttributes(payment);
            payment.payment = removeAttributes(payment.payment);
            return payment;
        });
    }
    if (data === null || data === void 0 ? void 0 : data.product_lines_attributes) {
        for (var i = 0; i < data.product_lines_attributes.length; i++) {
            var product = data.product_lines_attributes[i];
            if (product.product) {
                product.product_attributes = treatProduct(product.product);
            }
            if (product.current_unity_price_htva && product.product_attributes) {
                product.product_attributes.current_unity_price_htva = product.current_unity_price_htva;
            }
        }
    }
    if (data === null || data === void 0 ? void 0 : data.sending_options_attributes) {
        data.sending_options_attributes.emails = data.sending_options_attributes.emails_attributes;
        delete data.sending_options_attributes.emails_attributes;
    }
    if (data === null || data === void 0 ? void 0 : data.matched_payments) {
        data.matched_payments = data.matched_payments
            .map(function (el) { return removeAttributes(el); })
            .map(function (_a) {
            var invoice = _a.invoice, rest = __rest(_a, ["invoice"]);
            return (__assign(__assign({}, rest), { invoice: treatInvoice(invoice) }));
        });
    }
    return data;
};
export var treatInvoiceActionData = function (data) {
    var newData = removeAttributes(data);
    if (newData.debtor_address) {
        newData.debtor_address = removeAttributes(newData.debtor_address);
    }
    newData.sending_options = removeAttributes(newData.sending_options);
    if (newData.sending_options.address) {
        newData.sending_options.address_attributes = removeAttributes(newData.sending_options.address);
    }
    return newData;
};
export var treatBillingStatement = function (data) {
    var newData = removeAttributes(data);
    newData.additional_invoices = newData.additional_invoices.map(function (item) { return (__assign({ itemType: 'additional_invoices' }, removeAttributes(item))); });
    newData.credit_notes = newData.credit_notes.map(function (item) { return (__assign({ itemType: 'credit_notes' }, removeAttributes(item))); });
    newData.accounting_payments = newData.accounting_payments.map(function (item) { return (__assign({ itemType: 'accounting_payments' }, removeAttributes(item))); });
    newData.tp_payments = newData.tp_payments.map(function (item) { return (__assign({ itemType: 'tp_payments' }, removeAttributes(item))); });
    newData.main_invoice = removeAttributes(newData.main_invoice);
    return newData;
};
