import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import { UploadZone } from 'shared/io';
import { DialogShowId, showDialog, sideMenuHide } from 'store/view/view.actions';
import ImportCsvForm from '../ImportCsvForm';
import styleIdentifiers from './importClientsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImportClientsForm() {
    var t = useTranslation().t;
    var control = useForm({ shouldUnregister: true }).control;
    var onUploadCsv = function (file) {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.IMPORT_CSV),
            children: React.createElement(ImportCsvForm, { debtor: true, file: file }),
        });
        sideMenuHide();
    };
    return (React.createElement("form", { className: styles('ImportClientsForm') },
        React.createElement("div", { className: styles('head') }, t(i18nKeys.FORM.CLIENT_IMPORT.TITLE)),
        React.createElement("div", { className: styles('content') },
            React.createElement(Controller, { control: control, defaultValue: "", rules: { required: true }, name: "csv_file", render: function () { return (React.createElement(UploadZone, { accept: ".csv", info: t(i18nKeys.FORM.CLIENT_IMPORT.IMPORT_CLIENTS_LIST), subinfo: t(i18nKeys.FORM.CLIENT_IMPORT.CSV_FILE_FORMAT), noPreview: true, onChange: onUploadCsv })); } }))));
}
