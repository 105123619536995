import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { NavLink } from 'react-router-dom';
import AddressBlock from 'shared/components/AddressBlock';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './MergeableDebtor.scss';
var styles = classNames.bind(styleIdentifiers);
export var MergeableDebtor = function (_a) {
    var client = _a.client, onCheck = _a.onCheck, checkedDebtorId = _a.checkedDebtorId;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var id = client.id, _c = client.attributes, full_name = _c.full_name, vat_number = _c.vat_number, external_id = _c.external_id, address = _c.address, phones = _c.phones, emails = _c.emails, invoices_count = _c.invoices_count, currency = _c.currency, last_issue_date = _c.last_issue_date, total_ca = _c.total_ca;
    var selected = id === checkedDebtorId;
    var icon = (React.createElement(Icon, { name: IconName[selected ? 'COPY' : 'TRASH_SIMPLE'], className: styles('icon') }));
    var headClass = selected ? 'active' : 'inactive';
    return (React.createElement(Card, { title: React.createElement(NavLink, { to: "/clients/".concat(id) }, full_name || id), titleIcon: !!checkedDebtorId && icon, className: styles('client-wrapper'), classTitle: styles(!!checkedDebtorId && headClass), infosRight: React.createElement(Checkbox, { noMargin: true, checked: id === checkedDebtorId, onChange: function () {
                onCheck(id);
            } }) },
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.EXTERNAL_ID)),
            React.createElement("div", { className: styles('value', 'medium') }, external_id || '-')),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.VAT_NUMBER)),
            React.createElement("div", { className: styles('value', 'medium') }, vat_number || '-')),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.EMAIL)),
            emails.length > 0 ? (React.createElement("a", { href: "mailto:".concat(emails[0]), className: styles('value', 'medium') }, emails[0])) : ('-')),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PHONE)),
            phones.length > 0 ? (React.createElement("a", { href: "tel:".concat(phones[phones.length - 1].attributes.number), className: styles('value', 'medium') }, phones[phones.length - 1].attributes.number)) : ('-')),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.ADDRESS)),
            address ? (React.createElement("div", { className: styles('value', 'medium') },
                React.createElement(AddressBlock, { address: address, className: styles('address'), clean: true }))) : (React.createElement("div", null,
                "-",
                React.createElement("br", null),
                "\u00A0",
                React.createElement("br", null),
                "\u00A0"))),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.DETAIL.CLIENT_TOTAL_TVAC)),
            React.createElement("div", { className: styles('value', 'medium', 'blue') },
                React.createElement(Amount, { localizedValue: total_ca.value, value: total_ca.localized, suffix: currency }))),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICES)),
            React.createElement("div", { className: styles('value', 'medium') }, invoices_count.total)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.LAST_ISSUE_DATE)),
            React.createElement("div", { className: styles('value', 'medium') }, last_issue_date ? formattedDate(last_issue_date, currentLang) : '-'))));
};
