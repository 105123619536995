import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ColorPicker, UploadZone } from 'shared/io';
import styleIdentifiers from './ColorLogoPartialForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ColorLogoPartialForm() {
    var t = useTranslation().t;
    var _a = useFormContext(), control = _a.control, errors = _a.formState.errors;
    return (React.createElement("div", { className: styles('color-logo-ions-partial-form') },
        React.createElement("div", { className: styles('col', 'company-logo') },
            React.createElement("h4", null, t(i18nKeys.FORM.INVOICING.YOUR_LOGO)),
            React.createElement("div", { className: styles('upload-zone') },
                React.createElement(Controller, { defaultValue: "", name: "logo", control: control, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(UploadZone, __assign({}, values, { accept: "image/jpeg, image/png", img: true, logo: true, info: t(i18nKeys.FORM.INVOICING.IMPORT_LOGO), subinfo: t(i18nKeys.FORM.INVOICING.FILE_FORMAT), errorMessage: t(i18nKeys.ERROR.INVALID_FILE_FORMAT), small: true, noMargin: true, noResize: true, error: errors.logo, showError: true })));
                    } }))),
        React.createElement("div", { className: styles('col', 'company-color') },
            React.createElement("h4", null, t(i18nKeys.FORM.INVOICING.YOUR_COLOR)),
            React.createElement(Controller, { name: "color", defaultValue: "", render: function (_a) {
                    var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                    return React.createElement(ColorPicker, __assign({}, values));
                } }))));
}
