import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var DisputedInvoicesSchema = z
    .object({
    totalInvoicesDisputed: z.preprocess(function (val) { return Number(val); }, z.number()),
})
    .transform(function (_a) {
    var totalInvoicesDisputed = _a.totalInvoicesDisputed;
    return totalInvoicesDisputed;
});
export function loadDisputedInvoicesFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('analytics/disputed_invoices')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, DisputedInvoicesSchema.parse(data)];
            }
        });
    });
}
export function useLoadDisputedInvoices() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['disputed-invoices'],
        queryFn: function () { return loadDisputedInvoicesFn(axiosInstance); },
        onError: function (error) {
            captureException(error);
        },
    });
    return addResourceNameToQueryResult('disputedInvoices', queryResult);
}
