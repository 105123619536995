import React from 'react';
import { useTranslation } from 'locales';
import Logo from 'shared/components/Logo';
import { Card, Center, Group, Stack, Text, Title } from '@mantine/core';
export var MaintenancePage = function () {
    var t = useTranslation().t;
    return (React.createElement(Center, { h: "100%" },
        React.createElement(Stack, null,
            React.createElement(Title, null, "Maintenance"),
            React.createElement(Card, { miw: "25%", p: "xl", shadow: "sm" },
                React.createElement(Text, null,
                    "Recovr is undergoing scheduled maintenance. ",
                    React.createElement("br", null),
                    "We are striving to keep the interruption as short as possible.")),
            React.createElement(Group, { justify: "end" },
                React.createElement(Logo, { type: "blue", width: 150 })))));
};
