import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var BillingLogField = function (_a) {
    var company = _a.company;
    var t = useTranslation().t;
    var control = useFormContext().control;
    var items = company.invoice_customization.billing_logs;
    return (React.createElement(React.Fragment, null,
        React.createElement(Controller, { name: "billing_log_id", control: control, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { items: items, name: "billing_log_id", placeholder: t(i18nKeys.INVOICING.CREATE.BOOK), className: styles('invoice-select-input'), selectClassName: styles('input', 'invoice-custom-select'), valueClassName: styles('value'), errorClassName: styles('error'), keyText: "prefix", keyValue: "id", noBorder: true, noMargin: true, noArrow: true })); } }),
        !items.length && (React.createElement(Link, { className: styles('link-to-billing'), to: "/settings/billing" },
            React.createElement(Icon, { name: IconName.INFO }),
            " ",
            t(i18nKeys.FORM.INVOICING.NO_BILLING_LOG)))));
};
