import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'locales';
import { isDefined } from 'remeda';
import DateItem from 'shared/components/DateItem';
import { ActionIcon, Box, Tooltip, useMantineTheme } from '@mantine/core';
import { captureException } from '@sentry/react';
import { IconGavel, IconPhoneCall, IconScale } from '@tabler/icons-react';
import debtCollectorIcon from '../../../shared/components/InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
export var ThirdpartyIcon = function (_a) {
    var invoice = _a.invoice;
    var currentLang = useTranslation().currentLang;
    var theme = useMantineTheme();
    if (!isDefined(invoice.thirdPartyCase))
        return null;
    var _b = invoice.thirdPartyCase, type = _b.type, description = _b.description, date = _b.date;
    var ICON_PROPS = { size: 18, stroke: 1.3, color: theme.colors.gray[7] };
    var icon = (function () {
        switch (type) {
            case 'callcenter':
                return React.createElement(IconPhoneCall, __assign({ style: { width: '70%', height: '70%' } }, ICON_PROPS));
            case 'lawyer':
                return React.createElement(IconGavel, __assign({ style: { width: '70%', height: '70%' } }, ICON_PROPS));
            case 'bailiff':
                return React.createElement(IconScale, __assign({ style: { width: '70%', height: '70%' } }, ICON_PROPS));
            case 'debt_collector':
                return React.createElement("img", { width: "70%", height: "70%", src: debtCollectorIcon, alt: "Debt collector" });
            default:
                captureException('Encountered unknown third party case type', { extra: { invoice: invoice } });
                return null;
        }
    })();
    return (React.createElement(Tooltip, { withArrow: true, label: React.createElement(React.Fragment, null,
            description,
            " ",
            React.createElement(DateItem, { lg: currentLang, date: new Date(date) })) },
        React.createElement(Box, null,
            React.createElement(ActionIcon, { size: "sm", variant: "default", style: { pointerEvents: 'none' } }, icon))));
};
