import apiService from 'shared/service/api.service';
import { isConnectorActive } from 'shared/utils/connector';
import { config } from 'store/constants';
import { ENABLED_INTEGRATIONS, Integration } from '../Integrations.constants';
var baseUrl = apiService.baseUrl, tenant = apiService.tenant;
var exactClientId = config.exact.client_id, _a = config.billit, billitClientId = _a.client_id, billitBaseUrl = _a.base_url;
var publicUrl = baseUrl.replace('backend', 'public.backend');
export var isEnabledIntegration = function (integration) {
    return ENABLED_INTEGRATIONS.includes(integration);
};
export var getIntegrationRights = function (integration, company) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    switch (integration) {
        case Integration.CODABOX:
            return {
                canActivate: !isConnectorActive() &&
                    isEnabledIntegration(integration) &&
                    ((_a = company === null || company === void 0 ? void 0 : company.codabox_integration) === null || _a === void 0 ? void 0 : _a.can_activate),
                isActive: (_b = company === null || company === void 0 ? void 0 : company.codabox_integration) === null || _b === void 0 ? void 0 : _b.active,
            };
        case Integration.PONTO:
            return {
                canActivate: !isConnectorActive() &&
                    isEnabledIntegration(integration) &&
                    ((_c = company === null || company === void 0 ? void 0 : company.ponto_integration) === null || _c === void 0 ? void 0 : _c.can_activate),
                isActive: (_d = company === null || company === void 0 ? void 0 : company.ponto_integration) === null || _d === void 0 ? void 0 : _d.active,
            };
        case Integration.DIGITEAL:
            return {
                canActivate: isEnabledIntegration(integration) && ((_e = company === null || company === void 0 ? void 0 : company.package) === null || _e === void 0 ? void 0 : _e.can_use_online_payment),
                isActive: (_f = company === null || company === void 0 ? void 0 : company.digiteal_integration) === null || _f === void 0 ? void 0 : _f.use_integration,
            };
        case Integration.COMPANYWEB:
            return {
                canActivate: true,
                isActive: company.company_web_credentials.login != null ||
                    company.company_web_credentials.password != null,
            };
        case Integration.CHIFT:
            return {
                canActivate: !isConnectorActive() && ((_j = (_h = (_g = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _g === void 0 ? void 0 : _g.active) === null || _h === void 0 ? void 0 : _h.includes(integration)) !== null && _j !== void 0 ? _j : false),
                // Have we logged into the service?
                isConnected: (_m = (_l = (_k = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _k === void 0 ? void 0 : _k.integration_in_use) === null || _l === void 0 ? void 0 : _l.includes(integration)) !== null && _m !== void 0 ? _m : false,
                // Have minimum required config steps been taken, if any? (Most commonly choosing a company)
                isConfigure: (_q = (_p = (_o = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _o === void 0 ? void 0 : _o.can_configure) === null || _p === void 0 ? void 0 : _p.includes(integration)) !== null && _q !== void 0 ? _q : false,
                // Have we checked the checkboxes that actually cause the imports to happen?
                isActive: (_t = (_s = (_r = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _r === void 0 ? void 0 : _r.active) === null || _s === void 0 ? void 0 : _s.includes(integration)) !== null && _t !== void 0 ? _t : false,
            };
        default:
            return {
                canActivate: !isConnectorActive() &&
                    isEnabledIntegration(integration) &&
                    (((_v = (_u = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _u === void 0 ? void 0 : _u.active) === null || _v === void 0 ? void 0 : _v.includes(integration)) ||
                        ((_x = (_w = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _w === void 0 ? void 0 : _w.active) === null || _x === void 0 ? void 0 : _x.length) === 0),
                // Have we logged into the service?
                isConnected: (_0 = (_z = (_y = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _y === void 0 ? void 0 : _y.integration_in_use) === null || _z === void 0 ? void 0 : _z.includes(integration)) !== null && _0 !== void 0 ? _0 : false,
                // Have minimum required config steps been taken, if any? (Most commonly choosing a company)
                isConfigure: (_3 = (_2 = (_1 = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _1 === void 0 ? void 0 : _1.can_configure) === null || _2 === void 0 ? void 0 : _2.includes(integration)) !== null && _3 !== void 0 ? _3 : false,
                // Have we checked the checkboxes that actually cause the imports to happen?
                isActive: (_6 = (_5 = (_4 = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _4 === void 0 ? void 0 : _4.active) === null || _5 === void 0 ? void 0 : _5.includes(integration)) !== null && _6 !== void 0 ? _6 : false,
            };
    }
};
export var getTeamleaderConnectUrl = function () {
    return "https://app.teamleader.eu/oauth2/authorize?client_id=53efcfb7aaa8e786191ba73fbfcab285&response_type=code&state=".concat(tenant, "&redirect_uri=").concat(publicUrl, "/private_api/teamleader_callbacks/authenticate");
};
export var getHorusConnectUrl = function (currentLang) {
    return "https://my-horus.com/".concat(currentLang === 'nl' ? currentLang : 'fr', "/api/oauth2/authorize?client_id=ck_sEzBr3cObz5fAJo&response_type=code&state=").concat(tenant, "&redirect_uri=").concat(publicUrl, "/api/horus_callbacks/authenticate");
};
export var getExactConnectUrl = function () {
    return "https://start.exactonline.be/api/oauth2/auth?client_id=".concat(exactClientId, "&redirect_uri=").concat(publicUrl, "/private_api/exact_callbacks/authenticate?tenant=").concat(tenant, "&response_type=code&force_login=0");
};
export var getBillitConnectUrl = function (currentLang) {
    return "".concat(billitBaseUrl, "Account/Logon?client_id=").concat(billitClientId, "&state=").concat(tenant, "&redirect_uri=").concat(publicUrl, "/private_api/billit_callbacks/authenticate");
};
export var isMaskFilled = function (value) {
    return (value === null || value === void 0 ? void 0 : value.indexOf('_')) !== -1 ? 'ERROR.FIELD_REQUIRED' : true;
};
