import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import { PlanType } from 'api/models';
import RecoverAction from 'app/Private/Templates/EditDebtCollectionCustomization/RecoverAction';
import TemplateList from 'app/Private/Templates/TemplateList';
import TemplateFilters from 'app/Private/Templates/TemplateList/TemplateFilters';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { Button, ButtonColor } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { settingsActions } from 'store/settings/settings.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import styleIdentifiers from './TemplatesSettings.scss';
var styles = classNames.bind(styleIdentifiers);
export var TemplatesSettings = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var canAddTemplate = company.package.can_create_template;
    var _a = __read(useState({}), 2), filters = _a[0], setFilters = _a[1];
    var tabItems = [
        {
            title: t(i18nKeys.BATCH.CATEGORIES.RECOVERY),
            planType: PlanType.recovery,
            children: React.createElement(TemplateList, { key: 0, plan_type: PlanType.recovery, filters: filters }),
        },
        {
            title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
            planType: PlanType.payment,
            children: React.createElement(TemplateList, { key: 1, plan_type: PlanType.payment, filters: filters }),
        },
    ];
    var _b = __read(useState(0), 2), tabIndex = _b[0], setTabIndex = _b[1];
    var addDocument = function (planType) {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(RecoverAction, { planType: planType, onSelectAction: function (step_type, with_fees) {
                    settingsActions.addTemplate({
                        data: {
                            name: t(i18nKeys.NEW_TEMPLATE),
                            step_type: step_type,
                            plan_type: planType,
                            with_fees: with_fees,
                        },
                        callback: function (_a) {
                            var data = _a.data;
                            sideMenuHide();
                            history.push("/settings/document-customization/edit/".concat(data.id));
                        },
                    });
                } })),
        });
    };
    var openFilterForm = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(FiltersSideMenu, { onRemoveFilter: function () {
                    setFilters({});
                } },
                React.createElement(TemplateFilters, { filterObject: {}, initialValues: filters, onSubmit: function (_a) {
                        var name = _a.name, step_type = _a.step_type;
                        setFilters(__assign(__assign({}, (name ? { name: name } : null)), (step_type ? { step_type: step_type } : null)));
                    } }))),
        });
    };
    return (React.createElement("div", { className: styles('templates') },
        React.createElement(PageTitle, null,
            t(i18nKeys.DOCUMENT_CUSTOMIZATION),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { className: styles('filter'), label: t(i18nKeys.FILTER), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, iconLeft: IconName.FILTER, onClick: openFilterForm }))),
        React.createElement(Tabs, { className: styles('templates__tabs'), tabIndex: tabIndex, items: tabItems.map(function (e, i) { return (React.createElement("div", { className: styles('tab-item') },
                React.createElement("h3", null, e.title),
                tabIndex === i && (React.createElement("div", { className: styles('add-item-button'), title: canAddTemplate ? '' : t(i18nKeys.NOT_INCLUDED) },
                    React.createElement(Icon, { name: IconName.PLUS, className: styles(!canAddTemplate && 'disabled'), onClick: function () {
                            if (canAddTemplate) {
                                addDocument(e.planType);
                            }
                        } }))))); }), noBody: true, onChange: setTabIndex }),
        tabItems[tabIndex].children));
};
