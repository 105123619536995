import { __read } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Badge } from '@mantine/core';
export var PeriodicityLabel = function (_a) {
    var type = _a.type;
    var t = useTranslation().t;
    var isWeekly = type === 'weekly';
    var _b = __read(isWeekly
        ? [t(i18nKeys.DATES.PERIOD_NAMES.WEEKLY), 'indigo.4']
        : [t(i18nKeys.DATES.PERIOD_NAMES.MONTHLY), 'cyan.4'], 2), text = _b[0], color = _b[1];
    return (React.createElement(Badge, { variant: "light", color: color }, text));
};
