import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import AddressBlock from 'shared/components/AddressBlock';
import Card from 'shared/components/Card';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './debtorInfo.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorInfo(_a) {
    var imported = _a.imported, invoiceData = _a.invoiceData, clientData = _a.clientData, reloadInfo = _a.reloadInfo, label = _a.label;
    var t = useTranslation().t;
    var history = useHistory();
    var seeClient = function (debtorId) { return function () {
        dialogHide(DialogShowId.INVOICE);
        history.push("/clients/".concat(debtorId));
    }; };
    var editClient = function (debtor) { return function () {
        EditClient({
            client: debtor,
            callback: reloadInfo,
        });
    }; };
    return (React.createElement(Card, { title: label || "".concat(t(i18nKeys.COMMON.CLIENT), " - ").concat(clientData.full_name), infosRight: clientData.paused && (React.createElement(Icon, { name: IconName.PAUSE, color: IconColor.DARK, size: "18px", title: t(i18nKeys.RECOVERY_IN_PAUSE) })), className: styles('box', 'client-info', 'className'), actionHead: imported ? undefined : seeClient(clientData.id) },
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') },
                t(i18nKeys.ADDRESS),
                ' ',
                (invoiceData ? invoiceData.address_missing : !clientData.address_attributes) && (React.createElement(Tooltip, { className: styles('action-drop'), contentClassName: styles('warning'), item: React.createElement("div", { onClick: editClient(clientData), className: styles('circle') }, "!") }, t(i18nKeys.INCOMPLETE_ADRESS)))),
            React.createElement("div", { className: styles('value', 'medium') },
                React.createElement(AddressBlock, { address: clientData.address_attributes, className: styles('address') }))),
        clientData.phones_attributes.length > 0 && (React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PHONE)),
            React.createElement("a", { href: "tel:".concat(clientData.phones_attributes[clientData.phones_attributes.length - 1].number), className: styles('value', 'medium') }, clientData.phones_attributes[clientData.phones_attributes.length - 1].number))),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CONTACT)),
            React.createElement("span", { className: styles('value', 'medium') }, clientData.full_name)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.EMAIL)),
            (invoiceData ? invoiceData.email_missing : !clientData.emails[0]) ? (React.createElement("div", { className: styles('error'), onClick: editClient(clientData) }, t(i18nKeys.MISSING_EMAIL))) : (React.createElement("a", { href: "mailto:".concat(clientData.emails[0]), className: styles('value', 'medium') }, clientData.emails[0]))),
        clientData.account_manager && (React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.ACCOUNT_MANAGER)),
            React.createElement("span", { className: styles('value', 'medium') }, clientData.account_manager.email)))));
}
