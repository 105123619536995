import { __assign } from "tslib";
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import { SettingsCard } from '../SettingsCard';
import CompanySettingsForm from './CompanySettingsForm';
export var CompanySettings = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var handleUpdate = function (callback) { return function (values) {
        var data = __assign({}, values);
        if (company.phones_attributes.length > 0) {
            var differentPhone = data.phones_attributes[0].number !== company.phones_attributes[0].number;
            if (differentPhone) {
                data.phones_attributes[0] = {
                    number: data.phones_attributes[0].number,
                };
                data.phones_attributes.push(__assign(__assign({}, company.phones_attributes[0]), { _destroy: true }));
            }
        }
        company.company_bank_accounts.forEach(function (bankAccount) {
            if (data.company_bank_accounts) {
                if (!data.company_bank_accounts.some(function (companyBankAccount) { return companyBankAccount.id === bankAccount.id; })) {
                    data.company_bank_accounts.push({
                        id: bankAccount.id,
                        _destroy: true,
                    });
                }
            }
            else {
                data.company_bank_accounts = company.company_bank_accounts.map(function (_a) {
                    var id = _a.id;
                    return ({
                        id: id,
                        _destroy: true,
                    });
                });
            }
        });
        data.company_bank_accounts_attributes = data.company_bank_accounts;
        delete data.bank_accounts_attributes;
        delete data.company_bank_accounts;
        accountActions.updateCompanyInfo({
            data: data,
            callback: callback,
        });
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.COMPANY.PROFILE)),
        React.createElement(SettingsCard, null,
            React.createElement(CompanySettingsForm, { onSubmit: handleUpdate, initialValues: company }))));
};
