import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, Input } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import styleIdentifiers from './TemplateFilters.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TemplateFilters(_a) {
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: [],
    }), form = _b.form, resetForm = _b.resetForm, submit = _b.submit;
    var handleSubmit = form.handleSubmit, register = form.register;
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = function () {
                resetForm();
            };
        }
    }, [filterObject]);
    var itemsWithoutTasks = constants.recovery_step_types.template_types.filter(function (e) { return e.value !== 'task_generation'; });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('template-filters'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('container-fields') },
                React.createElement("div", { className: styles('fields') },
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { register: register('name'), noMargin: true, withBorder: true, defaultValue: "", shadow: true, type: "text", label: t(i18nKeys.NAME), onValueChanged: submit })),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Controller, { defaultValue: "", name: "step_type", render: function () { return (React.createElement(CustomSelect, { name: "step_type", noMargin: true, size: "small", onChange: function (value) {
                                    submit(value);
                                    return value;
                                }, items: itemsWithoutTasks, keyText: "description", keyValue: "value", withBorder: true, noBorder: true, shadow: true, label: t(i18nKeys.FORM.TYPE) })); } })))))));
}
