import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { dateFromNow } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimelineItemReaction = function (_a) {
    var reaction = _a.reaction;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = reaction.attributes, date = _c.date, invoice = _c.invoice, comment = _c.comment;
    var onClick = function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: invoice.id, modal: true }),
        });
    };
    return (React.createElement("div", { onClick: onClick },
        React.createElement("span", { className: styles('date') }, dateFromNow(date, currentLang)),
        React.createElement("div", { className: styles('from') }, invoice.attributes.debtor.attributes.full_name),
        React.createElement("p", { className: styles('sub-detail') },
            t(i18nKeys.TIMELINE.DEBTOR_REACTION),
            " ",
            invoice.attributes.reference),
        React.createElement("div", { className: styles('message') }, comment)));
};
