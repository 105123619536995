import { __assign } from "tslib";
import { useGetExternalMailboxConfigurations } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller } from 'react-hook-form';
import { CustomSelect } from 'shared/io';
import styleIdentifiers from './ExternalMailList.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailBoxForm = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'external_mailbox_id' : _b, currentMailbox = _a.currentMailbox;
    var t = useTranslation().t;
    var externalMailboxConfigurationsResponse = useGetExternalMailboxConfigurations().data;
    var mailboxes = (externalMailboxConfigurationsResponse === null || externalMailboxConfigurationsResponse === void 0 ? void 0 : externalMailboxConfigurationsResponse.data) || [];
    var concatedMailboxes = (currentMailbox ? [currentMailbox] : [])
        .concat(mailboxes.filter(function (e) { return e.id !== (currentMailbox === null || currentMailbox === void 0 ? void 0 : currentMailbox.id); }))
        .map(function (e) { return (__assign({ id: e.id }, e.attributes)); });
    return (React.createElement(Controller, { name: name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), withBorder: true, items: concatedMailboxes, keyValue: "id", keyText: "login", name: name, label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE) })); } }));
};
