import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import Loading from 'shared/components/Loading';
import MultiCurrencyData from 'shared/components/MultiCurrencyData';
import { useLoadDetailedDebtorBalance } from 'shared/hooks/use-load-debtors-detailed-balance';
import styleIdentifiers from './DetailedBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export function DetailedBalance(_a) {
    var accountManagerId = _a.accountManagerId;
    var t = useTranslation().t;
    var _b = useLoadDetailedDebtorBalance({
        accountManagerId: accountManagerId,
    }), detailedDebtorBalance = _b.detailedDebtorBalance, isDetailedDebtorBalanceLoading = _b.isDetailedDebtorBalanceLoading;
    if (isDetailedDebtorBalanceLoading || !isDefined(detailedDebtorBalance)) {
        return (React.createElement("div", { className: styles('balance-detailed') },
            React.createElement(Loading, { active: true }),
            t(i18nKeys.TOO_LONG_LOADING)));
    }
    var detailedBalance = detailedDebtorBalance.data.detailedBalance;
    return (React.createElement("div", { className: styles('balance-detailed') },
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.AMOUNT_BILLED)),
            React.createElement("div", { className: styles('value') },
                React.createElement(MultiCurrencyData, { data: detailedBalance.invoicesTotal, withTotal: true }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.CLAIMED_LATE_FEES)),
            React.createElement("div", { className: styles('value') },
                React.createElement(MultiCurrencyData, { data: detailedBalance.claimedFees, withTotal: true }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.NAV.CREDIT_NOTES)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(MultiCurrencyData, { data: detailedBalance.creditNotesTotal, withTotal: true }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -detailedBalance.paymentsTotal }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.LOST_SUM)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -detailedBalance.lostTotal }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') },
                t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                " ",
                React.createElement("span", null,
                    "(",
                    t(i18nKeys.WITH_FEES),
                    ")")),
            React.createElement("div", { className: styles('value', 'main-color') },
                React.createElement(Amount, { value: detailedBalance.remainingBalanceWithFees }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.REMAINING_LATE_FEES)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -detailedBalance.remainingLateFees }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') },
                t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                " ",
                React.createElement("span", null,
                    "(",
                    t(i18nKeys.WITHOUT_FEES),
                    ")")),
            React.createElement("div", { className: styles('value', 'main-color') },
                React.createElement(Amount, { value: detailedBalance.remainingBalance })))));
}
