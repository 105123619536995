import { __assign, __read } from "tslib";
import { useCreatePaymentPlan, useCreatePaymentStepCalculation } from 'api';
import qs from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { PaymentPlanFormSteps } from './PaymentPlanFormSteps';
import { PaymentPlanFormWrapper } from './PaymentPlanFormWrapper';
import { StepsCalculation } from './StepsCalculation/StepsCalculation';
export var PaymentPlanForm = function () {
    var history = useHistory();
    var queryParams = qs.parse(location.search);
    var invoicesParams = queryParams.invoice_ids;
    var _a = __read(useState(), 2), paymentProperties = _a[0], setPaymentProperties = _a[1];
    var _b = __read(useState(), 2), paymentSteps = _b[0], setPaymentSteps = _b[1];
    var createPaymentStepCalculation = useCreatePaymentStepCalculation().mutate;
    var calculateStep = function (data) {
        createPaymentStepCalculation({ data: data }, {
            onSuccess: function (response) {
                setPaymentProperties(data);
                setPaymentSteps(response);
            },
        });
    };
    var createPaymentPlan = useCreatePaymentPlan().mutate;
    if (!paymentProperties || !paymentSteps) {
        return (React.createElement(PaymentPlanFormWrapper, null,
            React.createElement(StepsCalculation, { onSubmit: calculateStep, initialValues: paymentProperties, debtorId: (paymentProperties === null || paymentProperties === void 0 ? void 0 : paymentProperties.debtor_id) || queryParams.debtor_id, invoiceIds: (paymentProperties === null || paymentProperties === void 0 ? void 0 : paymentProperties.invoice_ids) || (invoicesParams === null || invoicesParams === void 0 ? void 0 : invoicesParams.split(',')) })));
    }
    var recalculateSteps = function () {
        setPaymentSteps(undefined);
    };
    var onSubmit = function (data) {
        createPaymentPlan({ data: __assign(__assign({}, paymentProperties), data) }, {
            onSuccess: function (_a) {
                var planData = _a.data;
                history.push("/payment-plan/".concat(planData.id));
            },
        });
    };
    return (React.createElement(PaymentPlanFormSteps, { paymentProperties: paymentProperties, recalculateSteps: recalculateSteps, paymentSteps: paymentSteps, onSubmit: onSubmit }));
};
