import classNames from 'classnames/bind';
import React from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import styleIdentifiers from './WorkflowFormSteps.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsButton = function (_a) {
    var label = _a.label, title = _a.title, onClick = _a.onClick, disabled = _a.disabled;
    return (React.createElement("div", { className: styles('add', disabled && 'disabled') },
        React.createElement("button", { type: "button", onClick: onClick, disabled: disabled, title: title },
            React.createElement(Icon, { name: IconName.PLUS }),
            label && React.createElement("span", null, title))));
};
