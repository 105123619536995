import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, CustomSelect, Input, Textarea } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import styleIdentifiers from './productForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ProductForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, close = _a.close;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, unregister = form.unregister, _b = form.formState, isDirty = _b.isDirty, errors = _b.errors, handleSubmit = form.handleSubmit;
    useEffect(function () {
        if (initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) {
            register('id', { value: initialValues.id });
        }
        return function () {
            unregister('id');
        };
    }, []);
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('ProductForm'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('head') },
                initialValues && initialValues.id && t(i18nKeys.FORM.PRODUCT.EDIT),
                (!initialValues || !initialValues.id) && t(i18nKeys.NEW_PRODUCT),
                close && (React.createElement("div", { className: styles('close'), onClick: close },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, size: "20px" })))),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { register: register('name', { required: true }), errorMessage: errors.name, noMargin: true, withBorder: true, label: t(i18nKeys.INVOICE.PRODUCT) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Textarea, { register: register('description'), className: styles('input'), withBorder: true, label: t(i18nKeys.DESCRIPTION) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { register: register('price_htva', { required: true, min: 0 }), errorMessage: errors.price_htva, className: styles('input'), withBorder: true, type: "number", step: "0.01", label: t(i18nKeys.PRICE) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "tax_category_code", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "tax_category_code", label: t(i18nKeys.INVOICE.VAT_RATE), items: constants.tax_categories, filter: true })); } })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" })))));
}
