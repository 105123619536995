import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadPausedInvoices } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { BaseTicker } from '../BaseTicker';
function getAmountPaused(pausedAmount, formatter) {
    return formatter.format(pausedAmount);
}
function getPercentagePaused(agedBalanceCompact, pausedAmount) {
    var netAgedBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var totalReceivable = netAgedBalance.notDue +
        netAgedBalance['0'] +
        netAgedBalance['30'] +
        netAgedBalance['60'] +
        netAgedBalance['90'];
    var percentage = (pausedAmount / totalReceivable) * 100;
    return percentage.toFixed(2);
}
export var PausedTicker = function (_a) {
    var format = _a.format;
    var t = useTranslation().t;
    var _b = useLoadAgedBalanceCompact(), agedBalanceCompact = _b.agedBalanceCompact, isAgedBalanceCompactLoading = _b.isAgedBalanceCompactLoading;
    var _c = useLoadPausedInvoices(), pausedInvoices = _c.pausedInvoices, isPausedInvoicesLoading = _c.isPausedInvoicesLoading;
    var formatter = useSafeLocalizedCompactCurrencyFormatter(true);
    if (isAgedBalanceCompactLoading ||
        agedBalanceCompact == null ||
        isPausedInvoicesLoading ||
        pausedInvoices == null) {
        return React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.PAUSED.TITLE) });
    }
    var value = format === 'percentage'
        ? getPercentagePaused(agedBalanceCompact, pausedInvoices)
        : getAmountPaused(pausedInvoices, formatter);
    return (React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.PAUSED.TITLE), value: value, suffix: format === 'percentage' ? '%' : '€', url: "/invoices/listing?&status=paused" }));
};
