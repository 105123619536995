import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import styleIdentifiers from './tpCaseStatusItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TpCaseStatusItem(_a) {
    var className = _a.className, status = _a.status;
    var t = useTranslation().t;
    var availableStatus = {
        notOpen: {
            step: 1,
            text: t(i18nKeys.THIRD_PARTY_CASES.NOT_STARTED),
        },
        open: {
            step: 2,
            text: t(i18nKeys.THIRD_PARTY_CASES.STARTED),
        },
        close: {
            step: 3,
            text: t(i18nKeys.THIRD_PARTY_CASES.CLOSED),
        },
    };
    var step = availableStatus[status].step;
    return (React.createElement("div", { className: styles('tp-case-status-item', "step-".concat(step + 1), className) },
        React.createElement("div", { className: styles('label') }, availableStatus[status].text),
        React.createElement("div", { className: styles('indicator') }, new Array(3).fill(0).map(function (it, key) { return (React.createElement("span", { key: key, className: styles('indic', key <= step - 1 && 'selected') })); }))));
}
