import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import { isDefined } from 'remeda';
import Logo from 'shared/components/Logo';
import { useValidateTenant } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import styleIdentifiers from './ServerError.scss';
var styles = classNames.bind(styleIdentifiers);
export function ServerError() {
    var t = useTranslation().t;
    var history = useHistory();
    var tenant = useValidateTenant(apiService.tenant).tenant;
    useEffect(function () {
        if (isDefined(tenant))
            history.push('/');
    }, [tenant, history]);
    return (React.createElement("div", { className: styles('server-error-page') },
        React.createElement("div", { className: styles('content-wrapper') },
            React.createElement("div", { className: styles('content', 'card') },
                React.createElement("h1", null, t(i18nKeys.ERROR.SERVER_NOT_RESPONDING)),
                React.createElement("p", null, t(i18nKeys.ERROR.REFRESH_PAGE_IF_ERROR_PERSIST))),
            React.createElement(Logo, { className: styles('logo'), type: "blue" }))));
}
