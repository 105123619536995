import React from 'react';
import { useUpdateReport } from 'shared/hooks';
import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { NameStep } from '../../CreateReport/steps';
export var EditReportNameModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, report = _a.report;
    var _b = useUpdateReport(), updateReport = _b.updateReport, isUpdateReportLoading = _b.isUpdateReportLoading;
    var form = useForm({
        initialValues: {
            name: report.name,
        },
    });
    var handleSave = function () {
        updateReport({
            id: report.id,
            name: form.values.name,
        }, {
            onSuccess: function () {
                onClose();
            },
        });
    };
    return (React.createElement(Modal, { opened: isOpen, onClose: onClose, title: "Edit name" },
        React.createElement("form", { onSubmit: function (e) {
                e.preventDefault();
                handleSave();
            } },
            React.createElement(NameStep, { formProps: form.getInputProps('name') }),
            React.createElement(Group, { w: "100%", justify: "end", mt: "xl" },
                React.createElement(Button, { variant: "light", color: "orange", onClick: onClose }, "Cancel"),
                React.createElement(Button, { type: "submit", loading: isUpdateReportLoading }, "Save")))));
};
