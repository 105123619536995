import { __assign } from "tslib";
import React from 'react';
import { Comments } from 'app/Private/Comments';
import { ContactPersonsList } from 'app/Private/ContactPersons';
import CustomVariables from 'app/Private/CustomVariables/CustomVariables';
import CustomVariablesForm from 'app/Private/CustomVariables/CustomVariablesForm/CustomVariablesForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, showDialog, sideMenuShow } from 'store/view/view.actions';
import { PaymentDelayModal } from '../PaymentDelayModal';
import { TabInfoDebtor } from './TabInfoDebtor';
import { TabInfoPayment } from './TabInfoPayment';
import styleIdentifiers from '../ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var TabInfo = function (_a) {
    var debtor = _a.debtor, refetch = _a.refetch;
    var t = useTranslation().t;
    var id = debtor.id, _b = debtor.attributes, custom_variables = _b.custom_variables, comments = _b.comments;
    var editClient = function (data) {
        clientActions.update({
            data: __assign(__assign({}, data), { id: id }),
            callback: function () { return dialogHide(DialogShowId.CUSTOM); },
        });
    };
    var handlePaymentDelay = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS),
            children: React.createElement(PaymentDelayModal, { onSubmit: editClient, initialValues: treatClient(debtor) }),
        });
    };
    var handleCustomVariables = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(CustomVariablesForm, { onSubmit: editClient, initialValues: treatClient(debtor) }),
        });
    };
    var submitComment = function (values) {
        clientActions.comment(__assign(__assign({}, values), { id: id, callback: refetch }));
    };
    return (React.createElement("div", { className: styles('tab-item') },
        React.createElement(TabInfoDebtor, { debtor: treatClient(debtor), reload: refetch }),
        Object.keys(custom_variables).length > 0 && (React.createElement(CustomVariables, { data: custom_variables, handleSettings: handleCustomVariables })),
        React.createElement(TabInfoPayment, { debtor: treatClient(debtor), handleSettings: handlePaymentDelay }),
        React.createElement(Comments, { comments: comments, submitComment: submitComment }),
        React.createElement("div", { className: styles('contact-card', 'card') },
            React.createElement(ContactPersonsList, { debtor: treatClient(debtor) }))));
};
