import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { formatCommunication } from 'shared/utils/normalization';
import { downloadFile, formattedDate, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import DebtorReactionForm from './DebtorReactionForm';
import styleIdentifiers from './InvoiceDetailDebtor.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceDetailDebtor(_a) {
    var _b;
    var invoice = _a.invoice, isInvoicesTabActive = _a.isInvoicesTabActive, payInvoice = _a.payInvoice, credit = _a.credit, canUseOnlinePayment = _a.canUseOnlinePayment, _c = _a.isFrenchClient, isFrenchClient = _c === void 0 ? true : _c, _d = _a.canUseDigiteal, canUseDigiteal = _d === void 0 ? false : _d;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    var _f = __read(useState([]), 2), additionalFiles = _f[0], setAdditionalFiles = _f[1];
    useEffect(function () {
        invoiceActions.getAdditionalFiles({
            id: invoice.id,
            noFeedback: true,
            data: {
                document_type: 'invoice_additional_pdf',
                token: invoice.debtor_document_token,
            },
            callback: function (_a) {
                var data = _a.data;
                setAdditionalFiles(data.map(function (file) { return removeAttributes(file); }));
            },
        });
    }, []);
    var downloadAdditionalFile = function (additionalFile) { return function () {
        invoiceActions.document({
            id: invoice.id,
            noLoading: true,
            public: true,
            fileUpload: true,
            document_type: 'invoice_additional_pdf',
            data: {
                token: invoice.debtor_document_token,
                additional_file_id: additionalFile.id,
            },
            callback: function (data) {
                downloadFile(data, additionalFile.file_name);
            },
        });
    }; };
    var handleDownload = function (type) { return function () {
        invoiceActions.document({
            public: true,
            id: invoice.id,
            data: {
                token: invoice.debtor_document_token,
            },
            type: type === 'xml' ? 'invoice_ubl' : 'invoice_pdf',
        });
    }; };
    var comment = function (_a) {
        var _comment = _a.comment;
        invoiceActions.debtorReactionComment({
            id: invoice.id,
            data: { comment: _comment, token: invoice.debtor_reaction_token },
            success: i18nKeys.DEBTOR.SUCCESSFULLY_COMMENT,
            callback: function () { },
        });
    };
    var renderInfo = function (text, info) { return (React.createElement("div", { className: styles('key-text') },
        React.createElement("div", { className: styles('attribute') },
            t(text),
            ": "),
        React.createElement("div", { className: styles('value') }, info))); };
    return (React.createElement("div", { className: styles('download-document') },
        React.createElement("div", { className: styles('head') }, invoice.reference),
        React.createElement("div", { className: styles('content') },
            credit ? (React.createElement(React.Fragment, null,
                React.createElement("h3", null, t(i18nKeys.DEBTOR.REACTION.CREDIT_NOTE_DETAILS)),
                renderInfo(i18nKeys.FORM.REFERENCE, invoice.reference),
                Array.isArray(invoice.linked_invoices_references) && (React.createElement(React.Fragment, null, renderInfo(i18nKeys.INVOICE.LINK_INVOICES, invoice.linked_invoices_references.toString()))),
                renderInfo(i18nKeys.ISSUE_DATE, formattedDate(invoice.issue_date, currentLang)),
                renderInfo(i18nKeys.INVOICE.DISCOUNT, React.createElement(Amount, { localizedValue: invoice.total_discount_amount, value: invoice.localized_money_object.total_discount_amount, suffix: invoice.currency, debtor: invoice.debtor })),
                renderInfo(i18nKeys.TOTAL_NOT_VAT_PRICE, React.createElement(Amount, { localizedValue: invoice.total_htva, value: invoice.localized_money_object.total_htva, suffix: invoice.currency, debtor: invoice.debtor })),
                renderInfo(i18nKeys.TOTAL_VAT_PRICE, React.createElement(Amount, { localizedValue: invoice.total_tvac, value: invoice.localized_money_object.total_tvac, suffix: invoice.currency, debtor: invoice.debtor })),
                renderInfo(i18nKeys.BALANCE, React.createElement(Amount, { localizedValue: invoice.remaining_balance, value: invoice.localized_money_object.remaining_balance, suffix: invoice.currency, debtor: invoice.debtor })))) : (React.createElement(React.Fragment, null,
                React.createElement("h3", null, t(i18nKeys.INVOICE.DETAILS)),
                renderInfo(i18nKeys.ISSUE_DATE, formattedDate(invoice.issue_date, currentLang)),
                !isInvoicesTabActive &&
                    renderInfo(i18nKeys.DUE_DATE, formattedDate(invoice.due_date, currentLang)),
                renderInfo(i18nKeys.TOTAL_VAT_PRICE, React.createElement(Amount, { localizedValue: invoice.total_tvac, value: invoice.localized_money_object.total_tvac, suffix: invoice.currency, debtor: invoice.debtor })),
                renderInfo(i18nKeys.INVOICE.TOTAL_LATE_FEES, React.createElement(Amount, { localizedValue: invoice.late_fees, value: invoice.localized_money_object.late_fees, suffix: invoice.currency, debtor: invoice.debtor })),
                !isInvoicesTabActive &&
                    renderInfo(i18nKeys.BALANCE, React.createElement(Amount, { localizedValue: invoice.remaining_balance_with_fees, value: invoice.localized_money_object.remaining_balance_with_fees, suffix: invoice.currency, debtor: invoice.debtor })),
                renderInfo(i18nKeys.COMMUNICATION, formatCommunication(invoice.structured_communication)))),
            (invoice.has_pdf || invoice.has_ubl || ((_b = invoice.additional_files) !== null && _b !== void 0 ? _b : []).length > 0) && (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: styles('content-separator') }),
                React.createElement("h3", null, t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)),
                invoice.has_pdf && (React.createElement("div", { className: styles('item'), onClick: handleDownload('pdf') },
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.PDF, className: styles('icon') }),
                        React.createElement("span", { className: styles('content-txt') },
                            t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE),
                            " (PDF)")),
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.DOWNLOAD, className: styles('icon') })))),
                invoice.has_ubl && (React.createElement("div", { className: styles('item'), onClick: handleDownload('xml') },
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.XML, className: styles('icon') }),
                        React.createElement("span", { className: styles('content-txt') },
                            t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE),
                            " (UBL)")),
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.DOWNLOAD, className: styles('icon') })))),
                additionalFiles.map(function (additionalFile, index) { return (React.createElement("div", { key: index, onClick: downloadAdditionalFile(additionalFile), className: styles('item') },
                    React.createElement(Icon, { name: IconName.PDF, className: styles('icon') }),
                    React.createElement("span", null,
                        additionalFile.file_name,
                        " "),
                    React.createElement(Icon, { name: IconName.DOWNLOAD, className: styles('icon') }))); }))),
            !credit && (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: styles('content-separator') }),
                React.createElement("h3", null, t(i18nKeys.INVOICE.COMMENT)),
                React.createElement(DebtorReactionForm, { onSubmit: comment })))),
        payInvoice && canUseOnlinePayment && (isFrenchClient ? canUseDigiteal : true) && (React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { className: styles('paid-button'), noMargin: true, color: ButtonColor.GREEN, iconLeft: IconName.CREDIT_CARD, iconSize: "20px", label: t(i18nKeys.DEBTOR.REACTION.PAY), onClick: payInvoice })))));
}
