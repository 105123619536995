import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isNullish } from 'remeda';
import { useLoadAgedBalance, useLoadAgedBalanceCompact, useLoadAgedBalanceOverTime, useLoadViewsV2, } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { ActionIcon, Card, Group, LoadingOverlay, Select, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconArrowLeft } from '@tabler/icons-react';
import { UpdatedAt } from '../components';
import { AgedBalanceCompactChart, AgedBalanceOverTimeChart, AgedBalanceTable, } from '../components/charts';
export var AgedBalance = function () {
    var _a;
    var history = useHistory();
    var t = useTranslation().t;
    var _b = __read(useLocalStorage({
        key: 'aged-balance-view',
        defaultValue: null,
    }), 2), selectedViewId = _b[0], setSelectedViewId = _b[1];
    var debtorViews = useLoadViewsV2({
        resourceType: 'debtors',
        onSuccess: function (views) {
            if (isNullish(selectedViewId))
                setSelectedViewId(views[0].id);
        },
    }).views;
    /* When we change the viewId, the queryKey of the requests below will change, and their data
      will be undefined until the new request completes. We store the data in state so that when
      changing views, the loading spinner can appear on top of the stale data, instead of everything
      jarringly flashing off then back on. */
    var _c = __read(useState(), 2), savedAgedBalanceCompact = _c[0], setSavedAgedBalanceCompact = _c[1];
    var isAgedBalanceCompactLoading = useLoadAgedBalanceCompact({
        viewId: selectedViewId,
        onSuccess: setSavedAgedBalanceCompact,
    }).isAgedBalanceCompactLoading;
    var _d = __read(useState(), 2), savedAgedBalance = _d[0], setSavedAgedBalance = _d[1];
    var isAgedBalanceLoading = useLoadAgedBalance({
        viewId: selectedViewId,
        onSuccess: setSavedAgedBalance,
    }).isAgedBalanceLoading;
    var _e = __read(useState(), 2), savedAgedBalanceOverTime = _e[0], setSavedAgedBalanceOverTime = _e[1];
    var _f = useLoadAgedBalanceOverTime({
        viewId: selectedViewId,
        onSuccess: setSavedAgedBalanceOverTime,
    }), agedBalanceOverTime = _f.agedBalanceOverTime, isAgedBalanceOverTimeLoading = _f.isAgedBalanceOverTimeLoading;
    var _g = __read(useState('overTime'), 2), agedBalanceVersion = _g[0], setAgedBalanceVersion = _g[1];
    // If there are too few dates for the balance over time to display nicely, fallback to compact balance
    useEffect(function () {
        if (!isAgedBalanceOverTimeLoading && agedBalanceOverTime != null) {
            dispatchChartComponent(agedBalanceOverTime);
        }
    }, [isAgedBalanceOverTimeLoading, agedBalanceOverTime]);
    var dispatchChartComponent = function (_agedBalanceOverTime) {
        var amountOfDatesPresent = Object.keys(_agedBalanceOverTime.data).length;
        var shouldFallbackToCompactBalance = amountOfDatesPresent < 2;
        if (shouldFallbackToCompactBalance)
            setAgedBalanceVersion('compact');
    };
    var _h = __read(agedBalanceVersion === 'compact'
        ? [
            isAgedBalanceCompactLoading,
            React.createElement(AgedBalanceCompactChart, { agedBalanceCompact: savedAgedBalanceCompact }),
        ]
        : [
            isAgedBalanceOverTimeLoading,
            React.createElement(AgedBalanceOverTimeChart, { agedBalanceOverTime: savedAgedBalanceOverTime }),
        ], 2), isLoading = _h[0], chartComponent = _h[1];
    return (React.createElement("div", null,
        React.createElement(PageTitle, null,
            React.createElement(Group, { align: "center", justify: "center" },
                React.createElement(ActionIcon, { onClick: function () { return history.push('/analytics'); }, size: "xl", variant: "light" },
                    React.createElement(IconArrowLeft, null)),
                React.createElement(Stack, { gap: 0 },
                    t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE),
                    React.createElement(UpdatedAt, null))),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Select, { label: t(i18nKeys.FILTER), onChange: setSelectedViewId, allowDeselect: false, value: selectedViewId, data: (_a = debtorViews === null || debtorViews === void 0 ? void 0 : debtorViews.map(function (_a) {
                        var id = _a.id, name = _a.name;
                        return ({ label: name, value: id });
                    })) !== null && _a !== void 0 ? _a : [] }))),
        React.createElement(Space, { h: "md" }),
        React.createElement(Card, { radius: "md", shadow: "sm", style: { overflow: 'visible' } },
            React.createElement(LoadingOverlay, { visible: isLoading }),
            chartComponent),
        React.createElement(AgedBalanceTable, { agedBalance: savedAgedBalance, isLoading: isAgedBalanceLoading })));
};
