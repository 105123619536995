import React from 'react';
import classNames from 'classnames/bind';
import logoColorWhite from './assets/logo-color-white.svg';
import logoSmallDark from './assets/logo-dark.svg';
import logoSmallGrey from './assets/logo-grey.svg';
import logoGrey from './assets/logo-white.svg';
import logoBlue from './assets/recovr.svg';
import logoSmallRecovr from './assets/small-recovr.svg';
import styleIdentifiers from './logo.scss';
var styles = classNames.bind(styleIdentifiers);
var logoImg = {
    blue: logoBlue,
    white: logoColorWhite,
    grey: logoGrey,
    'small-grey': logoSmallGrey,
    'small-dark': logoSmallDark,
    small: logoSmallRecovr,
};
export default function Logo(_a) {
    var type = _a.type, width = _a.width, height = _a.height, className = _a.className, alt = _a.alt;
    var style = {
        width: width || '100%',
        height: height,
    };
    return (React.createElement("div", { className: styles('Logo', className) },
        React.createElement("img", { src: logoImg[type], style: style, alt: alt })));
}
