import { upperFirst } from 'lodash';
export function addActionNameToMutationResult(actionName, mutationResult) {
    var _a;
    var actionNameCapitalised = upperFirst(actionName);
    return _a = {},
        _a["".concat(actionName, "Result")] = mutationResult.data,
        _a["".concat(actionName, "Error")] = mutationResult.error,
        _a["is".concat(actionNameCapitalised, "Loading")] = mutationResult.isLoading,
        _a[actionName] = mutationResult.mutate,
        _a;
}
