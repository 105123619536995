import { __assign, __read, __spreadArray } from "tslib";
/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from './mutator/custom-instance';
/**
 * @summary Get activities for query parameters
 */
export var getActivities = function (params, signal) {
    return customInstance({
        url: "/private_api/activities",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetActivitiesQueryKey = function (params) { return __spreadArray([
    "/private_api/activities"
], __read((params ? [params] : [])), false); };
export var useGetActivities = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetActivitiesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getActivities(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
export var getPrivateConstants = function (params, signal) {
    return customInstance({
        url: "/private_api/private_constants",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetPrivateConstantsQueryKey = function (params) { return __spreadArray([
    "/private_api/private_constants"
], __read((params ? [params] : [])), false); };
export var useGetPrivateConstants = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPrivateConstantsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPrivateConstants(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get debtors for query parameters
 */
export var getDebtors = function (params) {
    return customInstance({
        url: "/private_api/debtors",
        method: 'get',
        params: params,
    });
};
export var getGetDebtorsQueryKey = function (params) { return __spreadArray([
    "/private_api/debtors"
], __read((params ? [params] : [])), false); };
export var useGetDebtorsInfinite = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsQueryKey(params);
    var queryFn = function (_a) {
        var pageParam = _a.pageParam;
        return getDebtors(__assign({ page: pageParam }, params));
    };
    var query = useInfiniteQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
export var useGetDebtors = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsQueryKey(params);
    var queryFn = function () { return getDebtors(params); };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get debtor details
 */
export var getDebtor = function (id, params, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id),
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorQueryKey = function (id, params) { return __spreadArray([
    "/private_api/debtors/".concat(id)
], __read((params ? [params] : [])), false); };
export var useGetDebtor = function (id, params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorQueryKey(id, params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtor(id, params, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Mark a debtor as disputed
 */
export var disputeDebtor = function (id, disputeDebtorBody) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/dispute_debtor"),
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: disputeDebtorBody,
    });
};
export var useDisputeDebtor = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return disputeDebtor(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a debtor's emails and activities sorted by date
 */
export var getDebtorTimeline = function (id, params, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/timeline"),
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorTimelineQueryKey = function (id, params) { return __spreadArray(["/private_api/debtors/".concat(id, "/timeline")], __read((params ? [params] : [])), false); };
export var useGetDebtorTimeline = function (id, params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorTimelineQueryKey(id, params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorTimeline(id, params, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get products for query parameters
 */
export var getProducts = function (params, signal) {
    return customInstance({
        url: "/private_api/products",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetProductsQueryKey = function (params) { return __spreadArray([
    "/private_api/products"
], __read((params ? [params] : [])), false); };
export var useGetProducts = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetProductsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getProducts(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Gets an invoice
 */
export var getInvoices = function (id, signal) {
    return customInstance({
        url: "/private_api/invoices/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetInvoicesQueryKey = function (id) { return ["/private_api/invoices/".concat(id)]; };
export var useGetInvoices = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetInvoicesQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getInvoices(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates an invoice
 */
export var updateInvoice = function (id, updateInvoiceBody) {
    return customInstance({
        url: "/private_api/invoices/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: updateInvoiceBody,
    });
};
export var useUpdateInvoice = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateInvoice(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Search invoices for query parameters
 */
export var searchInvoices = function (params) {
    return customInstance({
        url: "/private_api/credit_note/invoices",
        method: 'get',
        params: params,
    });
};
export var getSearchInvoicesQueryKey = function (params) { return __spreadArray([
    "/private_api/credit_note/invoices"
], __read((params ? [params] : [])), false); };
export var useSearchInvoicesInfinite = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchInvoicesQueryKey(params);
    var queryFn = function (_a) {
        var pageParam = _a.pageParam;
        return searchInvoices(__assign({ page: pageParam }, params));
    };
    var query = useInfiniteQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
export var useSearchInvoices = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchInvoicesQueryKey(params);
    var queryFn = function () {
        return searchInvoices(params);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get recovery plans list
 */
export var getRecoveryPlans = function (signal) {
    return customInstance({
        url: "/private_api/recovery_plans",
        method: 'get',
        signal: signal,
    });
};
export var getGetRecoveryPlansQueryKey = function () { return ["/private_api/recovery_plans"]; };
export var useGetRecoveryPlans = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecoveryPlansQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getRecoveryPlans(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a recovery plan
 */
export var createRecoveryPlan = function (createRecoveryPlanBody) {
    return customInstance({
        url: "/private_api/recovery_plans",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createRecoveryPlanBody,
    });
};
export var useCreateRecoveryPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createRecoveryPlan(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a email domain stored in DB
 */
export var getEmailDomain = function (signal) {
    return customInstance({
        url: "/private_api/email_domain",
        method: 'get',
        signal: signal,
    });
};
export var getGetEmailDomainQueryKey = function () { return ["/private_api/email_domain"]; };
export var useGetEmailDomain = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetEmailDomainQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getEmailDomain(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get a domain from Sendgrid
 */
export var getDomain = function (id, signal) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetDomainQueryKey = function (id) { return [
    "/private_api/sendgrid/authenticate_domains/".concat(id),
]; };
export var useGetDomain = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDomainQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDomain(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a domain from Sendgrid
 */
export var updateDomain = function (id) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'put',
    });
};
export var useUpdateDomain = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return updateDomain(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a domain
 */
export var deleteDomain = function (id) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'delete',
    });
};
export var useDeleteDomain = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteDomain(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Authenticate a domain
 */
export var authenticateDomain = function (authenticateDomainBody) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: authenticateDomainBody,
    });
};
export var useAuthenticateDomain = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return authenticateDomain(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a debtor sending options
 */
export var getDebtorSendingOptions = function (id, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/sending_options"),
        method: 'get',
        signal: signal,
    });
};
export var getGetDebtorSendingOptionsQueryKey = function (id) { return [
    "/private_api/debtors/".concat(id, "/sending_options"),
]; };
export var useGetDebtorSendingOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorSendingOptionsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorSendingOptions(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Get a recovery plan
 */
export var getRecoveryPlan = function (id, signal) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetRecoveryPlanQueryKey = function (id) { return [
    "/private_api/recovery_plans/".concat(id),
]; };
export var useGetRecoveryPlan = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecoveryPlanQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getRecoveryPlan(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a recovery plan
 */
export var updateRecoveryPlan = function (id, createRecoveryPlanBody) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createRecoveryPlanBody,
    });
};
export var useUpdateRecoveryPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateRecoveryPlan(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a recovery plan
 */
export var deleteRecoveryPlan = function (id) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'delete',
    });
};
export var useDeleteRecoveryPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteRecoveryPlan(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get reasons list
 */
export var getReasons = function (params, signal) {
    return customInstance({
        url: "/private_api/reasons",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetReasonsQueryKey = function (params) { return __spreadArray([
    "/private_api/reasons"
], __read((params ? [params] : [])), false); };
export var useGetReasons = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetReasonsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getReasons(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a reason
 */
export var createReason = function (createReasonBody) {
    return customInstance({
        url: "/private_api/reasons",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createReasonBody,
    });
};
export var useCreateReason = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createReason(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update a reason
 */
export var updateReason = function (id, createReasonBody) {
    return customInstance({
        url: "/private_api/reasons/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createReasonBody,
    });
};
export var useUpdateReason = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateReason(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a reason
 */
export var deleteReason = function (id) {
    return customInstance({ url: "/private_api/reasons/".concat(id), method: 'delete' });
};
export var useDeleteReason = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteReason(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get invoices to confirm list
 */
export var getInvoicesToConfirm = function (params, signal) {
    return customInstance({
        url: "/private_api/import_invoices",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetInvoicesToConfirmQueryKey = function (params) { return __spreadArray([
    "/private_api/import_invoices"
], __read((params ? [params] : [])), false); };
export var useGetInvoicesToConfirm = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetInvoicesToConfirmQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getInvoicesToConfirm(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Upload invoices CSV
 */
export var uploadInvoiceCSV = function (uploadInvoiceCSVBody) {
    return customInstance({
        url: "/private_api/import_invoices",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: uploadInvoiceCSVBody,
    });
};
export var useUploadInvoiceCSV = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return uploadInvoiceCSV(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete an unvalidated invoice
 */
export var deleteUnvalidatedInvoice = function (id) {
    return customInstance({
        url: "/private_api/import_invoices/".concat(id),
        method: 'delete',
    });
};
export var useDeleteUnvalidatedInvoice = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteUnvalidatedInvoice(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get debtors to confirm list
 */
export var getDebtorsToConfirm = function (params, signal) {
    return customInstance({
        url: "/private_api/import_debtors",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorsToConfirmQueryKey = function (params) { return __spreadArray([
    "/private_api/import_debtors"
], __read((params ? [params] : [])), false); };
export var useGetDebtorsToConfirm = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsToConfirmQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorsToConfirm(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Upload a debtor CSV
 */
export var uploadDebtorCSV = function (uploadDebtorCSVBody) {
    return customInstance({
        url: "/private_api/import_debtors",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: uploadDebtorCSVBody,
    });
};
export var useUploadDebtorCSV = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return uploadDebtorCSV(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get debtor to confirm details
 */
export var getDebtorToConfirm = function (id, signal) {
    return customInstance({
        url: "/private_api/import_debtors/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetDebtorToConfirmQueryKey = function (id) { return [
    "/private_api/import_debtors/".concat(id),
]; };
export var useGetDebtorToConfirm = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorToConfirmQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorToConfirm(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Delete an unvalidated debtor
 */
export var deleteUnvalidatedDebtor = function (id) {
    return customInstance({
        url: "/private_api/import_debtors/".concat(id),
        method: 'delete',
    });
};
export var useDeleteUnvalidatedDebtor = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteUnvalidatedDebtor(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get similar debtors list
 */
export var getSimilarDebtors = function (signal) {
    return customInstance({
        url: "/private_api/similar_debtors",
        method: 'get',
        signal: signal,
    });
};
export var getGetSimilarDebtorsQueryKey = function () { return ["/private_api/similar_debtors"]; };
export var useGetSimilarDebtors = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSimilarDebtorsQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getSimilarDebtors(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Merge similar debtors
 */
export var mergeSimilarDebtors = function (mergeSimilarDebtorsBody) {
    return customInstance({
        url: "/private_api/similar_debtors",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: mergeSimilarDebtorsBody,
    });
};
export var useMergeSimilarDebtors = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return mergeSimilarDebtors(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete unvalidated imports (debtors or invoices)
 */
export var deleteUnvalidatedImports = function (deleteUnvalidatedImportsBody) {
    return customInstance({
        url: "/private_api/unvalidated_imports",
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        data: deleteUnvalidatedImportsBody,
    });
};
export var useDeleteUnvalidatedImports = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return deleteUnvalidatedImports(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get similar debtors to given id
 */
export var getMergeableDebtors = function (id, signal) {
    return customInstance({
        url: "/private_api/similar_debtors/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetMergeableDebtorsQueryKey = function (id) { return [
    "/private_api/similar_debtors/".concat(id),
]; };
export var useGetMergeableDebtors = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMergeableDebtorsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getMergeableDebtors(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Ignore duplicates
 */
export var ignoreDuplicates = function (id) {
    return customInstance({
        url: "/private_api/similar_debtors/".concat(id),
        method: 'delete',
    });
};
export var useIgnoreDuplicates = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return ignoreDuplicates(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get task types list
 */
export var getTaskTypes = function (signal) {
    return customInstance({
        url: "/private_api/task_types",
        method: 'get',
        signal: signal,
    });
};
export var getGetTaskTypesQueryKey = function () { return ["/private_api/task_types"]; };
export var useGetTaskTypes = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTaskTypesQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTaskTypes(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a task type
 */
export var createTaskType = function (createTaskTypeBody) {
    return customInstance({
        url: "/private_api/task_types",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskTypeBody,
    });
};
export var useCreateTaskType = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTaskType(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update a task type
 */
export var updateTaskType = function (id, createTaskTypeBody) {
    return customInstance({
        url: "/private_api/task_types/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskTypeBody,
    });
};
export var useUpdateTaskType = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTaskType(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a task type
 */
export var deleteTaskType = function (id) {
    return customInstance({
        url: "/private_api/task_types/".concat(id),
        method: 'delete',
    });
};
export var useDeleteTaskType = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTaskType(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get tasks list
 */
export var getTasks = function (params, signal) {
    return customInstance({
        url: "/private_api/tasks",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetTasksQueryKey = function (params) { return __spreadArray([
    "/private_api/tasks"
], __read((params ? [params] : [])), false); };
export var useGetTasks = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTasksQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTasks(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a task
 */
export var createTask = function (createTaskBody) {
    return customInstance({
        url: "/private_api/tasks",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskBody,
    });
};
export var useCreateTask = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTask(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a task
 */
export var getTask = function (id, signal) {
    return customInstance({ url: "/private_api/tasks/".concat(id), method: 'get', signal: signal });
};
export var getGetTaskQueryKey = function (id) { return ["/private_api/tasks/".concat(id)]; };
export var useGetTask = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTaskQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTask(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a task
 */
export var updateTask = function (id, createTaskBody) {
    return customInstance({
        url: "/private_api/tasks/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskBody,
    });
};
export var useUpdateTask = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTask(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a task
 */
export var deleteTask = function (id) {
    return customInstance({ url: "/private_api/tasks/".concat(id), method: 'delete' });
};
export var useDeleteTask = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTask(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Create a comment on a task
 */
export var createTaskComment = function (id, createTaskCommentBody) {
    return customInstance({
        url: "/private_api/tasks/".concat(id, "/comments"),
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskCommentBody,
    });
};
export var useCreateTaskComment = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return createTaskComment(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update tasks status
 */
export var updateTasksStatus = function (updateTasksStatusBody) {
    return customInstance({
        url: "/private_api/tasks/update_status",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: updateTasksStatusBody,
    });
};
export var useUpdateTasksStatus = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return updateTasksStatus(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * Get available conditions list
 */
export var getAvailableConditions = function (params, signal) {
    return customInstance({
        url: "/private_api/available_conditions",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetAvailableConditionsQueryKey = function (params) { return __spreadArray([
    "/private_api/available_conditions"
], __read((params ? [params] : [])), false); };
export var useGetAvailableConditions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAvailableConditionsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAvailableConditions(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * Get automatic assignations list
 */
export var getAutomaticAssignations = function (params, signal) {
    return customInstance({
        url: "/private_api/automatic_assignations",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetAutomaticAssignationsQueryKey = function (params) { return __spreadArray([
    "/private_api/automatic_assignations"
], __read((params ? [params] : [])), false); };
export var useGetAutomaticAssignations = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAutomaticAssignationsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAutomaticAssignations(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * Create an automatic assignation
 */
export var createAutomaticAssignation = function (createAutomaticAssignationBody) {
    return customInstance({
        url: "/private_api/automatic_assignations",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createAutomaticAssignationBody,
    });
};
export var useCreateAutomaticAssignation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createAutomaticAssignation(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * Get an automatic assignation
 */
export var getAutomaticAssignation = function (id, signal) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetAutomaticAssignationQueryKey = function (id) { return [
    "/private_api/automatic_assignations/".concat(id),
]; };
export var useGetAutomaticAssignation = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAutomaticAssignationQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAutomaticAssignation(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * Update an automatic assignation
 */
export var updateAutomaticAssignation = function (id, createAutomaticAssignationBody) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createAutomaticAssignationBody,
    });
};
export var useUpdateAutomaticAssignation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateAutomaticAssignation(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * Delete an automatic assignation
 */
export var deleteAutomaticAssignation = function (id) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'delete',
    });
};
export var useDeleteAutomaticAssignation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteAutomaticAssignation(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var getContactPersons = function (id, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons"),
        method: 'get',
        signal: signal,
    });
};
export var getGetContactPersonsQueryKey = function (id) { return [
    "/private_api/debtors/".concat(id, "/contact_persons"),
]; };
export var useGetContactPersons = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetContactPersonsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getContactPersons(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
export var createContactPerson = function (id, createContactPersonBody) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons"),
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createContactPersonBody,
    });
};
export var useCreateContactPerson = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return createContactPerson(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var getContactPerson = function (id, contactPersonId, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'get',
        signal: signal,
    });
};
export var getGetContactPersonQueryKey = function (id, contactPersonId) { return [
    "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
]; };
export var useGetContactPerson = function (id, contactPersonId, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetContactPersonQueryKey(id, contactPersonId);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getContactPerson(id, contactPersonId, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!(id && contactPersonId) }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
export var updateContactPerson = function (id, contactPersonId, createContactPersonBody) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createContactPersonBody,
    });
};
export var useUpdateContactPerson = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, contactPersonId = _a.contactPersonId, data = _a.data;
        return updateContactPerson(id, contactPersonId, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var deleteContactPerson = function (id, contactPersonId) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'delete',
    });
};
export var useDeleteContactPerson = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, contactPersonId = _a.contactPersonId;
        return deleteContactPerson(id, contactPersonId);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Create a payment step calculation
 */
export var createPaymentStepCalculation = function (createPaymentStepCalculationBody) {
    return customInstance({
        url: "/private_api/payment_step_calculations",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentStepCalculationBody,
    });
};
export var useCreatePaymentStepCalculation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createPaymentStepCalculation(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Update a payment step calculation
 */
export var updatePaymentStepCalculation = function (id, updatePaymentStepCalculationBody) {
    return customInstance({
        url: "/private_api/payment_step_calculations/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: updatePaymentStepCalculationBody,
    });
};
export var useUpdatePaymentStepCalculation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updatePaymentStepCalculation(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get payment plans list
 */
export var getPaymentPlans = function (params, signal) {
    return customInstance({
        url: "/private_api/payment_plans",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetPaymentPlansQueryKey = function (params) { return __spreadArray([
    "/private_api/payment_plans"
], __read((params ? [params] : [])), false); };
export var useGetPaymentPlans = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPaymentPlansQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPaymentPlans(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a payment plan
 */
export var createPaymentPlan = function (createPaymentPlanBody) {
    return customInstance({
        url: "/private_api/payment_plans",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentPlanBody,
    });
};
export var useCreatePaymentPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createPaymentPlan(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a payment plan
 */
export var getPaymentPlan = function (id, signal) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetPaymentPlanQueryKey = function (id) { return [
    "/private_api/payment_plans/".concat(id),
]; };
export var useGetPaymentPlan = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPaymentPlanQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPaymentPlan(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a payment plan
 */
export var updatePaymentPlan = function (id, createPaymentPlanBody) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentPlanBody,
    });
};
export var useUpdatePaymentPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updatePaymentPlan(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Cancel a payment plan
 */
export var cancelPaymentPlan = function (id, cancelPaymentPlanBody) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id, "/cancel"),
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: cancelPaymentPlanBody,
    });
};
export var useCancelPaymentPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return cancelPaymentPlan(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get workflows list
 */
export var getWorkflows = function (signal) {
    return customInstance({
        url: "/private_api/workflows",
        method: 'get',
        signal: signal,
    });
};
export var getGetWorkflowsQueryKey = function () { return ["/private_api/workflows"]; };
export var useGetWorkflows = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetWorkflowsQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getWorkflows(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a workflow
 */
export var createWorkflow = function (createWorkflowBody) {
    return customInstance({
        url: "/private_api/workflows",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createWorkflowBody,
    });
};
export var useCreateWorkflow = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createWorkflow(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a workflow
 */
export var getWorkflow = function (id, signal) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetWorkflowQueryKey = function (id) { return ["/private_api/workflows/".concat(id)]; };
export var useGetWorkflow = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetWorkflowQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getWorkflow(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a workflow
 */
export var updateWorkflow = function (id, createWorkflowBody) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createWorkflowBody,
    });
};
export var useUpdateWorkflow = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateWorkflow(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a workflow
 */
export var deleteWorkflow = function (id) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'delete',
    });
};
export var useDeleteWorkflow = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteWorkflow(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get Templates list
 */
export var getTemplates = function (params, signal) {
    return customInstance({
        url: "/private_api/templates",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getGetTemplatesQueryKey = function (params) { return __spreadArray([
    "/private_api/templates"
], __read((params ? [params] : [])), false); };
export var useGetTemplates = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTemplatesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTemplates(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Create a template
 */
export var createTemplate = function (createTemplateBody) {
    return customInstance({
        url: "/private_api/templates",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createTemplateBody,
    });
};
export var useCreateTemplate = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTemplate(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get a template
 */
export var getTemplate = function (id, signal) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetTemplateQueryKey = function (id) { return ["/private_api/templates/".concat(id)]; };
export var useGetTemplate = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTemplateQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTemplate(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a template
 */
export var updateTemplate = function (id, updateTemplateBody) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: updateTemplateBody,
    });
};
export var useUpdateTemplate = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTemplate(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Delete a template
 */
export var deleteTemplate = function (id) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'delete',
    });
};
export var useDeleteTemplate = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTemplate(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var getExternalMailboxConfigurations = function (signal) {
    return customInstance({
        url: "/private_api/external_mailbox_configurations",
        method: 'get',
        signal: signal,
    });
};
export var getGetExternalMailboxConfigurationsQueryKey = function () { return [
    "/private_api/external_mailbox_configurations",
]; };
export var useGetExternalMailboxConfigurations = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetExternalMailboxConfigurationsQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getExternalMailboxConfigurations(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
export var createExternalMailboxConfiguration = function (createExternalMailboxConfigurationBody) {
    return customInstance({
        url: "/private_api/external_mailbox_configurations",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: createExternalMailboxConfigurationBody,
    });
};
export var useCreateExternalMailboxConfiguration = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createExternalMailboxConfiguration(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var getExternalMailboxConfiguration = function (id, signal) {
    return customInstance({
        url: "/private_api/external_mailbox_configurations/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetExternalMailboxConfigurationQueryKey = function (id) { return [
    "/private_api/external_mailbox_configurations/".concat(id),
]; };
export var useGetExternalMailboxConfiguration = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetExternalMailboxConfigurationQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getExternalMailboxConfiguration(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
export var updateExternalMailboxConfiguration = function (id, createExternalMailboxConfigurationBody) {
    return customInstance({
        url: "/private_api/external_mailbox_configurations/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: createExternalMailboxConfigurationBody,
    });
};
export var useUpdateExternalMailboxConfiguration = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateExternalMailboxConfiguration(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var deleteExternalMailboxConfiguration = function (id) {
    return customInstance({
        url: "/private_api/external_mailbox_configurations/".concat(id),
        method: 'delete',
    });
};
export var useDeleteExternalMailboxConfiguration = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteExternalMailboxConfiguration(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Force mailbox sync to get the latest ones
 */
export var manuallySyncExternalMails = function () {
    return customInstance({
        url: "/private_api/fetch_external_mails",
        method: 'post',
    });
};
export var useManuallySyncExternalMails = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function () {
        return manuallySyncExternalMails();
    };
    return useMutation(mutationFn, mutationOptions);
};
export var searchExternalMails = function (params, signal) {
    return customInstance({
        url: "/private_api/external_mails",
        method: 'get',
        params: params,
        signal: signal,
    });
};
export var getSearchExternalMailsQueryKey = function (params) { return __spreadArray([
    "/private_api/external_mails"
], __read((params ? [params] : [])), false); };
export var useSearchExternalMails = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchExternalMailsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return searchExternalMails(params, signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Reply to an existing external mail
 */
export var sendExternalMail = function (replyToExternalMailBody) {
    return customInstance({
        url: "/private_api/external_mails",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: replyToExternalMailBody,
    });
};
export var useSendExternalMail = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return sendExternalMail(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var getExternalMail = function (id, signal) {
    return customInstance({
        url: "/private_api/external_mails/".concat(id),
        method: 'get',
        signal: signal,
    });
};
export var getGetExternalMailQueryKey = function (id) { return [
    "/private_api/external_mails/".concat(id),
]; };
export var useGetExternalMail = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetExternalMailQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getExternalMail(id, signal);
    };
    var query = useQuery(queryKey, queryFn, __assign({ enabled: !!id }, queryOptions));
    query.queryKey = queryKey;
    return query;
};
export var updateExternalMail = function (id, updateExternalMailBody) {
    return customInstance({
        url: "/private_api/external_mails/".concat(id),
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: updateExternalMailBody,
    });
};
export var useUpdateExternalMail = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateExternalMail(id, data);
    };
    return useMutation(mutationFn, mutationOptions);
};
export var deleteExternalMail = function (id) {
    return customInstance({
        url: "/private_api/external_mails/".concat(id),
        method: 'delete',
    });
};
export var useDeleteExternalMail = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteExternalMail(id);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Execute action in batch on external mails
 */
export var batchActionOnExternalMail = function (batchActionOnExternalMailBody) {
    return customInstance({
        url: "/private_api/external_mails/batch",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: batchActionOnExternalMailBody,
    });
};
export var useBatchActionOnExternalMail = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return batchActionOnExternalMail(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Request a payment plan for debtor portal
 */
export var requestPaymentPlan = function (requestPaymentPlanBody) {
    return customInstance({
        url: "/private_api/request_payment_plan",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: requestPaymentPlanBody,
    });
};
export var useRequestPaymentPlan = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return requestPaymentPlan(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Decline a payment plan request make from debtor portal
 */
export var declinePaymentPlanRequest = function (declinePaymentPlanRequestBody) {
    return customInstance({
        url: "/private_api/decline_payment_plan_request",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: declinePaymentPlanRequestBody,
    });
};
export var useDeclinePaymentPlanRequest = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return declinePaymentPlanRequest(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Get the current user encoded JWT
 */
export var getEmbedUserJWT = function (signal) {
    return customInstance({ url: "/private_api/jwt", method: 'get', signal: signal });
};
export var getGetEmbedUserJWTQueryKey = function () { return ["/private_api/jwt"]; };
export var useGetEmbedUserJWT = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetEmbedUserJWTQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getEmbedUserJWT(signal);
    };
    var query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Reconcile payments and invoices
 */
export var manualReconciliation = function (reconcilePaymentsAndInvoicesBody) {
    return customInstance({
        url: "/private_api/manual_reconciliation",
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: reconcilePaymentsAndInvoicesBody,
    });
};
export var useManualReconciliation = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return manualReconciliation(data);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Reset the accounting integration
 */
export var resetAccountingIntegration = function () {
    return customInstance({
        url: "/private_api/accounting_integrations/reset",
        method: 'post',
    });
};
export var useResetAccountingIntegration = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function () {
        return resetAccountingIntegration();
    };
    return useMutation(mutationFn, mutationOptions);
};
export * from './index';
