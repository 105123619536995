import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import AddressBlock from 'shared/components/AddressBlock';
import TvaNumber from 'shared/components/TvaNumber';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CompanyDetails = function (_a) {
    var company = _a.company;
    var t = useTranslation().t;
    var name = company.name, address_attributes = company.address_attributes, vat_number = company.vat_number;
    return (React.createElement("div", { className: styles('company-detail') },
        React.createElement("div", { className: styles('name') }, name),
        React.createElement(AddressBlock, { address: address_attributes }),
        React.createElement("br", null),
        React.createElement("div", null,
            t(i18nKeys.VAT),
            " : ",
            React.createElement(TvaNumber, { value: vat_number }))));
};
