import React from 'react';
import { useCreateContactPerson, useGetContactPersons } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { ContactPersonForm } from '../ContactPersonForm/ContactPersonForm';
import { ContactPersonsListItem } from './ContactPersonsListItem';
import styleIdentifiers from './ContactPersonsList.scss';
var EnhancedTable = CustomTable(ContactPersonsListItem);
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonsList = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var createContactPersonMutation = useCreateContactPerson().mutate;
    var _b = useGetContactPersons(debtor.id), contactPersonsResponse = _b.data, isLoading = _b.isLoading, isFetched = _b.isFetched, refetch = _b.refetch;
    var TABLE_HEADERS = [
        {
            title: t(i18nKeys.FORM.STRIPE.FULL_NAME),
        },
        {
            title: t(i18nKeys.FORM.JOB_TITLE),
        },
        {
            title: t(i18nKeys.FORM.NOTIFICATIONS),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var addContactPerson = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(ContactPersonForm, { onSubmit: function (data) {
                    return createContactPersonMutation({ id: debtor.id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    return (React.createElement("div", { className: styles('listing') },
        React.createElement(EnhancedTable, { subtitle: t(i18nKeys.CONTACT_PERSON.TITLE), actions: React.createElement(Button, { label: t(i18nKeys.ACTIONS.ADD), onClick: addContactPerson, iconRight: IconName.MINIMAL_RIGHT, noMargin: true }), loading: isLoading, loaded: isFetched, headers: TABLE_HEADERS, items: (contactPersonsResponse === null || contactPersonsResponse === void 0 ? void 0 : contactPersonsResponse.data) || [], itemProps: { debtor: debtor, refetch: refetch }, noCheckbox: true, noShadow: true, noFooter: true, noSetTabTitle: true })));
};
