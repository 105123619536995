import { __makeTemplateObject } from "tslib";
import React from 'react';
import { isDefined } from 'remeda';
import { css, cx } from '@emotion/css';
import { Card } from '@mantine/core';
var styles = {
    clickable: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n    transition: all 150ms ease-in-out;\n\n    &:hover {\n      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.05) 0 10px 15px -5px,\n        rgba(0, 0, 0, 0.04) 0 7px 7px -5px;\n    }\n  "], ["\n    cursor: pointer;\n    transition: all 150ms ease-in-out;\n\n    &:hover {\n      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.05) 0 10px 15px -5px,\n        rgba(0, 0, 0, 0.04) 0 7px 7px -5px;\n    }\n  "]))),
};
export var KanbanCard = function (_a) {
    var _b;
    var children = _a.children, color = _a.color, onClick = _a.onClick;
    return (React.createElement(Card, { className: cx((_b = {}, _b[styles.clickable] = isDefined(onClick), _b)), shadow: "xs", onClick: onClick, p: 8, style: {
            borderLeft: "4px solid ".concat(color),
            flexShrink: 0,
        } }, children));
};
var templateObject_1;
