import Settings from './Settings';
export * from './CollaboratorsSettings/CollaboratorsSettings';
export * from './CompanySettings/CompanySettings';
export * from './CustomzationsSettings/CustomizationsSettings';
export * from './ExternalMailboxSettings/ExternalMailboxSettings';
export * from './ExternalMailboxSettings/ExternalMailboxSettingsForm';
export * from './CustomzationsSettings/CustomizationsSettings';
export * from './IntegrationsSettings/IntegrationsSettings';
export * from './InvoiceSettings/InvoiceSettings';
export * from './PackageSettings/PackageSettings';
export * from './PreferenceSettings/PreferenceSettings';
export * from './Settings';
export * from './SettingsCard';
export * from './TemplatesSettings/TemplatesSettings';
export * from './UserSettings';
export * from './WorkflowsSettings/WorkflowsSettings';
export default Settings;
