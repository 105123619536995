import React from 'react';
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { DateSelector } from 'shared/io';
import { getDefaultIssueDate } from '../InvoiceForm.utils';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var IssueDateField = function (_a) {
    var setValue = useFormContext().setValue;
    var defaultDate = getDefaultIssueDate();
    return (React.createElement(Controller, { name: "issue_date", rules: { required: true }, defaultValue: defaultDate.toDate(), render: function () { return (React.createElement(DateSelector, { name: "issue_date", placeholder: defaultDate.format('DD/MM/YYYY'), className: styles('date-selector'), inputClassName: styles('value'), errorClassName: styles('error'), noErrorText: true, noMargin: true, noBorder: true, noMinDate: true, handleChange: function (value) {
                setValue('issue_date', value);
            } })); } }));
};
