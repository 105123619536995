import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './cropper.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Cropper(_a) {
    var src = _a.src, aspectRatio = _a.aspectRatio, action = _a.action, options = _a.options;
    var cropper;
    var t = useTranslation().t;
    useEffect(function () {
        if (cropper) {
            cropper.replace(src);
            cropper.setAspectRatio(aspectRatio);
        }
    }, [src, aspectRatio]);
    var setRef = function (image) {
        if (!image) {
            return;
        }
        image = image;
        if (!cropper) {
            createCropper();
        }
    };
    var saveCrop = function () {
        var canvas = cropper.getCroppedCanvas();
        if (!canvas) {
            return false;
        }
        if (typeof action === 'function') {
            action(canvas.toDataURL());
        }
    };
    var createCropper = function () {
        var newOptions = options;
        newOptions = newOptions || {};
        newOptions.aspectRatio = aspectRatio;
        newOptions.ready = function () { };
        newOptions.crop = function (event) { };
    };
    return (React.createElement("div", { className: styles('Cropper') },
        React.createElement("div", { className: styles('img') },
            React.createElement("img", { ref: setRef, src: src, alt: "" })),
        React.createElement("div", { className: styles('actions') },
            React.createElement(Button, { className: styles('no-margin'), color: ButtonColor.RED, label: t(i18nKeys.SAVE), onClick: saveCrop }))));
}
