import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useCreateReason, useCreateTaskType, useGetReasons, useGetTaskTypes } from 'api';
import { ReasonForm } from 'app/Private/Reasons/ReasonForm/ReasonForm';
import { TaskTypeForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import EditCustomVariable from 'shared/action-component/EditCustomVariable';
import { Icon, IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import { PageTitle } from 'shared/layout/PageTitle';
import { appConstants } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { CustomizationSettingsCustomVariables } from './CustomVariables/CustomizationSettingsCustomVariables';
import { CustomizationsSettingsReasons } from './Reasons/CustomizationsSettingsReasons';
import { CustomizationsSettingsTaskTypes } from './TaskTypes/CustomizationsSettingsTaskTypes';
import styleIdentifiers from './CustomizationsSettings.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomizationSettings = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var _a = useSelector(function (state) { return state.account.company.data.package; }), can_use_tasks = _a.can_use_tasks, can_use_custom_variables = _a.can_use_custom_variables;
    var dispatch = useDispatch();
    var _b = useGetTaskTypes(), taskTypesListResponse = _b.data, refetch = _b.refetch;
    var refetchReasons = useGetReasons().refetch;
    var createTaskType = useCreateTaskType().mutate;
    var createReason = useCreateReason().mutate;
    useEffect(function () {
        if (taskTypesListResponse) {
            var data = taskTypesListResponse.data;
            dispatch({
                type: appConstants.constants.result,
                status: 'fulfilled',
                payload: {
                    task_types: data.map(function (e) { return ({
                        description: e.attributes.name,
                        value: e.id,
                    }); }),
                },
            });
        }
    }, [taskTypesListResponse, dispatch]);
    var tabItems = [
        {
            title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.CUSTOM_VARIABLES),
            path: '/settings/customization/custom-variables',
            active: can_use_custom_variables,
            children: React.createElement(CustomizationSettingsCustomVariables, null),
            action: function () {
                EditCustomVariable({
                    customVariable: undefined,
                });
            },
        },
        {
            title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.TASK_TYPES),
            path: '/settings/customization/task-types',
            active: can_use_tasks,
            children: React.createElement(CustomizationsSettingsTaskTypes, null),
            action: function () {
                return showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.SMALL,
                    title: t(i18nKeys.ADD_TASK_TYPE),
                    children: (React.createElement(TaskTypeForm, { onCancel: function () { return dialogHide(DialogShowId.CUSTOM); }, onSubmit: function (data) {
                            return createTaskType({ data: data }, {
                                onSuccess: function () {
                                    refetch();
                                    dialogHide(DialogShowId.CUSTOM);
                                },
                            });
                        } })),
                });
            },
        },
        {
            title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.REASONS),
            path: '/settings/customization/reasons',
            active: true,
            children: React.createElement(CustomizationsSettingsReasons, null),
            action: function () {
                return showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.SMALL,
                    title: t(i18nKeys.ADD_REASON),
                    children: (React.createElement(ReasonForm, { onCancel: function () { return dialogHide(DialogShowId.CUSTOM); }, onSubmit: function (data) {
                            return createReason({ data: data }, {
                                onSuccess: function () {
                                    refetchReasons();
                                    dialogHide(DialogShowId.CUSTOM);
                                },
                            });
                        } })),
                });
            },
        },
    ];
    var _c = __read(useState(tabItems.map(function (e) { return e.path; }).indexOf(location.pathname)), 2), tabIndex = _c[0], setTabIndex = _c[1];
    return (React.createElement("div", { className: styles('customizations') },
        React.createElement(PageTitle, null, t(i18nKeys.NAV.CUSTOMIZATION)),
        React.createElement(Tabs, { className: styles('customizations__tabs'), tabIndex: tabIndex, items: tabItems.map(function (_a, i) {
                var active = _a.active, action = _a.action, title = _a.title;
                return (React.createElement("div", { className: styles('tab-item') },
                    React.createElement("h3", null, title),
                    action && i === tabIndex && (React.createElement("div", { className: styles('add-item-button'), title: !active ? t(i18nKeys.NOT_INCLUDED) : undefined },
                        React.createElement(Icon, { name: IconName.PLUS, className: styles(!active && 'disabled'), onClick: function () {
                                if (active) {
                                    action();
                                }
                            } })))));
            }), noBody: true, onChange: function (e) {
                setTabIndex(e);
                history.push(tabItems[e].path);
            } }),
        tabItems[tabIndex].children));
};
