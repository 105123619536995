import { __assign, __read } from "tslib";
import React, { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadInvoiceFooter } from 'shared/hooks';
import { isDynamicFooterField } from 'shared/utils';
import { Divider, Modal, Radio, Select, Text, TextInput } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { styled } from '@stitches/react';
import { ModalFooter } from '../ModalFooter';
var ItemOptionContainer = styled('div', {
    paddingLeft: '20px',
    display: 'flex',
    height: '100px',
    gap: '30px',
    alignItems: 'center',
    cursor: 'pointer',
    variants: {
        isChecked: {
            true: {
                backgroundColor: '#E7F5FF',
            },
            false: {
                opacity: 0.6,
                '&:hover': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
    },
});
var ItemOption = function (_a) {
    var isChecked = _a.isChecked, onClick = _a.onClick, children = _a.children;
    return (React.createElement(ItemOptionContainer, { isChecked: isChecked, onClick: isChecked ? undefined : onClick },
        React.createElement(Radio, { readOnly: true, size: "md", checked: isChecked }),
        children));
};
export var EditFooterItemModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSave = _a.onSave, item = _a.item;
    var t = useTranslation().t;
    var _b = __read(React.useState(item), 2), value = _b[0], setValue = _b[1];
    var textInputRef = React.useRef(null);
    var selectInputRef = React.useRef(null);
    var invoiceFooter = useLoadInvoiceFooter().invoiceFooter;
    useHotkeys([['Enter', function () { return onSave(value); }]]);
    useEffect(function () { return setValue(item); }, [item, isOpen]);
    if (invoiceFooter == null)
        return null;
    var isNull = value == null;
    var isString = typeof value === 'string';
    var isDynamicField = isDynamicFooterField(value);
    var isDirty = value !== item;
    var selectOptions = invoiceFooter.availableFields.map(function (_a) {
        var field = _a.field, name = _a.name;
        return ({
            value: field,
            label: name,
        });
    });
    return (React.createElement(Modal, { h: "fit-content", opened: isOpen, onClose: onClose, title: t(i18nKeys.SETTINGS.INVOICING.FOOTER.MODAL_TITLE), centered: true, styles: { body: { paddingLeft: '0px', paddingRight: '0px' } } },
        React.createElement(Divider, { my: 0 }),
        React.createElement(ItemOption, { isChecked: isString, onClick: function () {
                var _a;
                setValue('');
                (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } },
            React.createElement(TextInput, __assign({}, (isString && { 'data-autofocus': true }), { ref: textInputRef, mt: 0, placeholder: t(i18nKeys.SETTINGS.INVOICING.FOOTER.ENTER_TEXT), value: isString ? value : '', onChange: function (event) { return setValue(event.currentTarget.value); } }))),
        React.createElement(Divider, { my: 0 }),
        React.createElement(ItemOption, { isChecked: isDynamicField, onClick: function () {
                var _a;
                setValue(
                // If deselecting then reselecting this option, attmept to use the initial value of the field
                // otherwise, pick the first option to fill the select.
                isDynamicFooterField(item)
                    ? item
                    : {
                        field: invoiceFooter === null || invoiceFooter === void 0 ? void 0 : invoiceFooter.availableFields[0].field,
                        content: invoiceFooter === null || invoiceFooter === void 0 ? void 0 : invoiceFooter.availableFields[0].content,
                    });
                (_a = selectInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } },
            React.createElement(Select, __assign({}, (isDynamicField && { 'data-autofocus': true }), { placeholder: t(i18nKeys.SETTINGS.INVOICING.FOOTER.SELECT_FIELD), allowDeselect: false, value: isDynamicField ? value.field : null, onChange: function (val) {
                    return setValue(invoiceFooter.availableFields.find(function (el) { return el.field === val; }));
                }, data: selectOptions }))),
        React.createElement(Divider, { my: 0 }),
        React.createElement(ItemOption, { isChecked: isNull, onClick: function () { return setValue(null); } },
            React.createElement(Text, { fz: "16px", lh: "16px", fw: 400, c: "gray.7" }, t(i18nKeys.SETTINGS.INVOICING.FOOTER.EMPTY))),
        React.createElement(Divider, { my: 0 }),
        React.createElement(ModalFooter, { isDisabled: (isString && value === '') || !isDirty, onCancel: onClose, onSave: function () { return onSave(value); }, withOwnPadding: true })));
};
