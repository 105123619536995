import { __assign, __awaiter, __generator, __read, __rest, __spreadArray } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AVAILABLE_CURRENCIES } from 'shared';
import EditClient from 'shared/action-component/EditClient';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, CustomSelect, DateSelector, Input, MaskInput } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { useForceUpdate, useSearchDebtors } from 'shared/utils/hooks';
import { currencySymbol } from 'shared/utils/normalization';
import { getCgv, useGetCompany } from 'shared/utils/selectors';
import { formActions } from 'store/form/form.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { settingsActions } from 'store/settings/settings.actions';
import styleIdentifiers from './ImportPDFInvoice.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImportPdfInvoice() {
    var _this = this;
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var history = useHistory();
    var forceUpdate = useForceUpdate();
    var node = useRef(null);
    var company = useGetCompany();
    var files = useSelector(function (state) { return state.form.pdfFiles; }) || [];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useState(0), 2), currentFile = _f[0], _setCurrentFile = _f[1];
    var _g = __read(useState(''), 2), fileUrl = _g[0], setFileUrl = _g[1];
    var _h = __read(useState([]), 2), formValues = _h[0], setFormValues = _h[1];
    var _j = __read(useState([]), 2), recoveryPlans = _j[0], setRecoveryPlans = _j[1];
    var _k = __read(useState({
        pageNumber: 1,
        numberOfPages: 1,
    }), 2), pdfMetadata = _k[0], setPdfMetadata = _k[1];
    var isFrenchClient = company.address_attributes.country_code === 'FR';
    var _l = useSearchDebtors(), debtors = _l.debtors, getDebtors = _l.getDebtors;
    var form = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        defaultValues: {
            invoice_params: {
                already_paid: '',
                currency: company.currency,
                debtor_id: '',
                due_date: '',
                issue_date: '',
                recovery_plan_id: 'auto',
                reference: '',
                structured_communication: '+++___/____/_____+++',
                total_tvac: '',
            },
        },
    });
    var register = form.register, getValues = form.getValues, control = form.control, reset = form.reset, setValue = form.setValue, watch = form.watch, errors = form.formState.errors, trigger = form.trigger, setError = form.setError;
    var pageNumber = pdfMetadata.pageNumber, numberOfPages = pdfMetadata.numberOfPages;
    var issueDate = watch('invoice_params.issue_date');
    var totalInclVat = watch('invoice_params.total_tvac');
    var amountAlreadyPaid = watch('invoice_params.already_paid');
    var debtorId = watch('invoice_params.debtor_id');
    var currency = watch('invoice_params.currency');
    var structuredComm = watch('invoice_params.structured_communication');
    var setPdfView = function (newFileUrl) {
        if (fileUrl) {
            var url = fileUrl;
            URL.revokeObjectURL(url);
        }
        if (newFileUrl) {
            setFileUrl(URL.createObjectURL(newFileUrl));
        }
        else {
            setFileUrl('');
        }
    };
    var setCurrentFile = function (nextCurrentFile, formValuesToEdit) {
        var _a, _b;
        if (nextCurrentFile === currentFile) {
            setPdfView(files[currentFile] && files[currentFile]);
        }
        if (formValuesToEdit) {
            setFormValues(formValuesToEdit);
        }
        else {
            var newFormValues_1 = formValues;
            newFormValues_1[currentFile] = getValues();
            setFormValues(newFormValues_1);
        }
        _setCurrentFile(nextCurrentFile);
        var newFormValues = { invoice_params: { structured_communication: '' } };
        if ((_a = formValues[nextCurrentFile]) === null || _a === void 0 ? void 0 : _a.invoice_params) {
            newFormValues = {
                invoice_params: __assign(__assign({}, formValues[nextCurrentFile].invoice_params), { structured_communication: ((_b = formValues[nextCurrentFile].invoice_params) === null || _b === void 0 ? void 0 : _b.structured_communication) || '' }),
            };
        }
        reset(newFormValues);
    };
    var companyCgv = getCgv(company);
    var setDueDate = function (value) {
        setValue('invoice_params.due_date', moment.utc(value).toISOString());
    };
    var setIssueDate = function (newIssueDate) {
        var date = moment.utc(newIssueDate);
        setValue('invoice_params.issue_date', date.toISOString());
        setDueDate(date.add(companyCgv === null || companyCgv === void 0 ? void 0 : companyCgv.minimum_payment_terms_delay, 'days').toISOString());
    };
    useEffect(function () {
        return function () {
            setPdfView();
            formActions.changeValue({ key: 'pdfFiles', value: [] });
        };
    }, []);
    useEffect(function () {
        if (files.length !== 0) {
            setPdfView(files[0]);
        }
        else {
            history.push('/invoices/listing');
        }
    }, [files.length]);
    useEffect(function () {
        setPdfView(files[currentFile] && files[currentFile]);
    }, [files, currentFile]);
    var deleteCurrentInvoice = function () {
        var nextCurrentFile = currentFile !== 0 && files.length - 1 === currentFile ? currentFile - 1 : currentFile;
        var newFiles = files;
        newFiles.splice(currentFile, 1);
        formActions.changeValue({ key: 'pdfFiles', value: newFiles });
        var newFormValues = formValues;
        newFormValues.splice(currentFile, 1);
        if (newFiles.length > 0) {
            setCurrentFile(nextCurrentFile, newFormValues);
        }
        else {
            forceUpdate();
        }
    };
    var importInvoice = function () { return __awaiter(_this, void 0, void 0, function () {
        var isValid, currentValues, data_1, key, formData_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (isValid) {
                        currentValues = getValues();
                        data_1 = {};
                        for (key in currentValues.invoice_params) {
                            data_1["invoice_params.".concat(key)] = currentValues.invoice_params[key];
                        }
                        if (typeof data_1['invoice_params.debtor_id'] === 'object') {
                            data_1['invoice_params.debtor_id'] = data_1['invoice_params.debtor_id'].id;
                        }
                        if (data_1['invoice_params.structured_communication']) {
                            data_1['invoice_params.structured_communication'] = data_1['invoice_params.structured_communication'].replace(/[\+\/\_]/g, '');
                        }
                        formData_1 = new FormData();
                        formData_1.append('file', files[currentFile]);
                        Object.keys(data_1).forEach(function (el) {
                            formData_1.append("".concat(el.replace('.', '['), "]"), data_1[el]);
                        });
                        setIsLoading(true);
                        invoiceActions.createInvoicePdf({
                            data: formData_1,
                            callback: function () {
                                setIsLoading(false);
                                deleteCurrentInvoice();
                                form.reset();
                            },
                        });
                    }
                    else if (!debtorId) {
                        setError('invoice_params.debtor_id', { type: 'required' });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onDocumentLoadSuccess = function (pdf) {
        setPdfMetadata({
            pageNumber: 1,
            numberOfPages: pdf._pdfInfo.numPages,
        });
    };
    var addClient = function () {
        EditClient({
            callback: function (newDebtor) {
                setValue('invoice_params.debtor_id', newDebtor);
            },
        });
    };
    var getRecoveryPlans = function () {
        settingsActions.recoveryPlanList({
            noLoading: true,
            callback: function (_a) {
                var data = _a.data;
                setRecoveryPlans(data.map(function (recoveryPlan) { return treatRecoveryPlan(recoveryPlan); }));
            },
        });
    };
    var currentDebtor = debtors.find(function (e) { return Number(e.id) === Number(debtorId); });
    useEffect(function () {
        if (currentDebtor === null || currentDebtor === void 0 ? void 0 : currentDebtor.currency) {
            setValue('invoice_params.currency', currentDebtor.currency);
        }
    }, [currentDebtor]);
    var frenchStructuredCommError = (function () {
        if (!isFrenchClient)
            return undefined;
        if (structuredComm == null || structuredComm.length === 0)
            return undefined;
        if (!structuredComm.startsWith('RF'))
            return { type: 'test', message: 'ERROR.START_RF' };
        if (structuredComm.replace(' ', '').length < 5)
            return { type: 'test', message: 'ERROR.MIN_5' };
        if (structuredComm.replace(' ', '').length > 25)
            return { type: 'test', message: 'ERROR.MAX_25' };
        return undefined;
    })();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('import-PDF-invoice'), ref: node },
            React.createElement("div", { className: styles('header') },
                React.createElement("h1", null, t(i18nKeys.INVOICING.IMPORT.PDF_INVOICES)),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: deleteCurrentInvoice, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE, noMargin: true, label: t(i18nKeys.DELETE) }),
                    React.createElement(Button, { onClick: importInvoice, color: ButtonColor.MAIN, iconLeft: IconName.CHECK, noMargin: true, isLoading: isLoading, label: t(i18nKeys.IMPORT), disabled: frenchStructuredCommError != null }))),
            React.createElement("div", { className: styles('body') },
                React.createElement("div", { className: styles('pdf-actions') },
                    numberOfPages > 1 && (React.createElement("div", { className: styles('container-actions') },
                        pageNumber > 1 && (React.createElement(Icon, { name: IconName.MINIMAL_UP, onClick: function () {
                                setPdfMetadata(__assign(__assign({}, pdfMetadata), { pageNumber: pageNumber - 1 }));
                            } })),
                        numberOfPages > pageNumber && (React.createElement(Icon, { name: IconName.MINIMAL_DOWN, onClick: function () {
                                setPdfMetadata(__assign(__assign({}, pdfMetadata), { pageNumber: pageNumber + 1 }));
                            } })))),
                    React.createElement(Document, { file: fileUrl, onLoadSuccess: onDocumentLoadSuccess },
                        React.createElement(Page, { renderAnnotationLayer: false, renderTextLayer: false, width: 540, pageNumber: pageNumber || 1 }))),
                React.createElement(FormProvider, __assign({}, form),
                    React.createElement("form", { className: styles('fill-data-container') },
                        React.createElement("div", { className: styles('title') },
                            React.createElement("div", null, currentFile - 1 >= 0 && (React.createElement(Icon, { name: IconName.CIRCLE_LEFT, size: "30px", onClick: function () { return setCurrentFile(currentFile - 1); } }))),
                            React.createElement("h2", null,
                                t(i18nKeys.COMMON.INVOICE),
                                " ",
                                currentFile + 1,
                                "/",
                                files.length),
                            React.createElement("div", null, currentFile + 1 < files.length && (React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "30px", onClick: function () { return setCurrentFile(currentFile + 1); } })))),
                        React.createElement("div", { className: styles('input-button') },
                            React.createElement(Controller, { defaultValue: "", name: "invoice_params.debtor_id", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { noMargin: true, className: styles('invoice-select'), errorClassName: styles('invoice-select-error'), noArrow: true, filter: true, load: getDebtors, items: debtors, keyText: "full_name", keyValue: "id", name: "invoice_params.debtor_id", placeholder: t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT), placeholderFilter: t(i18nKeys.FORM.RESEARCH) })); } }),
                            React.createElement(Button, { className: styles('add-client'), label: "+", onClick: addClient, noMargin: true })),
                        React.createElement(Controller, { defaultValue: "auto", name: "invoice_params.recovery_plan_id", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { className: styles('invoice-select'), errorClassName: styles('invoice-select-error'), noArrow: true, filter: true, load: getRecoveryPlans, items: __spreadArray([{ name: t(i18nKeys.AUTOMATIC), id: 'auto' }], __read(recoveryPlans), false), keyText: "name", keyValue: "id", name: "invoice_params.recovery_plan_id", placeholder: t(i18nKeys.RECOVERY_PLAN), placeholderFilter: t(i18nKeys.FORM.RESEARCH) })); } }),
                        React.createElement(Input, { register: register('invoice_params.reference', {
                                required: true,
                            }), placeholder: "INV-123", 
                            // @ts-ignore incorrectly typed
                            errorMessage: ((_a = errors.invoice_params) === null || _a === void 0 ? void 0 : _a.reference) && 'ERROR.FIELD_REQUIRED', label: t(i18nKeys.FORM.INVOICE_REFERENCE) }),
                        React.createElement("div", { className: styles('oneline') },
                            React.createElement(Controller, { control: control, defaultValue: "", rules: { required: true }, name: "invoice_params.issue_date", render: function () { return (React.createElement(DateSelector, { name: "invoice_params.issue_date", className: styles('input'), noMinDate: true, label: t(i18nKeys.ISSUE_DATE), handleChange: setIssueDate })); } }),
                            React.createElement(Controller, { control: control, defaultValue: "", rules: { required: true }, name: "invoice_params.due_date", render: function () { return (React.createElement(DateSelector, { className: styles('input'), label: t(i18nKeys.DUE_DATE), minDate: issueDate, handleChange: setDueDate, name: "invoice_params.due_date" })); } })),
                        React.createElement(Controller, { defaultValue: company === null || company === void 0 ? void 0 : company.currency, rules: { required: true }, name: "invoice_params.currency", render: function () { return (React.createElement(CustomSelect, { name: "invoice_params.currency", items: AVAILABLE_CURRENCIES, keyText: "description", keyValue: "value", label: t(i18nKeys.FORM.CLIENT.CURRENCY) })); } }),
                        React.createElement(Input, { register: register('invoice_params.total_tvac', {
                                required: true,
                            }), 
                            // @ts-ignore incorrectly typed
                            errorMessage: ((_b = errors.invoice_params) === null || _b === void 0 ? void 0 : _b.total_tvac) && 'ERROR.FIELD_REQUIRED', type: "number", label: t(i18nKeys.INVOICING.TOTAL_VAT_PRICE_CURRENCY, {
                                text: currencySymbol(currency, company),
                            }) }),
                        isFrenchClient ? (React.createElement(Input, { register: register('invoice_params.structured_communication'), errorMessage: frenchStructuredCommError, placeholder: "RF__ ____ ____ ____ ____ ____ _", label: t(i18nKeys.FORM.INVOICING.STRUCTURED_COMMUNICATION) })) : (React.createElement(Controller, { control: control, defaultValue: "", name: "invoice_params.structured_communication", rules: {
                                validate: function (val) {
                                    return (val === null || val === void 0 ? void 0 : val.indexOf('_')) > -1 && val !== '+++___/____/_____+++'
                                        ? 'ERROR.NOT_FULLY_FILLED'
                                        : true;
                                },
                            }, render: function (_a) {
                                var _b;
                                var _c = _a.field, ref = _c.ref, values = __rest(_c, ["ref"]);
                                return (React.createElement(MaskInput, __assign({}, values, { name: "invoice_params.structured_communication", error: (_b = errors.invoice_params) === null || _b === void 0 ? void 0 : _b.structured_communication, className: styles('cash_account_input'), label: t(i18nKeys.FORM.INVOICING.STRUCTURED_COMMUNICATION), guide: true, numberOnly: true, mask: "+++___/____/_____+++" })));
                            } })),
                        React.createElement(Input, { register: register('invoice_params.already_paid', {
                                max: totalInclVat,
                            }), 
                            // @ts-ignore incorrectly typed
                            errorMessage: (_d = (((_c = errors.invoice_params) === null || _c === void 0 ? void 0 : _c.already_paid) &&
                                (Number(amountAlreadyPaid) > Number(totalInclVat)
                                    ? 'INVOICING.IMPORT.MUST_BE_LOWER_EQUAL'
                                    : 'ERROR.FIELD_REQUIRED'))) !== null && _d !== void 0 ? _d : undefined, type: "number", label: t(i18nKeys.INVOICING.IMPORT.AMOUNT_ALREADY_PAID_CURRENCY, {
                                text: currencySymbol(currency, company),
                            }) }),
                        React.createElement("div", { className: styles('total') },
                            t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED),
                            " :",
                            ' ',
                            React.createElement(Amount, { suffix: currency, value: totalInclVat ? Number(totalInclVat) - Number(amountAlreadyPaid) : 0 }))))))));
}
