import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import { formatBankAccount } from 'shared/utils/normalization';
import styleIdentifiers from './BankAccountForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankAccountForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = useForm({ shouldUnregister: true }), register = _b.register, setValue = _b.setValue, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var onSetBankAccount = function (value) {
        setValue('iban', formatBankAccount(value.target.value));
    };
    return (React.createElement("form", { className: styles('bank-account-form'), onSubmit: handleSubmit(onSubmit) },
        React.createElement(Input, { errorMessage: errors.iban, onChange: onSetBankAccount, register: register('iban', { required: true }), type: "text", label: "IBAN" }),
        React.createElement(Input, { register: register('bic'), type: "text", label: "BIC (".concat(t(i18nKeys.OPTIONAL), ")") }),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { label: t(i18nKeys.FORM.VALIDATE), type: "submit" }))));
}
