import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { DateSelector } from 'shared/io';
import { getDefaultDueDate } from '../InvoiceForm.utils';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var DueDateField = function (_a) {
    var company = _a.company;
    var setValue = useFormContext().setValue;
    var defaultDate = getDefaultDueDate(company);
    var minDate = dayjs();
    return (React.createElement(Controller, { name: "due_date", rules: { required: true }, defaultValue: defaultDate.toDate(), render: function () { return (React.createElement(DateSelector, { name: "due_date", placeholder: defaultDate.format('DD/MM/YYYY'), className: styles('date-selector'), inputClassName: styles('value'), errorClassName: styles('error'), noErrorText: true, noMargin: true, noBorder: true, minDate: minDate, handleChange: function (value) {
                setValue('due_date', value);
            } })); } }));
};
