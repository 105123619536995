import React from 'react';
import { useGetDebtor } from 'api';
import classNames from 'classnames/bind';
import { useParams } from 'react-router';
import { Box, LoadingOverlay } from '@mantine/core';
import { ClientDetailHeader } from './ClientDetailHeader';
import { ClientDetailTabs } from './ClientDetailTabs';
import styleIdentifiers from './ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetail = function () {
    var debtorId = useParams().id;
    var _a = useGetDebtor(debtorId), debtorDetailsResponse = _a.data, isLoading = _a.isLoading, isError = _a.isError, refetch = _a.refetch;
    if (isLoading && !debtorDetailsResponse) {
        return (React.createElement(Box, { h: "100%", pos: "relative" },
            React.createElement(LoadingOverlay, { visible: true })));
    }
    if (isError || !debtorDetailsResponse) {
        return React.createElement("span", null, "Error");
    }
    var debtor = debtorDetailsResponse.data;
    return (React.createElement("div", { className: styles('client-detail') },
        React.createElement(ClientDetailHeader, { debtor: debtor, refetch: function () { return refetch(); } }),
        React.createElement(ClientDetailTabs, { debtor: debtor, refetch: function () { return refetch(); } })));
};
