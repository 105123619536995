import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { clone, mergeWith, subtract } from 'lodash-es';
import { useHistory } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { useAgedBalanceColors } from 'shared/hooks';
import { formatAmount } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Badge, Box, Button, useMantineTheme } from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';
import { animated } from '@react-spring/web';
import { DetailedBalance } from '../DetailedBalance';
import styleIdentifiers from './outstandingGraphic.scss';
var styles = classNames.bind(styleIdentifiers);
export function OutstandingGraphic(_a) {
    var agedBalanceCompact = _a.agedBalanceCompact, accountManagerId = _a.accountManagerId;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var colors = useAgedBalanceColors();
    var push = useHistory().push;
    var netBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var overdueAmount = netBalance['0'] + netBalance['30'] + netBalance['60'] + netBalance['90'];
    var notDueAmount = netBalance.notDue;
    var totalEngaged = overdueAmount + notDueAmount;
    var showBalanceDetailed = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.DASHBOARD.BALANCE_DETAIL),
            children: React.createElement(DetailedBalance, { accountManagerId: accountManagerId }),
        });
    };
    var pieData = [
        {
            id: 'overdue',
            label: t(i18nKeys.ANALYTICS.OVERDUE),
            value: overdueAmount,
            color: colors.totalDue,
            mantineColor: 'rgb(40, 40, 40, 0.8)',
            query: '/invoices/listing?page=1&status=late_unpaid,not_lost',
        },
        {
            id: 'not_due_yet',
            label: t(i18nKeys.ANALYTICS.NOT_DUE_YET),
            value: notDueAmount,
            color: colors.notDue,
            mantineColor: 'rgb(40, 40, 40, 0.8)',
            query: '/invoices/listing?status=unpaid,not_lost&max_days_late=0',
        },
    ];
    return (React.createElement(Card, { className: styles('OutstandingGraphic'), title: t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE), infosRight: React.createElement(Button, { variant: "light", color: "blue", size: "compact-sm", onClick: showBalanceDetailed }, t(i18nKeys.INVOICING.SEE_DETAIL)) },
        React.createElement("div", { className: styles('total') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TOTAL_COMMITTED)),
            React.createElement("div", { className: styles('amount') },
                React.createElement(Box, { c: "dark.5" },
                    React.createElement(Amount, { value: totalEngaged })))),
        React.createElement(Box, { w: "100%", h: "230px" },
            React.createElement(ResponsivePie, { onClick: function (d) { return push(d.data.query); }, onMouseEnter: function (_datum, event) {
                    event.currentTarget.style.cursor = 'pointer';
                }, tooltip: function () { return null; }, margin: { top: 10, bottom: 30 }, data: pieData, innerRadius: 0.5, padAngle: 0.7, cornerRadius: 4, colors: { datum: 'data.color' }, enableArcLinkLabels: false, arcLabelsComponent: function (_a) {
                    var datum = _a.datum, label = _a.label, style = _a.style;
                    return (React.createElement(animated.g, { transform: style.transform },
                        React.createElement("foreignObject", { width: "100", height: "25", overflow: "visible", pointerEvents: "none" },
                            React.createElement(Badge, { p: "xs", style: {
                                    transform: 'translateX(-50%) translateY(-15px)',
                                }, radius: "sm", size: "md", color: datum.data.mantineColor, variant: "filled", fw: 500 }, formatAmount(label, '.', ' ')))));
                }, legends: [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateX: 15,
                        translateY: 30,
                        itemWidth: 110,
                        itemHeight: 20,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        itemTextColor: theme.colors.gray[6],
                    },
                ] }))));
}
