import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import { ModelType } from 'app/Private/CustomVariables/CustomVariables.types';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { isEmpty } from 'remeda';
import { ExportCsv } from 'shared/components/ExportCsv';
import { useGetCompany, useGetConstants } from 'shared/utils/selectors';
import { appActions } from 'store/app/app.actions';
import { backgroundLoaderHide } from 'store/view/view.actions';
export var ExportDebtorCsv = function (_a) {
    var _b;
    var _c = _a.overrideDefaultSelection, overrideDefaultSelection = _c === void 0 ? [] : _c;
    var t = useTranslation().t;
    var constants = useGetConstants();
    var customVariables = ((_b = useGetCompany()) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes) || [];
    var filteredCustomVariables = customVariables
        .filter(function (customVar) { return customVar.model_type === ModelType.DEBTOR; })
        .map(function (customVar) { return ({
        description: customVar.name,
        value: customVar.column_name,
    }); });
    var _d = __read(useState({
        exportFields: constants.debtor_export_columns.debtor_fields.concat(constants.debtor_export_columns.debtor_financial_fields, filteredCustomVariables),
        wrappers: [
            {
                name: 'selectDebtorInfo',
                fields: constants.debtor_export_columns.debtor_fields,
                text: t(i18nKeys.COMMON.CLIENT),
            },
            {
                name: 'selectFinancialdata',
                fields: constants.debtor_export_columns.debtor_financial_fields,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.FINANCIAL_DATA),
            },
            {
                name: 'selectCustomVariable',
                fields: filteredCustomVariables,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.CUSTOM_DATA),
            },
        ],
    }), 1), _e = _d[0], exportFields = _e.exportFields, wrappers = _e.wrappers;
    var downloadCsv = function (data) {
        appActions.export({
            data: __assign(__assign(__assign({}, data), (data.export_with_filters === true ? qs.parse(location.search) : {})), { view_id: isEmpty(location.hash.slice(1)) ? undefined : location.hash.slice(1), resource_type: 'debtor', type: 'csv' }),
            actionFailure: function () {
                backgroundLoaderHide();
            },
            callback: function () {
                backgroundLoaderHide();
            },
        });
    };
    return (React.createElement(ExportCsv, { downloadCsv: downloadCsv, allFields: exportFields, wrappers: wrappers, shouldHideFilterOption: !isEmpty(overrideDefaultSelection), selectAllLabel: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_DEBTOR), selectFilteredLabel: t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_DEBTOR'), overrideDefaultSelection: overrideDefaultSelection }));
};
