import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from 'shared/components/Icon';
import Logo from 'shared/components/Logo';
import styleIdentifiers from './onboardingItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function OnboardingItem(_a) {
    var title = _a.title, className = _a.className, description = _a.description, icon = _a.icon, onClick = _a.onClick, paddingBottom = _a.paddingBottom, logo = _a.logo;
    return (React.createElement("div", { className: styles('OnboardingItem') },
        React.createElement("div", { className: styles('wrapper', className, 'actionable'), onClick: onClick },
            React.createElement("div", { className: styles('content') },
                icon && React.createElement(Icon, { name: icon, className: styles('dark'), size: "25px" }),
                logo && React.createElement(Logo, { className: styles('logo'), type: "small-dark", alt: "icon" }),
                React.createElement("span", { className: styles('title') }, title),
                description && (React.createElement("div", { className: styles('description', paddingBottom && 'padding-bottom') }, description))))));
}
