import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import EmailRendering from 'shared/components/EmailRendering/EmailRendering';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';
import { removeAttributes } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import styleIdentifiers from './formalNoticeForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function FormalNoticeForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, debtorEmails = _a.debtorEmails, onChangeTemplate = _a.onChangeTemplate, setPreview = _a.setPreview, customText = _a.customText, batch = _a.batch, addresses = _a.addresses, refetchSendingOptions = _a.refetchSendingOptions, debtorId = _a.debtorId;
    var t = useTranslation().t;
    var isLoading = useSelector(function (state) { return state.invoice.isLoading; });
    var _b = __read(useState([]), 2), formalNoticeTemplates = _b[0], setFormalNoticeTemplates = _b[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, watch = form.watch, handleSubmit = form.handleSubmit;
    useEffect(function () {
        settingsActions.templateListPage({
            step_type: 'formal_notice',
            callback: function (_a) {
                var data = _a.data;
                setFormalNoticeTemplates(data.map(function (item) { return removeAttributes(item); }));
            },
        });
    }, []);
    useEffect(function () {
        setPreview === null || setPreview === void 0 ? void 0 : setPreview({ email: sendEmail });
    }, [setPreview == null]);
    var sendEmail = watch('send_email');
    var groupedTypes = [
        {
            description: t(i18nKeys.INVOICE.SELECTED_ONLY),
            value: '',
        },
        {
            description: t(i18nKeys.INVOICE.IN_PROGRESS),
            value: 'unpaid_invoices',
        },
        {
            description: t(i18nKeys.INVOICE.LATE_ONLY),
            value: 'overdue_invoices',
        },
        {
            description: t(i18nKeys.CLIENT_BALANCE),
            value: 'debtor_balance',
        },
    ];
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('FormalNoticeForm', batch && 'batch'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", null,
                React.createElement("div", { className: styles('subtitle') }, customText || t(i18nKeys.FORM.FORMAL_NOTICE.CAN_EDIT)),
                React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: "template_id", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.FN_TEMPLATE), keyText: "name", keyValue: "id", name: "template_id", onValueChanged: onChangeTemplate, items: formalNoticeTemplates })); } }),
                batch && (React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", name: "grouped_type", items: groupedTypes, label: t(i18nKeys.STATEMENT) })); } })),
                React.createElement(Checkbox, { register: register('send_email'), watch: watch, label: t(i18nKeys.FORM.FORMAL_NOTICE.SEND_BY_MAIL_TOO), onChange: function () { return setPreview === null || setPreview === void 0 ? void 0 : setPreview({ email: !sendEmail }); } }),
                !batch && refetchSendingOptions && (React.createElement(AddContactPersonButton, { refetch: function () { return refetchSendingOptions(); }, debtorId: debtorId, withMargin: true })),
                debtorEmails && sendEmail && (React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "emails", render: function () { return (React.createElement(CustomSelect, { className: styles('send-copy-email'), multiple: true, canAdd: true, items: debtorEmails, label: t(i18nKeys.FORM.FORMAL_NOTICE.EMAIL_TO_SEND), name: "emails", inputValidation: email, itemRendering: function (_a) {
                            var value = _a.value;
                            return React.createElement(EmailRendering, { data: value });
                        }, registerMultipleInput: register('emailsinput') })); } })),
                !batch && React.createElement(AddressPartialForm, { addresses: addresses })),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.SUBMIT), type: "submit", disabled: !batch && watch('addresses_attributes').length === 0 })))));
}
