import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
var ExternalMailSchema = z.object({
    body: z.string(),
    original_mail_id: z.string(),
    subject: z.string(),
    to_mail: z.array(z.string()),
});
export function sendExternalMail(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('external_mails', ExternalMailSchema.parse(data))];
            }
        });
    });
}
export function useSendExternalMail() {
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return sendExternalMail(axiosInstance, data); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
        },
        onError: function (error) {
            var _a, _b, _c, _d;
            notifications.show({
                message: (_d = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message) !== null && _b !== void 0 ? _b : (_c = error.response) === null || _c === void 0 ? void 0 : _c.data.errors[0]) !== null && _d !== void 0 ? _d : error.message,
                color: 'red',
            });
        },
    });
    return addActionNameToMutationResult('sendExternalMail', mutation);
}
