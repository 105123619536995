import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { accountActions } from 'store/account/account.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { IntegrationFormFieldsName, INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import styleIdentifiers from '../Integration.scss';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { DBasicsForm, DBasicsFormFieldsName } from './DBasicsForm';
var styles = classNames.bind(styleIdentifiers);
export var DBasics = function (_a) {
    var _b;
    var _c = _a.history, goBack = _c.goBack, replace = _c.replace;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _d = getIntegrationRights(Integration.DBASICS, company), isActive = _d.isActive, canActivate = _d.canActivate, isConnected = _d.isConnected;
    useEffect(function () {
        if (!canActivate) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var handleSubmit = function (data) {
        accountActions.updateDbasicsSettings({
            data: data,
            callback: INTEGRATION_UPDATE_CALLBACK,
        });
    };
    var _e = company.tp_accounting, use_import = _e.use_import, debtor_update = _e.debtor_update, issue_date_limit = _e.issue_date_limit, automatic_sync = _e.automatic_sync, use_payment_matching = _e.use_payment_matching;
    return (React.createElement(React.Fragment, null,
        React.createElement(IntegrationHeader, { name: Integration.DBASICS, goBack: goBack, isActive: isActive, isConnected: isConnected }),
        React.createElement(DBasicsForm, { onSubmit: handleSubmit, initialValues: (_b = {},
                _b[DBasicsFormFieldsName.IMPORT_INTEGRATION] = use_import,
                _b[DBasicsFormFieldsName.PAYMENT_MATCHING] = use_payment_matching,
                _b[IntegrationFormFieldsName.ISSUE_DATE_LIMIT] = issue_date_limit,
                _b[IntegrationFormFieldsName.DEBTOR_UPDATE] = debtor_update,
                _b[IntegrationFormFieldsName.AUTOMATIC_SYNC] = automatic_sync,
                _b) })));
};
