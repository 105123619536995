import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Input } from 'shared/io';
import { WorkflowStepsTarget } from '../Workflows.constants';
import { WorkflowFormSteps } from './Steps/WorkflowFormSteps';
import { getDefaultValues, getWorkflowRights } from './WorkflowForm.utils';
import styleIdentifiers from './WorkflowForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowForm = function (_a) {
    var _b, _c;
    var planType = _a.planType, onSubmit = _a.onSubmit, onDuplicate = _a.onDuplicate, onDelete = _a.onDelete, workflow = _a.workflow, children = _a.children, isLoading = _a.isLoading;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _d = getWorkflowRights(company, planType), canAdd = _d.canAdd, canEdit = _d.canEdit, canDuplicate = _d.canDuplicate, canDelete = _d.canDelete;
    var form = useForm({
        defaultValues: getDefaultValues(planType, workflow),
    });
    var handleSubmit = form.handleSubmit, register = form.register, _e = form.formState, errors = _e.errors, isDirty = _e.isDirty, isSubmitted = _e.isSubmitted, reset = form.reset;
    useEffect(function () {
        reset(getDefaultValues(planType, workflow));
    }, [planType, workflow, reset]);
    var isDefault = (_b = workflow === null || workflow === void 0 ? void 0 : workflow.attributes) === null || _b === void 0 ? void 0 : _b.default;
    var isUsed = !!((_c = workflow === null || workflow === void 0 ? void 0 : workflow.attributes) === null || _c === void 0 ? void 0 : _c.payment_plans_using);
    var getCanDeleteTitle = function () {
        if (!canDelete) {
            return i18nKeys.NOT_INCLUDED;
        }
        if (isDefault) {
            return i18nKeys.USED_DEFAULT;
        }
        if (isUsed) {
            return i18nKeys.IS_USED;
        }
        return i18nKeys.DELETE;
    };
    var getCanDuplicate = function () {
        if (!canDuplicate) {
            return i18nKeys.NOT_INCLUDED;
        }
        return i18nKeys.DUPLICATE;
    };
    return (React.createElement("div", { className: styles('workflows-form') },
        React.createElement(FormProvider, __assign({}, form),
            React.createElement(NavigationPrompt, { when: isDirty && !isSubmitted, onSaveAndQuit: handleSubmit(onSubmit) }),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement("header", { className: styles('head') },
                    React.createElement("h2", null, t(workflow
                        ? i18nKeys.WORKFLOW.EDIT[planType.toUpperCase()]
                        : i18nKeys.WORKFLOW.ADD[planType.toUpperCase()])),
                    React.createElement("div", { className: styles('actions') },
                        children,
                        onDelete && (React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.DELETE), color: ButtonColor.GREY, onClick: onDelete, disabled: !canDelete || isDefault || isUsed, title: t(getCanDeleteTitle()), noMargin: true })),
                        onDuplicate && (React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.DUPLICATE), color: ButtonColor.BLUE, onClick: onDuplicate, disabled: !canDuplicate || isDirty, title: getCanDuplicate(), noMargin: true })),
                        React.createElement(Button, { isLoading: isLoading, type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.MAIN, disabled: workflow ? !canEdit : !canAdd, title: workflow
                                ? t(!canEdit ? i18nKeys.NOT_INCLUDED : i18nKeys.SAVE)
                                : t(!canAdd ? i18nKeys.NOT_INCLUDED : i18nKeys.SAVE), noMargin: true }))),
                React.createElement("div", { className: styles('container', 'card') },
                    React.createElement("div", { className: styles('head') },
                        React.createElement("div", { className: styles('title') },
                            React.createElement("button", { type: "button", className: styles('previous'), onClick: function () {
                                    history.push("/settings/workflows/".concat(planType));
                                } },
                                React.createElement(Icon, { color: IconColor.GREY, size: "18px", name: IconName.MINIMAL_LEFT })),
                            React.createElement(Input, { register: register('name', {
                                    required: true,
                                }), errorMessage: get(errors, 'name'), className: styles('input'), placeholder: t(i18nKeys.PLAN.NAME), disabled: workflow ? !canEdit : !canAdd, noMargin: true })),
                        workflow && (React.createElement("div", { className: styles('info') },
                            isDefault && React.createElement("div", { className: styles('label') }, t(i18nKeys.DEFAULT)),
                            React.createElement("div", null, "".concat(t(i18nKeys.FORM.REFERENCE), " : ").concat(workflow.id)),
                            React.createElement("div", null, "".concat(t(i18nKeys.USED), " : ").concat(workflow.attributes.payment_plans_using || 0))))),
                    React.createElement(WorkflowFormSteps, { planType: planType, name: WorkflowStepsTarget.BEFORE_DUE_DATE }),
                    React.createElement("h3", { className: styles('due-date') },
                        React.createElement("span", null, t(i18nKeys.DUE_DATE))),
                    React.createElement(WorkflowFormSteps, { planType: planType, name: WorkflowStepsTarget.AFTER_DUE_DATE }))))));
};
