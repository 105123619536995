import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React, { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { WorkflowFormStep } from '../Step/WorkflowFormStep';
import { getAvailableTemplates, getWorkflowRights, isAfterDueDate, isBeforeDueDate, } from '../WorkflowForm.utils';
import styleIdentifiers from './WorkflowFormSteps.scss';
import { WorkflowFormStepsButton } from './WorkflowFormStepsButton';
import { WorkflowFormStepsItem } from './WorkflowFormStepsItem';
import { WorkflowFormStepsLine } from './WorkflowFormStepsLine';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormSteps = function (_a) {
    var planType = _a.planType, name = _a.name;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = getWorkflowRights(company, planType), canAddStep = _b.canAddStep, canEditStep = _b.canEditStep;
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue;
    var _d = useFieldArray({
        name: name,
    }), fields = _d.fields, append = _d.append, remove = _d.remove, insert = _d.insert, prepend = _d.prepend;
    var edit = function (index) {
        if (!canEditStep) {
            return false;
        }
        var availableTemplates = getAvailableTemplates(planType, name, watch(name), index);
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(WorkflowFormStep, { planType: planType, templates: availableTemplates, step: watch("".concat(name, ".").concat(index)), onSubmit: function (data) {
                    setValue("".concat(name, ".").concat(index), data);
                    sideMenuHide();
                } })),
        });
    };
    var add = function (index, action) {
        if (!canAddStep) {
            return false;
        }
        var availableTemplates = getAvailableTemplates(planType, name, watch(name), index);
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(WorkflowFormStep, { planType: planType, templates: availableTemplates, onSubmit: function (data) {
                    action(data);
                    sideMenuHide();
                } })),
        });
    };
    var getButtonLabel = function (index) {
        return getAvailableTemplates(planType, name, watch(name), index).length
            ? t(i18nKeys.ADD_STEP)
            : t(i18nKeys.ADD_TASK);
    };
    return (React.createElement("section", { className: styles('workflows-form-steps') },
        fields.map(function (step, i) { return (React.createElement(Fragment, { key: step.id },
            i === 0 ? (React.createElement(WorkflowFormStepsLine, { bottom: true, top: isAfterDueDate(name) },
                React.createElement(WorkflowFormStepsButton, { title: getButtonLabel(0), onClick: function () { return add(0, function (data) { return prepend(data); }); }, disabled: !canAddStep, label: isBeforeDueDate(name) }))) : (React.createElement(WorkflowFormStepsLine, { top: true, bottom: true },
                React.createElement(WorkflowFormStepsButton, { title: getButtonLabel(i), onClick: function () { return add(i, function (data) { return insert(i, data); }); }, disabled: !canAddStep }))),
            React.createElement(WorkflowFormStepsItem, { planType: planType, name: name, index: i, edit: function () { return edit(i); }, remove: function () { return remove(i); } }),
            i === fields.length - 1 && (React.createElement(WorkflowFormStepsLine, { top: true, bottom: isBeforeDueDate(name) },
                React.createElement(WorkflowFormStepsButton, { title: getButtonLabel(i + 1), onClick: function () { return add(i + 1, function (data) { return append(data); }); }, disabled: !canAddStep, label: isAfterDueDate(name) }))))); }),
        !fields.length && (React.createElement(WorkflowFormStepsLine, { top: isAfterDueDate(name), bottom: isBeforeDueDate(name) },
            React.createElement(WorkflowFormStepsButton, { title: getButtonLabel(0), onClick: function () { return add(0, function (data) { return append(data); }); }, disabled: !canAddStep, label: true })))));
};
