import { __assign, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import DraggableList from 'react-draggable-list';
import { FormProvider, useForm } from 'react-hook-form';
import { reloadCustomView, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { Button, Group, ScrollArea, TextInput } from '@mantine/core';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './ChooseColumnModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ChooseColumnModal(_a) {
    var tableName = _a.tableName, currentView = _a.currentView, callbackAction = _a.callbackAction;
    var t = useTranslation().t;
    var _b = __read(useState([]), 2), columns = _b[0], setColumns = _b[1];
    var _c = __read(useState(''), 2), searchText = _c[0], setSearchText = _c[1];
    var form = useForm({
        defaultValues: {
            table_columns: currentView.table_columns,
            choose_columns: [],
        },
    });
    var watch = form.watch, setValue = form.setValue, reset = form.reset;
    var formData = watch();
    useEffect(function () {
        appActions.getColumns({
            data: {
                view: tableName,
            },
            callback: function (_a) {
                var data = _a.data;
                var choose_columns = [];
                var columns = data.map(function (item, index) {
                    choose_columns[index] = currentView.table_columns.some(function (tableColumn) { return tableColumn.custom_column_id.toString() === item.id; });
                    return removeAttributes(item);
                });
                setColumns(columns);
                reset({ choose_columns: choose_columns, table_columns: currentView.table_columns });
            },
        });
    }, []);
    var tableRef = useRef(null);
    var onMoveEnd = function (value) {
        setValue('table_columns', value);
    };
    var tableColumns = watch('table_columns');
    var save = function () {
        var table_columns_attributes = tableColumns.map(function (tableColumn, index) { return (__assign(__assign({}, tableColumn), { order: index + 1, id: tableColumn.type === 'table_column' ? tableColumn.id : undefined })); });
        currentView.table_columns
            .filter(function (item) { return !tableColumns.some(function (column) { return column.id.toString() === item.id; }); })
            .forEach(function (column) {
            table_columns_attributes.push(__assign(__assign({}, column), { _destroy: true }));
        });
        appActions.updateViews({
            data: {
                views_attributes: [
                    {
                        id: currentView.id,
                        view_type: currentView.view_type,
                        table_columns_attributes: table_columns_attributes,
                    },
                ],
            },
            callback: function (res) {
                appActions.getViewsRes(res, 'fulfilled', { view_type: tableName });
                dialogHide(DialogShowId.CUSTOM);
                reloadCustomView(callbackAction);
            },
        });
    };
    var onCheckboxChange = function (item, itemIndex) {
        if (!formData.choose_columns[itemIndex]) {
            tableColumns.push(__assign(__assign({}, item), { custom_column_id: item.id }));
            setValue('table_columns', tableColumns);
        }
        else {
            var index = tableColumns.findIndex(function (tableColumn) { return tableColumn.custom_column_id.toString() === item.id; });
            if (index >= 0) {
                tableColumns.splice(index, 1);
                setValue('table_columns', tableColumns);
            }
        }
        formData.choose_columns[itemIndex] = !formData.choose_columns[itemIndex];
        setValue('choose_columns', formData.choose_columns);
    };
    var removeColumn = function (item) {
        var index = tableColumns.findIndex(function (tableColumn) { return tableColumn.id.toString() === item.id; });
        var itemIndex = columns.findIndex(function (tableColumn) { return tableColumn.id.toString() === item.custom_column_id; });
        formData.choose_columns[itemIndex] = !formData.choose_columns[itemIndex];
        tableColumns.splice(index, 1);
        setValue('table_columns', tableColumns);
    };
    function DragItem(_a) {
        var item = _a.item, dragHandleProps = _a.dragHandleProps;
        return (React.createElement("div", __assign({ key: item.name, className: styles('draggable-column-item') }, dragHandleProps),
            React.createElement("div", { className: styles('name-container') },
                React.createElement(Icon, { name: IconName.MOVE_VERTICAL }),
                React.createElement("div", null, item.name_translated)),
            React.createElement("div", { className: styles('field-container') },
                React.createElement("div", null, t(i18nKeys.COLUMN_TYPE[item.column_type.toUpperCase()])),
                React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, onClick: function () { return removeColumn(item); } }))));
    }
    var filteredColumns = searchText === ''
        ? columns
        : columns.filter(function (col) {
            return col.name_translated.toLowerCase().includes(searchText.toLowerCase());
        });
    return (React.createElement("div", { className: styles('choose-column-modal') },
        React.createElement("div", { className: styles('body') },
            React.createElement("div", { className: styles('column-available') },
                React.createElement(TextInput, { pb: "sm", placeholder: t(i18nKeys.SEARCH), onChange: function (e) { return setSearchText(e.currentTarget.value); } }),
                React.createElement(FormProvider, __assign({}, form),
                    React.createElement(ScrollArea, { h: "calc(100% - 50px)" },
                        React.createElement("div", { className: styles('recovr-table') },
                            React.createElement("table", null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null,
                                            React.createElement("div", { className: styles('not-sortable-head') })),
                                        React.createElement("th", null,
                                            React.createElement("div", { className: styles('not-sortable-head') }, t(i18nKeys.COLUMN_NAME))),
                                        React.createElement("th", null,
                                            React.createElement("div", { className: styles('not-sortable-head') }, t(i18nKeys.TYPE_COLUMN))))),
                                React.createElement("tbody", null, columns.map(function (column, index) {
                                    if (!filteredColumns.some(function (col) { return col.id === column.id; }))
                                        return null;
                                    return (React.createElement("tr", { key: column.id, onClick: function () {
                                            onCheckboxChange(column, index);
                                        } },
                                        React.createElement("td", null,
                                            React.createElement("div", { className: styles('box', 'checkbox', formData.choose_columns[index] && 'checked') })),
                                        React.createElement("td", null, column.name_translated),
                                        React.createElement("td", null, t("COLUMN_TYPE.".concat(column.column_type.toUpperCase())))));
                                }))))))),
            React.createElement("div", { className: styles('displayed-column'), ref: tableRef },
                React.createElement(Group, { pb: "sm", justify: "end" },
                    React.createElement(Button, { onClick: save }, t(i18nKeys.SAVE))),
                React.createElement(ScrollArea, { h: "calc(100% - 50px)" },
                    React.createElement(DraggableList, { itemKey: "name", template: DragItem, list: tableColumns, onMoveEnd: onMoveEnd, container: function () { return tableRef.current || document.body; } }))))));
}
