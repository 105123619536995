import { __read } from "tslib";
import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Button, ButtonColor } from 'shared/io';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './Twikey.scss';
var styles = classnames.bind(styleIdentifiers);
export function Twikey(_a) {
    var onSubmit = _a.onSubmit, onboarding = _a.onboarding;
    var _b = __read(useState(''), 2), twikeyUrl = _b[0], setTwikeyUrl = _b[1];
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var onClick = function () {
        (onboarding ? onBoardingActions.twikey : settingsActions.addTwikey)({
            data: {
                locale: currentLang,
            },
            callback: function (data) {
                setTwikeyUrl(data.twikey_url);
            },
        });
    };
    var finishStep = function () {
        dialogHide(DialogShowId.CUSTOM);
        onSubmit();
    };
    return (React.createElement("div", { className: styles('twikey') }, twikeyUrl ? (React.createElement("span", null,
        t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.TO_CONTINUE),
        ' ',
        React.createElement("a", { href: twikeyUrl, onClick: finishStep, target: "_blank", rel: "noreferrer" }, t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.CLICK_ON_LINK)))) : (React.createElement("span", null,
        t(i18nKeys.ONBOARDING[1].DIRECT_DEBIT.CONFIRM),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.NO), onClick: function () { return dialogHide(DialogShowId.CUSTOM); } }),
            React.createElement(Button, { label: t(i18nKeys.YES), onClick: onClick }))))));
}
