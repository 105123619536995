import { __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { transactionActions, transactionConstants as events, } from 'store/transaction/transaction.actions';
import { sendApi } from '../sagas';
function transactionWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.paymentHistory.request, sendApi(api.transactions.payment, transactionActions.paymentHistoryRes))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.getReconciliationPayments.request, sendApi(api.transactions.payment, transactionActions.getReconciliationPaymentsRes))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.paymentHistoryPage.request, sendApi(api.transactions.payment, transactionActions.paymentHistoryPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getReconciliation.request, sendApi(api.transactions.getReconciliation, transactionActions.getReconciliationRes))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.manualReconciliation.request, sendApi(api.transactions.manualReconciliation, transactionActions.manualReconciliationRes))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.ignorePayments.request, sendApi(api.transactions.ignorePayments, transactionActions.ignorePaymentsRes))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.unignorePayments.request, sendApi(api.transactions.unignorePayments, transactionActions.unignorePaymentsRes))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.unmatchManualPayment.request, sendApi(api.transactions.unmatchManualPayment, invoiceActions.detailRes))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.unmatchExternalPayment.request, sendApi(api.transactions.unmatchExternalPayment, invoiceActions.detailRes))];
            case 9: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 10:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(transactionWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
