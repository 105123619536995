import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import styleIdentifiers from './TaskActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskActionsPostpone = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
    });
    var register = form.register, errors = form.formState.errors, handleSubmit = form.handleSubmit;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('task-action-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement(Input, { type: "number", label: t(i18nKeys.DAY), register: register('days_postponed', {
                        required: true,
                    }), errorMessage: get(errors, 'days_postponed'), noMargin: true, withBorder: true, min: "1" })),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE) })))));
};
