import { __assign } from "tslib";
import { treatActivity, treatCompany } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { clientConstants } from 'store/client/client.actions';
import { baseReducerData, baseReducerListPage, handlePageRequest, handlePageResponse, handleRequest, handleResponse, handleUpdatePaginationResponse, } from 'store/reducers';
import { settingsConstants } from 'store/settings/settings.actions';
import { invoiceConstants } from '../invoice/invoice.actions';
import { sessionConstants as session } from '../session/session.actions';
import { accountConstants as events } from './account.actions';
export var initialState = {
    activity: baseReducerListPage,
    company: baseReducerData,
    activityDetail: null,
};
var changeUnvalidateInvoices = function (state, unvalidated_invoices_count, unvalidated_debtors_count) {
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { unvalidated_invoices_count: unvalidated_invoices_count, unvalidated_debtors_count: unvalidated_debtors_count }) }) });
};
var changeUnvalidateClients = function (state, unvalidated_debtors_count) {
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { unvalidated_debtors_count: unvalidated_debtors_count }) }) });
};
var updateCustomVariablesList = function (state, custom_variables) {
    var customVariablesAttributes = custom_variables.map(function (cusVarAttributes) {
        return removeAttributes(cusVarAttributes);
    });
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { custom_variables_attributes: customVariablesAttributes }) }) });
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.getCompany.request:
            return handleRequest(state, action, 'company');
        case events.getCompany.result:
            return handleResponse(state, action, 'company', treatCompany);
        case events.updateCompany.result:
            return handleResponse(state, action, 'company', treatCompany);
        case events.activityPage.request:
            return handlePageRequest(state, action, 'activity');
        case events.activityPage.result:
            return handlePageResponse(state, action, 'activity', treatActivity);
        case events.activityDetail.request:
            return handlePageRequest(state, action, 'activityDetail');
        case events.activityDetail.result:
            return handlePageResponse(state, action, 'activityDetail', treatActivity);
        case events.activitySeen.result:
            var tmp = handleUpdatePaginationResponse(state, action, 'activity', treatActivity);
            return tmp;
        case invoiceConstants.importInvoiceCSV.result:
            return changeUnvalidateInvoices(state, action.payload.unvalidated_invoices_count, action.payload.unvalidated_debtors_count);
        case invoiceConstants.validateAllImportedInvoices.result:
            return changeUnvalidateInvoices(state, 0, action.payload.unvalidated_debtors_count);
        case invoiceConstants.deleteInvoiceToConfirm.result:
            return changeUnvalidateInvoices(state, state.company.data.unvalidated_invoices_count - 1, action.payload.unvalidated_debtors_count);
        case clientConstants.getAllToConfirm.result:
            return changeUnvalidateClients(state, action.payload.data.length);
        case clientConstants.validateAllImportedClients.result:
            return changeUnvalidateClients(state, action.payload.unvalidated_debtors_count);
        case clientConstants.deleteClientToConfirm.result:
            if (action.status === 'fulfilled')
                return changeUnvalidateClients(state, state.company.data.unvalidated_debtors_count - 1);
            return state;
        case settingsConstants.customVariableListPage.result:
            return updateCustomVariablesList(state, action.payload.data);
        case session.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
