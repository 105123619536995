import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import styleIdentifiers from './tableDetails.scss';
import TableDetailsItem from './TableDetailsItem';
var styles = classNames.bind(styleIdentifiers);
export default function TableDetails(_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, rest = __rest(_a, ["items"]);
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('TableDetails') },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: styles('th-item') }, t(i18nKeys.INVOICE.PRODUCT)),
                    React.createElement("th", { className: styles('th-qty') },
                        " ",
                        t(i18nKeys.INVOICE.QTE)),
                    React.createElement("th", { className: styles('th-price') },
                        " ",
                        t(i18nKeys.PRICE)),
                    React.createElement("th", { className: styles('th-disc') }, t(i18nKeys.INVOICE.DISCOUNT)),
                    React.createElement("th", { className: styles('th-tva-htva') }, t(i18nKeys.TOTAL_NOT_VAT_PRICE)),
                    React.createElement("th", { className: styles('th-tva') }, t(i18nKeys.VAT)),
                    React.createElement("th", { className: styles('th-total') }, t(i18nKeys.TOTAL_VAT_PRICE)))),
            React.createElement("tbody", null, items.map(function (item) { return (React.createElement(TableDetailsItem, __assign({ key: item.id }, rest, { productLine: item }))); })))));
}
