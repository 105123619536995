import { __read } from "tslib";
import React, { useEffect } from 'react';
import assert from 'assert';
import { atom, useAtom, useAtomValue } from 'jotai';
import { Redirect } from 'react-router';
import { isDefined, isNonNullish } from 'remeda';
import { useValidateTenant } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import * as Sentry from '@sentry/react';
import { LoadingScreen } from './Private/LoadingScreen';
// @ts-ignore We ignore the type error here because we inforce the invariant below; we don't
// want to spend our time doing conditional navifgation on the tenant when the app just shouldn't load as song as it's not loaded
export var tenantAtom = atom(undefined);
tenantAtom.debugLabel = 'Tenant';
export var useTenant = function () { return useAtomValue(tenantAtom); };
export var TenantValidator = function (_a) {
    var _b;
    var children = _a.children;
    var _c = useValidateTenant(apiService.tenant), tenant = _c.tenant, tenantError = _c.tenantError;
    var _d = __read(useAtom(tenantAtom), 2), tenantFromAtom = _d[0], setTenant = _d[1];
    useEffect(function () {
        if (isDefined(tenant)) {
            setTenant(tenant);
            Sentry.setTag('tenant', tenant === null || tenant === void 0 ? void 0 : tenant.domain);
        }
    }, [tenant, setTenant]);
    // This request is typically short, and mounting the LoadingScreen here as well as lower down in PrivateLoader causes the animation to sutter
    if (((_b = tenantError === null || tenantError === void 0 ? void 0 : tenantError.response) === null || _b === void 0 ? void 0 : _b.status) === 404)
        return React.createElement(Redirect, { to: "/bad-company" });
    if (isNonNullish(tenantError))
        return React.createElement(Redirect, { to: "/server-error" });
    if (tenantFromAtom == null)
        return React.createElement(LoadingScreen, null);
    assert(isDefined(tenant), "Expected tenant to be defined but it wasn't");
    return React.createElement(React.Fragment, null, children);
};
