import { __assign } from "tslib";
import React from 'react';
import { Notification } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddressBlock from 'shared/components/AddressBlock';
import { Icon, IconName } from 'shared/components/Icon';
import AddressForm from 'shared/forms/AddressForm';
import { Dropdown } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './AddressPartialForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddressPartialForm(_a) {
    var _b = _a.addresses, addresses = _b === void 0 ? [] : _b;
    var t = useTranslation().t;
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue, watch = _c.watch, register = _c.register;
    var _d = useFieldArray({
        control: control,
        name: 'addresses_attributes',
    }), append = _d.append, remove = _d.remove, fields = _d.fields;
    var openAddressForm = function (address, index) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.FORM.ADDRESS.ADD),
            children: (React.createElement(AddressForm, { initialValues: address, onSubmit: function (values) {
                    if (Number.isInteger(index)) {
                        setValue("addresses_attributes.".concat(index), values);
                    }
                    else {
                        append(values);
                    }
                    dialogHide(DialogShowId.CONFIRM);
                } })),
        });
    };
    var removeField = function (index) {
        remove(index);
    };
    return (React.createElement("div", { className: styles('address-partial-form') },
        React.createElement("div", { className: styles('title') },
            React.createElement("div", { className: styles('label-input') }, t(i18nKeys.FORM.FORMAL_NOTICE.SHIPPING_ADDRESS)),
            React.createElement("div", { className: styles('line') }),
            React.createElement(Dropdown, { selectorContent: React.createElement(Icon, { name: IconName.PLUS }) },
                React.createElement("div", { className: styles('dropdown-item'), onClick: function () { return openAddressForm({}); } },
                    React.createElement("span", null,
                        React.createElement(Icon, { name: IconName.PLUS, size: "10px" }),
                        " ",
                        t(i18nKeys.NEW_ADDRESS))),
                addresses.map(function (address) {
                    var _a;
                    return (React.createElement("div", { key: address.id, className: styles('dropdown-item'), onClick: function () { return append(__assign({}, address.attributes)); } },
                        !((_a = address.attributes.notifications) === null || _a === void 0 ? void 0 : _a.includes(Notification.Debtor)) && (React.createElement("b", null, address.attributes.description)),
                        React.createElement(AddressBlock, { address: address, clean: true })));
                }))),
        fields.map(function (field, index) {
            register("addresses_attributes.".concat(index));
            if (watch("addresses_attributes.".concat(index, "._destroy"))) {
                return undefined;
            }
            return (React.createElement("div", { className: styles('address'), key: field.id },
                React.createElement(AddressBlock, { address: watch("addresses_attributes.".concat(index)), edit: function () { return openAddressForm(watch("addresses_attributes.".concat(index)), index); }, remove: watch('addresses_attributes').length === 1 ? undefined : function () { return removeField(index); } })));
        })));
}
