import { __assign } from "tslib";
import React, { useEffect, useMemo } from 'react';
import { AssignableModel, AssignableTo } from 'api/models';
import { AutomaticAssignationsList } from 'app/Private/AutomaticAssignations';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomRecoveryPartialForm } from 'shared/forms/PreferencesForm';
import { Button, Dropdown } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './ListDebtCollectionCustomization.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ListDebtCollectionCustomization(_a) {
    var _b, _c;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var recoverPlans = useSelector(function (state) { return state.settings.recoveryPlanList; });
    var canAdd = useMemo(function () { return company.package.included_plan_count > recoverPlans.pages.length; }, [recoverPlans.pages.length]);
    var form = useForm({
        shouldUnregister: true,
        defaultValues: __assign(__assign({}, company), { generate_invoice_in_debtor_locale: (_b = company.generate_invoice_in_debtor_locale) === null || _b === void 0 ? void 0 : _b.toString(), tp_accounting_automatic_reminders: company.tp_accounting.automatic_reminders, automatic_reminders: company.automatic_reminders.toString(), default_formal_notice_template_id: company.default_templates.formal_notice, default_additional_reminder_template_id: company.default_templates.additional_reminder }),
    });
    var onSubmit = function (values) {
        settingsActions.editDebtCollection({
            data: values,
        });
    };
    var submit = function (value) {
        if (JSON.parse(value.automatic_reminders) !== JSON.parse(company.automatic_reminders)) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.CONFIRMATION),
                children: (React.createElement(AYSModal, { text: JSON.parse(value.automatic_reminders) ? (React.createElement("div", { className: styles('confirm-modal') },
                        React.createElement(HTML, { html: t(i18nKeys.FORM.PREFERENCES.AYS_AUTOMATIC_REMINDER_MODE) }),
                        company.reminders_to_send_count &&
                            company.reminders_to_send_count.next_work_day > 0 && (React.createElement("div", null, t(company.reminders_to_send_count.next_work_day > 1
                            ? i18nKeys.FORM.PREFERENCES.NEXT_WORKING_DAYS
                            : i18nKeys.FORM.PREFERENCES.NEXT_WORKING_DAY, {
                            count: company.reminders_to_send_count.next_work_day,
                        }))))) : (React.createElement("div", { className: styles('confirm-modal') },
                        React.createElement(HTML, { html: t(i18nKeys.FORM.PREFERENCES.AYS_MANUAL_REMINDER_MODE) }))), onConfirm: function () {
                        onSubmit(value);
                        form.reset(value);
                    } })),
            });
        }
        else {
            onSubmit(value);
            form.reset(value);
        }
    };
    var addRecoverPlan = function () {
        history.push('/settings/workflows/recovery-plans/create');
    };
    useEffect(function () {
        settingsActions.recoveryPlanListPage();
    }, []);
    var setPlanAsDefault = function (plan, steps_switch) {
        settingsActions.editDebtCollection({
            data: {
                recovery_plan_id: plan.id,
                steps_switch: steps_switch,
            },
            callback: function () {
                accountActions.getCompany();
                settingsActions.recoveryPlanListPage();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var setAsDefault = function (plan) { return function () {
        if (company.recovery_plan && company.recovery_plan.invoices_using > 0) {
            showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.SET_DEFAULT_PLAN),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.PLAN.MOVE_INVOICES_IN_NEW_PLAN), cancelButtonText: t(i18nKeys.NO), confirmButtonText: t(i18nKeys.YES), onConfirm: function () {
                        MoveRecoveryPlanInvoices({
                            title: t(i18nKeys.SET_DEFAULT_PLAN),
                            oldPlanId: company.recovery_plan.id,
                            newPlanId: plan.id,
                            onSubmit: function (steps_switch) {
                                setPlanAsDefault(plan, steps_switch);
                            },
                            companyDefault: true,
                        });
                    }, onCancel: function () {
                        dialogHide(DialogShowId.CUSTOM);
                        setPlanAsDefault(plan);
                    } })),
            });
        }
        else {
            setPlanAsDefault(plan);
        }
    }; };
    var editPlan = function (itemId) { return function () {
        history.push("/settings/workflows/recovery-plans/edit/".concat(itemId));
    }; };
    var moveInvoicesPlan = function (oldPlan) { return function () {
        showDialog({
            title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: (React.createElement(ChooseRecoveryPlanModal, { recoverPlans: recoverPlans.pages, onClick: function (planId) {
                    MoveRecoveryPlanInvoices({
                        title: t(i18nKeys.PLAN.INVOICES),
                        newPlanId: planId,
                        oldPlanId: oldPlan.id,
                        onSubmit: function (steps_switch) {
                            settingsActions.switchPlansInvoices({
                                data: {
                                    plans_switch: [
                                        {
                                            from: oldPlan.id,
                                            to: planId,
                                            steps_switch: steps_switch,
                                        },
                                    ],
                                },
                                callback: function () {
                                    settingsActions.recoveryPlanListPage();
                                    dialogHide(DialogShowId.CUSTOM);
                                },
                            });
                        },
                    });
                } })),
        });
    }; };
    var stopPropagation = function (e) {
        e.stopPropagation();
    };
    return (React.createElement("div", { className: styles('list-debt-collection-customization') },
        React.createElement("div", { className: styles('recovery-plans') },
            React.createElement("ul", null, (_c = recoverPlans.pages) === null || _c === void 0 ? void 0 :
                _c.map(function (item) { return (React.createElement("li", null,
                    React.createElement("div", { key: item.id, className: styles('item', 'card'), onClick: editPlan(item.id) },
                        React.createElement("div", { className: styles('title') },
                            React.createElement("h4", { title: item.name }, item.name),
                            item.company_default && (React.createElement("div", { className: styles('label', 'badge') }, t(i18nKeys.DEFAULT))),
                            React.createElement("div", { onClick: stopPropagation },
                                React.createElement(Dropdown, { contentClass: styles('dropdown-container'), selectorContent: React.createElement(Icon, { name: IconName.POINTS, className: styles('menu-icon') }), toggleContent: true }, item.company_default && item.invoices_using === 0 ? (React.createElement("div", { className: styles('dropdown-item') }, t(i18nKeys.NO_AVAILABLE_ACTIONS))) : (React.createElement(React.Fragment, null,
                                    !item.company_default && (React.createElement("div", { className: styles('dropdown-item'), onClick: setAsDefault(item) }, t(i18nKeys.PLAN.SET_AS_DEFAULT))),
                                    item.invoices_using > 0 && (React.createElement("div", { className: styles('dropdown-item'), onClick: moveInvoicesPlan(item) }, t(i18nKeys.PLAN.MOVE_INVOICES)))))))),
                        React.createElement("div", { className: styles('item-body') },
                            React.createElement("div", null,
                                t(i18nKeys.N_STEP, { count: item.recovery_steps_count }),
                                " "),
                            item.debtors_using + item.invoices_using === 0 ? (React.createElement("div", null,
                                " ",
                                t(i18nKeys.UNUSED))) : (React.createElement(React.Fragment, null,
                                React.createElement("div", null,
                                    item.debtors_using,
                                    ' ',
                                    React.createElement(Icon, { title: t(i18nKeys.PLAN.CLIENTS_NUMBER_INCLUDED), name: IconName.USER }),
                                    ' '),
                                React.createElement("div", null,
                                    item.invoices_using,
                                    ' ',
                                    React.createElement(Icon, { title: t(i18nKeys.PLAN.INVOICES_NUMBER_INCLUDED), name: IconName.INVOICE }),
                                    ' '))))))); }),
                React.createElement("li", null,
                    React.createElement("div", { className: styles('item', 'card', 'add-item', !canAdd && 'disabled'), onClick: addRecoverPlan },
                        React.createElement("div", null,
                            React.createElement(Icon, { name: IconName.PLUS }),
                            React.createElement("span", null, t(i18nKeys.ACTIONS.ADD))))))),
        React.createElement(AutomaticAssignationsList, { defaultType: AssignableTo.recovery_plan, tabItems: [
                {
                    model_to_assign: AssignableModel.invoice,
                    assign_to_type: AssignableTo.recovery_plan,
                    key: i18nKeys.COMMON.INVOICE,
                },
                {
                    model_to_assign: AssignableModel.debtor,
                    assign_to_type: AssignableTo.recovery_plan,
                    key: i18nKeys.COMMON.CLIENT,
                },
            ] }),
        React.createElement("form", { onSubmit: form.handleSubmit(submit) },
            React.createElement(FormProvider, __assign({}, form),
                React.createElement("h3", null, t(i18nKeys.FORM.PREFERENCES.CUSTOM_RECOVERY)),
                React.createElement(CustomRecoveryPartialForm, null),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { noShadow: true, disabled: !form.formState.isDirty, label: t(i18nKeys.SAVE), type: "submit" }))))));
}
