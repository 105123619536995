import React from 'react';
import { useDeleteExternalMailboxConfiguration, useUpdateExternalMailboxConfiguration } from 'api';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { ExternalMailboxSettingsForm } from './ExternalMailboxSettingsForm';
import styleIdentifiers from './ExternalMailboxSettings.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailboxSettingsItem = function (_a) {
    var item = _a.item, refetch = _a.refetch;
    var t = useTranslation().t;
    var updateExternalMailboxConfigurationMutation = useUpdateExternalMailboxConfiguration().mutate;
    var deleteExternalMailboxConfigurationMutation = useDeleteExternalMailboxConfiguration().mutate;
    var id = item.id, _b = item.attributes, host = _b.host, port = _b.port, ssl = _b.ssl, login = _b.login, is_microsoft = _b.is_microsoft;
    var handleUpdate = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(ExternalMailboxSettingsForm, { edit: true, externalMailboxConfiguration: item, onSubmit: function (data) {
                    return updateExternalMailboxConfigurationMutation({ id: id, data: data }, {
                        onSuccess: function (response) {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_EXTERNAL_MAILBOX_CONFIGURATION), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteExternalMailboxConfigurationMutation({ id: id }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    return (React.createElement("tr", { key: id, className: styles('Mailbox-settings-item') },
        React.createElement("td", null),
        React.createElement("td", null, id),
        React.createElement("td", null, host),
        React.createElement("td", null, port),
        React.createElement("td", null, t(!ssl ? i18nKeys.NO : i18nKeys.YES)),
        React.createElement("td", null, login),
        React.createElement("td", { className: styles('actions') },
            !is_microsoft && (React.createElement("span", { onClick: handleUpdate },
                React.createElement(Icon, { name: IconName.PENCIL, size: "18px", className: styles('action') }))),
            React.createElement("span", { onClick: handleDelete },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
};
