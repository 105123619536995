import { useSearchInvoices } from 'api';
import React from 'react';
export default function InvoiceReferences(_a) {
    var ids = _a.ids;
    var params = {
        ids: ids,
    };
    var invoicesResponse = useSearchInvoices(params).data;
    var invoices = (invoicesResponse === null || invoicesResponse === void 0 ? void 0 : invoicesResponse.data) || [];
    var references = invoices.map(function (_a) {
        var reference = _a.attributes.reference;
        return reference;
    }).join(' / ');
    return React.createElement(React.Fragment, null, references);
}
