import { __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import { isNullish, partition } from 'remeda';
import { useLoadLightUsers, useUpdateReport } from 'shared/hooks';
import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RecipientsStep } from '../../CreateReport/steps';
export var EditReportRecipientsModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, report = _a.report;
    var _b = useUpdateReport(), updateReport = _b.updateReport, isUpdateReportLoading = _b.isUpdateReportLoading;
    var users = useLoadLightUsers().users;
    var form = useForm({
        initialValues: {
            collaborators: [],
            emails: [],
        },
    });
    useEffect(function () {
        if (isNullish(users))
            return;
        var userEmails = users.map(function (_a) {
            var email = _a.email;
            return email;
        });
        var _a = __read(partition(report.emails, function (email) {
            return userEmails === null || userEmails === void 0 ? void 0 : userEmails.includes(email);
        }), 2), collaborators = _a[0], emails = _a[1];
        form.setValues({
            collaborators: collaborators,
            emails: emails,
        });
    }, [users]);
    // TODO: add the same validation as for the wizard steps
    var handleSave = function () {
        updateReport({
            id: report.id,
            emails: __spreadArray(__spreadArray([], __read(form.values.emails), false), __read(form.values.collaborators), false),
        }, {
            onSuccess: function () {
                onClose();
            },
        });
    };
    return (React.createElement(Modal, { opened: isOpen, onClose: onClose, title: "Edit name", size: "auto" },
        React.createElement("form", { onSubmit: function (e) {
                e.preventDefault();
                handleSave();
            } },
            React.createElement(RecipientsStep, { form: form }),
            React.createElement(Group, { w: "100%", justify: "end", mt: "xl" },
                React.createElement(Button, { variant: "light", color: "orange", onClick: onClose }, "Cancel"),
                React.createElement(Button, { type: "submit", loading: isUpdateReportLoading }, "Save")))));
};
