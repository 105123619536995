import React from 'react';
import { PlanType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { RECOVER_ACTION_TYPE } from '../EditDebtCollectionCustomization';
import styleIdentifiers from './RecoverAction.scss';
var styles = classNames.bind(styleIdentifiers);
export default function RecoverAction(_a) {
    var onSelectAction = _a.onSelectAction, planType = _a.planType;
    var t = useTranslation().t;
    var onChooseTemlateType = function (action) { return function () {
        if (planType === PlanType.recovery &&
            (action === RECOVER_ACTION_TYPE.LAST_REMINDER ||
                action === RECOVER_ACTION_TYPE.INTERMEDIATE_REMINDER ||
                action === RECOVER_ACTION_TYPE.ADDITIONAL_REMINDER)) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.LATE_FEES),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.TEMPLATE_WITH_LATE_FEES), cancelButtonText: t(i18nKeys.WITHOUT_FEES), confirmButtonText: t(i18nKeys.WITH_FEES), onConfirm: function () {
                        onSelectAction(action, true);
                    }, onCancel: function () {
                        onSelectAction(action, false);
                    } })),
            });
        }
        else {
            onSelectAction(action);
        }
    }; };
    return (React.createElement("div", { className: styles('recover-action') },
        React.createElement("div", { className: styles('head') }, t(i18nKeys.ADD_DOCUMENT)),
        React.createElement("div", { className: styles('content') },
            planType === PlanType.recovery ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.INVOICE) },
                    React.createElement(Icon, { name: IconName.INVOICE }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.INVOICE))),
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.CREDIT_NOTE) },
                    React.createElement(Icon, { name: IconName.CREDIT_NOTE }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.CREDIT_NOTE))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_CREATION) },
                    React.createElement(Icon, { name: IconName.PAYMENT_BOOK }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.PLAN_CREATION))),
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_UPDATE) },
                    React.createElement(Icon, { name: IconName.PAYMENT_BOOK }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.PLAN_UPDATE))),
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.PLAN_CANCEL) },
                    React.createElement(Icon, { name: IconName.PAYMENT_BOOK }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.PLAN_CANCEL))))),
            React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.PREVENTIVE_REMINDER) },
                React.createElement(Icon, { name: IconName.SEND }),
                React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.PREVENTIVE_REMINDER))),
            React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.FIRST_REMINDER) },
                React.createElement(Icon, { name: IconName.SEND }),
                React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.FIRST_REMINDER))),
            React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.INTERMEDIATE_REMINDER) },
                React.createElement(Icon, { name: IconName.SEND }),
                React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.INTERMEDIATE_REMINDER))),
            React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.LAST_REMINDER) },
                React.createElement(Icon, { name: IconName.SEND }),
                React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.LAST_REMINDER))),
            planType === PlanType.recovery && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.ADDITIONAL_REMINDER) },
                    React.createElement(Icon, { name: IconName.SEND }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.ADDITIONAL_REMINDER))),
                React.createElement("div", { className: styles('action'), onClick: onChooseTemlateType(RECOVER_ACTION_TYPE.FORMAL_NOTICE) },
                    React.createElement(Icon, { name: IconName.LAW }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE_TYPES.FORMAL_NOTICE))))))));
}
