import { __awaiter, __generator } from "tslib";
import dayjs from 'dayjs';
import { i18nKeys } from 'locales';
import { translate, useTranslation } from 'locales/i18n';
import { translateAction } from 'store/translate/translate.actions';
import { LocaleSchema } from 'types';
import { z, ZodIssueCode } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult, useAxiosInstance } from './utils';
// https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
z.setErrorMap(function (issue, ctx) {
    if (issue.code === ZodIssueCode.invalid_string && issue.validation === 'email') {
        return { message: translate(i18nKeys.ERROR.INVALID_EMAIL) };
    }
    if (issue.code === ZodIssueCode.too_small && issue.minimum === 1) {
        return { message: translate(i18nKeys.ERROR.FIELD_REQUIRED) };
    }
    return { message: ctx.defaultError };
});
export var UpdateProfileFormSchema = z
    .object({
    avatarUrl: z.string().url().nullable(),
    email: z.string().email(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    pageLimit: z.string().transform(function (val) { return Number(val); }),
    receiveDailyEmails: z.boolean(),
    receiveNotificationEmails: z.boolean(),
    receiveWeeklyEmails: z.boolean(),
    signature: z.string().nullable(),
})
    .partial();
export function updateProfileFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put('/profile', UpdateProfileFormSchema.parse(data))];
            }
        });
    });
}
export function useUpdateProfile() {
    var client = useQueryClient();
    var t = useTranslation().t;
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return updateProfileFn(axiosInstance, data); },
        onSuccess: function (_, ctx) {
            if ('locale' in ctx) {
                translateAction.changeLang(ctx.locale);
                dayjs.locale(ctx.locale);
            }
            notifications.show({
                message: t(i18nKeys.SETTINGS.PROFILE.SUCCESSFULLY_UPDATE),
                color: 'green',
            });
            client.invalidateQueries(['profile']);
        },
        onError: function (error) {
            var _a;
            (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message.forEach(function (message) {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: message,
                });
            });
        },
    });
    return addActionNameToMutationResult('updateProfile', mutation);
}
