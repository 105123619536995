import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Button, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useRefreshMonitoring } from '../../../../shared/hooks';
export var UpdateMonitoringDataButton = function (_a) {
    var hasVatNumber = _a.hasVatNumber, debtorId = _a.debtorId;
    var t = useTranslation().t;
    var _b = useRefreshMonitoring(), refreshMonitoring = _b.refreshMonitoring, isRefreshMonitoringLoading = _b.isRefreshMonitoringLoading;
    return (React.createElement(Tooltip, { label: t(i18nKeys.DEBTOR.MONITORING.NO_VAT), disabled: hasVatNumber },
        React.createElement(Button, { variant: "light", leftSection: React.createElement(IconRefresh, { stroke: 1.5 }), disabled: !hasVatNumber, loading: isRefreshMonitoringLoading, onClick: function () { return refreshMonitoring({ debtorId: debtorId }); } }, t(i18nKeys.DEBTOR.MONITORING.UPDATE_DATA))));
};
