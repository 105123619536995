import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { useUseMatching } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { formatAmount } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { transactionActions } from 'store/transaction/transaction.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, success, } from 'store/view/view.actions';
import styleIdentifiers from './UnmatchPayment.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UnmatchPayment(_a) {
    var reload = _a.reload, invoice = _a.invoice;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var isMatchingActive = useUseMatching();
    var _c = __read(useState(), 2), payments = _c[0], setPayments = _c[1];
    var _d = payments || invoice, manual_payments = _d.manual_payments, external_payments = _d.external_payments;
    useEffect(function () {
        if (!invoice.external_payments) {
            invoiceActions.detail({
                id: invoice.id,
                callback: function (_a) {
                    var data = _a.data;
                    setPayments(treatInvoice(data));
                },
            });
        }
    }, []);
    var submit = function (isExternalPayment, payment, steps_switch) {
        (isExternalPayment
            ? transactionActions.unmatchExternalPayment
            : transactionActions.unmatchManualPayment)({
            id: payment.id,
            keepMountOnExit: true,
            data: {
                recovery_step_id: steps_switch ? steps_switch[0].to : undefined,
            },
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                reload();
                success({
                    text: isExternalPayment
                        ? 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_UNMATCH_PAYMENT'
                        : 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_DELETE_PAYMENT',
                });
            },
        });
    };
    var unmatchPayment = function (payment, isExternalPayment) { return function () {
        if (invoice.paid) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.INVOICE_BACK_IN_PLAN), confirmButtonText: t(i18nKeys.YES), cancelButtonText: t(i18nKeys.NO), onConfirm: function () {
                        MoveRecoveryPlanInvoices({
                            title: t(i18nKeys.PLAN.INVOICES),
                            newPlanId: invoice.recovery_plan_id,
                            oldPlanId: invoice.recovery_plan_id,
                            currentStep: -1,
                            onSubmit: function (steps_switch) {
                                submit(isExternalPayment, payment, steps_switch);
                            },
                        });
                    }, onCancel: function () {
                        submit(isExternalPayment, payment);
                    } })),
            });
        }
        else {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: React.createElement("div", null,
                        React.createElement("div", null, isMatchingActive &&
                            t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_NOT_ACTIVE_ANYMORE)),
                        t(isExternalPayment
                            ? i18nKeys.INVOICE.UNMATCH_PAYMENT.AYS_TO_UNMATCH
                            : i18nKeys.INVOICE.UNMATCH_PAYMENT.AYS_TO_DELETE)), onConfirm: function () {
                        submit(isExternalPayment, payment);
                    } })),
            });
        }
    }; };
    var unlinkCn = function (cn) { return function () {
        creditNoteActions.unlinkToInvoices({
            id: cn.id,
            data: {
                unlink_invoice: invoice.id,
            },
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                reload();
            },
        });
    }; };
    return (React.createElement("div", { className: styles('UnmatchPayment') },
        (external_payments === null || external_payments === void 0 ? void 0 : external_payments.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("h3", null, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILED_PAYMENTS)),
            React.createElement("div", { className: "recovr-table" },
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.FORM.HISTORY.SENDER))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.DATE))),
                            React.createElement("th", { style: { width: 120 } },
                                React.createElement("div", null, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.PAYMENT_AMOUNT))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_AMOUNT))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.COMMUNICATION))),
                            React.createElement("th", { style: { width: 150 } },
                                React.createElement("div", null)))),
                    React.createElement("tbody", null, external_payments.map(function (payment) {
                        var _a;
                        return (React.createElement("tr", { key: payment.id },
                            React.createElement("td", null, payment.payment.counterparty_name || payment.payment.external_id),
                            React.createElement("td", null, formattedDate(payment.payment.value_date, currentLang)),
                            React.createElement("td", null,
                                React.createElement(Amount, { localizedValue: payment.payment.amount, value: payment.payment.localized_money_object.amount, suffix: payment.payment.currency })),
                            React.createElement("td", null,
                                React.createElement(Amount, { localizedValue: payment.amount, value: (_a = payment.localized_money_object) === null || _a === void 0 ? void 0 : _a.amount, suffix: payment.currency })),
                            React.createElement("td", null, payment.payment.communication),
                            React.createElement("td", { className: "actions" },
                                React.createElement(Button, { className: styles('delete-payment'), label: t(i18nKeys.INVOICE.UNMATCH_PAYMENT.CANCEL_PAYMENT), onClick: unmatchPayment(payment, true), small: true, noMargin: true, noShadow: true, color: ButtonColor.GREY, iconLeft: IconName.UNLINK }))));
                    })))))),
        (manual_payments === null || manual_payments === void 0 ? void 0 : manual_payments.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("h3", null, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.PAYMENTS_ADDED_MANUALLY)),
            React.createElement("div", { className: "recovr-table" },
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.MODALITY))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.DATE))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.AMOUNT))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.COMMENT))),
                            React.createElement("th", { style: { width: 150 } },
                                React.createElement("div", null)))),
                    React.createElement("tbody", null, manual_payments.map(function (payment) {
                        var _a;
                        return (React.createElement("tr", { key: payment.id },
                            React.createElement("td", null, payment.payment_modality_translated),
                            React.createElement("td", null, formattedDate(payment.received_at, currentLang)),
                            React.createElement("td", null,
                                React.createElement(Amount, { localizedValue: payment.amount, value: (_a = payment.localized_money_object) === null || _a === void 0 ? void 0 : _a.amount, suffix: payment.currency })),
                            React.createElement("td", null, payment.notes),
                            React.createElement("td", { className: "actions" },
                                React.createElement(Button, { className: styles('delete-payment'), label: t(i18nKeys.DELETE), onClick: unmatchPayment(payment), small: true, noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE }))));
                    })))))),
        invoice.credit_notes_attributes.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("h3", null, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.CREDIT_NOTES)),
            React.createElement("div", { className: "recovr-table" },
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.FORM.REFERENCE))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.ISSUE_DATE))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.AMOUNT))),
                            React.createElement("th", null,
                                React.createElement("div", null, t(i18nKeys.STATUS))),
                            React.createElement("th", { style: { width: 150 } },
                                React.createElement("div", null)))),
                    React.createElement("tbody", null, invoice.credit_notes_attributes.map(function (cn) {
                        var matched = cn.matched_payments_attributes.filter(function (e) { return e.invoice_attributes.id === invoice.id; });
                        return (React.createElement(React.Fragment, { key: cn.id }, matched.map(function (matchedPayment) { return (React.createElement("tr", { key: matchedPayment.id },
                            React.createElement("td", null, cn.reference),
                            React.createElement("td", null, formattedDate(cn.issue_date, currentLang)),
                            React.createElement("td", null, formatAmount(matchedPayment.amount)),
                            React.createElement("td", null, cn.status),
                            React.createElement("td", null,
                                React.createElement(Button, { className: styles('delete-payment'), label: t(i18nKeys.DELETE), onClick: unlinkCn(cn), small: true, noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE })))); })));
                    }))))))));
}
