import { __assign, __awaiter, __generator } from "tslib";
import { omit } from 'remeda';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export var companyConfigurationSchema = z.object({
    vatNumber: z.string().min(1),
    companyEmail: z.string().min(1),
    phoneNumber: z.string().min(1),
    locale: z.string().min(1),
    currency: z.string().min(1),
    addressAttributes: z.object({
        streetName: z.string().min(1),
        streetNumber: z.string().min(1),
        zipCode: z.string().min(1),
        city: z.string().min(1),
        countryCode: z.string().min(1),
    }),
});
var companyConfigurationTransformedSchema = companyConfigurationSchema.transform(function (data) { return (__assign(__assign({}, omit(data, ['phoneNumber', 'locale'])), { phonesAttributes: [{ number: data.phoneNumber }], locale: data.locale.toLowerCase() })); });
export function saveCompanyConfigurationFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    try {
                        companyConfigurationSchema.parse(data);
                    }
                    catch (error) {
                        console.error(error === null || error === void 0 ? void 0 : error.toString());
                    }
                    return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('onboarding/company_initialization', companyConfigurationTransformedSchema.parse(data))];
            }
        });
    });
}
export function useSaveCompanyConfiguration() {
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return saveCompanyConfigurationFn(axiosInstance, data); },
    });
    return addActionNameToMutationResult('saveCompanyConfiguration', mutation);
}
