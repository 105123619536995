import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { SendingMediaIcon } from '../SendingMedium';
import styleIdentifiers from './RecoveryPlan.scss';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryPlanStepLine = function (_a) {
    var index = _a.index, step = _a.step, editMode = _a.editMode, editStep = _a.editStep, deleteStep = _a.deleteStep;
    var t = useTranslation().t;
    var sending_media = step.sending_media, template = step.template, step_type = step.step_type, fee_step_reached = step.fee_step_reached, invoices_count = step.invoices_count, task_attributes = step.task_attributes;
    return (React.createElement("div", { className: styles('step') },
        React.createElement("div", { className: styles('step-template') },
            React.createElement("div", { className: styles('template') },
                React.createElement("div", { className: styles('template-name') }, template ? template.name : task_attributes.title),
                React.createElement("div", { className: styles('template-type') }, t(template
                    ? i18nKeys.TEMPLATE_TYPES[template.step_type.toUpperCase()]
                    : i18nKeys.TASK_GENERATION)),
                fee_step_reached && (React.createElement("div", { className: styles('late-fees') }, t(i18nKeys.LATE_FEES_INCLUDED)))),
            template ? (React.createElement("div", { className: styles('actions'), onClick: editStep && editStep(index) }, sending_media.map(function (_a, i) {
                var name = _a.name, force_sending = _a.force_sending;
                return i === 0 ? (React.createElement("div", { className: styles('action'), key: "".concat(name, "-").concat(i) },
                    React.createElement(Icon, { name: SendingMediaIcon[name] }))) : (React.createElement(React.Fragment, { key: "".concat(name, "-").concat(i) },
                    React.createElement("span", null, t(String(force_sending) === 'true' ? i18nKeys.AND : i18nKeys.OR)),
                    React.createElement("div", { className: styles('action') },
                        React.createElement(Icon, { name: SendingMediaIcon[name], className: styles('ml-4') }))));
            }))) : (task_attributes.mandatory === 'true' && (React.createElement("div", { className: styles('action', 'red'), title: t(i18nKeys.TASK.ATTRIBUTES.MANDATORY) },
                React.createElement(Icon, { name: IconName.ALERT_CIRCLE }))))),
        React.createElement("div", { className: styles('line-action') },
            editMode && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('action', 'add'), onClick: editStep(index, typeof task_attributes !== 'undefined') },
                    React.createElement(Icon, { name: IconName.PENCIL })),
                step_type !== 'first_reminder' && deleteStep && (React.createElement("div", { className: styles('action', 'red'), onClick: deleteStep(index) },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE }))))),
            React.createElement("div", { className: styles('action', 'invoice'), title: t(i18nKeys.PLAN.INVOICES_EXECUTED_STEP) },
                invoices_count || 0,
                " ",
                React.createElement(Icon, { name: IconName.INVOICE, color: IconColor.RED })))));
};
