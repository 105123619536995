import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Box, Button, Card, Flex, Text } from '@mantine/core';
export var MantineSettingsCard = function (_a) {
    var title = _a.title, children = _a.children, onSave = _a.onSave, saveButtonLabel = _a.saveButtonLabel;
    var t = useTranslation().t;
    return (React.createElement(Card, { h: "100%", withBorder: true, radius: "sm", shadow: "sm" },
        React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", mb: "md" },
            React.createElement(Text, { fw: 500, size: "lg", ff: "Roboto" }, title)),
        React.createElement(Box, { h: "100%" }, children),
        React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", mt: "lg" },
            React.createElement(Flex, { w: "100%", justify: "flex-end" },
                React.createElement(Button, { fw: 500, disabled: onSave == null, type: "submit", variant: "light", color: "blue", onClick: onSave }, saveButtonLabel !== null && saveButtonLabel !== void 0 ? saveButtonLabel : t(i18nKeys.SAVE))))));
};
