import React from 'react';
import { useGetTask, useUpdateTask } from 'api';
import { sideMenuHide } from 'store/view/view.actions';
import { TaskForm } from './TaskForm';
export var TaskSideMenu = function (_a) {
    var taskId = _a.taskId, refetch = _a.refetch;
    var _b = useGetTask(taskId), taskResponse = _b.data, isLoading = _b.isLoading, isError = _b.isError;
    var updateTaskMutation = useUpdateTask().mutate;
    if (isLoading && !taskResponse) {
        return React.createElement(React.Fragment, null);
    }
    if (isError || !taskResponse) {
        return React.createElement("span", null, "Error");
    }
    return (React.createElement(TaskForm, { task: taskResponse.data, onSubmit: function (data) {
            return updateTaskMutation({ id: taskId, data: data }, {
                onSuccess: function () {
                    refetch();
                    sideMenuHide();
                },
            });
        } }));
};
