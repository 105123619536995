import { __assign, __read } from "tslib";
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import AddressBlock from 'shared/components/AddressBlock';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import BankAccount from 'shared/components/BankAccount';
import DateItem from 'shared/components/DateItem';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import GradientBorder from 'shared/components/GradientBorder';
import HTML from 'shared/components/HTML/HTML';
import { IconName } from 'shared/components/Icon';
import InvoiceFooter from 'shared/components/InvoiceFooter';
import TvaNumber from 'shared/components/TvaNumber';
import EncodePaymentForm from 'shared/forms/EncodePaymentForm';
import SendInvoiceForm from 'shared/forms/SendInvoiceForm';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { currencySymbol, formatAmount, formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, showDialog, sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import LinkDocument from '../LinkDocument';
import TableDetails from './TableDetails';
import styleIdentifiers from './detail.scss';
var styles = classNames.bind(styleIdentifiers);
// NB: This is essentially the overview of a draft invoice before validation
export default function Detail(_a) {
    var _b;
    var credit = _a.credit, imported = _a.imported;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var invoice = useSelector(function (state) { var _a; return (_a = state.invoice.current) === null || _a === void 0 ? void 0 : _a.data; });
    var creditNote = useSelector(function (state) { var _a; return (_a = state.creditNote.current) === null || _a === void 0 ? void 0 : _a.data; });
    var params = useRouteMatch().params;
    var _d = __read(useState(), 2), pdf = _d[0], setPdf = _d[1];
    var history = useHistory();
    var data = credit ? creditNote : invoice;
    useEffect(function () {
        if (credit)
            creditNoteActions.detail({ id: params.id });
        else
            invoiceActions.detail({ id: params.id });
    }, []);
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.imported) {
            invoiceActions.document({
                id: params.id,
                document_type: 'invoice_pdf',
                callback: function (doc) {
                    setPdf(doc);
                },
            });
        }
    }, [data === null || data === void 0 ? void 0 : data.imported]);
    var nextRef = useMemo(function () {
        var billingLog = data === null || data === void 0 ? void 0 : data.billing_log_attributes;
        return billingLog != null
            ? "".concat(billingLog.prefix, "-").concat(billingLog.counter + 1, " (").concat(t('INVOICE.DRAFT'), ")")
            : '';
    }, [data]);
    // Only for invoice
    var submitPayment = function (currency) { return function (values) {
        if (!data)
            return;
        invoiceActions.actionsAddPayment({
            id: data.id,
            data: __assign({ currency: currency }, values),
            callback: function () {
                invoiceActions.detail({
                    id: data.id,
                    noReset: true,
                    noLoading: true,
                });
                sideMenuHide();
            },
        });
    }; };
    // Only for invoice
    var addPayment = function () {
        sideMenuShow({
            content: (React.createElement(EncodePaymentForm, { onSubmit: submitPayment(data === null || data === void 0 ? void 0 : data.currency), currency: data === null || data === void 0 ? void 0 : data.currency, totalAmount: data === null || data === void 0 ? void 0 : data.total_tvac })),
        });
    };
    var linkDocument = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.INVOICE.JOIN_FILE),
            children: React.createElement(LinkDocument, { invoice: data }),
        });
    };
    var deleteItem = function () {
        if (!data)
            return;
        sideMenuHide();
        showDialog({
            id: DialogShowId.CONFIRM,
            title: t(i18nKeys.ATTENTION),
            children: (React.createElement(AYSModal, { text: t(credit ? 'INVOICE.AYS_TO_DELETE_CREDIT_NOTE' : 'AYS.INVOICE_ACTION_DELETE'), onConfirm: function () {
                    (credit ? creditNoteActions : invoiceActions).delete({
                        id: data.id,
                        callback: function () {
                            history.push(credit ? '/credit-notes/listing' : '/invoices/listing');
                        },
                    });
                } })),
        });
    };
    var sendInvoice = function (values) {
        if (!data)
            return;
        if (credit) {
            creditNoteActions.send({
                id: data.id,
                data: values,
                callback: function () {
                    sideMenuHide();
                    history.push('/credit-notes/listing');
                },
            });
        }
        else {
            invoiceActions.send({
                id: data.id,
                data: values,
                callback: function () { return sideMenuHide(); },
            });
        }
    };
    var validate = function () {
        var entity = credit ? creditNote : invoice;
        if (!entity)
            return;
        sideMenuShow({
            unmount: true,
            content: React.createElement(SendInvoiceForm, { onSubmit: sendInvoice, data: entity, credit: credit }),
        });
    };
    var goToEditPage = function () {
        sideMenuHide();
        history.push("/".concat(credit ? 'credit-notes' : 'invoices', "/edit/").concat(data.id));
    };
    var customer = data === null || data === void 0 ? void 0 : data.debtor_attributes;
    var shouldHideVat = (customer === null || customer === void 0 ? void 0 : customer.intracom) || (customer === null || customer === void 0 ? void 0 : customer.co_contractor);
    var remaining = (function () {
        if (data == null)
            return 0;
        if (data.remaining_balance !== 0)
            return data.remaining_balance;
        return data.total_tvac - data.already_paid_amount;
    })();
    return (React.createElement("div", { className: styles('Detail') }, data && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('head') },
            React.createElement("div", { className: styles('left') },
                React.createElement(DownloadInvoice, { text: true, invoice: data })),
            !imported && (React.createElement(React.Fragment, null,
                React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), toggleContent: true, className: styles('actions-button'), selectorContent: React.createElement(Button, { noMargin: true, iconRight: IconName.ARROW_BOTTOM_ROUNDED }, t(i18nKeys.FORM.ACTIONS)) },
                    data.actions.includes('send_to_debtor') && (React.createElement("div", { className: "dropdown-item", onClick: validate },
                        React.createElement("div", null, t(credit
                            ? i18nKeys.INVOICING.SEND_CREDIT_NOTE
                            : i18nKeys.INVOICING.VALIDATE_INVOICE)))),
                    data.actions.includes('add_payment') && (React.createElement("div", { className: "dropdown-item", onClick: addPayment },
                        React.createElement("div", null, t(i18nKeys.INVOICE.ADD_PAYMENT)))),
                    !data.paid && !credit && (React.createElement("div", { className: "dropdown-item", onClick: linkDocument },
                        React.createElement("div", null,
                            t(i18nKeys.INVOICE.JOIN_FILE),
                            React.createElement("div", { className: styles('text-circle', 'border', 'grey', 'ml-10') }, data.additional_files_attributes.length)))),
                    data.actions.includes('edit') && (React.createElement("div", { className: "dropdown-item", onClick: goToEditPage }, t(i18nKeys.EDIT))),
                    data.actions.includes('delete') && (React.createElement("div", { className: "dropdown-item", onClick: deleteItem },
                        React.createElement("div", null, t(i18nKeys.DELETE))))),
                React.createElement("div", { className: styles('actions') },
                    data.actions.includes('delete') && (React.createElement(Button, { noMargin: true, label: t(i18nKeys.DELETE), color: ButtonColor.RED, onClick: deleteItem })),
                    data.actions.includes('edit') && (React.createElement(NavLink, { to: "/".concat(credit ? 'credit-notes' : 'invoices', "/edit/").concat(data.id) },
                        React.createElement(Button, { noMargin: true, label: t(i18nKeys.EDIT), color: ButtonColor.GREY }))),
                    data.actions.includes('add_payment') && (React.createElement(Button, { noMargin: true, label: t(i18nKeys.INVOICE.ADD_PAYMENT), color: ButtonColor.GREY, onClick: addPayment })),
                    !data.paid && !credit && (React.createElement(Button, { noMargin: true, color: ButtonColor.GREY, onClick: linkDocument },
                        t(i18nKeys.INVOICE.JOIN_FILE),
                        React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'grey') }, data.additional_files_attributes.length))),
                    data.actions.includes('send_to_debtor') && (React.createElement(Button, { noMargin: true, label: t(credit
                            ? i18nKeys.INVOICING.SEND_CREDIT_NOTE
                            : i18nKeys.INVOICING.VALIDATE_INVOICE), color: ButtonColor.MAIN, onClick: validate, iconRight: IconName.MINIMAL_RIGHT })))))),
        data.imported ? (React.createElement("div", { className: styles('pdf') },
            React.createElement(Document, { file: pdf },
                React.createElement(Page, { renderAnnotationLayer: false, renderTextLayer: false, pageNumber: 1 })))) : (React.createElement("div", { className: styles('invoice') },
            React.createElement(GradientBorder, null),
            React.createElement("div", { className: styles('content') },
                company && (React.createElement("div", { className: styles('head') },
                    company.invoice_customization.logo_url && (React.createElement("img", { className: styles('logo'), src: company.invoice_customization.logo_url, alt: "" })),
                    React.createElement("div", { className: styles('title') }))),
                React.createElement("div", { className: styles('infos') },
                    company && (React.createElement("div", { className: styles('company-detail') },
                        React.createElement("div", { className: styles('name') }, company.name),
                        React.createElement(AddressBlock, { address: company.address_attributes }),
                        React.createElement("br", null),
                        company.vat_number && (React.createElement("div", { className: styles('vat-number') },
                            t(i18nKeys.VAT),
                            ": ",
                            React.createElement(TvaNumber, { value: company.vat_number }))))),
                    customer && (React.createElement("div", { className: styles('main-detail', 'right') },
                        React.createElement("div", { className: styles('name') }, customer.full_name),
                        React.createElement(AddressBlock, { address: customer.address_attributes }),
                        React.createElement("br", null),
                        customer.vat_number && (React.createElement("div", { className: styles('vat-number') },
                            t(i18nKeys.VAT),
                            " : ",
                            React.createElement(TvaNumber, { value: customer.vat_number }))),
                        customer.co_contractor && (React.createElement("div", { className: styles('customer-type') }, t(i18nKeys.INVOICE.REVERSE_CHARGE_COCONTRACTOR))),
                        customer.intracom && (React.createElement("div", { className: styles('customer-type') }, t(i18nKeys.INVOICE.INTRACOMMUNITY)))))),
                React.createElement("div", { className: styles('invoice-infos') },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('invoice-number') }, (!data.draft || nextRef) &&
                            "".concat(t(i18nKeys.NR), " : ").concat(data.draft ? nextRef : data.reference)),
                        remaining > 0 && (React.createElement("div", { className: styles('recap') },
                            React.createElement("span", null,
                                React.createElement(HTML, { className: styles('inline'), html: t(i18nKeys.INVOICING.INVOICE_TEXT.GIVE_THE_AMOUNT, {
                                        text: formatAmount(remaining, ',', '.', 2, currencySymbol(data.currency, company)),
                                    }) }),
                                React.createElement("b", null,
                                    " ",
                                    company && React.createElement(BankAccount, { value: (_b = company.bank_account) === null || _b === void 0 ? void 0 : _b.iban }))),
                            data.structured_communication && (React.createElement(HTML, { className: styles('inline'), html: t(i18nKeys.INVOICING.INVOICE_TEXT.WITH_COMMUNICATION, {
                                    text: formatCommunication(data.structured_communication),
                                }) })),
                            React.createElement(HTML, { className: styles('inline'), html: t(i18nKeys.INVOICING.INVOICE_TEXT.BEFORE_DEADLINE, {
                                    date: formattedDate(data.due_date, currentLang),
                                }) })))),
                    React.createElement("div", { className: styles('right') },
                        data.structured_communication && (React.createElement("div", { className: styles('info') },
                            React.createElement("span", { className: styles('label') },
                                t(i18nKeys.COMMUNICATION),
                                " :"),
                            React.createElement("span", null, formatCommunication(data.structured_communication)))),
                        React.createElement("div", { className: styles('info') },
                            React.createElement("span", { className: styles('label') },
                                t(i18nKeys.INVOICING.INVOICE_DATE),
                                ":"),
                            React.createElement(DateItem, { lg: currentLang, date: data.issue_date, format: "DD/MM/YY" })),
                        React.createElement("div", { className: styles('info') },
                            React.createElement("span", { className: styles('label') },
                                t(i18nKeys.DUE_DATE),
                                " :"),
                            React.createElement(DateItem, { lg: currentLang, date: data.due_date, format: "DD/MM/YY" })))),
                React.createElement("div", { className: styles('products-detail') },
                    React.createElement(TableDetails, { noTva: shouldHideVat, suffix: data.currency, items: data.product_lines_attributes })),
                React.createElement("div", { className: styles('summary') },
                    React.createElement("div", { className: styles('notes') },
                        React.createElement("span", { className: styles('main') }, t(i18nKeys.NOTES)),
                        data.comment && React.createElement("span", { className: styles('secondary') }, data.comment)),
                    React.createElement("div", { className: styles('totals') },
                        React.createElement("div", null,
                            React.createElement("div", { className: styles('main') },
                                t(i18nKeys.TOTAL_NOT_VAT_PRICE),
                                " :",
                                React.createElement(Amount, { value: data.total_htva, suffix: data.currency })),
                            React.createElement("div", { className: styles('secondary') },
                                t(i18nKeys.VAT),
                                " :",
                                React.createElement(Amount, { value: data.total_tvac - data.total_htva, suffix: data.currency }))),
                        React.createElement("div", { className: styles('middle') },
                            React.createElement("div", { className: styles('main') },
                                t(i18nKeys.TOTAL_VAT_PRICE),
                                " :",
                                React.createElement(Amount, { value: data.total_tvac, suffix: data.currency })),
                            React.createElement("div", { className: styles('secondary') },
                                t(i18nKeys.INVOICING.ALREADY_PAID),
                                " :",
                                React.createElement(Amount, { value: data.already_paid_amount, suffix: data.currency }))),
                        React.createElement("div", { className: styles('total') },
                            React.createElement("div", { className: styles('main') },
                                t(i18nKeys.INVOICING.TOTAL_OUTSTANDING_PAYMENTS),
                                " :",
                                React.createElement(Amount, { value: remaining, suffix: data.currency })),
                            React.createElement("div", { className: styles('secondary') },
                                t(i18nKeys.DUE_DATE),
                                " :",
                                React.createElement(DateItem, { lg: currentLang, date: data.due_date, format: "DD/MM/YY" }))))),
                React.createElement(InvoiceFooter, null)),
            React.createElement(GradientBorder, { bottom: true })))))));
}
