import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import UserInfo from 'shared/components/UserInfo/UserInfo';
import { useLoadLightUsers } from 'shared/hooks';
import { Checkbox, CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';
import { CustomPreferencesFieldsName } from '../PreferencesForm.constants';
import { CustomDomainPartialForm } from './CustomDomainPartialForm';
import styleIdentifiers from '../PreferencesForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomPreferencesPartialForm = function () {
    var t = useTranslation().t;
    var _a = useFormContext(), register = _a.register, watch = _a.watch;
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var willSendCopy = watch(CustomPreferencesFieldsName.SEND_COPY);
    return (React.createElement("div", { className: styles('grid-row', 'custom-preferences-partial-form') },
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Checkbox, { register: register(CustomPreferencesFieldsName.SEND_COPY), watch: watch, label: React.createElement("span", null,
                    t(i18nKeys.FORM.PREFERENCES.RECEIVE_COPY),
                    ' ',
                    React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.PREFERENCES.TOOLTIP.RECEIVE_COPY) })) }),
            willSendCopy && (React.createElement(Controller, { name: CustomPreferencesFieldsName.SEND_COPY_EMAILS, render: function () { return (React.createElement(CustomSelect, { name: CustomPreferencesFieldsName.SEND_COPY_EMAILS, className: styles('send-copy-email'), multiple: true, noArrow: true, canAdd: true, itemRendering: function (props) { return React.createElement(UserInfo, __assign({}, props)); }, keyText: "email", keyValue: "email", items: users, label: t(i18nKeys.FORM.PREFERENCES.RECEIVE_ON), inputValidation: email, registerMultipleInput: register(CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT, {
                        validate: {
                            emailInputNotEmpty: function (value) { return !value; },
                        },
                    }) })); } }))),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Checkbox, { register: register(CustomPreferencesFieldsName.SEND_TO_ALL_EMAILS), watch: watch, label: React.createElement("span", null,
                    t(i18nKeys.FORM.PREFERENCES.SEND_TO_ALL_EMAILS),
                    ' ',
                    React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.PREFERENCES.TOOLTIP.SEND_TO_ALL_EMAILS) })) })),
        React.createElement(CustomDomainPartialForm, null)));
};
