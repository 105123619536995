import { styled } from '@stitches/react';
import React from 'react';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { Link } from 'react-router-dom';
import DateItem from 'shared/components/DateItem';
import { useTranslation } from 'locales';
var AddButton = styled('div', {
    display: 'inline-block',
    borderRadius: '100%',
    width: '24px',
    height: '24px',
    backgroundColor: '#0e5cad',
    transition: 'all 0.2s ease',
    padding: '6px 4px 4px 4px',
    color: 'white',
    marginLeft: '25px',
    cursor: 'pointer',
    '&:hover': {
        filter: 'brightness(1.2)',
    },
    variants: {
        disabled: {
            true: {
                pointerEvents: 'none',
                backgroundColor: '#ccc',
            },
        },
    },
});
var Input = styled('input', {
    borderBottom: '1px solid #24252A',
    fontSize: '12px',
});
export var InvoiceTableItem = function (_a) {
    var item = _a.item, amounts = _a.amounts, disableButtons = _a.disableButtons, handleSetAmount = _a.handleSetAmount;
    var currentLang = useTranslation().currentLang;
    var id = item.id, reference = item.reference, issueDate = item.issue_date, remainingBalance = item.remaining_balance, remainingFees = item.remaining_late_fees, currency = item.currency, _b = item.localized_money_object, remainingBalanceLocalized = _b.remaining_balance, remainingFeesLocalized = _b.remaining_late_fees;
    var amount = amounts[id];
    var isRemainingBalanceSaturated = Number(amount) >= remainingBalance;
    var isRemainingBalanceWithFeesSaturated = Number(amount) === Number(remainingBalance) + Number(remainingFees);
    return (React.createElement("tr", null,
        React.createElement("td", null),
        React.createElement("td", null,
            React.createElement(Link, { to: "/invoices/".concat(id), target: "_blank" }, reference)),
        React.createElement("td", null,
            React.createElement(DateItem, { date: issueDate, lg: currentLang, format: "DD/MM/YY" })),
        React.createElement("td", null,
            React.createElement("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                } },
                React.createElement(Amount, { style: { display: 'inline' }, localizedValue: remainingBalanceLocalized, value: remainingBalance, suffix: currency }),
                React.createElement(AddButton, { disabled: disableButtons || isRemainingBalanceSaturated, onClick: function () {
                        handleSetAmount(item.id, String(remainingBalance));
                    } },
                    React.createElement(Icon, { size: "12px", name: IconName.PLUS })))),
        React.createElement("td", null,
            React.createElement("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                } },
                React.createElement(Amount, { style: { display: 'inline' }, localizedValue: remainingFeesLocalized, value: Number(remainingBalance) + Number(remainingFees), suffix: currency }),
                React.createElement(AddButton, { disabled: disableButtons || isRemainingBalanceWithFeesSaturated, onClick: function () {
                        handleSetAmount(item.id, String(Number(remainingBalance) + Number(remainingFees)));
                    } },
                    React.createElement(Icon, { size: "12px", name: IconName.PLUS })))),
        React.createElement("td", null,
            React.createElement(Input, { type: "number", value: amount === '' ? amount : Number(amount), onChange: function (val) { return handleSetAmount(id, val.target.value); } }))));
};
