import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { IconName } from 'shared/components/Icon';
import { Integration, isEnabledIntegration, } from 'shared/components/Integrations';
import OnboardingItem from 'shared/components/OnboardingItem';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import Codabox from './Codabox/Codabox';
import Ponto from './Ponto/Ponto';
import BankAccountForm from './BankAccountForm';
import styleIdentifiers from './BankConnection.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankConnection(_a) {
    var onValidStep = _a.onValidStep, disableIntegration = _a.disableIntegration;
    var t = useTranslation().t;
    var callback = function () {
        dialogHide(DialogShowId.CUSTOM);
        accountActions.getCompany({
            callback: function () {
                onValidStep();
            },
            actionFailure: function (response) {
                onValidStep(response.data.next_step);
            },
        });
    };
    var submitCodabox = function (data) {
        onBoardingActions.codaboxToken({
            callback: callback,
            data: data,
        });
    };
    var submitPonto = function (data) {
        onBoardingActions.ponto({
            callback: callback,
            data: data,
        });
    };
    var submitBankAccount = function (values) {
        var data = __assign(__assign({}, values), { iban: values.iban.replace(/ /g, '') });
        onBoardingActions.bankAccount({
            callback: callback,
            data: data,
        });
    };
    var onChoosePonto = function () {
        if (!isEnabledIntegration(Integration.PONTO)) {
            return;
        }
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: 'Ponto',
            children: React.createElement(Ponto, { onSubmit: submitPonto }),
        });
    };
    var onChooseCodabox = function () {
        if (!isEnabledIntegration(Integration.CODABOX)) {
            return;
        }
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.ONBOARDING[2].CODABOX.TITLE),
            children: React.createElement(Codabox, { onSubmit: submitCodabox }),
        });
    };
    var onChooseNoBankConnection = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            keepMountOnExit: true,
            title: t(i18nKeys.ONBOARDING[2].PAYMENT_ACCOUNT),
            children: React.createElement(BankAccountForm, { onSubmit: submitBankAccount }),
        });
    };
    return (React.createElement("div", { className: styles('bank-connection', disableIntegration && 'center') },
        React.createElement("div", { className: styles('steps', disableIntegration && 'small') }, disableIntegration ? (React.createElement(BankAccountForm, { onSubmit: submitBankAccount })) : (React.createElement(React.Fragment, null,
            React.createElement(OnboardingItem, { title: t(i18nKeys.ONBOARDING[2].NO_BANK_CONNEXION), icon: IconName.BANK, onClick: onChooseNoBankConnection, paddingBottom: true }),
            isEnabledIntegration(Integration.PONTO) && (React.createElement(OnboardingItem, { title: "Ponto", icon: IconName.PONTO, onClick: onChoosePonto, paddingBottom: true })),
            isEnabledIntegration(Integration.CODABOX) && (React.createElement(OnboardingItem, { title: "Codabox", icon: IconName.CODABOX, onClick: onChooseCodabox, paddingBottom: true })))))));
}
