import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, CustomSelect, DateSelector, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { VarType } from '../CustomVariables.types';
import styleIdentifiers from './CustomVariablesForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CustomVariablesForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var availableCustomVariables = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, handleSubmit = form.handleSubmit, watch = form.watch, setValue = form.setValue, isDirty = form.formState.isDirty;
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var submit = function (values) {
        onSubmit(__assign({}, values));
    };
    var customVariables = watch('custom_variables');
    var accurateInput = function (customVarKey, varType) {
        switch (varType) {
            case VarType.BOOLEAN:
                return (React.createElement(Controller, { defaultValue: "", name: "custom_variables.".concat(customVarKey), render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], withBorder: true, name: "custom_variables.".concat(customVarKey), label: customVarKey.replaceAll('_', ' ') })); } }));
            case VarType.DATE:
                return (React.createElement(DateSelector, { name: "custom_variables.".concat(customVarKey), className: styles('input'), noMinDate: true, withBorder: true, placeholder: " ", label: customVarKey.replaceAll('_', ' '), handleChange: function (value) {
                        setValue("custom_variables.".concat(customVarKey), value);
                    } }));
            default:
                return (React.createElement(Input, { register: register("custom_variables.".concat(customVarKey)), withBorder: true, label: customVarKey.replaceAll('_', ' '), className: styles('input'), type: varType === VarType.NUMBER ? 'number' : 'text', step: "0.01", noMargin: true }));
        }
    };
    var renderInput = function (customVarKey) {
        var _a;
        var varType = (_a = availableCustomVariables.find(function (el) { return el.column_name === customVarKey; })) === null || _a === void 0 ? void 0 : _a.var_type;
        return (React.createElement("div", { key: customVarKey, className: styles('content') }, accurateInput(customVarKey, varType)));
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('custom-variables-form'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('wrapper') },
                React.createElement("div", { className: styles('head') }, t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE)),
                Object.keys(customVariables)
                    .sort(function (a, b) { return a.localeCompare(b); })
                    .map(function (customVarKey) { return renderInput(customVarKey); })),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.SAVE), type: "submit" })))));
}
