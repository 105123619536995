import React from 'react';
import { useUpdateReport } from 'shared/hooks';
import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { PeriodicityStep } from '../../CreateReport/steps';
export var EditReportRecurrenceModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, report = _a.report;
    var _b = useUpdateReport(), updateReport = _b.updateReport, isUpdateReportLoading = _b.isUpdateReportLoading;
    var daysWeek = report.periodicity.type === 'weekly' ? report.periodicity.days : [];
    var daysMonth = report.periodicity.type === 'monthly'
        ? report.periodicity.days.map(function (date) {
            var d = new Date('01-01-2024');
            d.setDate(date);
            return d;
        })
        : [];
    var form = useForm({
        initialValues: {
            periodicity: report.periodicity.type,
            daysWeek: daysWeek,
            daysMonth: daysMonth,
        },
    });
    var handleSave = function () {
        updateReport({
            id: report.id,
            periodicity: {
                type: form.values.periodicity,
                days: form.values.periodicity === 'weekly'
                    ? form.values.daysWeek
                    : form.values.daysMonth.map(function (date) { return date.getDate(); }),
            },
        }, {
            onSuccess: function () {
                onClose();
            },
        });
    };
    return (React.createElement(Modal, { opened: isOpen, onClose: onClose, title: "Edit name", size: "auto" },
        React.createElement("form", { onSubmit: function (e) {
                e.preventDefault();
                handleSave();
            } },
            React.createElement(PeriodicityStep, { form: form }),
            React.createElement(Group, { w: "100%", justify: "end", mt: "xl" },
                React.createElement(Button, { variant: "light", color: "orange", onClick: onClose }, "Cancel"),
                React.createElement(Button, { type: "submit", loading: isUpdateReportLoading }, "Save")))));
};
