import { __read, __spreadArray } from "tslib";
import React from 'react';
import { useCreateTaskType } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CustomSelect } from 'shared/io';
import { appConstants } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { TaskTypeForm } from '../TaskTypeForm/TaskTypeForm';
import styleIdentifiers from './TaskFormType.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskFormType = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'task_type_id' : _b, _c = _a.canAdd, canAdd = _c === void 0 ? true : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    var t = useTranslation().t;
    var task_types = useSelector(function (state) { return state.app.constants; }).task_types;
    var setValue = useFormContext().setValue;
    var dispatch = useDispatch();
    var createTaskType = useCreateTaskType().mutate;
    if (!canAdd) {
        return (React.createElement(Controller, { rules: { required: true }, name: name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: name, label: t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE), items: task_types, notAllowed: disabled, filter: true })); } }));
    }
    var onSubmitTaskType = function (data) {
        return createTaskType({ data: data }, {
            onSuccess: function (_a) {
                var newTaskType = _a.data;
                dispatch({
                    type: appConstants.constants.result,
                    status: 'fulfilled',
                    payload: {
                        task_types: __spreadArray(__spreadArray([], __read(task_types), false), [
                            {
                                description: newTaskType.attributes.name,
                                value: newTaskType.id,
                            },
                        ], false),
                    },
                });
                setValue(name, newTaskType.id);
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var addTaskType = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.ADD_TASK_TYPE),
            children: (React.createElement(TaskTypeForm, { onSubmit: onSubmitTaskType, onCancel: function () { return dialogHide(DialogShowId.CUSTOM); } })),
        });
    };
    return (React.createElement("div", { className: styles('field-type') },
        React.createElement(Controller, { rules: { required: true }, name: name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: name, label: t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE), items: task_types, notAllowed: disabled, filter: true })); } }),
        React.createElement(Button, { label: "+", title: t(i18nKeys.ADD_TASK_TYPE), onClick: addTaskType, disabled: disabled, noMargin: true })));
};
