import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ClientAddressForm from 'shared/action-component/EditClient/ClientForm/ClientAddressForm/ClientAddressForm';
import FormSection from 'shared/forms/FormSection';
import { Checkbox } from 'shared/io';
import styleIdentifiers from './ContactPersonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonFormAddress = function (_a) {
    var _b;
    var contactPerson = _a.contactPerson;
    var t = useTranslation().t;
    var form = useFormContext();
    var register = form.register, watch = form.watch, errors = form.formState.errors;
    return (React.createElement(FormSection, { title: t(i18nKeys.ADDRESS), startClosed: !((_b = contactPerson === null || contactPerson === void 0 ? void 0 : contactPerson.attributes) === null || _b === void 0 ? void 0 : _b.address) },
        React.createElement("div", { className: "col-12" },
            React.createElement(Checkbox, { label: t(i18nKeys.CONTACT_PERSON.USE_POSTAL_ADDRESS), errorMessage: get(errors, 'use_postal_address'), register: register('use_postal_address'), watch: watch })),
        React.createElement("div", { className: styles('address') },
            React.createElement(ClientAddressForm, { name: "address.attributes" }))));
};
