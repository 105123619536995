import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useCreateTask, useGetTasks, useUpdateTasksStatus } from 'api/index';
import { ExportButton } from 'app/Private/Clients/Listing/BatchActions/ExportButton';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import { Button } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { TaskActions } from '../TaskActions/TaskActions';
import { TaskForm } from '../TaskForm/TaskForm';
import { ExportTasksCsv } from './ExportTasksCsv';
import { TaskListItemActions } from './TasksListItemActions';
import styleIdentifiers from './TasksList.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomTasksList = function () {
    var _a;
    var t = useTranslation().t;
    var location = useLocation();
    var history = useHistory();
    var profile = useProfile();
    var _b = __read(useState([]), 2), selectedTasks = _b[0], setSelectedTasks = _b[1];
    var _c = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _c[0], setAreAllItemsAcrossPagesSelected = _c[1];
    var _d = __read(useState({
        view_id: location.hash.slice(1),
        page_limit: (_a = profile.preferences.itemsPerPage) !== null && _a !== void 0 ? _a : 20,
        page: 1,
    }), 2), params = _d[0], setParams = _d[1];
    var _e = useGetTasks(params), tasksListResponse = _e.data, isLoading = _e.isLoading, refetch = _e.refetch;
    var createTaskMutation = useCreateTask().mutate;
    var updateTasksStatus = useUpdateTasksStatus().mutate;
    useEffect(function () {
        setParams(function () {
            var _a;
            return (__assign({ view_id: location.hash.slice(1), page_limit: (_a = profile.preferences.itemsPerPage) !== null && _a !== void 0 ? _a : 20, page: 1 }, pick(Object.fromEntries(new URLSearchParams(location.search)), [
                'page',
                'page_limit',
                'sort_by',
                'sort_order',
                'title',
                'title_handler',
                'description',
                'description_handler',
                'status',
                'status_handler',
                'user_id',
                'user_id_handler',
                'debtors__full_name',
                'debtors__full_name_handler',
                'invoice_ids',
                'invoice_ids_handler',
                'mandatory',
                'mandatory_handler',
                'task_type_id',
                'task_type_id_handler',
                'recovery_plan_id',
                'recovery_plan_id_handler',
                'days_postponed',
                'min_days_postponed',
                'max_days_postponed',
                'days_postponed_handler',
                'created_at_start',
                'created_at_handler',
                'created_at_end',
                'due_date_start',
                'due_date_handler',
                'due_date_end',
                'updated_at_start',
                'updated_at_handler',
                'updated_at_end',
                'skipped_at_start',
                'skipped_at_handler',
                'skipped_at_end',
                'completed_at_start',
                'completed_at_handler',
                'completed_at_end',
                'quick_search',
            ])));
        });
    }, [location, setParams]);
    var addTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { onSubmit: function (data) {
                    return createTaskMutation({ data: data }, {
                        onSuccess: function () {
                            sideMenuHide();
                            refetch();
                        },
                    });
                } })),
        });
    };
    var batchActions = function (data) {
        return updateTasksStatus({
            data: data,
        }, {
            onSuccess: function (response) {
                if (Array.isArray(response)) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: response }),
                    });
                }
                else {
                    dialogHide(DialogShowId.CUSTOM);
                }
                refetch();
            },
        });
    };
    var handleFilters = function (data) {
        var filters = __assign(__assign({}, pick(params, ['page_limit', 'page'])), data);
        setParams(filters);
        return filters;
    };
    var taskIds = areAllItemsAcrossPagesSelected ? [] : selectedTasks.map(function (task) { return task.id; });
    var goToDetails = function (task) { return function () {
        history.push("/tasks/".concat(task.id));
    }; };
    var actionsCol = function (item) {
        return React.createElement(TaskListItemActions, { item: item, noCheckbox: true, refetch: refetch });
    };
    var handleClickExport = function () {
        sideMenuHide();
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.EXPORT_CSV),
            children: React.createElement(ExportTasksCsv, null),
        });
    };
    return (React.createElement("div", { className: styles('listing') },
        React.createElement(CustomTableViews, { title: t(i18nKeys.TASK.TITLE), callbackAction: refetch, onClickRow: goToDetails, actions: React.createElement(React.Fragment, null,
                React.createElement(ExportButton, { exportCsv: handleClickExport }),
                areAllItemsAcrossPagesSelected || !!selectedTasks.length ? (React.createElement("div", { className: styles('actions') },
                    React.createElement(TaskActions, { taskIds: taskIds, onClick: batchActions }))) : (React.createElement(Button, { className: styles('action-button'), noMargin: true, label: t(i18nKeys.NEW_TASK), onClick: addTask, iconRight: IconName.PLUS }))), isLoading: isLoading, pagination: tasksListResponse === null || tasksListResponse === void 0 ? void 0 : tasksListResponse.metadata.pagination, handleFilters: handleFilters, items: (tasksListResponse === null || tasksListResponse === void 0 ? void 0 : tasksListResponse.data) || [], onChangeSelectionCallback: function (vals) { return setSelectedTasks(vals); }, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, tableName: "tasks", actionsCol: actionsCol, loadDataGetView: false })));
};
