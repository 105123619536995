import accountReducer from './account/account.reducer';
import appReducer from './app/app.reducer';
import clientReducer from './client/client.reducer';
import colleagueReducer from './colleague/colleague.reducer';
import creditNoteReducer from './creditNote/creditnote.reducer';
import formReducer from './form/form.reducer';
import invoiceReducer from './invoice/invoice.reducer';
import onBoardingReducer from './onBoarding/onBoarding.reducer';
import productReducer from './product/product.reducer';
import settingsReducer from './settings/settings.reducer';
import thirdpartyCaseReducer from './thirdpartyCase/thirdpartycase.reducer';
import transactionReducer from './transaction/transaction.reducer';
import translateReducer from './translate/translate.reducer';
import viewReducer from './view/view.reducer';
export var account = accountReducer;
export var app = appReducer;
export var client = clientReducer;
export var colleague = colleagueReducer;
export var creditNote = creditNoteReducer;
export var invoice = invoiceReducer;
export var product = productReducer;
export var transaction = transactionReducer;
export var view = viewReducer;
export var translate = translateReducer;
export var onBoarding = onBoardingReducer;
export var form = formReducer;
export var settings = settingsReducer;
export var thirdpartyCase = thirdpartyCaseReducer;
