"use strict";
if (typeof Promise.withResolvers !== 'function') {
    Promise.withResolvers = function () {
        var resolve;
        var reject;
        var promise = new Promise(function (res, rej) {
            resolve = res;
            reject = rej;
        });
        return { promise: promise, resolve: resolve, reject: reject };
    };
}
if (typeof Promise.allSettled !== 'function') {
    Promise.allSettled = function (promises) {
        return Promise.all(promises.map(function (promise) {
            return Promise.resolve(promise).then(function (value) { return ({ status: 'fulfilled', value: value }); }, function (reason) { return ({ status: 'rejected', reason: reason }); });
        }));
    };
}
if (typeof Array.prototype.at !== 'function') {
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = function (index) {
        if (index < 0) {
            index = this.length + index;
        }
        return this[index];
    };
}
