import { __awaiter, __generator } from "tslib";
import React from 'react';
import { App } from 'app';
import BadCompany from 'app/Public/BadCompany';
import { MaintenancePage } from 'app/Public/MaintenancePage';
import { ServerError } from 'app/Public/ServerError';
import { TenantValidator } from 'app/TenantValidator';
import { Providers } from 'init/Providers';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Route, Switch } from 'react-router';
import { isDevelopment } from 'shared/utils/environment';
import rootSaga from 'store/root.sagas';
import setupStore, { browserHistory } from 'store/setupClientStore';
import { createClient } from '@vercel/edge-config';
import './init';
var MOUNT_NODE = document.getElementById('root');
export var storeSetup = setupStore;
var sagaTask = storeSetup.sagaRun(rootSaga);
var vercelEdgeConfigClient = createClient('https://edge-config.vercel.com/ecfg_jzw3wg3c82bicsstshb6aokonddq?token=7576ac8f-20c5-4133-8424-4b320d10f510', { cache: 'force-cache' });
var render = function () { return __awaiter(void 0, void 0, void 0, function () {
    var rootHtmlElement, root, isMaintenanceMode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                rootHtmlElement = document.getElementById('root');
                if (!rootHtmlElement)
                    return [2 /*return*/];
                root = createRoot(rootHtmlElement);
                return [4 /*yield*/, vercelEdgeConfigClient.get('isMaintenanceMode')];
            case 1:
                isMaintenanceMode = _a.sent();
                if (isMaintenanceMode) {
                    root.render(React.createElement(Providers, { store: storeSetup.store, history: browserHistory },
                        React.createElement(MaintenancePage, null)));
                    return [2 /*return*/];
                }
                root.render(React.createElement(Providers, { store: storeSetup.store, history: browserHistory },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/server-error", component: ServerError }),
                        React.createElement(Route, { exact: true, path: "/bad-company", component: BadCompany }),
                        React.createElement(TenantValidator, null,
                            React.createElement(App, null)))));
                return [2 /*return*/];
        }
    });
}); };
if (isDevelopment()) {
    if (module.hot) {
        module.hot.accept(['./init/Providers'], function () {
            return setImmediate(function () {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            });
        });
        module.hot.accept(['./styles/main.scss'], function () {
            return setImmediate(function () {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            });
        });
        module.hot.accept('./store/root.sagas', function () {
            var getNewSagas = require('./store/root.sagas').default;
            sagaTask.cancel();
            sagaTask.done.then(function () {
                sagaTask = storeSetup.sagaRun(function replacedSaga() {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getNewSagas()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            });
        });
    }
}
render();
