import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import ActivityDetail from 'shared/components/ActivityDetail';
import { EmailDeliveryStatusCompact } from 'shared/components/ActivityDetail/EmailDeliveryStatusCompact';
import HTML from 'shared/components/HTML/HTML';
import { dateFromNow } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimelineItemActivity = function (_a) {
    var activity = _a.activity;
    var currentLang = useTranslation().currentLang;
    var _b = activity.attributes, description = _b.description, author = _b.author, date = _b.date, email_statuses = _b.email_statuses;
    var openModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: React.createElement(ActivityDetail, { activity: activity.attributes }),
        });
    };
    return (React.createElement("div", { onClick: openModal },
        React.createElement("span", { className: styles('date') }, dateFromNow(date, currentLang)),
        React.createElement("div", { className: styles('from') }, author),
        React.createElement("div", { className: styles('message') },
            React.createElement(HTML, { html: description })),
        email_statuses != null && (React.createElement("div", { style: { marginTop: '10px' } },
            React.createElement(EmailDeliveryStatusCompact, { statuses: email_statuses })))));
};
