import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecalculateDatesModal from 'shared/components/RecalculateDatesModal';
import { Button, ButtonColor, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { adjustNextAmounts, adjustNextDates } from '../PaymentPlan.utils';
import { PaymentPlanFormWrapper } from '../PaymentPlanForm/PaymentPlanFormWrapper';
import { PaymentPlanProperties } from './PaymentPlanProperties';
import styleIdentifiers from '../PaymentPlanForm/PaymentPlanForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanEditFormSteps = function (_a) {
    var onSubmit = _a.onSubmit, paymentPlan = _a.paymentPlan, paymentSteps = _a.paymentSteps, onButtonClick = _a.onButtonClick, newProperties = _a.newProperties;
    var t = useTranslation().t;
    var _b = paymentPlan.attributes, invoices = _b.invoices, currency = _b.currency, total_amount = _b.total_amount;
    var _c = newProperties || {}, addedInvoiceIds = _c.addedInvoiceIds, newBalance = _c.newBalance;
    var invoiceIds = __spreadArray(__spreadArray([], __read(((invoices === null || invoices === void 0 ? void 0 : invoices.details.map(function (_a) {
        var id = _a.id;
        return id;
    })) || [])), false), __read((addedInvoiceIds || [])), false);
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            workflow_id: paymentPlan.attributes.workflow.id,
            invoice_ids: invoiceIds,
            payment_steps_attributes: paymentSteps,
        },
    });
    var control = form.control, errors = form.formState.errors, watch = form.watch, register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, reset = form.reset, getValues = form.getValues;
    var _d = useFieldArray({
        control: control,
        name: 'payment_steps_attributes',
    }), append = _d.append, fields = _d.fields, remove = _d.remove;
    useEffect(function () {
        reset(__assign(__assign({}, getValues), { invoice_ids: invoiceIds, payment_steps_attributes: paymentSteps, workflow_id: paymentPlan.attributes.workflow.id }));
    }, [paymentSteps, addedInvoiceIds, paymentPlan.attributes.workflow.id, getValues, reset]);
    var steps = watch('payment_steps_attributes') || [];
    var due_date = dayjs().toString();
    var stepsTotal = steps
        .filter(function (_a) {
        var _destroy = _a._destroy;
        return !_destroy;
    })
        .reduce(function (acc, _a) {
        var amount = _a.amount;
        return acc + Number(amount);
    }, 0);
    var amountsMatch = Number(newBalance || total_amount) === Math.round(stepsTotal * 100) / 100;
    var getMinDate = function (index) {
        var lastStep = steps[index - 1];
        if (isDefined(lastStep))
            return dayjs(lastStep.due_date).add(1, 'day').toDate();
        return dayjs(due_date).toDate();
    };
    register('invoice_ids');
    register('workflow_id');
    var removeStep = function (index) {
        var _a;
        if ((_a = steps[index]) === null || _a === void 0 ? void 0 : _a.id) {
            setValue("payment_steps_attributes.".concat(index, "._destroy"), true);
        }
        else {
            remove(index);
        }
    };
    var askRecalculation = function (index) {
        if (index === steps.length - 1) {
            return;
        }
        var _a = watch(), currentSteps = _a.payment_steps_attributes, rest = __rest(_a, ["payment_steps_attributes"]);
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            children: (React.createElement(RecalculateDatesModal, { text: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_DATES), onConfirm: function (newDelay) {
                    reset(__assign(__assign({}, rest), { payment_steps_attributes: adjustNextDates(currentSteps, index, newDelay) }), { keepTouched: true });
                } })),
        });
    };
    var handleAdjustNextAmounts = function (index) {
        var _a = watch(), currentSteps = _a.payment_steps_attributes, rest = __rest(_a, ["payment_steps_attributes"]);
        reset(__assign(__assign({}, rest), { payment_steps_attributes: adjustNextAmounts(currentSteps, index, Number(newBalance || total_amount || 0)) }), { keepTouched: true });
    };
    var displayedFields = steps.filter(function (step) { return !step._destroy; });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(PaymentPlanFormWrapper, { edit: true, actions: React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.MAIN, disabled: !amountsMatch, noMargin: true }) },
                React.createElement(PaymentPlanProperties, { paymentPlan: paymentPlan, addedInvoices: addedInvoiceIds, newBalance: newBalance }),
                React.createElement(Card, { className: styles('steps-details'), title: t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS), infosRight: React.createElement("div", { className: styles('button-wrapper') },
                        React.createElement(Button, { small: true, noMargin: true, onClick: function () {
                                return append({
                                    can_edit: true,
                                    amount: Math.round((Number(newBalance || total_amount) - Number(stepsTotal)) * 100) / 100,
                                    due_date: dayjs(steps[steps.length - 1].due_date)
                                        .add(31, 'day')
                                        .toISOString(),
                                });
                            }, label: t(i18nKeys.FORM.PAYMENT_PLAN.ADD_PAYMENT_STEP), noShadow: true }),
                        React.createElement(Button, { small: true, noMargin: true, onClick: onButtonClick, label: t(i18nKeys.ADD_INVOICES), color: ButtonColor.GREEN, noShadow: true })) },
                    React.createElement("div", { className: styles('headers', 'edit') },
                        React.createElement("div", { className: styles('header') }, t(i18nKeys.DATE)),
                        React.createElement("div", { className: styles('header') }, t(i18nKeys.AMOUNT_CURRENCY, {
                            text: currencySymbol(currency),
                        })),
                        React.createElement("div", { className: styles('header') },
                            t(i18nKeys.BALANCE),
                            " (",
                            currencySymbol(currency),
                            ")"),
                        React.createElement("div", { className: styles('header') }, " ")),
                    React.createElement("div", { className: styles('steps') }, fields.map(function (field, index) {
                        register("payment_steps_attributes.".concat(index, ".id"));
                        register("payment_steps_attributes.".concat(index, ".due_date"));
                        register("payment_steps_attributes.".concat(index, "._destroy"));
                        register("payment_steps_attributes.".concat(index, ".can_edit"));
                        register("payment_steps_attributes.".concat(index, ".remaining_balance"));
                        if (watch("payment_steps_attributes.".concat(index, "._destroy"))) {
                            return undefined;
                        }
                        var editable = watch("payment_steps_attributes.".concat(index, ".can_edit"));
                        return (React.createElement("div", { key: field.id, className: styles('step', 'edit') },
                            React.createElement(DateSelector, { required: true, name: "payment_steps_attributes.".concat(index, ".due_date"), className: styles('due-date-input'), minDate: getMinDate(index), noMargin: true, centered: true, withBorder: true, disabled: !editable, handleChange: function (value) {
                                    setValue("payment_steps_attributes.".concat(index, ".due_date"), value);
                                    askRecalculation(index);
                                } }),
                            React.createElement(Input, { className: styles('amount-input'), withBorder: true, disabled: !editable, register: register("payment_steps_attributes.".concat(index, ".amount"), {
                                    required: true,
                                    min: 0.01,
                                }), errorMessage: get(errors, "payment_steps_attributes.".concat(index, ".amount")), noMargin: true, type: "number", step: "0.01" }),
                            React.createElement(Input, { className: styles('amount-input', 'remaining-balance'), disabled: true, value: watch("payment_steps_attributes.".concat(index, ".remaining_balance")), noMargin: true, type: "number" }),
                            React.createElement("div", { className: styles('icons-wrapper') }, displayedFields.length > 1 && (React.createElement(React.Fragment, null,
                                index !== fields.length - 1 && editable ? (React.createElement(Icon, { title: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_AMOUNTS), name: IconName.MINIMAL_DOWN, onClick: function () { return handleAdjustNextAmounts(index); } })) : (React.createElement("span", { className: styles('empty-icon') })),
                                editable && (React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function () { return removeStep(index); } })))))));
                    })),
                    React.createElement("div", { className: styles('steps-footer', 'edit') },
                        React.createElement("div", { className: styles('total', amountsMatch ? ButtonColor.GREEN : ButtonColor.RED) },
                            React.createElement(Amount, { value: stepsTotal, suffix: currency }),
                            React.createElement(React.Fragment, null, amountsMatch ? ('') : (React.createElement(Amount, { value: Math.round((stepsTotal - Number(newBalance || total_amount)) * 100) / 100, suffix: currency })))))),
                React.createElement("div", { className: styles('bottom-actions') },
                    React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.MAIN, disabled: !amountsMatch, noMargin: true }))))));
};
