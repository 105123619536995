import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import styleIdentifiers from './WorkflowsList.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowsListItem = function (_a) {
    var planType = _a.planType, workflow = _a.workflow;
    var t = useTranslation().t;
    var history = useHistory();
    var id = workflow.id, _b = workflow.attributes, name = _b.name, steps_count = _b.steps_count, payment_plans_using = _b.payment_plans_using, isDefault = _b.default;
    var updateWorkflow = function () {
        history.push("/settings/workflows/".concat(planType, "/edit/").concat(id));
    };
    return (React.createElement("div", { className: styles('workflows-list-item', 'card'), onClick: function () { return updateWorkflow(); } },
        React.createElement("div", { className: styles('title') },
            React.createElement("h4", { title: name }, name),
            isDefault && React.createElement("div", { className: styles('label', 'badge') }, t(i18nKeys.DEFAULT))),
        React.createElement("div", { className: styles('content') },
            React.createElement("div", null,
                t(i18nKeys.N_STEP, { count: steps_count }),
                " "),
            payment_plans_using === 0 ? (React.createElement("div", null,
                " ",
                t(i18nKeys.UNUSED))) : (React.createElement("div", null,
                payment_plans_using,
                ' ',
                React.createElement(Icon, { title: t(i18nKeys.PLAN.INVOICES_NUMBER_INCLUDED), name: IconName.INVOICE }),
                ' ')))));
};
