import { __assign, __read } from "tslib";
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { first, head } from 'lodash-es';
import qs from 'query-string';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import SlideDown from 'react-slidedown';
import BankAccount from 'shared/components/BankAccount';
import { Icon, IconName } from 'shared/components/Icon';
import { InfiniteScroll } from 'shared/components/InfiniteScroll';
import { Button, ButtonColor, CustomSelect } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { reducerState, updateQueryFilters } from 'shared/utils/view';
import { transactionActions } from 'store/transaction/transaction.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ManualReconciliationModal } from './ManualReconciliationModal';
import ReconciliationInvoiceFilter from './ReconciliationInvoiceFilter';
import ReconciliationItem from './ReconciliationItem';
import ReconciliationMobileModal from './ReconciliationMobileModal';
import ReconciliationPaymentFilter from './ReconciliationPaymentFilter';
import styleIdentifiers from './reconciliation.scss';
var styles = classNames.bind(styleIdentifiers);
function hasLateFees(invoice) {
    if (invoice == null)
        return false;
    // ! Caution: remaining_late_fees is incorrectly typed as `number`, it is actually a `string`
    return Number(invoice.remaining_late_fees) !== 0;
}
function shouldTriggerModal(selectedInvoices) {
    if (selectedInvoices.length > 1)
        return true;
    if (selectedInvoices.length === 1 && hasLateFees(first(selectedInvoices)))
        return true;
    return false;
}
export default function Reconciliation() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _j = useSelector(function (state) { return state.transaction.reconciliation; }), invoices = _j.invoices, selectedInvoices = _j.selectedInvoices, selectedPayments = _j.selectedPayments, payments = _j.payments, isLoading = _j.loading;
    var location = useLocation();
    var _k = __read(useReducer(reducerState, {
        filters: { invoice: {}, payment: {} },
        paymentFilterOpen: false,
        invoiceFilterOpen: false,
        selectAll: false,
    }), 2), state = _k[0], setState = _k[1];
    var _l = __read(useState(false), 2), isInInvoiceTable = _l[0], setIsInInvoiceTable = _l[1];
    var paymentForm = useForm({
        shouldUnregister: true,
        defaultValues: state.filters.payment,
    });
    var invoiceForm = useForm({
        shouldUnregister: true,
        defaultValues: state.filters.payment,
    });
    var invoiceData = paymentForm.watch();
    var bank_account_ids = invoiceData.bank_account_ids;
    var selectedPaymentCurrency = (_a = head(selectedPayments)) === null || _a === void 0 ? void 0 : _a.currency;
    var selectedInvoiceCurrency = (_b = head(selectedInvoices)) === null || _b === void 0 ? void 0 : _b.currency;
    var selectedCurrency = selectedPaymentCurrency || selectedInvoiceCurrency;
    var loadInvoices = function (reset) {
        var _a;
        if (reset === void 0) { reset = false; }
        transactionActions.getReconciliation({
            noLoading: true,
            reset: reset,
            data: __assign(__assign({}, state.filters.invoice), { payment_id: reset && selectedInvoices.length === 0 && selectedPayments.length === 1
                    ? selectedPayments[0].id
                    : undefined, page: reset ? 1 : invoices ? invoices.metadata.pagination.current_page + 1 : 1, ignore_invoice_ids: reset
                    ? undefined
                    : (_a = invoices === null || invoices === void 0 ? void 0 : invoices.data) === null || _a === void 0 ? void 0 : _a.filter(function (invoice) { return invoice.suggested_invoice; }).map(function (invoice) { return invoice.id; }) }),
        });
    };
    var loadPayments = function (reset, newBankAccountIds) {
        if (reset === void 0) { reset = false; }
        transactionActions.getReconciliationPayments({
            noLoading: true,
            reset: reset,
            data: __assign(__assign({ reconciliation: true }, state.filters.payment), { bank_account_ids: newBankAccountIds || bank_account_ids, page: reset ? 1 : payments ? payments.metadata.pagination.current_page + 1 : 1 }),
        });
    };
    var updateFilters = function (type) { return function (editFilters) {
        var newFilters = __assign({}, state.filters);
        if (type === 'payment') {
            newFilters[type] = __assign({}, editFilters);
        }
        else {
            newFilters[type] = editFilters;
        }
        setState({ filters: newFilters });
        var queryFilters = {};
        for (var key in newFilters.invoice) {
            queryFilters["invoice_".concat(key)] = newFilters.invoice[key] || undefined;
        }
        for (var key in newFilters.payment) {
            queryFilters["payment_".concat(key)] = newFilters.payment[key] || undefined;
        }
        updateQueryFilters(queryFilters);
    }; };
    useEffect(function () {
        loadInvoices(true);
    }, [state.filters.invoice]);
    useEffect(function () {
        loadPayments(true);
    }, [state.filters.payment]);
    useEffect(function () {
        paymentForm.register('start_date');
        paymentForm.register('end_date');
        var filters = qs.parse(location.search);
        var newFilters = {
            invoice: {},
            payment: {},
        };
        for (var key in filters) {
            if (key.indexOf('invoice_') !== -1) {
                newFilters.invoice[key.substring(8)] = filters[key];
            }
            else if (key.indexOf('payment_') !== -1) {
                newFilters.payment[key.substring(8)] = filters[key];
            }
        }
        for (var key in newFilters.payment) {
            paymentForm.setValue(key, newFilters.payment[key]);
        }
        setState({
            filters: newFilters,
        });
        return function () {
            paymentForm.unregister('start_date');
            paymentForm.unregister('end_date');
        };
    }, []);
    useEffect(function () {
        if (selectedPayments.length <= 2 && selectedInvoices.length === 0) {
            loadInvoices(true);
        }
    }, [selectedInvoices.length, selectedPayments.length]);
    var selectItem = function (item) {
        transactionActions.selectReconciliation(item);
    };
    var changeOrder = function (field, type) {
        updateFilters(type)(__assign(__assign({}, state.filters[type]), { sort_by: field, sort_order: state.filters[type] &&
                state.filters[type].sort_by === field &&
                state.filters[type].sort_order === 'asc'
                ? 'desc'
                : 'asc' }));
    };
    var ignorePayments = function () {
        var query = state.selectAll ? __assign({}, state.filters.payment) : {};
        transactionActions.ignorePayments({
            data: __assign({ payment_ids: selectedPayments.map(function (payment) { return payment.id; }), reconciliation: true }, query),
            callback: function () { return loadPayments(true); },
        });
    };
    var handleReconciliation = function () {
        if (shouldTriggerModal(selectedInvoices)) {
            showDialog({
                id: DialogShowId.CUSTOM,
                title: t(i18nKeys.FORM.PREFERENCES.RECONCILIATION),
                children: (React.createElement(ManualReconciliationModal, { invoices: selectedInvoices, payments: selectedPayments, onSuccess: function () {
                        transactionActions.resetReconciliationSelection();
                        dialogHide(DialogShowId.CUSTOM);
                        loadInvoices(true);
                        loadPayments(true);
                    } })),
            });
        }
        else {
            transactionActions.manualReconciliation({
                data: {
                    invoices: selectedInvoices.map(function (arg) {
                        return {
                            id: arg.id,
                            amount: Number(arg.remaining_balance),
                        };
                    }),
                    payment_ids: selectedPayments.map(function (payment) { return payment.id; }),
                },
                callback: function () {
                    dialogHide(DialogShowId.CONFIRM);
                    loadInvoices(true);
                    loadPayments(true);
                },
            });
        }
    };
    var resetPaymentForm = function () {
        paymentForm.reset({
            start_date: '',
            end_date: '',
            reconciliation_detail: '',
            operator: '',
            amount: '',
        });
    };
    var resetInvoicesForm = function () {
        invoiceForm.setValue('issue_date_before', '');
        invoiceForm.setValue('issue_date_after', '');
        invoiceForm.setValue('reconciliation_detail', '');
        invoiceForm.setValue('remaining_balance', '');
        invoiceForm.setValue('remaining_balance_operator', '');
    };
    var resetFilters = function (type) { return function () {
        if (type === 'invoice')
            resetInvoicesForm();
        else
            resetPaymentForm();
        updateFilters(type)({});
    }; };
    var selectAll = function () {
        setState(__assign(__assign({}, state), { selectAll: true }));
        transactionActions.resetReconciliationSelection();
    };
    var unselectAll = function () {
        setState(__assign(__assign({}, state), { selectAll: false }));
    };
    var openMobileReconciliation = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.FULL,
            title: t(i18nKeys.NAV.RECONCILIATION),
            children: (React.createElement(ReconciliationMobileModal, { selectedInvoices: selectedInvoices, selectedPayments: selectedPayments, submit: handleReconciliation })),
        });
    };
    var renderHeadItem = function (label, className, field, type) { return (React.createElement("th", { className: styles('sortable-head', className), onClick: function () { return changeOrder(field, type); } },
        React.createElement("span", null, label),
        state.filters[type] && state.filters[type].sort_by === field && (React.createElement(Icon, { name: IconName.TAILDOWN, className: styles('arrow', state.filters[type].sort_order === 'desc' && 'inverted'), size: "15px" })))); };
    var bankAccount = function (_a) {
        var item = _a.item;
        return (React.createElement("div", { className: styles('bank-account', (bank_account_ids === null || bank_account_ids === void 0 ? void 0 : bank_account_ids.indexOf(item.id)) > -1 && 'selected') },
            React.createElement(BankAccount, { value: item.iban, small: true, image: item.bic }),
            (bank_account_ids === null || bank_account_ids === void 0 ? void 0 : bank_account_ids.indexOf(item.id)) > -1 && (React.createElement(Icon, { name: IconName.CHECK, className: styles('check-icon'), size: "20px" }))));
    };
    var invoiceFilterActiveNumber = 0;
    var paymentFilterActiveNumber = 0;
    var invoiceValues = state.filters.invoice;
    if (invoiceValues.issue_date_after || invoiceValues.issue_date_before) {
        invoiceFilterActiveNumber++;
    }
    if (invoiceValues.reconciliation_detail) {
        invoiceFilterActiveNumber++;
    }
    if (invoiceValues.remaining_balance) {
        invoiceFilterActiveNumber++;
    }
    var paymentValues = paymentForm.getValues();
    if (paymentValues.start_date || paymentValues.end_date) {
        paymentFilterActiveNumber++;
    }
    if (paymentValues.reconciliation_detail) {
        paymentFilterActiveNumber++;
    }
    if (paymentValues.amount) {
        paymentFilterActiveNumber++;
    }
    var paymentsNumber = (_c = payments === null || payments === void 0 ? void 0 : payments.data) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, _payments) { return (_payments.inactive ? acc : acc + 1); }, 0);
    var invoiceNumber = (_d = invoices === null || invoices === void 0 ? void 0 : invoices.data) === null || _d === void 0 ? void 0 : _d.reduce(function (acc, invoice) { return (invoice.inactive ? acc : acc + 1); }, 0);
    var totalSelectedInvoices = useMemo(function () {
        return selectedInvoices.reduce(function (acc, invoice) { return acc + +invoice.localized_money_object.remaining_balance_with_fees; }, 0);
    }, [selectedInvoices.length]);
    var totalSelectedPayments = useMemo(function () {
        return selectedPayments.reduce(function (acc, payment) { return acc + +payment.localized_money_object.remaining_balance; }, 0);
    }, [selectedPayments.length]);
    return (React.createElement("div", { className: styles('Reconciliation', isInInvoiceTable ? 'invoices' : 'payments') },
        React.createElement("div", { className: styles('head') },
            React.createElement("div", { className: styles('title') },
                React.createElement("h1", null, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.MANUAL_RECONCILIATION))),
            React.createElement("div", null,
                React.createElement(Button, { className: styles('variable'), label: t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.IGNORE_PAYMENTS), noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE, disabled: !state.selectAll && (selectedPayments.length === 0 || selectedInvoices.length > 0), onClick: ignorePayments }),
                React.createElement(Button, { className: styles('variable', 'reconciliation-button'), label: t(i18nKeys.RECONCILE), noMargin: true, color: ButtonColor.BLUE, iconRight: IconName.MINIMAL_RIGHT, isLoading: isLoading, disabled: !(selectedInvoices.length > 0 && selectedPayments.length > 0), onClick: handleReconciliation }),
                React.createElement(Button, { className: styles('variable', 'choosed-payments-button'), label: t(i18nKeys.INVOICING.SEE_INVOICES), noMargin: true, color: ButtonColor.BLUE, iconRight: IconName.MINIMAL_RIGHT, onClick: function () { return setIsInInvoiceTable(true); } }),
                React.createElement(Button, { className: styles('variable', 'back-button'), label: t(i18nKeys.BACK), noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.MINIMAL_LEFT, onClick: function () { return setIsInInvoiceTable(false); } }),
                React.createElement(Button, { className: styles('variable', 'mobile-reconciliation-button'), label: "Voir r\u00E9conciliation", noMargin: true, color: ButtonColor.GREEN, disabled: !(selectedInvoices.length > 0 && selectedPayments.length > 0), onClick: openMobileReconciliation }))),
        React.createElement("div", { className: styles('tables-wrapper') },
            React.createElement("div", { className: styles('table-content', 'card') },
                React.createElement("div", { className: styles('header') },
                    React.createElement(FormProvider, __assign({}, paymentForm),
                        React.createElement(Controller, { defaultValue: "", name: "bank_account_ids", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('bank-account-select'), valueClassName: styles('bank-account-select-value'), itemsClassName: styles('list-bank-account'), removeAll: t('BANK_ACCOUNT.RECONCILIATION.ALL_ENTRY_PAYMENT'), onValueChanged: function (value) {
                                    loadPayments(true, value);
                                    return value;
                                }, noMargin: true, noBorder: true, multiple: true, noTags: true, valueRendering: function (item) {
                                    return item &&
                                        (item.value.length === 0
                                            ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.ALL_ENTRY_PAYMENT)
                                            : item.value.length > 1
                                                ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SEVERAL_ACCOUNT_SELECTED)
                                                : bankAccount(item.value[0]));
                                }, itemRendering: bankAccount, name: "bank_account_ids", keyText: "iban", keyValue: "id", items: company.bank_accounts_attributes })); } })),
                    React.createElement("div", { className: styles('filter-container') },
                        paymentFilterActiveNumber > 0 && (React.createElement(Button, { onClick: resetFilters('payment'), color: ButtonColor.WHITE, small: true, noMargin: true, className: styles('filter-button') },
                            React.createElement(Icon, { name: IconName.FILTER }),
                            React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                        React.createElement(Button, { onClick: function () {
                                setState({ paymentFilterOpen: !state.paymentFilterOpen });
                            }, color: ButtonColor.WHITE, iconLeft: IconName.FILTER, className: styles('filter-button'), small: true, noMargin: true },
                            paymentFilterActiveNumber > 0 && (React.createElement("span", { className: styles('text-circle', 'absolute') }, paymentFilterActiveNumber)),
                            t(i18nKeys.FILTER)))),
                React.createElement("div", { className: styles('table-filter') },
                    React.createElement(FormProvider, __assign({}, paymentForm),
                        React.createElement(SlideDown, { className: styles('react-slidedown') }, state.paymentFilterOpen ? (React.createElement(ReconciliationPaymentFilter, { onSubmit: updateFilters('payment') })) : null))),
                ((_e = payments === null || payments === void 0 ? void 0 : payments.data) === null || _e === void 0 ? void 0 : _e.length) > 0 ? (React.createElement(InfiniteScroll, { className: styles('table-wrapper'), load: loadPayments, hasMore: payments.metadata.pagination.last_page !== payments.metadata.pagination.current_page, items: payments.data, loading: payments.loading },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                renderHeadItem(t(i18nKeys.DATE), 'date', 'value_date', 'payment'),
                                renderHeadItem(t(i18nKeys.DETAILS), 'details', 'counterparty_name', 'payment'),
                                renderHeadItem(t(i18nKeys.AMOUNT), 'amount', 'remaining_balance', 'payment'))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null, (selectedPayments.length > 0 || state.selectAll) && (React.createElement("td", { colSpan: 3, className: styles('select-all') },
                                state.selectAll &&
                                    selectedPayments.length <= 0 &&
                                    t(payments.metadata.pagination.total_objects === 1
                                        ? i18nKeys.SELECTED_ELEMENT
                                        : i18nKeys.SELECTED_ELEMENTS, {
                                        count: payments.metadata.pagination.total_objects,
                                    }),
                                React.createElement("span", { onClick: state.selectAll ? unselectAll : selectAll }, t(state.selectAll ? i18nKeys.FORM.DESELECT_ALL : i18nKeys.FORM.SELECT_ALL))))),
                            payments.data.map(function (payment) { return (React.createElement(ReconciliationItem, { overrideSelection: state.selectAll, disabled: selectedCurrency != null && selectedCurrency !== payment.currency, item: payment, key: payment.id, action: selectItem })); }))))) : (React.createElement("div", { className: styles('no-result') }, t(i18nKeys.NO_RESULT))),
                React.createElement("div", { className: styles('footer') },
                    (payments === null || payments === void 0 ? void 0 : payments.loaded) && ((_f = payments === null || payments === void 0 ? void 0 : payments.data) === null || _f === void 0 ? void 0 : _f.length) > 0 && (React.createElement("div", null,
                        "1 -",
                        ' ',
                        paymentsNumber >= payments.metadata.pagination.total_objects
                            ? paymentsNumber
                            : payments.metadata.pagination.total_objects,
                        ' ',
                        t(i18nKeys.OF),
                        " ",
                        payments.metadata.pagination.total_objects)),
                    totalSelectedPayments > 0 && (React.createElement("span", null,
                        React.createElement(AnimateNumber, { duration: 400, value: totalSelectedPayments, formatValue: function (value) {
                                return "".concat(formatAmount(value, ',', '.', 2, currencySymbol(undefined, company)));
                            } }))))),
            React.createElement("div", { className: styles('table-content', 'card') },
                React.createElement("div", { className: styles('header') },
                    React.createElement("h3", null, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.UNPAID_INVOICES)),
                    React.createElement("div", { className: styles('filter-container') },
                        invoiceFilterActiveNumber > 0 && (React.createElement(Button, { onClick: resetFilters('invoice'), color: ButtonColor.WHITE, small: true, noMargin: true, className: styles('filter-button') },
                            React.createElement(Icon, { name: IconName.FILTER }),
                            React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                        React.createElement(Button, { onClick: function () {
                                setState({ invoiceFilterOpen: !state.invoiceFilterOpen });
                            }, color: ButtonColor.WHITE, iconLeft: IconName.FILTER, className: styles('filter-button'), small: true, noMargin: true },
                            invoiceFilterActiveNumber > 0 && (React.createElement("span", { className: styles('text-circle', 'absolute') }, invoiceFilterActiveNumber)),
                            t(i18nKeys.FILTER)))),
                React.createElement("div", { className: styles('table-filter') },
                    React.createElement(FormProvider, __assign({}, invoiceForm),
                        React.createElement(SlideDown, { className: styles('react-slidedown') }, state.invoiceFilterOpen ? (React.createElement(ReconciliationInvoiceFilter, { onSubmit: updateFilters('invoice') })) : null))),
                ((_g = invoices === null || invoices === void 0 ? void 0 : invoices.data) === null || _g === void 0 ? void 0 : _g.length) > 0 ? (React.createElement(InfiniteScroll, { className: styles('table-wrapper'), load: loadInvoices, hasMore: invoices.metadata.pagination.last_page !== invoices.metadata.pagination.current_page, items: invoices.data, loading: invoices.loading },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                renderHeadItem(t(i18nKeys.DATE), 'date', 'issue_date', 'invoice'),
                                renderHeadItem(t(i18nKeys.DETAILS), 'details', 'debtor_name', 'invoice'),
                                renderHeadItem(t(i18nKeys.BALANCE), 'amount', 'remaining_balance', 'invoice'))),
                        React.createElement("tbody", null, invoices.data.map(function (invoice) { return (React.createElement(ReconciliationItem, { item: invoice, disabled: selectedCurrency != null && selectedCurrency !== invoice.currency, key: invoice.id, action: selectItem })); }))))) : (React.createElement("div", { className: styles('no-result') }, t(i18nKeys.NO_RESULT))),
                React.createElement("div", { className: styles('footer') },
                    totalSelectedInvoices > 0 && (React.createElement("span", null,
                        React.createElement(AnimateNumber, { duration: 400, value: totalSelectedInvoices, formatValue: function (value) {
                                return "".concat(formatAmount(value, ',', '.', 2, currencySymbol(undefined, company)));
                            } }),
                        ' ',
                        React.createElement("span", { className: styles('balance') },
                            totalSelectedInvoices - totalSelectedPayments > 0 && '+',
                            formatAmount(totalSelectedInvoices - totalSelectedPayments, ',', '.', 2, currencySymbol(undefined, company))))),
                    (invoices === null || invoices === void 0 ? void 0 : invoices.loaded) && ((_h = invoices === null || invoices === void 0 ? void 0 : invoices.data) === null || _h === void 0 ? void 0 : _h.length) > 0 && (React.createElement("div", null,
                        "1 - ",
                        invoiceNumber,
                        " ",
                        t(i18nKeys.OF),
                        ' ',
                        invoiceNumber > invoices.metadata.pagination.total_objects
                            ? invoiceNumber
                            : invoices.metadata.pagination.total_objects)))))));
}
