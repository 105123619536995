import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { ModalFooter } from 'shared/components';
import SunEditor from 'suneditor-react';
import { Modal } from '@mantine/core';
var SUN_EDITOR_OPTIONS = {
    attributesWhitelist: { all: 'style' },
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'fontColor',
            'hiliteColor',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'lineHeight',
            'link',
            'image',
        ],
    ],
};
export default function SignatureEditorModal(_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSave = _a.onSave, signature = _a.signature;
    var _b = __read(useState(signature), 2), htmlContent = _b[0], setHtmlContent = _b[1];
    useEffect(function () {
        setHtmlContent(signature);
    }, [signature]);
    var handleCancel = function () {
        setHtmlContent(signature);
        onClose();
    };
    var handleSave = function () {
        onSave(htmlContent !== null && htmlContent !== void 0 ? htmlContent : null);
        onClose();
    };
    return (React.createElement(Modal, { opened: isOpen, onClose: onClose, title: "Edit signature", size: "845px" },
        React.createElement(SunEditor, { height: "200px", setContents: htmlContent !== null && htmlContent !== void 0 ? htmlContent : undefined, onChange: setHtmlContent, setOptions: __assign(__assign({}, SUN_EDITOR_OPTIONS), { defaultStyle: 'font-family: Verdana; font-size: 16px;' }) }),
        React.createElement(ModalFooter, { onCancel: handleCancel, onSave: handleSave })));
}
