import React from 'react';
import { treatRecoveryPlan } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import MoveRecoveryPlanInvoicesModal from './MoveRecoveryPlanInvoicesModal';
export default function MoveRecoveryPlanInvoices(_a) {
    var title = _a.title, allStepRequired = _a.allStepRequired, newPlan = _a.newPlan, newPlanId = _a.newPlanId, oldPlan = _a.oldPlan, oldPlanId = _a.oldPlanId, onSubmit = _a.onSubmit, currentStep = _a.currentStep, debtor_id = _a.debtor_id, companyDefault = _a.companyDefault;
    var initialValues = {
        oldPlan: oldPlan,
        newPlan: newPlan,
    };
    var showModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.FULL,
            title: title,
            children: (React.createElement(MoveRecoveryPlanInvoicesModal, { allStepRequired: allStepRequired, currentStep: currentStep === -1 ? initialValues.oldPlan.recovery_steps.length - 1 : currentStep, newPlan: initialValues.newPlan, oldPlan: initialValues.oldPlan, onSubmit: onSubmit })),
        });
    };
    if (initialValues.oldPlan && initialValues.newPlan) {
        showModal();
    }
    else {
        if (!initialValues.oldPlan) {
            settingsActions.getRecoveryPlan({
                id: oldPlanId,
                data: {
                    debtor_id: debtor_id,
                    companyDefault: companyDefault,
                },
                callback: function (_a) {
                    var data = _a.data;
                    initialValues.oldPlan = treatRecoveryPlan(data);
                    if (initialValues.newPlan) {
                        showModal();
                    }
                },
            });
        }
        if (!initialValues.newPlan) {
            settingsActions.getRecoveryPlan({
                id: newPlanId,
                data: {
                    companyDefault: companyDefault,
                },
                callback: function (_a) {
                    var data = _a.data;
                    initialValues.newPlan = treatRecoveryPlan(data);
                    if (initialValues.oldPlan) {
                        showModal();
                    }
                },
            });
        }
    }
}
