import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { pathOr } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { ThirdPartyType } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import styleIdentifiers from './selectThirdPartyForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SelectThirdPartyForm(_a) {
    var onSubmit = _a.onSubmit, onSubmitGcollect = _a.onSubmitGcollect, selectedThirdPartyType = _a.selectedThirdPartyType, invoice = _a.invoice, editClient = _a.editClient, thirdParties = _a.thirdParties;
    var t = useTranslation().t;
    var isLoading = useSelector(function (state) { return state.invoice.isLoading; });
    var form = useForm({ shouldUnregister: true });
    var watch = form.watch;
    var availableCompanies = thirdParties[selectedThirdPartyType].availableCompanies;
    var selectedThirdPartyCompanyId = watch('third_party_company_id');
    var selectedThirdPartyCompany = availableCompanies.find(function (company) { return company.id === selectedThirdPartyCompanyId; });
    // @ts-ignore bad type inference on the isGcollect property
    var isGcollect = pathOr(selectedThirdPartyCompany, ['isGcollect'], false);
    var hasPhone = selectedThirdPartyType === ThirdPartyType.CALL_CENTER && invoice
        ? invoice.debtor_attributes.phones_attributes.length > 0
        : true;
    return (React.createElement(React.Fragment, null, selectedThirdPartyType === ThirdPartyType.CALL_CENTER && !hasPhone ? (React.createElement("div", { className: styles('callcenter-form') },
        React.createElement("div", null, t(i18nKeys.FORM.CLIENT.CALLCENTER_PHONE_REQUIRED)),
        React.createElement(Button, { onClick: editClient, label: t(i18nKeys.FORM.CLIENT.EDIT_RECORD) }))) : (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('select-third-party-form'), onSubmit: form.handleSubmit(isGcollect ? onSubmitGcollect : onSubmit) },
            React.createElement("div", { className: styles('wrapper') },
                selectedThirdPartyType === ThirdPartyType.BAILIFF && (React.createElement("div", { className: styles('description') },
                    React.createElement(HTML, { html: t(i18nKeys.FORM.CONFIRM_BAILIFF.DESCRIPTION) }))),
                React.createElement("div", { className: styles('content') },
                    React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "third_party_company_id", render: function () { return (React.createElement(CustomSelect, { items: thirdParties[selectedThirdPartyType].availableCompanies, name: "third_party_company_id", placeholder: t(i18nKeys.SELECT), keyText: "name", keyValue: "id", className: styles('input') })); } })),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.CONFIRM), type: "submit", className: styles('button') }))))))));
}
