import { __read } from "tslib";
import React, { useState } from 'react';
import { useDeleteAutomaticAssignation, useUpdateAutomaticAssignation } from 'api';
import { AssignableModel, } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';
import { AssignationConditions } from './AssignationConditions';
import styleIdentifiers from './AutomaticAssignationsListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export var AutomaticAssignationListItem = function (_a) {
    var item = _a.item, refetch = _a.refetch, metadata = _a.metadata, availableConditions = _a.availableConditions;
    var t = useTranslation().t;
    var history = useHistory();
    var _b = __read(useState(false), 2), hover = _b[0], setHover = _b[1];
    var id = item.id, _c = item.attributes, model_to_assign = _c.model_to_assign, assign_to = _c.assign_to, order = _c.order, assignation_conditions = _c.assignation_conditions;
    var deleteAutomaticAssignationMutation = useDeleteAutomaticAssignation().mutate;
    var updateAutomaticAssignationMutation = useUpdateAutomaticAssignation().mutate;
    var updateAutomaticAssignation = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(AutomaticAssignationForm, { availableConditions: availableConditions, automaticAssignation: item, itemsTotal: metadata.total, onSubmit: function (data) {
                    return updateAutomaticAssignationMutation({ id: id, data: data }, {
                        onSuccess: function () {
                            sideMenuHide();
                            refetch();
                        },
                    });
                } })),
        });
    };
    var deleteAutomaticAssignation = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.AUTOMATIC_ASSIGNATION_DELETE), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteAutomaticAssignationMutation({ id: id }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    var showAutomaticAssignation = function () {
        history.push("/settings/automatic-assignation/".concat(item.id));
    };
    return (React.createElement("tr", { className: styles('automatic-assignation-list-item', hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, key: id },
        React.createElement("td", null),
        React.createElement("td", { onClick: showAutomaticAssignation }, t(i18nKeys.COMMON[model_to_assign === AssignableModel.invoice
            ? AssignableModel.invoice.toUpperCase()
            : 'CLIENT'])),
        React.createElement("td", { onClick: showAutomaticAssignation }, assign_to.name),
        React.createElement("td", { onClick: showAutomaticAssignation },
            React.createElement(AssignationConditions, { items: assignation_conditions, availableConditions: availableConditions })),
        React.createElement("td", { onClick: showAutomaticAssignation }, order),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: updateAutomaticAssignation },
                React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: deleteAutomaticAssignation },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
};
