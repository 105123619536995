import React from 'react';
import classNames from 'classnames/bind';
import CollaboratorPicture from '../UserAvatar';
import styleIdentifiers from './UserInfo.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UserInfo(_a) {
    var className = _a.className, text = _a.text, inline = _a.inline, item = _a.item, picture = _a.picture;
    if (item == null && text == null)
        return null;
    return (React.createElement("div", { className: styles('user-info', className) },
        picture && item != null && React.createElement(CollaboratorPicture, { user: item }),
        React.createElement("div", { className: styles(inline && 'inline') }, item != null ? (React.createElement(React.Fragment, null,
            React.createElement("div", null, item.name.full),
            React.createElement("div", null, item.email))) : (text))));
}
