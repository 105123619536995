// todo: probably a duplicate of flattenItem or similar nightmarish util function we have
import { __assign, __rest } from "tslib";
export var formatActivities = function (activities) {
    return {
        metadata: activities.metadata,
        activities: activities.data.map(function (_a) {
            var date = _a.date, rest = __rest(_a, ["date"]);
            return (__assign(__assign({}, rest), { date: date }));
        }),
    };
};
