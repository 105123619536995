import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import AddressBlock from 'shared/components/AddressBlock';
import TvaNumber from 'shared/components/TvaNumber';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var DebtorDetails = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var address_attributes = debtor.address_attributes, vat_number = debtor.vat_number, co_contractor = debtor.co_contractor, intracom = debtor.intracom;
    return (React.createElement(React.Fragment, null,
        address_attributes && React.createElement(AddressBlock, { address: address_attributes }),
        React.createElement("br", null),
        vat_number && (React.createElement("div", null,
            t(i18nKeys.VAT),
            " : ",
            React.createElement(TvaNumber, { value: vat_number }))),
        co_contractor && (React.createElement("div", { className: styles('customer-type') }, t(i18nKeys.INVOICE.REVERSE_CHARGE_COCONTRACTOR))),
        intracom && (React.createElement("div", { className: styles('customer-type') }, t(i18nKeys.INVOICE.INTRACOMMUNITY)))));
};
