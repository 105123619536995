import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import styleIdentifiers from './PaymentPlanForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanFormWrapper = function (_a) {
    var edit = _a.edit, actions = _a.actions, className = _a.className, children = _a.children;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('payment-plan-form', className) },
        React.createElement("div", { className: styles('head') },
            React.createElement("div", null,
                React.createElement("h1", null, t(i18nKeys.FORM.PAYMENT_PLAN[edit ? 'EDIT' : 'NEW'].TITLE)),
                React.createElement("p", { className: styles('subhead') }, t(i18nKeys.FORM.PAYMENT_PLAN[edit ? 'EDIT' : 'NEW'].WARNING))),
            actions && React.createElement("div", { className: styles('actions') }, actions)),
        children));
};
