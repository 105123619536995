import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import SendingOptionsForm from 'app/Private/Invoices/ToHandleDetail/SendingOptionsForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { emailValues, getDefaultEmails, transformEmailsAndSubmit } from 'shared/utils/contacts';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Box, LoadingOverlay } from '@mantine/core';
import styleIdentifiers from './AdditionalReminder.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AdditionalReminder(_a) {
    var _b;
    var onSubmit = _a.onSubmit, invoice = _a.invoice, items = _a.items, invoiceActionsData = _a.invoiceActionsData, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var refContainer = useRef(null);
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var _c = __read(useState({
        pageNumber: 1,
        numberOfPages: 1,
        url: '',
        email: '',
    }), 2), state = _c[0], setState = _c[1];
    var url = state.url, pageNumber = state.pageNumber, numberOfPages = state.numberOfPages, email = state.email;
    var _d = __read(useState(false), 2), isPostSelected = _d[0], setIsPostSelected = _d[1];
    var _e = __read(useState(false), 2), isEmailSelected = _e[0], setIsEmailSelected = _e[1];
    var sendingMediaNames = initialValues.sending_media_attributes.map(function (_a) {
        var name = _a.name;
        return name;
    });
    var sendEmail = sendingMediaNames.includes(SendingMediaNames.email);
    var sendPost = sendingMediaNames.includes(SendingMediaNames.post); // Can send via ...
    var sendRegisteredPost = sendingMediaNames.includes(SendingMediaNames.registered_post);
    var _f = __read(useState(), 2), activeTab = _f[0], setActiveTab = _f[1];
    var _g = __read(useState(company.default_templates.additional_reminder.id), 2), currentTemplateId = _g[0], setCurrentTemplateId = _g[1];
    // @leo: Not ideal to have the child bubble up the state for the tabs,
    // but it's simpler then hoisting the whole SendingOptions form here for now
    useEffect(function () {
        if (isEmailSelected) {
            setActiveTab('email');
        }
        else {
            setActiveTab(isPostSelected ? 'post' : undefined);
        }
    }, [isEmailSelected]);
    useEffect(function () {
        if (isPostSelected) {
            setActiveTab('post');
        }
        else {
            setActiveTab(isEmailSelected ? 'email' : undefined);
        }
    }, [isPostSelected]);
    useEffect(function () {
        getEmail(currentTemplateId);
        getPdf(currentTemplateId);
    }, [currentTemplateId]);
    var _h = useGetDebtorSendingOptions(invoice.debtor_id), sendingOptionsResponse = _h.data, isError = _h.isError, isLoading = _h.isLoading, refetch = _h.refetch;
    var getPdf = function (template_id) {
        invoiceActions.document({
            id: invoice.id,
            noLoading: true,
            data: __assign({ document_type: 'additional_reminder_pdf', template_id: template_id }, items),
            callback: function (data) {
                setState(function (s) { return (__assign(__assign({}, s), { pageNumber: 1, url: URL.createObjectURL(data) })); });
            },
        });
    };
    var getEmail = function (template_id) {
        invoiceActions.previewTemplate({
            id: invoice.id,
            noLoading: true,
            data: __assign({ document_type: 'additional_reminder', manual_reminder: true, template_id: template_id }, items),
            callback: function (data) {
                setState(function (s) { return (__assign(__assign({}, s), { email: data.email })); });
            },
        });
    };
    if (isLoading && !sendingOptionsResponse) {
        return (React.createElement(Box, { w: 400, h: 400 },
            React.createElement(LoadingOverlay, { visible: true })));
    }
    if (isError || !sendingOptionsResponse) {
        return React.createElement("span", null, "Error");
    }
    var emails = sendingOptionsResponse.emails;
    var defaultEmails = getDefaultEmails(emails, Notification.reminders);
    var defaultEmailValues = emailValues(defaultEmails);
    var onDocumentLoadSuccess = function (pdf) {
        setState(function (s) { return (__assign(__assign({}, s), { numberOfPages: pdf._pdfInfo.numPages })); });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("div", { className: styles('additional-reminder') },
            React.createElement("div", { className: styles('left'), ref: refContainer },
                React.createElement("div", { className: styles('tab-wrapper') },
                    isEmailSelected && (React.createElement("div", { className: styles('tab-item', activeTab === 'email' && 'active'), onClick: function () { return setActiveTab(SendingMediaNames.email); } }, t(i18nKeys.EMAIL))),
                    isPostSelected && (React.createElement("div", { className: styles('tab-item', activeTab === 'post' && 'active'), onClick: function () { return setActiveTab(SendingMediaNames.post); } }, t(i18nKeys.POST)))),
                activeTab === 'email' && email && (React.createElement("div", { className: styles('email-preview') },
                    React.createElement(HTML, { className: "email-preview", html: email }))),
                activeTab === 'post' && (React.createElement("div", { className: styles('pdf-actions'), style: { border: url ? '1px solid #bbb' : '' } }, url ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('container-actions') },
                        pageNumber > 1 && (React.createElement(Icon, { name: IconName.MINIMAL_UP, onClick: function () {
                                setState(__assign(__assign({}, state), { pageNumber: pageNumber - 1 }));
                            } })),
                        numberOfPages > pageNumber && (React.createElement(Icon, { name: IconName.MINIMAL_DOWN, onClick: function () {
                                setState(__assign(__assign({}, state), { pageNumber: pageNumber + 1 }));
                            } }))),
                    React.createElement(Document, { file: url, onLoadSuccess: onDocumentLoadSuccess },
                        React.createElement(Page, { renderAnnotationLayer: false, renderTextLayer: false, width: (((_b = refContainer === null || refContainer === void 0 ? void 0 : refContainer.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 680) - 10, pageNumber: pageNumber })))) : (React.createElement("div", { className: styles('loading') },
                    React.createElement("div", null, t(i18nKeys.LOADING))))))),
            React.createElement("div", { className: styles('right') },
                React.createElement(SendingOptionsForm, { customText: t(i18nKeys.FORM.ADDITIONAL_REMINDER.SUMMARY), customButtonText: t(i18nKeys.SUBMIT), setTemplate: setCurrentTemplateId, onSubmit: function (values) { return transformEmailsAndSubmit(emails, values, onSubmit); }, type: "additional_reminder", invoiceActionsData: invoiceActionsData, setIsPostSelected: setIsPostSelected, setIsEmailSelected: setIsEmailSelected, initialValues: __assign(__assign({}, invoice.sending_options_attributes), { template_id: company.default_templates.additional_reminder, send_email: sendEmail, send_post: sendPost, send_registered_post: sendRegisteredPost }), invoice: __assign(__assign({}, invoice), { debtor_attributes: __assign(__assign({}, invoice.debtor_attributes), { emails: __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(defaultEmailValues), false), __read(invoice.sending_options_attributes.emails), false))), false) }) }), debtorSendingOptions: sendingOptionsResponse, refetchSendingOptions: refetch })))));
}
