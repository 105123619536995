import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import styleIdentifiers from './percentage.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Percentage(_a) {
    var value = _a.value, className = _a.className, rest = __rest(_a, ["value", "className"]);
    return (React.createElement(NumericFormat, __assign({ className: styles('Percentage', className), value: value, suffix: "%", decimalScale: 2, 
        // fixedDecimalScale
        displayType: "text" }, rest)));
}
