import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Textarea } from 'shared/io';
import styleIdentifiers from './debtorReactionForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorReactionForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = useForm({ shouldUnregister: true }), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors, setValue = _b.setValue;
    var submit = function (value) {
        onSubmit(value);
        setValue('comment', '');
    };
    return (React.createElement("form", { className: styles('DebtorReactionForm'), onSubmit: handleSubmit(submit) },
        React.createElement(Textarea, { error: errors.comment, register: register('comment', { required: true }), rows: 5, withBorder: true, label: t(i18nKeys.COMMENT) }),
        React.createElement("div", { className: styles('buttons') },
            React.createElement(Button, { label: t(i18nKeys.SUBMIT), type: "submit" }))));
}
