import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { CommentForm } from './CommentForm/CommentForm';
import { CommentItem } from './CommentItem/CommentItem';
import styleIdentifiers from './Comments.scss';
var styles = classNames.bind(styleIdentifiers);
export var Comments = function (_a) {
    var comments = _a.comments, submitComment = _a.submitComment;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('comment-card', 'card') },
        React.createElement("h3", null, t(i18nKeys.NOTES)),
        comments && comments.map(function (comment) { return React.createElement(CommentItem, { key: comment.id, comment: comment }); }),
        React.createElement(CommentForm, { onSubmit: submitComment, labelText: t(i18nKeys.NEW_NOTE), label: t(i18nKeys.ACTIONS.ADD) })));
};
