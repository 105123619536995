import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import { isTrue } from '../WorkflowForm.utils';
import styleIdentifiers from './WorkflowFormSteps.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsItemDetailTask = function (_a) {
    var task = _a.task, children = _a.children;
    var t = useTranslation().t;
    var title = task.title, mandatory = task.mandatory;
    return (React.createElement("div", { className: styles('description') },
        React.createElement("div", null,
            React.createElement("h3", null, title),
            React.createElement("p", null, t(i18nKeys.TASK_GENERATION))),
        isTrue(mandatory) && (React.createElement("div", { className: styles('info', 'red'), title: t(i18nKeys.TASK.ATTRIBUTES.MANDATORY) },
            React.createElement(Icon, { name: IconName.ALERT_CIRCLE }))),
        children));
};
