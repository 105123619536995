import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export var UpdateFooterSchema = z.array(z
    .union([z.null(), z.string(), z.object({ field: z.string(), content: z.string() })])
    .transform(function (val) { return (val == null ? false : val); }));
export function updateInvoiceFooterFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put('invoice_footer_customisation', {
                            fields: data,
                        })];
            }
        });
    });
}
export function useUpdateInvoiceFooter() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return updateInvoiceFooterFn(axiosInstance, data); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['invoice-footer']);
        },
    });
    return addActionNameToMutationResult('updateInvoiceFooter', mutation);
}
