import React from 'react';
import { useTranslation } from 'locales';
import { Box, Card, LoadingOverlay } from '@mantine/core';
export var DashboardChartLoader = function (_a) {
    var titleKey = _a.titleKey;
    var t = useTranslation().t;
    return (React.createElement(Card, { title: t(titleKey) },
        React.createElement(Box, { w: "100%", h: "285px" },
            React.createElement(LoadingOverlay, { visible: true }))));
};
