import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import styleIdentifiers from './reconciliationItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ReconciliationItem(_a) {
    var item = _a.item, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.overrideSelection, overrideSelection = _c === void 0 ? false : _c, action = _a.action;
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var handleClick = function () {
        if (action)
            action(item);
    };
    return (React.createElement("tr", { className: styles('ReconciliationItem', (overrideSelection || item.checked) && 'checked', item.suggested_invoice && 'suggested', disabled && 'disabled'), key: item.id },
        React.createElement("td", { className: styles('date'), onClick: handleClick },
            React.createElement(DateItem, { lg: currentLang, date: item.type === 'invoice'
                    ? item.issue_date
                    : item.value_date }),
            item.suggested_invoice && (React.createElement("div", { className: "label" }, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SUGGESTION)))),
        React.createElement("td", { className: styles('details'), onClick: handleClick },
            React.createElement("div", { className: styles('details-name') }, item.type === 'invoice'
                ? item.debtor_name
                : item.counterparty_name),
            item.type === 'invoice' && (React.createElement("div", { className: styles('details-com') }, item.reference)),
            React.createElement("div", { className: styles('details-com') },
                React.createElement("span", null, "Com: "),
                item.type === 'invoice'
                    ? item.structured_communication
                    : item.communication)),
        React.createElement("td", { className: styles('amount'), onClick: handleClick },
            item.amount && (React.createElement("div", null,
                React.createElement(Amount, { localizedValue: item.amount, value: item.localized_money_object.amount, suffix: item.currency, direction: DropdownDirection.BOTTOM }))),
            item.type === 'invoice' ? (React.createElement("div", null,
                React.createElement(Amount, { localizedValue: item.remaining_balance, value: item.localized_money_object.remaining_balance, suffix: item.currency, direction: DropdownDirection.BOTTOM }))) : (item.remaining_balance !== item.amount && (React.createElement("div", { className: styles(item.type !== 'invoice' && 'remaining-balance-payment') },
                t(i18nKeys.BALANCE),
                ":",
                ' ',
                React.createElement(Amount, { localizedValue: item.remaining_balance, value: item.localized_money_object.remaining_balance, suffix: item.currency, direction: DropdownDirection.BOTTOM })))),
            item.remaining_late_fees > 0 && (React.createElement("div", { className: styles('late-fees') },
                "+ ",
                t(i18nKeys.LATE_FEES),
                ":",
                ' ',
                React.createElement(Amount, { localizedValue: item.remaining_late_fees, value: item.localized_money_object.remaining_late_fees, suffix: item.currency, direction: DropdownDirection.BOTTOM }))))));
}
