import React from 'react';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Badge, Space, Tooltip } from '@mantine/core';
import styleIdentifiers from './NavContent.scss';
var styles = classNames.bind(styleIdentifiers);
export default function NavContent(_a) {
    var _b;
    var state = _a.state, goSubMenu = _a.goSubMenu, previous = _a.previous, onClickItem = _a.onClickItem, goFollowUp = _a.goFollowUp;
    var isFirst = state.current.first;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('head', !isFirst && 'pointer'), onClick: state.current.isFollowUpChild ? goFollowUp : previous },
            !isFirst && (React.createElement(Icon, { className: styles('previous'), name: IconName.MINIMAL_LEFT, size: "16px" })),
            state.current.url ? (React.createElement(NavLink, { activeClassName: styles('active'), className: styles('item'), to: state.current.url }, state.current.text)) : (state.current.text)),
        React.createElement("nav", { className: styles('body') }, (_b = state.current.menu) === null || _b === void 0 ? void 0 : _b.map(function (item, key) {
            if (item.isHidden)
                return null;
            return !item.menu ? (React.createElement(Tooltip, { key: item.url || key, disabled: item.tooltip == null, withArrow: true, position: "right", offset: 15, label: item.tooltip },
                React.createElement(NavLink, { activeClassName: styles('active'), isActive: function (match, location) {
                        return location.pathname.indexOf(item.match || item.url) !== -1;
                    }, className: styles('item'), to: item.url, onClick: onClickItem },
                    typeof item.icon === 'string' ? (React.createElement(Icon, { size: "20px", className: styles('icon', 'color-anchor'), name: item.icon, color: IconColor.DARK })) : (item.icon),
                    React.createElement(Space, { w: "xs" }),
                    React.createElement("span", { className: styles('text') },
                        item.text,
                        " ",
                        item.badge,
                        item.count > 0 && React.createElement(Badge, null, item.count))))) : (React.createElement("div", { onClick: function () { return goSubMenu(item); }, className: styles('item', item.disabled && 'disabled'), key: item.url || key },
                typeof item.icon === 'string' ? (React.createElement(Icon, { size: "20px", className: styles('icon', 'color-anchor'), name: item.icon, color: IconColor.DARK })) : (item.icon),
                React.createElement(Space, { w: "xs" }),
                React.createElement("span", { className: styles('text') },
                    item.text,
                    ' ',
                    item.badge > 0 && (React.createElement("span", { className: styles('text-circle', 'badge') }, item.badge))),
                React.createElement(Icon, { className: styles('arrow'), name: IconName.MINIMAL_RIGHT })));
        }))));
}
