import { __read } from "tslib";
import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared';
import { CollectionStep } from 'shared/components/CollectionStep';
import { PageTitle } from 'shared/layout';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Badge, Box, Button, Drawer, Stack, Text } from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import { IconFilter, IconFilterOff } from '@tabler/icons-react';
import { useLoadKanbanInvoices } from '../api';
import { InvoiceKanbanCard, InvoiceKanbanFilters, Kanban, KanbanLoadingState } from '../components';
import { countActiveFilters } from '../utils/filters';
export var InvoicesKanban = function () {
    var t = useTranslation().t;
    var formatter = useLocalizedCurrencyFormatter();
    var _a = useLoadKanbanInvoices(), columns = _a.columns, filtersForm = _a.filtersForm;
    var _b = __read(useDisclosure(false), 2), isFiltersDrawerOpen = _b[0], filtersDrawerHandlers = _b[1];
    useHotkeys([['f', filtersDrawerHandlers.toggle]]);
    var isFetching = Object.values(columns).some(function (column) { return column.isInvoiceColumnFetching; });
    var areAllColumnsPopulated = Object.values(columns).every(function (column) {
        return isDefined(column.invoiceColumn);
    });
    if (!areAllColumnsPopulated)
        return React.createElement(KanbanLoadingState, { title: t(i18nKeys.KANBAN.DEBTOR.TITLE), columnCount: 6 });
    var activeFiltersCount = countActiveFilters(filtersForm.values, [
        'column',
        'remainingBalance.operator',
    ]);
    // TODO Update to mantine modal
    var openInvoiceModal = function (invoiceId) {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: invoiceId, modal: true }),
        });
    };
    var cols = Object.values(columns).map(function (column, colIndex) {
        var _a, _b, _c;
        var cards = (_a = column.invoiceColumn) === null || _a === void 0 ? void 0 : _a.invoices.map(function (invoice, invoiceIndex) {
            return (React.createElement(Kanban.Card, { key: invoiceIndex, onClick: function () { return openInvoiceModal(invoice.id); } },
                React.createElement(InvoiceKanbanCard, { invoice: invoice })));
        });
        return (React.createElement(Kanban.Column, { isLoading: isFetching, key: colIndex, title: React.createElement(Stack, { gap: "sm" },
                React.createElement(CollectionStep, { step: colIndex }),
                React.createElement(Text, { fw: "bold", size: "16px", c: "gray.7" }, formatter.format((_c = (_b = column.invoiceColumn) === null || _b === void 0 ? void 0 : _b.total.totalInEur) !== null && _c !== void 0 ? _c : 0))), color: STEP_TO_STEP_THEME[colIndex].color }, cards));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { h: "60px" },
            React.createElement(PageTitle, null,
                t(i18nKeys.KANBAN.INVOICE.TITLE),
                React.createElement(PageTitle.Actions, null,
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { variant: "default", onClick: filtersDrawerHandlers.open, leftSection: React.createElement(IconFilter, { stroke: 1.5, size: 18 }), rightSection: React.createElement(Badge, { color: activeFiltersCount > 0 ? 'blue' : 'gray' }, activeFiltersCount) }, t(i18nKeys.FILTER)),
                        activeFiltersCount > 0 && (React.createElement(Button, { variant: "default", onClick: filtersForm.reset },
                            React.createElement(IconFilterOff, { stroke: 1.5, size: 18 }))))))),
        React.createElement(Box, { h: "calc(100% - 60px)" },
            React.createElement(Kanban, null, cols)),
        React.createElement(Drawer, { position: "right", opened: isFiltersDrawerOpen, onClose: filtersDrawerHandlers.close, title: t(i18nKeys.FILTERS), keepMounted: true, padding: "lg", styles: {
                title: {
                    fontSize: '1.5rem',
                },
            } },
            React.createElement(InvoiceKanbanFilters, { form: filtersForm }))));
};
