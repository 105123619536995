import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Input } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import styleIdentifiers from './linkInvoiceForm.scss';
import { getCurrentValues } from './LinkInvoiceForm.utils';
var styles = classNames.bind(styleIdentifiers);
export var LinkInvoiceFormTableItem = function (_a) {
    var field = _a.item, index = _a.index, creditNote = _a.creditNote, invoices = _a.invoices, reload = _a.reload;
    var _b = useFormContext(), setValue = _b.setValue, register = _b.register, watch = _b.watch;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var invoice = invoices.find(function (e) { return Number(e.id) === field.invoice_id; });
    if (!invoice) {
        return null;
    }
    var invoiceId = invoice.id, issue_date = invoice.issue_date, reference = invoice.reference, remaining_balance = invoice.remaining_balance, remaining_balance_with_fees = invoice.remaining_balance_with_fees, currency = invoice.currency, _d = invoice.localized_money_object, remainingBalanceWithFeesLocalized = _d.remaining_balance_with_fees, remainingBalanceLocalized = _d.remaining_balance;
    var _e = getCurrentValues(watch('invoices_to_link'), creditNote.localized_money_object), canAdd = _e.canAdd, restTotal = _e.restTotal, canSubmit = _e.canSubmit;
    var addAmount = function () {
        setValue("invoices_to_link.".concat(index, ".amount"), remaining_balance_with_fees < restTotal ? remaining_balance_with_fees : restTotal);
    };
    var max = remaining_balance_with_fees;
    var isLinked = creditNote.matched_payments_attributes.some(function (e) { return e.invoice_attributes.id === invoiceId; });
    var unlinkCn = function () {
        creditNoteActions.unlinkToInvoices({
            id: creditNote.id,
            data: {
                unlink_invoice: invoiceId,
            },
            callback: function () {
                if (reload) {
                    reload();
                }
            },
        });
    };
    return (React.createElement("tr", { key: "".concat(field.id).concat(reference) },
        React.createElement("td", null),
        React.createElement("td", null,
            React.createElement(Link, { to: "/invoices/".concat(invoiceId), target: "_blank" }, reference)),
        React.createElement("td", null, issue_date && (React.createElement(DateItem, { date: issue_date, lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') }))),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: remaining_balance, value: remainingBalanceLocalized, suffix: currency, className: styles('value', 'bigger') })),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: remaining_balance_with_fees, value: remainingBalanceWithFeesLocalized, suffix: currency, className: styles('value', 'bigger') })),
        React.createElement("td", null, !isLinked ? (React.createElement(Button, { small: true, noMargin: true, color: ButtonColor.GREY, onClick: addAmount, iconRight: IconName.MINIMAL_RIGHT, label: t(i18nKeys.ACTIONS.ADD), disabled: !canAdd || !canSubmit })) : (React.createElement(Button, { small: true, noMargin: true, color: ButtonColor.RED, onClick: function () { return unlinkCn(); }, iconRight: IconName.MINIMAL_RIGHT, label: t(i18nKeys.INVOICE.UNLINK) }))),
        React.createElement("td", null, isLinked ? (React.createElement(Input, { type: "number", step: "0.01", noMargin: true, label: t(i18nKeys.AMOUNT), placeholder: String(max), disabled: true, register: register("invoices_to_link.".concat(index, ".").concat('amount'), {
                required: true,
            }) })) : (React.createElement(Input, { type: "number", min: "0", max: max, step: "0.01", noMargin: true, label: t(i18nKeys.AMOUNT), placeholder: String(max), onValueChanged: function (e) {
                if (!e) {
                    setValue("invoices_to_link.".concat(index, ".").concat('amount'), 0);
                }
            }, register: register("invoices_to_link.".concat(index, ".").concat('amount'), {
                required: true,
                min: 0,
                max: max,
            }) })))));
};
