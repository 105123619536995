import { __assign, __awaiter, __generator, __read } from "tslib";
import { entries, filter, map, omit, pipe } from 'remeda';
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult, useAxiosInstance } from './utils';
function getRolesFromPermissions(permissions) {
    if (permissions == null)
        return [];
    if (permissions.admin)
        return ['admin'];
    return pipe(permissions, entries(), filter(function (_a) {
        var _b = __read(_a, 2), val = _b[1];
        return val === true;
    }), map(function (_a) {
        var _b = __read(_a, 1), role = _b[0];
        return role;
    }));
}
export var UpdateUserFormSchema = z
    .object({
    isActive: z.boolean(),
    permissions: z.record(z.boolean()),
    preferences: z.object({
        emails: z.object({
            daily: z.boolean(),
            weekly: z.boolean(),
            notifications: z.boolean(),
        }),
    }),
})
    .partial();
export var UpdateUserSchema = UpdateUserFormSchema.transform(function (data) {
    var _a, _b, _c;
    return (__assign(__assign({}, omit(data, ['preferences', 'permissions'])), { roles: getRolesFromPermissions(data.permissions), receiveDailyEmails: (_a = data.preferences) === null || _a === void 0 ? void 0 : _a.emails.daily, receiveWeeklyEmails: (_b = data.preferences) === null || _b === void 0 ? void 0 : _b.emails.weekly, receiveNotificationEmails: (_c = data.preferences) === null || _c === void 0 ? void 0 : _c.emails.notifications }));
});
export function updateUserFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance;
        var id = _b.id, data = _b.data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [2 /*return*/, instance.put("/users/".concat(id), UpdateUserSchema.parse(data))];
            }
        });
    });
}
export function useUpdateUser() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return updateUserFn(axiosInstance, variables); },
        onSuccess: function () {
            notifications.show({
                title: 'User updated',
                message: "This user's settings have been updated.",
                color: 'green',
            });
            client.invalidateQueries(['users']);
        },
        onError: function (error) {
            var _a;
            (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message.forEach(function (message) {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: message,
                });
            });
        },
    });
    return addActionNameToMutationResult('updateUser', mutation);
}
