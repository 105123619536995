import { __assign, __rest } from "tslib";
import React from 'react';
import { TaskActionType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { TaskActionsAssign } from './TaskActionsAssign';
import { TaskActionsPostpone } from './TaskActionsPostpone';
import styleIdentifiers from '../TaskDetail/TaskDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskActions = function (_a) {
    var task_ids = _a.taskIds, onClick = _a.onClick, children = _a.children;
    var t = useTranslation().t;
    var handlePostpone = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.TASK.ACTIONS.POSTPONE),
            children: (React.createElement(TaskActionsPostpone, { onSubmit: function (_a) {
                    var days_postponed = _a.days_postponed, data = __rest(_a, ["days_postponed"]);
                    return onClick(__assign({ task_ids: task_ids, action_type: TaskActionType.postpone, days_postponed: Number(days_postponed) }, data));
                } })),
        });
    };
    var handleAssign = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
            children: (React.createElement(TaskActionsAssign, { onSubmit: function (data) {
                    return onClick(__assign({ task_ids: task_ids, action_type: TaskActionType.assign }, data));
                } })),
        });
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS["DELETE_TASK".concat(task_ids.length > 1 ? 'S' : '')]), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return onClick({
                        task_ids: task_ids,
                        action_type: TaskActionType.delete,
                    });
                } })),
        });
    };
    return (React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.OTHER_ACTIONS), selectorContent: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.FORM.ACTIONS), className: styles('button'), color: ButtonColor.BLUE, iconRight: IconName.MINIMAL_DOWN }) },
        Object.keys(TaskActionType).map(function (key) { return (React.createElement("div", { key: key, className: styles('dropdown-item'), onClick: function () {
                switch (key) {
                    case TaskActionType.postpone:
                        handlePostpone();
                        break;
                    case TaskActionType.assign:
                        handleAssign();
                        break;
                    case TaskActionType.delete:
                        handleDelete();
                        break;
                    default:
                        onClick({
                            task_ids: task_ids,
                            action_type: TaskActionType[key],
                        });
                        break;
                }
            } }, t(i18nKeys.TASK.ACTIONS[key.toUpperCase()]))); }),
        children));
};
