export var IconName;
(function (IconName) {
    IconName["MINUS"] = "minus";
    IconName["SYNC"] = "sync";
    IconName["PONTO"] = "ponto";
    IconName["BANK"] = "bank";
    IconName["DOMICILIATION"] = "domiciliation";
    IconName["EXACT"] = "exact";
    IconName["CODABOX"] = "codabox";
    IconName["PENCIL"] = "pencil";
    IconName["HORUS"] = "horus";
    IconName["FILTER"] = "filter";
    IconName["CSV"] = "csv";
    IconName["XML"] = "xml";
    IconName["PDF"] = "pdf";
    IconName["QUESTION"] = "question";
    IconName["AT"] = "at";
    IconName["UNLINK"] = "unlink";
    IconName["TEAMLEADER"] = "teamleader";
    IconName["YUKI"] = "yuki";
    IconName["INTEGRATION"] = "integration";
    IconName["USER"] = "user";
    IconName["GEARS"] = "gears";
    IconName["PACKAGE"] = "package";
    IconName["INVOICE"] = "invoice";
    IconName["COMPANY"] = "company";
    IconName["ADDITIONAL_DOCUMENT"] = "additional-document";
    IconName["PAYMENT_BOOK"] = "payment-book";
    IconName["RECONCILIATION"] = "reconciliation";
    IconName["ADD_PAYMENT"] = "add-payment";
    IconName["FORMAL_NOTICE"] = "formal-notice";
    IconName["COMMENT"] = "comment";
    IconName["SENDING_OPTIONS"] = "sending-options";
    IconName["BALANCE"] = "balance";
    IconName["IMPORT_FILE"] = "import-file";
    IconName["CREDIT_NOTE"] = "credit-note";
    IconName["FILE"] = "file";
    IconName["EXTERNAL_LINK"] = "external-link";
    IconName["RECOVERY_PLAN"] = "recovery-plan";
    IconName["POINTS"] = "points";
    IconName["CUSTOMIZATION"] = "customization";
    IconName["STAR_EMPTY"] = "star-empty";
    IconName["STAR_FULL"] = "star-full";
    IconName["LATER"] = "later";
    IconName["QR_CODE"] = "qr-code";
    IconName["MAG"] = "mag";
    IconName["MOVE_VERTICAL"] = "move-vertical";
    IconName["BILLIT"] = "billit";
    IconName["CREDIT_CARD"] = "credit-card";
    IconName["SPINNER"] = "spinner";
    IconName["HAMBURGER"] = "hamburger";
    IconName["DOWNLOAD"] = "download";
    IconName["EYE"] = "eye";
    IconName["ARROW_BOTTOM_ROUNDED"] = "arrow-bottom-rounded";
    IconName["WALLET"] = "wallet";
    IconName["TRASH_SIMPLE"] = "trash-simple";
    IconName["TAILDOWN"] = "taildown";
    IconName["SMALL_REMOVE"] = "small-remove";
    IconName["SIMPLE_REMOVE"] = "simple-remove";
    IconName["SETTINGS_GEAR"] = "settings-gear";
    IconName["SERVER_RACK"] = "server-rack";
    IconName["SEND"] = "send";
    IconName["ROBOT"] = "robot";
    IconName["REPLACE"] = "replace";
    IconName["PLUS"] = "plus";
    IconName["PHONE_CALL"] = "phone-call";
    IconName["INFO"] = "info";
    IconName["CHECK"] = "check";
    IconName["PLAY"] = "play";
    IconName["MONEY"] = "money";
    IconName["PAUSE"] = "pause";
    IconName["MINIMAL_DOWN"] = "minimal-down";
    IconName["LOGOUT"] = "logout";
    IconName["LAW"] = "law";
    IconName["DASHBOARD_HALF"] = "dashboard-half";
    IconName["CLOUD_DOWNLOAD"] = "cloud-download";
    IconName["CLIENT"] = "client";
    IconName["CIRCLE_DOWN"] = "circle-down";
    IconName["CHART_BAR"] = "chart-bar";
    IconName["BOX_RIBBON"] = "box-ribbon";
    IconName["BELL"] = "bell";
    IconName["ALERT_CIRCLE"] = "alert-circle";
    IconName["OPENING_TIMES"] = "opening-times";
    IconName["LINE_CHART"] = "line-chart";
    IconName["COPY"] = "copy";
    IconName["CURRENCY"] = "currency";
    // Custom CSS
    IconName["MINIMAL_UP"] = "minimal-up";
    IconName["MINIMAL_RIGHT"] = "minimal-right";
    IconName["MINIMAL_LEFT"] = "minimal-left";
    IconName["CIRCLE_UP"] = "circle-up";
    IconName["CIRCLE_RIGHT"] = "circle-right";
    IconName["CIRCLE_LEFT"] = "circle-left";
})(IconName || (IconName = {}));
export var IconColor;
(function (IconColor) {
    IconColor["BLUE"] = "blue";
    IconColor["DARK"] = "dark";
    IconColor["DARK_GREEN"] = "dark-green";
    IconColor["GREEN"] = "green";
    IconColor["GREY"] = "grey";
    IconColor["RED"] = "red";
    IconColor["WARN"] = "warn";
    IconColor["WHITE"] = "white";
    IconColor["ORANGE"] = "orange";
})(IconColor || (IconColor = {}));
