import { __read } from "tslib";
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { isDefined } from 'remeda';
var visitsAtom = atomWithImmer({});
export function useIsFirstVisit(pageName) {
    var _a = __read(useAtom(visitsAtom), 2), visits = _a[0], setVisits = _a[1];
    var key = "visited-".concat(pageName);
    useEffect(function () {
        return function () {
            setVisits(function (draft) {
                draft[key] = true;
                return draft;
            });
        };
    }, []);
    if (!isDefined(visits[key]))
        return true;
    return false;
}
