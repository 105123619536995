import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Card from 'shared/components/Card';
import styleIdentifiers from './ProductsDetail.scss';
import ProductsDetailItem from './ProductsDetailItem';
var styles = classNames.bind(styleIdentifiers);
export default function ProductsDetail(_a) {
    var _b = _a.products, products = _b === void 0 ? [] : _b;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('ProductsDetail') },
        React.createElement(Card, { title: t(i18nKeys.INVOICE.INVOICE_PRODUCTS), classContent: styles('card-body'), className: styles('product') },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: styles('big') }, t(i18nKeys.INVOICE.PRODUCT)),
                        React.createElement("th", null, t(i18nKeys.INVOICE.QTE)),
                        React.createElement("th", null, t(i18nKeys.PRICE)),
                        React.createElement("th", null, t(i18nKeys.TOTAL_NOT_VAT_PRICE)),
                        React.createElement("th", null, t(i18nKeys.INVOICE.DISCOUNT)),
                        React.createElement("th", null, t(i18nKeys.VAT)),
                        React.createElement("th", { className: styles('right') }, t(i18nKeys.TOTAL_VAT_PRICE)))),
                React.createElement("tbody", null, products.map(function (product) { return (React.createElement(ProductsDetailItem, { key: product.id, productLine: product })); }))))));
}
