import { __makeTemplateObject } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useEnhancedNavigation } from 'shared/hooks/utils';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { css } from '@emotion/css';
import { ActionIcon, Group, Stack, Text } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
var styles = {
    floatingIconTrigger: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n\n    [data-floating-icon] {\n      position: absolute;\n      top: 0px;\n      right: 0px;\n      opacity: 0;\n      transition: opacity 100ms;\n      pointer-events: none;\n    }\n\n    &:hover {\n      [data-floating-icon] {\n        opacity: 1;\n        pointer-events: all;\n      }\n    }\n  "], ["\n    position: relative;\n\n    [data-floating-icon] {\n      position: absolute;\n      top: 0px;\n      right: 0px;\n      opacity: 0;\n      transition: opacity 100ms;\n      pointer-events: none;\n    }\n\n    &:hover {\n      [data-floating-icon] {\n        opacity: 1;\n        pointer-events: all;\n      }\n    }\n  "]))),
};
export var DebtorKanbanCard = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var formatter = useLocalizedCurrencyFormatter();
    var enhancedNavigate = useEnhancedNavigation();
    var name = debtor.name, remainingBalance = debtor.remainingBalance, unpaidInvoicesCount = debtor.unpaidInvoicesCount;
    return (React.createElement(Stack, { className: styles.floatingIconTrigger },
        React.createElement(ActionIcon, { size: "sm", variant: "default", "data-floating-icon": true, onClick: enhancedNavigate("/kanban/invoices?debtorName=".concat(debtor.name)) },
            React.createElement(IconFilter, { style: { width: '70%', height: '70%' }, stroke: 1.5 })),
        React.createElement(Group, { justify: "space-between", align: "top" },
            React.createElement(Stack, { gap: 4 },
                React.createElement(Text, { size: "sm", fw: 600 }, name))),
        React.createElement(Group, { justify: "space-between" },
            React.createElement(Stack, { gap: 0 },
                React.createElement(Text, { size: "xs", c: "dimmed", fw: 350 }, t(i18nKeys.KANBAN.BALANCE)),
                React.createElement(Text, { size: "xs", c: "blue.7", fw: 600 }, formatter.format(remainingBalance))),
            React.createElement(Stack, { gap: 0, align: "flex-end" },
                React.createElement(Text, { size: "xs", c: "dimmed", fw: 350 }, t(i18nKeys.KANBAN.LATE_INVOICES)),
                React.createElement(Text, { size: "xs", fw: 600 }, unpaidInvoicesCount)))));
};
var templateObject_1;
