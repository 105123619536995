import { __assign } from "tslib";
import React from 'react';
import { useCreateContactPerson, useCreateTask, useDisputeDebtor } from 'api';
import { ContactPersonForm } from 'app/Private/ContactPersons';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import { HideIf } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { treatClient } from 'shared/serializer';
import { useGetCompany } from 'shared/utils/selectors';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { SelectDuplicateDebtorForm } from '../Duplicates/SelectDuplicateDebtorForm';
import styleIdentifiers from './ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailHeader = function (_a) {
    var debtor = _a.debtor, refetch = _a.refetch;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useGetCompany();
    var disputeDebtor = useDisputeDebtor().mutate;
    var createTask = useCreateTask().mutate;
    var createContactPerson = useCreateContactPerson().mutate;
    var client = treatClient(debtor);
    var handleClientSettings = function () {
        EditClient({
            client: client,
            callback: function () {
                refetch();
            },
        });
    };
    var handleDelete = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return clientActions.delete({
                        id: client.id,
                        callback: function () {
                            history.push('/clients');
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var handleMerge = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT),
            children: React.createElement(SelectDuplicateDebtorForm, { currentDebtor: client }),
        });
    };
    var handlePause = function (values) {
        clientActions.pauseDebtor({
            id: client.id,
            data: __assign(__assign({}, values), { pause_debtor: true }),
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                clientActions.detail({ id: client.id });
            },
        });
    };
    var pauseDebtor = function (pause) { return function (e) {
        e.stopPropagation();
        if (client.disputed)
            return;
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CLIENT.PAUSE),
            children: pause ? (React.createElement(SetReasonForm, { onSubmit: handlePause, reasonType: "pause" })) : (React.createElement(AYSModal, { text: t(i18nKeys.INVOICE.AYS_TO_UNPAUSE), onConfirm: function () {
                    clientActions.pauseDebtor({
                        id: client.id,
                        data: { pause_debtor: false },
                        callback: function () {
                            dialogHide(DialogShowId.CUSTOM);
                            clientActions.detail({ id: client.id });
                        },
                    });
                } })),
        });
    }; };
    var handleDispute = function (data) {
        disputeDebtor({
            id: client.id,
            data: __assign(__assign({}, data), { dispute: true }),
        }, {
            onSuccess: function () {
                dialogHide(DialogShowId.CUSTOM);
                clientActions.detail({ id: client.id });
            },
        });
    };
    var dispute = function (clientDisputed) { return function (e) {
        e.stopPropagation();
        if (client.paused)
            return;
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys[clientDisputed ? 'CONFIRM' : 'MARK_AS_DISPUTED']),
            children: clientDisputed ? (React.createElement(AYSModal, { text: t(i18nKeys.AYS.CLIENT_ACTION_UNDISPUTED), onConfirm: function () {
                    disputeDebtor({ id: client.id, data: { dispute: false } }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CUSTOM);
                            clientActions.detail({ id: client.id });
                        },
                    });
                } })) : (React.createElement(SetReasonForm, { onSubmit: handleDispute, reasonType: "dispute" })),
        });
    }; };
    var create = function (type) { return function () {
        history.push("/".concat(type, "/create?debtor_id=").concat(client.id));
    }; };
    var addTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { clientId: client.id, onSubmit: function (data) {
                    return createTask({ data: data }, {
                        onSuccess: function () {
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var addPaymentPlan = function () {
        history.push("/payment-plans/create?debtor_id=".concat(client.id));
    };
    var addContactPerson = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(ContactPersonForm, { onSubmit: function (data) {
                    return createContactPerson({ id: client.id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    return (React.createElement(PageTitle, null,
        client.full_name,
        ' ',
        client.paused && React.createElement(Icon, { name: IconName.PAUSE, title: t(i18nKeys.RECOVERY_IN_PAUSE) }),
        client.has_payment_plan && (React.createElement(Icon, { name: IconName.PAYMENT_BOOK, title: t(i18nKeys.ONGOING_PAYMENT_PLAN) })),
        client.disputed && (React.createElement(Icon, { name: IconName.ALERT_CIRCLE, title: t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT), color: IconColor.RED })),
        React.createElement(PageTitle.Actions, null,
            React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), selectorContent: React.createElement(Button, { style: {
                        borderRadius: '100%',
                        aspectRatio: '1',
                        width: '44px',
                        fontSize: '24px',
                        padding: '0px',
                    }, label: "+", noMargin: true }) },
                React.createElement(HideIf, { connectorActive: true, isFrench: true },
                    React.createElement("div", { className: styles('dropdown-item'), onClick: create('invoices') }, t(i18nKeys.NEW_INVOICE)),
                    React.createElement("div", { className: styles('dropdown-item'), onClick: create('credit-notes') }, t(i18nKeys.NEW_CREDIT_NOTE))),
                company.package.can_use_tasks && (React.createElement("div", { className: styles('dropdown-item'), onClick: addTask }, t(i18nKeys.NEW_TASK))),
                company.package.can_use_payment_plans && (React.createElement("div", { className: styles('dropdown-item'), onClick: addPaymentPlan }, t(i18nKeys.NEW_PAYMENT_PLAN))),
                React.createElement("div", { className: styles('dropdown-item'), onClick: addContactPerson }, t(i18nKeys.CONTACT_PERSON.ADD))),
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.EDIT), onClick: handleClientSettings, iconLeft: IconName.PENCIL }),
            React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), selectorContent: React.createElement(Button, { noMargin: true, iconRight: IconName.ARROW_BOTTOM_ROUNDED, color: ButtonColor.WHITE, label: t(i18nKeys.FORM.ACTIONS) }) },
                client.paused ? (React.createElement("div", { className: styles('dropdown-item', client.disputed && 'disabled'), onClick: pauseDebtor(false) }, t(i18nKeys.CLIENT.DETAIL.UNPAUSE_DEBTOR_RECOVERY))) : (React.createElement("div", { className: styles('dropdown-item', client.disputed && 'disabled'), onClick: pauseDebtor(true) }, t(i18nKeys.CLIENT.DETAIL.PAUSE_DEBTOR_RECOVERY))),
                React.createElement("div", { className: styles('dropdown-item', client.paused && 'disabled'), onClick: dispute(client.disputed) }, t(i18nKeys[client.disputed ? 'REVERT_DISPUTED' : 'MARK_AS_DISPUTED'])),
                React.createElement("div", { className: styles('dropdown-item'), onClick: handleMerge }, t(i18nKeys.MERGE_CLIENT)),
                React.createElement("div", { className: styles('dropdown-item'), onClick: handleDelete }, t(i18nKeys.DELETE))))));
};
