import { __awaiter, __generator } from "tslib";
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult, useAxiosInstance } from './utils';
function markConversationAsReadMutationFn(axiosInstance, vars) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    data = instance.put("conversations/".concat(vars.id, "/mark_as_read"));
                    return [2 /*return*/, data];
            }
        });
    });
}
export function useMarkConversationAsRead() {
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (vars) {
            return markConversationAsReadMutationFn(axiosInstance, vars);
        },
        onError: function (error) {
            console.error(error);
        },
    });
    return addActionNameToMutationResult('markConversationAsRead', mutation);
}
