import classNames from 'classnames/bind';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import styleIdentifiers from './tvaNumber.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TvaNumber(_a) {
    var className = _a.className, value = _a.value;
    if (value && value.indexOf('BE') !== -1) {
        return (React.createElement("span", { className: styles('TvaNumber', className) },
            "BE",
            React.createElement(PatternFormat, { value: value, displayType: "text", format: "#### ### ###" })));
    }
    return value;
}
