import Payment from 'payment';
import { chunk, join, map, pipe } from 'remeda';
import { AVAILABLE_CURRENCIES } from 'shared/constants';
import { useProfile } from 'shared/hooks';
import { captureMessage } from '@sentry/react';
export function extractNumber(value) {
    if (value === void 0) { value = ''; }
    return value.replace(/\D+/g, '');
}
/**
 * @deprecated The currency should only:
 * - be part of a localizedAmount object
 * - be assumed to be EUR in other cases
 */
export function currencySymbol(currencySuffix, fromCurrencyItem) {
    var _a, _b, _c;
    if (currencySuffix === void 0) { currencySuffix = undefined; }
    if (fromCurrencyItem === void 0) { fromCurrencyItem = null; }
    var currency = (_a = currencySuffix !== null && currencySuffix !== void 0 ? currencySuffix : fromCurrencyItem === null || fromCurrencyItem === void 0 ? void 0 : fromCurrencyItem.currency) !== null && _a !== void 0 ? _a : 'EUR';
    return (_c = (_b = AVAILABLE_CURRENCIES.find(function (c) { return c.value === currency; })) === null || _b === void 0 ? void 0 : _b.symbol) !== null && _c !== void 0 ? _c : '€'; // TODO: BETTER TYPING AROUND CURRENCY
}
export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }
    var issuer = Payment.fns.cardType(value);
    var clearValue = extractNumber(value);
    var nextValue;
    switch (issuer) {
        case 'amex':
            nextValue = "".concat(clearValue.slice(0, 4), " ").concat(clearValue.slice(4, 10), " ").concat(clearValue.slice(10, 15));
            break;
        case 'dinersclub':
            nextValue = "".concat(clearValue.slice(0, 4), " ").concat(clearValue.slice(4, 10), " ").concat(clearValue.slice(10, 14));
            break;
        default:
            nextValue = "".concat(clearValue.slice(0, 4), " ").concat(clearValue.slice(4, 8), " ").concat(clearValue.slice(8, 12), " ").concat(clearValue.slice(12, 19));
            break;
    }
    return nextValue.trim();
}
/**
 * @deprecated Use `useSafeLocalizedCurrencyFormatter` instead
 */
export var formatAmount = function (value, decimal, thousands, decimalCount, currency) {
    if (decimal === void 0) { decimal = ','; }
    if (thousands === void 0) { thousands = '.'; }
    if (decimalCount === void 0) { decimalCount = 2; }
    if (currency === void 0) { currency = '€'; }
    value = parseFloat(value || 0);
    if (isNaN(value))
        return '';
    var negativeSign = value < 0 ? '-' : '';
    value = Math.abs(value);
    var decimalPart = (value - parseInt(value, 10)).toFixed(decimalCount).slice(2);
    var str = Math.floor(value).toString();
    var j = str.length > 3 ? str.length % 3 : 0;
    var val = negativeSign +
        (j ? str.substr(0, j) + thousands : '') +
        str.substr(j).replace(/(\d{3})(?=\d)/g, "$1".concat(thousands)) +
        (decimalCount === 0 ? '' : decimal) +
        decimalPart;
    return "".concat(val, " ").concat(currency);
};
// Value should be string but the input arg is typed as any in a few of the callsites
// Caused an issue when undefined was passed in
export function formatCommunication(value) {
    if (typeof value !== 'string')
        return '';
    var isFrenchCommunication = value.startsWith('RF');
    if (isFrenchCommunication) {
        // prettier-ignore
        return pipe(value, function () { return value.split(''); }, chunk(4), map(join('')), join(' '));
    }
    var clearValue = extractNumber(value);
    var output = "+++".concat(clearValue.slice(0, 3));
    if (clearValue.length > 3) {
        output += "/".concat(clearValue.slice(3, 7));
    }
    if (clearValue.length > 7) {
        output += "/".concat(clearValue.slice(7, 12));
    }
    if (clearValue.length >= 12) {
        output += '+++';
    }
    return output;
}
export function formatBankAccount(value) {
    if (!value)
        return '';
    var clearValue = value.replace(/\s+/g, '').toUpperCase();
    var output = "".concat(clearValue.slice(0, 4));
    if (clearValue.length > 4) {
        output += " ".concat(clearValue.slice(4, 8));
    }
    if (clearValue.length > 8) {
        output += " ".concat(clearValue.slice(8, 12));
    }
    if (clearValue.length > 12) {
        output += " ".concat(clearValue.slice(12, 16));
    }
    if (clearValue.length > 16) {
        output += " ".concat(clearValue.slice(16));
    }
    return output;
}
// See https://recovr.sentry.io/issues/4697076958/
// The problem is most likely a compatibility issue with the more recent `notation: 'compact'` option
function supportsCompactNotation() {
    try {
        new Intl.NumberFormat('en', { notation: 'compact' }).format(1000);
        return true;
    }
    catch (e) {
        captureMessage('Intl.NumberFormat does not support compact notation, falling back to long notation');
        return false;
    }
}
// Formatter that uses the compact notation if available without breaking on older browsers.
// If compact is not available, the long format will be used. I consider this acceptable given the
// small amount of people likely to be affected.
export function useSafeLocalizedCompactCurrencyFormatter(stripCurrency) {
    if (stripCurrency === void 0) { stripCurrency = false; }
    var locale = useProfile().locale;
    var options = {};
    if (!stripCurrency) {
        options.style = 'currency';
        options.currency = 'EUR';
    }
    if (supportsCompactNotation()) {
        options.notation = 'compact';
    }
    return new Intl.NumberFormat(locale, options);
}
/**
 * Invoke the hook to get the formatter, then call the .format method on the formatter
 * @param stripCurrency boolean
 * @returns Intl.NumberFormat
 */
export function useLocalizedCurrencyFormatter(stripCurrency) {
    if (stripCurrency === void 0) { stripCurrency = false; }
    var locale = useProfile().locale;
    var options = {};
    if (!stripCurrency) {
        options.style = 'currency';
        options.currency = 'EUR';
    }
    return new Intl.NumberFormat(locale, options);
}
