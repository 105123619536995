import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconLockAccess, IconStar, IconStarFilled } from '@tabler/icons-react';
export var ViewIcon = function (_a) {
    var view = _a.view, onFavorite = _a.onFavorite, onRemoveFavorite = _a.onRemoveFavorite;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    if (view.isDefault) {
        return (React.createElement(Tooltip, { withArrow: true, label: t(i18nKeys.VIEWS.SETTINGS.DEFAULT_LABEL) },
            React.createElement(IconLockAccess, { stroke: 1.3, size: "32px", color: theme.colors.gray[6] })));
    }
    return (React.createElement(Tooltip, { disabled: view.isFavorite, withArrow: true, label: t(i18nKeys.VIEWS.SETTINGS.MAKE_FAVORITE) }, view.isFavorite ? (React.createElement(ActionIcon, { variant: "transparent", onClick: function () { return onRemoveFavorite(view.id); } },
        React.createElement(IconStarFilled, null))) : (React.createElement(ActionIcon, { variant: "transparent", onClick: function () { return onFavorite(view.id); } },
        React.createElement(IconStar, { stroke: 1.5, color: theme.colors.gray[6] })))));
};
