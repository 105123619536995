import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadLightUsers } from 'shared/hooks';
import { Group, NumberInput, Select, Stack, TextInput } from '@mantine/core';
export var DebtorKanbanFilters = function (_a) {
    var form = _a.form;
    var t = useTranslation().t;
    var _b = useLoadLightUsers(false).users, users = _b === void 0 ? [] : _b;
    var accountManagersForSelect = users.map(function (user) { return ({
        value: String(user.id),
        label: user.name.full,
    }); });
    return (React.createElement(Stack, { gap: "lg" },
        React.createElement(Select, __assign({ autoFocus: true, label: t(i18nKeys.ACCOUNT_MANAGER), placeholder: t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER), data: accountManagersForSelect }, form.getInputProps('accountManagerId'))),
        React.createElement(TextInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME), placeholder: t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME_PH) }, form.getInputProps('fullName'))),
        React.createElement(Group, null,
            React.createElement(NumberInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.MIN_BALANCE), placeholder: "e.g. 100", flex: 1 }, form.getInputProps('minSaldo'))),
            React.createElement(NumberInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.MAX_BALANCE), placeholder: "e.g. 5000", flex: 1 }, form.getInputProps('maxSaldo'))))));
};
