import { __read } from "tslib";
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './CopyToClipboard.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CopyToClipboard(_a) {
    var textToCopy = _a.textToCopy, text = _a.text, custom = _a.custom;
    var _b = __read(useState(false), 2), showCopied = _b[0], setShowCopied = _b[1];
    var copyOnClipBoard = function () {
        var textField = document.createElement('textarea');
        textField.innerText = textToCopy;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setShowCopied(true);
        setTimeout(function () {
            setShowCopied(false);
        }, 1500);
    };
    return (React.createElement("span", { className: styles('copy-to-clipboard', custom && 'custom'), onClick: copyOnClipBoard },
        text,
        " ",
        React.createElement(Icon, { name: IconName.COPY }),
        React.createElement("span", { className: styles('copied', showCopied && 'show') },
            "copi\u00E9 ",
            React.createElement(Icon, { name: IconName.CHECK }))));
}
