import { __read } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, get, useFormContext } from 'react-hook-form';
import { CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './AddressForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddressForm() {
    var currentLang = useTranslation().currentLang;
    var t = useTranslation().t;
    var _a = useFormContext(), register = _a.register, errors = _a.formState.errors;
    var countriesForSelect = Object.entries(countries.getNames(currentLang)).map(function (_a) {
        var _b = __read(_a, 2), value = _b[0], description = _b[1];
        return ({ value: value, description: description });
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('col-6', 'street-name') },
            React.createElement(Input, { errorMessage: get(errors, 'addressAttributes.streetName'), className: styles('input'), withBorder: true, register: register('addressAttributes.streetName', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.STREET) })),
        React.createElement("div", { className: styles('col-3') },
            React.createElement(Input, { className: styles('input'), withBorder: true, register: register('addressAttributes.streetNumber'), type: "text", label: t(i18nKeys.NUMBER) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, 'addressAttributes.zipCode'), className: styles('input'), withBorder: true, register: register('addressAttributes.zipCode', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, 'addressAttributes.city'), className: styles('input'), withBorder: true, register: register('addressAttributes.city', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.CITY) })),
        React.createElement("div", { className: "col-12" },
            React.createElement(Controller, { defaultValue: "", name: "addressAttributes.countryCode", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "addressAttributes.countryCode", filter: true, items: countriesForSelect, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } }))));
}
