import { __read } from "tslib";
import React from 'react';
import { AssignableModel, AssignableTo } from 'api/models';
import { AutomaticAssignationsList } from 'app/Private/AutomaticAssignations/Listing/AutomaticAssignationsList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { partition } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { useLoadUsers } from 'shared/hooks';
import { Button } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { sideMenuShow } from 'store/view/view.actions';
import { SettingsCard } from '../SettingsCard';
import ColleagueItem from './ColleagueItem';
import InviteCollaboratorForm from './InviteCollaboratorForm';
import styleIdentifiers from './CollaboratorsSettings.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(ColleagueItem);
export var CollaboratorsSettings = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _a = useLoadUsers(true), _b = _a.users, users = _b === void 0 ? [] : _b, isUsersLoading = _a.isUsersLoading;
    var _c = __read(partition(users, function (user) { return user.isActive; }), 2), activeCollaborators = _c[0], _ = _c[1];
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: 'ID',
        },
        {
            key: 'first_name',
            title: t(i18nKeys.SETTINGS.STAFF.COLLABORATOR_NAME),
            width: 200,
        },
        {
            key: 'roles',
            title: t(i18nKeys.SETTINGS.STAFF.PERMISSIONS),
        },
        {
            key: 'email',
            title: t(i18nKeys.EMAIL),
            width: 200,
        },
        {
            key: 'active',
            title: t(i18nKeys.SETTINGS.STAFF.ACTIVE),
            width: 80,
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var newColleague = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(InviteCollaboratorForm, null),
        });
    };
    return (React.createElement("div", { className: styles('collaborators-settings') },
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.STAFF.TITLE)),
        React.createElement(SettingsCard, null,
            React.createElement(EnhancedTable, { noFooter: true, noShadow: true, subtitle: t(i18nKeys.SETTINGS.STAFF.YOUR_STAFF), actions: React.createElement(Button, { noMargin: true, label: t(i18nKeys.INVITE), onClick: newColleague, iconRight: IconName.MINIMAL_RIGHT, disabled: company.package.allowed_users_count !== null &&
                        company.package.allowed_users_count < activeCollaborators.length }), loading: isUsersLoading, loaded: isUsersLoading === false, headers: TABLE_HEADERS, items: users, noCheckbox: true }),
            React.createElement(AutomaticAssignationsList, { defaultType: AssignableTo.user, tabItems: [
                    {
                        model_to_assign: AssignableModel.invoice,
                        assign_to_type: AssignableTo.user,
                        key: i18nKeys.COMMON.INVOICE,
                    },
                    {
                        model_to_assign: AssignableModel.debtor,
                        assign_to_type: AssignableTo.user,
                        key: i18nKeys.COMMON.CLIENT,
                    },
                ] }))));
};
