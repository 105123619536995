import { __assign, __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { productActions, productConstants as events } from 'store/product/product.actions';
import { sendApi } from '../sagas';
function ProductWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.listPage.request, sendApi(api.product.list, productActions.listPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.add.request, sendApi(api.product.add, productActions.addRes, function (payload) { return (__assign(__assign({}, payload), { success: 'INVOICING.SUCCESSFULLY_PRODUCT_CREATION' })); }))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.update.request, sendApi(api.product.update, productActions.updateRes, function (payload) { return (__assign(__assign({}, payload), { success: 'INVOICING.SUCCESSFULLY_PRODUCT_UPDATE' })); }))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.delete.request, sendApi(api.product.delete, productActions.deleteRes))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.search.request, sendApi(api.product.list, productActions.searchRes, function (payload) { return ({
                        data: payload,
                        noFeedback: true,
                        noLoading: true,
                    }); }))];
            case 5: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 6:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(ProductWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
