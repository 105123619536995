import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, SwitchInput } from 'shared/io';
import styleIdentifiers from './editCollaboratorForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function EditCollaboratorForm(_a) {
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    }), watch = _b.watch, handleSubmit = _b.handleSubmit, control = _b.control;
    var isAdmin = watch('permissions.admin');
    var isActive = watch('isActive');
    return (React.createElement("form", { className: styles('EditCollaboratorForm'), onSubmit: handleSubmit(onSubmit) },
        React.createElement("div", { className: styles('head') },
            React.createElement("div", null, initialValues.name.full)),
        React.createElement("div", { className: styles('content') },
            React.createElement("div", { className: styles('role') },
                React.createElement("h3", null,
                    " ",
                    t(i18nKeys.FORM.EDIT_STAFF.TITLE)),
                React.createElement(Controller, { control: control, name: "isActive", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({ noMargin: true }, values, { label: t(i18nKeys.SETTINGS.STAFF.ACTIVE) })));
                    } }),
                isActive && (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    constants.user_roles.map(function (item, index) {
                        if (item.value !== 'admin' && isAdmin)
                            return null;
                        return (React.createElement(Controller, { key: index, control: control, name: "permissions.".concat(item.value), render: function (_a) {
                                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                                return (React.createElement(SwitchInput, __assign({}, values, { label: item.description })));
                            } }));
                    })))),
            React.createElement("hr", { className: styles('content-separator') }),
            React.createElement("div", { className: styles('role') },
                React.createElement("h3", null, t(i18nKeys.SETTINGS.STAFF.RECEIVE_EMAIL)),
                React.createElement(Controller, { control: control, name: "preferences.emails.daily", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { noMargin: true, label: t(i18nKeys.SETTINGS.STAFF.DAILY) })));
                    } }),
                React.createElement("hr", null),
                React.createElement(Controller, { control: control, name: "preferences.emails.weekly", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { noMargin: true, label: t(i18nKeys.SETTINGS.STAFF.WEEKLY) })));
                    } }),
                React.createElement("hr", null),
                React.createElement(Controller, { control: control, name: "preferences.emails.notifications", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { noMargin: true, label: t(i18nKeys.SETTINGS.STAFF.NOTIFICATION) })));
                    } }))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" }))));
}
