import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFormContext } from 'react-hook-form';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import GradientBorder from 'shared/components/GradientBorder';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var InvoiceFormContainer = function (_a) {
    var submit = _a.submit, isCreditNote = _a.isCreditNote, invoice = _a.invoice, creditNote = _a.creditNote, children = _a.children;
    var t = useTranslation().t;
    var handleSubmit = useFormContext().handleSubmit;
    var getTitle = function () {
        if (isCreditNote) {
            return t(creditNote ? i18nKeys.INVOICING.CREATE.EDIT_CREDIT_NOTE : i18nKeys.NEW_CREDIT_NOTE);
        }
        return t(invoice ? i18nKeys.INVOICING.EDIT_INVOICE : i18nKeys.NEW_INVOICE);
    };
    return (React.createElement("form", { className: styles('InvoiceForm'), onSubmit: handleSubmit(submit) },
        React.createElement("div", { className: styles('head') },
            React.createElement("h1", { className: styles('title') }, getTitle()),
            React.createElement("div", { className: styles('actions') },
                (invoice || creditNote) && (React.createElement(DownloadInvoice, { className: styles('space'), invoice: invoice || creditNote })),
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.BLUE, noMargin: true, noShadow: true }))),
        React.createElement("article", { className: styles('invoice') },
            React.createElement(GradientBorder, null),
            React.createElement("div", { className: styles('content') }, children),
            React.createElement(GradientBorder, { bottom: true })),
        React.createElement("div", { className: styles('bottom-actions') },
            React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), color: ButtonColor.BLUE, noMargin: true }))));
};
