import { __assign, __generator, __rest } from "tslib";
import { SendingMediaNames } from 'api/models';
import { push } from 'connected-react-router';
import { i18nKeys } from 'locales';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { invoiceFormToData } from 'shared/serializer';
import { api } from 'store/apis';
import { invoiceActions, invoiceConstants as events } from 'store/invoice/invoice.actions';
import { success } from 'store/view/view.actions';
import { downloadFile, extractFilename } from '../../shared/utils/view';
import { apiCall, sendApi } from '../sagas';
function InvoiceAdd(_a) {
    var constants, data, response, newInvoice;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.app.constants; })];
            case 1:
                constants = _b.sent();
                data = payload.data;
                return [4 /*yield*/, apiCall({
                        api: api.invoice.add,
                        data: invoiceFormToData(data, constants),
                        actionRes: invoiceActions.addRes,
                    })];
            case 2:
                response = _b.sent();
                if (!(response && response.status === 200)) return [3 /*break*/, 8];
                newInvoice = response.data && response.data.data;
                if (!newInvoice) return [3 /*break*/, 4];
                return [4 /*yield*/, put(push("/invoices/detail/".concat(newInvoice.id)))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, put(push('/invoices/listing'))];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [4 /*yield*/, success({
                    text: i18nKeys.INVOICE.SUCCESSFUL_CREATION,
                })];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8: return [2 /*return*/];
        }
    });
}
function InvoiceUpdate(_a) {
    var constants, id, data, response;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.app.constants; })];
            case 1:
                constants = _b.sent();
                id = payload.id, data = payload.data;
                return [4 /*yield*/, apiCall({
                        api: api.invoice.update,
                        id: id,
                        data: invoiceFormToData(data, constants),
                        actionRes: invoiceActions.updateRes,
                        callback: payload.callback,
                    })];
            case 2:
                response = _b.sent();
                if (!(response.status === 200)) return [3 /*break*/, 6];
                if (!response.data.data.attributes.draft) return [3 /*break*/, 4];
                return [4 /*yield*/, put(push("/invoices/detail/".concat(id)))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4 /*yield*/, success({
                    text: i18nKeys.INVOICE.SUCCESSFUL_UPDATE,
                })];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
function invoiceInfo(_a) {
    var callback, info;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, info = __rest(payload, ["callback"]);
                return [4 /*yield*/, invoiceActions.detail(payload)];
            case 1:
                _b.sent();
                return [4 /*yield*/, invoiceActions.activity(info)];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Other specific actions
 */
function invoiceSend(_a) {
    var data, response;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                data = payload === null || payload === void 0 ? void 0 : payload.data;
                if (data.by_mail && data.by_post) {
                    data.sending_media = [
                        { name: SendingMediaNames.email },
                        {
                            name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
                        },
                    ];
                }
                else if (data.by_mail) {
                    data.sending_media = [{ name: SendingMediaNames.email }];
                }
                else {
                    data.sending_media = [
                        {
                            name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
                        },
                    ];
                }
                if (data.recovery_plan_id.id) {
                    data.recovery_plan_id = data.recovery_plan_id.id;
                }
                return [4 /*yield*/, apiCall({
                        api: api.invoice.send,
                        id: payload.id,
                        data: data,
                        callback: payload.callback,
                    })];
            case 1:
                response = _b.sent();
                if (!(response && response.status === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(push('/invoices/listing'))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4 /*yield*/, invoiceActions.sendRes()];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function sendToThirdParty(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: api.invoice.sendToThirdParty,
                    id: payload.id,
                    data: payload.data,
                    callback: payload.callback,
                    actionRes: invoiceActions.actionsThirdPartyRes,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
// Download documents (see constants)
function downloadDocument(_a) {
    var type, request, filename;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                type = payload.type || payload.document_type || payload.data.document_type;
                return [4 /*yield*/, call(apiCall, {
                        api: payload.public ? api.invoice.publicDocument : api.invoice.document,
                        raw: true,
                        fileUpload: payload.fileUpload,
                        id: payload.id,
                        noLoading: payload.noLoading,
                        noFeedback: true,
                        data: __assign(__assign(__assign(__assign({}, payload.data), { document_type: type }), (payload.third_party_case_id ? { third_party_case_id: payload.third_party_case_id } : {})), (payload.document_id != null ? { document_id: payload.document_id } : null)),
                        callback: payload.callback,
                        errors: 'ERROR.DOC_NOT_AVAILABLE',
                    })];
            case 1:
                request = _b.sent();
                if (request && request.status === 200 && !payload.callback) {
                    filename = extractFilename(request.headers);
                    downloadFile(request.data, filename || "recovr.invoice-".concat(payload.id, "-").concat(type, ".").concat(type === 'invoice_ubl' ? 'xml' : 'pdf'));
                }
                return [2 /*return*/];
        }
    });
}
function importCSV(_a) {
    var nbreInvoiceByPartition, invoicesPartionned, length, i, result;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                nbreInvoiceByPartition = 500;
                invoicesPartionned = [];
                length = Math.ceil(payload.data.invoices.length / nbreInvoiceByPartition);
                i = 0;
                _b.label = 1;
            case 1:
                if (!(i < length)) return [3 /*break*/, 4];
                return [4 /*yield*/, apiCall({
                        api: api.invoice.import.csv,
                        data: {
                            invoices: payload.data.invoices.slice(i * nbreInvoiceByPartition, i * nbreInvoiceByPartition + nbreInvoiceByPartition),
                        },
                        actionRes: invoiceActions.importInvoiceCSVRes,
                        noLoading: true,
                    })];
            case 2:
                result = _b.sent();
                invoicesPartionned.push(result);
                _b.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4:
                payload.callback({
                    data: invoicesPartionned.reduce(function (acc, res) { return ((res === null || res === void 0 ? void 0 : res.status) === 200 ? acc.concat(res.data.data) : acc); }, []),
                });
                return [2 /*return*/];
        }
    });
}
function InvoiceWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.listPage.request, sendApi(api.invoice.list, invoiceActions.listPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.getInvoices.request, sendApi(api.invoice.list))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.detail.request, sendApi(api.invoice.detail, invoiceActions.detailRes))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.deleteMultiple.request, sendApi(api.invoice.deleteMultiple))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.add.request, InvoiceAdd)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.update.request, InvoiceUpdate)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.delete.request, sendApi(api.invoice.delete, invoiceActions.deleteRes))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.createInvoicePdf.request, sendApi(api.invoice.import.pdf))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.info.request, invoiceInfo)];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.setSendingOptions.request, sendApi(api.invoice.setSendingOptions, invoiceActions.setSendingOptionsRes))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.toHandle.request, sendApi(api.invoice.toHandle, invoiceActions.toHandleRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.postponable.request, sendApi(api.invoice.postponable, invoiceActions.postponableRes, function (payload) { return ({
                        noLoading: true,
                        data: payload,
                    }); }))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activity.request, sendApi(api.invoice.activity, invoiceActions.activityRes))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getActivityPage.request, sendApi(api.invoice.activity, invoiceActions.getActivityPageRes))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.previewTemplate.request, sendApi(api.invoice.previewTemplate))];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.send.request, invoiceSend)];
            case 16:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getAdditionalFiles.request, sendApi(api.invoice.additionalFile.detail, undefined, function (payload) { return (__assign(__assign({}, payload), { errors: 'ERROR.DOC_NOT_AVAILABLE' })); }))];
            case 17:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.addAdditionalFile.request, sendApi(api.invoice.additionalFile.add, invoiceActions.addAdditionalFileRes, function (payload) { return (__assign(__assign({}, payload), { fileUpload: true, success: 'SUCCESSFULLY_FILE_UPLOAD', error: 'ERROR.ERROR' })); }))];
            case 18:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.deleteAdditionalFile.request, sendApi(api.invoice.additionalFile.delete, invoiceActions.deleteAdditionalFileRes))];
            case 19:
                _b = _b.concat([
                    _c.sent()
                ]);
                // pdf
                return [4 /*yield*/, takeLatest(events.addOriginalInvoice.request, sendApi(api.invoice.additionalFile.add, undefined, function (payload) { return (__assign(__assign({}, payload), { fileUpload: true, success: 'SUCCESSFULLY_FILE_UPLOAD' })); }))];
            case 20:
                _b = _b.concat([
                    // pdf
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateOriginalFile.request, sendApi(api.invoice.updateOriginalFile, invoiceActions.updateOriginalFileRes, function (payload) { return (__assign(__assign({}, payload), { fileUpload: true, success: 'SUCCESSFULLY_FILE_UPLOAD' })); }))];
            case 21:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.document.request, downloadDocument)];
            case 22:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.importInvoiceCSV.request, importCSV)];
            case 23:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getGroupedInvoices.request, sendApi(api.invoice.getGroupedInvoices))];
            case 24:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getAllToConfirm.request, sendApi(api.invoice.import.getAllToConfirm, invoiceActions.getAllToConfirmRes, function (payload) { return ({
                        data: __assign({}, payload),
                        noLoading: true,
                    }); }))];
            case 25:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getToConfirm.request, sendApi(api.invoice.import.getToConfirm))];
            case 26:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.deleteInvoiceToConfirm.request, sendApi(api.invoice.import.deleteToConfirm, invoiceActions.deleteInvoiceToConfirmRes))];
            case 27:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.validateAllImportedInvoices.request, sendApi(api.invoice.import.validateAll, invoiceActions.validateAllImportedInvoicesRes))];
            case 28:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.copy.request, sendApi(api.invoice.copy, invoiceActions.detailRes))];
            case 29:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.assignToAccManager.request, sendApi(api.invoice.assignToAccManager))];
            case 30:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getRecoveryPlans.request, sendApi(api.invoice.getRecoveryPlans))];
            case 31:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.setRecoveryPlan.request, sendApi(api.invoice.setRecoveryPlan))];
            case 32:
                _b = _b.concat([
                    _c.sent()
                ]);
                // actions
                return [4 /*yield*/, takeLatest(events.actions.ignoreLateFees.request, sendApi(api.invoice.ignoreLateFees))];
            case 33:
                _b = _b.concat([
                    // actions
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.postponeMultiple.request, sendApi(api.invoice.postponeMultiple))];
            case 34:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.forceReminder.request, sendApi(api.invoice.forceReminder, invoiceActions.actionsForceReminderRes))];
            case 35:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.comment.request, sendApi(api.invoice.comment, invoiceActions.actionsCommentRes))];
            case 36:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.conversationMessage.request, sendApi(api.invoice.conversationMessage, function (payload) { return (__assign(__assign({}, payload), { success: 'INVOICE.SUCCESSFULLY_MESSAGE_SENT' })); }))];
            case 37:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.addPayment.request, sendApi(api.invoice.addPayment, invoiceActions.actionsAddPaymentRes, function (payload) { return (__assign(__assign({}, payload), { success: 'INVOICE.SUCCESSFULLY_ADDED_PAYMENT' })); }))];
            case 38:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.debtorReactionQrCode.request, sendApi(api.invoice.debtorReaction.qrCode))];
            case 39:
                _b = _b.concat([
                    _c.sent()
                ]);
                // mark invoice as
                return [4 /*yield*/, takeLatest(events.actions.paid.request, sendApi(api.invoice.paid))];
            case 40:
                _b = _b.concat([
                    // mark invoice as
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.lost.request, sendApi(api.invoice.lost))];
            case 41:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.pause.request, sendApi(api.invoice.pause))];
            case 42:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.disputed.request, sendApi(api.invoice.disputed))];
            case 43:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.batchAction.request, sendApi(api.invoice.batchAction, invoiceActions.actionsBatchActionRes))];
            case 44:
                _b = _b.concat([
                    _c.sent()
                ]);
                // Actions invoice to handle
                return [4 /*yield*/, takeLatest(events.actions.additionalReminder.request, sendApi(api.invoice.additionalReminder, invoiceActions.actionsAdditionalReminderRes))];
            case 45:
                _b = _b.concat([
                    // Actions invoice to handle
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.callcenter.request, sendApi(api.invoice.callcenter))];
            case 46:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.formalNotice.request, sendApi(api.invoice.createFormalNotice, invoiceActions.actionsFormalNoticeRes))];
            case 47:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.lawyer.request, sendApi(api.invoice.lawyer))];
            case 48:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.closeLawyer.request, sendApi(api.invoice.closeLawyer))];
            case 49:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.bailiff.request, sendApi(api.invoice.bailiff))];
            case 50:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.closeBailiff.request, sendApi(api.invoice.closeBailiff))];
            case 51:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.markAsToBeProccesed.request, sendApi(api.invoice.markAsToBeProccesed))];
            case 52:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.actions.thirdParty.request, sendToThirdParty)];
            case 53:
                _b = _b.concat([
                    _c.sent()
                ]);
                // debtor reaction
                return [4 /*yield*/, takeLatest(events.debtorReaction.request, sendApi(api.invoice.debtorReaction.getUnpaid))];
            case 54:
                _b = _b.concat([
                    // debtor reaction
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.debtorReactionComment.request, sendApi(api.invoice.debtorReaction.comment))];
            case 55:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.debtorReactionInvoices.request, sendApi(api.invoice.debtorReaction.getInvoices))];
            case 56:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.debtorReactionCreditNotes.request, sendApi(api.invoice.debtorReaction.getCreditNotes))];
            case 57:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getGroupedInvoicePaymentInfo.request, sendApi(api.invoice.debtorReaction.getGroupedInvoicePaymentInfo))];
            case 58: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 59:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(InvoiceWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
