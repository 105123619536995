import { __assign } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Input } from 'shared/io';
import { formatBankAccount } from 'shared/utils/normalization';
import styleIdentifiers from './BankAccounts.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankAccounts(_a) {
    var formArray = _a.formArray, className = _a.className, setFormDirty = _a.setFormDirty;
    var _b = useFormContext(), register = _b.register, setValue = _b.setValue, reset = _b.reset, getValues = _b.getValues;
    var deleteLine = function (index) { return function () {
        var bank_accounts = formArray.slice();
        bank_accounts.splice(index, 1);
        if (bank_accounts.length === 0) {
            bank_accounts.push({ iban: '', bic: '' });
        }
        reset(__assign(__assign({}, getValues()), { debtor_bank_accounts_attributes: bank_accounts }));
        setFormDirty();
    }; };
    var onSetBankAccount = function (name) { return function (value) {
        setValue(name, formatBankAccount(value.target.value));
    }; };
    return (React.createElement("div", { className: styles('BankAccounts') }, formArray === null || formArray === void 0 ? void 0 : formArray.map(function (item, index) {
        var _a, _b;
        return (React.createElement("div", { className: styles('field-wrapper'), key: index },
            React.createElement("div", null,
                React.createElement(Input, { register: register("debtor_bank_accounts_attributes.".concat(index, ".iban")), defaultValue: (_a = formArray[index]) === null || _a === void 0 ? void 0 : _a.iban, className: className, label: "IBAN", type: "text", withBorder: true, onChange: onSetBankAccount("debtor_bank_accounts_attributes.".concat(index, ".iban")) })),
            React.createElement("div", null,
                React.createElement(Input, { register: register("debtor_bank_accounts_attributes.".concat(index, ".bic")), defaultValue: (_b = formArray[index]) === null || _b === void 0 ? void 0 : _b.bic, className: className, label: "BIC", type: "text", withBorder: true })),
            React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteLine(index) })));
    })));
}
