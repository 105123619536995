import { __assign } from "tslib";
import { onBoardingConstants } from './onBoarding.actions';
export var initialState = {
    codaboxMessage: '',
    isLoading: false,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case onBoardingConstants.changeCodaboxMessage.request:
            return __assign(__assign({}, state), { codaboxMessage: action.payload });
        case onBoardingConstants.codaboxToken.request:
            return __assign(__assign({}, state), { isLoading: true });
        case onBoardingConstants.codaboxToken.result:
            return __assign(__assign({}, state), { isLoading: false });
        default:
            return state;
    }
};
export default reducer;
