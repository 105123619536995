import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Center, Image, Stack, Title } from '@mantine/core';
export var ClientScore = function (_a) {
    var score = _a.score;
    var t = useTranslation().t;
    var image = score.image, value = score.value;
    return (React.createElement(Center, null,
        React.createElement(Stack, null,
            React.createElement(Title, { order: 3 }, t(i18nKeys.DEBTOR.MONITORING.SCORE)),
            React.createElement(Image, { src: "https://recovr.s3.eu-west-3.amazonaws.com/public/company-web/".concat(image), alt: "A gauge showing the score of the company, in this case: ".concat(value), w: { base: 220, xl: 250 }, h: "100%" }))));
};
