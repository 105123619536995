import { __assign, __rest } from "tslib";
import { pick } from 'lodash-es';
export var getInitialValues = function (automatic_assignation) {
    if (!automatic_assignation) {
        return {};
    }
    var _a = automatic_assignation.attributes, assign_to = _a.assign_to, assignation_conditions = _a.assignation_conditions, rest = __rest(_a, ["assign_to", "assignation_conditions"]);
    return __assign(__assign(__assign({}, pick(rest, ['model_to_assign', 'order'])), (assign_to
        ? {
            assign_to_id: assign_to.id,
            assign_to_type: assign_to.type,
        }
        : undefined)), (assignation_conditions
        ? {
            assignation_conditions_attributes: assignation_conditions.map(function (e) { return (__assign({ id: e.id }, e.attributes)); }),
        }
        : undefined));
};
