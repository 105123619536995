import { useEffect } from 'react';
import { isNonNullish } from 'remeda';
import { useGetCompanyState } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { useAuthenticate } from './use-authenticate';
export function useLoadCompanyV1() {
    var auth0AccessToken = useAuthenticate().auth0AccessToken;
    var _a = useGetCompanyState(), isCompanyV1Loaded = _a.loaded, companyV1 = _a.data;
    useEffect(function () {
        if (isNonNullish(auth0AccessToken))
            accountActions.getCompany();
    }, [auth0AccessToken]);
    return { isCompanyV1Loaded: isCompanyV1Loaded, companyV1: companyV1 };
}
