import { __assign, __read } from "tslib";
import { useSearchInvoices } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Button } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getSearchInvoicesUrlParams } from 'shared/utils/hooks';
import { clientActions } from 'store/client/client.actions';
import { getProductLine } from '../InvoiceForm.utils';
import styleIdentifiers from './invoiceLines.scss';
import InvoiceProductLine from './InvoiceProductLine/InvoiceProductLine';
import InvoiceProductLineInvoice from './InvoiceProductLine/InvoiceProductLineInvoice';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceLines(props) {
    var noTva = props.noTva, currency = props.currency, isCreditNote = props.isCreditNote, initialValues = props.initialValues;
    var t = useTranslation().t;
    var _a = useFormContext(), control = _a.control, watch = _a.watch, setValue = _a.setValue;
    var _b = useFieldArray({
        control: control,
        shouldUnregister: true,
        name: 'product_lines_attributes',
    }), append = _b.append, remove = _b.remove, fields = _b.fields;
    var location = useLocation();
    var paramsQuery = getSearchInvoicesUrlParams(location);
    var debtor = watch('debtor_attributes');
    var productLines = watch('product_lines_attributes');
    var _c = __read(useState({
        currency: currency,
    }), 2), params = _c[0], setParams = _c[1];
    var invoicesResponse = useSearchInvoices(params).data;
    var addProduct = useCallback(function (data) {
        if (data === void 0) { data = {}; }
        return append(getProductLine(noTva, __assign({ isInvoice: false }, data)));
    }, [append, noTva]);
    var addInvoice = useCallback(function (data) {
        if (data === void 0) { data = {}; }
        return append(getProductLine(noTva, __assign({ isInvoice: true }, data)));
    }, [append, noTva]);
    useLayoutEffect(function () {
        if (Object.keys(paramsQuery).length) {
            setParams(function (currentParams) { return (__assign(__assign(__assign({}, currentParams), paramsQuery), { reference: ((paramsQuery === null || paramsQuery === void 0 ? void 0 : paramsQuery.reference) || [])[0] })); });
        }
    }, []);
    useEffect(function () {
        if (!fields.length && !isCreditNote) {
            addProduct();
        }
    }, [fields, isCreditNote, addProduct]);
    useEffect(function () {
        var _a, _b;
        if (invoicesResponse) {
            var invoices = invoicesResponse.data.filter(function (e) {
                return ((paramsQuery === null || paramsQuery === void 0 ? void 0 : paramsQuery.reference) || []).includes(e.attributes.reference);
            });
            var debtorId_1 = invoices.length ? (_b = (_a = invoices[0].attributes) === null || _a === void 0 ? void 0 : _a.debtor) === null || _b === void 0 ? void 0 : _b.id : undefined;
            if (debtorId_1) {
                clientActions.detail({
                    id: Number(debtorId_1),
                    callback: function (_a) {
                        var dataDebtor = _a.data;
                        var findedDebtor = treatClient(dataDebtor);
                        if (!findedDebtor) {
                            return;
                        }
                        setValue('debtor_attributes', findedDebtor);
                    },
                });
            }
            invoices.forEach(function (e) {
                var _a, _b;
                if (((_b = (_a = e.attributes) === null || _a === void 0 ? void 0 : _a.debtor) === null || _b === void 0 ? void 0 : _b.id) === debtorId_1) {
                    addInvoice({
                        linked_invoice_id: Number(e.id),
                        total_htva: -e.attributes.total_htva,
                        total_tvac: -e.attributes.total_tvac,
                        product_attributes: {
                            name: e.attributes.reference,
                            tax_category_code: '00',
                            current_unity_price_htva: -e.attributes.total_htva,
                        },
                    });
                }
            });
        }
    }, [invoicesResponse]);
    useEffect(function () {
        if (debtor && noTva) {
            productLines === null || productLines === void 0 ? void 0 : productLines.filter(function (e) { return !e.isInvoice; }).forEach(function (product_line, index) {
                setValue("product_lines_attributes.".concat(index, ".product_attributes.tax_category_code"), '00');
            });
        }
    }, [debtor, noTva, productLines, setValue]);
    return (React.createElement("div", { className: styles('InvoiceLines') },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: styles('name') }, t(i18nKeys.INVOICE.PRODUCT)),
                    React.createElement("td", null, t(i18nKeys.INVOICE.QTE)),
                    React.createElement("td", { className: styles('price') }, t(i18nKeys.PRICE)),
                    React.createElement("td", null, t(i18nKeys.INVOICING.CREATE.DISCOUNT_PERCENTAGE)),
                    React.createElement("td", { className: styles('amount') }, t(i18nKeys.TOTAL_NOT_VAT_PRICE)),
                    React.createElement("td", null, t(i18nKeys.INVOICING.CREATE.VAT_PERCENTAGE)),
                    React.createElement("td", { className: styles('amount') }, t(i18nKeys.TOTAL_VAT_PRICE)))),
            React.createElement("tbody", null, fields.map(function (field, index) {
                return field.isInvoice ? (React.createElement(InvoiceProductLineInvoice, __assign({}, props, { key: index, lineIndex: index, remove: remove, selected: (productLines !== null && productLines !== void 0 ? productLines : [])
                        .filter(function (e) { return e.isInvoice && e.linked_invoice_id; })
                        .map(function (e) { return Number(e.linked_invoice_id); }) }))) : (React.createElement(InvoiceProductLine, __assign({}, props, { initialValues: initialValues, key: index, field: field, removeProductLine: remove, lineIndex: index })));
            }))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.INVOICING.CREATE.ADD_PRODUCT), onClick: function () { return addProduct(); } }),
            isCreditNote && (React.createElement(Button, { noMargin: true, label: t(i18nKeys.ADD_INVOICE), onClick: function () {
                    return addInvoice({
                        product_attributes: {
                            name: '',
                            tax_category_code: '00',
                            current_unity_price_htva: 0,
                        },
                    });
                } })))));
}
