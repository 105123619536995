import { __assign, __read } from "tslib";
import React, { useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { omit } from 'lodash-es';
import Card from 'shared/components/Card';
import { CGV, CustomPreferencesFieldsName, getDefaultCgvValues, SalePreferencesFieldsName, } from 'shared/forms/PreferencesForm';
import { useGetCompany } from 'shared/utils/selectors';
import { reducerState } from 'shared/utils/view';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import SetupDebtCollectionForm from './SetupDebtCollectionForm';
import styleIdentifiers from './SetupDebtCollection.scss';
var styles = classNames.bind(styleIdentifiers);
var title = {
    1: i18nKeys.SALE_CONDITION,
    2: i18nKeys.FORM.PREFERENCES.CUSTOM_RECOVERY,
    3: i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND,
    4: i18nKeys.GENERAL_CONDITIONS,
};
export default function SetupDebtCollection(_a) {
    var _b;
    var onValidStep = _a.onValidStep;
    var _c = __read(useState(1), 2), step = _c[0], setStep = _c[1];
    var t = useTranslation().t;
    var company = useGetCompany();
    var _d = __read(useReducer(reducerState, {}), 2), formValues = _d[0], setFormValues = _d[1];
    var addDebtCollectionSettings = function (values) {
        var _a;
        onBoardingActions.setupDebtCollectionSettings({
            data: __assign(__assign({}, omit(values, [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT])), (_a = {}, _a[CGV] = values[CGV].map(function (e) { return omit(e, SalePreferencesFieldsName.PDF_BLOB); }), _a)),
            callback: function () {
                var files = values[CGV].filter(function (e) { return e[SalePreferencesFieldsName.PDF_BLOB]; });
                if (!files.length) {
                    return onValidStep();
                }
                return onBoardingActions.uploadTerms(__assign(__assign({}, files[0]), { callback: function () { return onValidStep(); } }));
            },
            actionFailure: function (response) {
                onValidStep(response.data.next_step);
            },
        });
    };
    var onSubmitStep = function (values) {
        setFormValues(values);
        if (step === 4) {
            addDebtCollectionSettings(__assign(__assign({}, formValues), values));
        }
        else {
            setStep((step + 1) % 5);
        }
    };
    var defaultValues = __assign(__assign({}, company), formValues);
    return (React.createElement("div", { className: styles('setup-debt-collection') },
        React.createElement("div", { className: styles('steps') },
            React.createElement(Card, { title: t(title[step]) },
                React.createElement(SetupDebtCollectionForm, { step: step, previousStep: function () { return setStep(step - 1); }, nextStep: onSubmitStep, defaultValues: __assign(__assign({}, defaultValues), (!defaultValues[CGV].length
                        ? (_b = {},
                            _b[CGV] = [getDefaultCgvValues(true)],
                            _b) : undefined)) })))));
}
