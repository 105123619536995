import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, InputFile, Textarea } from 'shared/io';
import styleIdentifiers from './CommentForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CommentForm = function (_a) {
    var onSubmit = _a.onSubmit, label = _a.label, oneLine = _a.oneLine, labelText = _a.labelText, rows = _a.rows, dismiss = _a.dismiss, dismissLabel = _a.dismissLabel, withFile = _a.withFile;
    var t = useTranslation().t;
    var form = useForm();
    var register = form.register, errors = form.formState.errors, setValue = form.setValue, handleSubmit = form.handleSubmit;
    var _b = __read(useState(), 2), file = _b[0], setFile = _b[1];
    var submit = function (values) {
        onSubmit(__assign(__assign({}, values), { attachment: file }));
        setValue('comment', '');
        setFile(undefined);
    };
    var onAddFile = function (newFile) {
        setFile(newFile);
        return newFile;
    };
    var removeIcon = function () {
        setFile(undefined);
    };
    if (dismiss) {
        return (React.createElement("form", { className: styles('CommentForm', oneLine && 'one-line'), onSubmit: handleSubmit(submit) },
            React.createElement(Textarea, { register: register('comment', { required: true }), error: get(errors, 'comment'), label: labelText, className: "border", rows: rows || 3, withBorder: true, oneLine: oneLine }),
            React.createElement("div", { className: styles('buttons-wrapper') },
                React.createElement(Button, { label: dismissLabel || t(i18nKeys.CANCEL), color: ButtonColor.TRANSPARENT, onClick: dismiss }),
                React.createElement(Button, { label: label, type: "submit" }))));
    }
    if (withFile) {
        return (React.createElement("form", { className: styles('CommentForm', oneLine && 'one-line'), onSubmit: handleSubmit(submit) },
            React.createElement(Textarea, { register: register('comment', { required: true }), error: get(errors, 'comment'), label: labelText, className: "border", rows: rows || 3, withBorder: true, oneLine: oneLine }),
            React.createElement("div", { className: styles('comment-actions') },
                React.createElement("span", null, file && (React.createElement("span", { className: styles('file') },
                    React.createElement(Icon, { name: IconName.FILE }),
                    React.createElement("span", null, file.name),
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, className: styles('remove-icon'), onClick: removeIcon })))),
                React.createElement("div", { className: styles('buttons-container') },
                    React.createElement(InputFile, { accept: "", onChange: onAddFile, color: ButtonColor.WHITE, name: "attachment", label: t(i18nKeys.ADD_FILE) }),
                    React.createElement(Button, { label: label || t(i18nKeys.FORM.ADD_COMMENT), type: "submit", color: ButtonColor.BLUE, noMargin: true })))));
    }
    return (React.createElement("form", { className: styles('CommentForm', oneLine && 'one-line'), onSubmit: handleSubmit(submit) },
        React.createElement(Textarea, { register: register('comment', { required: true }), error: get(errors, 'comment'), label: labelText, className: "border", rows: rows || 3, withBorder: true, oneLine: oneLine }),
        React.createElement(Button, { label: label || t(i18nKeys.FORM.ADD_COMMENT), type: "submit", color: ButtonColor.GREY })));
};
