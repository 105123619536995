import React from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { useGetCompany } from 'shared/utils/selectors';
import { Box } from '@mantine/core';
import { AgedBalance } from './pages/AgedBalance';
import { Overview } from './pages/Overview';
export var Analytics = function () {
    var history = useHistory();
    var company = useGetCompany();
    if (!(company === null || company === void 0 ? void 0 : company.package.can_use_analytics)) {
        history.push('/dashboard');
    }
    return (React.createElement(Box, { w: "80%", mx: "auto" },
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/analytics/aged-balance", component: AgedBalance }),
            React.createElement(Route, { path: "/analytics", component: Overview }))));
};
