import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from '../PaymentPlanDetailDebtor.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentStepsDebtor(_a) {
    var paymentSteps = _a.paymentSteps, currency = _a.currency;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var renderStep = function (date, remainingBalance) { return (React.createElement("div", { className: styles('key-text') },
        React.createElement("div", { className: styles('attribute') },
            formattedDate(date, currentLang),
            " "),
        React.createElement("div", { className: styles('value') },
            React.createElement(Amount, { localizedValue: remainingBalance, value: remainingBalance, suffix: currency })))); };
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS)),
        paymentSteps.map(function (_a) {
            var _b = _a.attributes, due_date = _b.due_date, remaining_balance = _b.remaining_balance;
            return (React.createElement(React.Fragment, null, renderStep(due_date, remaining_balance)));
        })));
}
