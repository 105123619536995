import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Checkbox, Input } from 'shared/io';
import { Group, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { CGV, SalePreferencesFieldsName } from '../PreferencesForm.constants';
import { getDefaultCgvValues, getFieldErrors } from './SalePreferencesPartialForm.utils';
import { SalePreferencesPartialFormItems } from './SalePreferencesPartialFormItems';
import { SalePreferencesPartialFormUpload } from './SalePreferencesPartialFormUpload';
import styleIdentifiers from './SalePreferencesPartialForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var SalePreferencesPartialForm = function (_a) {
    var _b = _a.onboarding, onboarding = _b === void 0 ? false : _b, _c = _a.api, api = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    var _d = useFormContext(), watch = _d.watch, getValues = _d.getValues, setValue = _d.setValue, register = _d.register, errors = _d.formState.errors;
    var _e = useFieldArray({
        name: CGV,
    }), fields = _e.fields, append = _e.append, remove = _e.remove;
    var values = watch(CGV);
    if (onboarding) {
        register("".concat(CGV, ".0.").concat(SalePreferencesFieldsName.DEFAULT));
    }
    var removeField = function (index) {
        var _a;
        if ((_a = values[index]) === null || _a === void 0 ? void 0 : _a.id) {
            setValue("".concat(CGV, ".").concat(index, ".").concat(SalePreferencesFieldsName.DESTROY), true, // React hook form typescript fix
            {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
        else {
            remove(index);
        }
        if (values[index].default) {
            setValue("".concat(CGV, ".").concat(values.findIndex(function (e) { return !e[SalePreferencesFieldsName.DESTROY]; }), ".").concat(SalePreferencesFieldsName.DEFAULT), true, {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
    };
    var setAsPrimary = function (index) {
        values.forEach(function (value, i) {
            setValue("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.DEFAULT), i === index);
        });
    };
    var notDestroyedValues = values.filter(function (e) { return !e[SalePreferencesFieldsName.DESTROY]; });
    var deletable = function (index) {
        var tos = values[index];
        return (!tos.default && tos.deletable) || !(tos === null || tos === void 0 ? void 0 : tos.id);
    };
    return (React.createElement("ul", { className: styles('sale-conditions-partial-form') },
        fields.map(function (field, i) {
            var fieldErrors = getFieldErrors(errors, i);
            register("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.ID));
            if (watch("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.DESTROY))) {
                return undefined;
            }
            return (React.createElement("li", { key: field.id, className: styles('grid-row') },
                React.createElement("div", { className: styles(!api ? 'col-4' : 'col-6') },
                    React.createElement(Input, { noMargin: true, type: "text", label: t(i18nKeys.NAME), errorMessage: fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.name, register: register("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.NAME), {
                            required: true,
                            validate: function (value) {
                                return getValues(CGV)
                                    .filter(function (e, index) { return i !== index && !e[SalePreferencesFieldsName.DESTROY]; })
                                    .map(function (e) { return e[SalePreferencesFieldsName.NAME]; })
                                    .includes(value)
                                    ? i18nKeys.ERROR.MUST_BE_DIFFERENT
                                    : undefined;
                            },
                        }) })),
                !api && (React.createElement("div", { className: styles('col-4') },
                    React.createElement(Input, { noMargin: true, type: "number", min: "0", label: t(i18nKeys.FORM.PREFERENCES.DEFAULT_PAYMENT_DELAY), errorMessage: fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.minimum_payment_terms_delay, register: register("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.MINIMUM_DELAY), {
                            required: true,
                            min: 0,
                        }) }))),
                !onboarding && (React.createElement("div", { className: styles('col-3') },
                    React.createElement(Checkbox, { noMargin: true, watch: watch, className: styles('input', 'checkbox-default'), label: t(i18nKeys.ACTIONS.USE_DEFAULT), disabled: notDestroyedValues.length <= 1, onChange: function () {
                            setAsPrimary(i);
                        }, register: register("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.DEFAULT)) }))),
                notDestroyedValues.length > 1 && deletable(i) && (React.createElement("div", { className: styles('col-1') },
                    React.createElement("div", { className: styles('delete') },
                        React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function () { return removeField(i); }, size: "22px" })))),
                React.createElement(Group, { mt: "md" },
                    React.createElement(Checkbox, { noMargin: true, watch: watch, label: t(i18nKeys.ACTIONS.USE_BELGIAN_LAW), register: register("".concat(CGV, ".").concat(i, ".").concat(SalePreferencesFieldsName.USE_BELGIAN_LAW)) }),
                    React.createElement(Tooltip, { label: t(i18nKeys.ACTIONS.TOOLTIP) },
                        React.createElement(IconInfoCircle, null))),
                onboarding ? (React.createElement("div", { className: styles('col-12') },
                    React.createElement(SalePreferencesPartialFormItems, { parent: i }))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('col-10'), style: { width: '80%' } },
                        React.createElement(SalePreferencesPartialFormItems, { parent: i })),
                    React.createElement("div", { className: styles('col-2'), style: { width: '20%' } },
                        React.createElement(SalePreferencesPartialFormUpload, { parent: i }))))));
        }),
        !onboarding && (React.createElement(Button, { color: ButtonColor.BLUE, label: t(i18nKeys.ACTIONS.ADD_SALE_CONDITION), iconRight: IconName.PLUS, onClick: function () { return append(getDefaultCgvValues()); } }))));
};
