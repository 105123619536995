import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useEffect } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { first, partition } from 'remeda';
import { useSendToThirdParty } from 'shared/hooks';
import { useGetCompany, useGetInvoiceState } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Button, Group, NumberInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { GcollectModalValidate } from './GcollectModalValidate';
// Either calls the API right away, if the codes are present, or prompts the user for them in a form, then calls the API
export function GcollectModal(_a) {
    var _this = this;
    var _b, _c;
    var invoiceIds = _a.invoiceIds, gcollectId = _a.gcollectId, _d = _a.additionalInvoiceIds, additionalInvoiceIds = _d === void 0 ? [] : _d;
    var t = useTranslation().t;
    var company = useGetCompany();
    var _e = __read(React.useState(false), 2), wereCodesMissing = _e[0], setWereCodesMissing = _e[1];
    var _f = useSendToThirdParty(), sendToThirdParty = _f.sendToThirdParty, isSendToThirdPartyLoading = _f.isSendToThirdPartyLoading;
    var isBatchActionLoading = useGetInvoiceState().isLoading;
    var form = useForm({
        initialValues: {
            sirenCode: (_b = company === null || company === void 0 ? void 0 : company.siren) !== null && _b !== void 0 ? _b : '',
            apeCode: (_c = company === null || company === void 0 ? void 0 : company.ape_code) !== null && _c !== void 0 ? _c : '',
        },
        validateInputOnBlur: true,
        validate: {
            sirenCode: function (value) {
                if (value == null || value === '') {
                    return t(i18nKeys.ERROR.FIELD_REQUIRED);
                }
                // todo: The input should actually be text, not number, as it's not a number but a string of digits
                // this would avoid the incorrect typing  that make s the string cast below necessary.
                if (String(value).length !== 9) {
                    return t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.SIREN_LENGTH_ERROR);
                }
                return null;
            },
            apeCode: function (value) {
                if (value == null || value === '') {
                    return t(i18nKeys.ERROR.FIELD_REQUIRED);
                }
                if (value.match(/\d{4}[a-zA-Z]/) == null) {
                    return t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.APE_CODE_FORMAT);
                }
                return null;
            },
        },
    });
    var isBatch = invoiceIds.length > 1;
    var handleNextStep = function (caseIds, errors) {
        modals.open({
            title: t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.CONFIRM_RATES),
            size: 'fit-content',
            centered: true,
            closeOnClickOutside: false,
            withCloseButton: false,
            children: React.createElement(GcollectModalValidate, { caseIds: caseIds, errors: errors }),
        });
    };
    // The batch or send_to_third_party calls will update Company with the SIREN and APE codes if they were missing from it.
    // We refetch company so that users won't be prompted a second time if they open another case right after the current one.
    var handleReloadCompany = function () {
        if (wereCodesMissing) {
            accountActions.getCompany();
        }
    };
    var openSingleCase = function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
        var options;
        var sirenCode = _b.sirenCode, apeCode = _b.apeCode;
        return __generator(this, function (_c) {
            options = {
                id: first(invoiceIds),
                data: {
                    third_party_company_id: gcollectId,
                    additional_invoices: additionalInvoiceIds,
                    siren: String(sirenCode),
                    ape_code: apeCode,
                },
            };
            sendToThirdParty(options, {
                onSuccess: function (data) {
                    handleNextStep(data.third_party_case_ids);
                    handleReloadCompany();
                },
            });
            return [2 /*return*/];
        });
    }); };
    var openBatchCase = function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
        var sirenCode = _b.sirenCode, apeCode = _b.apeCode;
        return __generator(this, function (_c) {
            invoiceActions.actionsBatchAction({
                data: {
                    third_party_company_id: gcollectId,
                    siren: String(sirenCode),
                    ape_code: apeCode,
                    action_type: 'third_party',
                    invoice_ids: invoiceIds,
                },
                callback: function (res) {
                    var _a = __read(partition(res, function (x) { return x.third_party_case_id != null; }), 2), cases = _a[0], errors = _a[1];
                    handleNextStep(cases.map(function (_a) {
                        var third_party_case_id = _a.third_party_case_id;
                        return third_party_case_id;
                    }), errors);
                    handleReloadCompany();
                },
            });
            return [2 /*return*/];
        });
    }); };
    var openCase = isBatch ? openBatchCase : openSingleCase;
    var isLoading = isBatch ? isBatchActionLoading : isSendToThirdPartyLoading;
    useEffect(function () {
        if (company != null && company.siren != null && company.ape_code != null) {
            openCase({ sirenCode: company.siren, apeCode: company.ape_code });
        }
        else {
            setWereCodesMissing(true);
        }
    }, []);
    return (React.createElement("form", { onSubmit: form.onSubmit(function (values) { return openCase(values); }) },
        React.createElement(NumberInput, __assign({ disabled: isLoading, withAsterisk: true, label: "SIREN", placeholder: "123456789", min: 0, allowDecimal: false, allowLeadingZeros: true, allowNegative: false, hideControls: true, valueIsNumericString: true }, form.getInputProps('sirenCode'))),
        React.createElement(TextInput, __assign({ disabled: isLoading, mt: "md", withAsterisk: true, label: "APE", placeholder: "1234E" }, form.getInputProps('apeCode'))),
        React.createElement(Group, { justify: "flex-end", mt: "md" },
            React.createElement(Button, { type: "submit", loading: isLoading }, t(i18nKeys.SUBMIT)))));
}
