import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import Switch from 'react-switch';
import { validationMessage } from 'shared/utils/validation';
import { useMantineTheme } from '@mantine/core';
import styleIdentifiers from './SwitchInput.scss';
var styles = classNames.bind(styleIdentifiers);
export var SwitchInput = function (_a) {
    var label = _a.label, labelClassName = _a.labelClassName, noMargin = _a.noMargin, className = _a.className, inversed = _a.inversed, onChange = _a.onChange, error = _a.error, value = _a.value, noShadow = _a.noShadow, rest = __rest(_a, ["label", "labelClassName", "noMargin", "className", "inversed", "onChange", "error", "value", "noShadow"]);
    var t = useTranslation().t;
    var theme = useMantineTheme();
    return (React.createElement("div", { className: styles('SwitchInput', className, noMargin && 'no-margin') },
        React.createElement("div", { className: styles('switch-wrapper', inversed && 'inversed') },
            React.createElement("div", { className: styles('label', labelClassName) }, label),
            React.createElement(Switch, __assign({ onChange: onChange }, rest, { checked: value || false, handleDiameter: 20, height: 18, width: 34, onColor: theme.colors.blue[7], boxShadow: theme.shadows.xs, checkedIcon: false, className: styles('switch-input', !noShadow && 'shadow'), uncheckedIcon: false }))),
        error && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[error.type] || error.message || error)))));
};
