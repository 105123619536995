import React, { useEffect } from 'react';
import { useAuthenticateDomain, useDeleteDomain, useGetEmailDomain } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { CustomDomainFieldsName } from '../PreferencesForm.constants';
import { DnsSettings } from './DnsSettings';
import styleIdentifiers from '../PreferencesForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomDomainPartialForm = function () {
    var t = useTranslation().t;
    var can_use_custom_domain = useSelector(function (state) { return state.account.company.data; }).package.can_use_custom_domain;
    var _a = useGetEmailDomain(), customDomainResponse = _a.data, refetch = _a.refetch;
    var authenticateDomain = useAuthenticateDomain().mutate;
    var deleteDomain = useDeleteDomain().mutate;
    var _b = useFormContext(), register = _b.register, watch = _b.watch, setValue = _b.setValue;
    var customDomain = (customDomainResponse || {}).data;
    var onSubmit = function () {
        authenticateDomain({ data: { custom_domain: watch('custom_domain') } }, {
            onSuccess: function () {
                refetch();
            },
        });
    };
    useEffect(function () {
        if (customDomain) {
            setValue(CustomDomainFieldsName.CUSTOM_DOMAIN, customDomain.attributes.domain);
        }
    }, [customDomain]);
    var openDnsSettings = function () {
        if (customDomain) {
            showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.MEDIUM,
                title: t(i18nKeys.CUSTOM_DOMAIN.DNS_SETTINGS),
                children: React.createElement(DnsSettings, { domainId: customDomain.id }),
            });
        }
    };
    var handleDeleteDomain = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_DOMAIN), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteDomain({ id: customDomain.id }, {
                        onSuccess: function () {
                            refetch();
                        },
                    });
                } })),
        });
    };
    var customDomainValue = watch('custom_domain');
    // https://regexr.com/3au3g
    var domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    var isDomainValid = customDomain && customDomain.attributes.domain_valid;
    return (React.createElement("div", { className: styles('col-12') },
        React.createElement("h4", { className: styles('margin') }, t(i18nKeys.CUSTOM_DOMAIN.SECTION_TITLE)),
        React.createElement("div", { className: styles('custom-domain-partial-form') },
            React.createElement("div", { className: styles('domain-setup') },
                React.createElement(Input, { register: register(CustomDomainFieldsName.CUSTOM_DOMAIN), label: t(i18nKeys.CUSTOM_DOMAIN.NAME), disabled: !can_use_custom_domain || Boolean(customDomain), title: can_use_custom_domain ? '' : t(i18nKeys.NOT_INCLUDED), noMargin: true }),
                React.createElement("div", { className: styles('domain-button-wrapper') }, customDomain ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: handleDeleteDomain, label: (React.createElement(Icon, { name: IconName.TRASH_SIMPLE })), color: ButtonColor.RED, className: styles('delete-button'), small: true, noMargin: true }),
                    React.createElement(Button, { onClick: openDnsSettings, label: t(i18nKeys.CUSTOM_DOMAIN.VERIFY), color: ButtonColor.BLUE, small: true, noMargin: true }))) : (React.createElement(Button, { onClick: onSubmit, label: t(i18nKeys.CUSTOM_DOMAIN.AUTHENTICATE), color: ButtonColor.GREEN, noMargin: true, small: true, disabled: customDomainValue === '' ||
                        customDomainValue === null ||
                        !domainRegex.test(customDomainValue) })))),
            customDomain && (React.createElement("div", { className: styles('default-from') },
                React.createElement(Input, { register: register(CustomDomainFieldsName.DEFAULT_FROM), label: t(i18nKeys.CUSTOM_DOMAIN.DEFAULT_FROM), noMargin: true, disabled: !isDomainValid, placeholder: isDomainValid ? 'domain.com' : t(i18nKeys.CUSTOM_DOMAIN.NOT_YET_VERIFIED) }),
                React.createElement("div", { className: styles('at-domain') },
                    React.createElement("p", null,
                        "@",
                        customDomain.attributes.domain)))))));
};
