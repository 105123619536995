import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Icon, IconName } from 'shared/components/Icon';
import { invoiceActions } from 'store/invoice/invoice.actions';
import styleIdentifiers from './downloadInvoice.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DownloadInvoice(_a) {
    var className = _a.className, invoice = _a.invoice, ubl = _a.ubl, text = _a.text, importOriginalInvoice = _a.importOriginalInvoice;
    var t = useTranslation().t;
    if (!(invoice === null || invoice === void 0 ? void 0 : invoice.id)) {
        return null;
    }
    var id = invoice.id, hasPdf = invoice.has_pdf;
    var handleDownload = function (type) { return function () {
        invoiceActions.document({
            id: id,
            type: type === 'ubl' ? 'invoice_ubl' : 'invoice_pdf',
        });
    }; };
    return (React.createElement("div", { className: styles('DownloadInvoice', className) },
        (hasPdf || importOriginalInvoice) && (React.createElement(Icon, { title: t(i18nKeys.INVOICE.DOWNLOAD_PDF), onClick: importOriginalInvoice || handleDownload('pdf'), name: IconName.PDF, className: styles('icon') })),
        text && (React.createElement("span", { onClick: handleDownload('pdf'), className: styles('text') }, t(i18nKeys.INVOICE.DOWNLOAD_PDF))),
        ubl && (React.createElement(Icon, { name: IconName.XML, onClick: handleDownload('ubl'), className: styles('xml', 'icon') }))));
}
