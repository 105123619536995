import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './AmountFields.scss';
var styles = classNames.bind(styleIdentifiers);
export var AmountFields = function (_a) {
    var className = _a.className, label = _a.label, labelOperator = _a.labelOperator, placeholder = _a.placeholder, name = _a.name, operatorName = _a.operatorName, noMin = _a.noMin, onChange = _a.onChange;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var operator_name = operatorName || "".concat(name, "_operator");
    var register = useFormContext().register;
    return (React.createElement("div", { className: styles('AmountFields', className) },
        label && React.createElement("div", { className: styles('label-input') }, label),
        React.createElement("div", { className: styles('fields') },
            React.createElement(Controller, { defaultValue: "", name: operator_name, render: function () { return (React.createElement(CustomSelect, { onValueChanged: onChange, selectClassName: styles('operator'), size: "small", noMargin: true, items: constants.operators, name: operator_name, placeholder: labelOperator || t(i18nKeys.FORM.CHOICE), keyText: "description", keyValue: "value", noBorder: true })); } }),
            React.createElement(Input, { className: styles('value'), register: register(name), type: "number", step: "0.01", defaultValue: "", min: !noMin ? '0' : undefined, placeholder: placeholder || t(i18nKeys.FORM.NUMBER), onValueChanged: onChange, noBorder: true }))));
};
