import React from 'react';
import Amount from 'shared/components/Amount';
import { Divider, Text } from '@mantine/core';
import { PaymentLabel } from './Labels';
export var PaymentRow = function (_a) {
    var payment = _a.payment;
    var communication = payment.communication, issueDate = payment.issueDate, remainingBalance = payment.remainingBalance;
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement(PaymentLabel, null)),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem", ta: "left", flex: 1 }, communication)),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem" }, status || '-')),
            React.createElement("td", { style: { minWidth: '100px' } },
                React.createElement(Text, { fz: "0.9rem" },
                    " ",
                    issueDate ? issueDate.format('DD/MM/YY') : '-')),
            React.createElement("td", { style: { minWidth: '100px' } }),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem", c: "blue" })),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem", c: "red" },
                    React.createElement(Amount, { value: remainingBalance }))),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem" },
                    React.createElement(Amount, { value: remainingBalance * -1 })))),
        React.createElement("tr", null,
            React.createElement("td", { colSpan: 8, style: { padding: '0' } },
                React.createElement(Divider, { p: 0 })))));
};
