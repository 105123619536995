import { __assign, __read, __rest, __values } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'remeda';
import { Button, Checkbox, RadioButton } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './ExportCsv.scss';
var styles = classNames.bind(styleIdentifiers);
export function ExportCsv(_a) {
    var downloadCsv = _a.downloadCsv, wrappers = _a.wrappers, allFields = _a.allFields, selectFilteredLabel = _a.selectFilteredLabel, selectAllLabel = _a.selectAllLabel, _b = _a.overrideDefaultSelection, overrideDefaultSelection = _b === void 0 ? [] : _b, _c = _a.shouldHideFilterOption, shouldHideFilterOption = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    var _d = __read(useState({
        selectAll: false,
    }), 2), checkboxWrappers = _d[0], setCheckboxWrappers = _d[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            attributes: {},
            export_with_filters: false,
        },
    });
    var getValues = form.getValues, register = form.register, watch = form.watch, reset = form.reset, control = form.control;
    // Required for the useEffect below to trigger re-renders
    watch('attributes');
    useEffect(function () {
        if (isEmpty(overrideDefaultSelection))
            return;
        overrideDefaultSelection.forEach(function (field) { return form.setValue("attributes.".concat(field), true); });
    }, [overrideDefaultSelection]);
    var resetForm = function (fields, value) {
        var e_1, _a;
        if (value !== undefined) {
            var formValues = getValues();
            var resetValues = {
                attributes: {},
            };
            try {
                for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                    var field = fields_1_1.value;
                    resetValues.attributes[field.value] = value;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            reset(__assign(__assign({}, formValues), { attributes: __assign(__assign({}, formValues.attributes), resetValues.attributes) }));
        }
    };
    var setCheckFields = function (type) { return function (value) {
        resetForm(type === 'selectAll' ? allFields : wrappers.find(function (_a) {
            var name = _a.name;
            return name === type;
        }).fields, value);
        var wrapperValue = {};
        wrappers.forEach(function (_a) {
            var name = _a.name;
            wrapperValue[name] = type === 'selectAll' || type === name ? value : checkboxWrappers[name];
        });
        setCheckboxWrappers(__assign({ selectAll: type === 'selectAll' ? value : checkboxWrappers.selectAll }, wrapperValue));
    }; };
    var submit = function () {
        dialogHide(DialogShowId.CUSTOM);
        downloadCsv(getValues());
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("div", { className: styles('export-csv') },
            !shouldHideFilterOption && (React.createElement(Controller, { control: control, name: "export_with_filters", render: function (_a) {
                    var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                    return (React.createElement(RadioButton, __assign({}, values, { className: styles('export-mode-container'), items: [
                            { value: false, label: selectAllLabel },
                            { value: true, label: selectFilteredLabel },
                        ] })));
                } })),
            React.createElement("div", { className: styles('summary') }, t(i18nKeys.INVOICING.EXPORT_CSV.PLEASE_SELECT_FIELDS)),
            React.createElement("div", null,
                React.createElement(Checkbox, { checked: checkboxWrappers.selectAll, onChange: setCheckFields('selectAll'), label: t(i18nKeys.FORM.SELECT_ALL), name: "selectAll" })),
            wrappers.map(function (_a) {
                var fields = _a.fields, name = _a.name, text = _a.text;
                return fields.length === 0 ? ('') : (React.createElement("div", { key: name },
                    React.createElement("div", { className: styles('subtitle') },
                        React.createElement(Checkbox, { checked: checkboxWrappers[name], noMargin: true, name: name, onChange: function () { return setCheckFields(name)(!checkboxWrappers[name]); } }),
                        text),
                    React.createElement("div", { className: styles('atttribute-list') }, fields.map(function (field) { return (React.createElement("div", { key: field.value, className: styles('attribute-item') },
                        React.createElement(Checkbox, { register: register("attributes[".concat(field.value, "]")), watch: watch, label: field.description }))); }))));
            }),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.INVOICE.DOWNLOAD_CSV), onClick: submit })))));
}
