import React, { memo } from 'react';
import { times } from 'remeda';
import { PageTitle } from 'shared/layout';
import { Box, Group, Skeleton, Stack } from '@mantine/core';
import { Kanban } from '.';
export var KanbanLoadingState = memo(function (_a) {
    var columnCount = _a.columnCount, title = _a.title;
    var cols = times(columnCount, function (index) {
        var cardAmount = Math.round(Math.random() * 15) + 4;
        return (React.createElement(Kanban.Column, { key: index, color: "gray", title: React.createElement(Skeleton, { width: "100%", h: "40px" }) }, times(cardAmount, function (cardIndex) {
            return (React.createElement(Kanban.Card, { key: cardIndex },
                React.createElement(Stack, { justify: "space-between", h: 90 },
                    React.createElement(Skeleton, { h: 15, w: "80%" }),
                    React.createElement(Group, { justify: "space-between" },
                        React.createElement(Skeleton, { h: 10, w: 50 }),
                        React.createElement(Skeleton, { h: 10, w: 40 })))));
        })));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { h: "60px" },
            React.createElement(PageTitle, null,
                title,
                React.createElement(PageTitle.Actions, null,
                    React.createElement(Skeleton, { width: 100, h: 30 })))),
        React.createElement(Box, { h: "calc(100% - 60px)" },
            React.createElement(Kanban, null, cols))));
});
