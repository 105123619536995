import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Checkbox, DateSelector, Input, Textarea } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { getInitialValues } from '../Task.utils';
import { TaskFormColleague } from './TaskFormColleague';
import { TaskFormDebtor } from './TaskFormDebtor';
import { TaskFormInvoices } from './TaskFormInvoices';
import { TaskFormType } from './TaskFormType';
import styleIdentifiers from './TaskForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskForm = function (_a) {
    var _b, _c, _d;
    var onSubmit = _a.onSubmit, task = _a.task, close = _a.close, clientId = _a.clientId, batch = _a.batch, invoiceId = _a.invoiceId, debtors = _a.debtors;
    var t = useTranslation().t;
    var isInvoiceLoading = useGetInvoiceState().isLoading;
    var isClientLoading = useGetClientState().isLoading;
    var isLoading = isInvoiceLoading || isClientLoading;
    var defaultValues = __assign({ title: '', description: '', task_type: '', debtor_id: clientId, group_same_debtor: true }, getInitialValues(task));
    var form = useForm({
        shouldUnregister: true,
        defaultValues: defaultValues,
    });
    var setValue = form.setValue, register = form.register, watch = form.watch, _e = form.formState, isDirty = _e.isDirty, errors = _e.errors, handleSubmit = form.handleSubmit;
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var minDueDate = dayjs();
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('task-form', batch && 'no-width'), onSubmit: handleSubmit(onSubmit) },
            !batch && (React.createElement("div", { className: styles('head') },
                t(task ? i18nKeys.FORM.TASK.EDIT : i18nKeys.FORM.TASK.NEW),
                close && (React.createElement("div", { className: styles('close'), onClick: close },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, size: "20px" }))))),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { register: register('title', {
                                required: true,
                            }), errorMessage: get(errors, 'title'), noMargin: true, withBorder: true, label: t(i18nKeys.TASK.ATTRIBUTES.TITLE) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Textarea, { register: register('description'), className: styles('input'), withBorder: true, label: t(i18nKeys.DESCRIPTION) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(TaskFormColleague, null)),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(TaskFormType, null)),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { name: "due_date", render: function () { return (React.createElement(DateSelector, { label: t(i18nKeys.DUE_DATE), name: "due_date", withBorder: true, className: styles('input', 'date-selector'), inputClassName: styles('value'), errorClassName: styles('error'), minDate: minDueDate.format('DD/MM/YYYY'), placeholder: minDueDate.format('DD/MM/YYYY'), handleChange: function (value) {
                                    setValue('due_date', value);
                                } })); } })),
                    !batch && !invoiceId && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles('col-12') },
                            React.createElement(TaskFormDebtor, { currentDebtor: (_b = task === null || task === void 0 ? void 0 : task.attributes) === null || _b === void 0 ? void 0 : _b.debtor })),
                        React.createElement("div", { className: styles('col-12') },
                            React.createElement(TaskFormInvoices, { attachedInvoices: (_d = (_c = task === null || task === void 0 ? void 0 : task.attributes) === null || _c === void 0 ? void 0 : _c.invoices) === null || _d === void 0 ? void 0 : _d.details })))),
                    batch && !debtors && (React.createElement("div", { className: styles('col-12') },
                        React.createElement(Checkbox, { label: t(i18nKeys.FORM.TASK.GROUP_SAME_DEBTOR), name: "group_same_debtor", register: register('group_same_debtor'), watch: watch }))))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { isLoading: isLoading, type: "submit", label: t(i18nKeys.SAVE), noShadow: true, noMargin: true })))));
};
