import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { useCreateReason, useGetReasons } from 'api';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, DateSelector } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Checkbox } from '@mantine/core';
import { ReasonForm } from '../ReasonForm/ReasonForm';
import styleIdentifiers from './SetReasonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var SetReasonForm = function (_a) {
    var onSubmit = _a.onSubmit, reasonType = _a.reasonType;
    var t = useTranslation().t;
    var isInvoiceLoading = useGetInvoiceState().isLoading;
    var isClientLoading = useGetClientState().isLoading;
    var isLoading = isInvoiceLoading || isClientLoading;
    var _b = __read(useState(false), 2), hasEndDate = _b[0], setHasEndDate = _b[1];
    var _c = __read(useState(), 2), selectedReason = _c[0], setSelectedReason = _c[1];
    useEffect(function () {
        setHasEndDate(selectedReason === 'promise_of_payment');
    }, [selectedReason]);
    var reasonsListResponse = useGetReasons({
        reason_type: reasonType,
    }).data;
    var form = useForm({
        shouldUnregister: true,
    });
    var handleSubmit = form.handleSubmit, setValue = form.setValue;
    var minDueDate = dayjs().add(1, 'day');
    var reasons = (reasonsListResponse === null || reasonsListResponse === void 0 ? void 0 : reasonsListResponse.data.map(function (e) { return ({
        description: e.attributes.name,
        value: e.id,
        uid: e.attributes.uid,
    }); })) || [];
    var _d = __read(useState(undefined), 2), newReasonsList = _d[0], setNewReasonsList = _d[1];
    var createReason = useCreateReason().mutate;
    var onSubmitReason = function (data) {
        return createReason({ data: data }, {
            onSuccess: function (_a) {
                var newReason = _a.data;
                setNewReasonsList(__spreadArray(__spreadArray([], __read(reasons), false), [
                    {
                        description: newReason.attributes.name,
                        value: newReason.id,
                    },
                ], false));
                setValue('reason_id', newReason.id.toString());
                dialogHide(DialogShowId.CONFIRM);
            },
        });
    };
    var addReason = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.ADD_REASON),
            children: (React.createElement(ReasonForm, { onSubmit: onSubmitReason, selectedType: reasonType, onCancel: function () { return dialogHide(DialogShowId.CONFIRM); } })),
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('reason-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('field-type') },
                React.createElement(Controller, { rules: { required: true }, name: "reason_id", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "reason_id", label: t(i18nKeys.FORM.REASON), items: newReasonsList || reasons, filter: true, onChange: function (value) {
                            var selectedItem = reasons.find(function (item) { return item.value === value; });
                            setSelectedReason(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uid);
                        } })); } }),
                React.createElement(Button, { label: "+", title: t(i18nKeys.ADD_TASK_TYPE), onClick: addReason, noMargin: true })),
            reasonType === 'pause' && (React.createElement("div", { className: styles('content') },
                React.createElement(Checkbox, { mt: "xl", size: "sm", label: t(i18nKeys.FORM.ADD_END_DATE), checked: hasEndDate, onChange: function (event) { return setHasEndDate(event.currentTarget.checked); } }),
                React.createElement(AnimatePresence, null, hasEndDate && (React.createElement(motion.div, { initial: { height: 0 }, animate: { height: 'auto' }, transition: { duration: 0.3 }, exit: { height: 0 }, key: "container" },
                    React.createElement(Controller, { name: "end_at", render: function () { return (React.createElement(DateSelector, { label: t(i18nKeys.FORM.END_DATE), name: "end_at", withBorder: true, required: true, className: styles('input', 'date-selector'), inputClassName: styles('value'), errorClassName: styles('error'), minDate: minDueDate.toDate(), placeholder: t(i18nKeys.FORM.SELECT_DATE), handleChange: function (value) {
                                setValue('end_at', value);
                            } })); } })))))),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { type: "submit", isLoading: isLoading, label: t(i18nKeys.CONFIRM) })))));
};
