import React, { isValidElement } from 'react';
import { Box, LoadingOverlay, ScrollArea, Stack, useMantineTheme } from '@mantine/core';
export var KanbanColumn = function (_a) {
    var children = _a.children, title = _a.title, color = _a.color, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var theme = useMantineTheme();
    var arrayChildren = React.Children.toArray(children).filter(isValidElement);
    var cards = arrayChildren.map(function (child) {
        // @ts-ignore todo: Fix typing
        return React.cloneElement(child, { color: color });
    });
    return (React.createElement(Stack, { gap: 10, h: "100%", miw: "calc(var(--kanban-min-width) + 20px)", style: { borderRadius: '4px' }, bg: "#ececec", pos: "relative" },
        React.createElement(LoadingOverlay, { zIndex: 1, visible: isLoading, overlayProps: { radius: 'sm', color: theme.colors.gray[4], backgroundOpacity: 0.75 } }),
        React.createElement(Box, { p: 10, pb: 0, mb: "xs", h: 62 }, title),
        React.createElement(ScrollArea, { mr: 2, scrollHideDelay: 0, scrollbarSize: 6 },
            React.createElement(Box, { pl: 10, pr: 8, pb: 16 },
                React.createElement(Stack, { gap: 8 }, cards)))));
};
