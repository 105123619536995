import { __assign, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import { Range } from 'react-range';
import { validationMessage } from 'shared/utils/validation';
import { useMantineTheme } from '@mantine/core';
import styleIdentifiers from './Slider.scss';
var styles = classNames.bind(styleIdentifiers);
export var Slider = function (_a) {
    var noError = _a.noError, currentValue = _a.currentValue, error = _a.error, noErrorText = _a.noErrorText, maxVal = _a.maxVal, step = _a.step, onSelect = _a.onSelect;
    var t = useTranslation().t;
    var _b = __read(useState(0), 2), value = _b[0], setValue = _b[1];
    var sliderRef = useRef(null);
    var theme = useMantineTheme();
    useEffect(function () {
        if (currentValue || currentValue === 0) {
            setValue(currentValue);
        }
    }, [currentValue]);
    var showError = !noError && error;
    var onChange = function (newValue) {
        setValue(newValue[0]);
        onSelect(newValue[0]);
    };
    return (React.createElement("div", { ref: sliderRef, className: styles('Slider', showError && 'error') },
        React.createElement(Range, { step: step, min: 0, max: maxVal, values: [value], onChange: onChange, renderMark: function (_a) {
                var props = _a.props, index = _a.index;
                return index % 5 === 0 && (React.createElement("div", __assign({ className: styles('range-label') }, props, { style: __assign({ position: 'absolute', top: '20px', color: '#000', fontSize: '12px', padding: '4px', borderRadius: '4px', whiteSpace: 'nowrap', background: '#f7f7f7', boxShadow: 'rgb(0 0 0 / 56%) 1px 1px 8px', transform: 'translate(-50%)' }, props.style) }),
                    index * step,
                    " %"));
            }, renderTrack: function (_a) {
                var props = _a.props, children = _a.children;
                return (React.createElement("div", __assign({}, props, { style: __assign(__assign({}, props.style), { height: '6px', width: '100%', backgroundColor: '#ccc' }) }), children));
            }, renderThumb: function (_a) {
                var props = _a.props;
                return (React.createElement("div", __assign({}, props, { style: __assign(__assign({}, props.style), { height: '20px', width: '20px', borderRadius: '20px', backgroundColor: theme.colors.blue[7], display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 2px 6px #AAA' }) }),
                    React.createElement("div", { className: styles('range-label'), style: {
                            position: 'absolute',
                            top: '-28px',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                            padding: '4px',
                            borderRadius: '4px',
                            backgroundColor: '#548BF4',
                            whiteSpace: 'nowrap',
                        } },
                        Math.round(value),
                        " %"),
                    ")"));
            } }),
        showError && !noErrorText && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[error.type] || error.message || error)))));
};
