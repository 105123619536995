import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Amount from 'shared/components/Amount';
import BankAccount from 'shared/components/BankAccount';
import DateItem from 'shared/components/DateItem';
import { IconColor, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { transactionActions } from 'store/transaction/transaction.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { captureMessage } from '@sentry/react';
import styleIdentifiers from './historyListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function HistoryListItem(_a) {
    var _b, _c, _d;
    var item = _a.item, action = _a.action;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    var unignorePayment = function (paymentId) { return function () {
        transactionActions.unignorePayments({
            data: {
                payment_ids: [paymentId],
            },
        });
    }; };
    var detailModal = function (item) {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: item.id, modal: true }),
        });
    };
    var constructReference = function (item) { return (React.createElement("b", { className: styles('reference'), onClick: function () { return detailModal(item); } }, item.reference)); };
    var constructMatchedInfo = function () {
        var list = item.matched_invoices_attributes;
        if (!list || list.length === 0)
            return t(i18nKeys.BANK_ACCOUNT.HISTORY.PAYMENT_NO_RECONCILE);
        return (React.createElement("div", null,
            t(item.matched
                ? i18nKeys.BANK_ACCOUNT.HISTORY.RECONCILE_WITH
                : i18nKeys.BANK_ACCOUNT.HISTORY.PARTIALLY_MATCHED_WITH),
            ': ',
            list.map(function (it, index) { return (React.createElement(React.Fragment, { key: index },
                index > 0 && index < list.length - 1 && ', ',
                index > 0 && index === list.length - 1 && ' et ',
                constructReference(it))); })));
    };
    // Sentry 173
    if (item.localized_money_object == null) {
        captureMessage('In `HistoryListItem.tsx`, `item.localized_money_object` is null.', {
            extra: { item: item },
        });
    }
    return (React.createElement("tr", { className: styles('HistoryListItem') },
        React.createElement("td", null,
            React.createElement("div", { onClick: action, className: styles('box', 'checkbox', item.checked && 'checked') })),
        React.createElement("td", null, item.transaction_id),
        React.createElement("td", null,
            React.createElement(BankAccount, { value: item.iban, small: true })),
        React.createElement("td", null, item.counterparty_name),
        React.createElement("td", null,
            React.createElement(DateItem, { lg: currentLang, date: item.value_date })),
        React.createElement("td", null,
            React.createElement(Amount, { localizedValue: item.amount, value: (_c = (_b = item.localized_money_object) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : item.amount, suffix: item.currency })),
        React.createElement("td", null, item.communication),
        React.createElement("td", { className: styles('actions') },
            React.createElement("div", null,
                item.ignored && (React.createElement(Tooltip, { className: styles('unignored-payment'), contentClass: styles('tooltip'), selectorClass: styles('tooltip-selector'), oneLine: true, direction: DropdownDirection.LEFT, icon: IconName.SPINNER, size: "16px", onClick: unignorePayment(item.id) }, t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED_PAYMENT))),
                React.createElement(Tooltip, { contentClass: styles('tooltip'), oneLine: true, direction: DropdownDirection.LEFT, icon: IconName.REPLACE, size: "16px", color: item.matched
                        ? IconColor.DARK_GREEN
                        : ((_d = item.matched_invoices_attributes) === null || _d === void 0 ? void 0 : _d.length) > 0
                            ? IconColor.WARN
                            : IconColor.DARK }, constructMatchedInfo())))));
}
