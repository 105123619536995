import React from 'react';
import { useDeleteTask } from 'api';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuShow, } from 'store/view/view.actions';
import { TaskSideMenu } from '../TaskForm/TaskSideMenu';
export var TaskListItemActions = function (_a) {
    var item = _a.item, refetch = _a.refetch;
    var t = useTranslation().t;
    var history = useHistory();
    var id = item.id;
    var deleteTaskMutation = useDeleteTask().mutate;
    var updateTask = function (e) {
        e.stopPropagation();
        sideMenuShow({
            unmount: true,
            content: React.createElement(TaskSideMenu, { taskId: item.id, refetch: refetch }),
        });
    };
    var deleteTask = function (e) {
        e.stopPropagation();
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_TASK), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteTaskMutation({ id: id }, {
                        onSuccess: function () {
                            history.push('/tasks');
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Icon, { onClick: updateTask, name: IconName.SETTINGS_GEAR, size: "18px" }),
        React.createElement(Icon, { onClick: deleteTask, name: IconName.TRASH_SIMPLE, size: "18px" })));
};
