import { __read } from "tslib";
import { useDeleteUnvalidatedDebtor } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './clientListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ClientListItem(_a) {
    var _b, _c, _d, _e;
    var item = _a.item, notValidate = _a.notValidate, isLoading = _a.isLoading, refetch = _a.refetch;
    var t = useTranslation().t;
    var _f = __read(useState(false), 2), hover = _f[0], setHover = _f[1];
    var history = useHistory();
    var id = item.id, _g = item.attributes, full_name = _g.full_name, vat_number = _g.vat_number, emails = _g.emails, phones = _g.phones, address = _g.address, csv_updated = _g.csv_updated;
    var deleteUnvalidatedDebtor = useDeleteUnvalidatedDebtor().mutate;
    var _h = __read(useState(false), 2), isDeleting = _h[0], setIsDeleting = _h[1];
    var goDetail = function () {
        if (!isDeleting && !isLoading) {
            history.push("/client-to-".concat(notValidate ? 'confirm' : 'merge', "/").concat(id));
        }
    };
    var deleteClient = function (e) {
        if (!isDeleting && !isLoading) {
            e.stopPropagation();
            setIsDeleting(true);
            deleteUnvalidatedDebtor({ id: id }, {
                onError: function () {
                    setIsDeleting(false);
                },
                onSuccess: function () {
                    refetch();
                },
            });
        }
    };
    return (React.createElement("tr", { className: styles('client-list-item', hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, onClick: goDetail },
        React.createElement("td", null),
        React.createElement("td", null, full_name),
        React.createElement("td", null, vat_number),
        React.createElement("td", null, emails.join(', ')),
        React.createElement("td", null, (_c = (_b = phones[0]) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.number),
        React.createElement("td", null, (_d = address === null || address === void 0 ? void 0 : address.attributes) === null || _d === void 0 ? void 0 : _d.city),
        React.createElement("td", null, (_e = address === null || address === void 0 ? void 0 : address.attributes) === null || _e === void 0 ? void 0 : _e.country),
        notValidate && !csv_updated && (React.createElement("td", { className: styles('Actions') },
            React.createElement(Button, { onClick: deleteClient, color: ButtonColor.GREY, label: t(i18nKeys.DELETE), noMargin: true, small: true, disabled: isLoading || isDeleting })))));
}
