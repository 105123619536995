import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Input } from 'shared/io';
import styleIdentifiers from './ContactPersonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonFormPhones = function (_a) {
    var _b, _c;
    var contactPerson = _a.contactPerson;
    var t = useTranslation().t;
    var _d = useFormContext(), watch = _d.watch, setValue = _d.setValue, register = _d.register, errors = _d.formState.errors;
    var _e = useFieldArray({
        name: 'phones',
    }), fields = _e.fields, append = _e.append, remove = _e.remove;
    return (React.createElement(FormSection, { title: t(i18nKeys.PHONES), startClosed: !((_c = (_b = contactPerson === null || contactPerson === void 0 ? void 0 : contactPerson.attributes) === null || _b === void 0 ? void 0 : _b.phones) === null || _c === void 0 ? void 0 : _c.length), onAdd: function () {
            append({});
        } },
        React.createElement("div", { className: styles('phones') }, fields.map(function (item, index) {
            register("phones.".concat(index, "._destroy"));
            register("phones.".concat(index, ".id"));
            if (watch("phones.".concat(index, "._destroy"))) {
                return null;
            }
            return (React.createElement("div", { key: item.id, className: styles('line-with-delete') },
                React.createElement(Input, { label: t(i18nKeys.PHONE), type: "phone", className: styles('input'), errorMessage: get(errors, "phones.".concat(index, ".attributes.number")), register: register("phones.".concat(index, ".attributes.number"), {
                        required: true,
                    }), withBorder: true }),
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, className: styles('delete'), onClick: function () {
                        if (watch("phones.".concat(index, ".id"))) {
                            setValue("phones.".concat(index, "._destroy"), true);
                        }
                        else {
                            remove(index);
                        }
                    } })));
        }))));
};
