import { removeAttributes } from 'shared/utils/view';
import { baseReducerData, baseReducerListPage, handlePageRequest, handlePageResponse, } from 'store/reducers';
import { thirdPartyCaseConstants as events } from 'store/thirdpartyCase/thirdpartycase.actions';
export var initialState = {
    list: baseReducerListPage,
    current: baseReducerData,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.listPage.request:
            return handlePageRequest(state, action, 'list');
        // listing
        case events.listPage.result:
            return handlePageResponse(state, action, 'list', removeAttributes);
        default:
            return state;
    }
};
export default reducer;
