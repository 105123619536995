import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import { IconName } from 'shared/components/Icon';
import ImportClientsForm from 'shared/forms/ImportClientsForm';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { sideMenuShow } from 'store/view/view.actions';
import styleIdentifiers from '../ClientsList.scss';
var styles = classNames.bind(styleIdentifiers);
export var NoCheckBox = function (_a) {
    var exportCSV = _a.exportCSV, duplicatesCount = _a.duplicatesCount;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var importClients = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(ImportClientsForm, null),
        });
    };
    var newClient = function () {
        EditClient({
            callback: function () {
                reloadCustomView(clientActions.listPageRes);
            },
        });
    };
    var goToDuplicates = function () {
        history.push('/client/duplicates');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), className: styles('actions-dropdown'), toggleContent: true, selectorContent: React.createElement(Button, { className: styles('action'), noMargin: true, label: t(i18nKeys.FORM.ACTIONS), iconRight: IconName.ARROW_BOTTOM_ROUNDED }) },
            React.createElement("div", { className: "dropdown-item", onClick: newClient },
                React.createElement("div", null, t(i18nKeys.NEW_CLIENT))),
            React.createElement("div", { className: "dropdown-item", onClick: importClients },
                React.createElement("div", null, t(i18nKeys.IMPORT_CSV))),
            React.createElement("div", { className: "dropdown-item", onClick: exportCSV },
                React.createElement("div", null, t(i18nKeys.EXPORT_CSV)))),
        company.unvalidated_debtors_count > 0 && (React.createElement(Button, { noMargin: true, onClick: function () { return history.push('/client/to-confirm'); } },
            t(i18nKeys.IMPORT_TO_CONFIRM),
            React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'blue') }, company.unvalidated_debtors_count))),
        duplicatesCount !== 0 && (React.createElement(Button, { noMargin: true, className: styles('button'), label: t(i18nKeys.DUPLICATES), onClick: goToDuplicates },
            React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'blue') }, duplicatesCount))),
        React.createElement(Dropdown, { toggleContent: true, selectorContent: React.createElement(Button, { noMargin: true, className: styles('button'), label: t(i18nKeys.IMPORT_EXPORT), iconRight: IconName.ARROW_BOTTOM_ROUNDED }) },
            React.createElement("div", { className: "dropdown-item", onClick: importClients },
                React.createElement("div", null, t(i18nKeys.IMPORT_CSV))),
            React.createElement("div", { className: "dropdown-item", onClick: exportCSV },
                React.createElement("div", null, t(i18nKeys.EXPORT_CSV)))),
        React.createElement(Button, { noMargin: true, className: styles('button'), label: t(i18nKeys.NEW_CLIENT), onClick: newClient, color: ButtonColor.BLUE })));
};
