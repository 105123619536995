import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import Loading from '../Loading/Loading';
import styleIdentifiers from './InfiniteScroll.scss';
var styles = classNames.bind(styleIdentifiers);
export function InfiniteScroll(_a) {
    var fwRef = _a.fwRef, children = _a.children, items = _a.items, className = _a.className, scrollThreshold = _a.scrollThreshold, hasMore = _a.hasMore, load = _a.load, loading = _a.loading;
    var listRef = fwRef || useRef(null);
    var actionTriggered = false;
    var prevScrollValue = 0;
    var timeout = false;
    var isElementAtBottom = function (target, scrollThreshold) {
        if (scrollThreshold === void 0) { scrollThreshold = 0.8; }
        var clientHeight = target.clientHeight, scrollTop = target.scrollTop, scrollHeight = target.scrollHeight;
        if (prevScrollValue < scrollTop + clientHeight) {
            prevScrollValue = scrollTop + clientHeight;
            return typeof scrollThreshold === 'number'
                ? scrollTop + clientHeight >= scrollThreshold * scrollHeight
                : scrollTop + clientHeight >= scrollHeight - parseFloat(scrollThreshold);
        }
    };
    var setScrollTop = function (target) {
        if (!actionTriggered) {
            var atBottom = isElementAtBottom(target, scrollThreshold);
            if (atBottom && hasMore) {
                actionTriggered = true;
                load && load();
            }
        }
        timeout = false;
    };
    var throttledOnScrollListener = function (event) {
        if (!timeout) {
            timeout = true;
            setTimeout(function () { return setScrollTop(event.target); }, 1000);
        }
    };
    useEffect(function () {
        if (actionTriggered) {
            actionTriggered = false;
        }
        if (hasMore) {
            listRef.current.addEventListener('scroll', throttledOnScrollListener);
        }
        else {
            listRef.current.removeEventListener('scroll', throttledOnScrollListener);
        }
        return function () {
            var _a;
            return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', throttledOnScrollListener);
        };
    }, [items]);
    return (React.createElement("div", { ref: listRef, className: styles(className, 'infinite-scroll') },
        children,
        React.createElement(Loading, { active: loading, size: "small", className: styles('loader') })));
}
