import { __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import { computeNextStepDate, computeStatus } from '../PaymentPlan.utils';
import styleIdentifiers from './PaymentPlansListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanListItem = function (_a) {
    var item = _a.item;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var history = useHistory();
    var _c = __read(useState(false), 2), hover = _c[0], setHover = _c[1];
    var id = item.id, _d = item.attributes, cancelled = _d.cancelled, completed = _d.completed, debtor = _d.debtor, invoices_count = _d.invoices_count, current_step = _d.current_step, payment_steps_attributes = _d.payment_steps_attributes;
    var updatePaymentPlan = function (e) {
        e.stopPropagation();
        history.push("/payment-plan/".concat(id, "/edit"));
    };
    var goToDetails = function () {
        history.push("/payment-plan/".concat(id));
    };
    var currentStep = payment_steps_attributes.find(function (step) { return step.id === (current_step === null || current_step === void 0 ? void 0 : current_step.toString()); });
    var translationKey = invoices_count > 1 ? i18nKeys.INVOICES : i18nKeys.COMMON.INVOICE;
    var STATUS = computeStatus(cancelled, completed);
    return (React.createElement("tr", { className: styles('payment-plan-list-item', hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, key: id, onClick: goToDetails },
        React.createElement("td", null),
        React.createElement("td", null, id),
        React.createElement("td", { className: styles('debtor') },
            React.createElement(NavLink, { onClick: function (e) { return e.stopPropagation(); }, to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name)),
        React.createElement("td", null, "".concat(invoices_count, " ").concat(t(translationKey).toLowerCase())),
        React.createElement("td", null, t(i18nKeys[STATUS])),
        React.createElement("td", null, computeNextStepDate(STATUS, currentLang, currentStep)),
        React.createElement("td", { className: styles('actions') }, !cancelled && !completed && (React.createElement("span", { onClick: updatePaymentPlan },
            React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') }))))));
};
