import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ProductItem = function (_a) {
    var _b = _a.item, name = _b.name, price_htva = _b.price_htva, company = _a.company, constants = _a.constants, currency = _a.currency;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('product-item') },
        React.createElement("div", null, name),
        React.createElement("div", null,
            t(i18nKeys.EXCL_VAT_PRICE),
            ":",
            ' ',
            formatAmount(price_htva, ',', '.', 2, currencySymbol(currency, company)))));
};
