import { SendingMediaNames } from 'api/models';
import { removeAttributes } from 'shared/utils/view';
import { flattenItem } from 'store/reducers';
import { treatRecoveryPlan } from './settings.serializer';
export var treatClient = function (item) {
    var newItem = flattenItem(item, [], ['emails', 'account_manager', 'default_credit_note_template', 'default_invoice_template']);
    if (newItem.account_manager)
        newItem.account_manager = removeAttributes(newItem.account_manager);
    if (newItem.default_credit_note_template) {
        newItem.default_credit_note_template = removeAttributes(newItem.default_credit_note_template);
    }
    if (newItem.default_invoice_template)
        newItem.default_invoice_template = removeAttributes(newItem.default_invoice_template);
    if (newItem.recovery_plan) {
        newItem.recovery_plan =
            newItem.recovery_plan.data === undefined
                ? newItem.recovery_plan
                : newItem.recovery_plan.data
                    ? treatRecoveryPlan(newItem.recovery_plan.data)
                    : null;
    }
    var sendingMedia = ((newItem === null || newItem === void 0 ? void 0 : newItem.sending_media_attributes) || []).map(function (_a) {
        var name = _a.name;
        return name;
    });
    if (sendingMedia.includes(SendingMediaNames.email))
        newItem.byMail = true;
    if (sendingMedia.includes(SendingMediaNames.post))
        newItem.byPost = true;
    return newItem;
};
