import { removeAttributes } from 'shared/utils/view';
export var treatActivity = function (item) {
    var activity = removeAttributes(item);
    if (activity.invoice_feedback) {
        activity.invoice_feedback = removeAttributes(activity.invoice_feedback);
        activity.invoice_feedback.conversation = removeAttributes(activity.invoice_feedback.conversation);
        activity.invoice_feedback.conversation.messages =
            activity.invoice_feedback.conversation.messages.map(function (message) { return removeAttributes(message); });
    }
    return activity;
};
