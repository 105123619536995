import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import ProductForm from 'shared/forms/ProductForm';
import { Button } from 'shared/io';
import { productActions } from 'store/product/product.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import ProductListItem from './ProductListItem';
import styleIdentifiers from './listing.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(ProductListItem);
export default function Listing() {
    var history = useHistory();
    var t = useTranslation().t;
    var productData = useSelector(function (state) { return state.product.list || {}; });
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: 'id',
            width: 40,
        },
        {
            key: 'name',
            title: t(i18nKeys.INVOICE.PRODUCT),
        },
        {
            key: 'description',
            title: t(i18nKeys.DESCRIPTION),
        },
        {
            width: 120,
            key: 'price_htva',
            title: t(i18nKeys.TOTAL_NOT_VAT_PRICE),
        },
        {
            width: 130,
            key: 'vat_rate',
            title: t(i18nKeys.INVOICE.VAT_RATE),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var newProduct = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(ProductForm, { onSubmit: submitProduct }),
        });
    };
    var submitProduct = function (values) {
        productActions.add({
            data: values,
            callback: function () {
                // handleUpdate();
                history.push('/products');
                sideMenuHide();
            },
        });
    };
    return (React.createElement("div", { className: styles('Listing') },
        React.createElement(EnhancedTable, { notSortable: true, title: t(i18nKeys.INVOICING.PRODUCT.TITLE), actions: React.createElement(Button, { className: styles('action-button'), noMargin: true, label: t(i18nKeys.NEW_PRODUCT), onClick: newProduct, iconRight: IconName.MINIMAL_RIGHT }), loadFunc: productActions.listPage, loading: productData.loading, loaded: productData.loaded, pagination: productData.metadata && productData.metadata.pagination, headers: TABLE_HEADERS, items: productData.pages || [], showShadow: true, noCheckbox: true })));
}
