import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Icon, IconName } from 'shared/components/Icon';
import { Dropdown } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { Pagination as MantinePagination } from '@mantine/core';
import styleIdentifiers from './TableFooter.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TableFooter(_a) {
    var pagination = _a.pagination, itemsLength = _a.itemsLength, className = _a.className, noShadow = _a.noShadow, noPageLimit = _a.noPageLimit, currentPage = _a.currentPage, currentPageLimit = _a.currentPageLimit, setPage = _a.setPage, setPageLimit = _a.setPageLimit;
    var t = useTranslation().t;
    var PAGES_LIMITS = [10, 20, 50, 100];
    var _b = pagination !== null && pagination !== void 0 ? pagination : {}, total_objects = _b.total_objects, page_limit = _b.page_limit, current_page = _b.current_page, last_page = _b.last_page;
    var pageCurrent = currentPage || current_page;
    var pageLimit = currentPageLimit || page_limit;
    var isLastPage = last_page === pageCurrent;
    var hasPagination = last_page > 1;
    return (React.createElement("div", { className: styles('table-footer', noShadow && 'no-shadow', className) },
        React.createElement("div", null,
            React.createElement("span", null, "".concat(isLastPage ? total_objects - itemsLength + 1 : (pageCurrent - 1) * pageLimit + 1, " - ").concat(isLastPage ? total_objects : pageCurrent * pageLimit, " ").concat(t(i18nKeys.OF), " ").concat(total_objects)),
            React.createElement("div", { className: styles('fields') }, !noPageLimit && setPageLimit && (React.createElement(Dropdown, { direction: DropdownDirection.TOP, selectorContent: React.createElement("div", { className: styles('number-element-selector') },
                    React.createElement("div", null,
                        pageLimit,
                        " ",
                        t(i18nKeys.PER_PAGE)),
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.ARROW_BOTTOM_ROUNDED }))), toggleContent: true }, PAGES_LIMITS.map(function (limit) { return (React.createElement("div", { key: "limit".concat(limit), className: "dropdown-item", onClick: setPageLimit(limit) }, limit)); }))))),
        hasPagination && (React.createElement(MantinePagination, { total: last_page, onChange: function (selected) { return setPage({ selected: selected - 1 }); }, value: pageCurrent, size: "sm" }))));
}
