import { __read, __spreadArray } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales/';
import { clone, first, mergeWith, subtract } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { BarChartTooltip } from 'shared/charts';
import Card from 'shared/components/Card';
import { useAgedBalanceColors, useProfile } from 'shared/hooks';
import { formatAmount, useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { Box, useMantineTheme } from '@mantine/core';
import { ResponsiveBar } from '@nivo/bar';
import styleIdentifiers from './balanceGraphic.scss';
var styles = classNames.bind(styleIdentifiers);
var DESIRED_X_AXIS_TICKS = 6;
export function BalanceGraphic(_a) {
    var agedBalanceCompact = _a.agedBalanceCompact;
    var push = useHistory().push;
    var profile = useProfile();
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var colors = useAgedBalanceColors();
    var formatter = useSafeLocalizedCompactCurrencyFormatter();
    var netBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var totalDue = netBalance[0] + netBalance[30] + netBalance[60] + netBalance[90];
    var graphLines = [
        {
            label: t(i18nKeys.ANALYTICS.AGED_BALANCE.notDue),
            query: 'max_days_late=0&status=unpaid,not_lost&',
            amount: Number(netBalance.notDue),
            key: 'notDue',
            color: colors.notDue,
        },
        {
            label: t(i18nKeys.ANALYTICS.AGED_BALANCE.totalDue),
            query: 'min_days_late=1&status=unpaid,not_lost&',
            amount: totalDue,
            key: 'totalDue',
            color: colors.totalDue,
        },
        {
            label: t(i18nKeys.DELAY.WITHIN_30),
            query: 'min_days_late=1&max_days_late=30&status=unpaid,not_lost&',
            amount: Number(netBalance['0']),
            key: 'within_30',
            color: colors[0],
        },
        {
            label: t(i18nKeys.DELAY.BETWEEN_30_AND_60),
            query: 'min_days_late=30&max_days_late=60&status=unpaid,not_lost&',
            amount: Number(netBalance['30']),
            key: 'between_30_and_60',
            color: colors[30],
        },
        {
            label: t(i18nKeys.DELAY.BETWEEN_60_AND_90),
            query: 'min_days_late=60&max_days_late=90&status=unpaid,not_lost&',
            amount: Number(netBalance['60']),
            key: 'between_60_and_90',
            color: colors[60],
        },
        {
            label: t(i18nKeys.DELAY.ABOVE_90),
            query: 'min_days_late=90&status=unpaid,not_lost&',
            amount: Number(netBalance['90']),
            key: 'above_90',
            color: colors[90],
        },
    ].reverse();
    var maxValue = Math.max.apply(Math, __spreadArray([], __read(graphLines.map(function (bar) { return bar.amount; })), false));
    var minValue = Math.min.apply(Math, __spreadArray([], __read(graphLines.map(function (bar) { return bar.amount; })), false));
    var _b = __read(nice(minValue, maxValue, DESIRED_X_AXIS_TICKS), 2), rangeStart = _b[0], rangeEnd = _b[1];
    var xValues = ticks(rangeStart, rangeEnd, DESIRED_X_AXIS_TICKS);
    return (React.createElement(Card, { className: styles('balance-graphic'), noPadding: true, title: t(i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE) },
        React.createElement(Box, { w: "100%", h: "350px" },
            React.createElement(ResponsiveBar, { onClick: function (d) { return push("/invoices/listing?".concat(d.data.query)); }, onMouseEnter: function (_datum, event) {
                    event.currentTarget.style.cursor = 'pointer';
                }, colors: function (d) { return d.data.color; }, margin: { bottom: 35, top: 10, left: profile.locale === 'nl' ? 120 : 100, right: 95 }, layout: "horizontal", data: graphLines, indexBy: "label", keys: ['amount'], padding: 0.45, isInteractive: true, colorBy: "indexValue", label: function (d) { return formatAmount(d.value, '.', ' '); }, enableGridX: true, enableGridY: false, labelSkipWidth: 80, labelTextColor: theme.colors.gray[0], tooltip: function (_a) {
                    var amount = _a.data.amount;
                    // Fix for incorrect initial tooltip position, see https://github.com/plouc/nivo/issues/2161
                    setTimeout(function () {
                        var tooltip = document.getElementById('tooltip-fix');
                        if (tooltip)
                            tooltip.style.opacity = '1';
                    }, 50);
                    return (React.createElement(BarChartTooltip, { id: "tooltip-fix", style: { opacity: 0, transition: 'all 0.1s ease-in-out' } }, formatAmount(amount, '.', ' ')));
                }, axisBottom: {
                    tickSize: 0,
                    tickValues: xValues,
                    format: function (val) { return formatter.format(val).replace(/(\.[0]+|,[0]+)$/, ''); },
                }, axisLeft: {
                    tickPadding: xValues[0] < 0 ? 25 : 15,
                    tickSize: 0,
                }, animate: false, theme: {
                    grid: {
                        line: {
                            stroke: theme.colors.gray[3],
                            strokeDasharray: 7,
                            strokeDashoffset: 15,
                        },
                    },
                    labels: {
                        text: {
                            fontSize: 12,
                            fontFamily: 'Work Sans',
                            fill: theme.colors.gray[6],
                        },
                    },
                    text: {
                        fontSize: 10,
                        fontFamily: 'Work Sans',
                        fill: theme.colors.gray[6],
                    },
                }, gridXValues: xValues, layers: [
                    'grid',
                    'axes',
                    'bars',
                    function (_a) {
                        var bars = _a.bars, innerWidth = _a.innerWidth;
                        var _b = __read(__spreadArray([], __read(bars), false).reverse(), 3), secondBar = _b[1], thirdBar = _b[2];
                        var secondBarEndPositionY = secondBar.y + secondBar.height;
                        var halfwayPointY = (secondBarEndPositionY + thirdBar.y) / 2;
                        return (React.createElement("line", { x1: -65, y1: halfwayPointY, x2: innerWidth + 65, y2: halfwayPointY, stroke: "#CED4DA", strokeWidth: "1.5", strokeDasharray: 7 }));
                    },
                    // See https://github.com/plouc/nivo/issues/146#issuecomment-1009184119
                    function (_a) {
                        var _b, _c;
                        var bars = _a.bars, labelSkipWidth = _a.labelSkipWidth;
                        var negativeBars = bars.filter(function (bar) { var _a; return ((_a = bar.data.value) !== null && _a !== void 0 ? _a : 0) < 0; });
                        var sortedNegativeBars = __spreadArray([], __read(negativeBars), false).sort(function (a, b) { var _a, _b; return ((_a = a.data.value) !== null && _a !== void 0 ? _a : 0) - ((_b = b.data.value) !== null && _b !== void 0 ? _b : 0); });
                        var yAxisNegativeValuePadding = (_c = (_b = first(sortedNegativeBars)) === null || _b === void 0 ? void 0 : _b.width) !== null && _c !== void 0 ? _c : 0;
                        return (React.createElement("g", null, bars.map(function (_a) {
                            var width = _a.width, height = _a.height, y = _a.y, barData = _a.data;
                            return width < labelSkipWidth ? (React.createElement("text", { key: barData.data.key, fontSize: 12, fontFamily: "Work Sans", fill: theme.colors.gray[6], transform: "translate(".concat(width + 10 + yAxisNegativeValuePadding, ", ").concat(y + height / 2, ")"), textAnchor: "left", dominantBaseline: "central" }, formatAmount(barData.value, '.', ' '))) : null;
                        })));
                    },
                ] }))));
}
