import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import { usePrevious } from 'shared/utils/hooks';
import { validationMessage } from 'shared/utils/validation';
import styleIdentifiers from './MaskInput.scss';
var styles = classNames.bind(styleIdentifiers);
// TO DO cursor at the good place when user write bad character
export var MaskInput = function (_a) {
    var onFocus = _a.onFocus, label = _a.label, withBorder = _a.withBorder, noMargin = _a.noMargin, className = _a.className, noBorder = _a.noBorder, shadow = _a.shadow, noErrorText = _a.noErrorText, noError = _a.noError, onChange = _a.onChange, inputRef = _a.inputRef, error = _a.error, errorClassName = _a.errorClassName, inputClassName = _a.inputClassName, length = _a.length, guide = _a.guide, prefix = _a.prefix, numberOnly = _a.numberOnly, mask = _a.mask, rest = __rest(_a, ["onFocus", "label", "withBorder", "noMargin", "className", "noBorder", "shadow", "noErrorText", "noError", "onChange", "inputRef", "error", "errorClassName", "inputClassName", "length", "guide", "prefix", "numberOnly", "mask"]);
    var t = useTranslation().t;
    var replaceAt = function (text, index, replacement) {
        return text.substr(0, index) + replacement + text.substr(index + replacement.length);
    };
    var removePrefix = function (value) {
        return prefix && (value === null || value === void 0 ? void 0 : value.slice(0, prefix.length)) === prefix ? value.slice(prefix.length) : value;
    };
    var addMask = function (value) {
        if (mask) {
            if (value) {
                var inputVal = mask;
                var valuePos = 0;
                for (var i = 0; i < mask.length; i++) {
                    if (value[valuePos] === undefined) {
                        break;
                    }
                    if (mask[i] === '_') {
                        inputVal = replaceAt(inputVal, i, value[valuePos]);
                        valuePos++;
                    }
                    else if (mask[i] === value[i]) {
                        valuePos++;
                    }
                }
                return inputVal;
            }
            return mask;
        }
        return value;
    };
    var initData = function (value) { return addMask(removePrefix(value)); };
    var _b = __read(useState(initData(rest.value)), 2), val = _b[0], _setVal = _b[1];
    var ref = useRef(null);
    var setValue = function (value) {
        var inputVal = addGuide(value);
        _setVal(inputVal);
        ref.current.value = inputVal;
        onChange(((prefix || '') + value));
    };
    var previousMask = usePrevious(mask);
    useEffect(function () {
        if (previousMask !== null) {
            setValue(initData(''));
        }
    }, [mask]);
    useEffect(function () {
        if (guide && ref.current) {
            setValue(addGuide(val));
        }
    }, [ref.current]);
    useEffect(function () {
        if (rest.value !== undefined && rest.value !== val) {
            changeValue(rest.value, 0);
        }
    }, [rest.value]);
    var addGuide = function (text) {
        if (guide) {
            var value = text || '';
            for (var i = value.length; value.length < length; i++) {
                value = "".concat(value, "_");
            }
            return value;
        }
        return text;
    };
    var handleInputFocus = function (event) {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
    };
    var showError = !noError && error;
    var changeValue = function (value, cursorPosition) {
        if (mask) {
            var maskPosition = 0;
            var valuePosition = 0;
            var newValue = '';
            var textToAdd = value.replace(/\_/g, '');
            while (!(textToAdd[valuePosition] === undefined || maskPosition > mask.length - 1)) {
                if (mask[maskPosition] === '_') {
                    if (numberOnly && isNaN(parseInt(textToAdd[valuePosition]))) {
                        valuePosition++;
                    }
                    else {
                        newValue += textToAdd[valuePosition];
                        valuePosition++;
                        maskPosition++;
                    }
                }
                else {
                    newValue += mask[maskPosition];
                    maskPosition++;
                }
            }
            newValue += mask.substring(maskPosition);
            setValue(newValue);
            var findFirstDiff = function (str1, str2) { return __spreadArray([], __read(str1), false).findIndex(function (el, index) { return el !== str2[index]; }); };
            if (value.length < mask.length) {
                ref.current.setSelectionRange(cursorPosition, cursorPosition);
            }
            else {
                var firstDiff = findFirstDiff(val, newValue);
                var newPosition = firstDiff === -1 ? cursorPosition : firstDiff + value.length - mask.length;
                ref.current.setSelectionRange(newPosition, newPosition);
            }
        }
        else {
            value = value.replace(/_/g, '');
            if (numberOnly && isNaN(parseInt(value))) {
                setValue(val);
                ref.current.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
            }
            else {
                if (length && length < value.length) {
                    value = value.slice(0, cursorPosition) + value.slice(cursorPosition + 1);
                    value = value.slice(0, length);
                }
                setValue(value);
                ref.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    };
    var handleChange = function (e) {
        changeValue((e.target.value || '').trim(), e.target.selectionStart);
    };
    return (React.createElement("div", { className: styles('MaskInput', noMargin && 'no-margin', className, shadow && 'shadow', withBorder && 'with-border', showError && 'error') },
        React.createElement("div", { className: styles('input-wrapper', !label && 'no-label', showError && 'error', showError && errorClassName) },
            label && React.createElement("div", { className: styles('label-input') }, label),
            React.createElement("div", { className: styles('input-container') },
                React.createElement("span", { className: styles('input', !noBorder && 'bordered') }, prefix),
                React.createElement("input", __assign({ className: styles(inputClassName, !noBorder && 'bordered'), onChange: handleChange, onFocus: handleInputFocus, ref: ref }, rest, { value: val || '' })))),
        showError && !noErrorText && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[error.type] || error.message || error)))));
};
