import { __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { colleagueActions, colleagueConstants as events } from 'store/colleague/colleague.actions';
import { sendApi } from '../sagas';
function colleagueWatchers() {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.add.request, sendApi(api.colleague.add, colleagueActions.addRes))];
            case 1: return [4 /*yield*/, _a.apply(void 0, [[
                        _b.sent()
                    ]])];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(colleagueWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
