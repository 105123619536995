import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { CustomSelect, DateSelector, SwitchInput } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName } from '../Integration.constants';
import { getExactConnectUrl, getIntegrationRights } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExactFormFieldsName;
(function (ExactFormFieldsName) {
    ExactFormFieldsName["IMPORT_INTEGRATION"] = "use_exact_import_integration";
    ExactFormFieldsName["PAYMENT_MATCHING"] = "use_exact_payment_matching";
    ExactFormFieldsName["DIVISION_ID"] = "division_id";
    ExactFormFieldsName["REFERENCE_FIELD"] = "reference_field";
})(ExactFormFieldsName || (ExactFormFieldsName = {}));
export var ExactForm = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, onboarding = _a.onboarding;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = getIntegrationRights(Integration.EXACT, company), isConfigure = _b.isConfigure, isConnected = _b.isConnected;
    var _c = __read(useState([]), 2), companies = _c[0], setCompanies = _c[1];
    var _d = __read(useState(false), 2), preventSubmit = _d[0], setPreventSubmit = _d[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    useEffect(function () {
        if (isConnected && !isConfigure) {
            if (onboarding) {
                onBoardingActions.exactFetchCompanies({
                    callback: function (data) { return setCompanies(data); },
                });
            }
            else {
                accountActions.exactFetchCompanies({
                    callback: function (data) { return setCompanies(data); },
                });
            }
        }
    }, [isConnected, isConfigure, onboarding]);
    var exact_reference_fields = useSelector(function (state) { return state.app.constants; }).exact_reference_fields;
    var handleSubmit = form.handleSubmit, control = form.control, setValue = form.setValue;
    var submit = function (values) {
        if (preventSubmit) {
            showDialog({
                id: DialogShowId.CONFIRM,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE), onConfirm: function () {
                        setPreventSubmit(false);
                        onSubmit(values);
                    } })),
            });
        }
        else {
            onSubmit(values);
        }
    };
    var debtorUpdate = form.watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
    var importIntegration = form.watch(ExactFormFieldsName.IMPORT_INTEGRATION);
    useEffect(function () {
        if (importIntegration) {
            setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                : new Date());
        }
    }, [importIntegration, initialValues, setValue]);
    if (!isConnected) {
        return (React.createElement(IntegrationForm, { integration: Integration.EXACT, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.EXACT.SUMMARY, connectUrl: getExactConnectUrl(), form: form }));
    }
    return (React.createElement(IntegrationForm, { integration: Integration.EXACT, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.EXACT.SUMMARY, form: form },
        !isConfigure && (React.createElement("div", { className: styles('select-company') },
            React.createElement("p", null, t(i18nKeys.FORM.EXACT.IRREVERSIBLE_ACTION)),
            React.createElement("p", { className: styles('warning') }, t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)),
            React.createElement("div", { className: styles('grid-row') },
                React.createElement("div", { className: styles(onboarding ? 'col-12' : 'col-6') },
                    React.createElement(Controller, { defaultValue: "", name: ExactFormFieldsName.DIVISION_ID, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: ExactFormFieldsName.DIVISION_ID, selectClassName: styles('company-select'), errorClassName: styles('invoice-select-error'), items: companies, keyText: "name", keyValue: "id", label: t(i18nKeys.FORM.EXACT.SELECT_COMPANY, {
                                text: Integration.EXACT,
                            }) })); } }))))),
        React.createElement("div", { className: styles('grid-row') },
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: ExactFormFieldsName.IMPORT_INTEGRATION, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                                text: Integration.EXACT,
                            }) })));
                    } })),
            importIntegration && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('col-12') },
                    React.createElement(DateSelector, { name: IntegrationFormFieldsName.ISSUE_DATE_LIMIT, className: styles('issue-date'), noMinDate: true, label: t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT), placeholder: t(i18nKeys.ISSUE_DATE), endPlaceholder: t(i18nKeys.FORM.TO), required: true, handleChange: function (date) {
                            if (!onboarding) {
                                setPreventSubmit(true);
                            }
                            setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                        } })),
                React.createElement("div", { className: styles('col-12') },
                    React.createElement(Controller, { name: ExactFormFieldsName.REFERENCE_FIELD, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: ExactFormFieldsName.REFERENCE_FIELD, items: exact_reference_fields, keyText: "description", keyValue: "value", selectClassName: styles('issue-date'), label: React.createElement("span", null,
                                t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.SELECT_COLUMN),
                                ' ',
                                React.createElement(Tooltip, { contentClassName: styles('tooltip'), icon: IconName.INFO }, t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.COLUMN_DESCRIPTION))), placeholder: t(i18nKeys.SETTINGS.INTEGRATIONS.EXACT.COLUMN_REFERENCE) })); } })),
                React.createElement("div", { className: styles('col-12') },
                    React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.DEBTOR_UPDATE, render: function (_a) {
                            var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                            return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button', 'issue-date'), label: t(debtorUpdate
                                    ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                                    : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR) })));
                        } })))),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: ExactFormFieldsName.PAYMENT_MATCHING, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                                text: Integration.EXACT,
                            }) })));
                    } })),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.AUTOMATIC_SYNC, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC) })));
                    } })))));
};
