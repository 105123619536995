import React from 'react';
import { useCreateTask } from 'api';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import ImportClientsForm from 'shared/forms/ImportClientsForm';
import InvoiceImportForm from 'shared/forms/InvoiceImportForm';
import ProductForm from 'shared/forms/ProductForm';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { productActions } from 'store/product/product.actions';
import { progressReset, sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import EditClient from '../../action-component/EditClient';
import { HideIf } from '..';
import styleIdentifiers from './QuickActions.scss';
var styles = classNames.bind(styleIdentifiers);
export default function QuickActions() {
    var t = useTranslation().t;
    var history = useHistory();
    var createTask = useCreateTask().mutate;
    var newInvoice = function () {
        invoiceActions.detailReset();
        sideMenuHide();
        history.push('/invoices/create');
    };
    var newCreditNote = function () {
        creditNoteActions.detailReset();
        sideMenuHide();
        history.push('/credit-notes/create');
    };
    var addClient = function () {
        EditClient({});
    };
    var importClient = function () {
        progressReset();
        sideMenuShow({
            unmount: true,
            content: React.createElement(ImportClientsForm, null),
        });
    };
    var importInvoice = function () {
        progressReset();
        sideMenuShow({
            unmount: true,
            content: React.createElement(InvoiceImportForm, null),
        });
    };
    var addProduct = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(ProductForm, { onSubmit: function (values) {
                    productActions.add({
                        data: values,
                        callback: function () { return sideMenuHide(); },
                    });
                } })),
        });
    };
    var addTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { onSubmit: function (data) {
                    return createTask({ data: data }, {
                        onSuccess: function () {
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    return (React.createElement("div", { className: styles('quick-actions') },
        React.createElement(HideIf, { connectorActive: true, isFrench: true },
            React.createElement("div", { onClick: newInvoice, className: styles('item') }, t(i18nKeys.NEW_INVOICE))),
        React.createElement(HideIf, { connectorActive: true, isFrench: true },
            React.createElement("div", { onClick: newCreditNote, className: styles('item') }, t(i18nKeys.NEW_CREDIT_NOTE))),
        React.createElement(HideIf, { connectorActive: true },
            React.createElement("div", { onClick: importInvoice, className: styles('item') }, t(i18nKeys.INVOICES_IMPORT))),
        React.createElement(HideIf, { connectorActive: true },
            React.createElement("div", { className: styles('item'), onClick: addProduct }, t(i18nKeys.NEW_PRODUCT))),
        React.createElement("div", { onClick: importClient, className: styles('item') }, t(i18nKeys.FORM.CLIENT_IMPORT.TITLE)),
        React.createElement("div", { className: styles('item'), onClick: addClient }, t(i18nKeys.NEW_CLIENT)),
        React.createElement("div", { className: styles('item'), onClick: addTask }, t(i18nKeys.NEW_TASK))));
}
