import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import { DisplayType, VarType } from './CustomVariables.types';
import styleIdentifiers from './customVariables.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CustomVariables(_a) {
    var data = _a.data, invoiceView = _a.invoiceView, handleSettings = _a.handleSettings, cardTitle = _a.cardTitle, marginLeft = _a.marginLeft;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var company = useSelector(function (state) { var _a; return (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data; });
    var availableCustomVariables = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes; });
    var keys = Object.keys(data);
    var threePart = Math.ceil(keys.length / 3);
    var firstPart = keys.splice(-threePart);
    var secondPart = keys.splice(-threePart);
    var thirdPart = keys;
    var title = t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE) + (cardTitle || '');
    var icon = typeof handleSettings === 'undefined' ? ('') : (React.createElement(Icon, { name: IconName.PENCIL, className: styles('icon'), onClick: handleSettings }));
    var renderValue = function (customVariableValue, varType, display) {
        if (customVariableValue === null || customVariableValue === void 0 ? void 0 : customVariableValue.toString()) {
            switch (varType) {
                case VarType.BOOLEAN:
                    return t(i18nKeys[customVariableValue.toString() === 'true' ? 'YES' : 'NO']);
                case VarType.DATE:
                    return formattedDate(customVariableValue, currentLang);
                default:
                    switch (display) {
                        case DisplayType.MONEY:
                            return (React.createElement(NumericFormat, { value: customVariableValue, displayType: "text", suffix: "\u20AC", decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " }));
                        case DisplayType.PERCENTAGE:
                            return (React.createElement(NumericFormat, { value: customVariableValue, suffix: "%", decimalScale: 2, displayType: "text" }));
                        default:
                            return customVariableValue;
                    }
            }
        }
        else {
            return 'N/A';
        }
    };
    var renderCustomVariable = function (customVariableName) {
        var customVar = (availableCustomVariables !== null && availableCustomVariables !== void 0 ? availableCustomVariables : []).find(function (el) { return el.column_name === customVariableName; });
        if (customVar == null)
            return null;
        var var_type = customVar.var_type, display = customVar.display;
        return (React.createElement("div", { key: customVariableName, className: styles('subtitle-value') },
            React.createElement("span", { className: styles('subtitle') }, customVariableName.replaceAll('_', ' ')),
            React.createElement("div", { className: styles('value') }, renderValue(data[customVariableName], var_type, display))));
    };
    var content = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('info-wrapper') },
            React.createElement("div", { className: styles('left') }, firstPart.map(function (key) {
                return renderCustomVariable(key);
            })),
            React.createElement("div", { className: styles('right') }, secondPart.map(function (key) {
                return renderCustomVariable(key);
            })),
            React.createElement("div", { className: styles('right') }, thirdPart.map(function (key) {
                return renderCustomVariable(key);
            })))));
    if (company === null || company === void 0 ? void 0 : company.package.can_use_custom_variables) {
        return invoiceView ? (React.createElement(Card, { title: title, infosRight: icon, className: styles('custom-variables', marginLeft && 'margin-left') }, content)) : (React.createElement("div", { className: styles('custom-variables', 'custom-box') },
            React.createElement("h3", null,
                title,
                icon),
            content));
    }
    return React.createElement(React.Fragment, null);
}
