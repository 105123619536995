import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import CustomVariables from 'app/Private/CustomVariables';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DebtorAdditionalInfo from 'shared/components/DebtorAdditionalInfo';
import DebtorInfo from 'shared/components/DebtorInfo';
import { Icon, IconName } from 'shared/components/Icon';
import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import styleIdentifiers from './ClientsImportedDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientsImportedDetail = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var params = useRouteMatch().params;
    var _a = __read(useState(), 2), importedClient = _a[0], setImportedClient = _a[1];
    useEffect(function () {
        clientActions.getToConfirm({
            id: params.id,
            callback: function (_a) {
                var data = _a.data;
                setImportedClient(treatClient(data));
            },
        });
    }, []);
    var goToList = function () {
        history.push('/client/to-confirm');
    };
    return (React.createElement("div", { className: styles('imported-client-detail') }, importedClient && (React.createElement("div", { className: styles('client-wrapper') },
        React.createElement("div", { className: styles('title-line') },
            React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
            ' ',
            React.createElement("h1", null, importedClient.full_name || importedClient.id)),
        React.createElement("div", { className: styles('line') },
            React.createElement(DebtorInfo, { clientData: importedClient, imported: true, label: t(i18nKeys.CLIENT.GENERAL_INFO) }),
            React.createElement(DebtorAdditionalInfo, { clientData: importedClient, imported: true })),
        Object.keys(importedClient.custom_variables).length > 0 && (React.createElement(CustomVariables, { data: importedClient.custom_variables, invoiceView: true }))))));
};
