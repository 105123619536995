import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { Fragment } from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import { RadioButton } from 'shared/io';
import { captureMessage } from '@sentry/react';
import styleIdentifiers from './RecoveryPlan.scss';
import { RecoveryPlanAddStep } from './RecoveryPlanAddStep';
import { RecoveryPlanStepEl } from './RecoveryPlanStep';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryPlan = function (props) {
    var recoverPlan = props.recoverPlan, editStep = props.editStep, addStep = props.addStep, messageInfo = props.messageInfo, setLateFees = props.setLateFees, lateFeesStep = props.lateFeesStep;
    var t = useTranslation().t;
    var stepsBefore = [];
    var stepsAfter = [];
    recoverPlan.recovery_steps.forEach(function (step, index) {
        if (step.before_due_date) {
            stepsBefore.push({ step: step, index: index });
        }
        else {
            stepsAfter.push({ step: step, index: index });
        }
    });
    var add = function (_a) {
        var index = _a.index, before_due_date = _a.before_due_date;
        addStep === null || addStep === void 0 ? void 0 : addStep(index === -1 ? 0 : index)(index === -1, before_due_date);
        if (addStep == null) {
            captureMessage('In RecoveryPlan.tsx: Invalid behavior, addStep is nullish but  abutton calling it was visible to the user. This message means an error was avoided but we allowed a user to click on a button that has no effect. Audit the code and remove it when appropriate.');
        }
    };
    return (React.createElement("div", { className: styles('recovery-plan') },
        stepsBefore.map(function (_a, i, array) {
            var step = _a.step, index = _a.index;
            var delay = step.delay, task_attributes = step.task_attributes;
            return (React.createElement(Fragment, { key: "before-".concat(i) },
                React.createElement(RecoveryPlanStepEl, __assign({ index: index, array: array, arrayIndex: i, step: step, add: function (number) { return add({ before_due_date: true, index: number }); } }, props), editStep && (React.createElement("div", { className: styles('delay') },
                    React.createElement("div", null),
                    React.createElement("div", null,
                        "- ",
                        t(i18nKeys.DAY_S, { count: delay }),
                        ' ',
                        React.createElement(Icon, { name: IconName.PENCIL, onClick: editStep(index, typeof task_attributes !== 'undefined') })),
                    React.createElement("div", null))))));
        }),
        !stepsBefore.length && (React.createElement(RecoveryPlanAddStep, { onClick: function () { return add({ before_due_date: true, index: 0 }); }, text: t(i18nKeys.ADD_STEP) })),
        React.createElement("div", { className: styles('debt-collection-item', 'key-date') },
            React.createElement("span", null, t(i18nKeys.DUE_DATE))),
        !stepsAfter.length && (React.createElement(RecoveryPlanAddStep, { onClick: function () {
                return add({
                    before_due_date: false,
                    index: recoverPlan.recovery_steps.length,
                });
            }, text: t(i18nKeys.ADD_STEP) })),
        stepsAfter.map(function (_a, i, array) {
            var step = _a.step, index = _a.index;
            var delay = step.delay, step_type = step.step_type, order = step.order, task_attributes = step.task_attributes, id = step.id;
            return (React.createElement(Fragment, { key: "after-".concat(i) },
                React.createElement(RecoveryPlanStepEl, __assign({ index: index, array: array, arrayIndex: i, step: step, add: function (number) { return add({ before_due_date: false, index: number }); } }, props), editStep ? (React.createElement(React.Fragment, null,
                    React.createElement("div", null),
                    React.createElement("div", null,
                        "+ ",
                        t(i18nKeys.DAY_S, { count: delay }),
                        ' ',
                        React.createElement(Icon, { name: IconName.PENCIL, onClick: editStep(index, typeof task_attributes !== 'undefined') })),
                    React.createElement("div", { className: styles('late-fees-button') },
                        React.createElement("div", null, (step_type === 'intermediate_reminder' || step_type === 'last_reminder') && (React.createElement(RadioButton, { canRemove: true, noMargin: true, onChange: setLateFees, value: lateFeesStep, name: "fee_starting_step", items: [{ value: order, label: t(i18nKeys.LATE_FEES) }] })))))) : (messageInfo && !id && React.createElement("div", { className: styles('message-info') }, messageInfo)))));
        })));
};
