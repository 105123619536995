import { __assign, __read } from "tslib";
import React, { useEffect, useReducer } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router-dom';
import { isPrivateEnvironment, isPublicEnvironment } from 'shared/utils/environment';
import { reducerState } from 'shared/utils/view';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import AYSModal from './AYSModal';
var INIT_STATE = {
    isActive: false,
    nextLocation: undefined,
    blockNavigation: undefined,
};
var unblock;
function NavigationPromptInner(_a) {
    var disableNative = _a.disableNative, canChangePage = _a.canChangePage, when = _a.when, _b = _a.dialogProps, dialogProps = _b === void 0 ? {} : _b, redirect = _a.redirect, onSaveAndQuit = _a.onSaveAndQuit;
    var t = useTranslation().t;
    var history = useHistory();
    var _c = __read(useReducer(reducerState, INIT_STATE), 2), state = _c[0], setState = _c[1];
    var onCancel = function () {
        setState(__assign({}, INIT_STATE));
    };
    var onBeforeUnload = function (e) {
        // custom message doesn't work for security reason
        var msg = t(i18nKeys.AYS.EXIT_PAGE);
        e.returnValue = msg;
        return msg;
    };
    var block = function (nextLocation, action) {
        var _a;
        if (isPrivateEnvironment())
            return undefined;
        if (!canChangePage && when) {
            setState({
                nextLocation: nextLocation,
                isActive: true,
            });
            showDialog(__assign((_a = { id: DialogShowId.CONFIRM, keepMountOnExit: true }, _a[onSaveAndQuit ? 'medium' : 'small'] = true, _a.title = t(i18nKeys.ATTENTION), _a.children = (React.createElement(AYSModal, { text: t(i18nKeys.AYS.EXIT_PAGE), onCancel: onCancel, onConfirm: function () { return navigateToNextLocation(nextLocation, action); }, customAction: onSaveAndQuit
                    ? function () {
                        onSaveAndQuit(function () {
                            navigateToNextLocation(nextLocation, action);
                        });
                    }
                    : undefined, confirmButtonText: t(onSaveAndQuit ? 'FORM.QUIT_WITHOUT_SAVING' : 'CONFIRM'), customButtonText: t(i18nKeys.FORM.SAVE_AND_QUIT) })), _a), dialogProps));
        }
        return canChangePage || !when;
    };
    useEffect(function () {
        if (when !== state.blockNavigation) {
            unblock = history.block(block);
            setState({
                blockNavigation: when,
            });
            if (!disableNative) {
                window.addEventListener('beforeunload', onBeforeUnload);
            }
        }
        return function () {
            unblock === null || unblock === void 0 ? void 0 : unblock();
            if (!disableNative) {
                window.removeEventListener('beforeunload', onBeforeUnload);
            }
        };
    }, [when]);
    useEffect(function () {
        if (redirect) {
            unblock === null || unblock === void 0 ? void 0 : unblock();
            redirect();
        }
    }, [redirect]);
    var navigateToNextLocation = function (nextLocation, action) {
        var currentAction = {
            POP: 'goBack',
            PUSH: 'push',
            REPLACE: 'replace',
        }[action || 'PUSH'];
        if (!nextLocation)
            nextLocation = { pathname: '/' };
        unblock();
        if (action === 'goBack') {
            // Because there is asynchronous time between calling history.goBack()
            // and history actually changing, we need to set up this temporary callback
            // -- if we tried to run this synchronously after calling history.goBack(),
            // then navigateToNextLocation would be triggered again.
            var unlisten_1 = history.listen(function () {
                unlisten_1();
                setState(__assign({}, INIT_STATE));
            });
            history.goBack();
        }
        else
            history[currentAction](nextLocation);
    };
    return null;
}
export default function NavigationPrompt(props) {
    return isPublicEnvironment() ? React.createElement(NavigationPromptInner, __assign({}, props)) : null;
}
