import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var AvailableFooterFieldSchema = z.object({
    name: z.string().describe('Translated display name'),
    field: z.string().describe('Slugified name'),
    content: z.string(),
});
var FooterFieldSchema = z.union([
    z.object({
        field: z.string(),
        content: z.string(),
    }),
    z.string(),
    z.null(),
]);
var InvoiceFooterSchema = z.object({
    availableFields: z.array(AvailableFooterFieldSchema),
    fields: z.array(FooterFieldSchema),
});
export function loadInvoiceFooterQueryFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('invoice_footer_customisation')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, InvoiceFooterSchema.parse(data)];
            }
        });
    });
}
export function useLoadInvoiceFooter() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['invoice-footer'],
        queryFn: function () { return loadInvoiceFooterQueryFn(axiosInstance); },
        onError: function (error) {
            captureException(error);
        },
    });
    return addResourceNameToQueryResult('invoiceFooter', queryResult);
}
