import React from 'react';
import classNames from 'classnames/bind';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './dateItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DateItem(_a) {
    var date = _a.date, format = _a.format, lg = _a.lg, className = _a.className, specialClass = _a.specialClass, children = _a.children;
    return (React.createElement("span", { className: styles('DateItem', className, specialClass) },
        formattedDate(date, lg, format),
        " ",
        children));
}
