import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Checkbox } from 'shared/io';
import { CustomPreferencesFieldsName } from '../PreferencesForm.constants';
export var PaymentPlanPartialForm = function () {
    var t = useTranslation().t;
    var _a = useFormContext(), watch = _a.watch, register = _a.register;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = company.package, can_use_advanced_payment_plans = _b.can_use_advanced_payment_plans, can_use_payment_plans = _b.can_use_payment_plans;
    var disabled = !can_use_payment_plans || !can_use_advanced_payment_plans;
    return (React.createElement(Checkbox, { register: register(CustomPreferencesFieldsName.ALLOW_PAYMENT_PLANS), watch: watch, disabled: disabled, label: React.createElement("span", null,
            t(i18nKeys.FORM.PREFERENCES.ALLOW_PAYMENT_PLANS),
            ' ',
            React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: disabled
                    ? t(i18nKeys.NOT_INCLUDED)
                    : t(i18nKeys.FORM.PREFERENCES.TOOLTIP.ALLOW_PAYMENT_PLANS) })) }));
};
