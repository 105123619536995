import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCreditNoteFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
export default function ManageCreditNote(_a) {
    var params = _a.match.params, history = _a.history;
    var creditNote = useSelector(function (state) { var _a; return ((_a = state.creditNote.current) === null || _a === void 0 ? void 0 : _a.data) || undefined; });
    useEffect(function () {
        if (params.id) {
            creditNoteActions.detail({
                id: Number(params.id),
            });
        }
    }, [params]);
    var callback = function (_a) {
        var id = _a.data.id;
        return history.push(id ? "/credit-notes/detail/".concat(id) : '/credit-notes/listing');
    };
    if (!params.id || !creditNote) {
        return (React.createElement(InvoiceForm, { initialValues: getCreditNoteFormValues(), creditNote: creditNote, isCreditNote: true, submit: function (data) {
                return creditNoteActions.add({
                    data: data,
                    callback: callback,
                });
            } }));
    }
    return (React.createElement(InvoiceForm, { initialValues: getCreditNoteFormValues(creditNote), creditNote: creditNote, isCreditNote: true, submit: function (data) {
            return creditNoteActions.update({
                data: __assign({ id: creditNote.id }, data),
                callback: callback,
            });
        } }));
}
