import { __read, __spreadArray } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { Button, ButtonColor } from 'shared/io';
import { getWorkflowStepRights } from '../WorkflowForm.utils';
import styleIdentifiers from './WorkflowFormStep.scss';
import { WorkflowFormStepReminderTemplate } from './WorkflowFormStepReminderTemplate';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepReminder = function (_a) {
    var planType = _a.planType, availableTemplates = _a.availableTemplates, step = _a.step, children = _a.children;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = useSelector(function (state) { return state.app.constants; }), _c = _b.sending_medium, sending_medium = _c === void 0 ? [] : _c, _d = _b.recovery_step_types.recovery_plan_steps, templateDescVal = _d === void 0 ? [] : _d;
    var _e = getWorkflowStepRights(company, planType), canAddReminder = _e.canAddReminder, canEditReminder = _e.canEditReminder;
    var form = useFormContext();
    var _f = __read(useState(step === null || step === void 0 ? void 0 : step.step_type), 2), show = _f[0], setShow = _f[1];
    var templatesTypes = __spreadArray([step === null || step === void 0 ? void 0 : step.step_type], __read(availableTemplates), false).filter(function (e) { return e; });
    var watch = form.watch, register = form.register, setValue = form.setValue, errors = form.formState.errors;
    register('template', { required: true });
    register('step_type', { required: true });
    var template = watch('template');
    var sending_media = watch('sending_media') || [];
    var stepType = watch('step_type');
    var addMedium = function () {
        var defaultSendingMedium = (sending_medium.filter(function (e) { return !sending_media.some(function (el) { return e.value === el.name; }); }) || sending_medium)[0];
        if (sending_media.length < sending_medium.length) {
            setValue('sending_media', __spreadArray(__spreadArray([], __read(sending_media), false), [
                {
                    name: defaultSendingMedium.value,
                    order: sending_media.length + 1,
                    force_sending: 'true',
                },
            ], false), {
                shouldTouch: true,
            });
        }
    };
    useEffect(function () {
        setShow(stepType);
    }, [stepType]);
    var onClickTemplate = function (selectedTemplate) {
        var _a;
        setValue('template', selectedTemplate);
        setValue('step_type', (_a = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.attributes) === null || _a === void 0 ? void 0 : _a.step_type);
    };
    var isFormDisabled = step ? !canEditReminder : !canAddReminder;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('head') }, t(step ? i18nKeys.EDIT_REMINDER : i18nKeys.ADD_REMINDER)),
        React.createElement("div", { className: styles('content') },
            isFormDisabled && React.createElement("p", { className: styles('not-included') }, t(i18nKeys.NOT_INCLUDED)),
            React.createElement(FormSection, { title: t(i18nKeys.DEBTOR.SENDING_MEDIUMS) },
                React.createElement(SendingMedia, { fieldName: "sending_media", className: styles('input') }),
                sending_media.length < sending_medium.length && (React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.ACTIONS.ADD), iconRight: IconName.PLUS, onClick: addMedium, disabled: isFormDisabled, small: true }))),
            children,
            React.createElement(FormSection, { title: t(i18nKeys.COMMON.TEMPLATE), className: styles('form-section') },
                React.createElement("div", { className: styles('actions') },
                    get(errors, 'template') && (React.createElement("div", { className: styles('error-message', 'error') }, t(i18nKeys.PLAN.SELECT_TEMPLATE))),
                    React.createElement("div", null, templateDescVal
                        .filter(function (e) { return templatesTypes.includes(e.value); })
                        .map(function (_a) {
                        var description = _a.description, templateType = _a.value;
                        return (React.createElement("div", { key: "template-".concat(templateType), onClick: function () { return setShow(templateType); }, className: styles('action', show && 'show-list', show !== templateType && 'hide', template && template.attributes.step_type === templateType && 'selected') },
                            React.createElement("div", { className: styles('template-name') },
                                React.createElement(Icon, { name: IconName.SEND }),
                                React.createElement("span", null, description),
                                show === templateType && (React.createElement(Icon, { name: IconName.SMALL_REMOVE, className: styles('remove-icon'), onClick: function () { return onClickTemplate(); } }))),
                            show === templateType && (React.createElement(WorkflowFormStepReminderTemplate, { disabled: isFormDisabled, params: {
                                    step_type: templateType,
                                    plan_type: planType,
                                }, onClick: function (selectedTemplate) { return onClickTemplate(selectedTemplate); } }))));
                    })))))));
};
