import { __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Checkbox } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { config } from 'store/constants';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { DigitealIntegrationStatus, INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var DigitealFormFieldsName;
(function (DigitealFormFieldsName) {
    DigitealFormFieldsName["ACCEPT_CGV"] = "accept_cgv";
    DigitealFormFieldsName["ACCEPT_DIGITEAL_CGV"] = "accept_digiteal_cgv";
})(DigitealFormFieldsName || (DigitealFormFieldsName = {}));
export var DigitealForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _c = __read(useState(), 2), acceptCGV = _c[0], setacceptCGV = _c[1];
    var _d = __read(useState(), 2), acceptDigitealCGV = _d[0], setaccepDigitealtCGV = _d[1];
    var _e = __read(useState(), 2), digitealCGV = _e[0], setDigitealCGV = _e[1];
    useLayoutEffect(function () {
        accountActions.getDigitealCGV({
            callback: setDigitealCGV,
        });
    }, []);
    var isActive = getIntegrationRights(Integration.DIGITEAL, company).isActive;
    var status = company.digiteal_integration.status;
    if (status === DigitealIntegrationStatus.IN_PROCESS) {
        return (React.createElement("div", { className: styles('integration-form') },
            React.createElement("div", null, t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)),
            React.createElement("div", { className: styles('in-process-info') },
                React.createElement(Icon, { name: IconName.INFO }),
                ' ',
                t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.SIGNUP_TO_CONTINUE))));
    }
    var setAvailability = function () {
        accountActions.setDigitealConfiguration({
            data: {
                use_digiteal_integration: !isActive,
            },
            callback: INTEGRATION_UPDATE_CALLBACK,
        });
    };
    if (status === DigitealIntegrationStatus.ACTIVATED) {
        return (React.createElement("div", { className: styles('integration-form') },
            React.createElement("div", null, t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { onClick: setAvailability, label: t(isActive
                        ? i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_UNAVAILABLE
                        : i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_AVAILABLE) }))));
    }
    var submit = function () {
        if (acceptCGV && acceptDigitealCGV) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.CONFIRMATION),
                children: (React.createElement(AYSModal, { onConfirm: onSubmit, text: React.createElement(React.Fragment, null,
                        React.createElement("p", null, t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.AYS_TO_ACTIVATE)),
                        React.createElement("p", null, t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.EMAIL_TO_ACTIVATE))) })),
            });
        }
        else {
            if (!acceptCGV) {
                setacceptCGV(false);
            }
            if (!acceptDigitealCGV) {
                setaccepDigitealtCGV(false);
            }
        }
    };
    return (React.createElement("div", { className: styles('integration-form') },
        React.createElement("div", null, t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)),
        React.createElement("div", null,
            React.createElement(Checkbox, { name: DigitealFormFieldsName.ACCEPT_CGV, checked: acceptCGV, onChange: function (value) { return setacceptCGV(value); }, errorMessage: acceptCGV === false && i18nKeys.ERROR.FIELD_REQUIRED, label: React.createElement(HTML, { className: styles('checkbox-label'), html: t(i18nKeys.SETTINGS.PACKAGE.ACCEPT_CGV, {
                        link: config.terms[currentLang],
                    }) }) }),
            React.createElement(Checkbox, { name: DigitealFormFieldsName.ACCEPT_DIGITEAL_CGV, checked: acceptDigitealCGV, onChange: function (value) { return setaccepDigitealtCGV(value); }, errorMessage: acceptDigitealCGV === false && i18nKeys.ERROR.FIELD_REQUIRED, label: digitealCGV && (React.createElement(HTML, { html: t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.ACCEPT_CGV, {
                        link1: "<a href=\"".concat(digitealCGV[0].url, "\" target=\"_blank\">").concat(digitealCGV[0].name, "</a>"),
                        link2: "<a href=\"".concat(digitealCGV[1].url, "\" target=\"_blank\">").concat(digitealCGV[1].name, "</a>"),
                        link3: "<a href=\"".concat(digitealCGV[2].url, "\" target=\"_blank\">").concat(digitealCGV[2].name, "</a>"),
                    }) })) })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { onClick: submit, label: t(i18nKeys.ACTIVATE) }))));
};
