import { upperFirst } from 'lodash';
export function addResourceNameToQueryResult(resourceName, queryResult) {
    var _a;
    var resourceNameCapitalised = upperFirst(resourceName);
    return _a = {},
        _a[resourceName] = queryResult.data,
        _a["".concat(resourceName, "Error")] = queryResult.error,
        _a["is".concat(resourceNameCapitalised, "Fetching")] = queryResult.isFetching,
        _a["is".concat(resourceNameCapitalised, "Loading")] = queryResult.isLoading,
        _a["refetch".concat(resourceNameCapitalised)] = queryResult.refetch,
        _a;
}
