import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { TasksList } from 'app/Private/Tasks/TasksList/TasksList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Monitoring } from 'modules/Debtor/monitoring';
import { useLocation } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { useGetCompany } from 'shared/utils/selectors';
import { TabInfo } from './TabInfo/TabInfo';
import { ThirdPartyCaseTable } from './ThirdpartyCaseTable/ThirdpartyCaseTable';
import { BalanceTab } from './BalanceTab';
import { ClientDetailTabsTimeline } from './ClientDetailTabsTimeline';
import styleIdentifiers from './ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabs = function (_a) {
    var _b;
    var debtor = _a.debtor, rest = __rest(_a, ["debtor"]);
    var location = useLocation();
    var tabItemProps = __assign({ debtor: debtor }, rest);
    var tabItems = useBuildTabItems(tabItemProps);
    var _c = __read(useState((_b = getSlugFromHash(location.hash)) !== null && _b !== void 0 ? _b : tabItems[0].slug), 2), activeSlug = _c[0], setActiveSlug = _c[1];
    useEffect(function () {
        var newTabSlug = getSlugFromHash(location.hash);
        if (isNullish(newTabSlug))
            return;
        setActiveSlug(newTabSlug);
    }, [location.hash]);
    var handleTabChange = function (index) {
        var newSlug = tabItems[index].slug;
        setActiveSlug(newSlug);
        window.history.replaceState(null, '', "#".concat(newSlug));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles('workflows__tabs'), tabIndex: tabItems.findIndex(function (item) { return item.slug === activeSlug; }), items: tabItems.map(function (e) { return e.title; }), noBody: true, onChange: handleTabChange }),
        tabItems.map(function (_a) {
            var title = _a.title, children = _a.children, slug = _a.slug;
            return activeSlug === slug && (React.createElement(Card, { key: "tab-".concat(title), noRadiusTop: true, noPadding: slug.includes('/cases'), noHead: true, noBorder: true }, children));
        })));
};
function getSlugFromHash(hash) {
    if (isEmpty(hash))
        return null;
    return hash.replace('#', '');
}
function useBuildTabItems(tabItemProps) {
    var _a, _b;
    var t = useTranslation().t;
    var company = useGetCompany();
    var debtor = tabItemProps.debtor;
    var tabItems = [
        {
            title: React.createElement("h3", null, t(i18nKeys.CLIENT.GENERAL_INFO)),
            slug: 'general-info',
            children: React.createElement(TabInfo, __assign({}, tabItemProps)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.DASHBOARD.BALANCE_DETAIL)),
            slug: 'balance-detail',
            children: React.createElement(BalanceTab, __assign({}, tabItemProps)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.INVOICE.ACTIVITIES)),
            slug: 'timeline',
            children: React.createElement(ClientDetailTabsTimeline, __assign({}, tabItemProps)),
        },
    ];
    var isTasksModuleEnabledInPackage = (_a = company.package.can_use_tasks) !== null && _a !== void 0 ? _a : false;
    if (isTasksModuleEnabledInPackage) {
        tabItems.push({
            title: React.createElement("h3", null, t(i18nKeys.NAV.TASKS)),
            slug: 'tasks',
            children: React.createElement(TasksList, __assign({}, tabItemProps)),
        });
    }
    var thirdPartyCaseCount = (_b = debtor.attributes.tp_cases_count) !== null && _b !== void 0 ? _b : 0;
    if (thirdPartyCaseCount > 0) {
        tabItems.push({
            title: React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.CURRENT_CASES)),
            slug: 'thirdparty-case-table',
            children: React.createElement(ThirdPartyCaseTable, { debtorId: debtor.id }),
        });
    }
    var vatNumber = debtor.attributes.vat_number;
    tabItems.push({
        title: React.createElement("h3", null, t(i18nKeys.DEBTOR.MONITORING.TAB_TITLE)),
        slug: 'monitoring',
        children: React.createElement(Monitoring, { hasVatNumber: isNonNull(vatNumber) && !isEmpty(vatNumber) }),
    });
    return tabItems;
}
