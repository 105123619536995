import { __assign } from "tslib";
import dayjs from 'dayjs';
import { formattedDate } from 'shared/utils/view';
import { PaymentPlanStatus } from './PaymentPlan.constants';
export var computeStatus = function (cancelled, completed) {
    if (cancelled) {
        return PaymentPlanStatus.CANCELLED;
    }
    if (completed) {
        return PaymentPlanStatus.COMPLETED;
    }
    return PaymentPlanStatus.IN_PROGRESS;
};
export var computeNextStepDate = function (status, lang, step) {
    if ([PaymentPlanStatus.CANCELLED, PaymentPlanStatus.COMPLETED].includes(status) || !step) {
        return '-';
    }
    return formattedDate(dayjs(step.attributes.due_date).toDate(), lang);
};
export var adjustNextDates = function (values, index, newDelay) {
    var dueDate = dayjs(values[index].due_date);
    return values.map(function (el, i) {
        // eslint-disable-next-line
        if (i <= index || el['_destroy']) {
            return el;
        }
        dueDate = dueDate.add(Number(newDelay), 'day');
        return __assign(__assign({}, el), { due_date: dueDate.format('YYYY-MM-DD') });
    });
};
export var adjustNextAmounts = function (values, index, total) {
    var value = values[index];
    var previousStepsTotal = values.reduce(function (acc, el, i) {
        // eslint-disable-next-line
        return i <= index && !el['_destroy'] ? acc + Number(el.amount) : acc;
    }, 0);
    var remaining = Math.round((total - previousStepsTotal) * 100) / 100;
    return values
        .map(function (el, i, arr) {
        // eslint-disable-next-line
        if (i <= index || el['_destroy']) {
            return el;
        }
        if (remaining <= 0) {
            return el.id
                ? __assign(__assign({}, el), { _destroy: true }) : undefined;
        }
        var amount = arr.length - 1 === i && remaining > 0
            ? Math.round(remaining * 100) / 100
            : Math.round(Math.min(remaining, value.amount) * 100) / 100;
        remaining -= amount;
        return __assign(__assign({}, el), { amount: amount });
    })
        .filter(function (e) { return e; });
};
