import { __rest } from "tslib";
import React from 'react';
import { filter, isTruthy, map, pipe } from 'remeda';
import { useIsFrenchClient } from 'shared/hooks/use-is-french-client';
import { isConnectorActive } from 'shared/utils/connector';
export var HideIf = function (_a) {
    var children = _a.children, conditions = __rest(_a, ["children"]);
    var flags = {
        connectorActive: isConnectorActive(),
        isFrench: useIsFrenchClient(),
    };
    var shouldHide = pipe(conditions, Object.keys, filter(isTruthy), map(function (key) { return flags[key]; }), function (t) { return t.some(Boolean); });
    if (shouldHide) {
        return null;
    }
    return React.createElement(React.Fragment, null, children);
};
