import { __read } from "tslib";
import React, { useEffect } from 'react';
import { isPublicEnvironment } from 'shared/utils/environment';
import { Box, Portal, Select, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
// eslint-disable-next-line @typescript-eslint/comma-dangle
export var useVariant = function (variants) {
    var _a = __read(useLocalStorage({
        key: 'use-variant',
        defaultValue: 0,
    }), 2), variantIndex = _a[0], setVariantIndex = _a[1];
    var currentVariant = variants[variantIndex];
    useEffect(function () {
        if (isPublicEnvironment())
            return undefined;
        var handleKeyPress = function (event) {
            if (event.key === 'ArrowRight')
                setVariantIndex(function (index) { return Math.min(index + 1, variants.length - 1); });
            else if (event.key === 'ArrowLeft')
                setVariantIndex(function (index) { return Math.max(index - 1, 0); });
            else if (Number(event.key) >= 1 && Number(event.key) <= variants.length)
                setVariantIndex(Number(event.key) - 1);
        };
        window.addEventListener('keydown', handleKeyPress);
        return function () {
            window.removeEventListener('keydown', handleKeyPress);
            return undefined;
        };
    }, [variants, setVariantIndex]);
    var Controls = function () {
        return (React.createElement(Portal, { target: "#variants-portal" },
            React.createElement(Box, { p: "sm", bg: "orange.2" },
                React.createElement(Select, { description: "Arrow and number keys can be used", value: String(variantIndex), onChange: function (val) { return setVariantIndex(Number(val)); }, label: "".concat(variants.length, " variants for this page:"), data: variants.map(function (variant, index) { return ({
                        label: "".concat(index + 1, " - ").concat(variant.name),
                        value: String(index),
                    }); }), inputWrapperOrder: ['label', 'input', 'description'] }),
                currentVariant.note && (React.createElement(Box, { c: "gray.8", bg: "orange.1", p: "xs", mt: "xs", style: { borderRadius: '5px' } },
                    React.createElement(Text, { fw: "bold" }, "Note:"),
                    React.createElement(Text, { size: "sm" }, currentVariant.note))))));
    };
    if (isPublicEnvironment()) {
        return { variant: variants[0].element, Controls: function () { return null; } };
    }
    return { variant: currentVariant.element, Controls: Controls };
};
