import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './RequestPaymentPlanModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function RequestPaymentPlanModal(_a) {
    var invoices = _a.invoices, invoiceId = _a.invoiceId, token = _a.token, currency = _a.currency, amount = _a.amount, onSubmit = _a.onSubmit;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var invoice_ids = invoices.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var handleSubmit = function () {
        onSubmit({ invoice_ids: invoice_ids, invoice_id: invoiceId, token: token });
    };
    var onCancel = function () {
        dialogHide(DialogShowId.CUSTOM);
    };
    return (React.createElement("div", { className: styles('request-payment-plan-modal') },
        t(i18nKeys.AYS.REQUEST_PAYMENT_PLAN),
        React.createElement("ul", { className: styles('invoices-list') },
            React.createElement("li", { className: styles('list-header') },
                React.createElement("span", null, t(i18nKeys.FORM.REFERENCE)),
                React.createElement("span", null, t(i18nKeys.ISSUE_DATE)),
                React.createElement("span", null, t(i18nKeys.DUE_DATE)),
                React.createElement("span", null, t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE))),
            invoices
                .filter(function (_a) {
                var type = _a.type;
                return type === 'debtor_reaction';
            })
                .map(function (_a) {
                var id = _a.id, reference = _a.reference, remaining_balance_with_fees = _a.remaining_balance_with_fees, issue_date = _a.issue_date, due_date = _a.due_date, localizedRemaining = _a.localized_money_object.remaining_balance_with_fees;
                return (React.createElement("li", { className: styles('invoice'), key: id },
                    React.createElement("span", null, reference),
                    React.createElement("span", null, formattedDate(issue_date, currentLang)),
                    React.createElement("span", null, formattedDate(due_date, currentLang)),
                    React.createElement(Amount, { value: remaining_balance_with_fees, localizedValue: localizedRemaining, suffix: currency, className: styles('blue') })));
            }),
            React.createElement("li", { className: styles('list-footer') },
                React.createElement(Amount, { value: amount, localizedValue: amount, suffix: currency, className: styles('blue') }))),
        React.createElement("div", { className: styles('buttons-wrapper') },
            React.createElement(Button, { color: ButtonColor.GREY, type: "submit", label: t(i18nKeys.CANCEL), onClick: onCancel }),
            React.createElement(Button, { type: "submit", color: ButtonColor.BLUE, label: t(i18nKeys.CONFIRM), onClick: handleSubmit }))));
}
