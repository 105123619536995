import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import { CalculationType, InterestCalculationType } from 'shared/forms/PreferencesForm';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function LatePaymentInfoTermsModal(_a) {
    var invoice = _a.invoice;
    var t = useTranslation().t;
    var _b = invoice.terms_and_conditions, fix_fee = _b.fix_fee, interest = _b.interest, penalty_clause = _b.penalty_clause, localized_money_object = invoice.localized_money_object, currency = invoice.currency;
    return (React.createElement("div", { className: styles('box', 'to-handle-detail-modal') },
        !!(penalty_clause === null || penalty_clause === void 0 ? void 0 : penalty_clause.value) && (React.createElement(React.Fragment, null,
            React.createElement("h4", null, t(i18nKeys.PENALTY_CLAUSE)),
            React.createElement("div", { className: styles('item-container') },
                !!(penalty_clause === null || penalty_clause === void 0 ? void 0 : penalty_clause.minimum) && (React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.AMOUNT_MIN)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { localizedValue: penalty_clause.minimum, value: localized_money_object.terms_and_conditions.penalty_clause.minimum, suffix: currency })))),
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(penalty_clause.type === InterestCalculationType.PERCENTAGE
                        ? i18nKeys.VALUE
                        : i18nKeys.AMOUNT)),
                    React.createElement("span", { className: styles('value') }, penalty_clause.type === InterestCalculationType.PERCENTAGE ? (React.createElement(Percentage, { value: penalty_clause.value })) : (React.createElement(Amount, { localizedValue: penalty_clause.value, value: localized_money_object.terms_and_conditions.penalty_clause.value, suffix: currency }))))))),
        !!(interest === null || interest === void 0 ? void 0 : interest.value) && (React.createElement(React.Fragment, null,
            React.createElement("h4", null, t(i18nKeys.FORM.PREFERENCES.INTEREST)),
            React.createElement("div", { className: styles('item-container') },
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PREFERENCES.PERIODICITY)),
                    React.createElement("span", { className: styles('value') }, t(i18nKeys.CALCULATION_TYPE[Object.keys(CalculationType).find(function (key) { return interest.period === CalculationType[key]; })]))),
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.DETAIL.INTEREST_RATE)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Percentage, { value: interest.value })))))),
        !!(fix_fee === null || fix_fee === void 0 ? void 0 : fix_fee.value) && (React.createElement(React.Fragment, null,
            React.createElement("h4", null, t(i18nKeys.FORM.PREFERENCES.FIX_FEE)),
            React.createElement("div", { className: styles('item-container') },
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.AMOUNT)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { localizedValue: fix_fee.value, value: localized_money_object.terms_and_conditions.fix_fee.value, suffix: currency }))))))));
}
