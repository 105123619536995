/**
 * It takes a key and returns the value of the query parameter with that key
 * @param {string} key - The key of the query parameter you want to get.
 * @returns A string or undefined.
 */
export var getQueryParams = function (key) {
    var urlParams = new URLSearchParams(window.location.search);
    var value = urlParams.get(key);
    return value ? value : undefined;
};
export var setQueryParams = function (key, value) {
    var searchParams = new URLSearchParams(window.location.search);
    value === undefined ? searchParams.delete(key) : searchParams.set(key, value);
    var newRelativePathQuery = "".concat(window.location.pathname, "?").concat(searchParams.toString());
    history.pushState(null, '', newRelativePathQuery);
};
