import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import UserInfo from 'shared/components/UserInfo';
import { useLoadLightUsers, useProfile } from 'shared/hooks';
import { Button, CustomSelect, DateSelector } from 'shared/io';
import { usersToDescVal } from 'shared/utils';
import { useFilterForm } from 'shared/utils/hooks';
import styleIdentifiers from './activityFiltersForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ActivityFiltersForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var profile = useProfile();
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var _c = useSelector(function (state) { return state.app.constants; }), activity_categories = _c.activity_categories, activity_authors = _c.activity_authors;
    var usersFormatted = usersToDescVal(users);
    var usersAndAuthors = __spreadArray(__spreadArray([], __read(activity_authors), false), __read(usersFormatted), false);
    var _d = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: ['start_date', 'end_date'],
    }), formData = _d.formData, form = _d.form, submit = _d.submit, resetForm = _d.resetForm;
    var handleSubmit = form.handleSubmit, setValue = form.setValue;
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = resetForm;
        }
    }, [filterObject, resetForm]);
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('ActivityFiltersForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('fields') },
                React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "start_date", endName: "end_date", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, label: t(i18nKeys.DATE), handleChange: function (value) {
                        setValue('start_date', value);
                        submit();
                    }, handleEndChange: function (value) {
                        setValue('end_date', value);
                        submit();
                    } }),
                React.createElement(Controller, { defaultValue: "", name: "category", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), keyText: "description", keyValue: "value", name: "category", noMargin: true, withBorder: true, shadow: true, items: activity_categories, label: t(i18nKeys.FORM.CATEGORY), onValueChanged: submit })); } }),
                React.createElement(Controller, { defaultValue: "", name: "author", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), keyText: "description", keyValue: "value", name: "author", noMargin: true, withBorder: true, shadow: true, listClassName: styles('author-suggestions'), items: usersAndAuthors, label: t(i18nKeys.ACTIVITIES.AUTHOR), onValueChanged: submit })); } }),
                (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Controller, { defaultValue: "", name: "account_manager_id", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('xl'), keyValue: "id", onValueChanged: submit, withBorder: true, shadow: true, itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({ inline: true }, props)); }, name: "account_manager_id", items: users, label: t(i18nKeys.ACCOUNT_MANAGER) })); } })),
                React.createElement(Button, { noShadow: true, noMargin: true, type: "submit", className: styles('invisible') })))));
}
