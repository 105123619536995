import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import InvoiceSettingsForm from 'shared/forms/InvoiceSettingsForm';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
export var InvoiceSettings = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var handleUpdate = function (callback) { return function (values) {
        var _a;
        values.billing_logs_attributes =
            ((_a = values.billing_logs_attributes) === null || _a === void 0 ? void 0 : _a.map(function (billing_log) {
                if (!billing_log.id) {
                    delete billing_log.id;
                }
                return billing_log;
            })) || [];
        accountActions.updateInvoiceConfigurations({
            data: values,
            callback: callback,
        });
    }; };
    var initialValues = {
        color: company.invoice_customization.color,
        billing_logs_attributes: company.invoice_customization.billing_logs,
        logo: company.invoice_customization.logo_url,
        sending_media: company.sending_media_attributes.map(function (medium) { return (__assign(__assign({}, medium), { force_sending: medium.force_sending })); }),
        generate_invoice_in_debtor_locale: company.generate_invoice_in_debtor_locale.toString(),
        default_credit_note_template_id: company.default_templates.credit_note,
        default_invoice_template_id: company.default_templates.invoice,
        display_qr_code: company.display_qr_code.toString(),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.INVOICING.TITLE)),
        React.createElement(InvoiceSettingsForm, { onSubmit: handleUpdate, initialValues: initialValues })));
};
