import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import UserAvatar from 'shared/components/UserAvatar';
import { useUpdateUser } from 'shared/hooks';
import { useResendInvitation } from 'shared/hooks/use-resend-invitation';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import EditCollaboratorForm from './EditCollaboratorForm';
import styleIdentifiers from './colleagueItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ColleagueItem(_a) {
    var user = _a.item;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var updateUser = useUpdateUser().updateUser;
    var _b = useResendInvitation(), resendInvitation = _b.resendInvitation, isResendInvitationLoading = _b.isResendInvitationLoading;
    var handleEditUser = function (newUser) {
        updateUser({ id: user.id, data: newUser });
        sideMenuHide();
    };
    var onClickEditUser = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(EditCollaboratorForm, { initialValues: user, onSubmit: handleEditUser }),
        });
    };
    var id = user.id, roles = user.roles, email = user.email, isAdmin = user.isAdmin, isActive = user.isActive, hasAcceptedGc = user.hasAcceptedGc;
    return (React.createElement("tr", { className: styles('ColleagueItem'), key: id },
        React.createElement("td", { className: styles('small') }),
        React.createElement("td", null, id),
        React.createElement("td", null,
            React.createElement("div", { className: styles('user') },
                React.createElement(UserAvatar, { user: user }),
                React.createElement("div", { className: styles('name') }, user.name.full))),
        React.createElement("td", { className: styles('role') }, hasAcceptedGc ? (React.createElement(React.Fragment, null, isAdmin
            ? constants.user_roles.find(function (role) { return role.value === 'admin'; }).description
            : roles.map(function (currentRole, i) { return (React.createElement("div", { key: i }, constants.user_roles.find(function (role) { return role.value === currentRole; })
                .description)); }))) : (React.createElement(Button, { label: t(i18nKeys.FORM.INVITE_STAFF.RESEND_INVITATION), noMargin: true, noShadow: true, small: true, iconRight: IconName.SEND, disabled: isResendInvitationLoading, onClick: function () { return resendInvitation(id); } }))),
        React.createElement("td", { className: styles('email') }, email),
        React.createElement("td", { className: styles('status', isActive && 'active') }, t(isActive ? i18nKeys.SETTINGS.STAFF.ACTIVE : i18nKeys.SETTINGS.STAFF.INACTIVE)),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: onClickEditUser },
                React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') })))));
}
