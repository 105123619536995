import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isEmpty } from 'remeda';
import RecovrLogoLoader from 'shared/components/Loader';
import { useAcceptGcollectRates, useLoadGcollectCaseFee } from 'shared/hooks';
import { formatAmount } from 'shared/utils/normalization';
import { Button, Group, Stack, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
export var GcollectModalValidate = function (_a) {
    var _b;
    var caseIds = _a.caseIds, _c = _a.errors, errors = _c === void 0 ? [] : _c;
    var t = useTranslation().t;
    var history = useHistory();
    var _d = useLoadGcollectCaseFee(caseIds), gcollectCaseFee = _d.gcollectCaseFee, isGcollectCaseFeeLoading = _d.isGcollectCaseFeeLoading;
    var _e = useAcceptGcollectRates(), acceptGcollectRates = _e.acceptGcollectRates, isAcceptGcollectRatesLoading = _e.isAcceptGcollectRatesLoading;
    if (isGcollectCaseFeeLoading)
        return React.createElement(RecovrLogoLoader, null);
    var caseRows = ((_b = gcollectCaseFee === null || gcollectCaseFee === void 0 ? void 0 : gcollectCaseFee.invoices) !== null && _b !== void 0 ? _b : []).map(function (_a) {
        var reference = _a.reference, collectionFee = _a.collectionFee, gcollectCollectionRate = _a.gcollectCollectionRate, remainingAmount = _a.remainingAmount;
        return [
            reference,
            formatAmount(remainingAmount),
            "".concat(Number((gcollectCollectionRate * 100).toFixed(2)), " %"),
            formatAmount(collectionFee),
        ];
    });
    var errorRows = errors.map(function (_a) {
        var key = _a.key, error_message = _a.error_message;
        return [key, error_message];
    });
    return (React.createElement(Stack, { gap: "md" },
        !isEmpty(caseRows) && (React.createElement(React.Fragment, null,
            React.createElement(Text, null, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_TEXT)),
            React.createElement(Table, { w: "100%", striped: true, style: { border: '1px solid lightgrey' }, data: {
                    head: [
                        t(i18nKeys.COMMON.INVOICE),
                        t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.INVOICE_AMOUNT),
                        t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_PERCENTAGE),
                        t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_AMOUNT),
                    ],
                    body: caseRows,
                } }))),
        !isEmpty(errors) && (React.createElement(React.Fragment, null,
            React.createElement(Text, null, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_ERRORS)),
            React.createElement(Table, { w: "100%", striped: true, style: { border: '1px solid lightgrey' }, data: {
                    head: [t(i18nKeys.COMMON.INVOICE), t(i18nKeys.REASON)],
                    body: errorRows,
                } }))),
        React.createElement(Group, { gap: "md", justify: "end" }, isEmpty(caseRows) ? (React.createElement(Button, { color: "red", onClick: modals.closeAll }, t(i18nKeys.CLOSE))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "subtle", color: "red", disabled: isAcceptGcollectRatesLoading, onClick: function () {
                    return acceptGcollectRates({ thirdPartyCaseIds: caseIds, acceptRates: false }, { onSuccess: function () { return modals.closeAll(); } });
                } }, t(i18nKeys.CANCEL)),
            React.createElement(Button, { loading: isAcceptGcollectRatesLoading, onClick: function () {
                    return acceptGcollectRates({ thirdPartyCaseIds: caseIds, acceptRates: true }, {
                        onSuccess: function () {
                            history.push('/thirdparty_cases');
                            modals.closeAll();
                        },
                    });
                } }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.ACCEPT)))))));
};
