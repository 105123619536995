import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Card from 'shared/components/Card';
import { Button, ButtonColor, Input, RadioButton } from 'shared/io';
import styleIdentifiers from '../../PaymentPlanForm/StepsCalculation/StepsCalculation.scss';
import { StepsCalculationInvoices } from '../../PaymentPlanForm/StepsCalculation/StepsCalculationInvoices';
var styles = classNames.bind(styleIdentifiers);
export var AddInvoicesForm = function (_a) {
    var onSubmit = _a.onSubmit, debtor = _a.debtor, onCancel = _a.onCancel;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            new_steps: false,
        },
    });
    var register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, watch = form.watch, errors = form.formState.errors;
    register('new_steps');
    var newSteps = watch('new_steps');
    var _b = __read(useState('count'), 2), deadlineType = _b[0], setDeadlineType = _b[1];
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('add-invoices-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement(Card, { title: t(i18nKeys.DETAILS) },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('content', 'col-12') },
                        React.createElement(RadioButton, { name: "new_steps", className: styles('radio-buttons'), onChange: function (value) {
                                setValue('new_steps', value);
                            }, noMargin: true, items: [
                                {
                                    value: false,
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.SPREAD_TO_EXISTING),
                                },
                                {
                                    value: true,
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADD_NEW_STEPS),
                                },
                            ] }))),
                React.createElement("div", { className: styles('content') },
                    React.createElement(StepsCalculationInvoices, { currentDebtor: debtor })),
                newSteps && (React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('content', 'col-12') },
                        React.createElement(RadioButton, { className: styles('radio-buttons'), noMargin: true, onChange: function (value) {
                                setDeadlineType(value);
                            }, value: deadlineType, name: "deadline_type", items: [
                                {
                                    value: 'count',
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT),
                                },
                                {
                                    value: 'amount',
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT),
                                },
                            ] })),
                    deadlineType === 'count' && (React.createElement("div", { className: styles('content', 'col-6') },
                        React.createElement(Input, { register: register('deadline_count', {
                                required: true,
                                min: 1,
                            }), errorMessage: get(errors, 'deadline_count'), noMargin: true, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT), type: "number", step: "1" }))),
                    deadlineType === 'amount' && (React.createElement("div", { className: styles('content', 'col-6') },
                        React.createElement(Input, { register: register('deadline_amount', {
                                required: true,
                                min: 0,
                            }), errorMessage: get(errors, 'deadline_amount'), noMargin: true, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT), type: "number", step: "0.01" }))),
                    React.createElement("div", { className: styles('content', 'col-6') },
                        React.createElement(Input, { register: register('delay', { required: true, min: 1 }), errorMessage: get(errors, 'delay'), noMargin: true, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY), type: "number", step: "1", placeholder: t(i18nKeys.DAYS, { count: 30 }) })))),
                React.createElement("div", { className: styles('content', 'col-12', 'button-wrapper') },
                    React.createElement(Button, { onClick: onCancel, noMargin: true, color: ButtonColor.GREY, label: t(i18nKeys.CANCEL), className: styles('cancel') }),
                    React.createElement(Button, { type: "submit", noMargin: true, label: t(i18nKeys.ADD_INVOICES) }))))));
};
