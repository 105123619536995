import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, CustomSelect, DateSelector, Input, Slider, Textarea } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import styleIdentifiers from './encodePaymentForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function EncodePaymentForm(_a) {
    var onSubmit = _a.onSubmit, currency = _a.currency, totalAmount = _a.totalAmount, amountWithoutFees = _a.amountWithoutFees;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var form = useForm();
    var handleSubmit = form.handleSubmit, register = form.register, errors = form.formState.errors, setValue = form.setValue, unregister = form.unregister, watch = form.watch;
    useEffect(function () {
        register('received_at', { required: true });
        return function () {
            unregister('received_at');
        };
    }, []);
    useEffect(function () {
        setValue('amount', 0);
    }, []);
    var formatPercent = function (value) {
        return "".concat(value, "%");
    };
    var changeRangeAmount = function (value) {
        setValue('amount', (Math.round(((totalAmount * value) / 100) * 100) / 100).toFixed(2));
    };
    var changeAmount = function (value) {
        setValue('amount', Number(value).toFixed(2));
    };
    var amount = watch('amount');
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('EncodePaymentForm'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('head') }, t(i18nKeys.SHARED.ENCODE_PAYMENT.TITLE)),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement("div", { className: styles('with-border-input') },
                            React.createElement("div", { className: styles('label-input') }, t(i18nKeys.AMOUNT_CURRENCY, {
                                text: currencySymbol(currency, company),
                            })),
                            React.createElement("div", { className: styles('slider-input') },
                                React.createElement(Slider, { currentValue: (+amount / totalAmount) * 100, maxVal: 100, step: 5, renderValue: formatPercent, onSelect: changeRangeAmount }),
                                React.createElement(Input, { style: { marginLeft: '10px' }, register: register('amount', { required: true }), type: "number", min: "0", max: totalAmount, required: true, step: "0.01", noMargin: true, placeholder: currencySymbol(currency, company) })),
                            errors.amount && 'ERROR.FIELD_REQUIRED',
                            React.createElement("div", { className: styles('buttons-container') },
                                (amountWithoutFees && amountWithoutFees != totalAmount && (React.createElement("div", { className: styles('label-action', 'blue'), onClick: function () {
                                        changeAmount(amountWithoutFees);
                                    } }, "Total sans frais de retard"))) ||
                                    null,
                                React.createElement("div", { className: styles('label-action'), onClick: function () {
                                        changeAmount(totalAmount);
                                    } }, "Total")))),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "received_at", render: function () { return (React.createElement(DateSelector, { name: "received_at", handleChange: function (value) {
                                    return setValue('received_at', value, { shouldValidate: true });
                                }, label: t(i18nKeys.FORM.PAYMENT_DATE), noMinDate: true, withBorder: true })); } })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Controller, { rules: { required: true }, name: "modality", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { name: "modality", keyText: "description", keyValue: "value", label: t(i18nKeys.MODALITY), withBorder: true, items: constants.payment_modalities })); } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Textarea, { register: register('notes'), withBorder: true, label: t(i18nKeys.NOTES) })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" })))));
}
