// export const cashAccountMask = ['5', '7', '0', /[0-9]/, /\d/, /\d/];
import { accountActions } from 'store/account/account.actions';
export var IntegrationFormFieldsName;
(function (IntegrationFormFieldsName) {
    IntegrationFormFieldsName["ISSUE_DATE_LIMIT"] = "tp_accounting_issue_date_limit";
    IntegrationFormFieldsName["DEBTOR_UPDATE"] = "tp_accounting_debtor_update";
    IntegrationFormFieldsName["AUTOMATIC_SYNC"] = "tp_accounting_automatic_sync";
})(IntegrationFormFieldsName || (IntegrationFormFieldsName = {}));
export var DigitealIntegrationStatus;
(function (DigitealIntegrationStatus) {
    DigitealIntegrationStatus["ACTIVATED"] = "activated";
    DigitealIntegrationStatus["IN_PROCESS"] = "in_process";
    DigitealIntegrationStatus["ACTIVABLE"] = "activable";
})(DigitealIntegrationStatus || (DigitealIntegrationStatus = {}));
export var INTEGRATION_UPDATE_CALLBACK = function () {
    return accountActions.getCompany({
        noLoading: true,
        noFeedback: true,
    });
};
