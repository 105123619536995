import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadInvoiceFooter } from 'shared/hooks';
import { Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';
var Item = styled('div', {
    height: '55px',
    width: '100%',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',
    border: 'solid 2px #ADB5BD',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f5f5f5',
    },
    variants: {
        label: {
            true: {
                justifyContent: 'start',
                paddingLeft: '20px',
                paddingTop: '3px',
            },
        },
        empty: {
            true: {
                border: 'dashed 3px #DEE2E6',
            },
        },
    },
});
var Placeholder = function (_a) {
    var onClick = _a.onClick;
    var t = useTranslation().t;
    return (React.createElement(Item, { empty: true, onClick: onClick },
        React.createElement(Text, { fw: 500, c: "#CED4DA", mt: 0 }, t(i18nKeys.SETTINGS.INVOICING.FOOTER.CLICK_TO_EDIT))));
};
export var FooterItem = function (_a) {
    var _b;
    var item = _a.item, onClickEdit = _a.onClickEdit;
    var invoiceFooter = useLoadInvoiceFooter().invoiceFooter;
    if (invoiceFooter == null)
        return null;
    if (item == null) {
        return React.createElement(Placeholder, { onClick: onClickEdit });
    }
    if (typeof item === 'string') {
        return (React.createElement(Item, { onClick: onClickEdit },
            React.createElement(Text, { mt: 0 }, item)));
    }
    var displayName = (_b = invoiceFooter.availableFields.find(function (el) { return el.field === item.field; })) === null || _b === void 0 ? void 0 : _b.name;
    return (React.createElement(Item, { label: true, onClick: onClickEdit },
        React.createElement(Stack, { justify: "center", gap: 0 },
            React.createElement(Text, { size: "xs", c: "gray.6" }, displayName),
            React.createElement(Text, null, item.content))));
};
