import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { transactionActions } from 'store/transaction/transaction.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './UnmatchPayment.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UnmatchCreditNote(_a) {
    var reload = _a.reload, creditNote = _a.creditNote;
    var t = useTranslation().t;
    var unmatchInvoice = function (matchedPayment) {
        creditNoteActions.update({
            data: {
                id: creditNote.id,
                unlink_invoice: matchedPayment.invoice_attributes.id,
            },
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                reload();
            },
        });
    };
    var cancelSettelment = function (manualPayment) {
        transactionActions.unmatchManualPayment({
            id: manualPayment.id,
            data: { id: manualPayment.id },
            callback: function () {
                reload();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var matched_payments_attributes = creditNote.matched_payments_attributes, manual_payments = creditNote.manual_payments;
    return (React.createElement("div", { className: styles('UnmatchPayment') },
        React.createElement("div", { className: "recovr-table" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement("div", null, t(i18nKeys.MODALITY))),
                        React.createElement("th", null,
                            React.createElement("div", null, t(i18nKeys.DEBTOR.INVOICE_REFERENCE))),
                        React.createElement("th", null,
                            React.createElement("div", null, t(i18nKeys.AMOUNT))),
                        React.createElement("th", null,
                            React.createElement("div", null, t(i18nKeys.FORM.ACTIONS))))),
                React.createElement("tbody", null,
                    matched_payments_attributes.map(function (matchedPayment) { return (React.createElement("tr", { key: matchedPayment.id },
                        React.createElement("td", null,
                            t(i18nKeys.INVOICE.AMOUNT_LINKED),
                            " "),
                        React.createElement("td", null, matchedPayment.invoice_attributes.reference),
                        React.createElement("td", null,
                            React.createElement(Amount, { value: matchedPayment.amount, suffix: matchedPayment.invoice_attributes.currency })),
                        React.createElement("td", { className: "actions" },
                            React.createElement(Button, { className: styles('delete-payment'), label: t(i18nKeys.DELETE), onClick: function () { return unmatchInvoice(matchedPayment); }, small: true, noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE })))); }),
                    manual_payments.map(function (manualPayment) { return (React.createElement("tr", { key: manualPayment.id },
                        React.createElement("td", null, t(i18nKeys.DEBTOR.AMOUNT_LINKED)),
                        React.createElement("td", null, "-"),
                        React.createElement("td", null,
                            React.createElement(Amount, { value: manualPayment.amount, suffix: manualPayment.currency })),
                        React.createElement("td", { className: "actions" },
                            React.createElement(Button, { className: styles('delete-payment'), label: t(i18nKeys.DELETE), onClick: function () { return cancelSettelment(manualPayment); }, small: true, noMargin: true, color: ButtonColor.GREY, iconLeft: IconName.TRASH_SIMPLE })))); }))))));
}
