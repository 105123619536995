import { __assign, __read, __rest, __values } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Checkbox, RadioButton } from 'shared/io';
import { useGetCompany, useGetConstants } from 'shared/utils/selectors';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './ExportInvoices.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ExportInvoices() {
    var _a;
    var t = useTranslation().t;
    var constants = useGetConstants();
    var customVariables = ((_a = useGetCompany()) === null || _a === void 0 ? void 0 : _a.custom_variables_attributes) || [];
    var filteredCustomVariables = customVariables
        .filter(function (customVar) { return customVar.model_type === 'invoice'; })
        .map(function (customVar) { return ({
        description: customVar.name,
        value: customVar.column_name,
    }); });
    var debtorCustomVariables = customVariables
        .filter(function (customVar) { return customVar.model_type === 'debtor'; })
        .map(function (customVar) { return ({
        description: customVar.name,
        value: "debtor_".concat(customVar.column_name),
    }); });
    var _b = __read(useState({
        selectAll: false,
    }), 2), checkboxWrappers = _b[0], setCheckboxWrappers = _b[1];
    var _c = __read(useState({
        exportFields: constants.invoice_export_columns.debtor_fields.concat(constants.invoice_export_columns.invoice_fields, filteredCustomVariables),
        wrappers: [
            {
                name: 'selectInvoice',
                fields: constants.invoice_export_columns.invoice_fields,
                text: t(i18nKeys.COMMON.INVOICE),
            },
            {
                name: 'selectDebtor',
                fields: constants.invoice_export_columns.debtor_fields,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.CLIENT_DATA),
            },
            {
                name: 'selectCustomVariable',
                fields: filteredCustomVariables,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.CUSTOM_DATA),
            },
            {
                name: 'selectDebtorCustomVariable',
                fields: debtorCustomVariables,
                text: t(i18nKeys.INVOICING.EXPORT_CSV.DEBTOR_CUSTOM_DATA),
            },
        ],
    }), 1), _d = _c[0], exportFields = _d.exportFields, wrappers = _d.wrappers;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            export_with_filters: true,
            type: 'csv',
        },
    });
    var getValues = form.getValues, register = form.register, watch = form.watch, reset = form.reset, control = form.control;
    var resetForm = function (fields, value) {
        var e_1, _a;
        if (value !== undefined) {
            var formValues = getValues();
            var resetValues = {
                attributes: {},
            };
            try {
                for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                    var field = fields_1_1.value;
                    resetValues.attributes[field.value] = value;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            reset(__assign(__assign({}, formValues), { attributes: __assign(__assign({}, formValues.attributes), resetValues.attributes) }));
        }
    };
    var setCheckFields = function (type) { return function (value) {
        resetForm(type === 'selectAll' ? exportFields : wrappers.find(function (_a) {
            var name = _a.name;
            return name === type;
        }).fields, value);
        var wrapperValue = {};
        wrappers.forEach(function (_a) {
            var name = _a.name;
            wrapperValue[name] = type === 'selectAll' || type === name ? value : checkboxWrappers[name];
        });
        setCheckboxWrappers(__assign({ selectAll: type === 'selectAll' ? value : checkboxWrappers.selectAll }, wrapperValue));
    }; };
    var type = watch('type');
    var submit = function () {
        dialogHide(DialogShowId.CUSTOM);
        var data = getValues();
        appActions.export({
            data: __assign(__assign(__assign({}, data), (data.export_with_filters === true ? qs.parse(location.search) : {})), { view_id: location.hash.slice(1), resource_type: 'invoice' }),
            noLoading: true,
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("div", { className: styles('export-invoices') },
            React.createElement(Controller, { control: control, name: "export_with_filters", render: function (_a) {
                    var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                    return (React.createElement(RadioButton, __assign({}, values, { className: styles('export-mode-container'), items: [
                            {
                                value: false,
                                label: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_INVOICE),
                            },
                            {
                                value: true,
                                label: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_FILTERED_INVOICE),
                            },
                        ] })));
                } }),
            React.createElement(Controller, { control: control, name: "type", render: function (_a) {
                    var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                    return (React.createElement(RadioButton, __assign({}, values, { className: styles('export-mode-container'), items: [
                            { value: 'pdf', label: 'PDF' },
                            { value: 'ubl', label: 'UBL' },
                            { value: 'csv', label: 'CSV' },
                        ] })));
                } }),
            type === 'csv' && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('summary') }, t(i18nKeys.INVOICING.EXPORT_CSV.PLEASE_SELECT_FIELDS)),
                React.createElement("div", null,
                    React.createElement(Checkbox, { checked: checkboxWrappers.selectAll, onChange: setCheckFields('selectAll'), label: t(i18nKeys.FORM.SELECT_ALL), name: "selectAll" })),
                wrappers.map(function (_a) {
                    var fields = _a.fields, name = _a.name, text = _a.text;
                    return fields.length === 0 ? ('') : (React.createElement("div", { key: name },
                        React.createElement("div", { className: styles('subtitle') },
                            React.createElement(Checkbox, { checked: checkboxWrappers[name], noMargin: true, name: name, onChange: function () { return setCheckFields(name)(!checkboxWrappers[name]); } }),
                            text),
                        React.createElement("div", { className: styles('atttribute-list') }, fields.map(function (field) { return (React.createElement("div", { key: field.value, className: styles('attribute-item') },
                            React.createElement(Checkbox, { register: register("attributes[".concat(field.value, "]")), watch: watch, label: field.description }))); }))));
                }))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.DOWNLOAD), onClick: submit })))));
}
