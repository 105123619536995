import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'qs';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { Button } from 'shared/io';
import { useRefresh } from 'shared/utils/hooks';
import { reducerState } from 'shared/utils/view';
import { transactionActions } from 'store/transaction/transaction.actions';
import { showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
import styleIdentifiers from './history.scss';
import HistoryListItem from './HistoryListItem';
import PaymentsFiltersForm from './PaymentsFiltersForm';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(HistoryListItem, PaymentsFiltersForm);
export default function History() {
    var _a;
    var t = useTranslation().t;
    var paymentHistory = useSelector(function (state) { return state.transaction.paymentHistory; });
    var _b = __read(useReducer(reducerState, {
        showCheckBox: false,
        selectAll: false,
        selectedItems: [],
    }), 2), _c = _b[0], selectedItems = _c.selectedItems, selectAll = _c.selectAll, showCheckBox = _c.showCheckBox, setState = _b[1];
    var _d = useRefresh(), refresh = _d.refresh, doRefresh = _d.doRefresh;
    var TABLE_HEADERS = [
        {
            key: 'transaction_id',
            title: t(i18nKeys.BANK_ACCOUNT.HISTORY.TRANSACTION_NUMBER),
            width: 70,
        },
        {
            title: t(i18nKeys.COMMON.BANK_ACCOUNT),
            width: 140,
        },
        {
            key: 'counterparty_name',
            title: t(i18nKeys.BANK_ACCOUNT.HISTORY.SENDER),
        },
        {
            key: 'value_date',
            title: t(i18nKeys.DATE),
        },
        {
            key: 'remaining_balance',
            title: t(i18nKeys.AMOUNT),
        },
        {
            title: t(i18nKeys.COMMUNICATION),
            width: 400,
        },
        {
            title: '',
            width: 60,
        },
    ];
    var handleFilters = function (filters) {
        var _a;
        var newFilters = __assign(__assign({}, filters), { bank_account_ids: ((_a = filters.bank_account_ids) === null || _a === void 0 ? void 0 : _a.length) > 0 ? filters.bank_account_ids : undefined });
        // need to be linked
        if ((newFilters.operator && !newFilters.amount) || (!newFilters.operator && newFilters.amount))
            return false;
        return newFilters;
    };
    var handleQueryParams = function (queryParams) {
        queryParams.bank_account_ids = queryParams.bank_account_ids
            ? queryParams.bank_account_ids.split(',')
            : undefined;
        return queryParams;
    };
    var batchActions = function (action) { return function () {
        var data = {};
        if (selectAll) {
            data = qs.parse(location.search);
        }
        transactionActions[action]({
            data: __assign(__assign({}, data), { feedback: true, payment_ids: selectAll ? [] : selectedItems.map(function (payment) { return payment.id; }) }),
            callback: function (res) {
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    title: t(i18nKeys.RESULT),
                    children: React.createElement(ListResModal, { data: res.data }),
                });
                transactionActions.paymentHistoryPage(handleQueryParams(qs.parse(location.search.slice(1))));
                setState({
                    selectAll: false,
                    selectedItems: [],
                });
                doRefresh();
            },
        });
    }; };
    var onCheckBoxChange = function (selectedItems) {
        setState({ selectedItems: selectedItems });
    };
    var onSelectAll = function (value) {
        setState({
            selectAll: value,
        });
    };
    return (React.createElement("div", { className: styles('Listing') },
        React.createElement(EnhancedTable, { title: t(i18nKeys.NAV.PAYMENT_BOOK), loadFunc: transactionActions.paymentHistoryPage, loading: paymentHistory.loading, orderField: function (field) { return field; }, handleLoadData: handleQueryParams, loaded: paymentHistory.loaded, pagination: (_a = paymentHistory.metadata) === null || _a === void 0 ? void 0 : _a.pagination, headers: TABLE_HEADERS, handleFilters: handleFilters, items: paymentHistory.pages, itemProps: { type: 'journal' }, setSelectAll: onSelectAll, showShadow: true, resetSelection: refresh, onCheckBoxChange: onCheckBoxChange, actions: (selectedItems.length > 0 || selectAll) && (React.createElement("div", { className: styles('actions') },
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED_PAYMENT), onClick: batchActions('unignorePayments') }),
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.IGNORE_PAYMENTS), onClick: batchActions('ignorePayments') }))) })));
}
