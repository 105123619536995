import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AmountFields, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import styleIdentifiers from './ReconciliationInvoiceFilter.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ReconciliationInvoiceFilter(_a) {
    var onSubmit = _a.onSubmit, className = _a.className;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = useFormContext(), register = _b.register, getValues = _b.getValues, handleSubmit = _b.handleSubmit, setValue = _b.setValue;
    var _c = __read(useState(), 2), timeout = _c[0], setTimeoutDebounce = _c[1];
    var submit = function () {
        if (timeout) {
            clearTimeout(timeout);
        }
        var newTimeout = setTimeout(function () {
            var values = getValues();
            onSubmit(values);
        }, 500);
        setTimeoutDebounce(newTimeout);
    };
    return (React.createElement("form", { className: styles('reconciliation-invoice-filter', className), onSubmit: handleSubmit(submit) },
        React.createElement("div", { className: styles('fields') },
            React.createElement(DateSelector, { className: styles('input'), shadow: true, withBorder: true, noMinDate: true, label: t(i18nKeys.ISSUE_DATE), placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), endName: "issue_date_before", name: "issue_date_after", handleChange: function (date) {
                    setValue('issue_date_after', date);
                    submit();
                }, handleEndChange: function (date) {
                    setValue('issue_date_before', date);
                    submit();
                } }),
            React.createElement(Input, { onValueChanged: submit, register: register('reconciliation_detail', {}), noMargin: true, withBorder: true, shadow: true, type: "text", label: t(i18nKeys.DETAILS) }),
            React.createElement(AmountFields, { onChange: submit, className: styles('spacing'), name: "remaining_balance", label: t(i18nKeys.BALANCE_CURRENCY, {
                    text: currencySymbol(undefined, company),
                }) }))));
}
