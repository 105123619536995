import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { CustomSelect, DateSelector, SwitchInput } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName } from '../Integration.constants';
import { getHorusConnectUrl, getIntegrationRights } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var HorusFormFieldsName;
(function (HorusFormFieldsName) {
    HorusFormFieldsName["IMPORT_INTEGRATION"] = "use_horus_import_integration";
    HorusFormFieldsName["EXPORT_INTEGRATION"] = "use_horus_export_integration";
    HorusFormFieldsName["PAYMENT_MATCHING"] = "use_horus_payment_matching";
    HorusFormFieldsName["FOLDER_ID"] = "folder_id";
})(HorusFormFieldsName || (HorusFormFieldsName = {}));
export var HorusForm = function (_a) {
    var onSubmit = _a.onSubmit, onboarding = _a.onboarding, initialValues = _a.initialValues;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _c = __read(useState([]), 2), companies = _c[0], setCompanies = _c[1];
    var _d = __read(useState(false), 2), preventSubmit = _d[0], setPreventSubmit = _d[1];
    var _e = getIntegrationRights(Integration.HORUS, company), isConfigure = _e.isConfigure, isConnected = _e.isConnected;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    useEffect(function () {
        if (isConnected && !isConfigure) {
            if (onboarding) {
                onBoardingActions.horusFetchCompanies({
                    callback: function (data) { return setCompanies(data); },
                });
            }
            else {
                accountActions.horusFetchCompanies({
                    callback: function (data) { return setCompanies(data); },
                });
            }
        }
    }, [isConnected, isConfigure, onboarding]);
    var handleSubmit = form.handleSubmit, control = form.control, watch = form.watch, setValue = form.setValue;
    var submit = function (values) {
        if (preventSubmit) {
            showDialog({
                id: DialogShowId.CONFIRM,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE), onConfirm: function () {
                        setPreventSubmit(false);
                        onSubmit(values);
                    } })),
            });
        }
        else {
            onSubmit(values);
        }
    };
    var debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);
    var importIntegration = watch(HorusFormFieldsName.IMPORT_INTEGRATION);
    useEffect(function () {
        if (importIntegration) {
            setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
                : new Date());
        }
    }, [importIntegration, initialValues, setValue]);
    if (!isConnected) {
        return (React.createElement(IntegrationForm, { integration: Integration.HORUS, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.HORUS.SUMMARY, connectUrl: getHorusConnectUrl(currentLang), form: form }));
    }
    return (React.createElement(IntegrationForm, { integration: Integration.HORUS, onSubmit: handleSubmit(submit), summary: i18nKeys.FORM.HORUS.SUMMARY, form: form },
        !isConfigure && (React.createElement("div", { className: styles('select-company') },
            React.createElement("p", null, t(i18nKeys.FORM.HORUS.IRREVERSIBLE_ACTION)),
            React.createElement("p", { className: styles('warning') }, t(i18nKeys.FORM.WARNING_IRREVERSIBLE_ACTION)),
            React.createElement("div", { className: styles('grid-row') },
                React.createElement("div", { className: styles(onboarding ? 'col-12' : 'col-6') },
                    React.createElement(Controller, { defaultValue: "", name: HorusFormFieldsName.FOLDER_ID, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: HorusFormFieldsName.FOLDER_ID, selectClassName: styles('company-select'), errorClassName: styles('invoice-select-error'), items: companies, keyText: "name", keyValue: "id", label: t(i18nKeys.FORM.HORUS.SELECT_COMPANY, {
                                text: Integration.HORUS,
                            }) })); } }))))),
        React.createElement("div", { className: styles('grid-row') },
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: HorusFormFieldsName.IMPORT_INTEGRATION, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                                text: Integration.HORUS,
                            }) })));
                    } }),
                importIntegration && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(DateSelector, { name: IntegrationFormFieldsName.ISSUE_DATE_LIMIT, className: styles('issue-date'), noMinDate: true, label: t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT), placeholder: t(i18nKeys.ISSUE_DATE), endPlaceholder: t(i18nKeys.FORM.TO), required: true, handleChange: function (date) {
                                if (!onboarding) {
                                    setPreventSubmit(true);
                                }
                                setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                            } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.DEBTOR_UPDATE, render: function (_a) {
                                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                                return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button', 'issue-date'), label: t(debtorUpdate
                                        ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                                        : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR) })));
                            } }))))),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: HorusFormFieldsName.PAYMENT_MATCHING, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                                text: Integration.HORUS,
                            }) })));
                    } })),
            React.createElement("div", { className: styles('col-12') },
                React.createElement(Controller, { control: control, name: IntegrationFormFieldsName.AUTOMATIC_SYNC, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(SwitchInput, __assign({}, values, { inversed: true, className: styles('switch-button'), label: t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC) })));
                    } })))));
};
