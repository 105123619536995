import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { PageTitle } from 'shared/layout';
import { Grid } from '@mantine/core';
import { UpdatedAt } from '../components';
import { AgedBalanceCard } from '../components/cards';
import { CeiCard } from '../components/cards/CeiCard';
import { DsoCard } from '../components/cards/DsoCard';
import { TickersBanner } from '../components/TickersBanner';
export var Overview = function () {
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null,
            t(i18nKeys.ANALYTICS.TITLE),
            React.createElement(PageTitle.Actions, null,
                React.createElement(UpdatedAt, null))),
        React.createElement(TickersBanner, null),
        React.createElement(Grid, { style: { overflow: 'visible' } },
            React.createElement(Grid.Col, { span: 12 },
                React.createElement(AgedBalanceCard, null)),
            React.createElement(Grid.Col, { span: 6 },
                React.createElement(CeiCard, null)),
            React.createElement(Grid.Col, { span: 6 },
                React.createElement(DsoCard, null)))));
};
