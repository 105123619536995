import { __assign, __read, __spreadArray } from "tslib";
import { useSearchInvoices } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomTable from 'shared/components/CustomTable';
import PaymentPlanInvoiceItem from './PaymentPlanInvoiceItem';
import styleIdentifiers from './StepsCalculation.scss';
var styles = classNames.bind(styleIdentifiers);
var GroupedTable = CustomTable(PaymentPlanInvoiceItem);
export var StepsCalculationInvoices = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'invoice_ids' : _b, currentDebtor = _a.currentDebtor, _c = _a.attachedInvoices, attachedInvoices = _c === void 0 ? [] : _c;
    var t = useTranslation().t;
    var _d = useFormContext(), register = _d.register, watch = _d.watch, setValue = _d.setValue;
    var debtor_id = watch('debtor_id');
    var _e = __read(useState({
        debtor_id: debtor_id,
        currency: currentDebtor === null || currentDebtor === void 0 ? void 0 : currentDebtor.attributes.currency,
        exclude: attachedInvoices.map(function (e) { return e.id; }),
        payment_plan: true,
    }), 2), params = _e[0], setParams = _e[1];
    var invoicesResponse = useSearchInvoices(params).data;
    useEffect(function () {
        if (currentDebtor) {
            setParams(function (currentParams) { return (__assign(__assign({}, currentParams), { debtor_id: currentDebtor.id })); });
        }
    }, [currentDebtor]);
    useEffect(function () {
        if (debtor_id) {
            setParams(function (currentParams) { return (__assign(__assign({}, currentParams), { debtor_id: debtor_id })); });
        }
    }, [debtor_id]);
    register(name);
    var invoiceIds = watch(name) || [];
    var onCheck = function (value) {
        setValue(name, invoiceIds.includes(value) ? invoiceIds.filter(function (e) { return e !== value; }) : __spreadArray(__spreadArray([], __read(invoiceIds), false), [value], false), { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    };
    var invoices = (invoicesResponse === null || invoicesResponse === void 0 ? void 0 : invoicesResponse.data) || [];
    var toggleCheckAll = function (checked) {
        setValue(name, checked ? invoices.map(function (_a) {
            var id = _a.id;
            return id;
        }) : []);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(GroupedTable, { className: styles('invoice-table'), onToggleCheckAll: function (check) { return toggleCheckAll(check); }, noMargin: true, noMinHeight: true, items: invoices, itemProps: {
                onCheck: onCheck,
                invoiceIds: invoiceIds,
            }, headers: [
                { title: t(i18nKeys.FORM.REFERENCE) },
                { title: t(i18nKeys.DATE) },
                { title: t(i18nKeys.AMOUNT) },
                { title: t(i18nKeys.INVOICING.ALREADY_PAID) },
                { title: t(i18nKeys.BALANCE) },
            ] })));
};
