import { __read } from "tslib";
import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Icon, IconName } from 'shared/components/Icon';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import { Button, ButtonColor } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { reducerState } from 'shared/utils/view';
import { error } from 'store/view/view.actions';
import styleIdentifiers from './MoveRecoveryPlanInvoicesModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function MoveRecoveryPlanInvoicesModal(_a) {
    var newPlan = _a.newPlan, oldPlan = _a.oldPlan, onSubmit = _a.onSubmit, currentStep = _a.currentStep, allStepRequired = _a.allStepRequired;
    var t = useTranslation().t;
    var isInvoiceLoading = useGetInvoiceState().isLoading;
    var isClientLoading = useGetClientState().isLoading;
    var isLoading = isInvoiceLoading || isClientLoading;
    var oldPlanHasPrevReminder = useMemo(function () { return oldPlan.recovery_steps[0].step_type === 'preventive_reminder'; }, [oldPlan]);
    var newPlanHasPrevReminder = useMemo(function () { return newPlan.recovery_steps[0].step_type === 'preventive_reminder'; }, [newPlan]);
    var initArrow = function () {
        var diffIndex = newPlanHasPrevReminder !== oldPlanHasPrevReminder ? (newPlanHasPrevReminder ? 1 : -1) : 0;
        return oldPlan.recovery_steps.map(function (step, index) {
            if (typeof currentStep === 'number' && currentStep !== index) {
                return null;
            }
            var newPlanIndex = index + diffIndex;
            var arrowIndex = null;
            if (newPlanIndex >= 0) {
                if (index >= oldPlan.recovery_steps.length - 1 &&
                    oldPlan.recovery_steps[index].step_type === 'last_reminder') {
                    arrowIndex =
                        newPlan.recovery_steps[newPlan.recovery_steps.length - 1].step_type === 'last_reminder'
                            ? newPlan.recovery_steps.length - 1
                            : null;
                }
                else if (newPlanIndex >= newPlan.recovery_steps.length) {
                    arrowIndex =
                        oldPlan.recovery_steps[index].step_type === 'last_reminder' &&
                            newPlan.recovery_steps[newPlan.recovery_steps.length - 1].step_type === 'last_reminder'
                            ? newPlan.recovery_steps.length - 1
                            : null;
                }
                else {
                    arrowIndex =
                        oldPlan.recovery_steps[index].step_type ===
                            newPlan.recovery_steps[newPlanIndex].step_type
                            ? newPlanIndex
                            : null;
                }
            }
            // If a step is not created yet, user can't move his invoices in this step
            return arrowIndex !== null && newPlan.recovery_steps[arrowIndex].id ? arrowIndex : null;
        });
    };
    var _b = __read(useReducer(reducerState, {
        oldStepChoosed: currentStep !== undefined ? currentStep : null,
        arrows: initArrow(),
    }), 2), state = _b[0], setState = _b[1];
    useEffect(function () {
        setState({
            oldStepChoosed: currentStep !== undefined ? currentStep : null,
            arrows: initArrow(),
        });
    }, [newPlan, oldPlan]);
    var onClickOnStep = function (index) {
        setState({
            oldStepChoosed: currentStep !== undefined ? currentStep : index === state.oldStepChoosed ? null : index,
        });
    };
    var onClickonNewStep = function (index) {
        if (newPlan.recovery_steps[index].id &&
            state.oldStepChoosed !== null &&
            (index === 0
                ? newPlan.recovery_steps[0].step_type !== 'preventive_reminder' ||
                    (state.oldStepChoosed === 0 &&
                        oldPlan.recovery_steps[0].step_type === 'preventive_reminder')
                : true)) {
            var arrows = state.arrows.slice();
            arrows[state.oldStepChoosed] = index;
            setState({
                arrows: arrows,
            });
        }
    };
    var removeArrow = function (index) { return function () {
        var arrows = state.arrows.slice();
        arrows[index] = null;
        setState({
            arrows: arrows,
        });
    }; };
    var submit = function () {
        if (allStepRequired && state.arrows.some(function (arrow) { return arrow === null; })) {
            error({
                text: i18nKeys.PLAN.ASSOCIATE_ALL_STEPS,
            });
        }
        else {
            var payload_1 = {};
            state.arrows.forEach(function (to, from) {
                if (state.arrows[from] !== null) {
                    var toId = newPlan.recovery_steps[to].id;
                    var fromId = oldPlan.recovery_steps[from].id;
                    if (payload_1[toId]) {
                        payload_1[toId].push(fromId);
                    }
                    else {
                        payload_1[toId] = [fromId];
                    }
                }
            });
            var steps_switch = [];
            for (var to in payload_1) {
                steps_switch.push({
                    to: to,
                    from: payload_1[to],
                });
            }
            onSubmit(steps_switch);
        }
    };
    return (React.createElement("div", { className: styles('move-recovery-plan-invoices') },
        React.createElement("div", { className: styles('header') },
            React.createElement("div", null, t(i18nKeys.PLAN.TRANSFERT_INVOICES)),
            React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.FORM.VALIDATE), color: ButtonColor.MAIN, noMargin: true, onClick: submit })),
        React.createElement("div", { className: styles('plans-wrapper') },
            React.createElement("div", { className: styles('plan') },
                React.createElement("div", { className: styles('plan-name') }, oldPlan.name),
                React.createElement(RecoveryPlan, { recoverPlan: oldPlan, onClickOnStep: currentStep > -1 ? undefined : onClickOnStep, stepHighlight: currentStep !== null && currentStep !== void 0 ? currentStep : state.oldStepChoosed, stepClassName: styles('old-step') })),
            React.createElement("div", { className: styles('space') }, state.arrows.map(function (newStep, index) {
                if (typeof newStep !== 'number') {
                    return null;
                }
                var stepGap = index - newStep;
                var endArrow = 25 +
                    (newStep === 0 && newPlanHasPrevReminder ? 65 : 160) +
                    140 * newStep +
                    (stepGap === 0 ? 0 : (stepGap / Math.abs(stepGap)) * 20);
                return (React.createElement(Fragment, { key: index },
                    !allStepRequired && currentStep === undefined && (React.createElement("div", { className: styles('remove-button'), onClick: removeArrow(index), style: {
                            top: (oldPlanHasPrevReminder && index === 0 ? 90 : 185) + 140 * index,
                        } },
                        React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                    React.createElement("svg", null,
                        React.createElement("defs", null,
                            React.createElement("marker", { id: "arrow", markerWidth: "10", markerHeight: "10", refX: "2", refY: "6", orient: "auto" },
                                React.createElement("path", { d: "M0,3 L0,9 L4,6 L0,3" }))),
                        React.createElement("path", { d: "M0,".concat((oldPlanHasPrevReminder && index === 0 ? 90 : 185) + 140 * index, " L80,").concat(endArrow), style: {
                                strokeWidth: '3px',
                                fill: 'none',
                                markerEnd: 'url(#arrow)',
                            } }))));
            })),
            React.createElement("div", { className: styles('plan') },
                React.createElement("div", { className: styles('plan-name') }, newPlan.name),
                React.createElement(RecoveryPlan, { recoverPlan: newPlan, messageInfo: t(i18nKeys.PLAN.STEP_MUST_BE_CREATED), stepClassName: state.oldStepChoosed !== null && styles('active-step'), onClickOnStep: onClickonNewStep, canClickPreventiveReminder: state.oldStepChoosed === 0 &&
                        oldPlan.recovery_steps[0].step_type === 'preventive_reminder' })))));
}
