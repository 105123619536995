import { __awaiter, __generator } from "tslib";
import axios from 'axios';
import apiService from 'shared/service/api.service';
import { config } from 'store/constants';
import urlJoin from 'url-join';
import { useAuth0 } from '@auth0/auth0-react';
var legacyApiUrl = urlJoin(apiService.baseUrl, 'private_api');
var apiV2Url = urlJoin(apiService.baseUrl, 'private_api/v2');
export function usePublicAxiosInstance() {
    return axios.create({
        baseURL: apiV2Url,
        headers: {
            tenant: apiService.tenant,
        },
    });
}
export function useAxiosInstance() {
    return __awaiter(this, void 0, void 0, function () {
        var getAccessTokenSilently, token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getAccessTokenSilently = useAuth0().getAccessTokenSilently;
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: config.auth0.audience,
                                scope: 'openid profile email offline_access',
                            },
                        })];
                case 1:
                    token = _a.sent();
                    return [2 /*return*/, axios.create({
                            baseURL: apiV2Url,
                            headers: {
                                tenant: apiService.tenant,
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
            }
        });
    });
}
export function useLegacyAxiosInstance() {
    return __awaiter(this, void 0, void 0, function () {
        var getAccessTokenSilently, token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getAccessTokenSilently = useAuth0().getAccessTokenSilently;
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: config.auth0.audience,
                                scope: 'openid profile email offline_access',
                            },
                        })];
                case 1:
                    token = _a.sent();
                    return [2 /*return*/, axios.create({
                            baseURL: legacyApiUrl,
                            headers: {
                                tenant: apiService.tenant,
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
            }
        });
    });
}
