import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import styleIdentifiers from './ExternalMailDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailReplyHead = function (_a) {
    var externalMail = _a.externalMail, originalSubject = _a.originalSubject, subject = _a.subject, setSubject = _a.setSubject;
    var t = useTranslation().t;
    var from_mail = externalMail.attributes.from_mail;
    return (React.createElement("div", { className: styles('detail-head') },
        React.createElement("div", { className: styles('grid') },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.SUBJECT)),
            React.createElement("input", { type: "text", className: styles('subject-input'), value: subject, placeholder: "RE: ".concat(originalSubject), onChange: function (e) { return setSubject(e.target.value); } })),
        React.createElement("div", { className: styles('grid') },
            React.createElement("p", { className: styles('text') }, t(i18nKeys.FORM.TO)),
            React.createElement("a", { className: styles('value'), href: "mailto:".concat(from_mail) }, from_mail))));
};
