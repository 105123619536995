import { removeAttributes } from 'shared/utils/view';
export var treatTpCase = function (item) {
    var thirdpartyCase = removeAttributes(item);
    if (thirdpartyCase.invoice_feedback) {
        thirdpartyCase.invoice_feedback = removeAttributes(thirdpartyCase.invoice_feedback);
        if (thirdpartyCase.invoice_feedback.conversation) {
            thirdpartyCase.invoice_feedback.conversation = removeAttributes(thirdpartyCase.invoice_feedback.conversation);
            thirdpartyCase.invoice_feedback.conversation.messages =
                thirdpartyCase.invoice_feedback.conversation.messages.map(function (message) {
                    return removeAttributes(message);
                });
        }
    }
    if (thirdpartyCase.attached_invoices) {
        thirdpartyCase.attached_invoices.details = thirdpartyCase.attached_invoices.details.map(function (invoice) { return removeAttributes(invoice); });
    }
    return thirdpartyCase;
};
