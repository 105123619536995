import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Box, Button, Stack, Text, Timeline, Title } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
export var WarningsOverview = function (_a) {
    var warnings = _a.warnings, warningsURL = _a.warningsURL;
    var t = useTranslation().t;
    return (React.createElement(Stack, { align: "center" },
        React.createElement(Box, { w: "100%", mb: "sm" },
            React.createElement(Title, { order: 2, mb: 0 }, t(i18nKeys.DEBTOR.MONITORING.WARNINGS))),
        React.createElement(Stack, { gap: "lg" },
            warnings && (React.createElement(Timeline, { bulletSize: 25, active: -1 }, warnings.map(function (_a, key) {
                var year = _a.year, descriptions = _a.descriptions;
                return (React.createElement(Timeline.Item, { title: year !== null && year !== void 0 ? year : t(i18nKeys.DEBTOR.MONITORING.ONGOING), key: "".concat(year, "-").concat(key) }, descriptions.map(function (description, recordKey) { return (React.createElement(Text, { key: "".concat(year, "-").concat(recordKey), size: "sm" }, "".concat(description))); })));
            }))),
            warningsURL && (React.createElement(Button, { variant: "transparent", component: "a", href: warningsURL, target: "_blank", rightSection: React.createElement(IconExternalLink, { size: 16, stroke: 1.5 }) }, t(i18nKeys.DEBTOR.MONITORING.WARNING_DETAILS))))));
};
