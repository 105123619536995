import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { DevTools } from 'jotai-devtools';
import { useTranslation } from 'locales';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { isPublicEnvironment } from 'shared/utils/environment';
import { config } from 'store/constants';
import { Auth0Provider as _Auth0Provider } from '@auth0/auth0-react';
import { Button, createTheme, DEFAULT_THEME, MantineProvider, mergeMantineTheme, Modal, Table, } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { styled } from '@stitches/react';
import { QueryClient as QueryClientV4, QueryClientProvider as QueryClientProviderV4, } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '../store/setupClientStore';
var queryClient = new QueryClient();
var queryClientV4 = new QueryClientV4({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});
export var MANTINE_THEME_OVERRIDES = createTheme({
    cursorType: 'pointer',
    fontFamily: 'Work Sans, sans-serif',
    headings: {
        fontFamily: 'Roboto, sans-serif',
    },
    colors: {
        // For the record because my name is attached to it now: I considered looking for another job over this.
        green: [
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
        ],
    },
    components: {
        // Without this directive, buttons behave as if they received the fullSize prop, I do not know why.
        Button: Button.extend({
            styles: {
                root: { width: 'fit-content', fontWeight: 500 },
                // Force the default mantine font-family on buttons
                label: {
                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
                },
            },
        }),
        Table: Table.extend({
            styles: {
                td: {
                    verticalAlign: 'middle', // Our reset sets this to baseline, which causes misalignment in tables
                },
            },
        }),
        Modal: Modal.extend({
            styles: {
                title: {
                    fontWeight: 600,
                    fontSize: '1.2rem',
                },
                header: {
                    borderBottom: '1px solid lightgrey',
                    paddingBottom: '12px',
                    paddingTop: '12px',
                },
                body: {
                    paddingTop: 'var(--mantine-spacing-md)',
                },
            },
        }),
    },
});
export var MANTINE_THEME = mergeMantineTheme(DEFAULT_THEME, MANTINE_THEME_OVERRIDES);
var DevtoolStyleFixes = styled('span', {
    '.jotai-devtools-trigger-button': {
        bottom: '60px',
        left: '0px',
        transform: 'scale(0.65)',
    },
});
var DevToolsWrapper = function () {
    if (isPublicEnvironment())
        return null;
    return (React.createElement(DevtoolStyleFixes, null,
        React.createElement(ReactQueryDevtools, { initialIsOpen: false }),
        React.createElement(DevTools, null)));
};
export var Providers = function (_a) {
    var store = _a.store, history = _a.history, children = _a.children;
    return (React.createElement(Auth0Provider, null,
        React.createElement(ReduxProviders, { store: store, history: history },
            React.createElement(ReactQueryProviders, null,
                React.createElement(MantineProviders, null,
                    children,
                    React.createElement(DevToolsWrapper, null),
                    React.createElement(Notifications, { position: "bottom-left" }))))));
};
var Auth0Provider = function (_a) {
    var children = _a.children;
    return (
    // eslint-disable-next-line react/jsx-pascal-case
    React.createElement(_Auth0Provider, { domain: config.auth0.domain, clientId: config.auth0.clientId, cacheLocation: "localstorage", useRefreshTokens: true, authorizationParams: {
            audience: config.auth0.audience,
            redirect_uri: "".concat(window.location.origin, "/dashboard"),
            scope: 'openid profile email offline_access',
        } }, children));
};
var ReduxProviders = function (_a) {
    var store = _a.store, history = _a.history, children = _a.children;
    return (React.createElement(Provider, { store: store },
        React.createElement(ConnectedRouter, { history: history }, children)));
};
var MantineProviders = function (_a) {
    var children = _a.children;
    var currentLang = useTranslation().currentLang;
    return (React.createElement(MantineProvider, { theme: MANTINE_THEME_OVERRIDES },
        React.createElement(DatesProvider, { settings: { locale: currentLang } },
            React.createElement(MantineModalsProvider, null, children))));
};
var ReactQueryProviders = function (_a) {
    var children = _a.children;
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(QueryClientProviderV4, { client: queryClientV4 }, children)));
};
