import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import { formattedDate, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { Center, Pagination } from '@mantine/core';
import InvoiceDetailDebtor from '../InvoiceDetailDebtor';
import styleIdentifiers from './DebtorReactionInvoicesList.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorReactionInvoicesList(_a) {
    var _b;
    var items = _a.items, isInvoicesTabActive = _a.isInvoicesTabActive, setData = _a.setData, onCheckboxChange = _a.onCheckboxChange, data = _a.data, queryStrings = _a.queryStrings, paymentLink = _a.paymentLink, _c = _a.credit, credit = _c === void 0 ? false : _c, metadata = _a.metadata, billingStatement = _a.billingStatement, canUseOnlinePayment = _a.canUseOnlinePayment, canUsePaymentPlan = _a.canUsePaymentPlan, canRequestPaymentPlan = _a.canRequestPaymentPlan, isFrenchClient = _a.isFrenchClient, canUseDigiteal = _a.canUseDigiteal, _d = _a.hasPaymentOptions, hasPaymentOptions = _d === void 0 ? false : _d;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    var params = useRouteMatch().params;
    var _f = useFormContext(), register = _f.register, watch = _f.watch;
    var openDownloadDocument = function (invoice) { return function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(InvoiceDetailDebtor, { credit: credit, payInvoice: invoice.remaining_balance_with_fees > 0
                    ? function () {
                        sideMenuHide();
                        paymentLink([invoice]);
                    }
                    : undefined, invoice: invoice, isInvoicesTabActive: isInvoicesTabActive, canUseOnlinePayment: canUseOnlinePayment, isFrenchClient: isFrenchClient, canUseDigiteal: canUseDigiteal })),
        });
    }; };
    var setPage = function (_a) {
        var selected = _a.selected;
        invoiceActions.debtorReactionInvoices({
            id: params.id,
            data: {
                page: selected + 1,
                token: queryStrings.document_token,
            },
            callback: function (paidInvoices) {
                paidInvoices.data = paidInvoices.data.map(function (invoices) { return removeAttributes(invoices); });
                setData(__assign(__assign({}, data), { paidInvoices: paidInvoices }));
            },
        });
    };
    var getPrice = function (invoice, localized) {
        if (localized === void 0) { localized = false; }
        var baseObject = localized ? invoice.localized_money_object : invoice;
        if (credit) {
            return baseObject.total_tvac;
        }
        if (isInvoicesTabActive) {
            return baseObject.total_tvac;
        }
        return baseObject.remaining_balance_with_fees;
    };
    return (React.createElement("div", { className: styles('debtor-reaction-invoices-list') },
        React.createElement("div", { className: styles('invoice-list') },
            billingStatement && React.createElement("div", { className: styles('subtitle') }, t(i18nKeys.INVOICES)),
            (items === null || items === void 0 ? void 0 : items.length) > 0 ? (items.map(function (invoice) { return (React.createElement("div", { key: invoice.id, className: styles('invoice-item', invoice.is_late && 'late') },
                hasPaymentOptions &&
                    !credit &&
                    !isInvoicesTabActive &&
                    (canUseOnlinePayment || (canUsePaymentPlan && canRequestPaymentPlan)) && (React.createElement("div", { className: styles('checkbox') },
                    React.createElement(Checkbox, { watch: watch, noMargin: true, register: register("".concat(invoice.id)), onChange: onCheckboxChange(invoice) }))),
                React.createElement("div", { className: styles('invoice-info'), onClick: openDownloadDocument(invoice) },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('reference') },
                            canUsePaymentPlan && canRequestPaymentPlan && (React.createElement(Icon, { title: t(i18nKeys.TOOLTIP["CAN_".concat(invoice.eligible_for_payment_plan ? '' : 'NOT_', "BE_INCLUDED_IN_PAYMENT_PLAN")]), name: IconName[invoice.eligible_for_payment_plan ? 'CHECK' : 'SIMPLE_REMOVE'], color: IconColor[invoice.eligible_for_payment_plan ? 'GREEN' : 'RED'] })),
                            ' ',
                            React.createElement("p", null, invoice.reference)),
                        React.createElement("div", { className: styles('amount') },
                            React.createElement(Amount, { localizedValue: getPrice(invoice), value: getPrice(invoice, true), suffix: invoice.currency, debtor: data.debtor_data }))),
                    React.createElement("div", { className: styles('date') },
                        React.createElement("div", null,
                            React.createElement("span", { className: styles('invoice-subtitle') },
                                t(i18nKeys.INVOICE.ISSUE_ON),
                                ' '),
                            ' ',
                            formattedDate(invoice.issue_date, currentLang)),
                        !credit && isInvoicesTabActive ? (+invoice.remaining_balance_with_fees === 0 && (React.createElement("div", { className: styles('green') }, t(i18nKeys.INVOICE.PAID)))) : (React.createElement("div", null,
                            React.createElement("span", { className: styles('invoice-subtitle') },
                                t(i18nKeys.INVOICE.DUE_ON),
                                ' '),
                            formattedDate(invoice.due_date, currentLang))))),
                React.createElement("div", { className: styles('actions-container'), onClick: openDownloadDocument(invoice) }, invoice.has_pdf && React.createElement(Icon, { name: IconName.DOWNLOAD })))); })) : (React.createElement("div", { className: styles('no-invoice') }, t(credit
                ? i18nKeys.NO_RESULT
                : isInvoicesTabActive
                    ? i18nKeys.DEBTOR.REACTION.NO_PAID_INVOICE
                    : i18nKeys.DEBTOR.REACTION.NO_INVOICE_TO_PAY))),
            data && billingStatement && (React.createElement(React.Fragment, null,
                data.credit_notes.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('subtitle') }, t(i18nKeys.NAV.CREDIT_NOTES)),
                    data.credit_notes.map(function (cn) { return (React.createElement("div", { key: cn.id, className: styles('invoice-item') },
                        hasPaymentOptions && canUseOnlinePayment && (React.createElement("div", { className: styles('checkbox') },
                            React.createElement(Checkbox, { register: register(cn.id), watch: watch, noMargin: true, onChange: onCheckboxChange(cn) }))),
                        React.createElement("div", { className: styles('invoice-info'), onClick: openDownloadDocument(cn) },
                            React.createElement("div", null,
                                React.createElement("div", { className: styles('reference') }, cn.reference),
                                React.createElement("div", { className: styles('amount') },
                                    React.createElement(Amount, { localizedValue: cn.remaining_balance, value: cn.localized_money_object.remaining_balance, suffix: cn.currency, debtor: data.debtor_data }))),
                            React.createElement("div", { className: styles('date') },
                                React.createElement("div", null,
                                    React.createElement("span", { className: styles('invoice-subtitle') },
                                        t(i18nKeys.INVOICE.ISSUE_ON),
                                        ' '),
                                    ' ',
                                    formattedDate(cn.issue_date, currentLang)))))); }))),
                data.payments_sum !== 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('subtitle') }, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)),
                    React.createElement("div", { className: styles('invoice-item', 'payment') },
                        hasPaymentOptions && (React.createElement("div", { className: styles('checkbox') },
                            React.createElement(Checkbox, { register: register('payment'), watch: watch, noMargin: true, onChange: onCheckboxChange({
                                    remaining_balance_with_fees: data.payments_sum,
                                    id: 'payment',
                                }) }))),
                        React.createElement("div", { className: styles('invoice-info') },
                            React.createElement("div", null,
                                React.createElement("div", { className: styles('reference') }, t(i18nKeys.RECEIVED_PAYMENT_S)),
                                React.createElement("div", { className: styles('amount') },
                                    React.createElement(Amount, { value: data.payments_sum, suffix: data.debtor_data.currency, debtor: data.debtor_data })))))))))),
        isDefined((_b = metadata === null || metadata === void 0 ? void 0 : metadata.pagination) === null || _b === void 0 ? void 0 : _b.last_page) && (React.createElement(Center, null,
            React.createElement(Pagination, { total: metadata.pagination.last_page, onChange: function (pageIndex) { return setPage({ selected: pageIndex - 1 }); }, value: metadata === null || metadata === void 0 ? void 0 : metadata.pagination.current_page, size: "sm" })))));
}
