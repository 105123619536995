import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Button } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import DebtorBatchActions from './DebtorBatchActions';
export var ShowCheckBox = function (_a) {
    var listUserChecked = _a.listUserChecked, selectAll = _a.selectAll;
    var t = useTranslation().t;
    var openBatchActionsModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.FORM.ACTIONS),
            children: React.createElement(DebtorBatchActions, { debtorsSelected: listUserChecked, isSelectedAll: selectAll }),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { noMargin: true, label: t(i18nKeys.FORM.ACTIONS), onClick: openBatchActionsModal })));
};
