import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var PausedInvoicesSchema = z
    .object({
    totalInvoicesPaused: z.preprocess(function (val) { return Number(val); }, z.number()),
})
    .transform(function (_a) {
    var totalInvoicesPaused = _a.totalInvoicesPaused;
    return totalInvoicesPaused;
});
export function loadPausedInvoicesFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('analytics/paused_invoices')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, PausedInvoicesSchema.parse(data)];
            }
        });
    });
}
export function useLoadPausedInvoices() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['paused-invoices'],
        queryFn: function () { return loadPausedInvoicesFn(axiosInstance); },
        onError: function (error) {
            captureException(error);
        },
    });
    return addResourceNameToQueryResult('pausedInvoices', queryResult);
}
