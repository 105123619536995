import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export var CompanyWebCredentialsSchema = z.union([
    z.object({
        login: z.string(),
        password: z.string(),
    }),
    z.object({
        login: z.null(),
        password: z.null(),
    }),
]);
export function saveCompanyWebCredentialsFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put('integrations/company-web', CompanyWebCredentialsSchema.parse(data))];
            }
        });
    });
}
export function useSaveCompanyWebCredentials() {
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return saveCompanyWebCredentialsFn(axiosInstance, data); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
        },
    });
    return addActionNameToMutationResult('saveCompanyWebCredentials', mutation);
}
