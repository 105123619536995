import { __read } from "tslib";
import React, { useEffect } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ActionIcon, Divider, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconExternalLink, IconMinus, IconPlus } from '@tabler/icons-react';
import { InvoiceLabel } from './Labels';
export var InvoiceRow = function (_a) {
    var invoice = _a.invoice, isForceUnfoldActive = _a.isForceUnfoldActive;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = __read(useDisclosure(false), 2), isUnfolded = _c[0], fold = _c[1];
    useEffect(function () {
        if (isForceUnfoldActive)
            fold.open();
        else
            fold.close();
    }, [isForceUnfoldActive]);
    var id = invoice.id, reference = invoice.reference, status = invoice.status, issueDate = invoice.issueDate, dueDate = invoice.dueDate, totalTvac = invoice.totalTvac, remainingBalance = invoice.remainingBalance, remainingLateFees = invoice.remainingLateFees, creditNotes = invoice.creditNotes, matchedPayments = invoice.matchedPayments;
    var Icon = isUnfolded ? IconMinus : IconPlus;
    var openInvoice = function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: id, modal: true }),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement(InvoiceLabel, null)),
            React.createElement("td", { onClick: fold.toggle, style: { cursor: 'pointer' } },
                React.createElement(Group, { gap: 0 },
                    React.createElement(ActionIcon, { variant: "transparent", size: 20 },
                        React.createElement(Icon, { color: "black", stroke: 1.5 })),
                    React.createElement(Text, { ml: "xs", fz: "0.9rem", ta: "left", flex: 1 }, reference),
                    React.createElement(ActionIcon, { variant: "transparent", size: 20, onClick: function (e) {
                            e.stopPropagation();
                            openInvoice();
                        }, color: "blue" },
                        React.createElement(IconExternalLink, { stroke: 1.5 })))),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem" }, status || '-')),
            React.createElement("td", { style: { minWidth: '100px' } },
                React.createElement(Text, { fz: "0.9rem" },
                    " ",
                    issueDate ? issueDate.format('DD/MM/YY') : '-')),
            React.createElement("td", { style: { minWidth: '100px' } },
                React.createElement(Text, { fz: "0.9rem" }, dueDate ? formattedDate(dueDate, currentLang, 'DD/MM/YY') : '-')),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem", c: "blue" },
                    React.createElement(Amount, { value: totalTvac + remainingLateFees }))),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem", c: "red" }, totalTvac - remainingBalance > 0 && React.createElement(Amount, { value: totalTvac - remainingBalance }))),
            React.createElement("td", null,
                React.createElement(Text, { fz: "0.9rem" },
                    React.createElement(Amount, { value: remainingBalance + remainingLateFees })))),
        isUnfolded && (React.createElement(React.Fragment, null,
            React.createElement("tr", { style: { border: 'none' } },
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Text, { ml: 20, fz: "0.9rem" }, t(i18nKeys.AMOUNT_BILLED))),
                React.createElement("td", { colSpan: 3 }),
                React.createElement("td", null,
                    React.createElement(Text, { fz: "0.9rem", c: "blue" },
                        React.createElement(Amount, { value: totalTvac })))),
            remainingLateFees !== 0 && (React.createElement("tr", { style: { border: 'none' } },
                React.createElement("td", null),
                React.createElement("td", null,
                    React.createElement(Text, { ml: 20, fz: "0.9rem" }, t(i18nKeys.LATE_FEES))),
                React.createElement("td", { colSpan: 3 }),
                React.createElement("td", null,
                    React.createElement(Text, { fz: "0.9rem", c: "blue" },
                        React.createElement(Amount, { value: remainingLateFees }))))),
            creditNotes.map(function (creditNote) {
                var _a, _b;
                return (React.createElement("tr", { key: creditNote.reference, style: { border: 'none' } },
                    React.createElement("td", null),
                    React.createElement("td", null,
                        React.createElement(Text, { ml: 20, fz: "0.9rem" },
                            t(i18nKeys.CREDIT_NOTE_LINKED),
                            " - ",
                            creditNote.reference)),
                    React.createElement("td", { colSpan: 1 }),
                    React.createElement("td", null,
                        React.createElement(Text, { fz: "0.9rem" }, (_b = (_a = creditNote.issueDate) === null || _a === void 0 ? void 0 : _a.format('DD/MM/YY')) !== null && _b !== void 0 ? _b : t(i18nKeys.MISSING_DATA))),
                    React.createElement("td", { colSpan: 2 }),
                    React.createElement("td", null,
                        React.createElement(Text, { fz: "0.9rem", c: "red" },
                            React.createElement(Amount, { value: creditNote.amount })))));
            }),
            matchedPayments.map(function (payment) {
                var _a, _b;
                return (React.createElement("tr", { key: payment.amount, style: { border: 'none' } },
                    React.createElement("td", null),
                    React.createElement("td", null,
                        React.createElement(Text, { ml: 20, fz: "0.9rem" },
                            t(i18nKeys.LINKED_PAYMENT),
                            " - ",
                            payment.communication)),
                    React.createElement("td", { colSpan: 1 }),
                    React.createElement("td", null,
                        React.createElement(Text, { fz: "0.9rem" }, (_b = (_a = payment.issueDate) === null || _a === void 0 ? void 0 : _a.format('DD/MM/YY')) !== null && _b !== void 0 ? _b : t(i18nKeys.MISSING_DATA))),
                    React.createElement("td", { colSpan: 2 }),
                    React.createElement("td", null,
                        React.createElement(Text, { fz: "0.9rem", c: "red" },
                            React.createElement(Amount, { value: payment.amount })))));
            }))),
        React.createElement("tr", null,
            React.createElement("td", { colSpan: 8, style: { padding: '0' } },
                React.createElement(Divider, { p: 0 })))));
};
