import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Icon } from 'shared/components/Icon';
import { SendingMediaIcon } from 'shared/components/SendingMedium';
import { isTrue } from '../WorkflowForm.utils';
import styleIdentifiers from './WorkflowFormSteps.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsItemDetailReminder = function (_a) {
    var step_type = _a.step_type, template = _a.template, sending_media = _a.sending_media;
    var t = useTranslation().t;
    var name = template.attributes.name;
    // TODO: For RecoveryPlan
    // const fee_step_reached = undefined;
    return (React.createElement("div", { className: styles('description') },
        React.createElement("div", null,
            React.createElement("h3", null, name),
            React.createElement("p", null, t(i18nKeys.TEMPLATE_TYPES[step_type.toUpperCase()]))),
        sending_media && (React.createElement("div", { className: styles('sending_media') }, sending_media.map(function (_a, i) {
            var mediaName = _a.name, force_sending = _a.force_sending;
            return i === 0 ? (React.createElement("span", { className: styles('button'), key: mediaName },
                React.createElement(Icon, { name: SendingMediaIcon[mediaName] }))) : (React.createElement(React.Fragment, { key: mediaName },
                React.createElement("span", null, t(isTrue(force_sending) ? i18nKeys.AND : i18nKeys.OR)),
                React.createElement("span", { className: styles('button') },
                    React.createElement(Icon, { name: SendingMediaIcon[mediaName] }))));
        })))));
};
