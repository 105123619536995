export var quickSearchKey = function (tableName) {
    switch (tableName) {
        case 'debtors':
            return 'DEBTOR';
        case 'tasks':
            return 'TASK';
        default:
            return 'INVOICE';
    }
};
