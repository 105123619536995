import React from 'react';
import classNames from 'classnames/bind';
import { useLoadInvoiceFooter } from 'shared/hooks';
import styleIdentifiers from './invoiceFooter.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceFooter() {
    var invoiceFooter = useLoadInvoiceFooter().invoiceFooter;
    if (invoiceFooter == null)
        return null;
    return (React.createElement("footer", { className: styles('InvoiceFooter', 'invoice') }, invoiceFooter.fields.map(function (field, index) { return (React.createElement("div", { key: index }, (function () {
        if (field == null)
            return '';
        if (typeof field === 'string')
            return field;
        if (field.content != null)
            return field.content;
        return '';
    })())); })));
}
