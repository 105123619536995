import { __assign } from "tslib";
export var getDefaultEmails = function (emails, notification) {
    var notificationEmails = emails.filter(function (_a) {
        var notifications = _a.notifications;
        return notifications.includes(notification);
    });
    if (notificationEmails.length > 0)
        return notificationEmails;
    var mainEmails = emails.filter(function (_a) {
        var main_contact = _a.main_contact;
        return main_contact;
    });
    if (mainEmails.length > 0)
        return mainEmails;
    return emails;
};
export var emailValues = function (emails) {
    return emails.map(function (_a) {
        var value = _a.value;
        return value;
    });
};
export var getDefaultAddresses = function (addresses, notification) {
    var filteredAddresses = addresses.filter(function (_a) {
        var use_postal_address = _a.attributes.use_postal_address;
        return use_postal_address;
    });
    var notificationAddresses = filteredAddresses.filter(function (_a) {
        var notifications = _a.attributes.notifications;
        return notifications.includes(notification);
    });
    if (notificationAddresses.length > 0)
        return notificationAddresses;
    var mainAddresses = filteredAddresses.filter(function (_a) {
        var main_contact = _a.attributes.main_contact;
        return main_contact;
    });
    if (mainAddresses.length > 0)
        return mainAddresses;
    return filteredAddresses;
};
export var addressesToAttributes = function (addresses) {
    return addresses === null || addresses === void 0 ? void 0 : addresses.map(function (_a) {
        var id = _a.id, type = _a.type, attributes = _a.attributes;
        return (__assign({ id: id, type: type }, attributes));
    });
};
export var transformEmailsAndSubmit = function (emails, values, onSubmit) {
    var _a;
    var parsedEmails = (_a = values.emails) === null || _a === void 0 ? void 0 : _a.map(function (rawEmail) {
        var maybeExistingEmail = emails.find(function (e) { return e.value === rawEmail; });
        if (maybeExistingEmail)
            return maybeExistingEmail;
        return { value: rawEmail };
    });
    onSubmit(__assign(__assign({}, values), { emails: parsedEmails }));
};
