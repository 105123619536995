import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import { CalculationType, InterestCalculationType } from 'shared/forms/PreferencesForm';
import { getCgv } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import styleIdentifiers from './TabInfoPayment.scss';
var styles = classNames.bind(styleIdentifiers);
export var TabInfoPayment = function (_a) {
    var debtor = _a.debtor, handleSettings = _a.handleSettings;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var average_payment_delay = debtor.average_payment_delay, delay_rate = debtor.delay_rate;
    var currentCGV = getCgv(company, debtor);
    if (!currentCGV) {
        return (React.createElement("div", { className: styles('PaymentDelay', 'card') },
            React.createElement("h3", null,
                t(i18nKeys.SALE_CONDITION),
                React.createElement(Icon, { name: IconName.PENCIL, className: styles('icon'), onClick: handleSettings })),
            React.createElement("div", { className: styles('row') },
                React.createElement("div", { className: styles('col') },
                    React.createElement("div", { className: styles('info-condition') }, t(i18nKeys.CLIENT.DETAIL.NOT_HAVE_CONDITIONS_FOR_CLIENT))))));
    }
    var id = currentCGV.id, isDefault = currentCGV.default, penalty_clause = currentCGV.penalty_clause, interest = currentCGV.interest, minimum_payment_terms_delay = currentCGV.minimum_payment_terms_delay, has_pdf = currentCGV.has_pdf, fix_fee = currentCGV.fix_fee, name = currentCGV.name;
    return (React.createElement("div", { className: styles('payment-delay', 'card') },
        React.createElement("h3", null,
            t(i18nKeys.SALE_CONDITION),
            React.createElement(Icon, { name: IconName.PENCIL, className: styles('icon'), onClick: handleSettings })),
        React.createElement("div", { className: styles('row') },
            React.createElement("div", { className: styles('col') },
                React.createElement("div", { className: styles('info-condition') },
                    React.createElement(HTML, { html: isDefault
                            ? t(i18nKeys.CLIENT.DETAIL.NOT_HAVE_CONDITIONS_FOR_CLIENT)
                            : t(i18nKeys.CLIENT.DETAIL.HAVE_CONDITIONS_FOR_CLIENT, {
                                text: name,
                            }) })),
                React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') },
                        React.createElement(HTML, { html: t(i18nKeys.CLIENT.DETAIL.AVERAGE_DELAY_AUTHORIZED) })),
                    React.createElement("span", { className: styles('blue') },
                        average_payment_delay || '-',
                        " / ",
                        minimum_payment_terms_delay || '-',
                        ' ',
                        t(i18nKeys.DAY))),
                React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') },
                        React.createElement(HTML, { html: t(i18nKeys.CLIENT.DETAIL.LATE_PAYMENT_RATE) })),
                    React.createElement("span", { className: styles('blue') },
                        React.createElement(Percentage, { value: delay_rate || 0 }))),
                has_pdf && (React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.SALE_CONDITION)),
                    React.createElement("div", { onClick: function () { return accountActions.getTerms({ id: id }); }, className: styles('download') },
                        React.createElement(Icon, { name: IconName.CLOUD_DOWNLOAD, className: styles('icon') }),
                        React.createElement("span", { className: styles('txt', 'value') }, t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)))))),
            React.createElement("div", { className: styles('col') },
                !!(penalty_clause === null || penalty_clause === void 0 ? void 0 : penalty_clause.value) && (React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_VALUE, {
                        text: penalty_clause.type === InterestCalculationType.PERCENTAGE ? '%' : '€',
                    })),
                    React.createElement("span", { className: styles('value') }, penalty_clause.type === InterestCalculationType.PERCENTAGE ? (React.createElement(React.Fragment, null,
                        React.createElement(Percentage, { value: penalty_clause.value }),
                        React.createElement("span", { className: styles('spacer') }, ' - '),
                        React.createElement(Amount, { value: penalty_clause.minimum }))) : (React.createElement(Amount, { value: penalty_clause.value }))))),
                !!(interest === null || interest === void 0 ? void 0 : interest.value) && (React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PREFERENCES.INTEREST)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement("span", null, t(i18nKeys.CALCULATION_TYPE[Object.keys(CalculationType).find(function (key) { return interest.period === CalculationType[key]; })])),
                        React.createElement("span", { className: styles('spacer') }, ' - '),
                        React.createElement(Percentage, { value: interest.value })))),
                !!(fix_fee === null || fix_fee === void 0 ? void 0 : fix_fee.value) && (React.createElement("div", { className: styles('group') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PREFERENCES.FIX_FEE)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { value: fix_fee.value }))))))));
};
