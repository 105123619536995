import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { currencySymbol } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './PaymentPlanDetailTable.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanDetailTable = function (_a) {
    var paymentSteps = _a.paymentSteps, currency = _a.currency;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var totalAmount = paymentSteps.reduce(function (acc, _a) {
        var amount = _a.attributes.amount;
        return acc + +amount;
    }, 0);
    var totalRemaining = paymentSteps.reduce(function (acc, _a) {
        var remaining_balance = _a.attributes.remaining_balance;
        return acc + +remaining_balance;
    }, 0);
    var totalPaid = totalAmount - totalRemaining;
    return (React.createElement(Card, { title: t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS), className: styles('payment-steps') },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, t(i18nKeys.DATE)),
                    React.createElement("th", null, t(i18nKeys.INVOICE.DAY_OF_DELAY)),
                    React.createElement("th", null, t(i18nKeys.TOTAL_VAT_PRICE)),
                    React.createElement("th", null, t(i18nKeys.INVOICING.ALREADY_PAID)),
                    React.createElement("th", null, t(i18nKeys.BALANCE_CURRENCY, {
                        text: currencySymbol(currency),
                    })))),
            React.createElement("tbody", null, paymentSteps.map(function (_a) {
                var id = _a.id, _b = _a.attributes, amount = _b.amount, due_date = _b.due_date, remaining_balance = _b.remaining_balance, days_late = _b.days_late;
                return (React.createElement("tr", { className: styles('step-line'), key: id },
                    React.createElement("td", null, formattedDate(due_date, currentLang)),
                    React.createElement("td", null, days_late || '-'),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: amount, value: amount, suffix: currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: amount - remaining_balance, value: amount - remaining_balance, suffix: currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: remaining_balance, value: remaining_balance, suffix: currency }))));
            })),
            paymentSteps.length > 1 && (React.createElement("tfoot", null,
                React.createElement("tr", { className: styles('total-line') },
                    React.createElement("td", null),
                    React.createElement("td", null),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: totalAmount, value: totalAmount, suffix: currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: totalPaid, value: totalPaid, suffix: currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: totalRemaining, value: totalRemaining, suffix: currency }))))))));
};
