import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import { atom, useAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales/';
import { UpdatedAt } from 'modules/Analytics/components';
import { isDefined, isEmpty } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadLightUsers, useProfile } from 'shared/hooks';
import { useLoadDashboard } from 'shared/hooks/use-load-dashboard';
import { PageTitle } from 'shared/layout/PageTitle';
import { Grid, Group, LoadingOverlay, Select, Title } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { IconUser } from '@tabler/icons-react';
import { BalanceGraphic } from './BalanceGraphic';
import BankStatus from './BankStatus';
import { DashboardChartLoader } from './DashboardChartLoader';
import { OutstandingGraphic } from './OutstandingGraphic';
import RecentActivities from './RecentActivities';
import styleIdentifiers from './dashboard.scss';
var styles = classNames.bind(styleIdentifiers);
var staggerContainer = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.02,
        },
    },
};
var staggerItem = {
    hidden: { y: 20 },
    show: { y: 0 },
};
var wasDashboardVisitedAtom = atom(false);
export var Dashboard = function () {
    var t = useTranslation().t;
    var profile = useProfile();
    var _a = __read(useState(), 2), accountManagerId = _a[0], setAccountManagerId = _a[1];
    var _b = useLoadDashboard({
        accountManagerId: accountManagerId,
    }), dashboard = _b.dashboard, isDashboardFetching = _b.isDashboardFetching;
    var _c = __read(useAtom(wasDashboardVisitedAtom), 2), wasDashboardVisited = _c[0], setWasDashboardVisited = _c[1];
    var previousDashboardValue = usePrevious(dashboard);
    var _d = useLoadLightUsers().users, users = _d === void 0 ? [] : _d;
    var _e = useLoadAgedBalanceCompact(), agedBalanceCompact = _e.agedBalanceCompact, isAgedBalanceCompactLoading = _e.isAgedBalanceCompactLoading;
    var isFirstRenderWithData = !isDefined(previousDashboardValue) && isDefined(dashboard);
    var shouldShowAnimation = isFirstRenderWithData && !wasDashboardVisited;
    useEffect(function () {
        if (shouldShowAnimation)
            setWasDashboardVisited(true);
    }, [shouldShowAnimation, setWasDashboardVisited]);
    var initial = shouldShowAnimation ? 'hidden' : 'show';
    if (!isDefined(dashboard))
        return null;
    return (React.createElement("div", { className: styles('Dashboard'), style: { position: 'relative' } },
        React.createElement(LoadingOverlay, { visible: isDashboardFetching }),
        React.createElement(motion.div, { variants: staggerContainer, initial: initial, animate: "show" },
            React.createElement(motion.div, { variants: staggerItem },
                React.createElement(PageTitle, null,
                    t(i18nKeys.COMMON.DASHBOARD),
                    React.createElement(PageTitle.Actions, null, (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Select, { w: 235, comboboxProps: { shadow: 'sm' }, placeholder: t(i18nKeys.ACCOUNT_MANAGER), clearable: true, disabled: isEmpty(users), leftSection: React.createElement(IconUser, null), onChange: function (value) {
                            return setAccountManagerId(value == null ? undefined : Number(value));
                        }, data: users.map(function (_a) {
                            var name = _a.name, id = _a.id;
                            return ({ value: id.toString(), label: name.full });
                        }) }))))),
            React.createElement(Grid, { style: { overflow: 'visible' } },
                React.createElement(Grid.Col, { span: profile.isAdmin ? 7 : 12 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(RecentActivities, { accountManagerId: accountManagerId, data: dashboard.data.recentActivities }))),
                profile.isAdmin && (React.createElement(Grid.Col, { span: 5 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(BankStatus, { data: dashboard.data.financialData })))),
                React.createElement(Grid.Col, { span: 12, pt: "lg" },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(Group, { justify: "space-between" },
                            React.createElement(Title, { order: 3 }, "Analytics"),
                            React.createElement(UpdatedAt, null)))),
                React.createElement(Grid.Col, { span: 5 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE })) : (React.createElement(OutstandingGraphic, { agedBalanceCompact: agedBalanceCompact, accountManagerId: accountManagerId })))),
                React.createElement(Grid.Col, { span: 7 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE })) : (React.createElement(BalanceGraphic, { agedBalanceCompact: agedBalanceCompact }))))))));
};
