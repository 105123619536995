import React from 'react';
import classNames from 'classnames/bind';
import styleIdentifiers from './card.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Card(_a) {
    var title = _a.title, titleIcon = _a.titleIcon, className = _a.className, children = _a.children, classTitle = _a.classTitle, classContent = _a.classContent, infosRight = _a.infosRight, bottom = _a.bottom, noHead = _a.noHead, noBorder = _a.noBorder, subtitle = _a.subtitle, actionHead = _a.actionHead, noRadiusTop = _a.noRadiusTop, noPadding = _a.noPadding;
    return (React.createElement("div", { className: styles('Card', className, 'card', noRadiusTop && 'no-radius-top', noBorder && 'no-border') },
        !noHead && (React.createElement("div", { className: styles('head', classTitle, actionHead && 'link'), onClick: actionHead },
            React.createElement("div", { className: styles(subtitle && 'head-content') },
                React.createElement("h3", null,
                    title,
                    " ",
                    titleIcon),
                subtitle && React.createElement("span", { className: styles('subtitle') }, subtitle)),
            infosRight && React.createElement("span", { className: styles('infos-right') }, infosRight))),
        React.createElement("div", { className: styles('content', noPadding && 'no-padding', classContent) }, children),
        bottom && React.createElement("div", { className: styles('bottom') }, bottom)));
}
