import { __read } from "tslib";
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styleIdentifiers from './ColorPicker.scss';
var styles = classNames.bind(styleIdentifiers);
export var ColorPicker = function (_a) {
    var onChange = _a.onChange, value = _a.value;
    var _b = __read(useState(value || null), 2), color = _b[0], setColor = _b[1];
    var handleChange = function (newValue) {
        if ((color && color.hex !== newValue.hex) || !color) {
            setColor(newValue);
            onChange(newValue);
        }
    };
    return (React.createElement("div", { className: styles('ColorPicker') },
        React.createElement(SketchPicker, { onChange: handleChange, color: color || '' })));
};
