import { __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useParams } from 'react-router';
import { isDefined } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { PageTitle } from 'shared/layout';
import { useGetCompany } from 'shared/utils/selectors';
import { formattedDate } from 'shared/utils/view';
import { Accordion, Card, Divider, Group, Select, Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';
import { useLoadReminder } from '../api';
var EmailStatusHistory = styled('div', {});
var EmailStatusHistoryItem = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    padding: '10px 0px 0px 5px',
});
export var Reminder = function (_a) {
    var _b;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var id = useParams().id;
    var _d = useLoadReminder(id), reminder = _d.reminder, isReminderLoading = _d.isReminderLoading;
    var _e = __read(React.useState(), 2), selectedRecipient = _e[0], setSelectedRecipient = _e[1];
    var canUseEmailStatus = useGetCompany().package.can_use_emails_activity;
    useEffect(function () {
        if (reminder != null) {
            var firstRecipient = reminder.recipients[0];
            if (isDefined(firstRecipient))
                setSelectedRecipient(firstRecipient.email);
        }
    }, [reminder == null]);
    var selectedEmail = reminder === null || reminder === void 0 ? void 0 : reminder.recipients.find(function (recipient) { return recipient.email === selectedRecipient; });
    var flattenedStatuses = Object.keys((_b = selectedEmail === null || selectedEmail === void 0 ? void 0 : selectedEmail.statuses) !== null && _b !== void 0 ? _b : {}).reduce(function (memo, currentKey) { return __spreadArray(__spreadArray([], __read(memo), false), __read(selectedEmail === null || selectedEmail === void 0 ? void 0 : selectedEmail.statuses[currentKey].map(function (date) { return ({
        status: currentKey,
        timestamp: dayjs(date),
    }); })), false); }, []);
    var sortedStatuses = flattenedStatuses.sort(function (a, b) { return b.timestamp.diff(a.timestamp); });
    if (isReminderLoading)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.REMINDER.REMINDER)),
        React.createElement(Card, null,
            React.createElement(Stack, { gap: "lg" },
                React.createElement(Group, null,
                    React.createElement(Text, { fw: "bold", c: "dimmed", fz: "xs", w: 120 }, t(i18nKeys.REMINDER.SENT_TO)),
                    React.createElement(Select, { w: 300, size: "sm", data: reminder === null || reminder === void 0 ? void 0 : reminder.recipients.map(function (recipient) { return recipient.email; }), value: selectedRecipient, onChange: setSelectedRecipient, allowDeselect: false })),
                React.createElement(Group, null,
                    React.createElement(Text, { fw: "bold", c: "dimmed", fz: "xs", w: 120 }, t(i18nKeys.REMINDER.DATE_SENT)),
                    React.createElement(Text, null, dayjs(selectedEmail === null || selectedEmail === void 0 ? void 0 : selectedEmail.sentAt).format('dddd DD MMMM YYYY - HH:mm')))),
            sortedStatuses.length !== 0 && canUseEmailStatus && (React.createElement(Group, { pt: "sm" },
                React.createElement(Text, { fw: "bold", c: "dimmed", fz: "xs", w: 120 }, t(i18nKeys.REMINDER.DELIVERY_STATUS)),
                React.createElement(Accordion, { variant: "separated", w: 300 },
                    React.createElement(Accordion.Item, { value: "1" },
                        React.createElement(Accordion.Control, null, t(i18nKeys.REMINDER.SEE_DELIVERY_HISTORY)),
                        React.createElement(Accordion.Panel, null,
                            React.createElement(EmailStatusHistory, null, sortedStatuses.map(function (statusObject, index) { return (React.createElement(EmailStatusHistoryItem, { key: index },
                                React.createElement("span", null, t(i18nKeys.MAILS.STATUS[statusObject.status.toUpperCase()])),
                                React.createElement("span", { style: { fontStyle: 'italic', color: '#bbb' } }, formattedDate(statusObject.timestamp.toDate(), currentLang, 'DD/MM/YYYY - HH:mm')))); }))))))),
            React.createElement(Divider, { my: "xl" }),
            React.createElement(HTML, { html: selectedEmail === null || selectedEmail === void 0 ? void 0 : selectedEmail.body }))));
};
