import { __assign, __rest } from "tslib";
import { pick } from 'lodash-es';
export var getInitialValues = function (task) {
    if (!task) {
        return {};
    }
    var _a = task.attributes, debtor = _a.debtor, assignee = _a.assignee, invoices = _a.invoices, rest = __rest(_a, ["debtor", "assignee", "invoices"]);
    return __assign(__assign(__assign(__assign({}, pick(rest, ['title', 'description', 'task_type_id', 'due_date'])), (debtor
        ? {
            debtor_id: debtor.id,
        }
        : undefined)), (assignee
        ? {
            user_id: assignee.id,
        }
        : undefined)), (invoices
        ? {
            invoice_ids: task.attributes.invoices.details.map(function (e) { return e.id; }),
        }
        : undefined));
};
export var getTaskTypeDescription = function (taskTypes, task) {
    var taskType = taskTypes.find(function (type) { return Number(type.value) === Number(task.attributes.task_type_id); });
    return taskType ? taskType.description : undefined;
};
export var getAssigneeFullName = function (_a) {
    var _b = _a.attributes.assignee.attributes, first_name = _b.first_name, last_name = _b.last_name;
    return "".concat(first_name, " ").concat(last_name);
};
