import { i18nKeys } from 'locales';
export var PaymentPlanStatus;
(function (PaymentPlanStatus) {
    PaymentPlanStatus["CANCELLED"] = "CANCELLED";
    PaymentPlanStatus["COMPLETED"] = "COMPLETED";
    PaymentPlanStatus["IN_PROGRESS"] = "IN_PROGRESS";
})(PaymentPlanStatus || (PaymentPlanStatus = {}));
export var TABLE_HEADERS = [
    { key: 'id', title: i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ID },
    {
        key: 'debtor',
        title: i18nKeys.COMMON.CLIENT,
    },
    {
        key: 'invoices',
        title: '',
    },
    {
        key: 'status',
        title: i18nKeys.STATUS,
    },
    {
        key: 'next_step',
        title: i18nKeys.NEXT_DEADLINE,
    },
    {
        title: i18nKeys.FORM.ACTIONS,
        width: 100,
    },
];
