import { __assign, __generator, __rest } from "tslib";
import { SendingMediaNames } from 'api/models';
import { i18nKeys } from 'locales';
import { all, call, select, takeLatest } from 'redux-saga/effects';
import { invoiceFormToData } from 'shared/serializer';
import { api } from 'store/apis';
import { creditNoteActions, creditNoteConstants as events, } from 'store/creditNote/creditnote.actions';
import { apiCall, sendApi } from '../sagas';
function creditNoteAdd(action) {
    var payload, constants, toSend;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                return [4 /*yield*/, select(function (state) { return state.app.constants; })];
            case 1:
                constants = _a.sent();
                toSend = invoiceFormToData(payload.data, constants);
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.add,
                        data: toSend,
                        actionRes: creditNoteActions.addRes,
                        callback: payload.callback,
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteUpdate(_a) {
    var constants, toSend;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.app.constants; })];
            case 1:
                constants = _b.sent();
                toSend = invoiceFormToData(payload.data, constants);
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.update,
                        id: toSend.id,
                        data: toSend,
                        actionRes: creditNoteActions.updateRes,
                        callback: payload.callback,
                        success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
                    })];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteLinkToInvoices(_a) {
    var _b, id, data;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _b = payload.data, id = _b.id, data = __rest(_b, ["id"]);
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.update,
                        id: id,
                        data: data,
                        actionRes: creditNoteActions.linkToInvoicesRes,
                        callback: payload.callback,
                        success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
                    })];
            case 1:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteUnLinkToInvoices(_a) {
    var id, data, callback;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, data = payload.data, callback = payload.callback;
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.update,
                        id: id,
                        data: data,
                        callback: callback,
                        actionRes: creditNoteActions.linkToInvoicesRes,
                        success: i18nKeys.INVOICE.SUCCESSFUL_CN_UPDATE,
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteInfo(_a) {
    var callback, rest;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, rest = __rest(payload, ["callback"]);
                return [4 /*yield*/, creditNoteActions.detail(payload)];
            case 1:
                _b.sent();
                return [4 /*yield*/, creditNoteActions.activity(rest)];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteSend(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                data = payload && payload.data;
                if (!data)
                    return [2 /*return*/];
                if (data.by_mail && data.by_post) {
                    data.sending_media = [
                        { name: SendingMediaNames.email },
                        {
                            name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
                        },
                    ];
                }
                else if (data.by_mail) {
                    data.sending_media = [{ name: SendingMediaNames.email }];
                }
                else {
                    data.sending_media = [
                        {
                            name: data.by_registered_post ? SendingMediaNames.registered_post : SendingMediaNames.post,
                        },
                    ];
                }
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.send,
                        id: payload.id,
                        data: data,
                        callback: payload.callback,
                        actionRes: creditNoteActions.sendRes,
                        success: i18nKeys.INVOICE.SUCCESSFUL_CN_SENT,
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function invoiceSearch(_a) {
    var callback, data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, data = __rest(payload, ["callback"]);
                return [4 /*yield*/, apiCall({
                        api: api.creditNote.invoiceSearch,
                        data: data,
                        callback: callback,
                        noFeedback: true,
                        noLoading: true,
                        actionRes: creditNoteActions.invoiceSearchRes,
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function creditNoteWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.listPage.request, sendApi(api.creditNote.list, creditNoteActions.listPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.get.request, sendApi(api.creditNote.list))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.detail.request, sendApi(api.creditNote.detail, creditNoteActions.detailRes))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.add.request, creditNoteAdd)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.update.request, creditNoteUpdate)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.linkToInvoices.request, creditNoteLinkToInvoices)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.unlinkToInvoices.request, creditNoteUnLinkToInvoices)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.delete.request, sendApi(api.creditNote.delete, creditNoteActions.deleteRes))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.info.request, creditNoteInfo)];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.copy.request, sendApi(api.invoice.copy, creditNoteActions.detailRes))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.send.request, creditNoteSend)];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.settle.request, sendApi(api.creditNote.settle))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.batchAction.request, sendApi(api.creditNote.batchAction))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activity.request, sendApi(api.creditNote.activity, creditNoteActions.activityRes))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.invoiceSearch.request, invoiceSearch)];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateOriginalFile.request, sendApi(api.creditNote.updateOriginalFile, creditNoteActions.updateOriginalFileRes, function (payload) { return (__assign(__assign({}, payload), { fileUpload: true, success: i18nKeys.SUCCESSFULLY_FILE_UPLOAD })); }))];
            case 16: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 17:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(creditNoteWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
