export * from './charts';
export * from './date';
export * from './environment';
export * from './form';
export * from './identities';
export * from './query';
export * from './unimplemented';
export function usersToDescVal(users) {
    return users.map(function (_a) {
        var id = _a.id, name = _a.name;
        return ({ description: name.full, value: id });
    });
}
export function descValsToSelectOptions(descVals) {
    return descVals.map(function (_a) {
        var description = _a.description, value = _a.value;
        return ({ label: description, value: value });
    });
}
