import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import Tooltip from 'shared/components/Tooltip';
import { CustomSelect, Input } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { productActions } from 'store/product/product.actions';
import styleIdentifiers from './InvoiceProductLine.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceProductLine(_a) {
    var _b, _c, _d, _e;
    var lineIndex = _a.lineIndex, removeProductLine = _a.removeProductLine, field = _a.field, initialValues = _a.initialValues, isCreditNote = _a.isCreditNote, noTva = _a.noTva, currency = _a.currency, company = _a.company, constants = _a.constants;
    var t = useTranslation().t;
    var listProducts = useSelector(function (state) { var _a; return ((_a = state.product.search) === null || _a === void 0 ? void 0 : _a.data) || []; });
    var _f = useFormContext(), watch = _f.watch, setValue = _f.setValue, register = _f.register, control = _f.control, getValues = _f.getValues, reset = _f.reset, errors = _f.formState.errors;
    var product_lines_attributes = watch('product_lines_attributes');
    var productChanged = watch('productChanged');
    var debtor = watch('debtor_attributes');
    var onSelectProduct = function (_a) {
        var item = _a.item;
        if (isCreditNote) {
            if (item.price_htva > 0)
                item.price_htva *= -1;
            if (item.current_unity_price_htva > 0)
                item.current_unity_price_htva *= -1;
        }
        var formValues = getValues();
        formValues.product_lines_attributes[lineIndex] = {
            id: '',
            product_id: item.id,
            quantity: 1,
            product_attributes: {
                tax_category_code: noTva ? '00' : item.tax_category_code,
                current_unity_price_htva: item.price_htva,
                name: item.name,
            },
        };
        reset(formValues);
    };
    var onChangeInputProduct = function () {
        if (product_lines_attributes[lineIndex].product_id) {
            setValue("product_lines_attributes.".concat(lineIndex, ".product_id"), undefined);
            setValue("product_lines_attributes.".concat(lineIndex, ".id"), undefined);
        }
    };
    var productLine = (product_lines_attributes && product_lines_attributes[lineIndex]) || {};
    var product = productLine.product_attributes || {};
    var tvaCode = product.tax_category_code || field.tax_category_code;
    var tvaObj = tvaCode && constants.tax_categories.find(function (it) { return it.value === tvaCode; });
    var tvaRate = noTva ? 0 : (tvaCode && (tvaObj === null || tvaObj === void 0 ? void 0 : tvaObj.vat_value)) || 0;
    register("product_lines_attributes.".concat(lineIndex, ".total_htva"));
    register("product_lines_attributes.".concat(lineIndex, ".total_tvac"));
    register("product_lines_attributes.".concat(lineIndex, ".id"));
    register("product_lines_attributes.".concat(lineIndex, ".product_id"));
    useEffect(function () {
        setValue("product_lines_attributes.".concat(lineIndex, ".total_htva"), productLine.quantity *
            product.current_unity_price_htva *
            (1 - (productLine.discount_rate || 0) / 100));
        setValue("product_lines_attributes.".concat(lineIndex, ".total_tvac"), productLine.quantity *
            product.current_unity_price_htva *
            (1 - (productLine.discount_rate || 0) / 100) *
            (1 + +tvaRate / 100));
        setValue('productChanged', productChanged + 1);
    }, [productLine.quantity, productLine.discount_rate, product.current_unity_price_htva, tvaRate]);
    var removeProduct = function () {
        removeProductLine(lineIndex);
    };
    var productItem = function (_a) {
        var item = _a.item;
        return (React.createElement("div", { className: styles('product-item') },
            React.createElement("span", null, item.name),
            React.createElement("span", null,
                t(i18nKeys.EXCL_VAT_PRICE),
                ":",
                ' ',
                formatAmount(item.price_htva, ',', '.', 2, currencySymbol(currency, company)))));
    };
    return (React.createElement("tr", { className: styles('invoice-product-line') },
        React.createElement("td", null,
            React.createElement(Controller, { control: control, rules: { required: true }, name: "product_lines_attributes.".concat(lineIndex, ".product_attributes.name"), defaultValue: "", render: function () {
                    var _a, _b;
                    return (React.createElement(CustomSelect, { CustomInput: Input, defaultValue: (_b = (_a = initialValues.product_lines_attributes) === null || _a === void 0 ? void 0 : _a[lineIndex]) === null || _b === void 0 ? void 0 : _b.product_attributes.name, load: function () {
                            productActions.search({
                                name: watch("product_lines_attributes.".concat(lineIndex, ".product_attributes.name")),
                            });
                        }, size: "small", className: styles('invoice-select-input-wrapper'), customInputClassname: styles('invoice-select-input'), selectClassName: styles('invoice-select'), valueClassName: styles('value'), errorClassName: styles('invoice-select-error'), itemRendering: productItem, items: listProducts, name: "product_lines_attributes.".concat(lineIndex, ".product_attributes.name"), placeholder: t(i18nKeys.INVOICING.CREATE.CHOOSE_PRODUCT), onSelectItem: onSelectProduct, onInputChange: onChangeInputProduct, noMargin: true, noArrow: true, noBorder: true }));
                } })),
        React.createElement("td", null,
            React.createElement(Input, { defaultValue: field.quantity || 1, className: styles('invoice-input'), inputClassName: styles('value'), errorClassName: styles('error'), errorMessage: errors.product_lines_attributes && ((_b = errors.product_lines_attributes[lineIndex]) === null || _b === void 0 ? void 0 : _b.quantity), noMargin: true, noBorder: true, register: register("product_lines_attributes.".concat(lineIndex, ".quantity"), {
                    required: true,
                    min: 0,
                }), type: "number", placeholder: "0", min: "0", step: "0.01" })),
        React.createElement("td", null,
            React.createElement(Input, { defaultValue: (_c = field.product_attributes) === null || _c === void 0 ? void 0 : _c.current_unity_price_htva, className: styles('invoice-input'), inputClassName: styles('value'), errorClassName: styles('error'), errorMessage: errors.product_lines_attributes &&
                    ((_e = (_d = errors.product_lines_attributes[lineIndex]) === null || _d === void 0 ? void 0 : _d.product_attributes) === null || _e === void 0 ? void 0 : _e.current_unity_price_htva), noMargin: true, noBorder: true, register: register("product_lines_attributes.".concat(lineIndex, ".product_attributes.current_unity_price_htva"), { required: true }), placeholder: "0.00", type: "number", step: "0.01" })),
        React.createElement("td", null,
            React.createElement(Input, { className: styles('invoice-input'), inputClassName: styles('value'), defaultValue: field.discount_rate, register: register("product_lines_attributes.".concat(lineIndex, ".discount_rate"), {
                    min: 0,
                    max: 100,
                }), noMargin: true, noBorder: true, type: "number", min: "0", max: "100", placeholder: "0%", step: "0.01" })),
        React.createElement("td", { className: styles('amount') },
            React.createElement("div", { className: styles('htva-price') },
                React.createElement(Amount, { value: product &&
                        productLine.quantity *
                            product.current_unity_price_htva *
                            (1 - (productLine.discount_rate || 0) / 100), suffix: currency }))),
        React.createElement("td", null,
            (productLine.product_id || noTva) && (React.createElement("div", { className: styles('amount') },
                React.createElement(Percentage, { value: tvaRate }),
                noTva && (React.createElement(Tooltip, { icon: IconName.INFO }, t(debtor.co_contractor
                    ? i18nKeys.FORM.INVOICING.VAT_COCONTRACTOR
                    : i18nKeys.FORM.INVOICING.VAT_INTRACOM))))),
            React.createElement("div", { className: productLine.product_id || noTva ? 'invisible-input' : '' },
                React.createElement(Controller, { control: control, defaultValue: "", rules: { required: true }, name: "product_lines_attributes.".concat(lineIndex, ".product_attributes.tax_category_code"), render: function () { return (React.createElement(CustomSelect, { size: "small", valueClassName: styles('select-value'), filter: true, className: styles('tva', 'invoice-select'), name: "product_lines_attributes.".concat(lineIndex, ".product_attributes.tax_category_code"), keyText: "description", keyValue: "value", noBorder: true, noMargin: true, placeholder: t(i18nKeys.SELECT), items: constants.tax_categories, errorClassName: styles('invoice-select-error') })); } }),
                noTva && (React.createElement("div", { className: styles('special-tva-info') }, t(i18nKeys.INVOICING.CREATE.VAT_APPLIED_0))))),
        React.createElement("td", { className: styles('amount') },
            React.createElement("div", { className: styles('vat-total') },
                React.createElement(Amount, { value: product &&
                        productLine.quantity *
                            product.current_unity_price_htva *
                            (1 - (productLine.discount_rate || 0) / 100) *
                            (1 + +tvaRate / 100), suffix: currency }),
                React.createElement("div", { className: styles('remove'), onClick: removeProduct },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "16px" }))))));
}
