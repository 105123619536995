import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useForm } from 'react-hook-form';
import HTML from 'shared/components/HTML/HTML';
import { Button, ButtonColor, CustomRadio } from 'shared/io';
import styleIdentifiers from './postponeForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PostponeForm(_a) {
    var onSubmit = _a.onSubmit, close = _a.close, _invoices = _a.invoices;
    var t = useTranslation().t;
    var areAllInvoicesSelected = _invoices === 'all';
    var invoices = _invoices === 'all' ? [] : _invoices;
    var buttonLabel = (function () {
        var basePath = i18nKeys.INVOICING.IMPENDING_REMINDERS;
        if (areAllInvoicesSelected)
            return t(basePath.POSTPONE_ALL);
        if (invoices.length === 1)
            return t(basePath.INVOICE_TO_POSTPONE);
        return t(basePath.INVOICES_TO_POSTPONE, { count: invoices.length });
    })();
    var _b = useForm(), handleSubmit = _b.handleSubmit, control = _b.control, errors = _b.formState.errors;
    var contentRadio = function (day) { return (React.createElement("div", null,
        React.createElement("div", null, day),
        React.createElement("div", null, t(i18nKeys.DAY)))); };
    return (React.createElement("form", { className: styles('PostponeForm'), onSubmit: handleSubmit(onSubmit) },
        React.createElement("div", { className: styles('wrapper') },
            React.createElement("div", { className: styles('content') }, invoices.length === 1 && React.createElement(HTML, { html: invoices[0].next_step_information })),
            React.createElement("div", { className: styles('form-wrapper') },
                React.createElement(Controller, { control: control, rules: { required: true }, name: "days_delayed", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(CustomRadio, __assign({}, values, { nameItem: "postpone", className: styles('custom-radio'), label: contentRadio(7), valueItem: "7" })));
                    } }),
                React.createElement(Controller, { control: control, rules: { required: true }, name: "days_delayed", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(CustomRadio, __assign({}, values, { nameItem: "postpone", className: styles('custom-radio'), label: contentRadio(14), valueItem: "14" })));
                    } }),
                React.createElement(Controller, { control: control, name: "days_delayed", rules: { required: true }, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(CustomRadio, __assign({}, values, { nameItem: "postpone", className: styles('custom-radio'), label: contentRadio(21), valueItem: "21" })));
                    } }),
                React.createElement(Controller, { control: control, name: "days_delayed", rules: { required: true }, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(CustomRadio, __assign({}, values, { nameItem: "postpone", className: styles('custom-radio'), label: contentRadio(28), valueItem: "28" })));
                    } })),
            errors.days_delayed && (React.createElement("div", { className: styles('error-message') }, t(i18nKeys.FORM.SELECT_AT_LEAST_ONE))),
            React.createElement("div", { className: styles('buttons-wrapper') },
                React.createElement(Button, { noMargin: true, color: ButtonColor.GREY, label: t(i18nKeys.INVOICING.DONT_CHANGE), onClick: close }),
                React.createElement(Button, { noMargin: true, label: buttonLabel, type: "submit", className: styles('button') })))));
}
