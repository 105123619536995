import { buildActions, makeReqCons } from 'store/actions';
export var accountConstants = {
    updateCompanyInfo: makeReqCons('account_update_company_info'),
    updateYukiSettings: makeReqCons('account_update_yuki'),
    updateHorusSettings: makeReqCons('account_update_horus'),
    updateDbasicsSettings: makeReqCons('account_update_dbasics'),
    updateTeamleaderSettings: makeReqCons('account_update_teamleader'),
    updateExactSettings: makeReqCons('account_update_exact'),
    updateBillitSettings: makeReqCons('account_update_billit'),
    exactFetchCompanies: makeReqCons('exact_fetch_companies'),
    horusFetchCompanies: makeReqCons('horus_fetch_companies'),
    syncIntegration: makeReqCons('sync_integration'),
    editBankingInfo: makeReqCons('edit-banking-info'),
    getCompany: makeReqCons('ACCOUNT_GET_COMPANY'),
    updateInvoiceConfigurations: makeReqCons('ACCOUNT_UPDATE_INVOICE_FOOTER'),
    updateCompany: makeReqCons('ACCOUNT_UPDATE_COMPANY'),
    updateCompanyFiles: makeReqCons('UPDATE_COMPANY_FILES'),
    importInvoice: makeReqCons('invoice_import'),
    activityPage: makeReqCons('account_activity_page'),
    activityDetail: makeReqCons('account_activity_detail'),
    activitySeen: makeReqCons('account_activity_seen'),
    activitySeenAll: makeReqCons('account_activity_seen_all'),
    setDigitealConfiguration: makeReqCons('set_digiteal_configuration'),
    resetSecretId: makeReqCons('reset_secret_id'),
    askUpgradePackage: makeReqCons('ask_upgrade_package'),
    getDigitealCGV: makeReqCons('get_digiteal_CGV'),
    getTerms: makeReqCons('GET_ACCOUNT_CGV'),
    uploadTerms: makeReqCons('PUT_ACCOUNT_CGV'),
};
// Autogenerated actions according to constants
var accountActions = buildActions('', accountConstants, {});
export { accountActions };
