import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { omit } from 'remeda';
import { useGetDialogs } from 'shared/utils/selectors';
import Modal from '../Modal';
import styleIdentifiers from './modals.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Modals() {
    var dialogs = useGetDialogs();
    return (React.createElement("div", { className: styles('modals') }, Object.keys(omit(dialogs, ['count'])).map(function (key) { return (React.createElement(Modal, { dialog: __assign({}, dialogs[key]), key: key })); })));
}
