import { __assign, __makeTemplateObject, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { upperCase } from 'lodash-es';
import { useHistory } from 'react-router';
import { isEmpty } from 'remeda';
import { useCreateReport, useLoadReports, useLoadViews } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { css } from '@emotion/css';
import { Button, Card, Center, Divider, Group, Loader, Paper, Select, SimpleGrid, Stack, Stepper, Text, UnstyledButton, } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { IconArrowRight, IconCalendarClock, IconFileAlert, IconFileDollar, IconInvoice, IconListCheck, IconReport, IconUsers, } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { NameStep, PeriodicityStep, RecipientsStep } from './steps';
// TODO: extract remaining steps
var styles = {
    resourceButton: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    height: rem(90px);\n    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));\n    transition: box-shadow 200ms ease, transform 200ms ease, border-color 200ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.03);\n    }\n  "], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    height: rem(90px);\n    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));\n    transition: box-shadow 200ms ease, transform 200ms ease, border-color 200ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.03);\n    }\n  "]))),
    resourceButtonText: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    transition: color 200ms ease;\n  "], ["\n    transition: color 200ms ease;\n  "]))),
};
var iconStyles = { size: 32, stroke: 1.5, color: 'var(--mantine-color-blue-6)' };
export var RESOURCES = [
    { slug: 'debtors', name: 'DEBTORS', icon: React.createElement(IconUsers, __assign({}, iconStyles)) },
    { slug: 'invoices', name: 'INVOICES', icon: React.createElement(IconFileDollar, __assign({}, iconStyles)) },
    {
        slug: 'postponable_invoices',
        name: 'REMINDERS',
        icon: React.createElement(IconCalendarClock, __assign({}, iconStyles)),
    },
    { slug: 'credit_notes', name: 'CREDIT_NOTES', icon: React.createElement(IconInvoice, __assign({}, iconStyles)) },
    { slug: 'tasks', name: 'TASKS', icon: React.createElement(IconListCheck, __assign({}, iconStyles)) },
    {
        slug: 'actionable_invoices',
        name: 'ACTIONABLE_INVOICES',
        icon: React.createElement(IconFileAlert, __assign({}, iconStyles)),
    },
];
export var CreateReport = function () {
    var _a, _b, _c, _d, _e;
    var t = useTranslation().t;
    var history = useHistory();
    var client = useQueryClient();
    var _f = useCreateReport(), createReport = _f.createReport, isCreateReportLoading = _f.isCreateReportLoading;
    var persisted = JSON.parse((_a = localStorage.getItem('persist-report-creation')) !== null && _a !== void 0 ? _a : 'null');
    var _g = __read(useState((_b = persisted === null || persisted === void 0 ? void 0 : persisted.step) !== null && _b !== void 0 ? _b : 0), 2), step = _g[0], setStep = _g[1];
    var _h = __read(useState((_c = persisted === null || persisted === void 0 ? void 0 : persisted.highestStepVisited) !== null && _c !== void 0 ? _c : 0), 2), highestStepVisited = _h[0], setHighestStepVisited = _h[1];
    var _j = __read(useState((_d = persisted === null || persisted === void 0 ? void 0 : persisted.subtitles) !== null && _d !== void 0 ? _d : { 0: '', 1: '', 2: '' }), 2), subtitles = _j[0], setSubtitles = _j[1];
    var setSubtitle = function (index, subtitle) {
        setSubtitles(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[index] = subtitle, _a)));
        });
    };
    // it's only here to guarantee that the data will be in cache for the form validation function below
    // eslint-disable-next-line
    var _k = useLoadReports();
    useEffect(function () {
        if (step > highestStepVisited)
            setHighestStepVisited(step);
    }, [step]);
    // TODO: extract the form and expose the validation
    var form = useForm({
        validateInputOnChange: true,
        initialValues: (_e = persisted === null || persisted === void 0 ? void 0 : persisted.form) !== null && _e !== void 0 ? _e : {
            name: '',
            viewId: null,
            resourceType: 'debtors',
            periodicity: 'weekly',
            daysWeek: [],
            daysMonth: [],
            // TODO: rename collaboratorEmails, externalEmails
            collaborators: [],
            emails: [],
        },
        validate: {
            name: function (value) {
                var _a;
                var exportTasks = (_a = client.getQueryData(['reports'])) !== null && _a !== void 0 ? _a : [];
                var exportTasksNames = exportTasks.map(function (task) { return task.name; });
                if (exportTasksNames.includes(value))
                    return t(i18nKeys.REPORTS.NEW.STEPS.NAME.DUPLICATED_NAME);
                return null;
            },
            emails: function (value) {
                var error = null;
                value.forEach(function (email) {
                    if (isEmail(true)(email))
                        error = "".concat(email, " ").concat(t(i18nKeys.REPORTS.NEW.STEPS.RECIPIENTS.INVALID_EMAIL));
                });
                return error;
            },
        },
    });
    useEffect(function () {
        localStorage.setItem('persist-report-creation', JSON.stringify({
            form: form.values,
            subtitles: subtitles,
            highestStepVisited: highestStepVisited,
            step: step,
        }));
    }, [JSON.stringify(form.values), subtitles, highestStepVisited, step]);
    var _l = useLoadViews({ resourceType: form.values.resourceType }), views = _l.views, isViewsFetching = _l.isViewsFetching;
    var viewsForSelect = (views !== null && views !== void 0 ? views : []).map(function (view) { return ({ value: view.id, label: view.name }); });
    useEffect(function () {
        var _a;
        // The extra logic below is to ensure that when we don't override persisted state when the effect runs
        if (isViewsFetching)
            return;
        if (viewsForSelect.map(function (v) { return v.value; }).includes(persisted.form.viewId))
            form.setFieldValue('viewId', persisted.form.viewId);
        else
            form.setFieldValue('viewId', (_a = viewsForSelect[0]) === null || _a === void 0 ? void 0 : _a.value);
    }, [isViewsFetching]);
    // TODO: the content of form.values is untyped!
    // TODO: Write a DtO to centralize this code which is replicated in the edit modals
    var handleCreateReport = function () {
        createReport({
            viewId: form.values.viewId,
            name: form.values.name,
            emails: __spreadArray(__spreadArray([], __read(form.values.emails), false), __read(form.values.collaborators), false),
            periodicity: {
                type: form.values.periodicity,
                days: form.values.periodicity === 'weekly'
                    ? form.values.daysWeek
                    : // todo: should all be dayjs
                        form.values.daysMonth.map(function (date) { return new Date(date).getDate(); }),
            },
        }, {
            onSuccess: function () {
                history.push('/reports');
                localStorage.removeItem('persist-report-creation');
            },
        });
    };
    var wasStepVisited = function (index) { return index <= highestStepVisited; };
    return (React.createElement(Stack, null,
        React.createElement(PageTitle, null,
            t(i18nKeys.REPORTS.NEW.TITLE),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { onClick: function () {
                        history.push('/reports');
                        localStorage.removeItem('persist-report-creation');
                    }, color: "orange", variant: "light" }, t(i18nKeys.REPORTS.NEW.CANCEL)))),
        React.createElement(Card, { flex: 1, radius: "md", shadow: "sm" },
            React.createElement(Stepper, { active: step, onStepClick: function (newStep) {
                    var _a;
                    setStep(newStep);
                    setSubtitle(0, form.values.name);
                    if (wasStepVisited(1)) {
                        setSubtitle(1, (_a = viewsForSelect.find(function (view) { return view.value === form.values.viewId; })) === null || _a === void 0 ? void 0 : _a.label);
                    }
                    if (wasStepVisited(2))
                        setSubtitle(2, t(i18nKeys.DATES.PERIOD_NAMES[upperCase(form.values.periodicity)]));
                } },
                React.createElement(Stepper.Step, { label: t(i18nKeys.REPORTS.NEW.STEP_LABELS.NAME), description: subtitles[0], allowStepSelect: true },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, { miw: "33%" },
                            React.createElement(NameStep, { formProps: form.getInputProps('name') }),
                            React.createElement(Button, { disabled: !form.values.name || form.errors.name != null, w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    setSubtitle(0, form.values.name);
                                    setStep(1);
                                } }, t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE))))),
                React.createElement(Stepper.Step, { label: t(i18nKeys.REPORTS.NEW.STEP_LABELS.DATA), allowStepSelect: (highestStepVisited >= 1 && form.values.name !== '') || form.errors.name != null, description: subtitles[1] },
                    React.createElement(Group, { my: "xl", justify: "center", align: "start" },
                        React.createElement(Stack, { w: "33%" },
                            React.createElement(Text, { size: "lg", fw: 450 }, t(i18nKeys.REPORTS.NEW.STEPS.DATA.RESOURCE_TYPE)),
                            React.createElement(Paper, { p: "xl", bg: "gray.1", shadow: "none" },
                                React.createElement(SimpleGrid, { cols: 2 }, RESOURCES.map(function (resource) {
                                    var isSelected = form.values.resourceType === resource.slug;
                                    return (React.createElement(UnstyledButton, { onClick: function () { return form.setFieldValue('resourceType', resource.slug); }, style: {
                                            border: isSelected
                                                ? '2px solid var(--mantine-color-blue-4)'
                                                : '2px solid transparent',
                                        }, key: resource.slug, p: "sm", className: styles.resourceButton },
                                        resource.icon,
                                        React.createElement(Text, { mt: "xs", c: isSelected ? 'blue.6' : 'gray.8', fw: isSelected ? 500 : 400, className: styles.resourceButtonText }, t(i18nKeys.REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES[resource.name]))));
                                })))),
                        React.createElement(Divider, { orientation: "vertical", mx: "lg" }),
                        React.createElement(Stack, { w: "33%" },
                            React.createElement(Text, { size: "lg", fw: 450 }, t(i18nKeys.REPORTS.NEW.STEPS.DATA.VIEW)),
                            React.createElement(Group, { pos: "relative" },
                                React.createElement(Select, __assign({}, form.getInputProps('viewId'), { w: "100%", disabled: isEmpty(viewsForSelect), checkIconPosition: "right", data: viewsForSelect, searchable: true, allowDeselect: false, placeholder: "Select a view to export" })),
                                isViewsFetching ? (React.createElement(Center, { style: { borderRadius: 'var(--mantine-radius-sm)' }, pos: "absolute", w: "100%", h: "100%", bg: "rgba(225, 225, 225, 0.9)" },
                                    React.createElement(Loader, { size: 25 }))) : null),
                            React.createElement(Text, { c: "dimmed" }, t(i18nKeys.REPORTS.NEW.STEPS.DATA.VIEW_SUBTITLE)),
                            React.createElement(Button, { w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    var _a;
                                    setSubtitle(1, (_a = viewsForSelect.find(function (view) { return view.value === form.values.viewId; })) === null || _a === void 0 ? void 0 : _a.label);
                                    setStep(2);
                                } }, t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE))))),
                React.createElement(Stepper.Step, { label: t(i18nKeys.REPORTS.NEW.STEP_LABELS.RECURRENCE), allowStepSelect: highestStepVisited >= 2, description: subtitles[2] },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, null,
                            React.createElement(PeriodicityStep, { form: form }),
                            React.createElement(Button, { disabled: isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek), w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    setSubtitle(2, t(i18nKeys.DATES.PERIOD_NAMES[upperCase(form.values.periodicity)]));
                                    setStep(3);
                                } }, t(i18nKeys.REPORTS.NEW.STEPS.ADVANCE))))),
                React.createElement(Stepper.Step, { label: t(i18nKeys.REPORTS.NEW.STEP_LABELS.RECIPIENTS), allowStepSelect: highestStepVisited >= 3 &&
                        !(isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek)) },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, { w: "33%", gap: "lg" },
                            React.createElement(RecipientsStep, { form: form }),
                            React.createElement(Button, { loading: isCreateReportLoading, disabled: (isEmpty(form.values.collaborators) && isEmpty(form.values.emails)) ||
                                    form.errors.emails != null, w: "100%", mt: "xl", rightSection: React.createElement(IconReport, { stroke: 1.5 }), onClick: handleCreateReport }, t(i18nKeys.REPORTS.NEW.STEPS.CREATE)))))))));
};
var templateObject_1, templateObject_2;
