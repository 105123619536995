import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import styleIdentifiers from './AddNewView.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddNewView(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    return (React.createElement("form", { className: styles('add-new-view'), onSubmit: handleSubmit(onSubmit) },
        React.createElement(Input, { register: register('name'), label: t(i18nKeys.VIEW_NAME), name: "name", noMargin: true }),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { label: t(i18nKeys.ACTIONS.ADD), type: "submit" }))));
}
