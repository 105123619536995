import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import { settingsActions } from 'store/settings/settings.actions';
import { CustomizationSettingsCustomVariablesItem } from './CustomizationSettingsCustomVariablesItem';
var CustomVariablesTable = CustomTable(CustomizationSettingsCustomVariablesItem);
export var CustomizationSettingsCustomVariables = function () {
    var _a;
    var t = useTranslation().t;
    var customVariableList = useSelector(function (state) { return state.settings.customVariableList; });
    var TABLE_HEADERS = [
        {
            key: 'name',
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.NAME),
        },
        {
            key: 'column_name',
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.COLUMN_NAME),
        },
        {
            key: 'var_type',
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.VAR_TYPE),
        },
        {
            key: 'model_type',
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.MODEL_TYPE),
        },
        {
            key: 'default_value',
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    return (React.createElement(CustomVariablesTable, { headers: TABLE_HEADERS, noCheckbox: true, noShadow: true, loading: customVariableList.loading, loaded: customVariableList.loaded, loadFunc: settingsActions.customVariableListPage, items: customVariableList.pages || [], pagination: (_a = customVariableList.metadata) === null || _a === void 0 ? void 0 : _a.pagination }));
};
