import { __assign } from "tslib";
import React from 'react';
import { treatClient } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import ClientForm from './ClientForm';
export default function EditClient(_a) {
    var client = _a.client, callback = _a.callback, sideMenuProps = _a.sideMenuProps;
    var debtor = client;
    var submitClient = function (values) {
        var _a, _b, _c;
        var action = function (_a) {
            var data = _a.data;
            callback === null || callback === void 0 ? void 0 : callback(treatClient(data));
            sideMenuHide();
        };
        values.emails = ((_a = values.emails) === null || _a === void 0 ? void 0 : _a.map(function (value) { return value.email; })) || [];
        values.emails.unshift(values.emails.splice(values.mainEmailIndex, 1)[0]);
        delete values.mainEmailIndex;
        if (!values.locale)
            delete values.locale;
        if (debtor === null || debtor === void 0 ? void 0 : debtor.id) {
            (_b = debtor.phones_attributes) === null || _b === void 0 ? void 0 : _b.forEach(function (phone) {
                var doesPhoneExist = values.phones_attributes.some(function (_a) {
                    var id = _a.id;
                    return id === phone.id;
                });
                if (!doesPhoneExist) {
                    values.phones_attributes.push(__assign(__assign({}, phone), { _destroy: true }));
                }
            });
            (_c = debtor === null || debtor === void 0 ? void 0 : debtor.debtor_bank_accounts_attributes) === null || _c === void 0 ? void 0 : _c.forEach(function (bank_account) {
                var doesBankAccountExist = values.debtor_bank_accounts_attributes.some(function (_a) {
                    var id = _a.id;
                    return id === bank_account.id;
                });
                if (!doesBankAccountExist) {
                    values.debtor_bank_accounts_attributes.push(__assign(__assign({}, bank_account), { _destroy: true }));
                }
            });
            clientActions.update({
                data: __assign(__assign({}, values), { id: debtor.id }),
                callback: action,
            });
        }
        else {
            clientActions.add({
                data: values,
                callback: action,
            });
        }
    };
    var transformVatNumber = function (vat_number) {
        var vatNumber = vat_number;
        if (vatNumber) {
            if (vatNumber.indexOf('BE0') !== -1) {
                vatNumber = vatNumber.slice(3);
                vatNumber = "BE 0".concat(vatNumber);
            }
        }
        else {
            return '';
        }
        return vatNumber;
    };
    var showSideMenu = function () {
        var _a, _b, _c, _d, _e, _f;
        var init = __assign(__assign({ moral_entity: false }, debtor), { debtor_bank_accounts_attributes: ((_a = debtor === null || debtor === void 0 ? void 0 : debtor.debtor_bank_accounts_attributes) === null || _a === void 0 ? void 0 : _a.length) > 0
                ? debtor.debtor_bank_accounts_attributes
                : undefined, phones_attributes: ((_b = debtor === null || debtor === void 0 ? void 0 : debtor.phones_attributes) === null || _b === void 0 ? void 0 : _b.length) > 0 ? debtor.phones_attributes : undefined, vat_number: transformVatNumber(debtor === null || debtor === void 0 ? void 0 : debtor.vat_number), emails: (_c = debtor === null || debtor === void 0 ? void 0 : debtor.emails) === null || _c === void 0 ? void 0 : _c.map(function (email) { return ({ email: email }); }), mainEmailIndex: 0, send_to_all_emails: ((_d = debtor === null || debtor === void 0 ? void 0 : debtor.send_to_all_emails) === null || _d === void 0 ? void 0 : _d.toString()) || 'default', allow_payment_plans: ((_e = debtor === null || debtor === void 0 ? void 0 : debtor.allow_payment_plans) === null || _e === void 0 ? void 0 : _e.toString()) || 'default', sending_media_attributes: ((_f = debtor === null || debtor === void 0 ? void 0 : debtor.sending_media_attributes) === null || _f === void 0 ? void 0 : _f.length) > 0
                ? debtor.sending_media_attributes
                : undefined, recovery_plan_id: (debtor === null || debtor === void 0 ? void 0 : debtor.recovery_plan)
                ? { name: debtor.recovery_plan.name, id: debtor.recovery_plan.id }
                : 'default', default_invoice_template_id: (debtor === null || debtor === void 0 ? void 0 : debtor.default_invoice_template)
                ? {
                    name: debtor.default_invoice_template.name,
                    id: debtor.default_invoice_template.id,
                }
                : 'default', default_credit_note_template_id: (debtor === null || debtor === void 0 ? void 0 : debtor.default_credit_note_template)
                ? {
                    name: debtor.default_credit_note_template.name,
                    id: debtor.default_credit_note_template.id,
                }
                : 'default', days_between_reminder: debtor === null || debtor === void 0 ? void 0 : debtor.days_between_reminder, minimum_saldo_sending: debtor === null || debtor === void 0 ? void 0 : debtor.minimum_saldo_sending });
        sideMenuShow(__assign(__assign({ unmount: true, noBackgroundClose: true }, sideMenuProps), { content: React.createElement(ClientForm, { onSubmit: submitClient, initialValues: init }) }));
    };
    if ((debtor === null || debtor === void 0 ? void 0 : debtor.id) && !debtor.debtor_bank_accounts_attributes) {
        clientActions.detail({
            id: debtor.id,
            callback: function (_a) {
                var data = _a.data;
                debtor = treatClient(data);
                showSideMenu();
            },
        });
    }
    else {
        showSideMenu();
    }
}
