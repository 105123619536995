import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Card from 'shared/components/Card';
import { useSaveCompanyConfiguration } from 'shared/hooks';
import GeneralInfoForm from './GeneralInfoForm';
import styleIdentifiers from './GeneralInfo.scss';
var styles = classNames.bind(styleIdentifiers);
export default function GeneralInfo(_a) {
    var onValidStep = _a.onValidStep;
    var t = useTranslation().t;
    var saveCompanyConfiguration = useSaveCompanyConfiguration().saveCompanyConfiguration;
    var onSubmit = function (data) {
        saveCompanyConfiguration(data, { onSuccess: function () { return onValidStep(); } });
    };
    return (React.createElement("div", { className: styles('general-info') },
        React.createElement(Card, { title: t(i18nKeys.ONBOARDING[4].TITLE), className: styles('small') },
            React.createElement(GeneralInfoForm, { onSubmit: onSubmit }))));
}
