import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { ActionIcon, Group, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { isAccountingIntegration } from '../Integrations.utils';
import { useSynchroniseIntegration } from './Integration.hooks';
import { ResetButton } from './ResetButton';
import styleIdentifiers from './IntegrationHeader.scss';
var styles = classNames.bind(styleIdentifiers);
export var IntegrationHeader = function (_a) {
    var name = _a.name, goBack = _a.goBack, children = _a.children, _b = _a.isActive, isActive = _b === void 0 ? false : _b, _c = _a.isConnected, isConnected = _c === void 0 ? false : _c, props = __rest(_a, ["name", "goBack", "children", "isActive", "isConnected"]);
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var history = useHistory();
    var _e = useSynchroniseIntegration(), synchronize = _e.synchronize, canSynchronize = _e.canSynchronize, nextPossibleSynchronizeDate = _e.nextPossibleSynchronizeDate, lastImportDate = _e.lastImportDate;
    return (React.createElement("header", __assign({ className: styles('header') }, props),
        React.createElement(Group, { mb: "xl" },
            React.createElement(ActionIcon, { onClick: function () { return history.push('/settings/integrations'); }, size: "md", variant: "light" },
                React.createElement(IconArrowLeft, { stroke: 1.5 })),
            React.createElement(Text, { size: "lg", fw: "bold" }, name)),
        React.createElement("div", { className: styles('synchronize') },
            isActive && (React.createElement(React.Fragment, null,
                lastImportDate && (React.createElement("p", null, t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                    date: formattedDate(lastImportDate, currentLang, 'LLL'),
                }))),
                React.createElement(Tooltip, { icon: IconName.INFO, iconClassName: styles('icon') },
                    t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION),
                    !canSynchronize && (React.createElement("span", { className: styles('info') }, t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                        date: formattedDate(nextPossibleSynchronizeDate, currentLang, 'LLL'),
                    })))),
                React.createElement(Button, { disabled: !canSynchronize, onClick: synchronize, iconRight: IconName.SYNC, label: t(i18nKeys.SYNCHRONIZE), noMargin: true, color: ButtonColor.WHITE }))),
            isAccountingIntegration(name) && isConnected ? React.createElement(ResetButton, null) : null)));
};
