import { __read } from "tslib";
import React, { useState } from 'react';
import { useDeleteExternalMail, useUpdateExternalMail } from 'api';
import { ExternalMailStatus } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Badge } from '@mantine/core';
import { ExternalMailAction } from '../ExternalMailActions/ExternalMailAction';
import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';
import styleIdentifiers from './ExternalMailList.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailListItem = function (_a) {
    var item = _a.item, refetch = _a.refetch, action = _a.action;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var history = useHistory();
    var _c = __read(useState(false), 2), hover = _c[0], setHover = _c[1];
    var id = item.id, checked = item.checked, _d = item.attributes, date = _d.date, subject = _d.subject, from_mail = _d.from_mail, from_name = _d.from_name, read = _d.read, status = _d.status, debtor = _d.debtor, has_attachments = _d.has_attachments, reply_count = _d.reply_count;
    var updateExternalMailMutation = useUpdateExternalMail().mutate;
    var deleteExternalMailMutation = useDeleteExternalMail().mutate;
    var goToDetails = function () {
        history.push("/mails/".concat(id));
    };
    var onUpdateOptions = {
        onSuccess: function () {
            refetch();
            dialogHide(DialogShowId.CUSTOM);
        },
    };
    var handleAssign = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
            children: (React.createElement(ExternalMailAction, { externalMail: item, onSubmit: function (data) {
                    return updateExternalMailMutation({ id: id, data: data }, onUpdateOptions);
                } },
                React.createElement(ExternalMailActionDebtor, null))),
        });
    };
    var handleChangeStatus = function () {
        var newStatus = status === ExternalMailStatus.processed
            ? ExternalMailStatus.unprocessed
            : ExternalMailStatus.processed;
        updateExternalMailMutation({
            id: id,
            data: { status: newStatus },
        }, onUpdateOptions);
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_EXTERNAL_MAIL), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteExternalMailMutation({ id: id }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    var today = new Date().setHours(0, 0, 0, 0);
    var parsedDate = new Date(date);
    var dayDate = function () {
        if (parsedDate - today < 0)
            return 'DD/MM/YY - ';
        return '';
    };
    return (React.createElement("tr", { className: styles('mails-list-item', read ? 'read' : 'unread', reply_count > 0 && "reply-".concat(reply_count), hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); } },
        React.createElement("td", null, action && (React.createElement("div", { onClick: action, className: styles('box', 'checkbox', checked && 'checked') }))),
        React.createElement("td", { onClick: goToDetails, className: styles('subject'), "data-reply": reply_count },
            React.createElement("span", { title: t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS), className: styles('attachments'), role: "img", "aria-label": "paper-clip" }, has_attachments ? React.createElement(React.Fragment, null, "\uD83D\uDCCE") : React.createElement(React.Fragment, null, "\u2800\u2800")),
            subject),
        React.createElement("td", null,
            from_name && (React.createElement(React.Fragment, null,
                React.createElement("span", null, from_name),
                React.createElement("br", null))),
            React.createElement("a", { href: "mailto:".concat(from_mail) }, from_mail),
            debtor && (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement(NavLink, { to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name)))),
        React.createElement("td", { onClick: goToDetails },
            React.createElement(Badge, { variant: "light", color: status === 'processed' ? 'green' : 'blue' }, t(i18nKeys.MAILS.STATUS[status.toUpperCase()]))),
        React.createElement("td", { onClick: goToDetails },
            dayDate() && (React.createElement(DateItem, { date: parsedDate, lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') })),
            React.createElement(DateItem, { date: parsedDate, lg: currentLang, format: "HH:mm", className: styles('value', 'blue', 'bigger') })),
        React.createElement("td", null,
            React.createElement("div", { className: styles('actions') },
                React.createElement("span", { onClick: handleAssign, title: t(i18nKeys.TASK.ACTIONS.ASSIGN) },
                    React.createElement(Icon, { name: IconName.USER, size: "18px", className: styles('action') })),
                React.createElement("span", { onClick: handleChangeStatus, title: t(i18nKeys.EXTERNAL_MAIL.ACTIONS["".concat(status === ExternalMailStatus.processed ? 'UN' : '', "PROCESS")]) },
                    React.createElement(Icon, { name: IconName[status === ExternalMailStatus.processed ? 'SIMPLE_REMOVE' : 'CHECK'], size: "18px", className: styles('action') })),
                React.createElement("span", { onClick: handleDelete, title: t(i18nKeys.DELETE) },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') }))))));
};
