import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import { Button, UploadZone } from 'shared/io';
import styleIdentifiers from './InputFile.scss';
var styles = classNames.bind(styleIdentifiers);
export var InputFile = function (_a) {
    var accept = _a.accept, onChange = _a.onChange, name = _a.name, label = _a.label, color = _a.color, className = _a.className, noShadow = _a.noShadow, props = __rest(_a, ["accept", "onChange", "name", "label", "color", "className", "noShadow"]);
    return (React.createElement("div", { className: styles(styles('button-file-input')) },
        React.createElement(Button, { label: label, color: color, noMargin: true, noShadow: noShadow }),
        React.createElement(UploadZone, __assign({ className: styles('file-input', className), onChange: onChange, accept: accept, noResize: true, noMargin: true, noPreview: true }, props))));
};
