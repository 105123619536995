import classNames from 'classnames/bind';
import React from 'react';
import { PontoForm } from 'shared/components/Integrations';
import styleIdentifiers from './Ponto.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Ponto(_a) {
    var onSubmit = _a.onSubmit;
    return (React.createElement("div", { className: styles('Ponto') },
        React.createElement(PontoForm, { onSubmit: onSubmit })));
}
