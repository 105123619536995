import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { lowerFirst } from 'lodash-es';
import moment from 'moment';
import { useLoadAgedBalanceCompact, useRefreshBalance } from 'shared/hooks';
import { ActionIcon, Group, Text, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
export var UpdatedAt = function () {
    var t = useTranslation().t;
    var agedBalanceCompact = useLoadAgedBalanceCompact().agedBalanceCompact;
    var refreshBalance = useRefreshBalance().refreshBalance;
    if (agedBalanceCompact == null)
        return null;
    return (React.createElement(Group, null,
        React.createElement(Text, { size: "sm", c: "gray.5", fs: "italic" },
            t(i18nKeys.ANALYTICS.LAST_UPDATED),
            lowerFirst(moment(agedBalanceCompact.computedAt).calendar())),
        React.createElement(Tooltip, { withArrow: true, label: t(i18nKeys.ANALYTICS.REFRESH_BALANCE), color: "gray.6", arrowSize: 7 },
            React.createElement(ActionIcon, { onClick: function () { return refreshBalance(); }, color: "gray.5", size: "sm", variant: "light" },
                React.createElement(IconRefresh, null)))));
};
