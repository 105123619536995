import { __read } from "tslib";
import '!style-loader!css-loader!react-slidedown/lib/slidedown.css';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import styleIdentifiers from './FormSection.scss';
var styles = classNames.bind(styleIdentifiers);
export default function FormSection(_a) {
    var children = _a.children, startClosed = _a.startClosed, isOpen = _a.isOpen, title = _a.title, onAdd = _a.onAdd, onToggle = _a.onToggle, className = _a.className, noLine = _a.noLine, checkbox = _a.checkbox;
    var _b = __read(useState(!startClosed), 2), open = _b[0], setOpen = _b[1];
    var _c = __read(useState(false), 2), firstClose = _c[0], setfirstClose = _c[1];
    useEffect(function () {
        if (isOpen !== undefined) {
            setOpen(isOpen);
        }
    }, [isOpen]);
    var toggle = function (forceOpen) {
        onToggle && onToggle(forceOpen || !open);
        setfirstClose(true);
        setOpen(forceOpen || !open);
    };
    return (React.createElement("div", { className: styles(className, 'form-section') },
        React.createElement("div", { className: styles('title') },
            React.createElement("div", { className: styles('name'), onClick: function () { return toggle(); } }, title),
            !noLine && React.createElement("div", { className: styles('line'), onClick: function () { return toggle(); } }),
            React.createElement("div", { className: styles('actions') },
                onAdd && (React.createElement(Icon, { name: IconName.PLUS, onClick: function () {
                        toggle(true);
                        onAdd();
                    }, className: styles('action') })),
                checkbox ? (React.createElement(Checkbox, { checked: open, onChange: toggle, noMargin: true })) : (React.createElement(Icon, { name: IconName.ARROW_BOTTOM_ROUNDED, onClick: function () { return toggle(); }, className: styles('icon', open && 'open') })))),
        React.createElement(SlideDown, { className: styles(!firstClose && 'react-slidedown-open') }, open ? children : null)));
}
