import { __assign } from "tslib";
import { removeAttributes } from 'shared/utils/view';
export var treatTemplate = function (item) {
    var newItem = removeAttributes(item);
    if (newItem.email_template_bodies) {
        newItem.email_template = newItem.email_template_bodies.map(function (template) {
            return removeAttributes(template);
        });
        delete newItem.email_template_bodies;
    }
    if (newItem.letter_template_bodies) {
        newItem.letter_template = newItem.letter_template_bodies.map(function (template) {
            return removeAttributes(template);
        });
        delete newItem.letter_template_bodies;
    }
    return newItem;
};
export var treatRecoveryPlan = function (item) {
    var newItem = removeAttributes(item);
    if (newItem.recovery_steps) {
        newItem.recovery_steps = newItem.recovery_steps.map(function (recovery_step) {
            var item = removeAttributes(recovery_step);
            if (item.template) {
                item.template = removeAttributes(item.template);
            }
            return item;
        });
    }
    return newItem;
};
export var recoveryPlanSerializer = function (item, oldPlan) {
    var newPlan = __assign({}, item);
    newPlan.recovery_steps_attributes = newPlan.recovery_steps.slice();
    newPlan.recovery_steps_attributes = newPlan.recovery_steps_attributes.map(function (step) { return (__assign({}, step)); });
    var stepsIds = oldPlan === null || oldPlan === void 0 ? void 0 : oldPlan.recovery_steps.map(function (step) { return step.id; });
    newPlan.recovery_steps_attributes = newPlan.recovery_steps_attributes.map(function (recoveryStep, index) {
        if (oldPlan && recoveryStep.id) {
            stepsIds.splice(stepsIds.findIndex(function (id) { return id === recoveryStep.id; }), 1);
        }
        if (recoveryStep.template) {
            recoveryStep.template_id = recoveryStep.template.id;
            delete recoveryStep.template;
        }
        recoveryStep.order = index;
        delete recoveryStep.invoices_count;
        delete recoveryStep.fee_step_reached;
        return recoveryStep;
    });
    if (oldPlan) {
        stepsIds.forEach(function (id, index) {
            newPlan.recovery_steps_attributes.push({
                id: id,
                _destroy: true,
                order: newPlan.recovery_steps_attributes.length,
            });
        });
    }
    delete newPlan.recovery_steps;
    return newPlan;
};
