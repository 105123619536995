var _a, _b;
import { PlanType, StepType } from 'api/models';
import { WorkflowStepsTarget } from '../Workflows.constants';
export var TEMPLATES = (_a = {},
    _a[PlanType.payment] = (_b = {},
        _b[WorkflowStepsTarget.BEFORE_DUE_DATE] = [
            {
                value: StepType.preventive_reminder,
                single: true,
                first: true,
                last: true,
            },
        ],
        _b[WorkflowStepsTarget.AFTER_DUE_DATE] = [
            {
                value: StepType.first_reminder,
                single: true,
                first: true,
            },
            {
                value: StepType.intermediate_reminder,
            },
            {
                value: StepType.last_reminder,
                single: true,
                last: true,
            },
        ],
        _b),
    _a);
