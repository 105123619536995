import { __assign, __generator, __rest } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { clientActions, clientConstants as events } from 'store/client/client.actions';
import { apiCall, sendApi } from '../sagas';
function ClientAdd(action) {
    var payload;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                if (payload.data.vat_number && payload.data.vat_number.indexOf('_') !== -1) {
                    payload.data.vat_number = '';
                }
                return [4 /*yield*/, apiCall({
                        api: api.client.add,
                        data: payload.data || payload,
                        callback: payload.callback,
                        success: 'CLIENT.SUCCESSFULLY_ADD',
                        actionRes: clientActions.addRes,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function ClientUpdate(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                data = payload.data;
                if (data.vat_number && data.vat_number.indexOf('_') !== -1) {
                    data.vat_number = '';
                }
                return [4 /*yield*/, apiCall({
                        api: api.client.update,
                        id: data.id,
                        data: data,
                        callback: payload.callback,
                        success: 'CLIENT.SUCCESSFULLY_UPDATE',
                        actionRes: clientActions.updateRes,
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function clientSearch(_a) {
    var search;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                search = (payload === null || payload === void 0 ? void 0 : payload.page_limit)
                    ? {
                        name: payload.name,
                        page_limit: payload.page_limit || 20,
                    }
                    : {
                        name: payload,
                    };
                return [4 /*yield*/, apiCall({
                        api: api.client.list,
                        data: search,
                        noFeedback: true,
                        noLoading: true,
                        actionRes: clientActions.searchRes,
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function clientComment(action) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: api.client.addComment,
                    id: action.payload.id,
                    data: action.payload,
                    callback: function () {
                        clientActions.detail({
                            id: action.payload.id,
                            noReset: true,
                            noLoading: true,
                        });
                        if (action.payload.callback) {
                            action.payload.callback();
                        }
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function ClientWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.listPage.request, sendApi(api.client.list, clientActions.listPageRes, function (_a) {
                        if (_a === void 0) { _a = {}; }
                        var callback = _a.callback, payload = __rest(_a, ["callback"]);
                        return ({
                            data: __assign({}, payload),
                            noLoading: true,
                            callback: callback,
                        });
                    }))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.detail.request, sendApi(api.client.detail, clientActions.detailRes))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.batchAction.request, sendApi(api.client.batchAction, clientActions.batchActionRes))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.assignToAccManager.request, sendApi(api.client.assignToAccManager))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.assignMultipleToUser.request, sendApi(api.client.assignMultipleToUser))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.add.request, ClientAdd)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.update.request, ClientUpdate)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.delete.request, sendApi(api.client.delete, clientActions.deleteRes))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.search.request, clientSearch)];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getFinancialData.request, sendApi(api.client.getFinancialData))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.comment.request, clientComment)];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.pauseDebtor.request, sendApi(api.client.pauseDebtor))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.deleteMultiple.request, sendApi(api.client.deleteMultiple))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.pauseMultiple.request, sendApi(api.client.pauseMultiple))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getAllToConfirm.request, sendApi(api.client.import.getAllToConfirm, clientActions.getAllToConfirmRes, function (payload) { return ({
                        data: __assign({}, payload),
                        noLoading: true,
                    }); }))];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getToConfirm.request, sendApi(api.client.import.getToConfirm))];
            case 16:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.deleteClientToConfirm.request, sendApi(api.client.import.deleteToConfirm, clientActions.deleteClientToConfirmRes))];
            case 17:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.validateAllImportedClients.request, sendApi(api.client.import.validateAll, clientActions.validateAllImportedClientsRes))];
            case 18: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 19:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(ClientWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
