import classNames from 'classnames/bind';
import React from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import styleIdentifiers from './RecoveryPlan.scss';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryPlanAddStep = function (_a) {
    var onClick = _a.onClick, text = _a.text;
    return (React.createElement("div", { className: styles('add-circle'), onClick: onClick },
        React.createElement(Icon, { name: IconName.PLUS }),
        text && (React.createElement(React.Fragment, null,
            ' ',
            React.createElement("span", { className: styles('label') }, text)))));
};
