import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import HTML from 'shared/components/HTML/HTML';
import { Button } from 'shared/io';
import styleIdentifiers from './IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var IntegrationForm = function (_a) {
    var integration = _a.integration, form = _a.form, onSubmit = _a.onSubmit, summary = _a.summary, children = _a.children, connectUrl = _a.connectUrl;
    var t = useTranslation().t;
    var errorMessage = qs.parse(location.search).errorMessage;
    var errors = !Array.isArray(errorMessage) ? [errorMessage] : errorMessage;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('integration-form'), onSubmit: onSubmit },
            summary && React.createElement(HTML, { className: styles('summary'), html: t(summary) }),
            children,
            !!errors.length && (React.createElement("div", { className: styles('errors-messages') }, errors.map(function (e, i) { return (React.createElement("div", { key: "error-".concat(i) },
                React.createElement("span", { className: styles('error-message') }, e))); }))),
            React.createElement("div", { className: styles('button-wrapper') }, connectUrl ? (React.createElement(Button, { className: styles('connect-button'), onClick: function () { return window.open(connectUrl, '_blank'); }, label: t(i18nKeys.FORM.INTEGRATION.CONNECT_ACCOUNT, {
                    text: integration,
                }) })) : (React.createElement(Button, { label: t(i18nKeys.FORM.VALIDATE), type: "submit" }))))));
};
