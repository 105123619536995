import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import moment from 'moment';
import Conversation from 'shared/components/ActivityDetail/Conversation';
import { EmailDeliveryStatusCompact } from 'shared/components/ActivityDetail/EmailDeliveryStatusCompact';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import ToHandleDetail from '..';
import styleIdentifiers from './InvoiceTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
var activityType = {
    payment: 'wallet',
    comment: 'comment',
    callcenter: 'phone-call',
    pause: 'pause',
    lawyer: 'law',
    bailiff: 'balance',
    formal_notice: 'formal-notice',
    preference: 'sending-options',
    reminders: 'send',
    import: 'import-file',
    warning: 'alert-circle-exc',
    task: 'info',
    time: 'later',
    valid: 'check',
    update: 'customization',
};
export default function InvoiceTimeline(_a) {
    var activities = _a.activities, nextAction = _a.nextAction, metadata = _a.metadata, seeMore = _a.seeMore, invoiceId = _a.invoiceId, onClickActivity = _a.onClickActivity;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var downloadDocument = function (item) { return function () {
        invoiceActions.document(__assign({ id: item.tracked_id, type: item.document_type }, (item.document_id ? { document_id: item.document_id } : {})));
    }; };
    var showCreditNote = function (item) { return function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { isCreditNote: true, id: item.linked_credit_note_id, modal: true }),
        });
    }; };
    var showConversation = function (activity) { return function () {
        var title = t((activity.activity_type === 'lawyer' && i18nKeys.INVOICE.LAWYER_CASE) ||
            (activity.activity_type === 'callcenter' && i18nKeys.INVOICE.CALLCENTER_CASE) ||
            (activity.tracked_type === 'Invoice' && i18nKeys.ACTIVITIES.UPDATE_STATUS_INVOICE) ||
            i18nKeys.NO_TEXT);
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: title,
            children: (React.createElement(Conversation, { className: styles('conversation'), conversation: activity.invoice_feedback, invoiceId: invoiceId, onSubmit: function () { } })),
        });
    }; };
    var getGapDate = function (index) {
        if (index + 1 !== activities.length) {
            var currentActivity = moment(activities[index].date).startOf('day');
            var nextActivity = activities[index + 1].date;
            if (!currentActivity.isSame(nextActivity, 'day')) {
                return "+ ".concat(t(i18nKeys.DAYS, {
                    count: Math.ceil(moment.duration(currentActivity.diff(nextActivity)).asDays()),
                }));
            }
        }
        return '';
    };
    if (activities == null)
        return null;
    return (React.createElement(Card, { title: "".concat(t(i18nKeys.INVOICE.ACTIVITIES)), className: styles('invoice-timeline'), classContent: styles('card-body') },
        nextAction && (React.createElement("div", { className: styles('activity', 'next-action') },
            React.createElement("div", { className: styles('info') },
                React.createElement("div", { className: styles('author') }, t(i18nKeys.INVOICE.NEXT_STEP)),
                React.createElement("div", null, formattedDate(new Date(), currentLang))),
            React.createElement("div", { className: styles('timeline') },
                React.createElement("div", { className: styles('circle') }),
                React.createElement("div", { className: styles('line') })),
            React.createElement("div", { className: styles('description') },
                React.createElement(HTML, { html: nextAction })))),
        activities.map(function (activity, index) {
            return (React.createElement("div", { key: activity.id, className: styles('activity') },
                React.createElement("div", { className: styles('info') },
                    React.createElement("div", { className: styles('author') }, activity.author),
                    React.createElement("div", null, formattedDate(activity.date, currentLang, 'DD/MM/YYYY, LT'))),
                React.createElement("div", { className: styles('timeline') },
                    React.createElement("div", { className: styles('circle') },
                        React.createElement(Icon, { name: activityType[activity.activity_type] || 'invoice' })),
                    React.createElement("div", { className: styles('line') },
                        React.createElement("span", { className: styles('gap-date') }, getGapDate(index)))),
                React.createElement("div", { className: styles('description', onClickActivity != null && 'clickable'), onClick: function () { return onClickActivity === null || onClickActivity === void 0 ? void 0 : onClickActivity(activity.id); } },
                    React.createElement(HTML, { html: activity.description }),
                    activity.comment && (React.createElement("span", null,
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMENT)),
                        " :",
                        ' ',
                        React.createElement("span", { className: styles('comment') }, activity.comment))),
                    activity.reason && (React.createElement("span", null,
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.REASON)),
                        " :",
                        ' ',
                        React.createElement("span", { className: styles('comment') }, activity.reason))),
                    activity.end_pause_at && (React.createElement("div", null,
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.END_OF_PAUSE)),
                        " :",
                        ' ',
                        React.createElement(DateItem, { lg: currentLang, date: activity.end_pause_at }))),
                    activity.payment_information && (React.createElement("div", { className: styles('payment-info') },
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.AMOUNT),
                                " : "),
                            React.createElement(Amount, { localizedValue: activity.payment_information.amount, value: activity.payment_information.localized_money_object.amount, suffix: activity.payment_information.currency })),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.DATE),
                                " : "),
                            React.createElement(DateItem, { lg: currentLang, date: activity.payment_information.date })),
                        React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.MODALITY),
                                " : "),
                            React.createElement("span", null, activity.payment_information.modality)),
                        activity.payment_information.notes && (React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.NOTES),
                                " : "),
                            React.createElement("span", null, activity.payment_information.notes))),
                        activity.payment_information.counterparty_name && (React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.ACTIVITIES.COUNTERPART),
                                " : "),
                            React.createElement("span", null, activity.payment_information.counterparty_name))),
                        activity.payment_information.external_id && (React.createElement("div", null,
                            React.createElement("b", null,
                                t(i18nKeys.ACTIVITIES.EXTERNAL_ID),
                                " : "),
                            React.createElement("span", null, activity.payment_information.external_id))))),
                    React.createElement("div", { className: styles('more') },
                        activity.linked_credit_note_id && (React.createElement(Button, { className: styles('show-conversation'), iconRight: IconName.EYE, onClick: function (e) {
                                e.stopPropagation();
                                showCreditNote(activity)();
                            }, label: t(i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE), small: true, noMargin: true, color: ButtonColor.WHITE })),
                        activity.invoice_feedback && (React.createElement(Button, { className: styles('show-conversation'), iconRight: IconName.EYE, onClick: function (e) {
                                e.stopPropagation();
                                showConversation(activity)();
                            }, label: t(i18nKeys.SHOW_CONVERSATION), small: true, noMargin: true, color: ButtonColor.WHITE })),
                        activity.email_statuses && (React.createElement(EmailDeliveryStatusCompact, { statuses: activity.email_statuses })),
                        activity.has_document && (React.createElement(Icon, { name: IconName.PDF, onClick: function (e) {
                                e.stopPropagation();
                                downloadDocument(activity)();
                            }, className: styles('download-document'), title: t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT) }))))));
        }),
        (metadata === null || metadata === void 0 ? void 0 : metadata.pagination.current_page) !== (metadata === null || metadata === void 0 ? void 0 : metadata.pagination.last_page) && (React.createElement("div", { className: styles('see-more'), onClick: seeMore },
            t(i18nKeys.SEE_MORE),
            " ",
            React.createElement(Icon, { name: IconName.ARROW_BOTTOM_ROUNDED })))));
}
