import { __assign, __read } from "tslib";
import React, { useEffect, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider } from 'react-hook-form';
import FormSection from 'shared/forms/FormSection';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import styleIdentifiers from './ClientFiltersForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientFiltersForm = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var _b = __read(useReducer(reducerState, {
        filters: [],
        filtersSearched: [],
    }), 2), _c = _b[0], filters = _c.filters, filtersSearched = _c.filtersSearched, setState = _b[1];
    var _d = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: [],
    }), form = _d.form, submit = _d.submit, resetForm = _d.resetForm;
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = function () {
                resetForm();
            };
        }
    }, [filterObject, resetForm]);
    useEffect(function () {
        appActions.getFilters({
            data: {
                view: 'debtors',
            },
            callback: function (_a) {
                var data = _a.data;
                var newFilters = data.map(function (item) { return removeAttributes(item); });
                setState({
                    filters: newFilters,
                    filtersSearched: newFilters,
                });
            },
        });
    }, []);
    var register = form.register, handleSubmit = form.handleSubmit, watch = form.watch, setValue = form.setValue;
    var formData = watch();
    var searchText = watch('filter');
    useEffect(function () {
        setState({
            filtersSearched: filters.filter(function (filter) { return filter.name.indexOf(searchText) !== -1; }),
        });
    }, [searchText]);
    return form ? (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('ClientFiltersForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('search-filter') },
                React.createElement(Input, { register: register('filter'), noMargin: true, type: "text", label: t(i18nKeys.FORM.RESEARCH) })),
            React.createElement("div", { className: styles('container-fields') }, filtersSearched.map(function (filter) { return (React.createElement(FormSection, { startClosed: true, key: filter.id, title: filter.name_translated, className: styles('filter-item') },
                React.createElement("div", { className: styles('filter-container') }, filter.filter_type === 'string' ? (React.createElement(Input, { register: register(filter.name), noMargin: true, withBorder: true, shadow: true, type: "text", placeholder: filter.name_translated, onValueChanged: submit })) : filter.filter_type === 'select' ? (React.createElement(Controller, { defaultValue: "", name: filter.name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), removeAll: t(i18nKeys.CLIENT.FILTER.ALL), keyText: "description", keyValue: "value", size: "small", name: filter.name, noMargin: true, noBorder: true, withBorder: true, shadow: true, items: [], onValueChanged: submit })); } })) : filter.filter_type === 'date' ? (React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "".concat(filter.name, "_start"), endName: "".concat(filter.name, "_end"), placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, handleChange: function (value) {
                        setValue("".concat(filter.name, "_start"), value);
                        submit();
                    }, handleEndChange: function (value) {
                        setValue("".concat(filter.name, "_end"), value);
                        submit();
                    } })) : filter.filter_type === 'number' ? (React.createElement(AmountFields, { className: styles('spacing'), name: filter.name, onChange: submit })) : filter.filter_type === 'boolean' ? (React.createElement(Controller, { defaultValue: "", name: filter.name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), removeAll: t(i18nKeys.CLIENT.FILTER.ALL), keyText: "description", keyValue: "value", size: "small", name: filter.name, noMargin: true, noBorder: true, withBorder: true, shadow: true, items: [
                            {
                                description: t(i18nKeys.YES),
                                value: true,
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], onValueChanged: submit })); } })) : null))); }))))) : null;
};
