import { Notification } from 'api/models';
import React from 'react';
export default function EmailRendering(_a) {
    var data = _a.data;
    var description = data.description, value = data.value, notifications = data.notifications;
    var isDebtor = notifications.includes(Notification.Debtor);
    return (React.createElement("div", null,
        !isDebtor && (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                React.createElement("b", null, description)),
            React.createElement("br", null))),
        React.createElement("span", null, value)));
}
