import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLoadLightUsers } from 'shared/hooks';
import { Button, ButtonColor, CustomSelect } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import UserInfo from '../UserInfo';
import styleIdentifiers from './ChooseCollaboratorModal.scss';
var styles = classNames.bind(styleIdentifiers);
// TODO: Too close in functionality to AccountManagerSelector. Refactor to reuse code.
var ChooseCollaboratorModal = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var isInvoiceLoading = useGetInvoiceState().isLoading;
    var isClientLoading = useGetClientState().isLoading;
    var isLoading = isInvoiceLoading || isClientLoading;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: { account_manager_id: initialValues === null || initialValues === void 0 ? void 0 : initialValues.id },
    });
    return (React.createElement("div", { className: styles('choose-collaborator-modal') },
        React.createElement(FormProvider, __assign({}, form),
            React.createElement(Controller, { defaultValue: "", name: "account_manager_id", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('select'), itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({ inline: true }, props)); }, keyValue: "id", items: users, name: "account_manager_id" })); } })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.DELETE_ACCOUNT_MANAGER), color: ButtonColor.GREY, onClick: function () {
                    onSubmit({ account_manager_id: null });
                } }),
            React.createElement(Button, { isLoading: isLoading, label: t(i18nKeys.CONFIRM), onClick: function () {
                    onSubmit(form.getValues());
                } }))));
};
export default ChooseCollaboratorModal;
