import { __read } from "tslib";
import { useDeleteUnvalidatedInvoice } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import StatusItem from 'shared/components/StatusItem';
import { Button, ButtonColor, DropdownDirection } from 'shared/io';
import styleIdentifiers from './invoiceListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceListItem(_a) {
    var item = _a.item, noCheckbox = _a.noCheckbox, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = __read(useState(false), 2), hover = _c[0], setHover = _c[1];
    var _d = __read(useState(false), 2), isDeleting = _d[0], setIsDeleting = _d[1];
    var history = useHistory();
    var deleteUnvalidatedInvoice = useDeleteUnvalidatedInvoice().mutate;
    var id = item.id, _e = item.attributes, debtor = _e.debtor, reference = _e.reference, due_date = _e.due_date, issue_date = _e.issue_date, days_late = _e.days_late, step_collection = _e.step_collection, total_tvac = _e.total_tvac, localized_money_object = _e.localized_money_object, currency = _e.currency, remaining_balance = _e.remaining_balance, csv_updated = _e.csv_updated, status = _e.status;
    var goDetail = function () {
        if (!isDeleting && !isLoading) {
            history.push("/invoice/to-confirm/".concat(id));
        }
    };
    var deleteInvoice = function (e) {
        e.stopPropagation();
        if (!isDeleting && !isLoading) {
            e.stopPropagation();
            setIsDeleting(true);
            deleteUnvalidatedInvoice({ id: id }, {
                onError: function () {
                    setIsDeleting(false);
                },
                onSuccess: function () {
                    refetch();
                },
            });
        }
    };
    return (React.createElement("tr", { className: styles('InvoiceListItem', hover && 'hover', item && status), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, onClick: goDetail },
        React.createElement("td", { className: styles('CheckBoxContainer', noCheckbox && 'small') }),
        React.createElement("td", { className: styles('InvoiceNumber') }, reference),
        React.createElement("td", { className: styles('Date') },
            React.createElement(DateItem, { lg: currentLang, date: issue_date })),
        React.createElement("td", { className: styles('Date') },
            React.createElement(DateItem, { lg: currentLang, date: due_date })),
        React.createElement("td", { className: styles('Client') }, debtor && debtor.attributes.full_name),
        React.createElement("td", { className: styles('Delay') },
            days_late > 1 && t(i18nKeys.DAYS, { count: days_late }),
            days_late === 1 && t(i18nKeys.ONE_DAY),
            !days_late && '-'),
        React.createElement("td", { className: styles('StatusContainer') },
            React.createElement(StatusItem, { status: status, step: step_collection })),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { localizedValue: total_tvac, value: localized_money_object.total_tvac, suffix: currency, direction: DropdownDirection.TOP_LEFT })),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { localizedValue: remaining_balance, value: localized_money_object.remaining_balance, suffix: currency, direction: DropdownDirection.TOP_LEFT })),
        React.createElement("td", { className: styles('Actions') }, !csv_updated && (React.createElement(Button, { onClick: deleteInvoice, color: ButtonColor.GREY, label: t(i18nKeys.DELETE), noMargin: true, small: true, disabled: isLoading || isDeleting })))));
}
