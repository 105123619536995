import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BankAccount from 'shared/components/BankAccount';
import { Icon, IconName } from 'shared/components/Icon';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import { currencySymbol } from 'shared/utils/normalization';
import styleIdentifiers from './paymentsFiltersForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentsFiltersForm(_a) {
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: [],
    }), submit = _b.submit, resetForm = _b.resetForm, form = _b.form, formData = _b.formData;
    var setValue = form.setValue, register = form.register;
    var bank_account_ids = [];
    var bankAccount = function (_a) {
        var _b;
        var item = _a.item;
        return (React.createElement("div", { className: styles('bank-account', (bank_account_ids === null || bank_account_ids === void 0 ? void 0 : bank_account_ids.indexOf(item.id)) > -1 && 'selected') },
            React.createElement(BankAccount, { value: item.iban, image: item.bic, small: true }),
            ((_b = formData.bank_account_ids) === null || _b === void 0 ? void 0 : _b.indexOf(item.id)) > -1 && (React.createElement(Icon, { name: IconName.CHECK, className: styles('check-icon'), size: "20px" }))));
    };
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = function () {
                resetForm();
            };
        }
    }, [filterObject]);
    useEffect(function () {
        submit === null || submit === void 0 ? void 0 : submit();
    }, [formData.bank_account_ids]);
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('PaymentsFiltersForm'), onSubmit: function (e) {
                e.preventDefault();
                submit();
            } },
            React.createElement("div", { className: styles('container-fields') },
                React.createElement("div", { className: styles('fields') },
                    React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "start_date", endName: "end_date", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, label: t(i18nKeys.DATE), handleChange: function (value) {
                            setValue('start_date', value);
                            submit();
                        }, handleEndChange: function (value) {
                            setValue('end_date', value);
                            submit();
                        } }),
                    React.createElement(AmountFields, { onChange: submit, className: styles('spacing'), operatorName: "operator", name: "amount", label: t(i18nKeys.AMOUNT_CURRENCY, {
                            text: currencySymbol(undefined, company),
                        }) }),
                    React.createElement("div", { className: styles('medium', 'spacing') },
                        React.createElement(Input, { register: register('communication'), className: styles('spacing'), noMargin: true, shadow: true, withBorder: true, label: t(i18nKeys.COMMUNICATION), onValueChanged: submit })),
                    React.createElement("div", { className: styles('large', 'spacing') },
                        React.createElement(Controller, { defaultValue: "", name: "bank_account_ids", render: function () { return (React.createElement(CustomSelect, { name: "bank_account_ids", onChange: function (value) { return value; }, className: styles('select-wrapper'), selectClassName: styles('bank-account-select', 'spacing'), valueClassName: styles('bank-account-select-value'), label: t(i18nKeys.COMMON.BANK_ACCOUNT), noMargin: true, removeAll: t(i18nKeys.FORM.SELECT_ALL), withBorder: true, multiple: true, shadow: true, noTags: true, valueRendering: function (item) {
                                    return item.value &&
                                        (item.value.length === 0
                                            ? ''
                                            : item.value.length > 1
                                                ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SEVERAL_ACCOUNT_SELECTED)
                                                : bankAccount(item.value[0]));
                                }, itemRendering: bankAccount, keyText: "iban", keyValue: "id", items: company.bank_accounts_attributes })); } }))),
                React.createElement("div", { className: styles('fields') },
                    React.createElement("div", { className: styles('medium', 'spacing') },
                        React.createElement(Input, { register: register('counterparty_name'), className: styles('spacing'), noMargin: true, shadow: true, withBorder: true, label: t(i18nKeys.FORM.HISTORY.SENDER), onValueChanged: submit })),
                    React.createElement("div", { className: styles('medium', 'spacing') },
                        React.createElement(Controller, { defaultValue: "", name: "ignored", render: function () { return (React.createElement(CustomSelect, { name: "ignored", keyText: "description", keyValue: "value", noMargin: true, withBorder: true, noBorder: true, shadow: true, items: [
                                    {
                                        description: t(i18nKeys.CLIENT.FILTER.ALL),
                                        value: 'all',
                                    },
                                    {
                                        description: t(i18nKeys.BANK_ACCOUNT.HISTORY.IGNORED),
                                        value: 'ignored',
                                    },
                                    {
                                        description: t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED),
                                        value: 'unignored',
                                    },
                                ], label: t(i18nKeys.FORM.TYPE), onValueChanged: submit })); } })))))));
}
