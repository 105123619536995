import { useSelector } from 'react-redux';
import { store } from 'store/setupClientStore';
export var useTranslation = function () {
    var _a = useSelector(function (state) { return state.translate; }), availableLang = _a.availableLang, currentLang = _a.currentLang, text = _a.text;
    var t = function (key, params) {
        return !params
            ? text(key)
            : Object.keys(params).reduce(function (acc, paramsKey) { return acc.replace("${".concat(paramsKey, "}"), String(params[paramsKey])); }, text(key));
    };
    return { t: t, availableLang: availableLang, currentLang: currentLang };
};
// eslint-disable-next-line
export var translate = function () { return ''; };
store.subscribe(function () {
    var text = store.getState().translate.text;
    // @ts-ignore
    translate = function (key, params) {
        return !params
            ? text(key)
            : Object.keys(params).reduce(function (acc, paramsKey) { return acc.replace("${".concat(paramsKey, "}"), String(params[paramsKey])); }, text(key));
    };
});
