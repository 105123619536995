import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { useId } from '@mantine/hooks';
import styleIdentifiers from './CustomRadio.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomRadio = function (_a) {
    var label = _a.label, valueItem = _a.valueItem, nameItem = _a.nameItem, className = _a.className, contentClassName = _a.contentClassName, onChange = _a.onChange, props = __rest(_a, ["label", "valueItem", "nameItem", "className", "contentClassName", "onChange"]);
    var id = useId();
    var handleChange = function () {
        onChange(valueItem);
    };
    return (React.createElement("div", { className: styles('CustomRadio', className) },
        React.createElement("label", { htmlFor: id },
            React.createElement("input", __assign({ type: "radio", name: nameItem, value: valueItem, id: id, onChange: handleChange }, props)),
            React.createElement("div", { className: styles('single', contentClassName) }, label))));
};
