import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
var ViewSchema = z
    .object({
    attributes: z.object({
        name: z.string(),
    }),
    id: z.string(),
})
    .transform(function (data) { return ({ id: data.id, name: data.attributes.name }); });
var ViewsSchema = z.object({ data: z.array(ViewSchema) }).transform(function (data) { return data.data; });
export function loadViewsQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('views', {
                            params: {
                                view_type: queryKey[1],
                            },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ViewsSchema.parse(data)];
            }
        });
    });
}
export function useLoadViews(_a) {
    var resourceType = _a.resourceType;
    var legacyAxiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['views', resourceType],
        queryFn: function (context) { return loadViewsQueryFn(legacyAxiosInstance, context); },
        onError: function (error) {
            captureException(error);
        },
    });
    return addResourceNameToQueryResult('views', queryResult);
}
