import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var BalanceValueSchema = z.preprocess(function (val) { return Number(val); }, z.number());
var BalanceSchema = z.object({
    '0': BalanceValueSchema,
    '30': BalanceValueSchema,
    '60': BalanceValueSchema,
    '90': BalanceValueSchema,
    notDue: BalanceValueSchema,
});
var AgedBalanceOverTimeSchema = z.object({
    computedAt: z.string(),
    isComputing: z.boolean(),
    data: z.record(z.object({
        credits: BalanceSchema,
        debits: BalanceSchema,
    })),
});
export function loadAgedBalanceOverTimeFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, Promise, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/analytics/aged_balances/stacked', {
                            params: { view_id: queryKey[2] },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, AgedBalanceOverTimeSchema.parse(data)];
            }
        });
    });
}
export function useLoadAgedBalanceOverTime(_a) {
    var viewId = _a.viewId, onSuccess = _a.onSuccess;
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'over-time', viewId],
        queryFn: function (context) { return loadAgedBalanceOverTimeFn(axiosInstance, context); },
        onError: function (error) {
            captureException(error);
        },
        onSuccess: onSuccess,
    });
    return addResourceNameToQueryResult('agedBalanceOverTime', queryResult);
}
