import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import { Icon, IconName } from 'shared/components/Icon';
import { hasSendingMedium } from 'shared/components/SendingMedium';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { addressesToAttributes, emailValues, getDefaultAddresses, getDefaultEmails, } from 'shared/utils/contacts';
import { formattedDate } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { error } from 'store/view/view.actions';
import AddressPartialForm from '../partial/AddressPartialForm';
import SendInvoiceFormEmail from './SendInvoiceFormEmail';
import styleIdentifiers from './sendInvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SendInvoiceForm(_a) {
    var data = _a.data, onSubmit = _a.onSubmit, close = _a.close, _b = _a.credit, credit = _b === void 0 ? false : _b;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var constants = useSelector(function (state) { return state.app.constants; });
    var recoveryPlans = useSelector(function (state) { return state.settings.recoveryPlanList.pages; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var isLoadingInvoice = useSelector(function (state) { return state.invoice.isLoading; });
    var isLoadingCreditNote = useSelector(function (state) { return state.creditNote.isLoading; });
    var debtor = data.debtor_attributes;
    var sendingMediaAttributes = debtor.sending_media_attributes.length > 0
        ? debtor.sending_media_attributes
        : company.sending_media_attributes;
    var _d = __read(useState([]), 2), templates = _d[0], setTemplates = _d[1];
    useEffect(function () {
        if (!(recoveryPlans === null || recoveryPlans === void 0 ? void 0 : recoveryPlans.length)) {
            settingsActions.recoveryPlanListPage();
        }
        settingsActions.templateList({
            data: {
                step_type: credit ? 'credit_note' : 'invoice',
            },
            noLoading: true,
            callback: function (_a) {
                var response = _a.data;
                setTemplates(response.map(function (template) { return treatTemplate(template); }));
            },
        });
    }, []);
    var _e = useGetDebtorSendingOptions(debtor.id), sendingOptionsResponse = _e.data, refetch = _e.refetch;
    var defaultValues = {
        recovery_plan_id: 'auto',
        sent_outside_app: null,
        print_color: company.print_color,
        print_mode: company.print_mode,
        emails: [],
        addresses_attributes: [],
        template_id: (credit
            ? debtor.default_credit_note_template || company.default_templates.credit_note
            : debtor.default_invoice_template || company.default_templates.invoice).id,
        by_mail: hasSendingMedium(sendingMediaAttributes, SendingMediaNames.email),
        by_post: hasSendingMedium(sendingMediaAttributes, SendingMediaNames.post) ||
            hasSendingMedium(sendingMediaAttributes, SendingMediaNames.registered_post),
        by_registered_post: hasSendingMedium(sendingMediaAttributes, SendingMediaNames.registered_post),
    };
    var form = useForm({
        defaultValues: defaultValues,
    });
    var watch = form.watch, register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, getValues = form.getValues, reset = form.reset;
    useEffect(function () {
        if (sendingOptionsResponse) {
            var _a = sendingOptionsResponse || {
                emails: [],
                addresses: [],
            }, emails = _a.emails, addresses = _a.addresses;
            var defaultEmails = getDefaultEmails(emails, Notification.invoice_sending);
            var defaultAddresses = getDefaultAddresses(addresses, Notification.invoice_sending);
            var defaultEmailValues = emailValues(defaultEmails);
            reset(__assign(__assign({}, getValues()), { emails: __spreadArray([], __read((debtor.send_to_all_emails ? defaultEmailValues : [defaultEmailValues[0]])), false), addresses_attributes: addressesToAttributes(defaultAddresses) }));
        }
    }, [sendingOptionsResponse, debtor, getValues, reset]);
    var isSendingByMail = watch('by_mail');
    var isSendingByPost = watch('by_post');
    var isOutsideApp = watch('sent_outside_app') || false;
    var matchEmails = function (emails) {
        var _a;
        var contactPersons = (_a = sendingOptionsResponse === null || sendingOptionsResponse === void 0 ? void 0 : sendingOptionsResponse.emails) !== null && _a !== void 0 ? _a : [];
        return emails.map(function (rawEmail) {
            var maybeContactPerson = contactPersons.find(function (e) { return e.value === rawEmail; });
            return maybeContactPerson !== null && maybeContactPerson !== void 0 ? maybeContactPerson : { value: rawEmail };
        });
    };
    var submit = function (_a) {
        var addresses_attributes = _a.addresses_attributes, rest = __rest(_a, ["addresses_attributes"]);
        var formData = __assign(__assign(__assign({}, rest), (isSendingByPost
            ? {
                addresses_attributes: addresses_attributes,
            }
            : null)), { emails: matchEmails(rest.emails) });
        if (formData.by_post || formData.by_mail || formData.sent_outside_app) {
            onSubmit(formData);
        }
        else {
            error({
                text: i18nKeys.FORM.SEND_INVOICE.SENDING_MEDIUM_REQUIRED,
            });
        }
    };
    var setSendOutsideApp = function () {
        setValue('by_post', false);
        setValue('by_mail', false);
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('SendInvoiceForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('head') },
                t(i18nKeys.FORM.SEND_INVOICE.TITLE),
                close && (React.createElement("div", { className: styles('close'), onClick: function () { return close(); } },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, size: "20px" })))),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", null, t(i18nKeys.FORM.SEND_INVOICE.DESCRIPTION)),
                !credit && (React.createElement("div", null,
                    React.createElement(Controller, { name: "recovery_plan_id", defaultValue: "", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('input'), keyText: "name", keyValue: "id", withBorder: true, name: "recovery_plan_id", items: __spreadArray([{ name: t(i18nKeys.AUTOMATIC), id: 'auto' }], __read(recoveryPlans), false), label: t(i18nKeys.RECOVERY_PLAN) })); } }))),
                React.createElement("hr", null),
                React.createElement("div", { className: styles('section') },
                    (isSendingByMail || isSendingByPost) && (React.createElement(AddContactPersonButton, { refetch: refetch, debtorId: debtor.id })),
                    React.createElement("div", { className: styles('check-wrapper') },
                        React.createElement(Checkbox, { register: register('by_mail'), watch: watch, className: "check-SendInvoice", label: t(i18nKeys.FORM.SEND_INVOICE.SEND_BY_EMAIL), disabled: isOutsideApp })),
                    React.createElement("div", { className: styles('input-wrapper') }, isSendingByMail && (React.createElement(React.Fragment, null,
                        React.createElement(SendInvoiceFormEmail, { emails: (sendingOptionsResponse === null || sendingOptionsResponse === void 0 ? void 0 : sendingOptionsResponse.emails) || [] }),
                        React.createElement(Controller, { rules: { required: false }, defaultValue: "", name: "template_id", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.COMMON.TEMPLATE), keyText: "name", keyValue: "id", name: "template_id", items: templates })); } }))))),
                React.createElement("div", { className: styles('section', !constants.external_services.postgreen.active && 'disabled') },
                    React.createElement("div", { className: styles('check-wrapper') },
                        React.createElement(Checkbox, { register: register('by_post'), watch: watch, disabled: !constants.external_services.postgreen.active || isOutsideApp, label: t(i18nKeys.FORM.SEND_INVOICE.SEND_BY_MAIL), className: "check-SendInvoice" })),
                    React.createElement("div", { className: styles('input-wrapper') }, isSendingByPost && (React.createElement(React.Fragment, null,
                        data.additional_files_attributes &&
                            data.additional_files_attributes.length > 0 && (React.createElement("div", { className: styles('info') }, t(data.additional_files_attributes.length > 1
                            ? i18nKeys.FORM.SEND_INVOICE.SEND_ADDITIONAL_FILES
                            : i18nKeys.FORM.SEND_INVOICE.SEND_ADDITIONAL_FILE, { count: data.additional_files_attributes.length }))),
                        React.createElement(Checkbox, { register: register('by_registered_post'), watch: watch, disabled: !constants.external_services.postgreen.active, label: t(i18nKeys.REGISTERED) }),
                        React.createElement(AddressPartialForm, { addresses: sendingOptionsResponse === null || sendingOptionsResponse === void 0 ? void 0 : sendingOptionsResponse.addresses }),
                        React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "print_color", render: function () { return (React.createElement(CustomSelect, { className: styles('input'), label: t(i18nKeys.FORM.PREFERENCES.COLOR), name: "print_color", withBorder: true, keyText: "description", keyValue: "value", items: constants.print_colors })); } }),
                        React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "print_mode", render: function () { return (React.createElement(CustomSelect, { className: styles('input'), label: t(i18nKeys.FORM.SEND_INVOICE.FRONT_OR_BACK), name: "print_mode", withBorder: true, keyText: "description", keyValue: "value", items: constants.print_modes })); } })))),
                    !constants.external_services.postgreen.active && (React.createElement("div", { className: styles('warn-message') },
                        React.createElement(Icon, { name: IconName.INFO }),
                        t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                            date: formattedDate(constants.external_services.postgreen.unavailable_until, currentLang),
                        }))),
                    React.createElement("hr", null),
                    React.createElement("div", { className: styles('section') },
                        React.createElement("div", { className: styles('check-wrapper') },
                            React.createElement(Checkbox, { register: register('sent_outside_app'), watch: watch, className: "check-SendInvoice", label: t(i18nKeys.FORM.SEND_INVOICE.VALID_WITHOUT_SEND), onChange: setSendOutsideApp, disabled: isSendingByMail || isSendingByPost })),
                        React.createElement("div", { className: styles('input-wrapper') },
                            React.createElement("span", { className: styles('legend') },
                                t(i18nKeys.FORM.SEND_INVOICE.CAN_VALID_WITHOUT_SEND, {
                                    text: t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE).toLowerCase(),
                                }),
                                ' ',
                                !credit && t(i18nKeys.FORM.SEND_INVOICE.SEND_REMINDERS)))))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { noShadow: true, noMargin: true, disabled: !(isSendingByMail || isSendingByPost || isOutsideApp), label: t(isOutsideApp ? 'FORM.VALIDATE' : 'FORM.VALIDATE_AND_SEND'), type: "submit", isLoading: isLoadingInvoice || isLoadingCreditNote })))));
}
