import { __assign, __rest } from "tslib";
import React from 'react';
import { useCancelPaymentPlan, useGetActivities, useGetPaymentPlan } from 'api';
import InvoiceTimeline from 'app/Private/Invoices/ToHandleDetail/InvoiceTimeline/InvoiceTimeline';
import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { Button, ButtonColor } from 'shared/io';
import { formatActivities } from 'shared/utils/activities.utils';
import { formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { computeNextStepDate, computeStatus } from '../PaymentPlan.utils';
import { PaymentPlanDetailTable } from './PaymentPlanDetailTable';
import styleIdentifiers from './PaymentPlanDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanDetail = function (_a) {
    var planId = _a.planId, inModal = _a.inModal;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var history = useHistory();
    var can_use_advanced_payment_plans = useSelector(function (state) { return state.account.company.data; }).package.can_use_advanced_payment_plans;
    var paymentPlanId = useParams().id;
    var _c = useGetPaymentPlan(planId || paymentPlanId), paymentPlanResponse = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    var cancelPaymentPlan = useCancelPaymentPlan().mutate;
    var _d = useGetActivities({
        payment_plan_id: planId || paymentPlanId,
    }), activitiesListResponse = _d.data, activitiesLoading = _d.isLoading, activitiesError = _d.isError;
    if (isLoading && !paymentPlanResponse && activitiesLoading) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !paymentPlanResponse || activitiesError || !activitiesListResponse) {
        return React.createElement("span", null, "Error");
    }
    var paymentPlan = paymentPlanResponse.data;
    var id = paymentPlan.id, _e = paymentPlan.attributes, created_at = _e.created_at, currency = _e.currency, communication = _e.communication, completed = _e.completed, cancelled = _e.cancelled, cancelled_at = _e.cancelled_at, debtor = _e.debtor, invoices = _e.invoices, payment_steps_attributes = _e.payment_steps_attributes, workflow = _e.workflow, with_fees = _e.with_fees, current_step = _e.current_step;
    var goToList = function () {
        dialogHide(DialogShowId.CUSTOM);
        history.push('/payment-plans/listing');
    };
    var cancelAndRedirect = function (unpause) {
        cancelPaymentPlan({ id: id, data: { unpause: unpause } }, {
            onSuccess: function () {
                goToList();
                dialogHide(DialogShowId.CONFIRM);
            },
        });
    };
    var handleClickCancelPlan = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.PAYMENT_PLAN), confirmButtonColor: ButtonColor.BLUE, confirmButtonText: t(i18nKeys.YES), cancelButtonText: t(i18nKeys.NO), onCancel: function () { return cancelAndRedirect(false); }, onConfirm: function () { return cancelAndRedirect(true); } })),
        });
    };
    var handleClickEditPlan = function () {
        dialogHide(DialogShowId.INVOICE);
        history.push("/payment-plan/".concat(id, "/edit"));
    };
    var currentStep = payment_steps_attributes.find(function (step) { return step.id === (current_step === null || current_step === void 0 ? void 0 : current_step.toString()); });
    var invoicesFormated = __assign(__assign({ type: 'attached_invoice' }, omit(invoices, ['details'])), { details: invoices.details.map(function (_a) {
            var invoiceId = _a.id, _b = _a.attributes, due_date_invoice = _b.due_date, localized_money_object = _b.localized_money_object, rest = __rest(_b, ["due_date", "localized_money_object"]);
            return (__assign({ id: Number(invoiceId), due_date: moment.utc(due_date_invoice), localized_money_object: localized_money_object }, rest));
        }) });
    var hasPlanEnded = cancelled || completed;
    var STATUS = computeStatus(cancelled, completed);
    var activitiesListFormatted = formatActivities(activitiesListResponse);
    return (React.createElement("div", { className: styles('payment-plan-detail', inModal && 'in-modal') },
        React.createElement("div", { className: styles('title-line') },
            !inModal && React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: function () { return history.goBack(); } }),
            React.createElement("h1", null, "".concat(t(i18nKeys.PAYMENT_PLAN.TITLE.ONE), " #").concat(id)),
            React.createElement("div", { className: styles('buttons-wrapper') },
                React.createElement(Button, { title: !can_use_advanced_payment_plans ? t(i18nKeys.NOT_INCLUDED) : '', label: t(i18nKeys.EDIT), noMargin: true, onClick: handleClickEditPlan, disabled: !can_use_advanced_payment_plans || hasPlanEnded }),
                React.createElement(Button, { label: t(i18nKeys.CANCEL), noMargin: true, onClick: handleClickCancelPlan, color: ButtonColor.RED, disabled: hasPlanEnded }))),
        React.createElement("div", { className: styles('line-wrapper') },
            React.createElement(Card, { title: t(i18nKeys.CLIENT.GENERAL_INFO) },
                React.createElement("div", null,
                    React.createElement("div", { className: styles('item') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CREATION_DATE)),
                        React.createElement("span", { className: styles('value') }, formattedDate(created_at, currentLang))),
                    React.createElement("div", { className: styles('item') },
                        React.createElement("span", { className: styles('subtitle') }, "WORKFLOW"),
                        React.createElement("span", { className: styles('value') }, workflow.attributes.name)),
                    React.createElement("div", { className: styles('item') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.COMMUNICATION)),
                        React.createElement("span", { className: styles('value') }, formatCommunication(communication))),
                    React.createElement("div", { className: styles('item') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.WITH_FEES)),
                        React.createElement("span", { className: styles('value') }, t(i18nKeys[with_fees ? 'YES' : 'NO']))))),
            React.createElement(Card, { title: t(i18nKeys.DETAILS) },
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMON.CLIENT)),
                    React.createElement("span", { className: styles('value', 'bigger') },
                        React.createElement(NavLink, { to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name))),
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.STATUS)),
                    React.createElement("span", { className: styles('value') }, t(i18nKeys[STATUS]))),
                cancelled && (React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CANCELLED_AT)),
                    React.createElement("span", { className: styles('value') }, formattedDate(cancelled_at, currentLang)))),
                React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.NEXT_DEADLINE)),
                    React.createElement("span", { className: styles('value') }, computeNextStepDate(STATUS, currentLang, currentStep))))),
        React.createElement(AttachedInvoicesList, { title: t(i18nKeys.INVOICES), invoices: invoicesFormated, withoutFees: !with_fees }),
        React.createElement(PaymentPlanDetailTable, { currency: currency, paymentSteps: payment_steps_attributes }),
        !!activitiesListFormatted.activities.length && (React.createElement(InvoiceTimeline, { metadata: activitiesListFormatted.metadata, activities: activitiesListFormatted.activities }))));
};
