import React, { useMemo } from 'react';
import { useTenant } from 'app/TenantValidator';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import Logo from 'shared/components/Logo';
import UserItem from 'shared/components/UserItem';
import { useLoadNotificationsCount } from 'shared/hooks';
import { Dropdown } from 'shared/io';
import { isStaging } from 'shared/utils/environment';
import { useGetCompany, useIsMobile } from 'shared/utils/selectors';
import { Indicator } from '@mantine/core';
import { IconBell, IconPresentationAnalytics } from '@tabler/icons-react';
import QuickActions from '../QuickActions';
import SwitchTenant from '../SwitchTenant';
import styleIdentifiers from './Header.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Header(_a) {
    var burger = _a.burger;
    var tenant = useTenant();
    var company = useGetCompany();
    var isMobile = useIsMobile();
    var notificationsCount = useLoadNotificationsCount().notificationsCount;
    var logo = useMemo(function () {
        var _a, _b;
        return (React.createElement(React.Fragment, null, ((_a = tenant.customBranding) === null || _a === void 0 ? void 0 : _a.logo) ? (React.createElement("img", { src: tenant.customBranding.logo, alt: (_b = tenant.customBranding.name) !== null && _b !== void 0 ? _b : '' })) : (React.createElement(Logo, { type: isMobile ? 'small' : 'white', width: !isMobile ? '150px' : undefined, height: isMobile ? '50px' : undefined, className: styles('logo') }))));
    }, [tenant, isMobile]);
    return (React.createElement("div", { className: styles('Header', isStaging() && 'staging') },
        React.createElement("div", { className: styles('content') },
            React.createElement("div", { className: styles('left') },
                burger,
                React.createElement(NavLink, { to: "/dashboard" }, logo),
                (company === null || company === void 0 ? void 0 : company.name) && React.createElement("div", { className: styles('company') }, company.name)),
            React.createElement("div", { className: styles('right') },
                React.createElement("div", { className: styles('item', 'separation') },
                    React.createElement(NavLink, { to: "/kanban" },
                        React.createElement(IconPresentationAnalytics, { size: 30, color: "white", stroke: 1 }))),
                React.createElement("div", { className: styles('item') },
                    React.createElement(NavLink, { to: "/activities" },
                        React.createElement(Indicator, { color: "red", disabled: notificationsCount === 0, offset: 3, size: 8 },
                            React.createElement(IconBell, { color: "white", size: 30, stroke: 1 })))),
                React.createElement("div", { "data-cy": "add-menu", className: styles('item-wrapper', 'separation') },
                    React.createElement(Dropdown, { selectorContent: React.createElement("div", { className: styles('item', 'add') },
                            React.createElement("span", null, "+")), toggleContent: true, className: styles('dropdown-menu') },
                        React.createElement(QuickActions, null))),
                React.createElement("div", { className: styles('item-wrapper') },
                    React.createElement(SwitchTenant, null)),
                React.createElement("div", { className: styles('item-wrapper') },
                    React.createElement(UserItem, null)),
                React.createElement("div", { className: styles('help-button'), onClick: function () {
                        window.Intercom('show');
                    } },
                    React.createElement(Icon, { name: IconName.QUESTION, className: styles('icon', 'bold') }))))));
}
