import { __read, __spreadArray } from "tslib";
import React, { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller } from 'react-hook-form';
import { concat, flat, map, pipe, prop, uniqueBy } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import styleIdentifiers from './TaskForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskFormDebtor = function (_a) {
    var _b, _c;
    var _d = _a.name, name = _d === void 0 ? 'debtor_id' : _d, currentDebtor = _a.currentDebtor, extraOptions = _a.extraOptions;
    var t = useTranslation().t;
    var _e = __read(useState(), 2), selectSearchValue = _e[0], setSelectSearchValue = _e[1];
    var _f = useGetDebtorsInfinite({ name: selectSearchValue }, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), debtorsResponse = _f.data, fetchNextPage = _f.fetchNextPage;
    var pages = (_b = debtorsResponse === null || debtorsResponse === void 0 ? void 0 : debtorsResponse.pages) !== null && _b !== void 0 ? _b : [];
    var selectedDebtorArray = currentDebtor
        ? [{ id: currentDebtor.id, full_name: currentDebtor.attributes.full_name }]
        : [];
    var items = pipe(pages, map(function (e) { return e.data; }), flat(), concat(selectedDebtorArray), map(treatClient), uniqueBy(prop('id')));
    var options = debtorsResponse == null
        ? []
        : __spreadArray(__spreadArray(__spreadArray([], __read(((_c = extraOptions === null || extraOptions === void 0 ? void 0 : extraOptions.map(treatClient)) !== null && _c !== void 0 ? _c : [])), false), __read((currentDebtor ? [currentDebtor].map(treatClient) : [])), false), __read(items.filter(function (el) { return el.id !== (currentDebtor === null || currentDebtor === void 0 ? void 0 : currentDebtor.id); })), false);
    return (React.createElement(Controller, { name: name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), filter: true, withBorder: true, infiniteScroll: true, onChangeFilter: setSelectSearchValue, items: options, keyValue: "id", keyText: "full_name", name: name, label: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR), load: fetchNextPage })); } }));
};
