import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, UploadZone } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { CGV, SalePreferencesFieldsName } from '../PreferencesForm.constants';
import styleIdentifiers from '../PreferencesForm.scss';
import { getFieldErrors } from './SalePreferencesPartialForm.utils';
var styles = classNames.bind(styleIdentifiers);
export var SalePreferencesPartialFormUpload = function (_a) {
    var onboarding = _a.onboarding, _b = _a.parent, parent = _b === void 0 ? 0 : _b;
    var t = useTranslation().t;
    var _c = useFormContext(), watch = _c.watch, errors = _c.formState.errors;
    var key = "".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesFieldsName.PDF_BLOB);
    var hasPdf = watch("".concat(CGV, ".").concat(parent, ".").concat(SalePreferencesFieldsName.HAS_PDF));
    var fieldErrors = getFieldErrors(errors, parent);
    var uploadZoneProps = {
        className: styles('upload-zone__el'),
        accept: 'application/pdf',
        info: t(i18nKeys.FORM.PREFERENCES.IMPORT_GENERAL_CONDITIONS),
        subinfo: t(i18nKeys.FORM.FILE_FORMAT_PDF),
        noResize: true,
        noMargin: true,
        noPreview: true,
        error: fieldErrors === null || fieldErrors === void 0 ? void 0 : fieldErrors.pdf,
        showError: true,
    };
    if (onboarding) {
        return (React.createElement(Controller, { name: key, render: function (_a) {
                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                return React.createElement(UploadZone, __assign({}, values, uploadZoneProps));
            } }));
    }
    return (React.createElement("div", { className: styles('upload-zone') },
        React.createElement(Controller, { name: key, render: function (_a) {
                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                return React.createElement(UploadZone, __assign({}, values, uploadZoneProps));
            } }),
        hasPdf && (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "button", className: styles('upload-zone__pdf'), label: t(i18nKeys.FORM.PREFERENCES.READ_CONDITIONS), noShadow: true, small: true, onClick: function () { return accountActions.getTerms({ id: watch("".concat(CGV, ".").concat(parent, ".id")) }); } })))));
};
