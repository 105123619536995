import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import { useGetDebtorsInfinite, useMergeSimilarDebtors } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { filter, flat, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AttributesSelection } from './AttributesSelection';
import styleIdentifiers from './SelectDuplicateDebtorForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var SelectDuplicateDebtorForm = function (_a) {
    var _b;
    var currentDebtor = _a.currentDebtor;
    var t = useTranslation().t;
    var _c = __read(useState(), 2), selectSearchValue = _c[0], setSelectSearchValue = _c[1];
    var _d = useGetDebtorsInfinite({ name: selectSearchValue }, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), debtorsResponse = _d.data, fetchNextPage = _d.fetchNextPage;
    var pages = (_b = debtorsResponse === null || debtorsResponse === void 0 ? void 0 : debtorsResponse.pages) !== null && _b !== void 0 ? _b : [];
    var concatedDebtors = pipe(pages, map(function (e) { return e.data; }), flat(), filter(function (e) { return e.id !== (currentDebtor === null || currentDebtor === void 0 ? void 0 : currentDebtor.id); }));
    var treatedDebtors = concatedDebtors.map(treatClient);
    var mergeDebtors = useMergeSimilarDebtors().mutate;
    var submitMerge = function (data) {
        mergeDebtors({ data: data }, {
            onSuccess: function () {
                clientActions.detail({ id: currentDebtor.id });
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var openAttributesSelectionModal = function (debtorToMergeId) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.CLIENT.MERGE.TITLE),
            children: (React.createElement(AttributesSelection, { baseDebtorId: currentDebtor.id, debtors: [
                    {
                        id: currentDebtor.id,
                        attributes: __assign(__assign({}, currentDebtor), { phones: currentDebtor.phones_attributes.map(function (e) { return ({
                                id: e.id,
                                attributes: { number: e.number },
                            }); }), debtor_bank_accounts: currentDebtor.debtor_bank_accounts_attributes, address: {
                                attributes: currentDebtor.address_attributes,
                            } }),
                    },
                    concatedDebtors.find(function (debtor) { return debtorToMergeId === debtor.id; }),
                ], onSubmit: function (data) { return submitMerge(data); } })),
        });
    };
    var form = useForm();
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('duplicate-debtor') },
            React.createElement("div", { className: styles('select-client') },
                React.createElement(Controller, { name: "debtor_id", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), filter: true, withBorder: true, infiniteScroll: true, items: treatedDebtors, keyValue: "id", keyText: "full_name", name: "debtor_id", load: fetchNextPage, onChangeFilter: setSelectSearchValue, onValueChanged: openAttributesSelectionModal })); } })))));
};
