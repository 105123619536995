import { __assign } from "tslib";
import { useForm, zodResolver } from '@mantine/form';
import { LoadDebtorColumnVariablesSchema, useLoadDebtorColumn, } from './use-load-debtor-column';
export function useLoadKanbanDebtors() {
    var filtersForm = useForm({
        validate: zodResolver(LoadDebtorColumnVariablesSchema),
        initialValues: {
            accountManagerId: null,
            minSaldo: '',
            maxSaldo: '',
            fullName: '',
            column: 'late', // placholder for type correctness, overwritten below,
        },
    });
    var filters = filtersForm.getValues();
    var late = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'late' }));
    var firstReminder = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'first_reminder' }));
    var lastReminder = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'last_reminder' }));
    var formalNotice = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'formal_notice' }));
    var thirdPartyCase = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'third_party_case' }));
    var disputed = useLoadDebtorColumn(__assign(__assign({}, filters), { column: 'disputed' }));
    return {
        columns: { late: late, firstReminder: firstReminder, lastReminder: lastReminder, formalNotice: formalNotice, thirdPartyCase: thirdPartyCase, disputed: disputed },
        filtersForm: filtersForm,
    };
}
