import React from 'react';
import { useGetWorkflows } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { WorkflowsListItem } from './WorkflowsListItem';
import styleIdentifiers from './WorkflowsList.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowsList = function (_a) {
    var planType = _a.planType;
    var t = useTranslation().t;
    var history = useHistory();
    var _b = useGetWorkflows(), workflowsListResponse = _b.data, isLoading = _b.isLoading, isError = _b.isError;
    if (isLoading && !workflowsListResponse) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !workflowsListResponse) {
        return React.createElement("span", null, "Error");
    }
    var workflows = workflowsListResponse.data, metadata = workflowsListResponse.metadata;
    var createWorkflow = function () {
        history.push("/settings/workflows/".concat(planType, "/create"));
    };
    return (React.createElement("div", { className: styles('workflows-list') },
        React.createElement("ul", null,
            workflows.map(function (workflow) { return (React.createElement("li", { key: workflow.id },
                React.createElement(WorkflowsListItem, { planType: planType, workflow: workflow }))); }),
            React.createElement("li", { onClick: function () { return createWorkflow(); } },
                React.createElement("div", { className: styles('workflows-list-item', 'card', 'add-item') },
                    React.createElement("div", null,
                        React.createElement(Icon, { name: IconName.PLUS }),
                        React.createElement("span", null, t(i18nKeys.ACTIONS.ADD))))))));
};
