import { __read } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared';
import { CollectionStep } from 'shared/components/CollectionStep';
import { useEnhancedNavigation } from 'shared/hooks/utils';
import { PageTitle } from 'shared/layout';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { Badge, Box, Button, Drawer, Stack, Text } from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import { IconFilter, IconFilterOff } from '@tabler/icons-react';
import { useLoadKanbanDebtors } from '../api';
import { DebtorKanbanCard, Kanban, KanbanLoadingState } from '../components';
import { DebtorKanbanFilters } from '../components/debtor/DebtorKanbanFilters';
import { countActiveFilters } from '../utils/filters';
export var DebtorKanban = function () {
    var t = useTranslation().t;
    var formatter = useLocalizedCurrencyFormatter();
    var _a = useLoadKanbanDebtors(), columns = _a.columns, filtersForm = _a.filtersForm;
    var _b = __read(useDisclosure(false), 2), isFiltersDrawerOpen = _b[0], filtersDrawerHandlers = _b[1];
    var enhancedNavigate = useEnhancedNavigation();
    useHotkeys([['f', filtersDrawerHandlers.toggle]]);
    var isFetching = Object.values(columns).some(function (column) { return column.isDebtorColumnFetching; });
    var areAllColumnsPopulated = Object.values(columns).every(function (column) {
        return isDefined(column.debtorColumn);
    });
    if (!areAllColumnsPopulated)
        return React.createElement(KanbanLoadingState, { title: t(i18nKeys.KANBAN.DEBTOR.TITLE), columnCount: 6 });
    var activeFiltersCount = countActiveFilters(filtersForm.values, ['column']);
    var cols = Object.values(columns).map(function (column, colIndex) {
        var cards = column.debtorColumn.debtors.map(function (debtor, invoiceIndex) {
            return (React.createElement(Kanban.Card, { key: invoiceIndex, onClick: enhancedNavigate("/clients/".concat(debtor.id, "/balance")) },
                React.createElement(DebtorKanbanCard, { debtor: debtor })));
        });
        return (React.createElement(Kanban.Column, { isLoading: isFetching, key: colIndex, title: React.createElement(Stack, { gap: "sm" },
                React.createElement(CollectionStep, { step: colIndex }),
                React.createElement(Text, { fw: "bold", size: "16px", c: "gray.7" }, formatter.format(column.debtorColumn.total))), color: STEP_TO_STEP_THEME[colIndex].color }, cards));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { h: "60px" },
            React.createElement(PageTitle, null,
                t(i18nKeys.KANBAN.DEBTOR.TITLE),
                React.createElement(PageTitle.Actions, null,
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { variant: "default", onClick: filtersDrawerHandlers.open, leftSection: React.createElement(IconFilter, { stroke: 1.5, size: 18 }), rightSection: React.createElement(Badge, { color: activeFiltersCount > 0 ? 'blue' : 'gray' }, activeFiltersCount) }, t(i18nKeys.FILTER)),
                        activeFiltersCount > 0 && (React.createElement(Button, { variant: "default", onClick: filtersForm.reset },
                            React.createElement(IconFilterOff, { stroke: 1.5, size: 18 }))))))),
        React.createElement(Box, { h: "calc(100% - 60px)" },
            React.createElement(Kanban, { minColumnWidth: 180 }, cols)),
        React.createElement(Drawer, { position: "right", opened: isFiltersDrawerOpen, onClose: filtersDrawerHandlers.close, title: t(i18nKeys.FILTERS), keepMounted: true, padding: "lg", styles: {
                title: {
                    fontSize: '1.5rem',
                },
            } },
            React.createElement(DebtorKanbanFilters, { form: filtersForm }))));
};
