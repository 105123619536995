import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './PaymentPlanDetailDebtor.scss';
import PaymentPlanInvoicesDebtor from './PaymentPlanInvoicesDebtor/PaymentPlanInvoicesDebtor';
import PaymentStepsDebtor from './PaymentStepsDebtor/PaymentStepsDebtor';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentPlanDetailDebtor(_a) {
    var paymentPlan = _a.paymentPlan;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var renderInfo = function (text, info) { return (React.createElement("div", { className: styles('key-text') },
        React.createElement("div", { className: styles('attribute') },
            t(text),
            ": "),
        React.createElement("div", { className: styles('value') }, info))); };
    var id = paymentPlan.id, _c = paymentPlan.attributes, remaining_balance = _c.remaining_balance, payment_steps_attributes = _c.payment_steps_attributes, communication = _c.communication, completed = _c.completed, cancelled = _c.cancelled, created_at = _c.created_at, next_due_date = _c.next_due_date, invoices = _c.invoices, currency = _c.currency, with_fees = _c.with_fees;
    return (React.createElement("div", { className: styles('side-menu-info') },
        React.createElement("div", { className: styles('head') }, "".concat(t(i18nKeys.PAYMENT_PLAN.TITLE.ONE), " #").concat(id)),
        React.createElement("div", { className: styles('content') },
            React.createElement(React.Fragment, null,
                React.createElement("h3", null, t(i18nKeys.DETAILS)),
                renderInfo(i18nKeys.COMMUNICATION, formatCommunication(communication)),
                renderInfo(i18nKeys.ISSUE_DATE, formattedDate(created_at, currentLang)),
                !completed &&
                    !cancelled &&
                    renderInfo(i18nKeys.NEXT_DEADLINE, formattedDate(next_due_date, currentLang)),
                !completed &&
                    renderInfo(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT, React.createElement(Amount, { localizedValue: remaining_balance, value: remaining_balance, suffix: currency })),
                React.createElement("hr", { className: styles('content-separator') }),
                React.createElement(PaymentStepsDebtor, { paymentSteps: payment_steps_attributes, currency: currency }),
                React.createElement("hr", { className: styles('content-separator') }),
                React.createElement(PaymentPlanInvoicesDebtor, { withFees: with_fees, invoices: invoices, currency: currency })))));
}
