import classNames from 'classnames/bind';
import React from 'react';
import Amount from 'shared/components/Amount';
import Percentage from 'shared/components/Percentage';
import styleIdentifiers from './tableDetails.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TableDetailsItem(_a) {
    var productLine = _a.productLine, noTva = _a.noTva, suffix = _a.suffix;
    var current_product_name = productLine.current_product_name, quantity = productLine.quantity, current_unity_price_htva = productLine.current_unity_price_htva, discount_rate = productLine.discount_rate, total_htva = productLine.total_htva, current_vat_rate = productLine.current_vat_rate, total_tvac = productLine.total_tvac;
    var isInvoice = !!(productLine === null || productLine === void 0 ? void 0 : productLine.linked_invoice_id);
    return (React.createElement("tr", null,
        React.createElement("td", null, current_product_name),
        React.createElement("td", null, !isInvoice ? quantity : '-'),
        React.createElement("td", null,
            React.createElement(Amount, { value: current_unity_price_htva, suffix: suffix })),
        React.createElement("td", null, !isInvoice ? React.createElement(Percentage, { value: discount_rate }) : '-'),
        React.createElement("td", null,
            React.createElement(Amount, { value: total_htva, suffix: suffix })),
        React.createElement("td", null, !isInvoice ? React.createElement(Percentage, { value: noTva ? 0 : current_vat_rate }) : '-'),
        React.createElement("td", { className: styles('total') },
            React.createElement(Amount, { value: total_tvac, suffix: suffix }))));
}
