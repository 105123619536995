import { __makeTemplateObject, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { useLoadViewsV2 } from 'shared/hooks';
import { css, cx } from '@emotion/css';
import { Box, Button, Group, LoadingOverlay, ScrollArea, Space, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCreateView } from '../../../hooks/use-create-view';
import { ViewSettings } from './components';
var styles = {
    shadow: {
        base: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      top: 0;\n      z-index: 1;\n      left: 0;\n      width: 100%;\n      height: 25px;\n      background: linear-gradient(180deg, rgba(200, 200, 200, 0.3), transparent);\n      transition: opacity 0.3s;\n      pointer-events: none;\n    "], ["\n      position: absolute;\n      top: 0;\n      z-index: 1;\n      left: 0;\n      width: 100%;\n      height: 25px;\n      background: linear-gradient(180deg, rgba(200, 200, 200, 0.3), transparent);\n      transition: opacity 0.3s;\n      pointer-events: none;\n    "]))),
        bottom: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      top: unset;\n      bottom: 0;\n      transform: rotate(180deg);\n    "], ["\n      top: unset;\n      bottom: 0;\n      transform: rotate(180deg);\n    "]))),
        hidden: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      opacity: 0;\n    "], ["\n      opacity: 0;\n    "]))),
    },
};
export function ViewSettingsModal(_a) {
    var _b, _c;
    var resourceType = _a.resourceType;
    var t = useTranslation().t;
    var _d = useLoadViewsV2({ resourceType: resourceType }), views = _d.views, isViewsLoading = _d.isViewsLoading;
    var _e = useCreateView(), createView = _e.createView, isCreateViewLoading = _e.isCreateViewLoading;
    var _f = __read(useState(false), 2), shouldScrollToNewElement = _f[0], setShouldScrollToNewElement = _f[1];
    var _g = __read(useState(false), 2), showTopShadow = _g[0], setShowTopShadow = _g[1];
    var _h = __read(useState(true), 2), showBottomShadow = _h[0], setShowBottomShadow = _h[1];
    var scrollableRef = useRef(null);
    useEffect(function () {
        var scrollableElement = scrollableRef.current;
        var handleScroll = function () {
            var _a = scrollableElement, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            setShowTopShadow(scrollTop > 0);
            // The scroll sometimes does not bottom out full and lands on a decimal value so we ceil
            setShowBottomShadow(Math.ceil(scrollTop) < scrollHeight - clientHeight);
        };
        handleScroll(); // To avoid first render glitch when content is not scrollable
        scrollableElement === null || scrollableElement === void 0 ? void 0 : scrollableElement.addEventListener('scroll', handleScroll);
        return function () {
            scrollableElement === null || scrollableElement === void 0 ? void 0 : scrollableElement.removeEventListener('scroll', handleScroll);
        };
    }, [views === null || views === void 0 ? void 0 : views.length]);
    useEffect(function () {
        var _a;
        if (shouldScrollToNewElement) {
            (_a = scrollableRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: scrollableRef.current.scrollHeight,
                behavior: 'smooth',
            });
            setShouldScrollToNewElement(false);
        }
    }, [views === null || views === void 0 ? void 0 : views.length]);
    return (React.createElement(Stack, null,
        React.createElement(ScrollArea, { px: "sm", viewportRef: scrollableRef, viewportProps: { style: { maxHeight: 'min(900px, calc(100vh - 250px))' } }, bg: "gray.2", style: function (theme) { return ({ borderRadius: theme.radius.sm }); } },
            React.createElement("div", { className: cx(styles.shadow.base, (_b = {}, _b[styles.shadow.hidden] = !showTopShadow, _b)) }),
            React.createElement("div", { className: cx(styles.shadow.base, styles.shadow.bottom, (_c = {},
                    _c[styles.shadow.hidden] = !showBottomShadow,
                    _c)) }),
            isViewsLoading ? (React.createElement(Box, { h: 450 },
                React.createElement(LoadingOverlay, { visible: true, overlayProps: { backgroundOpacity: 0 } }))) : (React.createElement(React.Fragment, null,
                React.createElement(Space, { h: "sm" }),
                React.createElement(Stack, null, views === null || views === void 0 ? void 0 : views.map(function (view) {
                    return (React.createElement(ViewSettings, { key: view.id, view: view, onDuplicate: function () { return setShouldScrollToNewElement(true); } }));
                })),
                React.createElement(Space, { h: "sm" })))),
        React.createElement(Group, { justify: "end" },
            React.createElement(Button, { disabled: isViewsLoading, loading: isCreateViewLoading, rightSection: React.createElement(IconPlus, { strokeWidth: 1.5, size: 20 }), onClick: function () {
                    createView({ resourceType: resourceType }, { onSuccess: function () { return setShouldScrollToNewElement(true); } });
                } }, t(i18nKeys.VIEWS.SETTINGS.NEW)))));
}
var templateObject_1, templateObject_2, templateObject_3;
