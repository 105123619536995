import React from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { isNonNull } from 'remeda';
import { useSubscribeToMonitoring } from 'shared/hooks/use-subscribe-to-monitor';
import { Button, Group, Text } from '@mantine/core';
import { lowerFirst } from '@mantine/hooks';
import { IconRadar } from '@tabler/icons-react';
import { UpdateMonitoringDataButton } from './UpdateMonitoringDataButton';
export var MonitoringStatusActions = function (_a) {
    var isExperimentalEnabled = _a.isExperimentalEnabled, isMonitoringActive = _a.isMonitoringActive, updatedAt = _a.updatedAt, hasVatNumber = _a.hasVatNumber, debtorId = _a.debtorId;
    var t = useTranslation().t;
    var _b = useSubscribeToMonitoring(), subscribeToMonitoring = _b.subscribeToMonitoring, isSubscribeToMonitoringLoading = _b.isSubscribeToMonitoringLoading;
    return (React.createElement(Group, { justify: "flex-end" },
        !isExperimentalEnabled && !isMonitoringActive && (React.createElement(Group, null,
            isNonNull(updatedAt) && (React.createElement(Text, { size: "sm", c: "gray.5", fs: "italic" },
                t(i18nKeys.ANALYTICS.LAST_UPDATED),
                lowerFirst(dayjs(dayjs(updatedAt)).calendar()))),
            React.createElement(UpdateMonitoringDataButton, { hasVatNumber: hasVatNumber, debtorId: Number(debtorId) }))),
        isMonitoringActive && (React.createElement(Group, null,
            React.createElement(Text, { size: "sm", c: "gray.5", fs: "italic" }, t(i18nKeys.DEBTOR.MONITORING.SUBSCRIPTION_ACTIVE, {
                date: lowerFirst(dayjs(dayjs(updatedAt)).calendar()),
            })))),
        isExperimentalEnabled && !isMonitoringActive && (React.createElement(Group, null,
            isNonNull(updatedAt) && (React.createElement(Text, { size: "sm", c: "gray.5", fs: "italic" },
                t(i18nKeys.ANALYTICS.LAST_UPDATED),
                lowerFirst(dayjs(dayjs(updatedAt)).calendar()))),
            React.createElement(UpdateMonitoringDataButton, { hasVatNumber: hasVatNumber, debtorId: Number(debtorId) }),
            React.createElement(Button, { leftSection: React.createElement(IconRadar, { stroke: 1.5 }), loading: isSubscribeToMonitoringLoading, onClick: function () { return subscribeToMonitoring({ debtorId: String(debtorId) }); } }, t(i18nKeys.DEBTOR.MONITORING.SUBSCRIBE))))));
};
