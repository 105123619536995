import { StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { getWorkflowRights, isAfterDueDate, isBeforeDueDate } from '../WorkflowForm.utils';
import styleIdentifiers from './WorkflowFormSteps.scss';
import { WorkflowFormStepsItemDelay } from './WorkflowFormStepsItemDelay';
import { WorkflowFormStepsItemDetail } from './WorkflowFormStepsItemDetail';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepsItem = function (_a) {
    var planType = _a.planType, name = _a.name, index = _a.index, edit = _a.edit, remove = _a.remove;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = getWorkflowRights(company, planType), canEditStep = _b.canEditStep, canDeleteStep = _b.canDeleteStep;
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue;
    var _d = watch("".concat(name, ".").concat(index)), id = _d.id, delay = _d.delay, step_type = _d.step_type, _destroy = _d._destroy;
    var isFirstReminder = step_type === StepType.first_reminder;
    // TODO: For RecoveryPlan
    // const isIntermediateReminder = step_type === StepType.intermediate_reminder;
    // const isLastReminder = step_type === StepType.last_reminder;
    // const invoicesCount = undefined;
    // const lateFeesStep = undefined;
    var handleRemove = function (i) {
        return id ? setValue("".concat(name, ".").concat(i, "._destroy"), true) : remove();
    };
    if (_destroy)
        return React.createElement(React.Fragment, null);
    return (React.createElement(React.Fragment, null,
        isAfterDueDate(name) && (React.createElement(WorkflowFormStepsItemDelay, { onClick: canEditStep ? edit : undefined, delay: delay, top: true, after: true })),
        React.createElement(WorkflowFormStepsItemDetail, { name: name, index: index },
            React.createElement("button", { type: "button", className: styles('opacity'), title: t(i18nKeys.EDIT), disabled: !canEditStep, onClick: canEditStep ? edit : undefined },
                React.createElement(Icon, { name: IconName.PENCIL })),
            !isFirstReminder && (React.createElement("button", { type: "button", className: styles('red', 'opacity'), title: t(i18nKeys.DELETE), disabled: !canDeleteStep, onClick: function () { return (canDeleteStep ? handleRemove(index) : undefined); } },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE })))),
        isBeforeDueDate(name) && (React.createElement(WorkflowFormStepsItemDelay, { onClick: canEditStep ? edit : undefined, delay: delay, bottom: true, before: true }))));
};
