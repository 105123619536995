import { __makeTemplateObject, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isEmpty } from 'remeda';
import { useDeleteView, useDuplicateView, useProfile, useRemoveFavoriteView, useSetFavoriteView, useToggleViewPrivacy, useUpdateView, } from 'shared/hooks';
import { css, cx } from '@emotion/css';
import { ActionIcon, Avatar, Badge, Button, Card, Group, Loader, Overlay, Stack, Switch, Text, TextInput, Tooltip, useMantineTheme, } from '@mantine/core';
import { useDebouncedValue, useFocusTrap, useIsFirstRender } from '@mantine/hooks';
import { IconCheck, IconCopy, IconTrash } from '@tabler/icons-react';
import { ViewIcon } from './ViewIcon';
var styles = {
    overlay: {
        base: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      pointer-events: none;\n      opacity: 0;\n      transition: opacity 300ms ease;\n    "], ["\n      pointer-events: none;\n      opacity: 0;\n      transition: opacity 300ms ease;\n    "]))),
        visible: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      pointer-events: all;\n      opacity: 1;\n    "], ["\n      pointer-events: all;\n      opacity: 1;\n    "]))),
    },
};
export var ViewSettings = function (_a) {
    var _b;
    var view = _a.view, onDuplicate = _a.onDuplicate;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var profile = useProfile();
    var setFavoriteView = useSetFavoriteView().setFavoriteView;
    var removeFavoriteView = useRemoveFavoriteView().removeFavoriteView;
    var toggleViewPrivacy = useToggleViewPrivacy().toggleViewPrivacy;
    var _c = useDuplicateView(), duplicateView = _c.duplicateView, isDuplicateViewLoading = _c.isDuplicateViewLoading;
    var _d = useDeleteView(), deleteView = _d.deleteView, isDeleteViewLoading = _d.isDeleteViewLoading;
    var _e = useUpdateView(), updateView = _e.updateView, isUpdateViewLoading = _e.isUpdateViewLoading;
    var isFirstRender = useIsFirstRender();
    var focusTrapRef = useFocusTrap();
    var isUserAuthor = view.user.id === profile.id;
    var _f = __read(useState(false), 2), showDeletionConfirm = _f[0], setShowDeletionConfirm = _f[1];
    var _g = __read(useState(view.name), 2), name = _g[0], setName = _g[1];
    var initialName = useRef(view.name);
    var _h = __read(useDebouncedValue(name, 500), 1), debouncedName = _h[0];
    useEffect(function () {
        if (isFirstRender)
            return;
        updateView({
            viewId: view.id,
            name: debouncedName,
            resourceType: view.resourceType,
        });
    }, [debouncedName]);
    var hasNameChanged = view.name !== initialName.current;
    return (React.createElement(Card, { radius: "sm", withBorder: true },
        React.createElement(Overlay, { radius: "sm", center: true, backgroundOpacity: 0.4, color: "#ffffff", blur: 10, className: cx(styles.overlay.base, (_b = {}, _b[styles.overlay.visible] = showDeletionConfirm, _b)) },
            React.createElement(Stack, null,
                React.createElement(Text, { mb: "lg" }, t(i18nKeys.VIEWS.SETTINGS.CONFIRM_DELETE)),
                React.createElement(Group, { justify: "right" },
                    React.createElement(Button, { flex: 1, variant: "light", color: "orange", onClick: function () { return setShowDeletionConfirm(false); } }, t(i18nKeys.CANCEL)),
                    React.createElement(Button, { flex: 1, loading: isDeleteViewLoading, color: "red", onClick: function () {
                            deleteView({ viewId: view.id, resourceType: view.resourceType });
                        } }, t(i18nKeys.DELETE))))),
        React.createElement(Group, { justify: "space-between", align: "start" },
            React.createElement(Group, { gap: 0 },
                React.createElement(ViewIcon, { view: view, onFavorite: function () {
                        return setFavoriteView({ viewId: view.id, resourceType: view.resourceType });
                    }, onRemoveFavorite: function () {
                        return removeFavoriteView({
                            viewId: view.id,
                            resourceType: view.resourceType,
                        });
                    } }),
                React.createElement(TextInput, { ref: focusTrapRef, value: name, size: "xs", ml: "lg", mr: "xs", onChange: function (event) { return setName(event.target.value); } }),
                (function () {
                    if (isUpdateViewLoading)
                        return React.createElement(Loader, { size: "xs", color: "blue.4" });
                    if (hasNameChanged)
                        return React.createElement(IconCheck, { stroke: 1.3, color: theme.colors.green[5] });
                    return null;
                })()),
            React.createElement(Stack, { align: "end" },
                React.createElement(Group, null,
                    React.createElement(Avatar, { size: "sm", color: "cyan", radius: "xl", src: view.user.profilePicture }, view.user.name.initials),
                    React.createElement(Text, { size: "sm" }, view.user.name.full)),
                isUserAuthor && !view.isDefault && (React.createElement(Switch, { label: t(i18nKeys.VIEWS.SETTINGS.PUBLIC), size: "xs", checked: view.isShared, onChange: function () {
                        return toggleViewPrivacy({
                            viewId: view.id,
                            resourceType: view.resourceType,
                        });
                    } })))),
        React.createElement(Stack, { gap: "xs", mt: "lg" },
            React.createElement(Text, { c: "dimmed" },
                t(i18nKeys.VIEWS.SETTINGS.COLUMNS),
                ":"),
            React.createElement(Group, { gap: "xs" }, view.columns.map(function (column, index) {
                return (React.createElement(Badge, { key: index, size: "sm", radius: "sm", variant: "light" }, column.name));
            }))),
        !isEmpty(view.filters) && (React.createElement(Stack, { gap: "xs", mt: "lg" },
            React.createElement(Text, { c: "dimmed" },
                t(i18nKeys.VIEWS.SETTINGS.FILTERS),
                ":"),
            React.createElement(Group, { gap: "xs" }, view.filters.map(function (filter, index) {
                return (React.createElement(Badge, { key: index, size: "sm", radius: "sm", variant: "light", color: "violet.5" }, filter.name));
            })))),
        React.createElement(Group, { justify: "end", mt: "lg", gap: "xs" },
            React.createElement(Tooltip, { label: t(i18nKeys.VIEWS.SETTINGS.DUPLICATE), withArrow: true },
                React.createElement(ActionIcon, { loading: isDuplicateViewLoading, variant: "outline", size: "input-xs", color: "gray.4", onClick: function () {
                        return duplicateView({ viewId: view.id, resourceType: view.resourceType }, { onSuccess: onDuplicate });
                    } },
                    React.createElement(IconCopy, { stroke: 1.7, size: 16 }))),
            !view.isDefault && (React.createElement(ActionIcon, { loading: isDeleteViewLoading, variant: "light", color: "red", size: "input-xs", onClick: function () {
                    setShowDeletionConfirm(true);
                } },
                React.createElement(IconTrash, { stroke: 1.5, size: 16 }))))));
};
var templateObject_1, templateObject_2;
