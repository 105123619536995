import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { Box, Group, Stack, Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { ThirdpartyIcon } from '../ThirdpartyIcon';
export var InvoiceKanbanCard = function (_a) {
    var invoice = _a.invoice;
    var t = useTranslation().t;
    var formatter = useLocalizedCurrencyFormatter();
    var reference = invoice.reference, debtor = invoice.debtor, remainingBalance = invoice.remainingBalance, daysLate = invoice.daysLate;
    return (React.createElement(Stack, { pos: "relative" },
        React.createElement(Box, { pos: "absolute", top: 0, right: 0 },
            React.createElement(ThirdpartyIcon, { invoice: invoice })),
        React.createElement(Stack, { gap: 4 },
            React.createElement(Text, { size: "sm", fw: 600 }, reference),
            React.createElement(Text, { size: "xs", c: "dimmed" }, debtor.fullName)),
        React.createElement(Group, { justify: "space-between" },
            React.createElement(Stack, { gap: 0 },
                React.createElement(Text, { size: "xs", c: "dimmed", fw: 350 }, t(i18nKeys.BALANCE)),
                React.createElement(Text, { size: "xs", c: "blue.7", fw: 600 }, formatter.format(remainingBalance))),
            React.createElement(Stack, { gap: 0, align: "flex-end" },
                React.createElement(Text, { size: "xs", c: "dimmed", fw: 350 }, upperFirst(t(i18nKeys.LATE))),
                React.createElement(Text, { size: "xs", fw: 600 }, t(i18nKeys.FOLLOW_UP.SUMMARY.DELAY_J, {
                    count: daysLate,
                }))))));
};
