import { __assign } from "tslib";
import { useGetWorkflows } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller } from 'react-hook-form';
import { CustomSelect } from 'shared/io';
import styleIdentifiers from './StepsCalculation.scss';
var styles = classNames.bind(styleIdentifiers);
var cleanWorkflow = function (_a) {
    var id = _a.id, attributes = _a.attributes;
    return (__assign({ id: id }, attributes));
};
export var StepsCalculationWorkflow = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'workflow_id' : _b, currentWorkflow = _a.currentWorkflow, setCurrentWorkflow = _a.setCurrentWorkflow, _c = _a.canUnselect, canUnselect = _c === void 0 ? true : _c;
    var t = useTranslation().t;
    var workflowsResponse = useGetWorkflows().data;
    var workflows = (workflowsResponse === null || workflowsResponse === void 0 ? void 0 : workflowsResponse.data) || [];
    var workflowsCleaned = workflows.map(cleanWorkflow);
    var concatenatedWorkflows = (currentWorkflow ? [currentWorkflow] : []).concat(workflows.filter(function (e) { return e.id !== (currentWorkflow === null || currentWorkflow === void 0 ? void 0 : currentWorkflow.id); }));
    return (React.createElement(Controller, { name: name, defaultValue: "", render: function () { return (React.createElement(CustomSelect, { canUnselect: canUnselect, selectClassName: styles('input'), withBorder: true, noMargin: true, placeholder: t(i18nKeys.DEFAULT), items: workflowsCleaned, keyValue: "id", keyText: "name", onChange: function (id) {
                setCurrentWorkflow(concatenatedWorkflows.find(function (e) { return e.id === id; }));
            }, name: name, label: t(i18nKeys.FORM.PAYMENT_PLAN.USED_WORKFLOW) })); } }));
};
