import { __assign } from "tslib";
import { useEffect } from 'react';
import { useForm, zodResolver } from '@mantine/form';
import { LoadInvoiceColumnVariablesSchema, useLoadInvoiceColumn, } from './use-load-invoice-column';
export function useLoadKanbanInvoices() {
    var searchParams = useSearchParams();
    var filtersForm = useForm({
        validate: zodResolver(LoadInvoiceColumnVariablesSchema),
        initialValues: {
            accountManagerId: null,
            column: 'late', // placholder for type correctness, overwritten below,
            debtorName: '',
            endDate: null,
            startDate: null,
            maxDaysLate: '',
            minDaysLate: '',
            reference: '',
            remainingBalance: {
                amount: '',
                operator: '<=',
            },
        },
    });
    useEffect(function () {
        var debtorName = searchParams.get('debtorName');
        if (debtorName)
            filtersForm.setFieldValue('debtorName', debtorName);
    }, []);
    var filters = filtersForm.getValues();
    var late = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'late' }));
    var firstReminder = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'first_reminder' }));
    var lastReminder = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'last_reminder' }));
    var formalNotice = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'formal_notice' }));
    var thirdPartyCase = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'third_party_case' }));
    var disputed = useLoadInvoiceColumn(__assign(__assign({}, filters), { column: 'disputed' }));
    return {
        columns: {
            late: late,
            firstReminder: firstReminder,
            lastReminder: lastReminder,
            formalNotice: formalNotice,
            thirdPartyCase: thirdPartyCase,
            disputed: disputed,
        },
        filtersForm: filtersForm,
    };
}
var useSearchParams = function () {
    var searchParams = new URLSearchParams(window.location.search);
    return {
        get: function (key) { return searchParams.get(key); },
        set: function (key, value) {
            searchParams.set(key, value);
            window.history.replaceState(null, '', "?".concat(searchParams.toString()));
        },
    };
};
