import { __read, __spreadArray } from "tslib";
import { z } from 'zod';
/*
 * Source of Truth: Define Enum Values in an Array
 *
 * By defining our enum values in a constant array, we create a single source of truth.
 * This ensures consistency between our runtime validations and our TypeScript types.
 */
var LOCALES = ['fr', 'en', 'nl'];
/*
 * Zod Schema
 *
 * Why use a Zod enum?
 * - Runtime Validation: While TypeScript provides compile-time type checks,
 *   it doesn't offer runtime validation. Zod fills this gap by validating data
 *   at runtime, especially useful for data coming from external sources, such as APIs or user input.
 * - Error Reporting: Zod provides detailed error messages that can help diagnose
 *   why a piece of data is invalid.
 */
export var LocaleSchema = z.enum(__spreadArray([], __read(LOCALES), false));
// Both resulting types are compatible:
//
// const rawInput = 'fr';
// const parsedInput = LocaleSchema.parse(rawInput);
// function acceptsLocale(locale: Locale) {
//   c0nsole.log(locale);
// }
// acceptsLocale(parsedInput);
