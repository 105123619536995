import { useSearchInvoices } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import InvoiceReferences from 'shared/components/InvoiceReferences/InvoiceReferences';
import UsedWorkflow from 'shared/components/UsedWorkflow/UsedWorkflow';
import { Button } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './PaymentPlanForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanFormStepsProperties = function (_a) {
    var recalculateSteps = _a.recalculateSteps, paymentProperties = _a.paymentProperties, paymentSteps = _a.paymentSteps;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var invoice_ids = paymentProperties.invoice_ids, due_date = paymentProperties.due_date, delay = paymentProperties.delay, with_fees = paymentProperties.with_fees, workflow_id = paymentProperties.workflow_id;
    var total_amount = paymentSteps.total_amount, debtor = paymentSteps.debtor;
    var invoicesResponse = useSearchInvoices({
        debtor_id: debtor.id,
    }).data;
    var filteredInvoices = invoicesResponse === null || invoicesResponse === void 0 ? void 0 : invoicesResponse.data.filter(function (invoice) {
        return invoice_ids.includes(invoice.id);
    });
    var invoiceIds = (filteredInvoices === null || filteredInvoices === void 0 ? void 0 : filteredInvoices.map(function (_a) {
        var id = _a.id;
        return id;
    })) || [];
    var full_name = debtor.attributes.full_name;
    return (React.createElement(Card, { title: t(i18nKeys.DETAILS), infosRight: React.createElement(Button, { small: true, noMargin: true, onClick: function () { return recalculateSteps(); }, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.RECALCULATE) }) },
        React.createElement("div", { className: styles('details') },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMON.CLIENT)),
                    React.createElement("span", { className: styles('value') }, full_name)),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICES)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(InvoiceReferences, { ids: invoiceIds }))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.WITH_FEES)),
                    React.createElement("span", { className: styles('value') }, t(i18nKeys[with_fees ? 'YES' : 'NO']))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PAYMENT_PLAN.USED_WORKFLOW)),
                    React.createElement("span", { className: styles('value') }, workflow_id ? React.createElement(UsedWorkflow, { workflowId: workflow_id }) : t(i18nKeys.DEFAULT)))),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DUE_DATE)),
                    React.createElement("span", { className: styles('value') }, formattedDate(due_date, currentLang))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY)),
                    React.createElement("span", { className: styles('value') }, t(i18nKeys.DAYS, { count: delay }))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TOTAL)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { value: total_amount, suffix: debtor.attributes.currency })))))));
};
