import React from 'react';
import { useDeleteAutomaticAssignation, useGetAutomaticAssignation, useGetAvailableConditions, useUpdateAutomaticAssignation, } from 'api';
import { AssignableModel, AssignableTo } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory, useParams } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';
import { AssignationConditions } from '../Listing/AssignationConditions';
import styleIdentifiers from './AutomaticAssignationDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AutomaticAssignationDetail() {
    var t = useTranslation().t;
    var history = useHistory();
    var availableConditionsListResponse = useGetAvailableConditions({}).data;
    var availableConditions = (availableConditionsListResponse === null || availableConditionsListResponse === void 0 ? void 0 : availableConditionsListResponse.data) || [];
    var automaticAssignationId = useParams().id;
    var _a = useGetAutomaticAssignation(automaticAssignationId), automaticAssignationResponse = _a.data, isLoading = _a.isLoading, isError = _a.isError, refetch = _a.refetch;
    var updateAutomaticAssignationMutation = useUpdateAutomaticAssignation().mutate;
    var deleteAutomaticAssignationMutation = useDeleteAutomaticAssignation().mutate;
    if (isLoading && !automaticAssignationResponse) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !automaticAssignationResponse) {
        return React.createElement("span", null, "Error");
    }
    var automaticAssignation = automaticAssignationResponse.data;
    var id = automaticAssignation.id, _b = automaticAssignation.attributes, model_to_assign = _b.model_to_assign, assign_to = _b.assign_to, assignation_conditions = _b.assignation_conditions, order = _b.order;
    var goToList = function () {
        history.goBack();
    };
    var handleUpdateAutomaticAssignation = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(AutomaticAssignationForm, { availableConditions: availableConditions, automaticAssignation: automaticAssignation, onSubmit: function (data) {
                    return updateAutomaticAssignationMutation({ id: id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var handleDeleteAutomaticAssignation = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.AUTOMATIC_ASSIGNATION_DELETE), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteAutomaticAssignationMutation({ id: id }, {
                        onSuccess: function () {
                            goToList();
                            dialogHide(DialogShowId.CONFIRM);
                        },
                    });
                } })),
        });
    };
    return (React.createElement("div", { className: styles('automatic-assignation-detail') },
        React.createElement("div", { className: styles('automatic-assignation-wrapper') },
            React.createElement("div", { className: styles('title-line') },
                React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
                React.createElement("h1", null, "".concat(t(i18nKeys.AUTOMATIC_ASSIGNATION), " - ").concat(id)),
                React.createElement("div", { className: styles('buttons-wrapper') },
                    React.createElement("div", null,
                        React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.OTHER_ACTIONS), selectorContent: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.FORM.ACTIONS), className: styles('button'), color: ButtonColor.BLUE, iconRight: IconName.MINIMAL_DOWN }) },
                            React.createElement("div", { className: styles('dropdown-item'), onClick: handleUpdateAutomaticAssignation }, t(i18nKeys.EDIT)),
                            React.createElement("div", { className: styles('dropdown-item'), onClick: handleDeleteAutomaticAssignation }, t(i18nKeys.DELETE)))))),
            React.createElement("div", { className: styles('line-wrapper') },
                React.createElement(Card, { title: t(i18nKeys.CLIENT.GENERAL_INFO), className: styles('automatic-assignation-info', 'box') },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER)),
                            React.createElement("span", { className: styles('value', 'blue') }, order)),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN)),
                            React.createElement("span", { className: styles('value', 'blue') }, t(i18nKeys.COMMON[model_to_assign === AssignableModel.debtor ? 'CLIENT' : 'INVOICE']))),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO)),
                            React.createElement("span", { className: styles('value') }, t(i18nKeys[assign_to.type === AssignableTo.recovery_plan
                                ? 'RECOVERY_PLAN'
                                : 'ACCOUNT_MANAGER']))),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, " "),
                            React.createElement("span", { className: styles('value') }, assign_to.name)))),
                React.createElement(Card, { title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS), className: styles('task-info', 'box') },
                    React.createElement(AssignationConditions, { items: assignation_conditions, availableConditions: availableConditions, detailView: true }))))));
}
