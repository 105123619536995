import classNames from 'classnames/bind';
import React from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimelineItem = function (_a) {
    var type = _a.type, children = _a.children;
    return (React.createElement("div", { className: styles('item', type) },
        React.createElement("div", { className: styles('see-detail') },
            React.createElement(Icon, { name: IconName.EXTERNAL_LINK })),
        children));
};
