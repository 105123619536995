import { __assign, __read, __spreadArray } from "tslib";
import React from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { initial, last } from 'lodash-es';
import { invert, isNullish, omit } from 'remeda';
import { useAgedBalanceColors } from 'shared/hooks';
import { useAgedBalanceAccentColors } from 'shared/hooks/utils';
import { useLocalizedCurrencyFormatter, useSafeLocalizedCompactCurrencyFormatter, } from 'shared/utils/normalization';
import { Box, Checkbox, Divider, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ResponsiveLine } from '@nivo/line';
import { styled } from '@stitches/react';
var Dot = styled('div', {
    width: 12,
    height: 12,
    borderRadius: '50%',
});
export var AgedBalanceOverTimeChart = function (_a) {
    var agedBalanceOverTime = _a.agedBalanceOverTime;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var colors = useAgedBalanceColors();
    var formatter = useLocalizedCurrencyFormatter();
    var compactFormatter = useSafeLocalizedCompactCurrencyFormatter();
    var form = useForm({
        initialValues: {
            '0': true,
            '1': true,
            '2': true,
            '3': true,
            '4': true,
        },
    });
    if (isNullish(agedBalanceOverTime))
        return null;
    var lines = Object.entries(omit(colors, ['totalDue'])).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], color = _b[1];
        return ({
            id: t(i18nKeys.ANALYTICS.AGED_BALANCE[key]),
            color: color,
            data: Object.entries(agedBalanceOverTime.data).map(function (_a) {
                var _b = __read(_a, 2), date = _b[0], balance = _b[1];
                return ({
                    x: date,
                    y: balance.debits[key] - balance.credits[key],
                    color: color,
                });
            }),
        });
    });
    var linesOrdered = __spreadArray([last(lines)], __read(initial(lines)), false);
    var indicesToHide = Object.entries(form.values).reduce(function (acc, _a) {
        var _b = __read(_a, 2), index = _b[0], flag = _b[1];
        if (!flag)
            return __spreadArray(__spreadArray([], __read(acc), false), [Number(index)], false);
        return acc;
    }, []);
    var linesFiltered = linesOrdered.filter(function (_, index) { return !indicesToHide.includes(index); });
    var isOnlyOneLineEnabled = linesFiltered.length === 1;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { w: "100%", h: 460 },
            React.createElement(ResponsiveLine, { yScale: {
                    stacked: true,
                    type: 'linear',
                }, colors: function (d) { return d.color; }, margin: { top: 10, left: 85, bottom: 50, right: 50 }, data: linesFiltered, isInteractive: true, enableSlices: "x", enableGridX: true, lineWidth: 4, pointSymbol: CustomPointSymbol, yFormat: function (val) { return formatter.format(Number(val)); }, pointLabel: function () { return 'test'; }, axisBottom: {
                    tickSize: 0,
                    tickPadding: 20,
                    tickRotation: -25,
                    format: function (date) { return dayjs(date).format('DD MMM'); },
                }, axisLeft: {
                    tickSize: 0,
                    tickPadding: 20,
                    format: function (value) { return compactFormatter.format(value); },
                }, curve: "monotoneX", enableArea: true, areaOpacity: 0.7, sliceTooltip: function (_a) {
                    var slice = _a.slice;
                    var total = slice.points[0].data.yStacked;
                    return (React.createElement(Box, { p: "md", bg: "white", style: {
                            borderRadius: 'var(--mantine-radius-xs)',
                            boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.05)',
                        } },
                        React.createElement(Group, null,
                            React.createElement(Stack, null,
                                slice.points.map(function (point) { return (React.createElement(Group, { key: point.id },
                                    React.createElement(Dot, { style: { backgroundColor: point.serieColor } }),
                                    React.createElement(Text, { my: "0" }, point.serieId))); }),
                                React.createElement(Divider, { my: "xs", w: "115%" }),
                                React.createElement(Group, { mt: 0 },
                                    React.createElement(Dot, { style: { opacity: 0 } }),
                                    React.createElement(Text, { my: "0" }, "Total"))),
                            React.createElement(Stack, null,
                                slice.points.map(function (point) { return (React.createElement(Text, { my: "0", fw: "bold", key: point.id }, formatter.format(Number(point.data.y)))); }),
                                React.createElement(Divider, { my: "xs" }),
                                React.createElement(Text, { mt: 0, fw: "bold" }, formatter.format(Number(total)))))));
                }, theme: {
                    grid: {
                        line: {
                            stroke: theme.colors.gray[3],
                            strokeDasharray: 7,
                            strokeDashoffset: 15,
                        },
                    },
                    labels: {
                        text: {
                            fontSize: 15,
                            fontWeight: 500,
                            fontFamily: 'Work Sans',
                            fill: theme.colors.gray[6],
                        },
                    },
                    text: {
                        fontSize: 14,
                        fontFamily: 'Work Sans',
                        fill: theme.colors.gray[6],
                    },
                } })),
        React.createElement(Group, { w: "100%", mt: "sm", mb: "md", justify: "center", gap: "xl" }, linesOrdered.map(function (line, index) {
            return (React.createElement(Checkbox, __assign({ size: "xs", style: { cursor: 'pointer' }, key: index }, form.getInputProps(String(index), { type: 'checkbox' }), { label: line.id, color: line.color, disabled: isOnlyOneLineEnabled && form.values[String(index)] })));
        }))));
};
var CustomPointSymbol = function (_a) {
    var color = _a.color;
    var colors = useAgedBalanceColors();
    var accentColors = useAgedBalanceAccentColors();
    var colorsReversed = invert(colors);
    var keyForThisLine = colorsReversed[color];
    var accentColor = accentColors[keyForThisLine];
    return (React.createElement("g", null,
        React.createElement("circle", { r: 7, fill: accentColor }),
        React.createElement("circle", { r: 5, strokeWidth: 2, stroke: "white", fill: color })));
};
