import { __read } from "tslib";
import React from 'react';
import { useHistory } from 'react-router';
import Header from 'shared/components/Header';
import Nav from 'shared/components/Nav';
import { AppShell, Box, Burger } from '@mantine/core';
import { useDisclosure, useHotkeys, useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { useTenant } from '../TenantValidator';
import { WarningBanners } from './WarningBanners';
export var PrivateRoutesWrapper = function (_a) {
    var children = _a.children;
    var tenant = useTenant();
    var history = useHistory();
    var _b = __read(useDisclosure(), 2), isMobileOpened = _b[0], _c = _b[1], toggleMobile = _c.toggle, closeMobile = _c.close;
    var _d = __read(useLocalStorage({
        key: 'open-navbar',
        defaultValue: true,
    }), 2), isDesktopOpened = _d[0], setIsDesktopOpened = _d[1];
    var isMobile = useMediaQuery('(max-width: 48em)');
    var toggleDesktop = function () {
        setIsDesktopOpened(function (state) { return !state; });
    };
    useHotkeys([
        ['mod+B', toggleDesktop],
        ['mod+H', function () { return history.push('/dashboard'); }],
    ]);
    return (React.createElement(AppShell, { padding: "xl", header: { height: 60 }, navbar: {
            width: 258,
            breakpoint: 'sm',
            collapsed: { mobile: !isMobileOpened, desktop: !isDesktopOpened },
        }, styles: {
            header: { zIndex: 10 },
            navbar: { zIndex: 10 },
            main: { zIndex: 0 },
        } },
        React.createElement(AppShell.Header, null,
            React.createElement(Header, { burger: isMobile ? (React.createElement(Box, { c: "white", pl: isMobile ? 'lg' : '20px' },
                    React.createElement(Burger, { color: "white", opened: isMobileOpened, onClick: toggleMobile, size: "sm" }))) : null })),
        tenant.onboarding.isComplete && (React.createElement(AppShell.Navbar, null,
            React.createElement(Nav, { onClickItem: isMobile ? closeMobile : function () { } }))),
        React.createElement(AppShell.Main, { style: { overflowY: 'auto', height: 'calc(100vh - 60px)' }, bg: "#f4f4f4" },
            React.createElement(WarningBanners, null),
            children)));
};
