import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import { IconName } from 'shared/components/Icon';
import ImportCsvForm from 'shared/forms/ImportCsvForm';
import { Button, UploadZone } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { treatRecoveryPlan } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';
import { formActions } from 'store/form/form.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, progressReset, showDialog, } from 'store/view/view.actions';
import styleIdentifiers from './ImportedInvoices.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImportedInvoices() {
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var ublFiles = useSelector(function (state) { return state.form.ublFiles; });
    var progress = useSelector(function (state) { return state.view.progress; });
    var uploadChange = function (files) {
        formActions.changeValue({ key: 'ublFiles', value: files });
        progressReset();
    };
    var onUploadPdf = function (files) {
        formActions.changeValue({ key: 'pdfFiles', value: files });
        if (location.pathname !== '/invoices/import-pdf')
            history.push('/invoices/import-pdf');
    };
    var onUploadCsv = function (file) {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.IMPORT_CSV),
            children: React.createElement(ImportCsvForm, { file: file }),
        });
    };
    var chooseRecoveryPlan = function (e) {
        e.stopPropagation();
        if (company.active_plans_count > 1) {
            settingsActions.recoveryPlanList({
                callback: function (res) {
                    var plans = res.data.map(function (plan) { return treatRecoveryPlan(plan); });
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.PLAN.SELECT_PLAN),
                        children: (React.createElement(ChooseRecoveryPlanModal, { recoverPlans: plans, onClick: function (planId) {
                                importUbl(planId);
                                dialogHide(DialogShowId.CUSTOM);
                            } })),
                    });
                },
            });
        }
        else {
            importUbl();
        }
    };
    var importUbl = function (planId) {
        accountActions.importInvoice({
            ublFiles: ublFiles,
            planId: planId,
            callback: function () {
                formActions.changeValue({ key: 'ublFiles', value: null });
            },
        });
    };
    return (React.createElement("div", { className: styles('imported-invoices') },
        React.createElement(PageTitle, null,
            t(i18nKeys.INVOICES_IMPORT),
            React.createElement(PageTitle.Actions, null, company.unvalidated_invoices_count > 0 ? (React.createElement(Button, { noMargin: true, onClick: function () { return history.push('/invoices/to-confirm'); } },
                t(i18nKeys.INVOICING.IMPORT.SEE_INVOICES_TO_CONFIRM),
                React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'blue') }, company.unvalidated_invoices_count))) : null)),
        React.createElement("div", { className: styles('card', 'content') },
            React.createElement(UploadZone, { noMargin: true, icon: IconName.XML, accept: ".xml", info: t(i18nKeys.INVOICING.IMPORT.UBL_INVOICES), noPreview: true, onChange: uploadChange, multiple: true, progress: progress, action: chooseRecoveryPlan, value: ublFiles }),
            React.createElement(UploadZone, { icon: IconName.PDF, accept: ".pdf", info: t(i18nKeys.INVOICING.IMPORT.PDF_INVOICES), noPreview: true, onChange: onUploadPdf, multiple: true, noMargin: true }),
            React.createElement("div", { className: styles('csv-container') },
                React.createElement(UploadZone, { className: styles('upload-zone'), icon: IconName.CSV, accept: ".csv", info: t(i18nKeys.INVOICING.IMPORT.CSV_INVOICES), noPreview: true, onChange: onUploadCsv, noMargin: true }),
                React.createElement("div", { className: styles('mobile-element') }, t(i18nKeys.UNAVAILABLE_WITH_SCREEN_SIZE))),
            React.createElement("div", { className: styles('mobile-element') }, t(i18nKeys.UNAVAILABLE_WITH_SCREEN_SIZE)))));
}
