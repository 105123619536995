import { __assign, __rest } from "tslib";
import React from 'react';
import { useCreateTaskComment, useDeclinePaymentPlanRequest, useGetActivities, useGetTask, useUpdateTask, useUpdateTasksStatus, } from 'api';
import { TaskActionType, } from 'api/models';
import { Comments } from 'app/Private/Comments';
import InvoiceTimeline from 'app/Private/Invoices/ToHandleDetail/InvoiceTimeline/InvoiceTimeline';
import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { omit } from 'lodash-es';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import Tooltip from 'shared/components/Tooltip';
import { Button, ButtonColor } from 'shared/io';
import { formatActivities } from 'shared/utils/activities.utils';
import { dialogHide, DialogShowId, sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { getAssigneeFullName, getTaskTypeDescription } from '../Task.utils';
import { TaskActions } from '../TaskActions/TaskActions';
import { TaskForm } from '../TaskForm/TaskForm';
import { TasksListItemStatus } from '../TasksList/TasksListItemStatus';
import styleIdentifiers from './TaskDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskDetail = function () {
    var _a = useTranslation(), t = _a.t, currentLang = _a.currentLang;
    var history = useHistory();
    var task_types = useSelector(function (state) { return state.app.constants; }).task_types;
    var taskId = useParams().id;
    var _b = useGetTask(taskId), taskResponse = _b.data, isLoading = _b.isLoading, isError = _b.isError, refetch = _b.refetch;
    var updateTaskMutation = useUpdateTask().mutate;
    var updateTasksStatus = useUpdateTasksStatus().mutate;
    var createTaskComment = useCreateTaskComment().mutate;
    var declinePaymentPlanRequest = useDeclinePaymentPlanRequest().mutate;
    var _c = useGetActivities({
        task_id: taskId,
    }), activitiesListResponse = _c.data, refetchActivities = _c.refetch, activitiesLoading = _c.isLoading, activitiesError = _c.isError;
    if (isLoading && !taskResponse && activitiesLoading) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !taskResponse || activitiesError || !activitiesListResponse) {
        return React.createElement("span", null, "Error");
    }
    var goToList = function () {
        history.goBack();
    };
    var task = taskResponse.data;
    var id = task.id, _d = task.attributes, title = _d.title, created_at = _d.created_at, status_key = _d.status_key, status = _d.status, debtor = _d.debtor, description = _d.description, invoices = _d.invoices, due_date = _d.due_date, days_postponed = _d.days_postponed, due_date_adjusted = _d.due_date_adjusted, comments = _d.comments, completed = _d.completed, skipped = _d.skipped, uid = _d.task_type.attributes.uid;
    var handleUpdateTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { task: task, onSubmit: function (data) {
                    return updateTaskMutation({ id: taskId, data: data }, {
                        onSuccess: function () {
                            refetch();
                            refetchActivities();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var handleUpdateStatus = function (data) {
        return updateTasksStatus({
            data: data,
        }, {
            onSuccess: function () {
                if (data.action_type === TaskActionType.delete) {
                    goToList();
                }
                else {
                    refetch();
                    refetchActivities();
                }
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    // TODO REWORK AttachedInvoicesList Component
    var invoicesFormated = __assign(__assign({ type: 'attached_invoice' }, omit(invoices, ['details'])), { details: invoices.details.map(function (_a) {
            var invoiceId = _a.id, _b = _a.attributes, due_date_invoice = _b.due_date, localized_money_object = _b.localized_money_object, rest = __rest(_b, ["due_date", "localized_money_object"]);
            return (__assign({ id: Number(invoiceId), due_date: moment.utc(due_date_invoice), localized_money_object: localized_money_object }, rest));
        }) });
    var activitiesListFormated = formatActivities(activitiesListResponse);
    var contactInfo = function () {
        switch (task.attributes.task_type_id) {
            case 1:
                return (React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PHONES)),
                    debtor.attributes.phones.map(function (_a) {
                        var number = _a.attributes.number;
                        return (React.createElement("a", { key: number, href: "tel:".concat(number), className: styles('value') },
                            React.createElement("div", { className: styles('value') }, number)));
                    })));
            case 2:
                return (React.createElement("div", { className: styles('item') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.EMAIL)),
                    debtor.attributes.emails.map(function (email) { return (React.createElement("a", { key: email, href: "mailto:".concat(email), className: styles('value', 'block') },
                        React.createElement("span", null, email))); })));
            default:
                return null;
        }
    };
    var submitComment = function (data) {
        return createTaskComment({
            id: taskId,
            data: data,
        }, {
            onSuccess: function () {
                refetch();
                refetchActivities();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var goToPaymentPlanCreation = function () {
        history.push("/payment-plans/create?debtor_id=".concat(debtor === null || debtor === void 0 ? void 0 : debtor.id, "&invoice_ids=").concat(invoices.details.map(function (_a) {
            var invoiceId = _a.id;
            return invoiceId;
        })));
    };
    var refusePlan = function () {
        return declinePaymentPlanRequest({
            data: { task_id: taskId },
        }, {
            onSuccess: function () {
                refetch();
                refetchActivities();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var skippedOrCompleted = skipped || completed;
    return (React.createElement("div", { className: styles('task-detail') },
        React.createElement("div", { className: styles('task-wrapper') },
            React.createElement("div", { className: styles('title-line') },
                React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
                React.createElement("h1", null, title || id),
                React.createElement("div", { className: styles('buttons-wrapper') },
                    uid === 'payment_plan' && (React.createElement(React.Fragment, null,
                        React.createElement(Button, { noMargin: true, label: t(i18nKeys.TASK.PAYMENT_PLAN.ACCEPT), color: ButtonColor.GREEN, onClick: goToPaymentPlanCreation, disabled: skippedOrCompleted, noShadow: true }),
                        React.createElement(Button, { noMargin: true, label: t(i18nKeys.TASK.PAYMENT_PLAN.DECLINE), color: ButtonColor.RED, onClick: refusePlan, disabled: skippedOrCompleted, noShadow: true }))),
                    React.createElement(TaskActions, { taskIds: [id], onClick: handleUpdateStatus },
                        React.createElement("div", { className: styles('dropdown-item'), onClick: function () {
                                handleUpdateTask();
                            } }, t(i18nKeys.EDIT))))),
            React.createElement("div", { className: styles('line-wrapper') },
                React.createElement(Card, { title: t(i18nKeys.CLIENT.GENERAL_INFO), className: styles('task-info', 'box') },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TASK.ATTRIBUTES.TITLE)),
                            React.createElement("span", { className: styles('value', 'blue', 'bigger') }, title)),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CREATION_DATE)),
                            React.createElement(DateItem, { date: new Date(created_at), lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') })),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DUE_DATE)),
                            due_date_adjusted ? (React.createElement(DateItem, { date: new Date(due_date_adjusted), lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') }, !!days_postponed && (React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", color: IconColor.RED },
                                React.createElement("div", null,
                                    React.createElement(DateItem, { date: new Date(due_date), lg: currentLang, format: "DD/MM/YY", className: styles('value', 'white', 'bigger') }),
                                    "+ ",
                                    t(i18nKeys.DAY_S, { count: days_postponed })))))) : (React.createElement("span", { className: styles('value', 'blue', 'bigger') }, "-"))),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE)),
                            React.createElement("span", { className: styles('value') }, getTaskTypeDescription(task_types, task))),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TASK.ATTRIBUTES.ASSIGNEE)),
                            React.createElement("div", { className: styles('value') }, getAssigneeFullName(task))))),
                React.createElement(Card, { title: t(i18nKeys.DETAILS), className: styles('task-info', 'box') },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TASK.ATTRIBUTES.STATUS)),
                            React.createElement("span", null,
                                React.createElement(TasksListItemStatus, { statusKey: status_key, status: status }))),
                        debtor && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles('item') },
                                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TASK.ATTRIBUTES.DEBTOR)),
                                React.createElement("span", { className: styles('value') },
                                    React.createElement(NavLink, { to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name))),
                            contactInfo())),
                        React.createElement("div", { className: styles('item') },
                            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DESCRIPTION)),
                            React.createElement("div", { className: styles('value') }, description))))),
            !!invoices.details.length && (React.createElement(AttachedInvoicesList, { title: t(i18nKeys.TASK.ATTRIBUTES.INVOICE), invoices: invoicesFormated })),
            !!activitiesListFormated.activities.length && (React.createElement(InvoiceTimeline, { metadata: activitiesListFormated.metadata, activities: activitiesListFormated.activities })),
            React.createElement(Comments, { comments: comments, submitComment: submitComment }))));
};
