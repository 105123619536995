import { __assign } from "tslib";
import loadLang from 'locales/';
import { isDevelopment } from 'shared/utils/environment';
import { translateActions } from 'store/translate/translate.actions';
import { captureMessage } from '@sentry/react';
import { storeSetup } from '../../entry';
var loadedText = loadLang('fr');
var fallbackText = {};
var defaultState = {
    // TODO: This should be removed and replaced in favor of literally anything else
    availableLang: [
        { description: 'Français', value: 'fr' },
        { description: 'English', value: 'en' },
        { description: 'Nederlands', value: 'nl' },
    ],
    currentLang: 'fr', // TODO: This should be removed and replaced in favor of useProfile().locale everywhere else
    text: function (key) { return searchText(key, loadedText); },
};
export default (function (state, _a) {
    if (state === void 0) { state = defaultState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case translateActions.changeLang.request:
            if (state.currentLang !== payload) {
                loadedText = loadLang(payload);
            }
            return __assign(__assign({}, state), { currentLang: payload });
        default:
            return state;
    }
});
var reportProblem = function (key) {
    if (Object.keys(loadedText).length !== 0) {
        var currentLang = storeSetup.store.getState().translate.currentLang;
        if (isDevelopment()) {
            throw new Error("Missing translation for ".concat(currentLang, " - ").concat(key));
        }
        captureMessage("Missing translation for ".concat(currentLang, " - ").concat(key));
        if (Object.keys(fallbackText).length === 0) {
            fallbackText = loadLang('fr');
        }
        return searchText(key, fallbackText);
    }
    return 'Missing translation';
};
var searchText = function (key, text) {
    return key.split('.').reduce(function (prev, curr) { return (prev && prev[curr]) || reportProblem(key); }, text);
};
