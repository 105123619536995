/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var ObjectType = {
    address: 'address',
    phone: 'phone',
    user: 'user',
    short_user: 'short_user',
    light_invoice: 'light_invoice',
    attached_invoice: 'attached_invoice',
    debtor: 'debtor',
    task: 'task',
    product: 'product',
    recovery_plan: 'recovery_plan',
    recovery_plan_short: 'recovery_plan_short',
    recovery_step: 'recovery_step',
    template: 'template',
    template_short: 'template_short',
    assignation_condition: 'assignation_condition',
    automatic_assignation: 'automatic_assignation',
    activity: 'activity',
    external_mail: 'external_mail',
    contactPerson: 'contactPerson',
    credit_note: 'credit_note',
    invoice: 'invoice',
    accounting_payment: 'accounting_payment',
    payment: 'payment',
    debtor_reaction: 'debtor_reaction',
};
