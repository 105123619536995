import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useForm } from 'react-hook-form';
import { useSendInvitation } from 'shared/hooks';
import { Button, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import { sideMenuHide } from 'store/view/view.actions';
import styleIdentifiers from './inviteCollaboratorForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InviteCollaboratorForm() {
    var t = useTranslation().t;
    var _a = useSendInvitation(), sendInvitation = _a.sendInvitation, isSendInvitationLoading = _a.isSendInvitationLoading;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    return (React.createElement("form", { className: styles('InviteCollaboratorForm'), onSubmit: handleSubmit(function (newUserData) {
            return sendInvitation(newUserData, {
                onSuccess: sideMenuHide,
            });
        }) },
        React.createElement("div", { className: styles('head') }, t(i18nKeys.FORM.INVITE_STAFF.TITLE)),
        React.createElement("div", { className: styles('content') },
            React.createElement(Input, { errorMessage: errors.firstName, className: styles('input'), withBorder: true, noMargin: true, register: register('firstName', { required: true }), type: "text", label: t(i18nKeys.FORM.FIRSTNAME) }),
            React.createElement(Input, { errorMessage: errors.lastName, className: styles('input'), withBorder: true, register: register('lastName', { required: true }), type: "text", label: t(i18nKeys.FORM.LASTNAME) }),
            React.createElement(Input, { errorMessage: errors.email, className: styles('input'), withBorder: true, register: register('email', {
                    required: true,
                    validate: {
                        email: email,
                    },
                }), type: "text", label: t(i18nKeys.EMAIL) })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { disabled: isSendInvitationLoading, noShadow: true, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" }))));
}
