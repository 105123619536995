import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { useProfile } from 'shared/hooks';
import { Dropdown } from 'shared/io';
import apiService from 'shared/service/api.service';
import { useGetCompany } from 'shared/utils/selectors';
import Avatar from '../Avatar';
import styleIdentifiers from './SwitchTenant.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SwitchTenant(_a) {
    var _b;
    var className = _a.className, rest = __rest(_a, ["className"]);
    var company = useGetCompany();
    var profile = useProfile();
    var otherTenants = profile.accessibleTenants
        .filter(function (_tenant) { return _tenant.subdomain !== apiService.tenant; })
        .toSorted(function (a, b) { return a.companyName.localeCompare(b.companyName); });
    if (!company || !otherTenants.length)
        return null;
    return (React.createElement(Dropdown, __assign({ className: styles(className, 'SwitchTenant'), contentClass: styles('scrollable'), toggleContent: true, selectorContent: React.createElement(Avatar, { size: 35, name: company.name, logoUrl: (_b = company === null || company === void 0 ? void 0 : company.invoice_customization) === null || _b === void 0 ? void 0 : _b.logo_url }) }, rest),
        React.createElement("ul", { className: styles('menu') }, otherTenants.map(function (_a) {
            var companyName = _a.companyName, logo = _a.logo, url = _a.url;
            return (React.createElement("li", { key: companyName },
                React.createElement("a", { href: url, className: styles('link'), rel: "noopener noreferrer" },
                    React.createElement(Avatar, { name: companyName, logoUrl: logo }),
                    React.createElement("span", null, companyName))));
        }))));
}
