import React from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import EmailRendering from 'shared/components/EmailRendering/EmailRendering';
import { CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';
export default function SendInvoiceFormEmail(_a) {
    var emails = _a.emails;
    var t = useTranslation().t;
    var register = useFormContext().register;
    return (React.createElement(Controller, { name: "emails", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "emails", label: t(i18nKeys.EMAILS), items: emails, inputValidation: email, keyValue: "value", registerMultipleInput: register('emailsinput', {
                validate: {
                    emailInputNotEmpty: function (value) { return !value; },
                },
            }), info: t(i18nKeys.FORM.CONFIRM_EMAIL), itemRendering: function (_a) {
                var item = _a.item;
                return React.createElement(EmailRendering, { data: item });
            }, multiple: true, canAdd: true })); } }));
}
