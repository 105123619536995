import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult, useAxiosInstance } from './utils';
export var AcceptGcollectRatesVariablesSchema = z.object({
    thirdPartyCaseIds: z.array(z.number()),
    acceptRates: z.boolean(),
});
function acceptGcollectRatesMutationFn(axiosInstance, vars) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    data = instance.post('gcollect_cases/accept_rates', AcceptGcollectRatesVariablesSchema.parse(vars));
                    return [2 /*return*/, data];
            }
        });
    });
}
export function useAcceptGcollectRates() {
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (vars) {
            return acceptGcollectRatesMutationFn(axiosInstance, vars);
        },
        onError: function (error) {
            var _a;
            console.error(error);
            notifications.show({
                title: 'Error',
                color: 'red',
                message: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message,
            });
        },
    });
    return addActionNameToMutationResult('acceptGcollectRates', mutation);
}
