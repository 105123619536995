import { __assign, __generator, __rest } from "tslib";
import { i18nKeys } from 'locales';
import { call } from 'redux-saga/effects';
import { logout } from 'store/session/session.actions';
import { error, success } from 'store/view/view.actions';
import apiService from '../shared/service/api.service';
export function apiCall(options) {
    var response, requestData, data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                options.headers = {};
                if (options.allOrigin) {
                    options.headers['Access-Control-Allow-Origin'] = '*';
                }
                options.name = apiService.tenant;
                return [4 /*yield*/, call(options.api, options)];
            case 1:
                response = _b.sent();
                requestData = options.data;
                if (!!response) return [3 /*break*/, 8];
                if (!!options.noFeedback) return [3 /*break*/, 3];
                return [4 /*yield*/, error({
                        text: i18nKeys.ERROR.SERVER_NOT_RESPONDING,
                    })];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                if (!options.actionFailure) return [3 /*break*/, 5];
                return [4 /*yield*/, options.actionFailure()];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!options.actionRes) return [3 /*break*/, 7];
                return [4 /*yield*/, options.actionRes({}, 'rejected', requestData)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: 
            // todo: could produce a not found error
            return [2 /*return*/, null];
            case 8:
                data = response.data || null;
                if (!(response.status >= 400)) return [3 /*break*/, 19];
                if (!(response.status === 401)) return [3 /*break*/, 12];
                if (!!options.noFeedback) return [3 /*break*/, 10];
                return [4 /*yield*/, error({
                        data: data && (data.error_message || data.errors),
                    })];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [4 /*yield*/, logout()];
            case 11:
                _b.sent();
                return [2 /*return*/, response];
            case 12:
                if (!!options.noFeedback) return [3 /*break*/, 14];
                return [4 /*yield*/, error({
                        data: data && (data.error_message || (data.errors && data.errors.full_messages) || data.errors),
                    })];
            case 13:
                _b.sent();
                _b.label = 14;
            case 14:
                if (!options.actionFailure) return [3 /*break*/, 16];
                return [4 /*yield*/, options.actionFailure(response, requestData)];
            case 15:
                _b.sent();
                _b.label = 16;
            case 16:
                if (!options.actionRes) return [3 /*break*/, 18];
                return [4 /*yield*/, options.actionRes(response, 'rejected', requestData)];
            case 17:
                _b.sent();
                _b.label = 18;
            case 18: return [2 /*return*/, response];
            case 19:
                if (!(response.status === 200)) return [3 /*break*/, 23];
                if (!options.actionRes) return [3 /*break*/, 21];
                return [4 /*yield*/, options.actionRes(data, 'fulfilled', requestData, options.reset, options.id)];
            case 20:
                _b.sent();
                _b.label = 21;
            case 21:
                if (options.callback) {
                    options.callback(data);
                }
                if (!(!options.noSuccess && !options.noFeedback && (data.message || options.success))) return [3 /*break*/, 23];
                return [4 /*yield*/, success((_a = {},
                        _a[data.message ? 'data' : 'text'] = data.message || options.success,
                        _a))];
            case 22:
                _b.sent();
                _b.label = 23;
            case 23: return [2 /*return*/, response];
        }
    });
}
export var sendApi = function (api, actionRes, customPayload) {
    return function (action) {
        var _a, id, apiPayload;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = (customPayload ? customPayload(action.payload) : action.payload) || {}, id = _a.id, apiPayload = __rest(_a, ["id"]);
                    return [4 /*yield*/, apiCall(__assign({ api: api, actionRes: actionRes, id: id }, apiPayload))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default apiCall;
