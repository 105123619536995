import { buildActions, makeReqCons } from 'store/actions';
export var onBoardingConstants = {
    companyDetails: makeReqCons('company_details'),
    stripe: makeReqCons('stripe'),
    noPayment: makeReqCons('no_payment'),
    codaboxRequest: makeReqCons('codabox_request'),
    changeCodaboxMessage: makeReqCons('codabox_message'),
    codaboxToken: makeReqCons('codabox_token'),
    ponto: makeReqCons('ponto'),
    exact: makeReqCons('exact'),
    exactFetchCompanies: makeReqCons('onboarding_exact_fetch_companies'),
    bankAccount: makeReqCons('bankAccount'),
    setupDebtCollectionSettings: makeReqCons('debt_collection_settings'),
    companyIdentity: makeReqCons('color_logo'),
    twikey: makeReqCons('twikey'),
    yuki: makeReqCons('yuki'),
    horus: makeReqCons('horus'),
    horusFetchCompanies: makeReqCons('onboarding_horus_fetch_companies'),
    teamleader: makeReqCons('teamleader'),
    billit: makeReqCons('billit'),
    uploadTerms: makeReqCons('uploadTerms'),
};
var onBoardingActions = buildActions('', onBoardingConstants, {});
export { onBoardingActions };
