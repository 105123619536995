import { __assign } from "tslib";
import { ExternalMailReadStatus, ExternalMailStatus } from 'api/models';
import { i18nKeys } from 'locales';
export var TABLE_HEADERS = [
    {
        key: 'subject',
        title: i18nKeys.SUBJECT,
    },
    {
        key: 'from_mail',
        title: i18nKeys.FROM,
    },
    {
        key: 'status',
        title: i18nKeys.STATUS,
    },
    {
        key: 'date',
        title: i18nKeys.DATE,
    },
    {
        title: i18nKeys.FORM.ACTIONS,
        width: 100,
    },
];
export var EXTERNAL_MAIL_STATUSES = Object.values(__assign(__assign({}, ExternalMailStatus), ExternalMailReadStatus)).map(function (value) { return ({
    description: i18nKeys.MAILS.STATUS[value.toUpperCase()],
    value: value,
}); });
