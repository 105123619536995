import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import DateItem from 'shared/components/DateItem';
import { Button, ButtonColor } from 'shared/io';
import { Badge } from '@mantine/core';
import styleIdentifiers from './ExternalMailDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailDetailInfoRight = function (_a) {
    var externalMail = _a.externalMail, setFullDetail = _a.setFullDetail, _b = _a.isReply, isReply = _b === void 0 ? false : _b;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = externalMail.attributes, date = _d.date, status = _d.status;
    return (React.createElement("div", { className: styles('info-right') },
        React.createElement("span", { className: styles('date') },
            React.createElement(DateItem, { date: new Date(date), lg: currentLang, format: "DD/MM/YY - HH:mm", className: styles('value', 'blue', 'bigger') })),
        !isReply && (React.createElement(React.Fragment, null,
            React.createElement(Badge, { variant: "light", color: status === 'processed' ? 'green' : 'blue' }, t(i18nKeys.MAILS.STATUS[status.toUpperCase()])),
            React.createElement(Button, { noShadow: true, color: ButtonColor.GREY, label: t(i18nKeys.INVOICING.SEE_DETAIL), noMargin: true, small: true, onClick: function () { return setFullDetail === null || setFullDetail === void 0 ? void 0 : setFullDetail(); } })))));
};
