import { TaskStatus } from 'api/models';
import classNames from 'classnames/bind';
import React from 'react';
import styleIdentifiers from './TasksListItemStatus.scss';
var styles = classNames.bind(styleIdentifiers);
export var TasksListItemStatus = function (_a) {
    var className = _a.className, statusKey = _a.statusKey, status = _a.status;
    return (React.createElement("div", { className: styles('task-status-item', className) },
        React.createElement("div", { className: styles('label') }, status),
        React.createElement("div", { className: styles('indicator') },
            React.createElement("span", { className: styles("step-".concat(statusKey)) }),
            statusKey === TaskStatus.pending ? (React.createElement("span", null)) : (React.createElement("span", { className: styles("step-".concat(statusKey)) })))));
};
