import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { Fragment } from 'react';
import styleIdentifiers from './RecoveryPlan.scss';
import { RecoveryPlanAddStep } from './RecoveryPlanAddStep';
import { RecoveryPlanStepLine } from './RecoveryPlanStepLine';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryPlanStepEl = function (_a) {
    var add = _a.add, array = _a.array, arrayIndex = _a.arrayIndex, props = __rest(_a, ["add", "array", "arrayIndex"]);
    var t = useTranslation().t;
    var index = props.index, _b = props.step, id = _b.id, before_due_date = _b.before_due_date, step_type = _b.step_type, onClickOnStep = props.onClickOnStep, stepHighlight = props.stepHighlight, stepClassName = props.stepClassName, children = props.children, editMode = props.editMode, canClickPreventiveReminder = props.canClickPreventiveReminder, recoverPlan = props.recoverPlan;
    var clickOnStep = function (i) { return function () {
        onClickOnStep === null || onClickOnStep === void 0 ? void 0 : onClickOnStep(i);
    }; };
    var preventiveReminder = recoverPlan.recovery_steps.some(function (e) { return e.step_type === 'preventive_reminder'; });
    var lastReminder = recoverPlan.recovery_steps.some(function (e) { return e.step_type === 'last_reminder'; });
    var getClassName = function () {
        if (step_type === 'preventive_reminder') {
            return styles('debt-collection-item', canClickPreventiveReminder && stepClassName, stepHighlight === index && 'highlight');
        }
        return styles('debt-collection-item', id && stepClassName, stepHighlight === index && 'highlight');
    };
    var getTopButtonText = function () {
        if (!before_due_date) {
            return undefined;
        }
        if (preventiveReminder) {
            return t(i18nKeys.ADD_TASK);
        }
        return t(i18nKeys.ADD_STEP);
    };
    var getBottomButtonText = function () {
        if (before_due_date) {
            return undefined;
        }
        if (lastReminder) {
            return t(i18nKeys.ADD_TASK);
        }
        return t(i18nKeys.ADD_STEP);
    };
    return (React.createElement(Fragment, { key: index },
        editMode && arrayIndex === 0 && (React.createElement("div", { className: styles('add-item', 'timeline', before_due_date && 'before-due-date') }, !before_due_date ? (React.createElement("div", { className: styles('line') },
            React.createElement(RecoveryPlanAddStep, { onClick: function () { return add(index); }, text: getTopButtonText() }))) : (React.createElement(RecoveryPlanAddStep, { onClick: function () { return add(index); }, text: getTopButtonText() })))),
        !before_due_date && React.createElement("div", { className: styles('delay') }, children),
        React.createElement("div", { className: getClassName(), onClick: clickOnStep(index) },
            React.createElement("div", { className: styles('timeline') },
                React.createElement("div", { className: styles('circle') }, index + 1),
                arrayIndex !== array.length - 1 && (React.createElement("div", { className: styles('line') }, editMode && React.createElement(RecoveryPlanAddStep, { onClick: function () { return add(index + 1); } })))),
            React.createElement(RecoveryPlanStepLine, __assign({}, props))),
        before_due_date && React.createElement("div", { className: styles('delay') }, children),
        editMode && arrayIndex === array.length - 1 && (React.createElement("div", { className: styles('add-item', 'timeline', before_due_date && 'before-due-date') }, before_due_date ? (React.createElement("div", { className: styles('line') },
            React.createElement(RecoveryPlanAddStep, { onClick: function () { return add(index + 1); }, text: getBottomButtonText() }))) : (React.createElement(RecoveryPlanAddStep, { onClick: function () { return add(index + 1); }, text: getBottomButtonText() }))))));
};
