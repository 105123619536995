import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Input } from 'shared/io';
import { RecoveryStepFormCreateTask } from './RecoveryStepFormCreateTask';
import { RecoveryStepFormSendReminder } from './RecoveryStepFormSendReminder';
import styleIdentifiers from './RecoveryStepForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function RecoveryStepForm(_a) {
    var step = _a.step, availableTemplateTypes = _a.availableTemplateTypes, onSelectTemplate = _a.onSelectTemplate, _b = _a.onlyTask, onlyTask = _b === void 0 ? false : _b, _c = _a.onlyReminder, onlyReminder = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: step,
    });
    var register = form.register, handleSubmit = form.handleSubmit, errors = form.formState.errors;
    var getType = function () {
        if (onlyTask) {
            return 'task_generation';
        }
        if (onlyReminder) {
            return 'send_reminder';
        }
        if (!(step === null || step === void 0 ? void 0 : step.step_type)) {
            return undefined;
        }
        switch (step.step_type) {
            case 'task_generation':
                return step.step_type;
            default:
                return 'send_reminder';
        }
    };
    var getTitle = function () {
        if (onlyTask) {
            return t(i18nKeys.ADD_TASK);
        }
        if (onlyReminder) {
            return t(i18nKeys.ADD_REMINDER);
        }
        return t(i18nKeys.PLAN.EDITION_STEP);
    };
    var _d = __read(useState(getType()), 2), type = _d[0], setType = _d[1];
    var submit = function (values) {
        return onSelectTemplate(__assign(__assign({}, step), values));
    };
    if (!type) {
        return (React.createElement("div", { className: styles('recovery-step-form') },
            React.createElement("div", { className: styles('head') }, getTitle()),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('action'), onClick: function () { return setType('send_reminder'); } },
                    React.createElement(Icon, { name: IconName.SEND }),
                    t(i18nKeys.FORM.RECOVER_STEP.TYPE.SEND_REMINDER)),
                React.createElement("div", { className: styles('action'), onClick: function () { return setType('task_generation'); } },
                    React.createElement(Icon, { name: IconName.COMMENT }),
                    t(i18nKeys.FORM.RECOVER_STEP.TYPE.CREATE_TASK)))));
    }
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('recovery-step-form'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('head') }, getTitle()),
            React.createElement("div", { className: styles('content') }, type === 'task_generation' ? (React.createElement(React.Fragment, null, !company.package.can_use_tasks ? (React.createElement("div", null, t(i18nKeys.ACCOUNT_LIMITATION))) : (React.createElement(RecoveryStepFormCreateTask, null,
                React.createElement(Input, { register: register('delay', { required: true, min: 0 }), errorMessage: errors.delay, label: t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION), type: "number", withBorder: true }))))) : (React.createElement(RecoveryStepFormSendReminder, { availableTemplateTypes: availableTemplateTypes },
                React.createElement(Input, { register: register('delay', { required: true, min: 0 }), errorMessage: errors.delay, label: t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION), type: "number", withBorder: true })))),
            React.createElement("div", { className: styles('button-wrapper') }, (type !== 'task_generation' || company.package.can_use_tasks) && (React.createElement(Button, { type: "submit", noMargin: true, label: t(i18nKeys.SAVE), disabled: !type }))))));
}
