import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Button } from 'shared/io';
import styleIdentifiers from './ChooseRecoveryPlanModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ChooseRecoveryPlanModal(_a) {
    var className = _a.className, recoverPlans = _a.recoverPlans, onClick = _a.onClick;
    var t = useTranslation().t;
    var click = function (planId) { return function () {
        onClick(planId);
    }; };
    return (React.createElement("div", { className: styles('choose-recovery-plan-modal', className) }, recoverPlans.map(function (plan) { return (React.createElement("div", { className: styles('plan'), key: plan.id },
        plan.name,
        React.createElement(Button, { label: t(i18nKeys.CHOOSE), noMargin: true, onClick: click(plan.id) }))); })));
}
