import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadLightUsers } from 'shared/hooks';
import { Group, NumberInput, Select, Stack, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
export var InvoiceKanbanFilters = function (_a) {
    var form = _a.form;
    var t = useTranslation().t;
    var _b = useLoadLightUsers(false).users, users = _b === void 0 ? [] : _b;
    var accountManagersForSelect = users.map(function (user) { return ({
        value: String(user.id),
        label: user.name.full,
    }); });
    return (React.createElement(Stack, { gap: "lg" },
        React.createElement(Select, __assign({ autoFocus: true, label: t(i18nKeys.ACCOUNT_MANAGER), placeholder: t(i18nKeys.DASHBOARD.SELECT_ACCOUNT_MANAGER), data: accountManagersForSelect }, form.getInputProps('accountManagerId'))),
        React.createElement(Group, null,
            React.createElement(DatePickerInput, __assign({ label: t(i18nKeys.FORM.START_DATE), placeholder: t(i18nKeys.FORM.CHOOSE_DATE), flex: 1 }, form.getInputProps('startDate'))),
            React.createElement(DatePickerInput, __assign({ label: t(i18nKeys.FORM.START_DATE), placeholder: t(i18nKeys.FORM.CHOOSE_DATE), flex: 1 }, form.getInputProps('endDate')))),
        React.createElement(TextInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.INVOICE_NAME), placeholder: t(i18nKeys.KANBAN.FILTERS.INVOICE_NAME_PH) }, form.getInputProps('reference'))),
        React.createElement(TextInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME), placeholder: t(i18nKeys.KANBAN.FILTERS.CLIENT_NAME_PH) }, form.getInputProps('debtorName'))),
        React.createElement(Group, null,
            React.createElement(NumberInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.MIN_DAYS_LATE), placeholder: t(i18nKeys.KANBAN.FILTERS.MIN_DAYS_LATE_PH), flex: 1 }, form.getInputProps('minDaysLate'))),
            React.createElement(NumberInput, __assign({ label: t(i18nKeys.KANBAN.FILTERS.MAX_DAYS_LATE), placeholder: t(i18nKeys.KANBAN.FILTERS.MAX_DAYS_LATE_PH), flex: 1 }, form.getInputProps('maxDaysLate')))),
        React.createElement(Group, null,
            React.createElement(Select, __assign({ label: t(i18nKeys.KANBAN.FILTERS.OPERATOR), flex: 1, data: [
                    { label: t(i18nKeys.KANBAN.FILTERS.LESS_THAN), value: '<=' },
                    { label: t(i18nKeys.KANBAN.FILTERS.MORE_THAN), value: '>=' },
                ] }, form.getInputProps('remainingBalance.operator'))),
            React.createElement(NumberInput, __assign({ label: t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE), placeholder: "e.g. 1000", flex: 2 }, form.getInputProps('remainingBalance.amount'))))));
};
