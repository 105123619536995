import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Textarea } from 'shared/io';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CommentField = function (_a) {
    var t = useTranslation().t;
    var register = useFormContext().register;
    return (React.createElement(Textarea, { register: register('comment'), placeholder: t(i18nKeys.INVOICING.CREATE.NOTES_COMMENTS), className: styles('textarea'), inputClassName: styles('value'), noBorder: true, noMargin: true, rows: 6 }));
};
