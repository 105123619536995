import { __assign, __read, __spreadArray } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller } from 'react-hook-form';
import UserInfo from 'shared/components/UserInfo';
import { useLoadLightUsers } from 'shared/hooks';
import { CustomSelect } from 'shared/io';
import styleIdentifiers from './TaskForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskFormColleague = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'user_id' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    var _d = useLoadLightUsers().users, users = _d === void 0 ? [] : _d;
    var colleaguesOrAccountManager = name === 'task_attributes.user_id'
        ? __spreadArray([
            {
                name: {
                    full: t(i18nKeys.ACCOUNT_MANAGER),
                    first: t(i18nKeys.ACCOUNT_MANAGER),
                    last: '',
                    initials: t(i18nKeys.ACCOUNT_MANAGER)[0].toUpperCase(),
                },
                email: '-',
                id: 'auto',
                isActive: true,
                profilePicture: null,
            }
        ], __read(users), false) : users;
    return (React.createElement(Controller, { name: name, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: name, keyValue: "id", label: t(i18nKeys.TASK.ATTRIBUTES.ASSIGNEE), items: colleaguesOrAccountManager, selectClassName: styles('input'), itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({ inline: true }, props)); }, notAllowed: disabled, withBorder: true })); } }));
};
