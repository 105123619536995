import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, RadioButton } from 'shared/io';
import { formatAmount } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './SelectStatementType.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SelectStatementType(_a) {
    var onSelectAction = _a.onSelectAction, initialValue = _a.initialValue;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var form = useForm({
        defaultValues: { grouped_type: initialValue || '' },
    });
    var submit = function () {
        onSelectAction(form.getValues().grouped_type);
    };
    var grouped_type = form.watch('grouped_type');
    return (React.createElement("div", { className: styles('select-statement-type') },
        React.createElement("div", { className: styles('header') },
            React.createElement("div", { className: styles('description') }, t(i18nKeys.TEMPLATE.SELECT_STATEMENT)),
            React.createElement("div", null,
                React.createElement(Button, { color: ButtonColor.MAIN, label: t(i18nKeys.FORM.VALIDATE), noMargin: true, onClick: submit }))),
        React.createElement(FormProvider, __assign({}, form),
            React.createElement("div", { className: styles('table-wrapper') },
                React.createElement("div", { className: styles('statement-type') },
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, values = __rest(_b, ["ref", "value", "onChange"]);
                            return (React.createElement(React.Fragment, null,
                                React.createElement(RadioButton, __assign({}, values, { labelStyle: styles('radio-button-label'), value: grouped_type, noMargin: true, onChange: function (value) {
                                        form.setValue('grouped_type', value);
                                    }, name: "grouped_type", items: [{ value: '', label: t(i18nKeys.NO_STATEMENT) }] }))));
                        } }))),
            React.createElement("div", { className: styles('table-wrapper') },
                React.createElement("div", { className: styles('statement-type') },
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                            return (React.createElement(RadioButton, __assign({}, values, { labelStyle: styles('radio-button-label'), value: grouped_type, noMargin: true, onChange: function (value) {
                                    form.setValue('grouped_type', value);
                                }, name: "grouped_type", items: [
                                    {
                                        value: 'unpaid_invoices',
                                        label: t(i18nKeys.INVOICE.IN_PROGRESS),
                                    },
                                ] })));
                        } })),
                React.createElement("div", { className: "recovr-table" },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { style: { width: 200 } },
                                    React.createElement("div", null, t(i18nKeys.FORM.REFERENCE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.ISSUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.DUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.TOTAL_VAT_PRICE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.LATE_FEES))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.INVOICING.ALREADY_PAID))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT))))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 30), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 15), currentLang)),
                                React.createElement("td", null, formatAmount(500)),
                                React.createElement("td", null, formatAmount(50)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(550))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_2"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 5), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * -10), currentLang)),
                                React.createElement("td", null, formatAmount(250)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(250))))))),
            React.createElement("div", { className: styles('table-wrapper') },
                React.createElement("div", { className: styles('statement-type') },
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                            return (React.createElement(RadioButton, __assign({}, values, { labelStyle: styles('radio-button-label'), value: grouped_type, onChange: function (value) {
                                    form.setValue('grouped_type', value);
                                }, noMargin: true, name: "grouped_type", items: [
                                    {
                                        value: 'overdue_invoices',
                                        label: t(i18nKeys.INVOICE.LATE_ONLY),
                                    },
                                ] })));
                        } })),
                React.createElement("div", { className: "recovr-table" },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { style: { width: 200 } },
                                    React.createElement("div", null, t(i18nKeys.FORM.REFERENCE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.ISSUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.DUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.TOTAL_VAT_PRICE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.LATE_FEES))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.INVOICING.ALREADY_PAID))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT))))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 30), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 15), currentLang)),
                                React.createElement("td", null, formatAmount(500)),
                                React.createElement("td", null, formatAmount(50)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(550))))))),
            React.createElement("div", { className: styles('table-wrapper') },
                React.createElement("div", { className: styles('statement-type') },
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                            return (React.createElement(RadioButton, __assign({}, values, { labelStyle: styles('radio-button-label'), value: grouped_type, noMargin: true, onChange: function (value) {
                                    form.setValue('grouped_type', value);
                                }, name: "grouped_type", items: [
                                    {
                                        value: 'debtor_balance',
                                        label: t(i18nKeys.CLIENT_BALANCE),
                                    },
                                ] })));
                        } }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE.FULL_DEBTOR_BALANCE))),
                React.createElement("div", { className: "recovr-table" },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { style: { width: 200 } },
                                    React.createElement("div", null, t(i18nKeys.FORM.REFERENCE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.ISSUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.DUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.TOTAL_VAT_PRICE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.LATE_FEES))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.INVOICING.ALREADY_PAID))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT))))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 30), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 15), currentLang)),
                                React.createElement("td", null, formatAmount(500)),
                                React.createElement("td", null, formatAmount(50)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(550))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_2"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 5), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * -10), currentLang)),
                                React.createElement("td", null, formatAmount(250)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(250))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 }, className: styles('red') }, "CN_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 5), currentLang)),
                                React.createElement("td", null),
                                React.createElement("td", null, formatAmount(250)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(250))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 }, className: styles('yellow') },
                                    t(i18nKeys.PAYMENT),
                                    " +++000/0000/00000+++"),
                                React.createElement("td", null, formattedDate(new Date(), currentLang)),
                                React.createElement("td", null),
                                React.createElement("td", null, formatAmount(300)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(300))))))),
            React.createElement("div", { className: styles('table-wrapper') },
                React.createElement("div", { className: styles('statement-type') },
                    React.createElement(Controller, { name: "grouped_type", defaultValue: "", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, value = _b.value, values = __rest(_b, ["ref", "value"]);
                            return (React.createElement(RadioButton, __assign({}, values, { labelStyle: styles('radio-button-label'), value: grouped_type, noMargin: true, onChange: function (value) {
                                    form.setValue('grouped_type', value);
                                }, name: "grouped_type", items: [
                                    {
                                        value: 'overdue_debtor_balance',
                                        label: t(i18nKeys.OVERDUE_BALANCE),
                                    },
                                ] })));
                        } }),
                    React.createElement("span", null, t(i18nKeys.TEMPLATE.OVERDUE_DEBTOR_BALANCE))),
                React.createElement("div", { className: "recovr-table" },
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { style: { width: 200 } },
                                    React.createElement("div", null, t(i18nKeys.FORM.REFERENCE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.ISSUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.DUE_DATE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.TOTAL_VAT_PRICE))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.LATE_FEES))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.INVOICING.ALREADY_PAID))),
                                React.createElement("th", null,
                                    React.createElement("div", null, t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT))))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 } }, "INV_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 30), currentLang)),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 15), currentLang)),
                                React.createElement("td", null, formatAmount(500)),
                                React.createElement("td", null, formatAmount(50)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(550))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 }, className: styles('red') }, "CN_1"),
                                React.createElement("td", null, formattedDate(new Date(new Date() - 60 * 60 * 24 * 1000 * 5), currentLang)),
                                React.createElement("td", null),
                                React.createElement("td", null, formatAmount(250)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(250))),
                            React.createElement("tr", null,
                                React.createElement("td", { style: { width: 200 }, className: styles('yellow') },
                                    t(i18nKeys.PAYMENT),
                                    " +++000/0000/00000+++"),
                                React.createElement("td", null, formattedDate(new Date(), currentLang)),
                                React.createElement("td", null),
                                React.createElement("td", null, formatAmount(300)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(0)),
                                React.createElement("td", null, formatAmount(300))))))))));
}
