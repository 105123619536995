import { useHistory } from 'react-router';
export function useEnhancedNavigation() {
    var history = useHistory();
    var handleNavigation = function (url) { return function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            window.open(url, '_blank');
        }
        else {
            history.push(url);
        }
    }; };
    return handleNavigation;
}
