export var VarType;
(function (VarType) {
    VarType["STRING"] = "string";
    VarType["NUMBER"] = "number";
    VarType["DATE"] = "date";
    VarType["BOOLEAN"] = "boolean";
})(VarType || (VarType = {}));
export var ModelType;
(function (ModelType) {
    ModelType["INVOICE"] = "invoice";
    ModelType["DEBTOR"] = "debtor";
})(ModelType || (ModelType = {}));
export var DisplayType;
(function (DisplayType) {
    DisplayType["MONEY"] = "money";
    DisplayType["PERCENTAGE"] = "percentage";
})(DisplayType || (DisplayType = {}));
