import { __assign, __read, __rest } from "tslib";
import '!style-loader!css-loader!react-credit-cards/es/styles-compiled.css';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, MaskInput } from 'shared/io';
import { formatCreditCardNumber } from 'shared/utils/normalization';
import styleIdentifiers from './paymentForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentForm(_a) {
    var className = _a.className, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = __read(useState(''), 2), focused = _b[0], setFocused = _b[1];
    var _c = useForm({ shouldUnregister: true }), watch = _c.watch, register = _c.register, errors = _c.formState.errors, handleSubmit = _c.handleSubmit, setValue = _c.setValue, control = _c.control;
    var _d = watch(), bank_account_number = _d.bank_account_number, holder_name = _d.holder_name, expiry = _d.expiry, verification_numbers = _d.verification_numbers;
    var handleInputFocus = function (e) {
        setFocused((e === null || e === void 0 ? void 0 : e.target.name) === 'verification_numbers' ? 'cvc' : e === null || e === void 0 ? void 0 : e.target.name);
    };
    var onSetCreditCardNumber = function (value) {
        setValue('bank_account_number', formatCreditCardNumber(value.target.value));
    };
    return (React.createElement("form", { className: styles('payment-form', className), onSubmit: handleSubmit(onSubmit) },
        React.createElement("div", { className: styles('card') },
            React.createElement(Cards, { number: bank_account_number || '', name: holder_name || '', expiry: expiry || '', cvc: verification_numbers || '', focused: focused })),
        React.createElement(Input, { register: register('bank_account_number', { required: true }), errorMessage: errors.bank_account_number, type: "text", label: t(i18nKeys.FORM.STRIPE.CARD_NUMBER), onChange: onSetCreditCardNumber, onFocus: handleInputFocus }),
        React.createElement(Input, { errorMessage: errors.holder_name, register: register('holder_name', { required: true }), type: "text", label: t(i18nKeys.FORM.STRIPE.FULL_NAME), onFocus: handleInputFocus }),
        React.createElement("div", { className: "grid-row" },
            React.createElement("div", { className: "col-6" },
                React.createElement(Controller, { control: control, defaultValue: "", name: "expiry", rules: {
                        validate: function (val) {
                            return val === '__/__'
                                ? 'ERROR.FIELD_REQUIRED'
                                : val.indexOf('_') > -1
                                    ? 'ERROR.NOT_FULLY_FILLED'
                                    : true;
                        },
                    }, render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(MaskInput, __assign({}, values, { name: "expiry", error: errors.expiry, label: t(i18nKeys.FORM.STRIPE.EXPIRATION), numberOnly: true, mask: "__/__", onFocus: handleInputFocus })));
                    } })),
            React.createElement("div", { className: "col-6" },
                React.createElement(Input, { register: register('verification_numbers', {
                        required: true,
                        validate: {
                            maxLength4: function (value) { return (value > 9999 ? 'ERROR.INVALID' : true); },
                        },
                    }), errorMessage: errors.verification_numbers, type: "number", label: t(i18nKeys.FORM.STRIPE.CVC), onFocus: handleInputFocus }))),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { className: styles('submit-button'), label: t(i18nKeys.FORM.STRIPE.ADD_PAYMENT_METHOD), type: "submit" }))));
}
