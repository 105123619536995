import { __generator } from "tslib";
import { i18nKeys, translate } from 'locales';
import { all, call, takeEvery } from 'redux-saga/effects';
import { viewActionTypes as events } from 'store/view/view.actions';
import { notifications } from '@mantine/notifications';
function DisplaySuccess(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, notifications.show({
                    title: translate(i18nKeys.SUCCESS),
                    color: 'green',
                    message: "".concat(payload.text ? translate(payload.text) : '', "\n").concat(typeof payload.data === 'string' ? payload.data : ''),
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function DisplayErrors(_a) {
    var concatenatedMessages;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload.data && !payload.text)
                    return [2 /*return*/];
                concatenatedMessages = null;
                if (Array.isArray(payload.data)) {
                    concatenatedMessages = payload.data.join('\n');
                }
                return [4 /*yield*/, notifications.show({
                        title: translate(i18nKeys.ERROR.ERROR),
                        color: 'red',
                        message: "".concat(payload.text ? translate(payload.text) : '', "\n").concat(typeof payload.data === 'string' ? payload.data : concatenatedMessages),
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function ViewWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(events.notification.error, DisplayErrors)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(events.notification.success, DisplaySuccess)];
            case 2: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 3:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(ViewWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
