import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult, useLegacyAxiosInstance } from './utils';
export var SendToThirdPartyVariablesSchema = z.object({
    third_party_company_id: z.number(),
    additional_invoices: z.array(z.number()),
    ape_code: z.string(),
    siren: z.string(),
});
var SendToThirdPartyResponseSchema = z.object({
    message: z.string(),
    third_party_case_ids: z.array(z.number()),
});
export function sendToThirdPartyFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, res;
        var id = _b.id, data = _b.data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.post("invoices/".concat(id, "/send_to_third_party"), SendToThirdPartyVariablesSchema.parse(data))];
                case 2:
                    res = _c.sent();
                    return [2 /*return*/, SendToThirdPartyResponseSchema.parse(res.data)];
            }
        });
    });
}
export function useSendToThirdParty() {
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return sendToThirdPartyFn(axiosInstance, variables); },
        onError: function (error) {
            var _a;
            notifications.show({
                title: 'Error',
                color: 'red',
                message: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message,
            });
        },
    });
    return addActionNameToMutationResult('sendToThirdParty', mutation);
}
