import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useTenant } from 'app/TenantValidator';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Redirect } from 'react-router';
import Card from 'shared/components/Card';
import GradientBorder from 'shared/components/GradientBorder';
import { useEnforceProfile } from 'shared/hooks';
import { unimplementedWith } from 'shared/utils';
import { useQueryClient } from '@tanstack/react-query';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import BankConnection from './BankConnection';
import ColorLogo from './ColorLogo';
import Integrations from './Integrations';
import PaymentMethod from './PaymentMethod';
import SetupDebtCollection from './SetupDebtCollection';
import styleIdentifiers from './onBoarding.scss';
var styles = classNames.bind(styleIdentifiers);
export var OnBoarding = function () {
    var t = useTranslation().t;
    var tenant = useTenant();
    var queryClient = useQueryClient();
    useEnforceProfile();
    var _a = __read(useState(tenant.onboarding.currentStep), 2), step = _a[0], setStep = _a[1];
    useEffect(function () {
        queryClient.invalidateQueries(['tenant']);
    }, [step, queryClient]);
    if (tenant.onboarding.isComplete)
        return React.createElement(Redirect, { to: "/" });
    return (React.createElement("div", { className: styles('on-boarding', 'wrapper') },
        React.createElement("div", null,
            React.createElement(Card, { title: t(i18nKeys.ONBOARDING.TITLE) }, step !== 7 && (React.createElement("div", { className: styles('header-card') },
                React.createElement("div", { className: styles('step-name') }, "".concat(t(i18nKeys.ONBOARDING.STEP), " ").concat(step, " :").concat(' '),
                    t(i18nKeys.ONBOARDING[step].TITLE)),
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('progress-bar') },
                        React.createElement(GradientBorder, { className: styles('progress') }),
                        React.createElement("div", { className: styles('anti-progress'), style: {
                                left: "".concat(step * (100 / 7), "%"),
                            } })))))),
            step === 1 && React.createElement(GeneralInfo, { onValidStep: function () { return setStep(2); } }),
            step === 2 && React.createElement(PaymentMethod, { onValidStep: function () { return setStep(3); } }),
            step === 3 && (React.createElement(BankConnection, { onValidStep: function () { return setStep(4); }, disableIntegration: unimplementedWith(false) })),
            step === 4 && (React.createElement(SetupDebtCollection, { onValidStep: function () {
                    setStep(5);
                } })),
            step === 5 && React.createElement(ColorLogo, { onValidStep: function () { return setStep(6); } }),
            step === 6 && React.createElement(Integrations, { onValidStep: function () { return setStep(7); } }))));
};
