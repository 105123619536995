import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styleIdentifiers from './InfiniteScroll.scss';
var styles = classNames.bind(styleIdentifiers);
// The scrolltop value is sometimes a decimal and alternatively needs to be rounded
// up or down to match scrollheight. We check against a tolerance to account for this.
// Increase tolerance if you find that the load function is not being called while the
// scrollarea is maxed out.
function isWithinToleranceOf(value, target, tolerance) {
    return Math.abs(value - target) <= tolerance;
}
export function InfiniteScrollCustom(_a) {
    var fwRef = _a.fwRef, children = _a.children, className = _a.className, load = _a.load;
    var innerRef = useRef(null);
    var listRef = fwRef !== null && fwRef !== void 0 ? fwRef : innerRef;
    var isElementAtBottom = function (element) {
        var _a = element.target, scrollTop = _a.scrollTop, clientHeight = _a.clientHeight, scrollHeight = _a.scrollHeight;
        return isWithinToleranceOf(scrollTop + clientHeight, scrollHeight, 3);
    };
    var setScrollTop = useCallback(function (target) {
        if (isElementAtBottom(target))
            load();
    }, [load]);
    useEffect(function () {
        listRef.current.addEventListener('scroll', setScrollTop);
        var node = listRef.current;
        return function () { return node.removeEventListener('scroll', setScrollTop); };
    }, [listRef, setScrollTop]);
    return (React.createElement("div", { ref: listRef, className: styles(className, 'infinite-scroll') }, children));
}
