import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { useSearchInvoicesInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { flat, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { formatAmount } from 'shared/utils/normalization';
import styleIdentifiers from './TaskForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskFormInvoices = function (_a) {
    var _b;
    var _c = _a.name, name = _c === void 0 ? 'invoice_ids' : _c, _d = _a.attachedInvoices, attachedInvoices = _d === void 0 ? [] : _d;
    var t = useTranslation().t;
    var watch = useFormContext().watch;
    var debtor_id = watch('debtor_id');
    var _e = __read(useState({
        debtor_id: debtor_id,
        exclude: attachedInvoices.map(function (e) { return e.id; }),
    }), 2), params = _e[0], setParams = _e[1];
    var _f = useSearchInvoicesInfinite(params, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), invoicesResponse = _f.data, fetchNextPage = _f.fetchNextPage;
    useEffect(function () {
        if (debtor_id) {
            setParams(function (currentParams) { return (__assign(__assign({}, currentParams), { debtor_id: debtor_id })); });
        }
    }, [debtor_id]);
    var pages = (_b = invoicesResponse === null || invoicesResponse === void 0 ? void 0 : invoicesResponse.pages) !== null && _b !== void 0 ? _b : [];
    var items = pipe(pages, map(function (e) { return e.data; }), flat(), map((treatInvoice)));
    var concatedInvoices = __spreadArray(__spreadArray([], __read(attachedInvoices.map((treatInvoice))), false), __read(items.filter(function (e) { return !attachedInvoices.some(function (el) { return el.id === e.id; }); })), false);
    return (React.createElement(Controller, { name: name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), filter: true, withBorder: true, multiple: true, infiniteScroll: true, load: fetchNextPage, onChangeFilter: function (reference) { return setParams(function (state) { return (__assign(__assign({}, state), { reference: reference })); }); }, items: concatedInvoices || [], keyValue: "id", keyText: "reference", name: name, label: t(i18nKeys.TASK.ATTRIBUTES.INVOICE), itemRendering: function (_a) {
                var item = _a.item;
                var reference = item.reference, total_tvac = item.total_tvac;
                return (React.createElement("div", { className: styles('invoice-item') },
                    React.createElement("div", { className: styles('reference') }, reference),
                    React.createElement("div", { className: styles('comp') }, total_tvac && React.createElement("b", null, formatAmount(total_tvac)))));
            } })); } }));
};
