import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { NavLink } from 'react-router-dom';
import { useProfile } from 'shared/hooks';
import { Dropdown } from 'shared/io';
import { useAuth0 } from '@auth0/auth0-react';
import { useTenant } from '../../../app/TenantValidator';
import Avatar from '../Avatar';
import styleIdentifiers from './userItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UserItem() {
    var t = useTranslation().t;
    var tenant = useTenant();
    var profile = useProfile();
    var logout = useAuth0().logout;
    if (!(tenant === null || tenant === void 0 ? void 0 : tenant.onboarding.isComplete))
        return null;
    return (React.createElement(Dropdown, { className: styles('UserItem'), toggleContent: true, selectorContent: React.createElement(Avatar, { size: 35, name: profile.name.first, secondName: profile.name.last, logoUrl: profile.profilePicture }) },
        React.createElement("div", { className: styles('menu') },
            React.createElement(NavLink, { className: styles('item'), to: "/settings/preferences", activeClassName: styles('active') }, t(i18nKeys.PREFERENCES)),
            React.createElement(NavLink, { className: styles('item'), to: "/settings/user", activeClassName: styles('active') }, t(i18nKeys.HEADER.USER_ACTIONS.EDIT_PROFILE)),
            React.createElement("div", { onClick: function () { return logout({ logoutParams: { returnTo: "".concat(window.location.origin, "/login") } }); }, className: styles('item') }, t(i18nKeys.HEADER.USER_ACTIONS.LOGOUT)))));
}
