import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'shared/io';
import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';
import styleIdentifiers from './ExternalMailBatchActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailBatchActionsAssign = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
    });
    var handleSubmit = form.handleSubmit;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('external-mail-batch-actions-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement(ExternalMailActionDebtor, null)),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE) })))));
};
