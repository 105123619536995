import { __assign } from "tslib";
import { buildActions, makeReqCons, makeResourceConstants, } from 'store/actions';
var prefix = 'invoice';
export var invoiceConstants = __assign(__assign({}, makeResourceConstants(prefix)), { deleteMultiple: makeReqCons("".concat(prefix, "_DELETE_MULTIPLE")), createInvoicePdf: makeReqCons("".concat(prefix, "_PDF_CREATE")), getInvoices: makeReqCons("".concat(prefix, "_GET_INVOICES")), toHandle: makeReqCons("".concat(prefix, "_TO_HANDLE")), postponable: makeReqCons("".concat(prefix, "_POSTPONABLE")), activity: makeReqCons("".concat(prefix, "_ACTIVITY")), getActivityPage: makeReqCons("".concat(prefix, "_ACTIVITY_PAGE")), info: makeReqCons("".concat(prefix, "_info")), send: makeReqCons("".concat(prefix, "_SEND")), downloadPdf: makeReqCons("".concat(prefix, "_download_pdf")), document: makeReqCons("".concat(prefix, "_document")), previewTemplate: makeReqCons("".concat(prefix, "_preview_template")), setSendingOptions: makeReqCons("".concat(prefix, "_SET_SENDING_OPTIONS")), getAdditionalFiles: makeReqCons("".concat(prefix, "_ADDITIONAL_FILES")), addAdditionalFile: makeReqCons("".concat(prefix, "_ADD_ADDITIONAL_FILE")), deleteAdditionalFile: makeReqCons("".concat(prefix, "_DELETE_ADDITIONAL_FILE")), addOriginalInvoice: makeReqCons("".concat(prefix, "_ADD_ORIGINAL_INVOICE")), updateOriginalFile: makeReqCons("".concat(prefix, "_UPDATE_ORIGINAL_INVOICE")), importInvoiceCSV: makeReqCons("".concat(prefix, "_CSV_IMPORT")), getAllToConfirm: makeReqCons("".concat(prefix, "_GET_ALL_TO_CONFIRM")), getToConfirm: makeReqCons("".concat(prefix, "_GET_TO_CONFIRM")), validateAllImportedInvoices: makeReqCons("".concat(prefix, "_VALIDATE_ALL_TO_CONFIRM")), deleteInvoiceToConfirm: makeReqCons("".concat(prefix, "_DELETE_TO_CONFIRM")), copy: makeReqCons("".concat(prefix, "_copy")), assignToAccManager: makeReqCons("".concat(prefix, "_assign_to_user")), getRecoveryPlans: makeReqCons("".concat(prefix, "_get_recovery_plan")), setRecoveryPlan: makeReqCons("".concat(prefix, "_set_recovery_plan")), getGroupedInvoices: makeReqCons("".concat(prefix, "_get_grouped_invoices")), 
    // debtor reaction
    debtorReactionQrCode: makeReqCons("".concat(prefix, "_debtor_reaction_QR_CODE")), debtorReaction: makeReqCons("".concat(prefix, "_debtor_reaction")), debtorReactionComment: makeReqCons("".concat(prefix, "_debtor_reaction_comment")), debtorReactionInvoices: makeReqCons("".concat(prefix, "_debtor_reaction_get_paid_invoices")), debtorReactionCreditNotes: makeReqCons("".concat(prefix, "_debtor_reaction_credit_notes")), getGroupedInvoicePaymentInfo: makeReqCons("".concat(prefix, "_get_grouped_invoice_payment_info")), actions: {
        comment: makeReqCons("".concat(prefix, "_comment")),
        conversationMessage: makeReqCons("".concat(prefix, "_conversation_message")),
        replyLawyerQuestion: makeReqCons("".concat(prefix, "_reply_lawyer_question")),
        addPayment: makeReqCons("".concat(prefix, "_add_payment")),
        postpone: makeReqCons("".concat(prefix, "_POSTPONE")),
        forceReminder: makeReqCons("".concat(prefix, "_FORCE_REMINDER")),
        postponeMultiple: makeReqCons("".concat(prefix, "_POSTPONE_MULTIPLE")),
        ignoreLateFees: makeReqCons("".concat(prefix, "_IGNORE_LATE_FEES")),
        // mark as
        paid: makeReqCons("".concat(prefix, "_paid")),
        lost: makeReqCons("".concat(prefix, "_LOST")),
        pause: makeReqCons("".concat(prefix, "_PAUSE")),
        disputed: makeReqCons("".concat(prefix, "_DISPUTED")),
        batchAction: makeReqCons("".concat(prefix, "_batch_action")),
        // to handle action
        additionalReminder: makeReqCons("".concat(prefix, "_additional_reminder")),
        callcenter: makeReqCons("".concat(prefix, "_CALL_CENTER")),
        formalNotice: makeReqCons("".concat(prefix, "_FORMAL_NOTICE")),
        lawyer: makeReqCons("".concat(prefix, "_LAWYER")),
        closeLawyer: makeReqCons("".concat(prefix, "_CLOSE_LAWYER")),
        bailiff: makeReqCons("".concat(prefix, "_BAILIFF")),
        closeBailiff: makeReqCons("".concat(prefix, "_CLOSE_BAILIFF")),
        markAsToBeProccesed: makeReqCons("".concat(prefix, "_MARK_AS_TO_BE_PROCESSED")),
        thirdParty: makeReqCons("".concat(prefix, "_THIRD_PARTY")),
    } });
var invoiceActions = buildActions('', invoiceConstants, {});
export { invoiceActions };
