import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNonNull } from 'remeda';
import { PageTitle } from 'shared/layout/PageTitle';
import { getQueryParams, setQueryParams } from 'shared/utils/url';
import { appActions } from 'store/app/app.actions';
import { sideMenuHide } from 'store/view/view.actions';
import { Card, SimpleGrid, Tabs } from '@mantine/core';
import { isEnabledIntegration } from './components/Integration.utils';
import { Integration, INTEGRATION_ROUTE, IntegrationCategory, IntegrationQueryParams, } from './Integrations.constants';
import { getIntegrationCard, getIntegrationsCards, getIntegrationsCategories, } from './Integrations.utils';
import { IntegrationsCard } from './IntegrationsCard';
export var Integrations = function (_a) {
    var _b = _a.isOnBoarding, isOnBoarding = _b === void 0 ? false : _b, onIntegrationSelect = _a.onIntegrationSelect;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _c = __read(useState(IntegrationCategory.ALL), 2), currentCategory = _c[0], setCurrentCategory = _c[1];
    var queryIntegration = getQueryParams(IntegrationQueryParams.NAME);
    var queryCategory = getQueryParams(IntegrationQueryParams.CATEGORY);
    useEffect(function () {
        if (queryIntegration && onIntegrationSelect) {
            var integration = queryIntegration.toUpperCase();
            if (isEnabledIntegration(Integration[integration])) {
                onIntegrationSelect(getIntegrationCard(integration, company, t));
            }
        }
    }, [queryIntegration, company, onIntegrationSelect, t]);
    useEffect(function () {
        if (queryCategory &&
            queryCategory !== currentCategory &&
            Object.keys(IntegrationCategory).includes(queryCategory)) {
            setCurrentCategory(queryCategory);
        }
    }, []);
    useEffect(function () {
        setQueryParams(IntegrationQueryParams.CATEGORY, currentCategory);
        appActions.updateLastSettingsScreen(currentCategory);
    }, [currentCategory]);
    var onClickCard = function (integration) {
        if (onIntegrationSelect) {
            onIntegrationSelect(integration);
        }
        else {
            history.push(INTEGRATION_ROUTE[integration.title]);
        }
    };
    var setCategory = function (newCategory) {
        setCurrentCategory(newCategory);
        sideMenuHide();
    };
    var categories = getIntegrationsCategories(t).filter(function (e) {
        return isOnBoarding ? e.category !== IntegrationCategory.BANKING : true;
    });
    var integrationList = getIntegrationsCards(company, t)
        .filter(function (e) { return (isOnBoarding ? e.category !== IntegrationCategory.BANKING : true); })
        .filter(function (e) { return (company.isFrenchClient ? e.title !== Integration.CODABOX : true); })
        .filter(function (e) {
        return currentCategory === IntegrationCategory.ALL ||
            e.category === currentCategory ||
            (currentCategory === IntegrationCategory.MY_INTEGRATIONS && e.isActive);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE)),
        React.createElement(Card, { radius: "md", shadow: "sm" },
            React.createElement(Tabs, { orientation: "vertical", value: currentCategory, onChange: function (category) {
                    if (isNonNull(category))
                        setCategory(category);
                }, variant: "pills", radius: "xl", pb: "xl" }, categories.map(function (_a) {
                var text = _a.text, category = _a.category;
                return (React.createElement(Tabs.Tab, { key: category, value: category, onClick: function () { return setCategory(category); } }, text));
            })),
            React.createElement(SimpleGrid, { cols: 2 }, integrationList.map(function (integration) { return (React.createElement(IntegrationsCard, { key: integration.title, integration: integration, onClick: onClickCard })); })))));
};
