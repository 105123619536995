import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import { sideMenuShow } from 'store/view/view.actions';
import PaymentPlanDetailDebtor from '../PaymentPlanDetailDebtor';
import styleIdentifiers from './DebtorReactionPaymentPlansList.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorReactionPaymentPlansList(_a) {
    var items = _a.items, data = _a.data;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var showDetails = function (id) {
        var paymentPlan = items.find(function (item) { return item.id === id; });
        sideMenuShow({
            unmount: true,
            content: React.createElement(PaymentPlanDetailDebtor, { paymentPlan: paymentPlan }),
        });
    };
    var getStatus = function (completed) {
        return completed ? i18nKeys.COMPLETED : i18nKeys.CANCELLED;
    };
    return (React.createElement("div", { className: styles('debtor-reaction-payment-plans-list') },
        React.createElement("div", { className: styles('payment-plan-list') }, (items === null || items === void 0 ? void 0 : items.length) > 0 ? (items.map(function (_a) {
            var id = _a.id, _b = _a.attributes, remaining_balance = _b.remaining_balance, currency = _b.currency, created_at = _b.created_at, next_due_date = _b.next_due_date, cancelled = _b.cancelled, completed = _b.completed;
            return (React.createElement("div", { key: id, className: styles('payment-plan-item', cancelled && 'cancelled', completed && 'completed') },
                React.createElement("div", { className: styles('payment-plan-info'), onClick: function () { return showDetails(id); } },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles('reference') }, "".concat(t(i18nKeys.PAYMENT_PLAN.TITLE.ONE), " #").concat(id)),
                        React.createElement("div", { className: styles('amount') },
                            React.createElement(Amount, { localizedValue: remaining_balance, value: remaining_balance, suffix: currency, debtor: data.debtor_data }))),
                    React.createElement("div", { className: styles('date') },
                        React.createElement("div", null,
                            React.createElement("span", { className: styles('payment-plan-subtitle') },
                                t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.CREATED_AT),
                                ' '),
                            formattedDate(created_at, currentLang)),
                        React.createElement("div", null, cancelled || completed ? (React.createElement(React.Fragment, null, t(getStatus(completed)))) : (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: styles('payment-plan-subtitle') },
                                t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.NEXT_DUE_DATE),
                                ' '),
                            formattedDate(next_due_date, currentLang)))))),
                React.createElement("div", { className: styles('actions-container'), onClick: function () { return showDetails(id); } },
                    React.createElement(Icon, { name: IconName.EYE }))));
        })) : (React.createElement("div", { className: styles('no-payment-plan') }, t(i18nKeys.NO_RESULT))))));
}
