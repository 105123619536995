import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import UserInfo from 'shared/components/UserInfo';
import { useLoadLightUsers, useProfile } from 'shared/hooks';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { useFilterForm, useSearchDebtors } from 'shared/utils/hooks';
import { currencySymbol } from 'shared/utils/normalization';
import styleIdentifiers from './ThirdpartyCasesFiltersForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ThirdpartyCasesFiltersForm(_a) {
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var profile = useProfile();
    var _b = useSearchDebtors(), debtors = _b.debtors, getDebtors = _b.getDebtors;
    var _c = useLoadLightUsers().users, users = _c === void 0 ? [] : _c;
    var _d = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: [],
    }), form = _d.form, resetForm = _d.resetForm, submit = _d.submit;
    var handleSubmit = form.handleSubmit, register = form.register, setValue = form.setValue;
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = function () {
                resetForm();
            };
        }
    }, [filterObject, resetForm]);
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('ThirdpartyCasesFiltersForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('container-fields') },
                React.createElement("div", { className: styles('fields') },
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { register: register('filenumber'), noMargin: true, withBorder: true, defaultValue: "", shadow: true, type: "text", label: t(i18nKeys.THIRD_PARTY_CASES.REFERENCE), onValueChanged: submit })),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Controller, { defaultValue: "", name: "debtor_name", render: function () { return (React.createElement(CustomSelect, { CustomInput: Input, noMargin: true, size: "small", load: getDebtors, onInputChange: submit, items: debtors, name: "debtor_name", keyText: "full_name", keyValue: "full_name", withBorder: true, noBorder: true, noArrow: true, shadow: true, label: t(i18nKeys.COMMON.CLIENT), onValueChanged: submit })); } })),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { register: register('invoice_reference'), noMargin: true, withBorder: true, defaultValue: "", shadow: true, type: "text", label: t(i18nKeys.INVOICE_NUMBER), onValueChanged: submit })),
                    React.createElement(Controller, { defaultValue: "", name: "status", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), keyText: "description", keyValue: "value", size: "small", name: "status", noMargin: true, noBorder: true, withBorder: true, shadow: true, items: constants.tp_case_statuses, label: t(i18nKeys.STATUS), onValueChanged: submit, removeAll: t(i18nKeys.FORM.SELECT_ALL) })); } }),
                    React.createElement(Controller, { defaultValue: "", name: "unread_messages", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), keyText: "description", keyValue: "value", size: "small", name: "unread_messages", noMargin: true, noBorder: true, withBorder: true, shadow: true, items: [
                                {
                                    description: t(i18nKeys.YES),
                                    value: 'true',
                                },
                                {
                                    description: t(i18nKeys.NO),
                                    value: 'false',
                                },
                            ], label: t(i18nKeys.FORM.THIRD_PARTY_CASES_FILTERS.UNREAD_MESSAGES), onValueChanged: submit, removeAll: t(i18nKeys.FORM.SELECT_ALL) })); } }),
                    React.createElement(AmountFields, { className: styles('spacing'), name: "total_tvac", label: t(i18nKeys.INVOICING.TOTAL_VAT_PRICE_CURRENCY, {
                            text: currencySymbol(undefined, company),
                        }), onChange: submit })),
                React.createElement("div", { className: styles('fields') },
                    React.createElement(Controller, { defaultValue: "", name: "case_type", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), keyText: "description", keyValue: "value", size: "small", name: "case_type", noMargin: true, noBorder: true, withBorder: true, shadow: true, items: constants.tp_case_types, label: t(i18nKeys.CASE_TYPE), onValueChanged: submit })); } }),
                    React.createElement(AmountFields, { className: styles('spacing'), name: "remaining_balance", label: t(i18nKeys.BALANCE_CURRENCY, {
                            text: currencySymbol(undefined, company),
                        }), onChange: submit }),
                    React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "sent_date_after", endName: "sent_date_before", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, label: t(i18nKeys.CREATION_DATE), handleChange: function (value) {
                            setValue('sent_date_after', value);
                            submit();
                        }, handleEndChange: function (value) {
                            setValue('sent_date_before', value);
                            submit();
                        } }),
                    React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "update_date_after", endName: "update_date_before", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, label: t(i18nKeys.LAST_UPDATE), handleChange: function (value) {
                            setValue('update_date_after', value);
                            submit();
                        }, handleEndChange: function (value) {
                            setValue('update_date_before', value);
                            submit();
                        } }),
                    (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Controller, { defaultValue: "", name: "account_manager_id", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('xl'), keyValue: "id", onValueChanged: submit, withBorder: true, shadow: true, itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({}, props, { inline: true })); }, name: "account_manager_id", items: users, label: t(i18nKeys.ACCOUNT_MANAGER) })); } })))))));
}
