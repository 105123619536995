import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { OperatorValues } from 'shared/forms/PreferencesForm';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './AssignationConditions.scss';
var styles = classNames.bind(styleIdentifiers);
export var AssignationConditionText = function (_a) {
    var assignationCondition = _a.assignationCondition, availableConditions = _a.availableConditions, detailView = _a.detailView;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = assignationCondition.attributes, operator = _c.operator, value = _c.value, name = _c.name, id = _c.id;
    var _d = availableConditions.find(function (condition) { return condition.value === name; }) || {}, description = _d.description, var_type = _d.var_type;
    var parsedValues = value
        .map(function (v, i) {
        if (var_type === 'boolean') {
            return t(i18nKeys[v ? 'YES' : 'NO']);
        }
        if (var_type === 'date') {
            if (i === 0) {
                return '';
            }
            if (i === 1) {
                return [
                    value[0] === '' ? '∞' : formattedDate(new Date(value[0]), currentLang),
                    v === '' ? '∞' : formattedDate(new Date(v), currentLang),
                ].join(' - ');
            }
        }
        return v;
    })
        .filter(function (v) { return v !== ''; })
        .join(' / ');
    if (detailView) {
        return (React.createElement("div", { className: styles('item'), key: id },
            React.createElement("span", { className: styles('subtitle') }, description),
            React.createElement("span", { className: styles('value') },
                operator && OperatorValues[operator === null || operator === void 0 ? void 0 : operator.toUpperCase()],
                " ",
                parsedValues)));
    }
    return (React.createElement("p", { className: styles('assignation-condition') },
        description,
        " (",
        operator && "".concat(OperatorValues[operator === null || operator === void 0 ? void 0 : operator.toUpperCase()], " "),
        parsedValues,
        ")"));
};
