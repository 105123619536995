import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import Card from 'shared/components/Card';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './debtorAdditionalInfo.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorAdditionalInfo(_a) {
    var _b, _c;
    var imported = _a.imported, clientData = _a.clientData;
    var _d = useTranslation(), t = _d.t, availableLang = _d.availableLang;
    var history = useHistory();
    var seeClient = function (debtorId) { return function () {
        dialogHide(DialogShowId.INVOICE);
        history.push("/clients/".concat(debtorId));
    }; };
    return (React.createElement(Card, { title: t(i18nKeys.DETAILS), className: styles('box', 'client-info', 'className'), actionHead: imported ? undefined : seeClient(clientData.id) },
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CLIENT.EXTERNAL_ID)),
            React.createElement("div", { className: styles('value', 'medium') }, clientData.csv_id)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.VAT_NUMBER)),
            React.createElement("div", { className: styles('value', 'medium') }, clientData.vat_number)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.RECOVERY_PLAN)),
            React.createElement("div", { className: styles('value', 'medium') }, (_b = clientData.recovery_plan) === null || _b === void 0 ? void 0 : _b.name)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.LOCALE)),
            React.createElement("div", { className: styles('value', 'medium') }, clientData.locale
                ? availableLang.find(function (lang) { return lang.value === clientData.locale; }).description
                : 'N/A')),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.CURRENCY)),
            React.createElement("div", { className: styles('value', 'medium') }, clientData.currency)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMON.BANK_ACCOUNT)),
            React.createElement("div", { className: styles('value', 'medium') }, (_c = clientData.debtor_bank_accounts_attributes[0]) === null || _c === void 0 ? void 0 : _c.iban))));
}
