import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, get, useForm } from 'react-hook-form';
import { Button, ButtonColor, CustomSelect, Input, VatInput } from 'shared/io';
import { appActions } from 'store/app/app.actions';
import AddressForm from './AddressForm/AddressForm';
import styleIdentifiers from './GeneralInfoForm.scss';
var styles = classNames.bind(styleIdentifiers);
// STEP 1
export default function GeneralInfoForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({ shouldUnregister: true });
    var watch = form.watch, setValue = form.setValue;
    var vatNumber = watch('vatNumber');
    var getAddress = function () {
        appActions.fetchAddress({
            data: {
                vat_number: vatNumber,
            },
            callback: function (res) {
                var keys = Object.keys(res);
                for (var i = 0; i < keys.length; i += 1) {
                    var key = keys[i];
                    var elem = res[key];
                    if (key === 'company_name')
                        continue;
                    var camelCaseKey = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
                    setValue("addressAttributes.".concat(camelCaseKey), elem);
                }
            },
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('general-info-form'), onSubmit: form.handleSubmit(onSubmit) },
            React.createElement("div", { className: "grid-row" },
                React.createElement("div", { className: styles('col-12', 'flex') },
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { errorMessage: get(form.formState.errors, 'companyEmail'), className: styles('input'), withBorder: true, register: form.register('companyEmail', {
                                required: true,
                            }), type: "text", label: t(i18nKeys.COMPANY_EMAIL) })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { errorMessage: get(form.formState.errors, 'phoneNumber'), className: styles('input'), withBorder: true, register: form.register('phoneNumber', {
                                required: true,
                            }), type: "text", label: t(i18nKeys.PHONE) })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "locale", items: [
                                { value: 'FR', description: 'Francais' },
                                { value: 'EN', description: 'English' },
                                { value: 'NL', description: 'Nederlands' },
                            ], label: t(i18nKeys.COMPANY_LOCALE) })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "currency", items: [
                                { description: 'Euro', value: 'EUR' },
                                { description: 'US Dollar', value: 'USD' },
                                { description: 'British Pound', value: 'GBP' },
                            ], label: t(i18nKeys.CURRENCY) }))),
                React.createElement("div", { className: styles('col-12') },
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(VatInput, { className: styles('input', 'vat-input'), withBorder: true, name: "vatNumber", label: t(i18nKeys.FORM.VAT), noDropdownMargin: true }),
                        typeof vatNumber === 'string' && !vatNumber.includes('_') && (React.createElement("div", { className: styles('info') },
                            React.createElement(Button, { noMargin: true, noShadow: true, color: ButtonColor.BLUE, className: styles('link'), label: t(i18nKeys.FORM.CLIENT.PRE_FILL), onClick: getAddress }))))),
                React.createElement("div", { className: "col-12" },
                    React.createElement(AddressForm, null))),
            React.createElement(Button, { className: styles('button'), label: t(i18nKeys.ONBOARDING.NEXT_STEP), type: "submit" }))));
}
