import { __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { Button, ButtonColor, Input } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './ExternalMailboxSettingsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function OAauthModal(_a) {
    var confirmButtonColor = _a.confirmButtonColor, onConfirm = _a.onConfirm, children = _a.children;
    var t = useTranslation().t;
    var _b = __read(useState(''), 2), email = _b[0], setEmail = _b[1];
    var onClickOnButton = function (e) {
        e.preventDefault();
        dialogHide(DialogShowId.CONFIRM);
    };
    return (React.createElement("div", { className: styles('oauth-modal') },
        React.createElement(Input, { type: "email", withBorder: true, label: t(i18nKeys.EMAIL), noMargin: true, value: email, onValueChanged: function (value) { return setEmail(value); } }),
        React.createElement("form", { onSubmit: onClickOnButton },
            React.createElement(Button, { type: "submit", color: ButtonColor.GREY, label: t(i18nKeys.CANCEL), onClick: onClickOnButton, noShadow: true }),
            React.createElement(Button, { type: "submit", color: confirmButtonColor, label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CONNECT), onClick: function () { return onConfirm({ login: email, is_microsoft: true }); }, noShadow: true, childrenBefore: true, disabled: !email.match(/^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/) }, children))));
}
