import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { useGetRecoveryPlans } from 'api';
import { AssignableModel, AssignableTo, } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { useLoadLightUsers } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import { usersToDescVal } from 'shared/utils';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { getInitialValues } from '../AutomaticAssignation.utils';
import AssignationConditionsForm from './AssignationConditionsForm';
import styleIdentifiers from './AutomaticAssignationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var AutomaticAssignationForm = function (_a) {
    var prefill = _a.prefill, onSubmit = _a.onSubmit, automaticAssignation = _a.automaticAssignation, close = _a.close, itemsTotal = _a.itemsTotal, availableConditions = _a.availableConditions;
    var t = useTranslation().t;
    var recoveryPlansResponse = useGetRecoveryPlans().data;
    var recoveryPlans = ((recoveryPlansResponse === null || recoveryPlansResponse === void 0 ? void 0 : recoveryPlansResponse.data) || []).map(function (e) { return ({
        description: e.attributes.name,
        value: e.id,
    }); });
    var _b = useLoadLightUsers().users, _users = _b === void 0 ? [] : _b;
    var users = usersToDescVal(_users);
    var modelsToAssign = Object.values(AssignableModel).map(function (e) { return ({
        description: t(i18nKeys.COMMON[e === AssignableModel.debtor ? 'CLIENT' : 'INVOICE']),
        value: e,
    }); });
    var assignableTo = Object.values(AssignableTo).map(function (e) { return ({
        description: t(i18nKeys[e === AssignableTo.user ? 'ACCOUNT_MANAGER' : 'RECOVERY_PLAN']),
        value: e,
    }); });
    var defaultValues = __assign({ model_to_assign: prefill === null || prefill === void 0 ? void 0 : prefill.modelToAssign, assign_to_type: prefill === null || prefill === void 0 ? void 0 : prefill.assignToType, assign_to_id: '', assignation_conditions_attributes: [{ name: undefined, value: [] }] }, getInitialValues(automaticAssignation));
    var form = useForm({
        shouldUnregister: true,
        defaultValues: defaultValues,
    });
    var watch = form.watch, isDirty = form.formState.isDirty, handleSubmit = form.handleSubmit, setValue = form.setValue, control = form.control;
    var _c = useFieldArray({
        control: control,
        name: 'assignation_conditions_attributes',
    }), fields = _c.fields, append = _c.append, remove = _c.remove;
    var values = watch('assignation_conditions_attributes');
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var items = watch('assign_to_type') === AssignableTo.recovery_plan ? recoveryPlans : users;
    var fieldName = t(i18nKeys[watch('assign_to_type') === AssignableTo.recovery_plan ? 'RECOVERY_PLAN' : 'ACCOUNT_MANAGER']);
    var priorities = [];
    for (var i = 1; i <= (itemsTotal || 0); i += 1) {
        priorities.push({ description: i.toString(), value: i });
    }
    var removeCondition = function (index) {
        var _a;
        if ((_a = values[index]) === null || _a === void 0 ? void 0 : _a.id) {
            setValue("assignation_conditions_attributes.".concat(index, "._destroy"), true, // React hook form typescript fix
            {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
        else {
            remove(index);
        }
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('automatic-assignation-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('head') },
                t(i18nKeys.FORM.AUTOMATIC_ASSIGNATION[automaticAssignation ? 'EDIT' : 'NEW']),
                close && (React.createElement("div", { className: styles('close'), onClick: close },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, size: "20px" })))),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { name: "model_to_assign", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "model_to_assign", label: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN), keyValue: "value", keyText: "description", items: modelsToAssign, selectClassName: styles('input'), withBorder: true, notAllowed: true })); } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { name: "assign_to_type", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "assign_to_type", label: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO), keyValue: "value", keyText: "description", items: assignableTo, selectClassName: styles('input'), withBorder: true, onChange: function () {
                                    setValue('assign_to_id', '');
                                }, notAllowed: true })); } })),
                    typeof watch('assign_to_type') !== 'undefined' && watch('assign_to_type') !== null && (React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { name: "assign_to_id", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "assign_to_id", label: fieldName, keyValue: "value", keyText: "description", items: items, selectClassName: styles('input'), withBorder: true })); } }))),
                    automaticAssignation && (React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { name: "order", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "order", label: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER), keyValue: "value", keyText: "description", items: priorities, selectClassName: styles('input'), withBorder: true })); } }))),
                    React.createElement(FormSection, { className: styles('assignation-condition-section'), title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS), onAdd: function () {
                            return append({
                                name: undefined,
                                value: [],
                                operator: undefined,
                            });
                        } },
                        React.createElement(AssignationConditionsForm, { availableConditions: availableConditions, formArray: fields, removeCondition: removeCondition })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), noShadow: true, noMargin: true })))));
};
