import { __assign, __read } from "tslib";
import { useSearchInvoices } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { LightInvoiceItem } from '../../components';
import styleIdentifiers from './InvoiceProductLine.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceProductLineInvoice(props) {
    var lineIndex = props.lineIndex, currency = props.currency, remove = props.remove, selected = props.selected;
    var t = useTranslation().t;
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue, control = _a.control, register = _a.register;
    var _b = __read(useState({
        currency: currency,
    }), 2), params = _b[0], setParams = _b[1];
    var _c = useSearchInvoices(params), invoicesResponse = _c.data, refetch = _c.refetch;
    var debtor = watch('debtor_attributes');
    register("product_lines_attributes.".concat(lineIndex, ".product_attributes.name"));
    register("product_lines_attributes.".concat(lineIndex, ".product_attributes.current_unity_price_htva"));
    register("product_lines_attributes.".concat(lineIndex, ".total_htva"));
    register("product_lines_attributes.".concat(lineIndex, ".total_tvac"));
    var productLine = watch("product_lines_attributes.".concat(lineIndex));
    useEffect(function () {
        if (Array.isArray(selected)) {
            setParams(function (currentParams) { return (__assign(__assign({}, currentParams), { exclude: selected.filter(function (e) { return Number(e) !== Number(productLine.linked_invoice_id); }) })); });
        }
    }, [productLine, selected]);
    useEffect(function () {
        if (debtor) {
            setParams(function (currentParams) { return (__assign(__assign({}, currentParams), { debtor_id: debtor.id })); });
        }
    }, [debtor]);
    var onSelectProduct = function (_a) {
        var item = _a.item;
        setValue("product_lines_attributes.".concat(lineIndex, ".product_attributes.name"), item.reference);
        setValue("product_lines_attributes.".concat(lineIndex, ".product_attributes.current_unity_price_htva"), -item.total_htva);
        setValue("product_lines_attributes.".concat(lineIndex, ".total_htva"), -item.total_htva);
        setValue("product_lines_attributes.".concat(lineIndex, ".total_tvac"), -item.total_tvac);
    };
    var removeProduct = function () {
        remove(lineIndex);
    };
    var concatedInvoices = ((invoicesResponse === null || invoicesResponse === void 0 ? void 0 : invoicesResponse.data) || []).map(function (e) {
        // TODO: Change this ... (Required for customSelect)
        return treatInvoice(e);
    });
    return (React.createElement("tr", { className: styles('invoice-product-line') },
        React.createElement("td", null,
            React.createElement(Controller, { control: control, rules: { required: true }, name: "product_lines_attributes.".concat(lineIndex, ".linked_invoice_id"), render: function () { return (React.createElement(CustomSelect, { load: function () { return refetch(); }, keyValue: "id", keyText: "reference", size: "small", className: styles('invoice-select-input-wrapper'), customInputClassname: styles('invoice-select-input'), selectClassName: styles('invoice-select'), valueClassName: styles('value'), errorClassName: styles('invoice-select-error'), itemRendering: function (data) { return (React.createElement(LightInvoiceItem, __assign({}, data, pick(props, ['isCreditNote', 'noTva', 'currency', 'company', 'constants'])))); }, items: concatedInvoices, name: "product_lines_attributes.".concat(lineIndex, ".linked_invoice_id"), placeholder: t(i18nKeys.INVOICING.CREATE.CHOOSE_PRODUCT), onSelectItem: onSelectProduct, noMargin: true, noArrow: true, noBorder: true })); } })),
        React.createElement("td", null),
        React.createElement("td", null,
            React.createElement(Amount, { value: watch("product_lines_attributes.".concat(lineIndex, ".total_htva")), suffix: currency })),
        React.createElement("td", null),
        React.createElement("td", { className: styles('amount') },
            React.createElement("div", { className: styles('htva-price') },
                React.createElement(Amount, { value: watch("product_lines_attributes.".concat(lineIndex, ".total_htva")), suffix: currency }))),
        React.createElement("td", null),
        React.createElement("td", { className: styles('amount') },
            React.createElement("div", { className: styles('vat-total') },
                React.createElement(Amount, { value: watch("product_lines_attributes.".concat(lineIndex, ".total_tvac")), suffix: currency }),
                React.createElement("div", { className: styles('remove'), onClick: removeProduct },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "16px" }))))));
}
