import { __assign } from "tslib";
import axios from 'axios';
import { config } from 'store/constants';
var ApiService = /** @class */ (function () {
    function ApiService(subdomain) {
        this.tenant = subdomain;
        this.baseUrl = config.api.baseUrl;
        this.domain = config.api.domain;
    }
    ApiService.axiosCall = function (url, request) {
        return axios(url, request)
            .then(function (response) { return response; })
            .catch(function (error) { return error.response; });
    };
    ApiService.prototype.call = function (path, type, options) {
        if (options === void 0) { options = {}; }
        var url = this.baseUrl + path;
        var headers = __assign(__assign({}, options.headers), { tenant: this.tenant, 'Content-Type': 'application/json' });
        var data = options.data;
        if (options.fileUpload) {
            headers = __assign(__assign({}, headers), { Accept: 'application/json', 'Content-Type': 'multipart/form-data;' });
            var formData = new FormData();
            var keys = Object.keys(data);
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                formData.append(key, data[key]);
            }
            data = formData;
        }
        // Request
        var request = {};
        request.method = type;
        request.headers = headers;
        request.data = data;
        request.params = data;
        if (options.fileUpload) {
            request.onUploadProgress = function (event) {
                if (typeof options.progress === 'function') {
                    var progress = Math.round((event.loaded / event.total) * 100);
                    options.progress(progress);
                }
            };
        }
        return ApiService.axiosCall(url, request);
    };
    return ApiService;
}());
var instance;
function getInstance() {
    if (instance)
        return instance;
    var href = window.location.href;
    var subdomain = href.substring(href.indexOf('://') + 3, href.indexOf('.'));
    if (!subdomain)
        throw new Error('Bad subdomain');
    instance = new ApiService(subdomain);
    return instance;
}
export default getInstance();
