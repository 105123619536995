import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { useInvoiceFormTotals } from '../InvoiceForm.hooks';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var TotalsDetails = function (_a) {
    var currency = _a.currency, noTva = _a.noTva, constants = _a.constants;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var watch = useFormContext().watch;
    var _c = useInvoiceFormTotals(), totalTvac = _c.totalTvac, totalHtva = _c.totalHtva, totalVat = _c.totalVat, totalDiscount = _c.totalDiscount;
    var dueDate = watch('due_date');
    return (React.createElement("div", { className: styles('totals') },
        React.createElement("div", null,
            React.createElement("div", { className: styles('main') },
                React.createElement("span", null,
                    t(i18nKeys.TOTAL_NOT_VAT_PRICE),
                    " :"),
                React.createElement(Amount, { value: totalHtva, suffix: currency })),
            React.createElement("div", { className: styles('secondary') },
                React.createElement("span", null,
                    t(i18nKeys.VAT),
                    " :"),
                React.createElement(Amount, { value: totalVat, suffix: currency }))),
        React.createElement("div", { className: styles('total', 'bottom') },
            React.createElement("div", { className: styles('main') },
                React.createElement("span", null,
                    t(i18nKeys.TOTAL_VAT_PRICE),
                    " :"),
                React.createElement(Amount, { value: totalTvac, suffix: currency })),
            React.createElement("div", { className: styles('secondary', 'spacing') },
                React.createElement("span", null,
                    t(i18nKeys.INVOICE.DISCOUNT),
                    " :"),
                React.createElement(Amount, { value: totalDiscount, suffix: currency })),
            dueDate && (React.createElement("div", { className: styles('secondary') },
                React.createElement("span", null,
                    t(i18nKeys.DUE_DATE),
                    " :"),
                React.createElement(DateItem, { date: dayjs(dueDate).toDate(), lg: currentLang }))))));
};
