import { __read } from "tslib";
import React, { useState } from 'react';
import { useDeleteTask, useUpdateTask } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { getAssigneeFullName, getTaskTypeDescription } from '../Task.utils';
import { TaskForm } from '../TaskForm/TaskForm';
import { TasksListItemStatus } from './TasksListItemStatus';
import styleIdentifiers from './TasksListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export var TaskListItem = function (_a) {
    var item = _a.item, refetch = _a.refetch;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var history = useHistory();
    var task_types = useSelector(function (state) { return state.app.constants; }).task_types;
    var _c = __read(useState(false), 2), hover = _c[0], setHover = _c[1];
    var id = item.id, _d = item.attributes, title = _d.title, debtor = _d.debtor, status = _d.status, due_date_adjusted = _d.due_date_adjusted, status_key = _d.status_key;
    var updateTaskMutation = useUpdateTask().mutate;
    var deleteTaskMutation = useDeleteTask().mutate;
    var updateTask = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { task: item, onSubmit: function (data) {
                    return updateTaskMutation({ id: id, data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var deleteTask = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_TASK), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteTaskMutation({ id: id }, {
                        onSuccess: function () {
                            history.push('/tasks');
                            dialogHide(DialogShowId.CONFIRM);
                            refetch();
                        },
                    });
                } })),
        });
    };
    var goToDetails = function () {
        history.push("/tasks/".concat(id));
    };
    return (React.createElement("tr", { className: styles('task-list-item', hover && 'hover'), onMouseEnter: function () { return setHover(true); }, onMouseOver: function () { return setHover(true); }, onFocus: function () { return setHover(true); }, onMouseLeave: function () { return setHover(false); }, key: id },
        React.createElement("td", null),
        React.createElement("td", { onClick: goToDetails }, title),
        React.createElement("td", { onClick: goToDetails }, getTaskTypeDescription(task_types, item)),
        debtor ? (React.createElement("td", { className: styles('debtor') },
            React.createElement(NavLink, { to: "/clients/".concat(debtor.id) }, debtor.attributes.full_name))) : (React.createElement("td", { onClick: goToDetails }, "-")),
        React.createElement("td", { onClick: goToDetails },
            React.createElement(TasksListItemStatus, { status: status, statusKey: status_key })),
        React.createElement("td", { onClick: goToDetails }, getAssigneeFullName(item)),
        due_date_adjusted ? (React.createElement("td", { onClick: goToDetails }, formattedDate(new Date(due_date_adjusted), currentLang))) : (React.createElement("td", { onClick: goToDetails }, "-")),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: updateTask },
                React.createElement(Icon, { name: IconName.SETTINGS_GEAR, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: deleteTask },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
};
