import { __assign, __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useDeleteUnvalidatedImports, useGetInvoicesToConfirm } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { pick } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import CustomTable from 'shared/components/CustomTable';
import InvoiceListItem from 'shared/components/InvoiceListItem';
import RecovrLogoLoader from 'shared/components/Loader';
import { useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { useGetInvoiceState } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import styleIdentifiers from './InvoiceListToConfirm.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(InvoiceListItem);
export default function InvoiceListToConfirm() {
    var _a;
    var t = useTranslation().t;
    var history = useHistory();
    var profile = useProfile();
    var isProcessing = useGetInvoiceState().isLoading;
    var _b = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _b[0], setParams = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), ['page', 'page_limit', 'sort_by', 'sort_order']))); });
    }, []);
    var _c = useGetInvoicesToConfirm(params), invoicesToConfirmListResponse = _c.data, isLoading = _c.isLoading, isSuccess = _c.isSuccess, isError = _c.isError;
    var _d = useDeleteUnvalidatedImports(), deleteUnvalidatedImports = _d.mutate, isDeleting = _d.isLoading;
    if (isLoading && !invoicesToConfirmListResponse) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !invoicesToConfirmListResponse) {
        return React.createElement("span", null, "Error");
    }
    var handleDeleteUnvalidatedImports = function () {
        deleteUnvalidatedImports({ data: {} }, {
            onSuccess: function () {
                accountActions.getCompany();
                history.push('/invoices/listing');
            },
        });
    };
    var items = (_a = invoicesToConfirmListResponse.data) !== null && _a !== void 0 ? _a : [];
    var pendingImport = invoicesToConfirmListResponse.pending_import;
    var INVOICES_TABLE_HEADERS = [
        {
            key: 'reference',
            title: t(i18nKeys.INVOICE_NUMBER),
            width: 140,
        },
        {
            key: 'issue_date',
            title: t(i18nKeys.ISSUE_DATE),
            width: 140,
        },
        {
            key: 'due_date',
            title: t(i18nKeys.DUE_DATE),
            width: 140,
        },
        {
            key: 'debtor_attributes.full_name',
            title: t(i18nKeys.COMMON.CLIENT),
            width: 120,
        },
        {
            key: 'days_late',
            title: t(i18nKeys.LATE),
        },
        {
            key: 'step_collection',
            title: t(i18nKeys.STATUS),
            width: 160,
        },
        {
            key: 'total_tvac',
            title: t(i18nKeys.TOTAL_VAT_PRICE),
        },
        {
            key: 'remaining_balance',
            title: t(i18nKeys.BALANCE),
        },
        {
            title: '',
            width: 120,
        },
    ];
    var handleFilters = function (newValues) {
        var optimisticResult = __assign(__assign({}, params), pick(newValues, ['page_limit', 'page']));
        setParams(function (current) { return (__assign(__assign({}, current), pick(newValues, ['page_limit', 'page']))); });
        return optimisticResult;
    };
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']))); });
    };
    var action = function (actionToSend) { return function () {
        return actionToSend({
            callback: function () {
                history.push('/invoices/import');
            },
        });
    }; };
    return (React.createElement("div", { className: styles('invoice-list-to-confirm') },
        React.createElement(EnhancedTable, { title: t(i18nKeys.INVOICING.INVOICES_TO_CONFIRM), actions: pendingImport || items.length === 0 ? null : (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: handleDeleteUnvalidatedImports, color: ButtonColor.GREY, label: t(i18nKeys.INVOICING.IMPORT.DELETE_ALL), noMargin: true, disabled: isProcessing || items.filter(function (item) { return !item.attributes.csv_updated; }).length === 0, isLoading: isDeleting }),
                React.createElement(Button, { onClick: action(invoiceActions.validateAllImportedInvoices), color: ButtonColor.GREEN, label: t(i18nKeys.INVOICING.IMPORT.VALIDATE_ALL), noMargin: true, disabled: isDeleting, isLoading: isProcessing }))), noCheckbox: true, onSortChange: onSortChange, loading: isLoading, loaded: isSuccess, pagination: pendingImport ? undefined : invoicesToConfirmListResponse.metadata.pagination, headers: INVOICES_TABLE_HEADERS, handleFilters: handleFilters, itemProps: { isLoading: isDeleting }, items: pendingImport ? [] : items, pendingImport: pendingImport })));
}
