import { useDeleteTemplate } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { showDialog, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './TemplateListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TemplateListItem(_a) {
    var item = _a.item, refetch = _a.refetch;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var history = useHistory();
    var id = item.id, _c = item.attributes, name = _c.name, step_type = _c.step_type, updated_at = _c.updated_at;
    var showTemplate = function () {
        history.push("/settings/document-customization/edit/".concat(item.id));
    };
    var deleteTemplate = useDeleteTemplate().mutate;
    var deleteItem = function (e) {
        e.stopPropagation();
        showDialog({
            id: DialogShowId.CONFIRM,
            title: t(i18nKeys.DELETE_TEMPLATE),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_TEMPLATE), confirmButtonText: t(i18nKeys.DELETE), confirmButtonColor: ButtonColor.RED, onConfirm: function () {
                    return deleteTemplate({ id: id }, {
                        onSuccess: function () {
                            refetch();
                        },
                    });
                } })),
        });
    };
    return (React.createElement("tr", { className: styles('template-item', step_type), onClick: showTemplate },
        React.createElement("td", null),
        React.createElement("td", null, name),
        React.createElement("td", null, t(i18nKeys.TEMPLATE_TYPES[step_type.toUpperCase()])),
        React.createElement("td", null, formattedDate(updated_at, currentLang)),
        React.createElement("td", { className: styles('actions') },
            React.createElement(Icon, { name: IconName.SETTINGS_GEAR, onClick: showTemplate }),
            company.package.can_create_template && (React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteItem })),
            ' ')));
}
