export var i18nKeys = {
    ACCOUNT_LIMITATION: 'ACCOUNT_LIMITATION',
    ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
    ACTIONS: {
        ADD: 'ACTIONS.ADD',
        ADD_SALE_CONDITION: 'ACTIONS.ADD_SALE_CONDITION',
        FAVORITE: 'ACTIONS.FAVORITE',
        FILTER: 'ACTIONS.FILTER',
        USE_BELGIAN_LAW: 'ACTIONS.USE_BELGIAN_LAW',
        TOOLTIP: 'ACTIONS.TOOLTIP',
        USE_DEFAULT: 'ACTIONS.USE_DEFAULT',
    },
    ACTIVATE: 'ACTIVATE',
    ACTIVATED: 'ACTIVATED',
    ACTIVITIES: {
        AUTHOR: 'ACTIVITIES.AUTHOR',
        CALLCENTER: 'ACTIVITIES.CALLCENTER',
        CALLCENTER_CASE: 'ACTIVITIES.CALLCENTER_CASE',
        COUNTERPART: 'ACTIVITIES.COUNTERPART',
        DETAIL: 'ACTIVITIES.DETAIL',
        DOWNLOAD_DOCUMENT: 'ACTIVITIES.DOWNLOAD_DOCUMENT',
        EDIT_COMPANY: 'ACTIVITIES.EDIT_COMPANY',
        EXTERNAL_ID: 'ACTIVITIES.EXTERNAL_ID',
        INVOICES_TO_PAY_ATTENTION: 'ACTIVITIES.INVOICES_TO_PAY_ATTENTION',
        INVOICE_TO_PAY_ATTENTION: 'ACTIVITIES.INVOICE_TO_PAY_ATTENTION',
        LAWYER: 'ACTIVITIES.LAWYER',
        LAWYER_CASE: 'ACTIVITIES.LAWYER_CASE',
        MARK_ALL_AS_READ: 'ACTIVITIES.MARK_ALL_AS_READ',
        NEW: 'ACTIVITIES.NEW',
        PAY_ATTENTION: 'ACTIVITIES.PAY_ATTENTION',
        SEE_CLIENT: 'ACTIVITIES.SEE_CLIENT',
        SEE_CREDIT_NOTE: 'ACTIVITIES.SEE_CREDIT_NOTE',
        SEE_INVOICE: 'ACTIVITIES.SEE_INVOICE',
        SEE_PAYMENT_PLAN: 'ACTIVITIES.SEE_PAYMENT_PLAN',
        SEE_TASK: 'ACTIVITIES.SEE_TASK',
        SEND_MESSAGE: 'ACTIVITIES.SEND_MESSAGE',
        SINCE_LAST_LOGIN: 'ACTIVITIES.SINCE_LAST_LOGIN',
        TITLE: 'ACTIVITIES.TITLE',
        UNPROCESSED_TRANSACTION: 'ACTIVITIES.UNPROCESSED_TRANSACTION',
        UNPROCESSED_TRANSACTIONS: 'ACTIVITIES.UNPROCESSED_TRANSACTIONS',
        UNSEEN_ACTIVITIES: 'ACTIVITIES.UNSEEN_ACTIVITIES',
        UNSEEN_ACTIVITY: 'ACTIVITIES.UNSEEN_ACTIVITY',
        UPDATE_STATUS_CREDIT_NOTE: 'ACTIVITIES.UPDATE_STATUS_CREDIT_NOTE',
        UPDATE_STATUS_DEBTOR: 'ACTIVITIES.UPDATE_STATUS_DEBTOR',
        UPDATE_STATUS_INVOICE: 'ACTIVITIES.UPDATE_STATUS_INVOICE',
        UPDATE_STATUS_TASK: 'ACTIVITIES.UPDATE_STATUS_TASK',
    },
    ADDITIONAL_FILES: 'ADDITIONAL_FILES',
    ADDITIONAL_LINE_1: 'ADDITIONAL_LINE_1',
    ADDITIONAL_LINE_2: 'ADDITIONAL_LINE_2',
    ADDRESS: 'ADDRESS',
    ADDRESSES: 'ADDRESSES',
    ADDRESS_COMPLEMENT: 'ADDRESS_COMPLEMENT',
    ADD_CONTACT_PERSON: 'ADD_CONTACT_PERSON',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    ADD_FILE: 'ADD_FILE',
    ADD_INVOICE: 'ADD_INVOICE',
    ADD_INVOICES: 'ADD_INVOICES',
    ADD_LATER: 'ADD_LATER',
    ADD_LATE_FEES: 'ADD_LATE_FEES',
    ADD_REASON: 'ADD_REASON',
    ADD_REMINDER: 'ADD_REMINDER',
    ADD_STATEMENT: 'ADD_STATEMENT',
    ADD_STEP: 'ADD_STEP',
    ADD_TASK: 'ADD_TASK',
    ADD_TASK_TYPE: 'ADD_TASK_TYPE',
    ADD_VARIABLE: 'ADD_VARIABLE',
    ADD_VIEW: 'ADD_VIEW',
    AMOUNT: 'AMOUNT',
    AMOUNT_BILLED: 'AMOUNT_BILLED',
    AMOUNT_CREDITED: 'AMOUNT_CREDITED',
    AMOUNT_CURRENCY: 'AMOUNT_CURRENCY',
    AMOUNT_MIN: 'AMOUNT_MIN',
    AMOUNT_SETTLED: 'AMOUNT_SETTLED',
    ANALYTICS: {
        AGED_BALANCE: {
            '0': 'ANALYTICS.AGED_BALANCE.0',
            '30': 'ANALYTICS.AGED_BALANCE.30',
            '60': 'ANALYTICS.AGED_BALANCE.60',
            '90': 'ANALYTICS.AGED_BALANCE.90',
            DEBTOR: 'ANALYTICS.AGED_BALANCE.DEBTOR',
            SEE_BREAKDOWN: 'ANALYTICS.AGED_BALANCE.SEE_BREAKDOWN',
            TITLE: 'ANALYTICS.AGED_BALANCE.TITLE',
            notDue: 'ANALYTICS.AGED_BALANCE.notDue',
            totalDue: 'ANALYTICS.AGED_BALANCE.totalDue',
        },
        BETA: 'ANALYTICS.BETA',
        BETA_TITLE: 'ANALYTICS.BETA_TITLE',
        CEI: {
            HELP: {
                LINE_1: 'ANALYTICS.CEI.HELP.LINE_1',
                LINE_2: 'ANALYTICS.CEI.HELP.LINE_2',
                TITLE: 'ANALYTICS.CEI.HELP.TITLE',
            },
            TITLE: 'ANALYTICS.CEI.TITLE',
        },
        DSO: {
            HELP: {
                LINE_1: 'ANALYTICS.DSO.HELP.LINE_1',
                TITLE: 'ANALYTICS.DSO.HELP.TITLE',
            },
            TITLE: 'ANALYTICS.DSO.TITLE',
        },
        HIGHER_BETTER: 'ANALYTICS.HIGHER_BETTER',
        LAST_UPDATED: 'ANALYTICS.LAST_UPDATED',
        LOWER_BETTER: 'ANALYTICS.LOWER_BETTER',
        NOT_DUE_YET: 'ANALYTICS.NOT_DUE_YET',
        OVERDUE: 'ANALYTICS.OVERDUE',
        REFRESH_BALANCE: 'ANALYTICS.REFRESH_BALANCE',
        REFRESH_BALANCE_ERROR: 'ANALYTICS.REFRESH_BALANCE_ERROR',
        REFRESH_BALANCE_SUCCESS: 'ANALYTICS.REFRESH_BALANCE_SUCCESS',
        TICKERS: {
            ACTIONABLE: {
                TITLE: 'ANALYTICS.TICKERS.ACTIONABLE.TITLE',
            },
            AT_RISK: {
                TITLE: 'ANALYTICS.TICKERS.AT_RISK.TITLE',
            },
            DISPUTED: {
                TITLE: 'ANALYTICS.TICKERS.DISPUTED.TITLE',
            },
            PAUSED: {
                TITLE: 'ANALYTICS.TICKERS.PAUSED.TITLE',
            },
            TOTAL: {
                TITLE: 'ANALYTICS.TICKERS.TOTAL.TITLE',
            },
        },
        TITLE: 'ANALYTICS.TITLE',
    },
    AND: 'AND',
    ASSIGN_ACCOUNT_MANAGER: 'ASSIGN_ACCOUNT_MANAGER',
    ASSIGN_ACCOUNT_MANAGER_SHORT: 'ASSIGN_ACCOUNT_MANAGER_SHORT',
    ATTENTION: 'ATTENTION',
    AUTOMATED_PLANS: 'AUTOMATED_PLANS',
    AUTOMATIC: 'AUTOMATIC',
    AUTOMATIC_ASSIGNATION: 'AUTOMATIC_ASSIGNATION',
    AYS: {
        AUTOMATIC_ASSIGNATION_DELETE: 'AYS.AUTOMATIC_ASSIGNATION_DELETE',
        CLIENT_ACTION_UNDISPUTED: 'AYS.CLIENT_ACTION_UNDISPUTED',
        CNS_ACTION_DELETE: 'AYS.CNS_ACTION_DELETE',
        CNS_ACTION_SETTLE: 'AYS.CNS_ACTION_SETTLE',
        CNS_ACTION_UNSETTLE: 'AYS.CNS_ACTION_UNSETTLE',
        CN_ACTION_DELETE: 'AYS.CN_ACTION_DELETE',
        CN_ACTION_SETTLE: 'AYS.CN_ACTION_SETTLE',
        CN_ACTION_UNSETTLE: 'AYS.CN_ACTION_UNSETTLE',
        DELETE_CONTACT_PERSON: 'AYS.DELETE_CONTACT_PERSON',
        DELETE_DOMAIN: 'AYS.DELETE_DOMAIN',
        DELETE_EXTERNAL_MAIL: 'AYS.DELETE_EXTERNAL_MAIL',
        DELETE_EXTERNAL_MAILBOX_CONFIGURATION: 'AYS.DELETE_EXTERNAL_MAILBOX_CONFIGURATION',
        DELETE_EXTERNAL_MAILS: 'AYS.DELETE_EXTERNAL_MAILS',
        DELETE_REASON: 'AYS.DELETE_REASON',
        DELETE_RECOVERY_PLAN: 'AYS.DELETE_RECOVERY_PLAN',
        DELETE_TASK: 'AYS.DELETE_TASK',
        DELETE_TASKS: 'AYS.DELETE_TASKS',
        DELETE_TASK_TYPE: 'AYS.DELETE_TASK_TYPE',
        DELETE_TEMPLATE: 'AYS.DELETE_TEMPLATE',
        EXIT_PAGE: 'AYS.EXIT_PAGE',
        IGNORE_DUPLICATES: 'AYS.IGNORE_DUPLICATES',
        IGNORE_LATE_FEES: 'AYS.IGNORE_LATE_FEES',
        INVOICES_ACTION_DELETE: 'AYS.INVOICES_ACTION_DELETE',
        INVOICES_ACTION_DISPUTED: 'AYS.INVOICES_ACTION_DISPUTED',
        INVOICES_ACTION_IGNORE_LATE_FEES: 'AYS.INVOICES_ACTION_IGNORE_LATE_FEES',
        INVOICES_ACTION_PAID: 'AYS.INVOICES_ACTION_PAID',
        INVOICES_ACTION_PAUSED: 'AYS.INVOICES_ACTION_PAUSED',
        INVOICES_ACTION_TO_BE_PROCESSED: 'AYS.INVOICES_ACTION_TO_BE_PROCESSED',
        INVOICES_ACTION_UNDISPUTED: 'AYS.INVOICES_ACTION_UNDISPUTED',
        INVOICES_ACTION_UNPAUSED: 'AYS.INVOICES_ACTION_UNPAUSED',
        INVOICE_ACTION_DELETE: 'AYS.INVOICE_ACTION_DELETE',
        INVOICE_ACTION_DISPUTED: 'AYS.INVOICE_ACTION_DISPUTED',
        INVOICE_ACTION_IGNORE_LATE_FEES: 'AYS.INVOICE_ACTION_IGNORE_LATE_FEES',
        INVOICE_ACTION_PAID: 'AYS.INVOICE_ACTION_PAID',
        INVOICE_ACTION_PAUSED: 'AYS.INVOICE_ACTION_PAUSED',
        INVOICE_ACTION_TO_BE_PROCESSED: 'AYS.INVOICE_ACTION_TO_BE_PROCESSED',
        INVOICE_ACTION_UNDISPUTED: 'AYS.INVOICE_ACTION_UNDISPUTED',
        INVOICE_ACTION_UNPAUSED: 'AYS.INVOICE_ACTION_UNPAUSED',
        INVOICE_BACK_IN_PLAN: 'AYS.INVOICE_BACK_IN_PLAN',
        PAYMENT_PLAN: 'AYS.PAYMENT_PLAN',
        REQUEST_PAYMENT_PLAN: 'AYS.REQUEST_PAYMENT_PLAN',
    },
    BACK: 'BACK',
    BALANCE: 'BALANCE',
    BALANCE_CURRENCY: 'BALANCE_CURRENCY',
    BANK_ACCOUNT: {
        HISTORY: {
            IGNORED: 'BANK_ACCOUNT.HISTORY.IGNORED',
            PARTIALLY_MATCHED_WITH: 'BANK_ACCOUNT.HISTORY.PARTIALLY_MATCHED_WITH',
            PAYMENT_NO_RECONCILE: 'BANK_ACCOUNT.HISTORY.PAYMENT_NO_RECONCILE',
            RECONCILE_WITH: 'BANK_ACCOUNT.HISTORY.RECONCILE_WITH',
            SENDER: 'BANK_ACCOUNT.HISTORY.SENDER',
            TRANSACTION_NUMBER: 'BANK_ACCOUNT.HISTORY.TRANSACTION_NUMBER',
            UNIGNORED: 'BANK_ACCOUNT.HISTORY.UNIGNORED',
            UNIGNORED_PAYMENT: 'BANK_ACCOUNT.HISTORY.UNIGNORED_PAYMENT',
        },
        RECONCILIATION: {
            ALL_ENTRY_PAYMENT: 'BANK_ACCOUNT.RECONCILIATION.ALL_ENTRY_PAYMENT',
            AYS_TO_IGNORE_PAYMENT: 'BANK_ACCOUNT.RECONCILIATION.AYS_TO_IGNORE_PAYMENT',
            IGNORE_PAYMENTS: 'BANK_ACCOUNT.RECONCILIATION.IGNORE_PAYMENTS',
            LATE_FEES: 'BANK_ACCOUNT.RECONCILIATION.LATE_FEES',
            MANUAL_RECONCILIATION: 'BANK_ACCOUNT.RECONCILIATION.MANUAL_RECONCILIATION',
            PAYMENTS: 'BANK_ACCOUNT.RECONCILIATION.PAYMENTS',
            SEVERAL_ACCOUNT_SELECTED: 'BANK_ACCOUNT.RECONCILIATION.SEVERAL_ACCOUNT_SELECTED',
            SUGGESTION: 'BANK_ACCOUNT.RECONCILIATION.SUGGESTION',
            UNPAID_INVOICES: 'BANK_ACCOUNT.RECONCILIATION.UNPAID_INVOICES',
        },
    },
    BANK_ACCOUNTS: 'BANK_ACCOUNTS',
    BATCH: {
        ACTIONS: {
            ALLOW_PAYMENT_PLANS: {
                TEXT: 'BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TEXT',
                TITLE: 'BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TITLE',
            },
            CUSTOM_VARIABLES: 'BATCH.ACTIONS.CUSTOM_VARIABLES',
        },
        CATEGORIES: {
            ASSIGNMENT: 'BATCH.CATEGORIES.ASSIGNMENT',
            FOLLOW_UP: 'BATCH.CATEGORIES.FOLLOW_UP',
            OTHER: 'BATCH.CATEGORIES.OTHER',
            RECOVERY: 'BATCH.CATEGORIES.RECOVERY',
            STATUS: 'BATCH.CATEGORIES.STATUS',
        },
    },
    BCC: 'BCC',
    BILLING_STATEMENT_DESCRIPTION: 'BILLING_STATEMENT_DESCRIPTION',
    BODY: 'BODY',
    CALCULATION_TYPE: {
        ANNUAL: 'CALCULATION_TYPE.ANNUAL',
        MONTHLY: 'CALCULATION_TYPE.MONTHLY',
        QUARTERLY: 'CALCULATION_TYPE.QUARTERLY',
    },
    CANCEL: 'CANCEL',
    CANCELLED: 'CANCELLED',
    CANCELLED_AT: 'CANCELLED_AT',
    CASE_TYPE: 'CASE_TYPE',
    CC: 'CC',
    CHOOSE: 'CHOOSE',
    CHOOSE_ACCOUNT_MANAGER: 'CHOOSE_ACCOUNT_MANAGER',
    CLAIMED_LATE_FEES: 'CLAIMED_LATE_FEES',
    CLIENT: {
        AYS_TO_DELETE_CLIENT: 'CLIENT.AYS_TO_DELETE_CLIENT',
        AYS_TO_DELETE_CLIENT_ALL: 'CLIENT.AYS_TO_DELETE_CLIENT_ALL',
        AYS_TO_DELETE_CLIENT_MULTIPLE: 'CLIENT.AYS_TO_DELETE_CLIENT_MULTIPLE',
        AYS_TO_PAUSE_CLIENT: 'CLIENT.AYS_TO_PAUSE_CLIENT',
        AYS_TO_PAUSE_CLIENT_ALL: 'CLIENT.AYS_TO_PAUSE_CLIENT_ALL',
        AYS_TO_PAUSE_CLIENT_MULTIPLE: 'CLIENT.AYS_TO_PAUSE_CLIENT_MULTIPLE',
        AYS_TO_UNPAUSE_CLIENT: 'CLIENT.AYS_TO_UNPAUSE_CLIENT',
        AYS_TO_UNPAUSE_CLIENT_ALL: 'CLIENT.AYS_TO_UNPAUSE_CLIENT_ALL',
        AYS_TO_UNPAUSE_CLIENT_MULTIPLE: 'CLIENT.AYS_TO_UNPAUSE_CLIENT_MULTIPLE',
        CLIENT_DELETION: 'CLIENT.CLIENT_DELETION',
        DELETED: 'CLIENT.DELETED',
        DELETE_MULTIPLE: 'CLIENT.DELETE_MULTIPLE',
        DETAIL: {
            APPLIED_VAT_0: 'CLIENT.DETAIL.APPLIED_VAT_0',
            AVERAGE_DELAY_AUTHORIZED: 'CLIENT.DETAIL.AVERAGE_DELAY_AUTHORIZED',
            CHANGE_INVOICES_STATE: 'CLIENT.DETAIL.CHANGE_INVOICES_STATE',
            CHANGE_TERMS_AND_CONDITIONS: 'CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS',
            CLIENT_TOTAL_TVAC: 'CLIENT.DETAIL.CLIENT_TOTAL_TVAC',
            COCONTRACTOR: 'CLIENT.DETAIL.COCONTRACTOR',
            CONTACT_ADMIN: 'CLIENT.DETAIL.CONTACT_ADMIN',
            DOWNLOAD_OLD_DOC: 'CLIENT.DETAIL.DOWNLOAD_OLD_DOC',
            HAVE_CONDITIONS_FOR_CLIENT: 'CLIENT.DETAIL.HAVE_CONDITIONS_FOR_CLIENT',
            INFORMATIONS: 'CLIENT.DETAIL.INFORMATIONS',
            INTEREST_RATE: 'CLIENT.DETAIL.INTEREST_RATE',
            LATE: 'CLIENT.DETAIL.LATE',
            LATE_PAYMENT_RATE: 'CLIENT.DETAIL.LATE_PAYMENT_RATE',
            MIN_DAYS_BETWEEN_REMINDER: 'CLIENT.DETAIL.MIN_DAYS_BETWEEN_REMINDER',
            MIN_PENALTY_CLAUSE: 'CLIENT.DETAIL.MIN_PENALTY_CLAUSE',
            NOT_HAVE_CONDITIONS_FOR_CLIENT: 'CLIENT.DETAIL.NOT_HAVE_CONDITIONS_FOR_CLIENT',
            NO_ACTIVITY: 'CLIENT.DETAIL.NO_ACTIVITY',
            OVERWRITE_OLD_DOC: 'CLIENT.DETAIL.OVERWRITE_OLD_DOC',
            PAUSE_DEBTOR_RECOVERY: 'CLIENT.DETAIL.PAUSE_DEBTOR_RECOVERY',
            REMAIN_BALANCE: 'CLIENT.DETAIL.REMAIN_BALANCE',
            SALES_CONDITIONS: 'CLIENT.DETAIL.SALES_CONDITIONS',
            SHOW_SETTLED: 'CLIENT.DETAIL.SHOW_SETTLED',
            TYPES: 'CLIENT.DETAIL.TYPES',
            UNPAUSE_DEBTOR_RECOVERY: 'CLIENT.DETAIL.UNPAUSE_DEBTOR_RECOVERY',
        },
        DISPUTED_REASON: 'CLIENT.DISPUTED_REASON',
        EDITING_DEFAULT_TEMPLATE: 'CLIENT.EDITING_DEFAULT_TEMPLATE',
        EDIT_DEFAULT_TEMPLATE: 'CLIENT.EDIT_DEFAULT_TEMPLATE',
        EXPORT_CSV: 'CLIENT.EXPORT_CSV',
        EXTERNAL_ID: 'CLIENT.EXTERNAL_ID',
        FILTER: {
            ALL: 'CLIENT.FILTER.ALL',
            CURRENT_BALANCE: 'CLIENT.FILTER.CURRENT_BALANCE',
            LATE_BALANCE: 'CLIENT.FILTER.LATE_BALANCE',
            LATE_PAYMENT: 'CLIENT.FILTER.LATE_PAYMENT',
            ONGOING: 'CLIENT.FILTER.ONGOING',
            PAUSED: 'CLIENT.FILTER.PAUSED',
            RECOVERY_STATUS: 'CLIENT.FILTER.RECOVERY_STATUS',
            REVENUES_EURO: 'CLIENT.FILTER.REVENUES_EURO',
        },
        GENERAL_INFO: 'CLIENT.GENERAL_INFO',
        HEADER: {
            AVERAGE_AUTHORIZED_DELAY: 'CLIENT.HEADER.AVERAGE_AUTHORIZED_DELAY',
            CURRENT_BALANCE: 'CLIENT.HEADER.CURRENT_BALANCE',
            LATE_BALANCE: 'CLIENT.HEADER.LATE_BALANCE',
        },
        IMPORT: {
            CLIENTS_TO_CONFIRM: 'CLIENT.IMPORT.CLIENTS_TO_CONFIRM',
        },
        MERGE: {
            DESCRIPTION: 'CLIENT.MERGE.DESCRIPTION',
            IGNORE: 'CLIENT.MERGE.IGNORE',
            SELECT_CLIENT: 'CLIENT.MERGE.SELECT_CLIENT',
            TITLE: 'CLIENT.MERGE.TITLE',
        },
        MOVE_INVOICES_IN_PLAN: 'CLIENT.MOVE_INVOICES_IN_PLAN',
        NOT_IMPORTED: 'CLIENT.NOT_IMPORTED',
        PAUSE: 'CLIENT.PAUSE',
        PAUSED_REASON: 'CLIENT.PAUSED_REASON',
        PAUSE_MULTIPLE: 'CLIENT.PAUSE_MULTIPLE',
        PORTAIL_LINK: 'CLIENT.PORTAIL_LINK',
        SUCCESSFULLY_ADD: 'CLIENT.SUCCESSFULLY_ADD',
        SUCCESSFULLY_UPDATE: 'CLIENT.SUCCESSFULLY_UPDATE',
        SUCCESSFUL_IMPORT: 'CLIENT.SUCCESSFUL_IMPORT',
        UNPAUSE: 'CLIENT.UNPAUSE',
        UNPAUSE_MULTIPLE: 'CLIENT.UNPAUSE_MULTIPLE',
        YOUR_CLIENTS: 'CLIENT.YOUR_CLIENTS',
    },
    CLIENTS: 'CLIENTS',
    CLIENT_BALANCE: 'CLIENT_BALANCE',
    CLOSE: 'CLOSE',
    COLUMNS: 'COLUMNS',
    COLUMN_NAME: 'COLUMN_NAME',
    COLUMN_TYPE: {
        ACTIONS: 'COLUMN_TYPE.ACTIONS',
        BOOLEAN: 'COLUMN_TYPE.BOOLEAN',
        DATE: 'COLUMN_TYPE.DATE',
        NUMBER: 'COLUMN_TYPE.NUMBER',
        SELECT: 'COLUMN_TYPE.SELECT',
        STRING: 'COLUMN_TYPE.STRING',
    },
    COMMENT: 'COMMENT',
    COMMENTS: 'COMMENTS',
    COMMON: {
        BANK_ACCOUNT: 'COMMON.BANK_ACCOUNT',
        CLIENT: 'COMMON.CLIENT',
        DASHBOARD: 'COMMON.DASHBOARD',
        INVOICE: 'COMMON.INVOICE',
        INVOICING: 'COMMON.INVOICING',
        TASK: 'COMMON.TASK',
        TEMPLATE: 'COMMON.TEMPLATE',
    },
    COMMUNICATION: 'COMMUNICATION',
    COMPANY_LOCALE: 'COMPANY_LOCALE',
    COMPANY_NAME: 'COMPANY_NAME',
    COMPLETED: 'COMPLETED',
    CONFIRM: 'CONFIRM',
    CONFIRMATION: 'CONFIRMATION',
    CONGRATULATION: 'CONGRATULATION',
    CONTACT: 'CONTACT',
    CONTACT_PERSON: {
        ADD: 'CONTACT_PERSON.ADD',
        EDIT: 'CONTACT_PERSON.EDIT',
        MAIN_CONTACT: 'CONTACT_PERSON.MAIN_CONTACT',
        NOTIFICATIONS: {
            FORMAL_NOTICE: 'CONTACT_PERSON.NOTIFICATIONS.FORMAL_NOTICE',
            INVOICE_SENDING: 'CONTACT_PERSON.NOTIFICATIONS.INVOICE_SENDING',
            LEGAL_CASE: 'CONTACT_PERSON.NOTIFICATIONS.LEGAL_CASE',
            REMINDERS: 'CONTACT_PERSON.NOTIFICATIONS.REMINDERS',
        },
        TITLE: 'CONTACT_PERSON.TITLE',
        USE_POSTAL_ADDRESS: 'CONTACT_PERSON.USE_POSTAL_ADDRESS',
    },
    CONTINUE: 'CONTINUE',
    CONTINUE_ANYWAY: 'CONTINUE_ANYWAY',
    COPIED_LINK: 'COPIED_LINK',
    COPY: 'COPY',
    CREATION: 'CREATION',
    CREATION_DATE: 'CREATION_DATE',
    CREDIT: 'CREDIT',
    CREDIT_CARD: 'CREDIT_CARD',
    CREDIT_NOTE: 'CREDIT_NOTE',
    CREDIT_NOTE_LINKED: 'CREDIT_NOTE_LINKED',
    CREDIT_NOTE_SHORT: 'CREDIT_NOTE_SHORT',
    CURRENCY: 'CURRENCY',
    CUSTOMIZATION_INVOICE_FOOTER: 'CUSTOMIZATION_INVOICE_FOOTER',
    CUSTOM_DOMAIN: {
        AUTHENTICATE: 'CUSTOM_DOMAIN.AUTHENTICATE',
        DEFAULT_FROM: 'CUSTOM_DOMAIN.DEFAULT_FROM',
        DNS_INFO: 'CUSTOM_DOMAIN.DNS_INFO',
        DNS_SETTINGS: 'CUSTOM_DOMAIN.DNS_SETTINGS',
        NAME: 'CUSTOM_DOMAIN.NAME',
        NOT_YET_VERIFIED: 'CUSTOM_DOMAIN.NOT_YET_VERIFIED',
        NO_DOMAIN: 'CUSTOM_DOMAIN.NO_DOMAIN',
        NO_MATCH: 'CUSTOM_DOMAIN.NO_MATCH',
        SECTION_TITLE: 'CUSTOM_DOMAIN.SECTION_TITLE',
        VALIDATE_DOMAIN: 'CUSTOM_DOMAIN.VALIDATE_DOMAIN',
        VERIFY: 'CUSTOM_DOMAIN.VERIFY',
    },
    CUSTOM_FIELD: 'CUSTOM_FIELD',
    DASHBOARD: {
        ACTIVE_INTEGRATION: 'DASHBOARD.ACTIVE_INTEGRATION',
        BALANCE_DETAIL: 'DASHBOARD.BALANCE_DETAIL',
        BANK_STATUS: {
            ACCOUNT_BALANCE: 'DASHBOARD.BANK_STATUS.ACCOUNT_BALANCE',
            ALL_ACCOUNTS_BALANCE: 'DASHBOARD.BANK_STATUS.ALL_ACCOUNTS_BALANCE',
            MONTH_FORECAST: 'DASHBOARD.BANK_STATUS.MONTH_FORECAST',
            TITLE: 'DASHBOARD.BANK_STATUS.TITLE',
            WEEK_FORECAST: 'DASHBOARD.BANK_STATUS.WEEK_FORECAST',
        },
        CUSTOMER_RECEIVABLES: {
            TITLE: 'DASHBOARD.CUSTOMER_RECEIVABLES.TITLE',
            TOTAL_COMMITTED: 'DASHBOARD.CUSTOMER_RECEIVABLES.TOTAL_COMMITTED',
        },
        DELAY_GRAPHIC: {
            SUBTITLE: 'DASHBOARD.DELAY_GRAPHIC.SUBTITLE',
            TITLE: 'DASHBOARD.DELAY_GRAPHIC.TITLE',
        },
        ONGOING_TASKS_COUNT: 'DASHBOARD.ONGOING_TASKS_COUNT',
        ONGOING_TASK_COUNT: 'DASHBOARD.ONGOING_TASK_COUNT',
        OUTSTANDINGS_BASED_ON: 'DASHBOARD.OUTSTANDINGS_BASED_ON',
        SELECT_ACCOUNT_MANAGER: 'DASHBOARD.SELECT_ACCOUNT_MANAGER',
        UNPROCESSED_EMAILS_COUNT: 'DASHBOARD.UNPROCESSED_EMAILS_COUNT',
        UNREAD_THIRD_PARTY_MESSAGES_COUNT: 'DASHBOARD.UNREAD_THIRD_PARTY_MESSAGES_COUNT',
        UNPROCESSED_EMAIL_COUNT: 'DASHBOARD.UNPROCESSED_EMAIL_COUNT',
    },
    DASHBOARD_OPTIONS: 'DASHBOARD_OPTIONS',
    DATE: 'DATE',
    DATES: {
        DAY_NAMES: {
            MONDAY: 'DATES.DAY_NAMES.MONDAY',
            TUESDAY: 'DATES.DAY_NAMES.TUESDAY',
            WEDNESDAY: 'DATES.DAY_NAMES.WEDNESDAY',
            THURSDAY: 'DATES.DAY_NAMES.THURSDAY',
            FRIDAY: 'DATES.DAY_NAMES.FRIDAY',
            SATURDAY: 'DATES.DAY_NAMES.SATURDAY',
            SUNDAY: 'DATES.DAY_NAMES.SUNDAY',
        },
        PERIOD_NAMES: {
            MONTHLY: 'DATES.PERIOD_NAMES.MONTHLY',
            WEEKLY: 'DATES.PERIOD_NAMES.WEEKLY',
        },
    },
    DAY: 'DAY',
    DAYS: 'DAYS',
    DAY_S: 'DAY_S',
    DEACTIVATED: 'DEACTIVATED',
    DEBIT: 'DEBIT',
    DEBTOR: {
        AMOUNT_LINKED: 'DEBTOR.AMOUNT_LINKED',
        CREDIT_NOTE_REFERENCE: 'DEBTOR.CREDIT_NOTE_REFERENCE',
        CURRENCY: 'DEBTOR.CURRENCY',
        DETAILED_VIEW: 'DEBTOR.DETAILED_VIEW',
        DOWNLOAD_LINKED_DOCUMENT: 'DEBTOR.DOWNLOAD_LINKED_DOCUMENT',
        DOWNLOAD_MY: 'DEBTOR.DOWNLOAD_MY',
        INVOICE_REFERENCE: 'DEBTOR.INVOICE_REFERENCE',
        LOCALE: 'DEBTOR.LOCALE',
        MONITORING: {
            CREDIT_LIMIT: 'DEBTOR.MONITORING.CREDIT_LIMIT',
            FULL_REPORT: 'DEBTOR.MONITORING.FULL_REPORT',
            NOT_ENABLED: 'DEBTOR.MONITORING.NOT_ENABLED',
            NOT_EXISTING: 'DEBTOR.MONITORING.NOT_EXISTING',
            NO_VAT: 'DEBTOR.MONITORING.NO_VAT',
            ONGOING: 'DEBTOR.MONITORING.ONGOING',
            SCORE: 'DEBTOR.MONITORING.SCORE',
            TAB_TITLE: 'DEBTOR.MONITORING.TAB_TITLE',
            TO_SETTINGS: 'DEBTOR.MONITORING.TO_SETTINGS',
            UPDATE_DATA: 'DEBTOR.MONITORING.UPDATE_DATA',
            WARNINGS: 'DEBTOR.MONITORING.WARNINGS',
            WARNING_DETAILS: 'DEBTOR.MONITORING.WARNING_DETAILS',
            SUBSCRIBE: 'DEBTOR.MONITORING.SUBSCRIBE',
            SUBSCRIPTION_ACTIVE: 'DEBTOR.MONITORING.SUBSCRIPTION_ACTIVE',
        },
        REACTION: {
            CLIENT_PORTAL_OF: 'DEBTOR.REACTION.CLIENT_PORTAL_OF',
            CREDIT_NOTE_DETAILS: 'DEBTOR.REACTION.CREDIT_NOTE_DETAILS',
            GTC: 'DEBTOR.REACTION.GTC',
            NO_INVOICE_TO_PAY: 'DEBTOR.REACTION.NO_INVOICE_TO_PAY',
            NO_PAID_INVOICE: 'DEBTOR.REACTION.NO_PAID_INVOICE',
            PAY: 'DEBTOR.REACTION.PAY',
            PAYMENT_FAILED: 'DEBTOR.REACTION.PAYMENT_FAILED',
            PAYMENT_QR_CODE: 'DEBTOR.REACTION.PAYMENT_QR_CODE',
            PAYMENT_SUCCEEDED: 'DEBTOR.REACTION.PAYMENT_SUCCEEDED',
            QR_CODE_DESCRIPTION: 'DEBTOR.REACTION.QR_CODE_DESCRIPTION',
            REQUEST_PAYMENT_PLAN: 'DEBTOR.REACTION.REQUEST_PAYMENT_PLAN',
            SELECT_INVOICES: 'DEBTOR.REACTION.SELECT_INVOICES',
        },
        REACT_TO: 'DEBTOR.REACT_TO',
        REFERENCE: 'DEBTOR.REFERENCE',
        REFERENCE_PLACEHOLDER: 'DEBTOR.REFERENCE_PLACEHOLDER',
        SENDING_MEDIUM: 'DEBTOR.SENDING_MEDIUM',
        SENDING_MEDIUMS: 'DEBTOR.SENDING_MEDIUMS',
        SENT_DOCUMENT_REACTION: 'DEBTOR.SENT_DOCUMENT_REACTION',
        SUCCESSFULLY_COMMENT: 'DEBTOR.SUCCESSFULLY_COMMENT',
        SUMMARY: 'DEBTOR.SUMMARY',
    },
    DEBTOR_BALANCE: 'DEBTOR_BALANCE',
    DEBTOR_LOCALE: 'DEBTOR_LOCALE',
    DEBTOR_NAME: 'DEBTOR_NAME',
    DEFAULT: 'DEFAULT',
    DEFAULT_ADDITIONAL_REMINDER: 'DEFAULT_ADDITIONAL_REMINDER',
    DEFAULT_CN_TEMPLATE: 'DEFAULT_CN_TEMPLATE',
    DEFAULT_FORMAL_NOTICE: 'DEFAULT_FORMAL_NOTICE',
    DEFAULT_INVOICE_TEMPLATE: 'DEFAULT_INVOICE_TEMPLATE',
    DELAY: {
        ABOVE_90: 'DELAY.ABOVE_90',
        BETWEEN_30_AND_60: 'DELAY.BETWEEN_30_AND_60',
        BETWEEN_60_AND_90: 'DELAY.BETWEEN_60_AND_90',
        NOT_DUE_YET: 'DELAY.NOT_DUE_YET',
        OVERDUE: 'DELAY.OVERDUE',
        WITHIN_30: 'DELAY.WITHIN_30',
    },
    DELAY_WITH_PREVIOUS_ACTION: 'DELAY_WITH_PREVIOUS_ACTION',
    DELETE: 'DELETE',
    DELETE_ACCOUNT_MANAGER: 'DELETE_ACCOUNT_MANAGER',
    DELETE_ALL: 'DELETE_ALL',
    DELETE_EMPTY_VALUES: 'DELETE_EMPTY_VALUES',
    DELETE_PAYMENT: 'DELETE_PAYMENT',
    DELETE_SELECTION: 'DELETE_SELECTION',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    DESCRIPTION: 'DESCRIPTION',
    DETAILS: 'DETAILS',
    DISPLAY_QR_CODE: 'DISPLAY_QR_CODE',
    DOCUMENTS_LOCALE: 'DOCUMENTS_LOCALE',
    DOCUMENT_CUSTOMIZATION: 'DOCUMENT_CUSTOMIZATION',
    DOWNLOAD: 'DOWNLOAD',
    DOWNLOAD_SENT_LETTER: 'DOWNLOAD_SENT_LETTER',
    DUE_DATE: 'DUE_DATE',
    DUE_DATE_SHORT: 'DUE_DATE_SHORT',
    DUPLICATE: 'DUPLICATE',
    DUPLICATES: 'DUPLICATES',
    EDIT: 'EDIT',
    EDIT_REMINDER: 'EDIT_REMINDER',
    EDIT_SIGNATURE: 'EDIT_SIGNATURE',
    EDIT_TASK: 'EDIT_TASK',
    EDIT_TASK_TYPE: 'EDIT_TASK_TYPE',
    EDIT_TEMPLATE: 'EDIT_TEMPLATE',
    EMAIL: 'EMAIL',
    EMAILS: 'EMAILS',
    EMPTY: 'EMPTY',
    END_OF_PAUSE: 'END_OF_PAUSE',
    ENTERPRISE_PHONE_NUMBER: 'ENTERPRISE_PHONE_NUMBER',
    ERROR: {
        CANNOT_BE_MOVED: 'ERROR.CANNOT_BE_MOVED',
        CHOOSE_FILE_NOT_ACCEPTED: 'ERROR.CHOOSE_FILE_NOT_ACCEPTED',
        CURRENT_PASSWORD_REQUIRED: 'ERROR.CURRENT_PASSWORD_REQUIRED',
        DOC_NOT_AVAILABLE: 'ERROR.DOC_NOT_AVAILABLE',
        ERROR: 'ERROR.ERROR',
        FIELD_NOT_EMPTY: 'ERROR.FIELD_NOT_EMPTY',
        FIELD_REQUIRED: 'ERROR.FIELD_REQUIRED',
        FIELD_VALUE_TOO_BIG: 'ERROR.FIELD_VALUE_TOO_BIG',
        FIELD_VALUE_TOO_SMALL: 'ERROR.FIELD_VALUE_TOO_SMALL',
        HAS_PROBLEM: 'ERROR.HAS_PROBLEM',
        HAVE_BEEN_DISCONNECTED: 'ERROR.HAVE_BEEN_DISCONNECTED',
        HIGHER_THAN_0: 'ERROR.HIGHER_THAN_0',
        IMPORT_ERROR: 'ERROR.IMPORT_ERROR',
        INVALID: 'ERROR.INVALID',
        INVALID_EMAIL: 'ERROR.INVALID_EMAIL',
        INVALID_FILE_FORMAT: 'ERROR.INVALID_FILE_FORMAT',
        INVALID_PASSWORD: 'ERROR.INVALID_PASSWORD',
        INVALID_PHONE: 'ERROR.INVALID_PHONE',
        MAX_25: 'ERROR.MAX_25',
        MAX_LENGTH: 'ERROR.MAX_LENGTH',
        MIN_5: 'ERROR.MIN_5',
        MIN_LENGTH: 'ERROR.MIN_LENGTH',
        MUST_BE_DIFFERENT: 'ERROR.MUST_BE_DIFFERENT',
        MUST_BE_HIGHER: 'ERROR.MUST_BE_HIGHER',
        MUST_BE_LOWER_EQUAL: 'ERROR.MUST_BE_LOWER_EQUAL',
        MUST_BE_NUMBER: 'ERROR.MUST_BE_NUMBER',
        NOT_FULLY_FILLED: 'ERROR.NOT_FULLY_FILLED',
        NO_CUSTOM_VARIABLE: 'ERROR.NO_CUSTOM_VARIABLE',
        NO_INVOICE_IN_PLAN: 'ERROR.NO_INVOICE_IN_PLAN',
        RECONCILATION_ACTIVATED: 'ERROR.RECONCILATION_ACTIVATED',
        REFRESH_PAGE_IF_ERROR_PERSIST: 'ERROR.REFRESH_PAGE_IF_ERROR_PERSIST',
        REPORT_PROBLEM: 'ERROR.REPORT_PROBLEM',
        RESTART: 'ERROR.RESTART',
        SERVER_NOT_RESPONDING: 'ERROR.SERVER_NOT_RESPONDING',
        START_RF: 'ERROR.START_RF',
        SUMMARY: 'ERROR.SUMMARY',
    },
    EXCL_VAT: 'EXCL_VAT',
    EXCL_VAT_PRICE: 'EXCL_VAT_PRICE',
    EXPORT: 'EXPORT',
    EXPORT_CSV: 'EXPORT_CSV',
    EXTERNAL_MAIL: {
        ACTIONS: {
            ASSIGN: 'EXTERNAL_MAIL.ACTIONS.ASSIGN',
            DELETE: 'EXTERNAL_MAIL.ACTIONS.DELETE',
            PROCESS: 'EXTERNAL_MAIL.ACTIONS.PROCESS',
            READ: 'EXTERNAL_MAIL.ACTIONS.READ',
            UNPROCESS: 'EXTERNAL_MAIL.ACTIONS.UNPROCESS',
            UNREAD: 'EXTERNAL_MAIL.ACTIONS.UNREAD',
        },
        ATTACHMENTS: 'EXTERNAL_MAIL.ATTACHMENTS',
    },
    FAILED_ACTIONS: 'FAILED_ACTIONS',
    FILE_NUMBER: 'FILE_NUMBER',
    FILL: 'FILL',
    FILTER: 'FILTER',
    FILTERS: 'FILTERS',
    FINANCIAL_DATA: 'FINANCIAL_DATA',
    FIX_FEE_AMOUNT: 'FIX_FEE_AMOUNT',
    FN_TEMPLATE: 'FN_TEMPLATE',
    FOLLOW_UP: {
        INVOICE_TO_PROCESS: {
            AGENT_CALL_DEBTOR: 'FOLLOW_UP.INVOICE_TO_PROCESS.AGENT_CALL_DEBTOR',
            FORMAL_NOTICE_SEND_BY_MAIL: 'FOLLOW_UP.INVOICE_TO_PROCESS.FORMAL_NOTICE_SEND_BY_MAIL',
            GCOLLECT: {
                ACCEPT: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.ACCEPT',
                APE_CODE_FORMAT: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.APE_CODE_FORMAT',
                COLLECTION_AMOUNT: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_AMOUNT',
                COLLECTION_PERCENTAGE: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.COLLECTION_PERCENTAGE',
                CONFIRM_RATES: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.CONFIRM_RATES',
                INVOICE_AMOUNT: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.INVOICE_AMOUNT',
                SIREN_LENGTH_ERROR: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.SIREN_LENGTH_ERROR',
                VALIDATE_FEES_ERRORS: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_ERRORS',
                VALIDATE_FEES_TEXT: 'FOLLOW_UP.INVOICE_TO_PROCESS.GCOLLECT.VALIDATE_FEES_TEXT',
            },
            LAWYER_SEND_MAIL_TO_DEBTOR: 'FOLLOW_UP.INVOICE_TO_PROCESS.LAWYER_SEND_MAIL_TO_DEBTOR',
            NO_INVOICE_WITH_DELAY: 'FOLLOW_UP.INVOICE_TO_PROCESS.NO_INVOICE_WITH_DELAY',
            NO_MORE_AUTOMATIC_ACTIONS: 'FOLLOW_UP.INVOICE_TO_PROCESS.NO_MORE_AUTOMATIC_ACTIONS',
            ORIGINAL_INVOICE_REQUIRED: 'FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED',
            SEND_FORMAL_NOTICE: 'FOLLOW_UP.INVOICE_TO_PROCESS.SEND_FORMAL_NOTICE',
            SEND_REMINDER: 'FOLLOW_UP.INVOICE_TO_PROCESS.SEND_REMINDER',
            SEND_TO_CALLCENTER: 'FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_CALLCENTER',
            SEND_TO_LAWYER: 'FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_LAWYER',
            SEND_TO_THIRD_PARTY: 'FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_THIRD_PARTY',
            START_BAILIFF: 'FOLLOW_UP.INVOICE_TO_PROCESS.START_BAILIFF',
            START_BAILIFF_CASE: 'FOLLOW_UP.INVOICE_TO_PROCESS.START_BAILIFF_CASE',
            TAKE_OUT_OF_PROCESS: 'FOLLOW_UP.INVOICE_TO_PROCESS.TAKE_OUT_OF_PROCESS',
            THIRD_PARTIES: 'FOLLOW_UP.INVOICE_TO_PROCESS.THIRD_PARTIES',
        },
        SUMMARY: {
            COLLECTION_STEP: {
                '0': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.0',
                '1': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.1',
                '2': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.2',
                '3': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.3',
                '4': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.4',
                '5': 'FOLLOW_UP.SUMMARY.COLLECTION_STEP.5',
            },
            CONFLICT: 'FOLLOW_UP.SUMMARY.CONFLICT',
            DELAY_J: 'FOLLOW_UP.SUMMARY.DELAY_J',
            GREAT_NO_INVOICE: 'FOLLOW_UP.SUMMARY.GREAT_NO_INVOICE',
            TITLE: 'FOLLOW_UP.SUMMARY.TITLE',
        },
    },
    FORM: {
        ACTIONS: 'FORM.ACTIONS',
        ADD_END_DATE: 'FORM.ADD_END_DATE',
        ADDITIONAL_REMINDER: {
            CONFIRM_SENDING_OPTIONS: 'FORM.ADDITIONAL_REMINDER.CONFIRM_SENDING_OPTIONS',
            SUMMARY: 'FORM.ADDITIONAL_REMINDER.SUMMARY',
        },
        ADDRESS: {
            ADD: 'FORM.ADDRESS.ADD',
            BOX: 'FORM.ADDRESS.BOX',
            CITY: 'FORM.ADDRESS.CITY',
            COUNTRY: 'FORM.ADDRESS.COUNTRY',
            POSTAL_CODE: 'FORM.ADDRESS.POSTAL_CODE',
            STREET: 'FORM.ADDRESS.STREET',
        },
        ADD_COMMENT: 'FORM.ADD_COMMENT',
        AUTOMATIC_ASSIGNATION: {
            EDIT: 'FORM.AUTOMATIC_ASSIGNATION.EDIT',
            NEW: 'FORM.AUTOMATIC_ASSIGNATION.NEW',
        },
        CATEGORY: 'FORM.CATEGORY',
        CHOICE: 'FORM.CHOICE',
        CHOOSE_DATE: 'FORM.CHOOSE_DATE',
        CHOOSE_FILE: 'FORM.CHOOSE_FILE',
        CHOOSE_INVOICES: {
            ADD_FEES: 'FORM.CHOOSE_INVOICES.ADD_FEES',
            AMOUNT_CLAIMED: 'FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED',
            BAILIFF_DESCRIPTION: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
            BAILIFF_TITLE: 'FORM.CHOOSE_INVOICES.BAILIFF_TITLE',
            DELETE_FEES: 'FORM.CHOOSE_INVOICES.DELETE_FEES',
            DESCRIPTION: 'FORM.CHOOSE_INVOICES.DESCRIPTION',
            LATE_FEES: 'FORM.CHOOSE_INVOICES.LATE_FEES',
            LATE_FEE_DISCLAIMER: 'FORM.CHOOSE_INVOICES.LATE_FEE_DISCLAIMER',
            MAIN_INVOICE: 'FORM.CHOOSE_INVOICES.MAIN_INVOICE',
            NO_LATE_FEE_CLAIMED: 'FORM.CHOOSE_INVOICES.NO_LATE_FEE_CLAIMED',
            REMAINING_AMOUNT: 'FORM.CHOOSE_INVOICES.REMAINING_AMOUNT',
            TITLE: 'FORM.CHOOSE_INVOICES.TITLE',
            UPDATE_FEES: 'FORM.CHOOSE_INVOICES.UPDATE_FEES',
        },
        CHOOSE_MEDIA: 'FORM.CHOOSE_MEDIA',
        CHOOSE_THIRD_PARTY_TYPE: {
            DESCRIPTION: 'FORM.CHOOSE_THIRD_PARTY_TYPE.DESCRIPTION',
            TITLE: 'FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE',
        },
        CLIENT: {
            ADD_BANK_ACCOUNT: 'FORM.CLIENT.ADD_BANK_ACCOUNT',
            ADD_PHONE: 'FORM.CLIENT.ADD_PHONE',
            CALLCENTER_PHONE_REQUIRED: 'FORM.CLIENT.CALLCENTER_PHONE_REQUIRED',
            COCONTRACTOR_VAT0: 'FORM.CLIENT.COCONTRACTOR_VAT0',
            CONTACT_LANG: 'FORM.CLIENT.CONTACT_LANG',
            CURRENCY: 'FORM.CLIENT.CURRENCY',
            EDIT_ALL_INVOICES_ADDRESS: 'FORM.CLIENT.EDIT_ALL_INVOICES_ADDRESS',
            EDIT_ALL_INVOICES_EMAIL: 'FORM.CLIENT.EDIT_ALL_INVOICES_EMAIL',
            EDIT_ALL_INVOICES_SENDING_MEDIUM: 'FORM.CLIENT.EDIT_ALL_INVOICES_SENDING_MEDIUM',
            EDIT_RECORD: 'FORM.CLIENT.EDIT_RECORD',
            EDIT_SENDING_OPTIONS: 'FORM.CLIENT.EDIT_SENDING_OPTIONS',
            EDIT_SENDING_OPTIONS_SUMMARY: 'FORM.CLIENT.EDIT_SENDING_OPTIONS_SUMMARY',
            FULL_NAME: 'FORM.CLIENT.FULL_NAME',
            INTRA_COMMUNITY_VAT0: 'FORM.CLIENT.INTRA_COMMUNITY_VAT0',
            LEGAL_ENTITY: 'FORM.CLIENT.LEGAL_ENTITY',
            PRE_FILL: 'FORM.CLIENT.PRE_FILL',
            SELECT_RADIO_BUTTON: 'FORM.CLIENT.SELECT_RADIO_BUTTON',
        },
        CLIENT_IMPORT: {
            CSV_FILE_FORMAT: 'FORM.CLIENT_IMPORT.CSV_FILE_FORMAT',
            IMPORT_CLIENTS_LIST: 'FORM.CLIENT_IMPORT.IMPORT_CLIENTS_LIST',
            TITLE: 'FORM.CLIENT_IMPORT.TITLE',
        },
        COMPANY: {
            CURRENCY: 'FORM.COMPANY.CURRENCY',
            CURRENCY_CONVERSION_DATE: 'FORM.COMPANY.CURRENCY_CONVERSION_DATE',
            DEFAULT_ACCOUNT: 'FORM.COMPANY.DEFAULT_ACCOUNT',
            LANG: 'FORM.COMPANY.LANG',
            LEGAL_NAME: 'FORM.COMPANY.LEGAL_NAME',
        },
        CONFIRM_BAILIFF: {
            AYS_SEND_CONTESTED_INVOICE: 'FORM.CONFIRM_BAILIFF.AYS_SEND_CONTESTED_INVOICE',
            BAILIFF_CHOICE: 'FORM.CONFIRM_BAILIFF.BAILIFF_CHOICE',
            CHECK_INCONTESTED: 'FORM.CONFIRM_BAILIFF.CHECK_INCONTESTED',
            DESCRIPTION: 'FORM.CONFIRM_BAILIFF.DESCRIPTION',
            INTERMEDIANCE: 'FORM.CONFIRM_BAILIFF.INTERMEDIANCE',
            INVOICE_UNCONTESTABLE: 'FORM.CONFIRM_BAILIFF.INVOICE_UNCONTESTABLE',
            IS_INVOICE_CONTESTABLE: 'FORM.CONFIRM_BAILIFF.IS_INVOICE_CONTESTABLE',
            MODERO: 'FORM.CONFIRM_BAILIFF.MODERO',
            READ_AND_APPROVE: 'FORM.CONFIRM_BAILIFF.READ_AND_APPROVE',
        },
        CONFIRM_EMAIL: 'FORM.CONFIRM_EMAIL',
        CONFIRM_PASSWORD: 'FORM.CONFIRM_PASSWORD',
        CONFIRM_VALUE: 'FORM.CONFIRM_VALUE',
        CUSTOM_VARIABLES: {
            TITLE: {
                EDIT: 'FORM.CUSTOM_VARIABLES.TITLE.EDIT',
                NEW: 'FORM.CUSTOM_VARIABLES.TITLE.NEW',
            },
        },
        DAY_OF_DELAY: 'FORM.DAY_OF_DELAY',
        DBASICS: {
            IRREVERSIBLE_ACTION: 'FORM.DBASICS.IRREVERSIBLE_ACTION',
            SELECT_COMPANY: 'FORM.DBASICS.SELECT_COMPANY',
            SUMMARY: 'FORM.DBASICS.SUMMARY',
        },
        DEBT_COLLECTOR_TITLE: 'FORM.DEBT_COLLECTOR_TITLE',
        DELETE_FILTERS: 'FORM.DELETE_FILTERS',
        DESELECT_ALL: 'FORM.DESELECT_ALL',
        EDIT_STAFF: {
            TITLE: 'FORM.EDIT_STAFF.TITLE',
        },
        END_DATE: 'FORM.END_DATE',
        EXACT: {
            IRREVERSIBLE_ACTION: 'FORM.EXACT.IRREVERSIBLE_ACTION',
            SELECT_COMPANY: 'FORM.EXACT.SELECT_COMPANY',
            SUMMARY: 'FORM.EXACT.SUMMARY',
        },
        EXPORT_RECOVR_PAYMENTS: 'FORM.EXPORT_RECOVR_PAYMENTS',
        FILE_FORMAT_PDF: 'FORM.FILE_FORMAT_PDF',
        FIRSTNAME: 'FORM.FIRSTNAME',
        FORMAL_NOTICE: {
            CAN_EDIT: 'FORM.FORMAL_NOTICE.CAN_EDIT',
            CONFIRM: 'FORM.FORMAL_NOTICE.CONFIRM',
            EMAIL_TO_SEND: 'FORM.FORMAL_NOTICE.EMAIL_TO_SEND',
            SEND_BY_MAIL_TOO: 'FORM.FORMAL_NOTICE.SEND_BY_MAIL_TOO',
            SHIPPING_ADDRESS: 'FORM.FORMAL_NOTICE.SHIPPING_ADDRESS',
            SIGNATORY_NAME: 'FORM.FORMAL_NOTICE.SIGNATORY_NAME',
        },
        FROM: 'FORM.FROM',
        GENERAL_CONDITIONS_OF_USE: 'FORM.GENERAL_CONDITIONS_OF_USE',
        HISTORY: {
            PARTIALLY_MATCHED_WITH: 'FORM.HISTORY.PARTIALLY_MATCHED_WITH',
            PAYMENT_HISTORY: 'FORM.HISTORY.PAYMENT_HISTORY',
            PAYMENT_NO_RECONCILE: 'FORM.HISTORY.PAYMENT_NO_RECONCILE',
            RECONCILE_WITH: 'FORM.HISTORY.RECONCILE_WITH',
            SENDER: 'FORM.HISTORY.SENDER',
            TRANSACTION_NUMBER: 'FORM.HISTORY.TRANSACTION_NUMBER',
        },
        HORUS: {
            IRREVERSIBLE_ACTION: 'FORM.HORUS.IRREVERSIBLE_ACTION',
            SELECT_COMPANY: 'FORM.HORUS.SELECT_COMPANY',
            SUMMARY: 'FORM.HORUS.SUMMARY',
        },
        IMPORT_CLIENT_CONDITIONS: 'FORM.IMPORT_CLIENT_CONDITIONS',
        INTEGRATION: {
            AUTOMATIC_SYNC: 'FORM.INTEGRATION.AUTOMATIC_SYNC',
            AYS_TO_CHANGE_DATE: 'FORM.INTEGRATION.AYS_TO_CHANGE_DATE',
            CONNECT_ACCOUNT: 'FORM.INTEGRATION.CONNECT_ACCOUNT',
            EXPORT: 'FORM.INTEGRATION.EXPORT',
            IMPORT: 'FORM.INTEGRATION.IMPORT',
            INVOICE_ISSUE_DATE_TO_IMPORT: 'FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT',
            LAST_IMPORT_THE: 'FORM.INTEGRATION.LAST_IMPORT_THE',
            MANUAL_SYNC_DESCRIPTION: 'FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION',
            NEXT_IMPORT_THE: 'FORM.INTEGRATION.NEXT_IMPORT_THE',
            PRIORITIZE_INTEGRATION: 'FORM.INTEGRATION.PRIORITIZE_INTEGRATION',
            PRIORITIZE_RECOVR: 'FORM.INTEGRATION.PRIORITIZE_RECOVR',
            RESET_ACCOUNTING_INTEGRATION: 'FORM.INTEGRATION.RESET_ACCOUNTING_INTEGRATION',
        },
        INTEREST_RATE: 'FORM.INTEREST_RATE',
        INVITE_STAFF: {
            RESEND_INVITATION: 'FORM.INVITE_STAFF.RESEND_INVITATION',
            TITLE: 'FORM.INVITE_STAFF.TITLE',
        },
        INVOICE_REFERENCE: 'FORM.INVOICE_REFERENCE',
        INVOICING: {
            ADD_EMAIL: 'FORM.INVOICING.ADD_EMAIL',
            ADD_NEW_BOOK: 'FORM.INVOICING.ADD_NEW_BOOK',
            CURRENT_COUNTER: 'FORM.INVOICING.CURRENT_COUNTER',
            CUSTOM_INVOICE: 'FORM.INVOICING.CUSTOM_INVOICE',
            FILE_FORMAT: 'FORM.INVOICING.FILE_FORMAT',
            FORCE_BALANCE_UPDATE: 'FORM.INVOICING.FORCE_BALANCE_UPDATE',
            IMPORT_LOGO: 'FORM.INVOICING.IMPORT_LOGO',
            INVOICING_BOOK: 'FORM.INVOICING.INVOICING_BOOK',
            NEXT_INVOICE_REFRENCE: 'FORM.INVOICING.NEXT_INVOICE_REFRENCE',
            NO_BILLING_LOG: 'FORM.INVOICING.NO_BILLING_LOG',
            PREFIX: 'FORM.INVOICING.PREFIX',
            STRUCTURED_COMMUNICATION: 'FORM.INVOICING.STRUCTURED_COMMUNICATION',
            VAT_COCONTRACTOR: 'FORM.INVOICING.VAT_COCONTRACTOR',
            VAT_INTRACOM: 'FORM.INVOICING.VAT_INTRACOM',
            YOUR_COLOR: 'FORM.INVOICING.YOUR_COLOR',
            YOUR_LOGO: 'FORM.INVOICING.YOUR_LOGO',
        },
        JOB_TITLE: 'FORM.JOB_TITLE',
        LASTNAME: 'FORM.LASTNAME',
        LAWYER_TITLE: 'FORM.LAWYER_TITLE',
        MAX: 'FORM.MAX',
        MIN: 'FORM.MIN',
        MIN_PENALTY_CLAUSE: 'FORM.MIN_PENALTY_CLAUSE',
        NEW_PASSWORD: 'FORM.NEW_PASSWORD',
        NOTIFICATIONS: 'FORM.NOTIFICATIONS',
        NUMBER: 'FORM.NUMBER',
        PAYMENT_DATE: 'FORM.PAYMENT_DATE',
        PAYMENT_DELAY: {
            AUTHORIZED_DELAY: 'FORM.PAYMENT_DELAY.AUTHORIZED_DELAY',
            IMPORT_CLIENT_CONDITIONS: 'FORM.PAYMENT_DELAY.IMPORT_CLIENT_CONDITIONS',
            INTEREST_RATE: 'FORM.PAYMENT_DELAY.INTEREST_RATE',
            READ_CONDITIONS_PDF: 'FORM.PAYMENT_DELAY.READ_CONDITIONS_PDF',
        },
        PAYMENT_PLAN: {
            ADD_PAYMENT_STEP: 'FORM.PAYMENT_PLAN.ADD_PAYMENT_STEP',
            EDIT: {
                TITLE: 'FORM.PAYMENT_PLAN.EDIT.TITLE',
                WARNING: 'FORM.PAYMENT_PLAN.EDIT.WARNING',
            },
            NEW: {
                TITLE: 'FORM.PAYMENT_PLAN.NEW.TITLE',
                WARNING: 'FORM.PAYMENT_PLAN.NEW.WARNING',
            },
            PROPERTIES: {
                ADDED_INVOICES: 'FORM.PAYMENT_PLAN.PROPERTIES.ADDED_INVOICES',
                NEW_BALANCE: 'FORM.PAYMENT_PLAN.PROPERTIES.NEW_BALANCE',
                OLD_BALANCE: 'FORM.PAYMENT_PLAN.PROPERTIES.OLD_BALANCE',
            },
            STEP_CALCULATION: {
                ADD_NEW_STEPS: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.ADD_NEW_STEPS',
                ADJUST_NEXT_AMOUNTS: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_AMOUNTS',
                ADJUST_NEXT_DATES: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_DATES',
                CALCULATE: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.CALCULATE',
                DEADLINE_AMOUNT: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT',
                DEADLINE_COUNT: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT',
                DELAY: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY',
                DUE_DATE: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.DUE_DATE',
                RECALCULATE: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.RECALCULATE',
                SPREAD_TO_EXISTING: 'FORM.PAYMENT_PLAN.STEP_CALCULATION.SPREAD_TO_EXISTING',
            },
            USED_WORKFLOW: 'FORM.PAYMENT_PLAN.USED_WORKFLOW',
        },
        PENALTY_CLAUSE: 'FORM.PENALTY_CLAUSE',
        PREFERENCES: {
            ALLOW_PAYMENT_PLANS: 'FORM.PREFERENCES.ALLOW_PAYMENT_PLANS',
            ALLOW_PAYMENT_PLANS_DEBTOR: 'FORM.PREFERENCES.ALLOW_PAYMENT_PLANS_DEBTOR',
            AMOUNT_EURO: 'FORM.PREFERENCES.AMOUNT_EURO',
            ANNUAL_INTEREST_RATE: 'FORM.PREFERENCES.ANNUAL_INTEREST_RATE',
            AUTOMATIC: 'FORM.PREFERENCES.AUTOMATIC',
            AYS_AUTOMATIC_REMINDER_MODE: 'FORM.PREFERENCES.AYS_AUTOMATIC_REMINDER_MODE',
            AYS_MANUAL_REMINDER_MODE: 'FORM.PREFERENCES.AYS_MANUAL_REMINDER_MODE',
            CLAIM_THE_ADDITIONAL_COSTS: 'FORM.PREFERENCES.CLAIM_THE_ADDITIONAL_COSTS',
            CLAUSE_TYPE: 'FORM.PREFERENCES.CLAUSE_TYPE',
            COLOR: 'FORM.PREFERENCES.COLOR',
            CUSTOM_RECOVERY: 'FORM.PREFERENCES.CUSTOM_RECOVERY',
            DAILY_SUMMARY: 'FORM.PREFERENCES.DAILY_SUMMARY',
            DAYS_AFTER_DEADLINE: 'FORM.PREFERENCES.DAYS_AFTER_DEADLINE',
            DAYS_BETWEEN_REMINDERS: 'FORM.PREFERENCES.DAYS_BETWEEN_REMINDERS',
            DEFAULT_PAYMENT_DELAY: 'FORM.PREFERENCES.DEFAULT_PAYMENT_DELAY',
            DONT_SEND_REMINDER_TO_DEBTOR_UNDER: 'FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER',
            DONT_SEND_REMINDER_UNDER: 'FORM.PREFERENCES.DONT_SEND_REMINDER_UNDER',
            FIX_FEE: 'FORM.PREFERENCES.FIX_FEE',
            HOW_SEND_REMINDERS: 'FORM.PREFERENCES.HOW_SEND_REMINDERS',
            IMPORT_GENERAL_CONDITIONS: 'FORM.PREFERENCES.IMPORT_GENERAL_CONDITIONS',
            INTEREST: 'FORM.PREFERENCES.INTEREST',
            MANUAL: 'FORM.PREFERENCES.MANUAL',
            MARK_PAID_ON_NO_BALANCE: 'FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE',
            MARK_PAID_ON_NO_BALANCE_TOOLTIP: 'FORM.PREFERENCES.MARK_PAID_ON_NO_BALANCE_TOOLTIP',
            MIN_DAYS_BETWEEN_REMINDER: 'FORM.PREFERENCES.MIN_DAYS_BETWEEN_REMINDER',
            NEW_REACTION_DEBTOR: 'FORM.PREFERENCES.NEW_REACTION_DEBTOR',
            NEW_THIRD_PARTY_MESSAGE: 'FORM.PREFERENCES.NEW_THIRD_PARTY_MESSAGE',
            NEXT_WORKING_DAY: 'FORM.PREFERENCES.NEXT_WORKING_DAY',
            NEXT_WORKING_DAYS: 'FORM.PREFERENCES.NEXT_WORKING_DAYS',
            NOTIF_BY_EMAIL: 'FORM.PREFERENCES.NOTIF_BY_EMAIL',
            OVERDUE_BALANCE: 'FORM.PREFERENCES.OVERDUE_BALANCE',
            PENALTY_CLAUSE_MIN: 'FORM.PREFERENCES.PENALTY_CLAUSE_MIN',
            PENALTY_CLAUSE_VALUE: 'FORM.PREFERENCES.PENALTY_CLAUSE_VALUE',
            PERIODICITY: 'FORM.PREFERENCES.PERIODICITY',
            PREFERENCES_POSTAL_SEND: 'FORM.PREFERENCES.PREFERENCES_POSTAL_SEND',
            PREFERENCES_SEND: 'FORM.PREFERENCES.PREFERENCES_SEND',
            PRINTING: 'FORM.PREFERENCES.PRINTING',
            READ_CONDITIONS: 'FORM.PREFERENCES.READ_CONDITIONS',
            RECEIVE_COPY: 'FORM.PREFERENCES.RECEIVE_COPY',
            RECEIVE_ON: 'FORM.PREFERENCES.RECEIVE_ON',
            RECONCILIATION: 'FORM.PREFERENCES.RECONCILIATION',
            REMAINING_BALANCE: 'FORM.PREFERENCES.REMAINING_BALANCE',
            REMINDERS: 'FORM.PREFERENCES.REMINDERS',
            REMINDER_MANAGEMENT: 'FORM.PREFERENCES.REMINDER_MANAGEMENT',
            SEND_AUTOMATIC_REMINDERS: 'FORM.PREFERENCES.SEND_AUTOMATIC_REMINDERS',
            SEND_CONDITION_EACH_INVOICE: 'FORM.PREFERENCES.SEND_CONDITION_EACH_INVOICE',
            SEND_TO_ALL_EMAILS: 'FORM.PREFERENCES.SEND_TO_ALL_EMAILS',
            SUCCESSFULL_CG_UPDATE: 'FORM.PREFERENCES.SUCCESSFULL_CG_UPDATE',
            SUCCESSFULL_LOGO_UPDATE: 'FORM.PREFERENCES.SUCCESSFULL_LOGO_UPDATE',
            THRESHOLD: 'FORM.PREFERENCES.THRESHOLD',
            TOOLTIP: {
                ALLOW_PAYMENT_PLANS: 'FORM.PREFERENCES.TOOLTIP.ALLOW_PAYMENT_PLANS',
                CLAIM_THE_ADDITIONAL_COSTS: 'FORM.PREFERENCES.TOOLTIP.CLAIM_THE_ADDITIONAL_COSTS',
                DONT_SEND_REMINDER_UNDER: 'FORM.PREFERENCES.TOOLTIP.DONT_SEND_REMINDER_UNDER',
                HOW_SEND_REMINDERS: 'FORM.PREFERENCES.TOOLTIP.HOW_SEND_REMINDERS',
                RECEIVE_COPY: 'FORM.PREFERENCES.TOOLTIP.RECEIVE_COPY',
                SEND_CONDITION_EACH_INVOICE: 'FORM.PREFERENCES.TOOLTIP.SEND_CONDITION_EACH_INVOICE',
                SEND_TO_ALL_EMAILS: 'FORM.PREFERENCES.TOOLTIP.SEND_TO_ALL_EMAILS',
            },
            WEEKLY_SUMMARY: 'FORM.PREFERENCES.WEEKLY_SUMMARY',
        },
        PRODUCT: {
            EDIT: 'FORM.PRODUCT.EDIT',
            NAME: 'FORM.PRODUCT.NAME',
        },
        QUICK_SEARCH: {
            PLACEHOLDER: 'FORM.QUICK_SEARCH.PLACEHOLDER',
            TOOLTIP: {
                DEBTOR: 'FORM.QUICK_SEARCH.TOOLTIP.DEBTOR',
                INVOICE: 'FORM.QUICK_SEARCH.TOOLTIP.INVOICE',
                TASK: 'FORM.QUICK_SEARCH.TOOLTIP.TASK',
            },
        },
        QUIT_WITHOUT_SAVING: 'FORM.QUIT_WITHOUT_SAVING',
        READ_CONDITIONS_PDF: 'FORM.READ_CONDITIONS_PDF',
        REASON: 'FORM.REASON',
        RECOVER_STEP: {
            TYPE: {
                CREATE_TASK: 'FORM.RECOVER_STEP.TYPE.CREATE_TASK',
                SEND_REMINDER: 'FORM.RECOVER_STEP.TYPE.SEND_REMINDER',
            },
            TYPE_LABEL: 'FORM.RECOVER_STEP.TYPE_LABEL',
        },
        REFERENCE: 'FORM.REFERENCE',
        RESEARCH: 'FORM.RESEARCH',
        SAVE_AND_QUIT: 'FORM.SAVE_AND_QUIT',
        SEARCH: 'FORM.SEARCH',
        SELECT_ALL: 'FORM.SELECT_ALL',
        SELECT_AT_LEAST_ONE: 'FORM.SELECT_AT_LEAST_ONE',
        SELECT_DATE: 'FORM.SELECT_DATE',
        SEND_INVOICE: {
            CAN_VALID_WITHOUT_SEND: 'FORM.SEND_INVOICE.CAN_VALID_WITHOUT_SEND',
            DESCRIPTION: 'FORM.SEND_INVOICE.DESCRIPTION',
            FORCE_SENDING_MEDIUM: 'FORM.SEND_INVOICE.FORCE_SENDING_MEDIUM',
            FRONT_OR_BACK: 'FORM.SEND_INVOICE.FRONT_OR_BACK',
            SELECT_SEND_METHOD: 'FORM.SEND_INVOICE.SELECT_SEND_METHOD',
            SENDING_MEDIUM_REQUIRED: 'FORM.SEND_INVOICE.SENDING_MEDIUM_REQUIRED',
            SEND_ADDITIONAL_FILE: 'FORM.SEND_INVOICE.SEND_ADDITIONAL_FILE',
            SEND_ADDITIONAL_FILES: 'FORM.SEND_INVOICE.SEND_ADDITIONAL_FILES',
            SEND_BY_EMAIL: 'FORM.SEND_INVOICE.SEND_BY_EMAIL',
            SEND_BY_MAIL: 'FORM.SEND_INVOICE.SEND_BY_MAIL',
            SEND_REMINDERS: 'FORM.SEND_INVOICE.SEND_REMINDERS',
            TITLE: 'FORM.SEND_INVOICE.TITLE',
            VALID_WITHOUT_SEND: 'FORM.SEND_INVOICE.VALID_WITHOUT_SEND',
        },
        SIDE_MENU: {
            LOST_INFO: 'FORM.SIDE_MENU.LOST_INFO',
        },
        SIGNUP: {
            CONFIRM_PASSWORD: 'FORM.SIGNUP.CONFIRM_PASSWORD',
        },
        START_DATE: 'FORM.START_DATE',
        STRIPE: {
            ADD_PAYMENT_METHOD: 'FORM.STRIPE.ADD_PAYMENT_METHOD',
            CARD_NUMBER: 'FORM.STRIPE.CARD_NUMBER',
            CVC: 'FORM.STRIPE.CVC',
            EXPIRATION: 'FORM.STRIPE.EXPIRATION',
            FULL_NAME: 'FORM.STRIPE.FULL_NAME',
        },
        SUCCESSFULLY_CONNEXION: 'FORM.SUCCESSFULLY_CONNEXION',
        TASK: {
            EDIT: 'FORM.TASK.EDIT',
            GROUP_SAME_DEBTOR: 'FORM.TASK.GROUP_SAME_DEBTOR',
            NEW: 'FORM.TASK.NEW',
        },
        TEAMLEADER: {
            DESCRIPTION: 'FORM.TEAMLEADER.DESCRIPTION',
            SUMMARY: 'FORM.TEAMLEADER.SUMMARY',
        },
        THIRD_PARTY_CASES_FILTERS: {
            UNREAD_MESSAGES: 'FORM.THIRD_PARTY_CASES_FILTERS.UNREAD_MESSAGES',
        },
        TO: 'FORM.TO',
        TYPE: 'FORM.TYPE',
        USER_SETTINGS: {
            INTERFACE_LANG: 'FORM.USER_SETTINGS.INTERFACE_LANG',
            NEW_PASSWORD: 'FORM.USER_SETTINGS.NEW_PASSWORD',
            OLD_PASSWORD: 'FORM.USER_SETTINGS.OLD_PASSWORD',
            PAGINATION: 'FORM.USER_SETTINGS.PAGINATION',
            PREFERENCES: 'FORM.USER_SETTINGS.PREFERENCES',
        },
        UNTREATED: 'FORM.UNTREATED',
        USE_PAYMENT_MATCHING: 'FORM.USE_PAYMENT_MATCHING',
        VALIDATE: 'FORM.VALIDATE',
        VALIDATE_AND_SEND: 'FORM.VALIDATE_AND_SEND',
        VAT: 'FORM.VAT',
        VAT_FORMAT: 'FORM.VAT_FORMAT',
        WARNING_IRREVERSIBLE_ACTION: 'FORM.WARNING_IRREVERSIBLE_ACTION',
        YUKI: {
            ADMIN_KEY: 'FORM.YUKI.ADMIN_KEY',
            API_KEY: 'FORM.YUKI.API_KEY',
            BASED_ON: 'FORM.YUKI.BASED_ON',
            CASH_ACCOUNT: 'FORM.YUKI.CASH_ACCOUNT',
            EXPORT_ON_YUKI: 'FORM.YUKI.EXPORT_ON_YUKI',
            IMPORT_ON_RECOVR: 'FORM.YUKI.IMPORT_ON_RECOVR',
            SUMMARY: 'FORM.YUKI.SUMMARY',
        },
    },
    FROM: 'FROM',
    FULL_BILLING_STATEMENT: 'FULL_BILLING_STATEMENT',
    GENERAL_CONDITIONS: 'GENERAL_CONDITIONS',
    HEADER: {
        USER_ACTIONS: {
            EDIT_PROFILE: 'HEADER.USER_ACTIONS.EDIT_PROFILE',
            LOGOUT: 'HEADER.USER_ACTIONS.LOGOUT',
        },
    },
    HIDE_PAID_INVOICES: 'HIDE_PAID_INVOICES',
    IF_NOT: 'IF_NOT',
    IGNORE_LATE_FEES: 'IGNORE_LATE_FEES',
    IMPORT: 'IMPORT',
    IMPORT_CSV: 'IMPORT_CSV',
    IMPORT_EXPORT: 'IMPORT_EXPORT',
    IMPORT_TO_CONFIRM: 'IMPORT_TO_CONFIRM',
    INCLUDE_NDC_PAYMENT: 'INCLUDE_NDC_PAYMENT',
    INCLUDE_ONLY_INVOICE_BALANCE: 'INCLUDE_ONLY_INVOICE_BALANCE',
    INCOMPLETE_ADRESS: 'INCOMPLETE_ADRESS',
    INTEREST_CALCULATION_TYPE: {
        FIX: 'INTEREST_CALCULATION_TYPE.FIX',
        PERCENTAGE: 'INTEREST_CALCULATION_TYPE.PERCENTAGE',
    },
    INVITE: 'INVITE',
    INVOICE: {
        ACTIVITIES: 'INVOICE.ACTIVITIES',
        ADDITIONAL_DOCUMENT: 'INVOICE.ADDITIONAL_DOCUMENT',
        ADD_ADDITIONAL_DOCUMENT: 'INVOICE.ADD_ADDITIONAL_DOCUMENT',
        ADD_PAYMENT: 'INVOICE.ADD_PAYMENT',
        AMOUNT_DUE: 'INVOICE.AMOUNT_DUE',
        AMOUNT_LINKED: 'INVOICE.AMOUNT_LINKED',
        AVERAGE_AUTHORIZED_DELAY: 'INVOICE.AVERAGE_AUTHORIZED_DELAY',
        AYS_TO_DELETE_CREDIT_NOTE: 'INVOICE.AYS_TO_DELETE_CREDIT_NOTE',
        AYS_TO_PAUSE: 'INVOICE.AYS_TO_PAUSE',
        AYS_TO_SEND_TO_CALLCENTER: 'INVOICE.AYS_TO_SEND_TO_CALLCENTER',
        AYS_TO_UNPAUSE: 'INVOICE.AYS_TO_UNPAUSE',
        BAILIFF_CASE: 'INVOICE.BAILIFF_CASE',
        BALANCE_CREDIT_NOTE: 'INVOICE.BALANCE_CREDIT_NOTE',
        BALANCE_FEES: 'INVOICE.BALANCE_FEES',
        BALANCE_INC_FEES: 'INVOICE.BALANCE_INC_FEES',
        CALCULATE_DATE: 'INVOICE.CALCULATE_DATE',
        CALLCENTER_CASE: 'INVOICE.CALLCENTER_CASE',
        CHANGE_STATUS: 'INVOICE.CHANGE_STATUS',
        CLIENT_SUMMARY: 'INVOICE.CLIENT_SUMMARY',
        COMMENT: 'INVOICE.COMMENT',
        CREATE_CREDIT_NOTE: 'INVOICE.CREATE_CREDIT_NOTE',
        CREDIT_NOTE_DETAIL: 'INVOICE.CREDIT_NOTE_DETAIL',
        CREDIT_NOTE_STATUS: 'INVOICE.CREDIT_NOTE_STATUS',
        DAY_OF_DELAY: 'INVOICE.DAY_OF_DELAY',
        DELETE: 'INVOICE.DELETE',
        DELETED: 'INVOICE.DELETED',
        DELETE_MULTIPLE: 'INVOICE.DELETE_MULTIPLE',
        DETAILS: 'INVOICE.DETAILS',
        DISCOUNT: 'INVOICE.DISCOUNT',
        DISPUTED: 'INVOICE.DISPUTED',
        DISPUTED_REASON: 'INVOICE.DISPUTED_REASON',
        DOWNLOAD_CSV: 'INVOICE.DOWNLOAD_CSV',
        DOWNLOAD_PDF: 'INVOICE.DOWNLOAD_PDF',
        DRAFT: 'INVOICE.DRAFT',
        DUE_AMOUNT: 'INVOICE.DUE_AMOUNT',
        DUE_ON: 'INVOICE.DUE_ON',
        EDIT_ORGINAL_FILE: 'INVOICE.EDIT_ORGINAL_FILE',
        EDIT_SENDING_OPTIONS: 'INVOICE.EDIT_SENDING_OPTIONS',
        EDIT_SENDING_OPTIONS_SUMMARY: 'INVOICE.EDIT_SENDING_OPTIONS_SUMMARY',
        ESTIMATED_PAYMENT_DATE: 'INVOICE.ESTIMATED_PAYMENT_DATE',
        EXPORT: 'INVOICE.EXPORT',
        FILES_JOIN: 'INVOICE.FILES_JOIN',
        FILE_JOIN: 'INVOICE.FILE_JOIN',
        IMPORT_JOIN_NEW_DOC: 'INVOICE.IMPORT_JOIN_NEW_DOC',
        IMPORT_NEW_DOC: 'INVOICE.IMPORT_NEW_DOC',
        INTEREST: 'INVOICE.INTEREST',
        INTRACOMMUNITY: 'INVOICE.INTRACOMMUNITY',
        INVOICE_DETAIL: 'INVOICE.INVOICE_DETAIL',
        INVOICE_LOST: 'INVOICE.INVOICE_LOST',
        INVOICE_PRODUCTS: 'INVOICE.INVOICE_PRODUCTS',
        INVOICE_STATUS: 'INVOICE.INVOICE_STATUS',
        IN_PROGRESS: 'INVOICE.IN_PROGRESS',
        ISSUE_ON: 'INVOICE.ISSUE_ON',
        JOIN_FILE: 'INVOICE.JOIN_FILE',
        LATE: 'INVOICE.LATE',
        LATE_ONLY: 'INVOICE.LATE_ONLY',
        LAWYER_CASE: 'INVOICE.LAWYER_CASE',
        LINK_INVOICE: 'INVOICE.LINK_INVOICE',
        LINK_INVOICES: 'INVOICE.LINK_INVOICES',
        LINK_TO_INVOICE: 'INVOICE.LINK_TO_INVOICE',
        LOST_REASON: 'INVOICE.LOST_REASON',
        MARK_AS_DISPUTED: 'INVOICE.MARK_AS_DISPUTED',
        MARK_INVOICE_AS_LOST: 'INVOICE.MARK_INVOICE_AS_LOST',
        MARK_INVOICE_AS_PAID: 'INVOICE.MARK_INVOICE_AS_PAID',
        MAX_DAYS_LATE: 'INVOICE.MAX_DAYS_LATE',
        MIN_DAYS_LATE: 'INVOICE.MIN_DAYS_LATE',
        MOVE_PLAN: 'INVOICE.MOVE_PLAN',
        NEW_COMMENT: 'INVOICE.NEW_COMMENT',
        NEXT_STEP: 'INVOICE.NEXT_STEP',
        NOT_IMPORTED: 'INVOICE.NOT_IMPORTED',
        NO_PAYMENT_METHOD: 'INVOICE.NO_PAYMENT_METHOD',
        OPEN_CASE: 'INVOICE.OPEN_CASE',
        ORIGINAL_FILE: 'INVOICE.ORIGINAL_FILE',
        OTHER_ACTIONS: 'INVOICE.OTHER_ACTIONS',
        PAID: 'INVOICE.PAID',
        PAIDS: 'INVOICE.PAIDS',
        PAUSE_THE_FOLLOW_UP: 'INVOICE.PAUSE_THE_FOLLOW_UP',
        PAYMENT_INFO: 'INVOICE.PAYMENT_INFO',
        PREVIEW: 'INVOICE.PREVIEW',
        PRODUCT: 'INVOICE.PRODUCT',
        QTE: 'INVOICE.QTE',
        RECOVER_ACTIONS: 'INVOICE.RECOVER_ACTIONS',
        RESUME_FOLLUW_UP: 'INVOICE.RESUME_FOLLUW_UP',
        REVENUES: 'INVOICE.REVENUES',
        REVERSE_CHARGE_COCONTRACTOR: 'INVOICE.REVERSE_CHARGE_COCONTRACTOR',
        SELECTED_ONLY: 'INVOICE.SELECTED_ONLY',
        SELECT_ACTION: 'INVOICE.SELECT_ACTION',
        SENDING_OPTIONS: 'INVOICE.SENDING_OPTIONS',
        SENDING_OPTIONS_BATCH_SUMMARY: 'INVOICE.SENDING_OPTIONS_BATCH_SUMMARY',
        SENDING_OPTIONS_SUMMARY: 'INVOICE.SENDING_OPTIONS_SUMMARY',
        SEND_REMINDER: 'INVOICE.SEND_REMINDER',
        SEND_REMINDERS_BY: 'INVOICE.SEND_REMINDERS_BY',
        SEND_WITH: 'INVOICE.SEND_WITH',
        SEND_WITH_INVOICE: 'INVOICE.SEND_WITH_INVOICE',
        SETTLE: 'INVOICE.SETTLE',
        SUCCESSFULLY_ADDED_PAYMENT: 'INVOICE.SUCCESSFULLY_ADDED_PAYMENT',
        SUCCESSFULLY_MESSAGE_SENT: 'INVOICE.SUCCESSFULLY_MESSAGE_SENT',
        SUCCESSFUL_CN_SENT: 'INVOICE.SUCCESSFUL_CN_SENT',
        SUCCESSFUL_CN_UPDATE: 'INVOICE.SUCCESSFUL_CN_UPDATE',
        SUCCESSFUL_CREATION: 'INVOICE.SUCCESSFUL_CREATION',
        SUCCESSFUL_IMPORT: 'INVOICE.SUCCESSFUL_IMPORT',
        SUCCESSFUL_UPDATE: 'INVOICE.SUCCESSFUL_UPDATE',
        TOTAL_LATE_FEES: 'INVOICE.TOTAL_LATE_FEES',
        UNDO_DISPUTED: 'INVOICE.UNDO_DISPUTED',
        UNLINK: 'INVOICE.UNLINK',
        UNMATCH_PAYMENT: {
            AYS_TO_DELETE: 'INVOICE.UNMATCH_PAYMENT.AYS_TO_DELETE',
            AYS_TO_UNMATCH: 'INVOICE.UNMATCH_PAYMENT.AYS_TO_UNMATCH',
            CANCEL_PAYMENT: 'INVOICE.UNMATCH_PAYMENT.CANCEL_PAYMENT',
            CREDIT_NOTES: 'INVOICE.UNMATCH_PAYMENT.CREDIT_NOTES',
            PAYMENTS_ADDED_MANUALLY: 'INVOICE.UNMATCH_PAYMENT.PAYMENTS_ADDED_MANUALLY',
            PAYMENT_AMOUNT: 'INVOICE.UNMATCH_PAYMENT.PAYMENT_AMOUNT',
            RECONCILED_PAYMENTS: 'INVOICE.UNMATCH_PAYMENT.RECONCILED_PAYMENTS',
            RECONCILIATION_AMOUNT: 'INVOICE.UNMATCH_PAYMENT.RECONCILIATION_AMOUNT',
            RECONCILIATION_NOT_ACTIVE_ANYMORE: 'INVOICE.UNMATCH_PAYMENT.RECONCILIATION_NOT_ACTIVE_ANYMORE',
            SUCCESSFULLY_DELETE_PAYMENT: 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_DELETE_PAYMENT',
            SUCCESSFULLY_UNMATCH_PAYMENT: 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_UNMATCH_PAYMENT',
            UNMATCH_PAYMENT: 'INVOICE.UNMATCH_PAYMENT.UNMATCH_PAYMENT',
        },
        VAT_RATE: 'INVOICE.VAT_RATE',
        VIEW_PDF: 'INVOICE.VIEW_PDF',
        YOUR_INVOICE_FOR: 'INVOICE.YOUR_INVOICE_FOR',
    },
    INVOICES: 'INVOICES',
    INVOICES_IMPORT: 'INVOICES_IMPORT',
    INVOICE_BALANCE: 'INVOICE_BALANCE',
    INVOICE_BILLING_STATEMENT: 'INVOICE_BILLING_STATEMENT',
    INVOICE_IMPORT: 'INVOICE_IMPORT',
    INVOICE_NUMBER: 'INVOICE_NUMBER',
    INVOICE_SENDING_MEDIUM: 'INVOICE_SENDING_MEDIUM',
    INVOICE_TO_PROCESS: 'INVOICE_TO_PROCESS',
    INVOICING: {
        ACTION: 'INVOICING.ACTION',
        ADD_CREDIT_NOTE: 'INVOICING.ADD_CREDIT_NOTE',
        ADD_REFERENCE: 'INVOICING.ADD_REFERENCE',
        ALREADY_PAID: 'INVOICING.ALREADY_PAID',
        CREATE: {
            ADD_PRODUCT: 'INVOICING.CREATE.ADD_PRODUCT',
            BILLING_BOOK: 'INVOICING.CREATE.BILLING_BOOK',
            BOOK: 'INVOICING.CREATE.BOOK',
            CHOOSE_PRODUCT: 'INVOICING.CREATE.CHOOSE_PRODUCT',
            DISCOUNT_PERCENTAGE: 'INVOICING.CREATE.DISCOUNT_PERCENTAGE',
            EDIT_CREDIT_NOTE: 'INVOICING.CREATE.EDIT_CREDIT_NOTE',
            ERROR: {
                CHOOSE_BOOK: 'INVOICING.CREATE.ERROR.CHOOSE_BOOK',
                CHOOSE_CLIENT: 'INVOICING.CREATE.ERROR.CHOOSE_CLIENT',
                CHOOSE_INVOICE: 'INVOICING.CREATE.ERROR.CHOOSE_INVOICE',
            },
            NOTES_COMMENTS: 'INVOICING.CREATE.NOTES_COMMENTS',
            SELECT_CLIENT: 'INVOICING.CREATE.SELECT_CLIENT',
            VAT_APPLIED_0: 'INVOICING.CREATE.VAT_APPLIED_0',
            VAT_PERCENTAGE: 'INVOICING.CREATE.VAT_PERCENTAGE',
        },
        CREDIT_NOTE_BOOK: 'INVOICING.CREDIT_NOTE_BOOK',
        DONT_CHANGE: 'INVOICING.DONT_CHANGE',
        EDIT_INVOICE: 'INVOICING.EDIT_INVOICE',
        EXPORT_CSV: {
            CANNOT_EXPORT: 'INVOICING.EXPORT_CSV.CANNOT_EXPORT',
            CLIENT_DATA: 'INVOICING.EXPORT_CSV.CLIENT_DATA',
            CUSTOM_DATA: 'INVOICING.EXPORT_CSV.CUSTOM_DATA',
            DEBTOR_CUSTOM_DATA: 'INVOICING.EXPORT_CSV.DEBTOR_CUSTOM_DATA',
            EXPORT_ALL_CREDIT_NOTE: 'INVOICING.EXPORT_CSV.EXPORT_ALL_CREDIT_NOTE',
            EXPORT_ALL_DEBTOR: 'INVOICING.EXPORT_CSV.EXPORT_ALL_DEBTOR',
            EXPORT_ALL_INVOICE: 'INVOICING.EXPORT_CSV.EXPORT_ALL_INVOICE',
            EXPORT_ALL_TASKS: 'INVOICING.EXPORT_CSV.EXPORT_ALL_TASKS',
            EXPORT_FILTERED_CREDIT_NOTE: 'INVOICING.EXPORT_CSV.EXPORT_FILTERED_CREDIT_NOTE',
            EXPORT_FILTERED_DEBTOR: 'INVOICING.EXPORT_CSV.EXPORT_FILTERED_DEBTOR',
            EXPORT_FILTERED_INVOICE: 'INVOICING.EXPORT_CSV.EXPORT_FILTERED_INVOICE',
            EXPORT_FILTERED_TASKS: 'INVOICING.EXPORT_CSV.EXPORT_FILTERED_TASKS',
            FINANCIAL_DATA: 'INVOICING.EXPORT_CSV.FINANCIAL_DATA',
            PLEASE_SELECT_FIELDS: 'INVOICING.EXPORT_CSV.PLEASE_SELECT_FIELDS',
        },
        IMPENDING_REMINDERS: {
            CANCEL_REMINDER: 'INVOICING.IMPENDING_REMINDERS.CANCEL_REMINDER',
            INVOICES_TO_POSTPONE: 'INVOICING.IMPENDING_REMINDERS.INVOICES_TO_POSTPONE',
            INVOICE_TO_POSTPONE: 'INVOICING.IMPENDING_REMINDERS.INVOICE_TO_POSTPONE',
            LEAVE_ON: 'INVOICING.IMPENDING_REMINDERS.LEAVE_ON',
            POSTPONE_ALL: 'INVOICING.IMPENDING_REMINDERS.POSTPONE_ALL',
            POSTPONE_REMINDER: 'INVOICING.IMPENDING_REMINDERS.POSTPONE_REMINDER',
            WAITING_VALIDATION: 'INVOICING.IMPENDING_REMINDERS.WAITING_VALIDATION',
        },
        IMPORT: {
            AMOUNT_ALREADY_PAID: 'INVOICING.IMPORT.AMOUNT_ALREADY_PAID',
            AMOUNT_ALREADY_PAID_CURRENCY: 'INVOICING.IMPORT.AMOUNT_ALREADY_PAID_CURRENCY',
            CSV_INVOICES: 'INVOICING.IMPORT.CSV_INVOICES',
            DELETE_ALL: 'INVOICING.IMPORT.DELETE_ALL',
            DELETE_ALL_EMPTY_COL: 'INVOICING.IMPORT.DELETE_ALL_EMPTY_COL',
            EDIT_SUMMARY: 'INVOICING.IMPORT.EDIT_SUMMARY',
            GO_TO_VALIDATION_PAGE: 'INVOICING.IMPORT.GO_TO_VALIDATION_PAGE',
            LINE: 'INVOICING.IMPORT.LINE',
            LINES: 'INVOICING.IMPORT.LINES',
            MATCH_WITH: 'INVOICING.IMPORT.MATCH_WITH',
            MUST_BE_LOWER_EQUAL: 'INVOICING.IMPORT.MUST_BE_LOWER_EQUAL',
            ORIGINAL_FILE: 'INVOICING.IMPORT.ORIGINAL_FILE',
            PAYMENT_DONE: 'INVOICING.IMPORT.PAYMENT_DONE',
            PDF_INVOICES: 'INVOICING.IMPORT.PDF_INVOICES',
            PUT_LINE_AS_HEADER: 'INVOICING.IMPORT.PUT_LINE_AS_HEADER',
            SEE_INVOICES_TO_CONFIRM: 'INVOICING.IMPORT.SEE_INVOICES_TO_CONFIRM',
            SELECT_ALL_REQUIRED_FIELDS: 'INVOICING.IMPORT.SELECT_ALL_REQUIRED_FIELDS',
            SUMMARY: 'INVOICING.IMPORT.SUMMARY',
            UBL_INVOICES: 'INVOICING.IMPORT.UBL_INVOICES',
            VALIDATE_ALL: 'INVOICING.IMPORT.VALIDATE_ALL',
        },
        INVOICES_BOOK: 'INVOICING.INVOICES_BOOK',
        INVOICES_TO_CONFIRM: 'INVOICING.INVOICES_TO_CONFIRM',
        INVOICE_DATE: 'INVOICING.INVOICE_DATE',
        INVOICE_TEXT: {
            BEFORE_DEADLINE: 'INVOICING.INVOICE_TEXT.BEFORE_DEADLINE',
            GIVE_THE_AMOUNT: 'INVOICING.INVOICE_TEXT.GIVE_THE_AMOUNT',
            WITH_COMMUNICATION: 'INVOICING.INVOICE_TEXT.WITH_COMMUNICATION',
        },
        PLANNED_REMINDER: 'INVOICING.PLANNED_REMINDER',
        PRODUCT: {
            AYS_TO_DELETE: 'INVOICING.PRODUCT.AYS_TO_DELETE',
            TITLE: 'INVOICING.PRODUCT.TITLE',
        },
        RECOVERY: 'INVOICING.RECOVERY',
        SEE_DETAIL: 'INVOICING.SEE_DETAIL',
        SEE_INVOICE: 'INVOICING.SEE_INVOICE',
        SEE_INVOICES: 'INVOICING.SEE_INVOICES',
        SELECT_INVOICE: 'INVOICING.SELECT_INVOICE',
        SEND_CREDIT_NOTE: 'INVOICING.SEND_CREDIT_NOTE',
        SUCCESSFULLY_PRODUCT_CREATION: 'INVOICING.SUCCESSFULLY_PRODUCT_CREATION',
        SUCCESSFULLY_PRODUCT_UPDATE: 'INVOICING.SUCCESSFULLY_PRODUCT_UPDATE',
        TOTAL_OUTSTANDING_PAYMENTS: 'INVOICING.TOTAL_OUTSTANDING_PAYMENTS',
        TOTAL_VAT_PRICE_CURRENCY: 'INVOICING.TOTAL_VAT_PRICE_CURRENCY',
        VALIDATE_INVOICE: 'INVOICING.VALIDATE_INVOICE',
    },
    IN_PROGRESS: 'IN_PROGRESS',
    ISSUE_DATE: 'ISSUE_DATE',
    ISSUE_DATE_SHORT: 'ISSUE_DATE_SHORT',
    IS_USED: 'IS_USED',
    KANBAN: {
        BALANCE: 'KANBAN.BALANCE',
        DEBTOR: {
            TITLE: 'KANBAN.DEBTOR.TITLE',
        },
        FILTERS: {
            CLIENT_NAME: 'KANBAN.FILTERS.CLIENT_NAME',
            CLIENT_NAME_PH: 'KANBAN.FILTERS.CLIENT_NAME_PH',
            INVOICE_NAME: 'KANBAN.FILTERS.INVOICE_NAME',
            INVOICE_NAME_PH: 'KANBAN.FILTERS.INVOICE_NAME_PH',
            LESS_THAN: 'KANBAN.FILTERS.LESS_THAN',
            MAX_BALANCE: 'KANBAN.FILTERS.MAX_BALANCE',
            MAX_DAYS_LATE: 'KANBAN.FILTERS.MAX_DAYS_LATE',
            MAX_DAYS_LATE_PH: 'KANBAN.FILTERS.MAX_DAYS_LATE_PH',
            MIN_BALANCE: 'KANBAN.FILTERS.MIN_BALANCE',
            MIN_DAYS_LATE: 'KANBAN.FILTERS.MIN_DAYS_LATE',
            MIN_DAYS_LATE_PH: 'KANBAN.FILTERS.MIN_DAYS_LATE_PH',
            MORE_THAN: 'KANBAN.FILTERS.MORE_THAN',
            OPERATOR: 'KANBAN.FILTERS.OPERATOR',
        },
        INVOICE: {
            TITLE: 'KANBAN.INVOICE.TITLE',
        },
        LATE_INVOICES: 'KANBAN.LATE_INVOICES',
    },
    LAST_ISSUE_DATE: 'LAST_ISSUE_DATE',
    LAST_UPDATE: 'LAST_UPDATE',
    LATE: 'LATE',
    LATE_FEES: 'LATE_FEES',
    LATE_FEES_INCLUDED: 'LATE_FEES_INCLUDED',
    LINKED_PAYMENT: 'LINKED_PAYMENT',
    LOADING: 'LOADING',
    LOGIN: {
        ASK_ACCOUNT: 'LOGIN.ASK_ACCOUNT',
        BAD_SUBDOMAIN: 'LOGIN.BAD_SUBDOMAIN',
        CHANGE_PASSWORD: 'LOGIN.CHANGE_PASSWORD',
        CREATE_PASSWORD: 'LOGIN.CREATE_PASSWORD',
        NOT_YET_ACCOUNT: 'LOGIN.NOT_YET_ACCOUNT',
        PASSWORD_EDITED: 'LOGIN.PASSWORD_EDITED',
        RESET_PASSWORD: 'LOGIN.RESET_PASSWORD',
        TITLE: 'LOGIN.TITLE',
        VERIFY_SUBDOMAIN: 'LOGIN.VERIFY_SUBDOMAIN',
        YOUR_PASSWORD: 'LOGIN.YOUR_PASSWORD',
        YOU_FORGOT: 'LOGIN.YOU_FORGOT',
    },
    LOG_IN: 'LOG_IN',
    LOST_SUM: 'LOST_SUM',
    MAILS: {
        HISTORY: 'MAILS.HISTORY',
        NAV: 'MAILS.NAV',
        STATUS: {
            BOUNCE: 'MAILS.STATUS.BOUNCE',
            CLICK: 'MAILS.STATUS.CLICK',
            DELIVERED: 'MAILS.STATUS.DELIVERED',
            DROPPED: 'MAILS.STATUS.DROPPED',
            OPEN: 'MAILS.STATUS.OPEN',
            PROCESSED: 'MAILS.STATUS.PROCESSED',
            READ: 'MAILS.STATUS.READ',
            UNPROCESSED: 'MAILS.STATUS.UNPROCESSED',
            UNREAD: 'MAILS.STATUS.UNREAD',
        },
    },
    MAIN: 'MAIN',
    MAINTENANCE: {
        TITLE: 'MAINTENANCE.TITLE',
        DESC_1: 'MAINTENANCE.DESC_1',
        DESC_2: 'MAINTENANCE.DESC_2',
    },
    MARK_AS_DISPUTED: 'MARK_AS_DISPUTED',
    MARK_AS_LOST: 'MARK_AS_LOST',
    MARK_AS_PAID: 'MARK_AS_PAID',
    MORE_INFO: 'MORE_INFO',
    MERGE_CLIENT: 'MERGE_CLIENT',
    MERGE_CLIENTS: 'MERGE_CLIENTS',
    MISSING_DATA: 'MISSING_DATA',
    MISSING_EMAIL: 'MISSING_EMAIL',
    MISSING_VAR: 'MISSING_VAR',
    MODALITY: 'MODALITY',
    MONTH: 'MONTH',
    NAME: 'NAME',
    NAV: {
        ACTIVITY_BOOK: 'NAV.ACTIVITY_BOOK',
        ALL_INVOICE: 'NAV.ALL_INVOICE',
        CREDIT_NOTES: 'NAV.CREDIT_NOTES',
        CUSTOMIZATION: 'NAV.CUSTOMIZATION',
        IMINENT_REMINDERS: 'NAV.IMINENT_REMINDERS',
        MY_FOLLOW_UP: 'NAV.MY_FOLLOW_UP',
        PAYMENT_BOOK: 'NAV.PAYMENT_BOOK',
        PAYMENT_PLAN: 'NAV.PAYMENT_PLAN',
        PRODUCTS: 'NAV.PRODUCTS',
        RECONCILIATION: 'NAV.RECONCILIATION',
        SETTINGS: 'NAV.SETTINGS',
        SUMMARY_TABLE: 'NAV.SUMMARY_TABLE',
        TASKS: 'NAV.TASKS',
        THIRDPARTY: 'NAV.THIRDPARTY',
    },
    NEW_ADDRESS: 'NEW_ADDRESS',
    NEW_CLIENT: 'NEW_CLIENT',
    NEW_CREDIT_NOTE: 'NEW_CREDIT_NOTE',
    NEW_INVOICE: 'NEW_INVOICE',
    NEW_MESSAGE: 'NEW_MESSAGE',
    NEW_NOTE: 'NEW_NOTE',
    NEW_PAYMENT_PLAN: 'NEW_PAYMENT_PLAN',
    NEW_PRODUCT: 'NEW_PRODUCT',
    NEW_TASK: 'NEW_TASK',
    NEW_TEMPLATE: 'NEW_TEMPLATE',
    NEXT: 'NEXT',
    NEXT_DEADLINE: 'NEXT_DEADLINE',
    NEXT_STEP: 'NEXT_STEP',
    NEXT_STEP_DATE: 'NEXT_STEP_DATE',
    NO: 'NO',
    NONE: 'NONE',
    NOTES: 'NOTES',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    NOT_INCLUDED: 'NOT_INCLUDED',
    NO_AVAILABLE_ACTIONS: 'NO_AVAILABLE_ACTIONS',
    NO_PAYMENT_METHOD: 'NO_PAYMENT_METHOD',
    NO_RESULT: 'NO_RESULT',
    NO_RESULT_FOR_THIS_SEARCH: 'NO_RESULT_FOR_THIS_SEARCH',
    NO_SIGNATURE: 'NO_SIGNATURE',
    NO_STATEMENT: 'NO_STATEMENT',
    NO_TEXT: 'NO_TEXT',
    NR: 'NR',
    NUMBER: 'NUMBER',
    N_DAYS_AFTER_CREATION: 'N_DAYS_AFTER_CREATION',
    N_STEP: 'N_STEP',
    OF: 'OF',
    OFF_BALANCE: 'OFF_BALANCE',
    ONBOARDING: {
        '0': {
            TITLE: 'ONBOARDING.0.TITLE',
            TERMS_AND_CONDITIONS: {
                AGREEMENT: 'ONBOARDING.0.TERMS_AND_CONDITIONS.AGREEMENT',
                LINK: 'ONBOARDING.0.TERMS_AND_CONDITIONS.LINK',
            },
            PROCEED_TO_DASHBOARD: 'ONBOARDING.0.PROCEED_TO_DASHBOARD',
        },
        '1': {
            AYS_TO_NOT_ADD_PAYMENT_METHOD: 'ONBOARDING.1.AYS_TO_NOT_ADD_PAYMENT_METHOD',
            CREDIT_CARD: {
                DESCRIPTION: 'ONBOARDING.1.CREDIT_CARD.DESCRIPTION',
                TITLE: 'ONBOARDING.1.CREDIT_CARD.TITLE',
            },
            DIRECT_DEBIT: {
                CLICK_ON_LINK: 'ONBOARDING.1.DIRECT_DEBIT.CLICK_ON_LINK',
                CONFIRM: 'ONBOARDING.1.DIRECT_DEBIT.CONFIRM',
                DESCRIPTION: 'ONBOARDING.1.DIRECT_DEBIT.DESCRIPTION',
                TITLE: 'ONBOARDING.1.DIRECT_DEBIT.TITLE',
                TO_CONTINUE: 'ONBOARDING.1.DIRECT_DEBIT.TO_CONTINUE',
            },
            TITLE: 'ONBOARDING.1.TITLE',
        },
        '2': {
            CODABOX: {
                CLICK_TO_RECEIVE_TOKEN: 'ONBOARDING.2.CODABOX.CLICK_TO_RECEIVE_TOKEN',
                CODE_SENT_BY_EMAIL: 'ONBOARDING.2.CODABOX.CODE_SENT_BY_EMAIL',
                DESCRIPTION: 'ONBOARDING.2.CODABOX.DESCRIPTION',
                EASY_TO_VALID: 'ONBOARDING.2.CODABOX.EASY_TO_VALID',
                ENTER_TOKEN_BELOW: 'ONBOARDING.2.CODABOX.ENTER_TOKEN_BELOW',
                FIRSTLY: 'ONBOARDING.2.CODABOX.FIRSTLY',
                TITLE: 'ONBOARDING.2.CODABOX.TITLE',
            },
            NO_BANK_CONNEXION: 'ONBOARDING.2.NO_BANK_CONNEXION',
            PAYMENT_ACCOUNT: 'ONBOARDING.2.PAYMENT_ACCOUNT',
            TITLE: 'ONBOARDING.2.TITLE',
        },
        '3': {
            DESCRIPTION_STEP1: 'ONBOARDING.3.DESCRIPTION_STEP1',
            DESCRIPTION_STEP1_2: 'ONBOARDING.3.DESCRIPTION_STEP1_2',
            DESCRIPTION_STEP2: 'ONBOARDING.3.DESCRIPTION_STEP2',
            DESCRIPTION_STEP3: 'ONBOARDING.3.DESCRIPTION_STEP3',
            DESCRIPTION_STEP4: 'ONBOARDING.3.DESCRIPTION_STEP4',
            TITLE: 'ONBOARDING.3.TITLE',
        },
        '4': {
            TITLE: 'ONBOARDING.4.TITLE',
        },
        '5': {
            CONFIGURATION_DONE: 'ONBOARDING.5.CONFIGURATION_DONE',
            SUMMARY: 'ONBOARDING.5.SUMMARY',
            TITLE: 'ONBOARDING.5.TITLE',
        },
        '6': {
            BUTTON: 'ONBOARDING.6.BUTTON',
            TITLE: 'ONBOARDING.6.TITLE',
        },
        API: {
            STEP_1: 'ONBOARDING.API.STEP_1',
            STEP_2: 'ONBOARDING.API.STEP_2',
            STEP_3: 'ONBOARDING.API.STEP_3',
        },
        NEXT_STEP: 'ONBOARDING.NEXT_STEP',
        SIGNUP_ACCOUNT_TITLE: 'ONBOARDING.SIGNUP_ACCOUNT_TITLE',
        SIGNUP_COMPANY_TITLE: 'ONBOARDING.SIGNUP_COMPANY_TITLE',
        STEP: 'ONBOARDING.STEP',
        TITLE: 'ONBOARDING.TITLE',
    },
    ONE_DAY: 'ONE_DAY',
    ONGOING_PAYMENT_PLAN: 'ONGOING_PAYMENT_PLAN',
    ONLINE_PAYMENT: 'ONLINE_PAYMENT',
    OPTIONAL: 'OPTIONAL',
    OR: 'OR',
    OTHER: 'OTHER',
    OTHERWISE: 'OTHERWISE',
    OVERDUE_BALANCE: 'OVERDUE_BALANCE',
    OVER_ONE_YEAR: 'OVER_ONE_YEAR',
    PAIDS: 'PAIDS',
    PASSWORD: 'PASSWORD',
    PAYMENT: 'PAYMENT',
    PAYMENT_BANNER: {
        GO_TO_PAYMENT_PORTAL: 'PAYMENT_BANNER.GO_TO_PAYMENT_PORTAL',
        LATE_PAYMENT_BANNER_TEXT: 'PAYMENT_BANNER.LATE_PAYMENT_BANNER_TEXT',
        TITLE: 'PAYMENT_BANNER.TITLE',
    },
    PAYMENT_PLAN: {
        ATTRIBUTES: {
            COMMUNICATION: 'PAYMENT_PLAN.ATTRIBUTES.COMMUNICATION',
            CREATED_AT: 'PAYMENT_PLAN.ATTRIBUTES.CREATED_AT',
            NEXT_DUE_DATE: 'PAYMENT_PLAN.ATTRIBUTES.NEXT_DUE_DATE',
            PAYMENT_STEPS: 'PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS',
        },
        TITLE: {
            EDIT: 'PAYMENT_PLAN.TITLE.EDIT',
            MANY: 'PAYMENT_PLAN.TITLE.MANY',
            NEW: 'PAYMENT_PLAN.TITLE.NEW',
            ONE: 'PAYMENT_PLAN.TITLE.ONE',
        },
    },
    PENALTY_CLAUSE: 'PENALTY_CLAUSE',
    PENDING_IMPORT: 'PENDING_IMPORT',
    PERCENTAGE: 'PERCENTAGE',
    PERIOD: {
        ANNUAL: 'PERIOD.ANNUAL',
        INTRODUCTION: 'PERIOD.INTRODUCTION',
        MONTHLY: 'PERIOD.MONTHLY',
    },
    PERSONAL_INFO: 'PERSONAL_INFO',
    PERSONAL_PREFENCES: 'PERSONAL_PREFENCES',
    PER_PAGE: 'PER_PAGE',
    PHONE: 'PHONE',
    COMPANY_EMAIL: 'COMPANY_EMAIL',
    PHONES: 'PHONES',
    PLAN: {
        ADD_PREVENTIVE_REMINDER: 'PLAN.ADD_PREVENTIVE_REMINDER',
        ADD_STEP: 'PLAN.ADD_STEP',
        ASSOCIATE_ALL_STEPS: 'PLAN.ASSOCIATE_ALL_STEPS',
        CLIENTS_NUMBER_INCLUDED: 'PLAN.CLIENTS_NUMBER_INCLUDED',
        EDITION_STEP: 'PLAN.EDITION_STEP',
        EDITION_TASK: 'PLAN.EDITION_TASK',
        EXECUTED: 'PLAN.EXECUTED',
        INVOICES: 'PLAN.INVOICES',
        INVOICES_EXECUTED_STEP: 'PLAN.INVOICES_EXECUTED_STEP',
        INVOICES_NUMBER_INCLUDED: 'PLAN.INVOICES_NUMBER_INCLUDED',
        MOVE_DEBTOR: 'PLAN.MOVE_DEBTOR',
        MOVE_INVOICES: 'PLAN.MOVE_INVOICES',
        MOVE_INVOICES_IN_NEW_PLAN: 'PLAN.MOVE_INVOICES_IN_NEW_PLAN',
        NAME: 'PLAN.NAME',
        SELECT_PLAN: 'PLAN.SELECT_PLAN',
        SELECT_STEP: 'PLAN.SELECT_STEP',
        SELECT_TEMPLATE: 'PLAN.SELECT_TEMPLATE',
        SET_AS_DEFAULT: 'PLAN.SET_AS_DEFAULT',
        STEP_MUST_BE_CREATED: 'PLAN.STEP_MUST_BE_CREATED',
        TRANSFERT_INVOICES: 'PLAN.TRANSFERT_INVOICES',
        WHERE_MOVE_INVOICES: 'PLAN.WHERE_MOVE_INVOICES',
    },
    PONTO_NOT_SYNCED: 'PONTO_NOT_SYNCED',
    POST: 'POST',
    POSTPONE: 'POSTPONE',
    PREFERENCES: 'PREFERENCES',
    PREVIOUS: 'PREVIOUS',
    PRICE: 'PRICE',
    PROCESS: 'PROCESS',
    PUBLIC: 'PUBLIC',
    READ_AND_APPROVE: 'READ_AND_APPROVE',
    REASON: 'REASON',
    REASONS: 'REASONS',
    RECEIVED_PAYMENT_S: 'RECEIVED_PAYMENT_S',
    RECONCILE: 'RECONCILE',
    RECOVERY_IN_PAUSE: 'RECOVERY_IN_PAUSE',
    RECOVERY_PLAN: 'RECOVERY_PLAN',
    RECOVERY_PLANS: 'RECOVERY_PLANS',
    RECOVR: 'RECOVR',
    REFETCH: 'REFETCH',
    REGISTERED: 'REGISTERED',
    REMAINING_BALANCE_WITH_LATE_FEES: 'REMAINING_BALANCE_WITH_LATE_FEES',
    REMAINING_LATE_FEES: 'REMAINING_LATE_FEES',
    REMINDER: {
        DATE_SENT: 'REMINDER.DATE_SENT',
        DELIVERY_STATUS: 'REMINDER.DELIVERY_STATUS',
        REMINDER: 'REMINDER.REMINDER',
        SEE_DELIVERY_HISTORY: 'REMINDER.SEE_DELIVERY_HISTORY',
        SENT_TO: 'REMINDER.SENT_TO',
        SEE_REMINDER_DETAILS: 'REMINDER.SEE_REMINDER_DETAILS',
    },
    REMINDERS: 'REMINDERS',
    REPLIES: 'REPLIES',
    REPLY: 'REPLY',
    REPLY_TO: 'REPLY_TO',
    REPORTS: {
        INDEX: {
            ACTIONS: {
                EDIT: 'REPORTS.INDEX.ACTIONS.EDIT',
            },
            EXECUTION_DATE: {
                NOT_EXECUTED: 'REPORTS.INDEX.EXECUTION_DATE.NOT_EXECUTED',
            },
            ROWS: {
                ACTIONS: 'REPORTS.INDEX.ROWS.ACTIONS',
                EXECUTION_DATE: 'REPORTS.INDEX.ROWS.EXECUTION_DATE',
                NAME: 'REPORTS.INDEX.ROWS.NAME',
                PERIODICITY: 'REPORTS.INDEX.ROWS.PERIODICITY',
            },
            TITLE: 'REPORTS.INDEX.TITLE',
        },
        NEW: {
            ACTION_BUTTON: 'REPORTS.NEW.ACTION_BUTTON',
            CANCEL: 'REPORTS.NEW.CANCEL',
            STEPS: {
                ADVANCE: 'REPORTS.NEW.STEPS.ADVANCE',
                CREATE: 'REPORTS.NEW.STEPS.CREATE',
                DATA: {
                    RESOURCE_TYPE: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPE',
                    RESOURCE_TYPES: {
                        ACTIONABLE_INVOICES: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.ACTIONABLE_INVOICES',
                        CREDIT_NOTES: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.CREDIT_NOTES',
                        DEBTORS: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.DEBTORS',
                        INVOICES: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.INVOICES',
                        REMINDERS: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.REMINDERS',
                        TASKS: 'REPORTS.NEW.STEPS.DATA.RESOURCE_TYPES.TASKS',
                    },
                    VIEW: 'REPORTS.NEW.STEPS.DATA.VIEW',
                    VIEW_SUBTITLE: 'REPORTS.NEW.STEPS.DATA.VIEW_SUBTITLE',
                },
                NAME: {
                    DUPLICATED_NAME: 'REPORTS.NEW.STEPS.NAME.DUPLICATED_NAME',
                    FORM_SUBTITLE: 'REPORTS.NEW.STEPS.NAME.FORM_SUBTITLE',
                    FORM_TITLE: 'REPORTS.NEW.STEPS.NAME.FORM_TITLE',
                    PLACEHOLDER: 'REPORTS.NEW.STEPS.NAME.PLACEHOLDER',
                },
                RECIPIENTS: {
                    COLLABORATORS: 'REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS',
                    COLLABORATORS_PLACEHOLDER: 'REPORTS.NEW.STEPS.RECIPIENTS.COLLABORATORS_PLACEHOLDER',
                    EXTERNAL: 'REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL',
                    EXTERNAL_PLACEHOLDER: 'REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_PLACEHOLDER',
                    EXTERNAL_SUBMIT_HINT: 'REPORTS.NEW.STEPS.RECIPIENTS.EXTERNAL_SUBMIT_HINT',
                    INVALID_EMAIL: 'REPORTS.NEW.STEPS.RECIPIENTS.INVALID_EMAIL',
                    TITLE: 'REPORTS.NEW.STEPS.RECIPIENTS.TITLE',
                },
                RECURRENCE: {
                    LAST_DAY_DISCLAIMER: 'REPORTS.NEW.STEPS.RECURRENCE.LAST_DAY_DISCLAIMER',
                    MONTHLY_END_OF_MONTH_DISCLAIMER: 'REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_END_OF_MONTH_DISCLAIMER',
                    MONTHLY_TITLE: 'REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_TITLE',
                    WEEKLY_TITLE: 'REPORTS.NEW.STEPS.RECURRENCE.WEEKLY_TITLE',
                },
            },
            STEP_LABELS: {
                DATA: 'REPORTS.NEW.STEP_LABELS.DATA',
                NAME: 'REPORTS.NEW.STEP_LABELS.NAME',
                RECIPIENTS: 'REPORTS.NEW.STEP_LABELS.RECIPIENTS',
                RECURRENCE: 'REPORTS.NEW.STEP_LABELS.RECURRENCE',
            },
            TITLE: 'REPORTS.NEW.TITLE',
        },
        RECURRENCE: {
            MONTHLY: 'REPORTS.RECURRENCE.MONTHLY',
            WEEKLY: 'REPORTS.RECURRENCE.WEEKLY',
        },
        SHOW: {
            AYS_DELETE: 'REPORTS.SHOW.AYS_DELETE',
            DELETE_REPORT: 'REPORTS.SHOW.DELETE_REPORT',
            DETAILS: 'REPORTS.SHOW.DETAILS',
            NAME: 'REPORTS.SHOW.NAME',
            NO_EXPORTS_YET: 'REPORTS.SHOW.NO_EXPORTS_YET',
            NO_REPORTS_YET: 'REPORTS.SHOW.NO_REPORTS_YET',
            RECIPIENTS: 'REPORTS.SHOW.RECIPIENTS',
            RECURRENCE: 'REPORTS.SHOW.RECURRENCE',
        },
    },
    RESET: 'RESET',
    RESULT: 'RESULT',
    REVERT_DISPUTED: 'REVERT_DISPUTED',
    SALE_CONDITION: 'SALE_CONDITION',
    SALE_CONDITION_APPLIED: 'SALE_CONDITION_APPLIED',
    SAVE: 'SAVE',
    SAVE_FILTERS: 'SAVE_FILTERS',
    SEARCH: 'SEARCH',
    SEE_ALL: 'SEE_ALL',
    SEE_ALL_M: 'SEE_ALL_M',
    SEE_FILE: 'SEE_FILE',
    SEE_LESS: 'SEE_LESS',
    SEE_MORE: 'SEE_MORE',
    SELECT: 'SELECT',
    SELECTED_ELEMENT: 'SELECTED_ELEMENT',
    SELECTED_ELEMENTS: 'SELECTED_ELEMENTS',
    SELECT_ELEMENT: 'SELECT_ELEMENT',
    SELECT_ELEMENTS: 'SELECT_ELEMENTS',
    SENDING_SETTINGS: 'SENDING_SETTINGS',
    SEND_FULL_BILLING_STATEMENT: 'SEND_FULL_BILLING_STATEMENT',
    SERVICE_UNAVAILABLE_UNTIL: 'SERVICE_UNAVAILABLE_UNTIL',
    SETTINGS: {
        AUTOMATIC_ASSIGNATION: {
            ASSIGNATION_CONDITIONS: 'SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS',
            ASSIGN_TO: 'SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO',
            CLIENT_PLAN: 'SETTINGS.AUTOMATIC_ASSIGNATION.CLIENT_PLAN',
            CLIENT_USER: 'SETTINGS.AUTOMATIC_ASSIGNATION.CLIENT_USER',
            INV_PLAN: 'SETTINGS.AUTOMATIC_ASSIGNATION.INV_PLAN',
            INV_USER: 'SETTINGS.AUTOMATIC_ASSIGNATION.INV_USER',
            MODEL_TO_ASSIGN: 'SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN',
            ORDER: 'SETTINGS.AUTOMATIC_ASSIGNATION.ORDER',
        },
        CLIENT_ID: 'SETTINGS.CLIENT_ID',
        COMPANY: {
            AYS_RESET_SECRET_ID: 'SETTINGS.COMPANY.AYS_RESET_SECRET_ID',
            BANK_ACCOUNT_DESCRIPTION: 'SETTINGS.COMPANY.BANK_ACCOUNT_DESCRIPTION',
            NOT_SHOW_SECRET_ID_ANYMORE: 'SETTINGS.COMPANY.NOT_SHOW_SECRET_ID_ANYMORE',
            PROFILE: 'SETTINGS.COMPANY.PROFILE',
            RESET_SECRET_ID: 'SETTINGS.COMPANY.RESET_SECRET_ID',
            SECRET_ID: 'SETTINGS.COMPANY.SECRET_ID',
            SUCCESSFULLY_UPDATE: 'SETTINGS.COMPANY.SUCCESSFULLY_UPDATE',
            TITLE: 'SETTINGS.COMPANY.TITLE',
        },
        CUSTOMIZATION: {
            TABS: {
                CUSTOM_VARIABLES: 'SETTINGS.CUSTOMIZATION.TABS.CUSTOM_VARIABLES',
                REASONS: 'SETTINGS.CUSTOMIZATION.TABS.REASONS',
                TASK_TYPES: 'SETTINGS.CUSTOMIZATION.TABS.TASK_TYPES',
            },
        },
        CUSTOM_VARIABLES: {
            API: 'SETTINGS.CUSTOM_VARIABLES.API',
            AYS_TO_DELETE: 'SETTINGS.CUSTOM_VARIABLES.AYS_TO_DELETE',
            COLUMN_NAME: 'SETTINGS.CUSTOM_VARIABLES.COLUMN_NAME',
            DEFAULT_VALUE: 'SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE',
            DISPLAY: 'SETTINGS.CUSTOM_VARIABLES.DISPLAY',
            LONG_TITLE: 'SETTINGS.CUSTOM_VARIABLES.LONG_TITLE',
            MODEL_TYPE: 'SETTINGS.CUSTOM_VARIABLES.MODEL_TYPE',
            NAME: 'SETTINGS.CUSTOM_VARIABLES.NAME',
            TITLE: 'SETTINGS.CUSTOM_VARIABLES.TITLE',
            VAR_TYPE: 'SETTINGS.CUSTOM_VARIABLES.VAR_TYPE',
            WEBHOOK: 'SETTINGS.CUSTOM_VARIABLES.WEBHOOK',
        },
        EXTERNAL_MAILBOX: {
            ADD: 'SETTINGS.EXTERNAL_MAILBOX.ADD',
            CONNECT: 'SETTINGS.EXTERNAL_MAILBOX.CONNECT',
            DELETE: 'SETTINGS.EXTERNAL_MAILBOX.DELETE',
            EDIT: 'SETTINGS.EXTERNAL_MAILBOX.EDIT',
            HOST: 'SETTINGS.EXTERNAL_MAILBOX.HOST',
            ID: 'SETTINGS.EXTERNAL_MAILBOX.ID',
            LIMIT_EXCEEDED: 'SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED',
            LOGIN: 'SETTINGS.EXTERNAL_MAILBOX.LOGIN',
            MANUAL_SETUP: 'SETTINGS.EXTERNAL_MAILBOX.MANUAL_SETUP',
            PASSWORD: 'SETTINGS.EXTERNAL_MAILBOX.PASSWORD',
            PORT: 'SETTINGS.EXTERNAL_MAILBOX.PORT',
            QUICK_SETUP: 'SETTINGS.EXTERNAL_MAILBOX.QUICK_SETUP',
            SIGN_IN_WITH: {
                MICROSOFT: 'SETTINGS.EXTERNAL_MAILBOX.SIGN_IN_WITH.MICROSOFT',
            },
            SSL: 'SETTINGS.EXTERNAL_MAILBOX.SSL',
            TITLE: 'SETTINGS.EXTERNAL_MAILBOX.TITLE',
        },
        INTEGRATIONS: {
            ACCOUNTING: 'SETTINGS.INTEGRATIONS.ACCOUNTING',
            ALL: 'SETTINGS.INTEGRATIONS.ALL',
            BANKING: 'SETTINGS.INTEGRATIONS.BANKING',
            BILLIT: {
                CLICK_TO_RECEIVE_TOKEN: 'SETTINGS.INTEGRATIONS.BILLIT.CLICK_TO_RECEIVE_TOKEN',
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.BILLIT.DESCRIPTION',
                EASY_TO_VALID: 'SETTINGS.INTEGRATIONS.BILLIT.EASY_TO_VALID',
                SUMMARY: 'SETTINGS.INTEGRATIONS.BILLIT.SUMMARY',
            },
            CATEGORIES: 'SETTINGS.INTEGRATIONS.CATEGORIES',
            CODABOX: {
                ALERT_NEW_TOKEN: 'SETTINGS.INTEGRATIONS.CODABOX.ALERT_NEW_TOKEN',
                ASK_TOKEN: 'SETTINGS.INTEGRATIONS.CODABOX.ASK_TOKEN',
                CODABOX_TOKEN: 'SETTINGS.INTEGRATIONS.CODABOX.CODABOX_TOKEN',
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.CODABOX.DESCRIPTION',
                NEW_TOKEN: 'SETTINGS.INTEGRATIONS.CODABOX.NEW_TOKEN',
            },
            COMPANYWEB: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.COMPANYWEB.DESCRIPTION',
                INTERCOM_MORE_INFO: 'SETTINGS.INTEGRATIONS.COMPANYWEB.INTERCOM_MORE_INFO',
            },
            DBASICS: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.DBASICS.DESCRIPTION',
            },
            DIGITEAL: {
                ACCEPT_CGV: 'SETTINGS.INTEGRATIONS.DIGITEAL.ACCEPT_CGV',
                AYS_TO_ACTIVATE: 'SETTINGS.INTEGRATIONS.DIGITEAL.AYS_TO_ACTIVATE',
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION',
                EMAIL_TO_ACTIVATE: 'SETTINGS.INTEGRATIONS.DIGITEAL.EMAIL_TO_ACTIVATE',
                MAKE_AVAILABLE: 'SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_AVAILABLE',
                MAKE_UNAVAILABLE: 'SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_UNAVAILABLE',
                SIGNUP_TO_CONTINUE: 'SETTINGS.INTEGRATIONS.DIGITEAL.SIGNUP_TO_CONTINUE',
                SUMMARY: 'SETTINGS.INTEGRATIONS.DIGITEAL.SUMMARY',
            },
            ERP: 'SETTINGS.INTEGRATIONS.ERP',
            EXACT: {
                COLUMN_DESCRIPTION: 'SETTINGS.INTEGRATIONS.EXACT.COLUMN_DESCRIPTION',
                COLUMN_REFERENCE: 'SETTINGS.INTEGRATIONS.EXACT.COLUMN_REFERENCE',
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.EXACT.DESCRIPTION',
                SELECT_COLUMN: 'SETTINGS.INTEGRATIONS.EXACT.SELECT_COLUMN',
            },
            HORUS: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.HORUS.DESCRIPTION',
                SUMMARY: 'SETTINGS.INTEGRATIONS.HORUS.SUMMARY',
            },
            MY_INTEGRATIONS: 'SETTINGS.INTEGRATIONS.MY_INTEGRATIONS',
            PONTO: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.PONTO.DESCRIPTION',
                SECRET_CODE: 'SETTINGS.INTEGRATIONS.PONTO.SECRET_CODE',
                SUMMARY: 'SETTINGS.INTEGRATIONS.PONTO.SUMMARY',
            },
            TEAMLEADER: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.TEAMLEADER.DESCRIPTION',
            },
            TITLE: 'SETTINGS.INTEGRATIONS.TITLE',
            YUKI: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.YUKI.DESCRIPTION',
            },
            CHIFT: {
                DESCRIPTION: 'SETTINGS.INTEGRATIONS.CHIFT.DESCRIPTION',
            },
        },
        INVOICING: {
            AYS_DELETE_LOGO: 'SETTINGS.INVOICING.AYS_DELETE_LOGO',
            FOOTER: {
                CLICK_TO_EDIT: 'SETTINGS.INVOICING.FOOTER.CLICK_TO_EDIT',
                DESCRIPTION: 'SETTINGS.INVOICING.FOOTER.DESCRIPTION',
                EMPTY: 'SETTINGS.INVOICING.FOOTER.EMPTY',
                ENTER_TEXT: 'SETTINGS.INVOICING.FOOTER.ENTER_TEXT',
                MODAL_TITLE: 'SETTINGS.INVOICING.FOOTER.MODAL_TITLE',
                SELECT_FIELD: 'SETTINGS.INVOICING.FOOTER.SELECT_FIELD',
                TITLE: 'SETTINGS.INVOICING.FOOTER.TITLE',
            },
            TITLE: 'SETTINGS.INVOICING.TITLE',
        },
        PACKAGE: {
            ACCEPT_CGV: 'SETTINGS.PACKAGE.ACCEPT_CGV',
            ACCOUNT_SUMMARY: 'SETTINGS.PACKAGE.ACCOUNT_SUMMARY',
            ALL_PRICES_EXCL_VAT: 'SETTINGS.PACKAGE.ALL_PRICES_EXCL_VAT',
            CLICK_TO_BE_CONTACTED: 'SETTINGS.PACKAGE.CLICK_TO_BE_CONTACTED',
            EDIT_BANKING_INFO: 'SETTINGS.PACKAGE.EDIT_BANKING_INFO',
            FOR_1_YEAR: 'SETTINGS.PACKAGE.FOR_1_YEAR',
            MORE_FUNCTIONNALITIES: 'SETTINGS.PACKAGE.MORE_FUNCTIONNALITIES',
            NUMBER_LEGAL_CREDITS: 'SETTINGS.PACKAGE.NUMBER_LEGAL_CREDITS',
            NUMBER_PHONE_BY_MONTH: 'SETTINGS.PACKAGE.NUMBER_PHONE_BY_MONTH',
            PAYMENT_BY_INTEGRATOR: 'SETTINGS.PACKAGE.PAYMENT_BY_INTEGRATOR',
            PAYMENT_METHOD: 'SETTINGS.PACKAGE.PAYMENT_METHOD',
            PAYMENT_PERIOD: 'SETTINGS.PACKAGE.PAYMENT_PERIOD',
            SEPA_MANDATE_VIA_TWIKEY: 'SETTINGS.PACKAGE.SEPA_MANDATE_VIA_TWIKEY',
            SUMMARY: 'SETTINGS.PACKAGE.SUMMARY',
            TITLE: 'SETTINGS.PACKAGE.TITLE',
            YEARLY_COMMITMENT: 'SETTINGS.PACKAGE.YEARLY_COMMITMENT',
            YOUR_PACKAGE: 'SETTINGS.PACKAGE.YOUR_PACKAGE',
        },
        PREFERENCES: {
            TITLE: 'SETTINGS.PREFERENCES.TITLE',
        },
        PROFILE: {
            EDIT: 'SETTINGS.PROFILE.EDIT',
            SUCCESSFULLY_UPDATE: 'SETTINGS.PROFILE.SUCCESSFULLY_UPDATE',
            TITLE: 'SETTINGS.PROFILE.TITLE',
        },
        STAFF: {
            ACTIVE: 'SETTINGS.STAFF.ACTIVE',
            COLLABORATOR_NAME: 'SETTINGS.STAFF.COLLABORATOR_NAME',
            DAILY: 'SETTINGS.STAFF.DAILY',
            INACTIVE: 'SETTINGS.STAFF.INACTIVE',
            NOTIFICATION: 'SETTINGS.STAFF.NOTIFICATION',
            PERMISSIONS: 'SETTINGS.STAFF.PERMISSIONS',
            RECEIVE_EMAIL: 'SETTINGS.STAFF.RECEIVE_EMAIL',
            SUCCESSFULLY_UPDATE: 'SETTINGS.STAFF.SUCCESSFULLY_UPDATE',
            TITLE: 'SETTINGS.STAFF.TITLE',
            WEEKLY: 'SETTINGS.STAFF.WEEKLY',
            YOUR_STAFF: 'SETTINGS.STAFF.YOUR_STAFF',
        },
        TASKS: {
            TASK_CUSTOM: 'SETTINGS.TASKS.TASK_CUSTOM',
            TASK_DEFAULT: 'SETTINGS.TASKS.TASK_DEFAULT',
            TASK_ID: 'SETTINGS.TASKS.TASK_ID',
            TASK_TYPE: 'SETTINGS.TASKS.TASK_TYPE',
            TASK_TYPE_TITLE: 'SETTINGS.TASKS.TASK_TYPE_TITLE',
            TASK_VALUE: 'SETTINGS.TASKS.TASK_VALUE',
            TITLE: 'SETTINGS.TASKS.TITLE',
        },
    },
    SET_ACCOUNT_MANAGER: 'SET_ACCOUNT_MANAGER',
    SET_DEFAULT_PLAN: 'SET_DEFAULT_PLAN',
    SHARED: {
        CUSTOM_TABLE: {
            LOAD_MORE: 'SHARED.CUSTOM_TABLE.LOAD_MORE',
        },
        ENCODE_PAYMENT: {
            TITLE: 'SHARED.ENCODE_PAYMENT.TITLE',
        },
    },
    SHOW_CGV: 'SHOW_CGV',
    SHOW_CONVERSATION: 'SHOW_CONVERSATION',
    SHOW_DOCUMENT: 'SHOW_DOCUMENT',
    SHOW_TEMPLATE: 'SHOW_TEMPLATE',
    SIGNATURE: 'SIGNATURE',
    SLOGAN: 'SLOGAN',
    SPOTLIGHT: {
        PLACEHOLDER: 'SPOTLIGHT.PLACEHOLDER',
        SET_LOCALE: 'SPOTLIGHT.SET_LOCALE',
    },
    STATEMENT: 'STATEMENT',
    STATUS: 'STATUS',
    SUBJECT: 'SUBJECT',
    SUBJECT_VARIABLES: 'SUBJECT_VARIABLES',
    SUBMIT: 'SUBMIT',
    SUCCESS: 'SUCCESS',
    SUCCESSFULLY_FILE_UPLOAD: 'SUCCESSFULLY_FILE_UPLOAD',
    SUCCESSFULL_ACTIONS: 'SUCCESSFULL_ACTIONS',
    SUCCESSFUL_ACTION: 'SUCCESSFUL_ACTION',
    SYNCHRONIZE: 'SYNCHRONIZE',
    TASK: {
        ACTIONS: {
            ASSIGN: 'TASK.ACTIONS.ASSIGN',
            COMPLETE: 'TASK.ACTIONS.COMPLETE',
            DELETE: 'TASK.ACTIONS.DELETE',
            POSTPONE: 'TASK.ACTIONS.POSTPONE',
            REVERT: 'TASK.ACTIONS.REVERT',
            SKIP: 'TASK.ACTIONS.SKIP',
        },
        ATTRIBUTES: {
            ASSIGNEE: 'TASK.ATTRIBUTES.ASSIGNEE',
            DEBTOR: 'TASK.ATTRIBUTES.DEBTOR',
            INVOICE: 'TASK.ATTRIBUTES.INVOICE',
            MANDATORY: 'TASK.ATTRIBUTES.MANDATORY',
            STATUS: 'TASK.ATTRIBUTES.STATUS',
            TASK_TYPE: 'TASK.ATTRIBUTES.TASK_TYPE',
            TITLE: 'TASK.ATTRIBUTES.TITLE',
        },
        PAYMENT_PLAN: {
            ACCEPT: 'TASK.PAYMENT_PLAN.ACCEPT',
            DECLINE: 'TASK.PAYMENT_PLAN.DECLINE',
        },
        STATUS: {
            ALL: 'TASK.STATUS.ALL',
            DONE: 'TASK.STATUS.DONE',
            PENDING: 'TASK.STATUS.PENDING',
            SKIPPED: 'TASK.STATUS.SKIPPED',
        },
        TITLE: 'TASK.TITLE',
    },
    TASK_GENERATION: 'TASK_GENERATION',
    TEMPLATE: {
        ACCOUNT_MANAGER_EMAIL_FIELD: 'TEMPLATE.ACCOUNT_MANAGER_EMAIL_FIELD',
        ACCOUNT_MANAGER_NAME_FIELD: 'TEMPLATE.ACCOUNT_MANAGER_NAME_FIELD',
        ATTACH_FILE: 'TEMPLATE.ATTACH_FILE',
        CAN_USE_VARIABLE: 'TEMPLATE.CAN_USE_VARIABLE',
        FULL_DEBTOR_BALANCE: 'TEMPLATE.FULL_DEBTOR_BALANCE',
        OVERDUE_DEBTOR_BALANCE: 'TEMPLATE.OVERDUE_DEBTOR_BALANCE',
        SELECT_STATEMENT: 'TEMPLATE.SELECT_STATEMENT',
        WHICH_TO_INCLUDE: 'TEMPLATE.WHICH_TO_INCLUDE',
    },
    TEMPLATES: 'TEMPLATES',
    TEMPLATE_EDITION: 'TEMPLATE_EDITION',
    TEMPLATE_NAME: 'TEMPLATE_NAME',
    TEMPLATE_TYPE: 'TEMPLATE_TYPE',
    TEMPLATE_TYPES: {
        ADDITIONAL_REMINDER: 'TEMPLATE_TYPES.ADDITIONAL_REMINDER',
        CREDIT_NOTE: 'TEMPLATE_TYPES.CREDIT_NOTE',
        FIRST_REMINDER: 'TEMPLATE_TYPES.FIRST_REMINDER',
        FORMAL_NOTICE: 'TEMPLATE_TYPES.FORMAL_NOTICE',
        INTERMEDIATE_REMINDER: 'TEMPLATE_TYPES.INTERMEDIATE_REMINDER',
        INVOICE: 'TEMPLATE_TYPES.INVOICE',
        LAST_REMINDER: 'TEMPLATE_TYPES.LAST_REMINDER',
        PLAN_CANCEL: 'TEMPLATE_TYPES.PLAN_CANCEL',
        PLAN_CREATION: 'TEMPLATE_TYPES.PLAN_CREATION',
        PLAN_UPDATE: 'TEMPLATE_TYPES.PLAN_UPDATE',
        PREVENTIVE_REMINDER: 'TEMPLATE_TYPES.PREVENTIVE_REMINDER',
    },
    TEMPLATE_WITH_LATE_FEES: 'TEMPLATE_WITH_LATE_FEES',
    TEST_PERIOD: 'TEST_PERIOD',
    THE_DATE: 'THE_DATE',
    THIRD_PARTY_CASES: {
        CASE_TYPE: {
            BAILIFF: 'THIRD_PARTY_CASES.CASE_TYPE.BAILIFF',
            CALLCENTER: 'THIRD_PARTY_CASES.CASE_TYPE.CALLCENTER',
            DEBT_COLLECTOR: 'THIRD_PARTY_CASES.CASE_TYPE.DEBT_COLLECTOR',
            LAWYER: 'THIRD_PARTY_CASES.CASE_TYPE.LAWYER',
        },
        CLOSED: 'THIRD_PARTY_CASES.CLOSED',
        CONVERSATION: 'THIRD_PARTY_CASES.CONVERSATION',
        CURRENT: 'THIRD_PARTY_CASES.CURRENT',
        CURRENTLY_PROCESSED: 'THIRD_PARTY_CASES.CURRENTLY_PROCESSED',
        CURRENT_CASES: 'THIRD_PARTY_CASES.CURRENT_CASES',
        DESCRIPTIONS: {
            BAILIFF: {
                FIRST: 'THIRD_PARTY_CASES.DESCRIPTIONS.BAILIFF.FIRST',
                SECOND: 'THIRD_PARTY_CASES.DESCRIPTIONS.BAILIFF.SECOND',
            },
            CALLCENTER: {
                FIRST: 'THIRD_PARTY_CASES.DESCRIPTIONS.CALLCENTER.FIRST',
                SECOND: 'THIRD_PARTY_CASES.DESCRIPTIONS.CALLCENTER.SECOND',
            },
            DEBTCOLLECTOR: {
                FIRST: 'THIRD_PARTY_CASES.DESCRIPTIONS.DEBTCOLLECTOR.FIRST',
                SECOND: 'THIRD_PARTY_CASES.DESCRIPTIONS.DEBTCOLLECTOR.SECOND',
            },
            LAWYER: {
                FIRST: 'THIRD_PARTY_CASES.DESCRIPTIONS.LAWYER.FIRST',
                SECOND: 'THIRD_PARTY_CASES.DESCRIPTIONS.LAWYER.SECOND',
            },
            WHAT: 'THIRD_PARTY_CASES.DESCRIPTIONS.WHAT',
            WHEN: 'THIRD_PARTY_CASES.DESCRIPTIONS.WHEN',
        },
        INVOICES_OF_CASE: {
            MANY: 'THIRD_PARTY_CASES.INVOICES_OF_CASE.MANY',
            ONE: 'THIRD_PARTY_CASES.INVOICES_OF_CASE.ONE',
        },
        MODAL_TITLE: 'THIRD_PARTY_CASES.MODAL_TITLE',
        NOT_COMMUNICATED: 'THIRD_PARTY_CASES.NOT_COMMUNICATED',
        NOT_STARTED: 'THIRD_PARTY_CASES.NOT_STARTED',
        REFERENCE: 'THIRD_PARTY_CASES.REFERENCE',
        STARTED: 'THIRD_PARTY_CASES.STARTED',
        THIRD_PARTY_CASES_LIST: 'THIRD_PARTY_CASES.THIRD_PARTY_CASES_LIST',
        ONE: 'THIRD_PARTY_CASES.ONE',
        MANY: 'THIRD_PARTY_CASES.MANY',
    },
    TIMELINE: {
        ACTIVITY: 'TIMELINE.ACTIVITY',
        DEBTOR_REACTION: 'TIMELINE.DEBTOR_REACTION',
        EXTERNAL_MAIL: 'TIMELINE.EXTERNAL_MAIL',
    },
    TO: 'TO',
    TOOLTIP: {
        CAN_BE_INCLUDED_IN_PAYMENT_PLAN: 'TOOLTIP.CAN_BE_INCLUDED_IN_PAYMENT_PLAN',
        CAN_NOT_BE_INCLUDED_IN_PAYMENT_PLAN: 'TOOLTIP.CAN_NOT_BE_INCLUDED_IN_PAYMENT_PLAN',
        IMMINENT_REMINDER_INVOICES: 'TOOLTIP.IMMINENT_REMINDER_INVOICES',
    },
    TOO_LONG_LOADING: 'TOO_LONG_LOADING',
    TOTAL: 'TOTAL',
    TOTAL_NOT_VAT_PRICE: 'TOTAL_NOT_VAT_PRICE',
    TOTAL_VAT_PRICE: 'TOTAL_VAT_PRICE',
    TUTORIAL: {
        CUSTOMIZE_REMINDER: {
            ITEM_1: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_1',
            ITEM_10: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_10',
            ITEM_11: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_11',
            ITEM_2: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_2',
            ITEM_3: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_3',
            ITEM_4: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_4',
            ITEM_5: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_5',
            ITEM_6: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_6',
            ITEM_7: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_7',
            ITEM_8: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_8',
            ITEM_9: 'TUTORIAL.CUSTOMIZE_REMINDER.ITEM_9',
            READY: 'TUTORIAL.CUSTOMIZE_REMINDER.READY',
            TITLE: 'TUTORIAL.CUSTOMIZE_REMINDER.TITLE',
        },
        DESCRIPTION: 'TUTORIAL.DESCRIPTION',
        FIRST_REMINDER_SENT_BY: 'TUTORIAL.FIRST_REMINDER_SENT_BY',
        INTRODUCTION: 'TUTORIAL.INTRODUCTION',
        NEW_INVOICE_CREATED: 'TUTORIAL.NEW_INVOICE_CREATED',
        NO_AUTOMATIC_ACTION_PLANNED: 'TUTORIAL.NO_AUTOMATIC_ACTION_PLANNED',
        RECOVER_ACTION: {
            ITEM_1: 'TUTORIAL.RECOVER_ACTION.ITEM_1',
            ITEM_10: 'TUTORIAL.RECOVER_ACTION.ITEM_10',
            ITEM_11: 'TUTORIAL.RECOVER_ACTION.ITEM_11',
            ITEM_12: 'TUTORIAL.RECOVER_ACTION.ITEM_12',
            ITEM_13: 'TUTORIAL.RECOVER_ACTION.ITEM_13',
            ITEM_14: 'TUTORIAL.RECOVER_ACTION.ITEM_14',
            ITEM_15: 'TUTORIAL.RECOVER_ACTION.ITEM_15',
            ITEM_16: 'TUTORIAL.RECOVER_ACTION.ITEM_16',
            ITEM_17: 'TUTORIAL.RECOVER_ACTION.ITEM_17',
            ITEM_18: 'TUTORIAL.RECOVER_ACTION.ITEM_18',
            ITEM_2: 'TUTORIAL.RECOVER_ACTION.ITEM_2',
            ITEM_3: 'TUTORIAL.RECOVER_ACTION.ITEM_3',
            ITEM_4: 'TUTORIAL.RECOVER_ACTION.ITEM_4',
            ITEM_5: 'TUTORIAL.RECOVER_ACTION.ITEM_5',
            ITEM_6: 'TUTORIAL.RECOVER_ACTION.ITEM_6',
            ITEM_7: 'TUTORIAL.RECOVER_ACTION.ITEM_7',
            ITEM_8: 'TUTORIAL.RECOVER_ACTION.ITEM_8',
            ITEM_9: 'TUTORIAL.RECOVER_ACTION.ITEM_9',
            READY: 'TUTORIAL.RECOVER_ACTION.READY',
            TITLE: 'TUTORIAL.RECOVER_ACTION.TITLE',
        },
        SECOND_REMINDER_SENT: 'TUTORIAL.SECOND_REMINDER_SENT',
        SECOND_REMINDER_SENT_BY: 'TUTORIAL.SECOND_REMINDER_SENT_BY',
        SHOW_TUTORIAL: {
            ITEM_0: 'TUTORIAL.SHOW_TUTORIAL.ITEM_0',
        },
        TUTORIAL: 'TUTORIAL.TUTORIAL',
        UNDERSTOOD: 'TUTORIAL.UNDERSTOOD',
    },
    TYPE_COLUMN: 'TYPE_COLUMN',
    UNAVAILABLE_WITH_SCREEN_SIZE: 'UNAVAILABLE_WITH_SCREEN_SIZE',
    UNPAIDS: 'UNPAIDS',
    UNUSED: 'UNUSED',
    UPDATE_DATE: 'UPDATE_DATE',
    USED: 'USED',
    USED_DEFAULT: 'USED_DEFAULT',
    VALUE: 'VALUE',
    VARIABLES: 'VARIABLES',
    VAT: 'VAT',
    VAT_NUMBER: 'VAT_NUMBER',
    VAT_NUMBER_MISSING: 'VAT_NUMBER_MISSING',
    VIEWS: {
        FILTERS: {
            CATEGORY: {
                CUSTOM_DATA: 'VIEWS.FILTERS.CATEGORY.CUSTOM_DATA',
                DATE_DATA: 'VIEWS.FILTERS.CATEGORY.DATE_DATA',
                DEBTOR_DATA: 'VIEWS.FILTERS.CATEGORY.DEBTOR_DATA',
                INVOICE_DATA: 'VIEWS.FILTERS.CATEGORY.INVOICE_DATA',
                MOST_USED: 'VIEWS.FILTERS.CATEGORY.MOST_USED',
                RECOVERY_DATA: 'VIEWS.FILTERS.CATEGORY.RECOVERY_DATA',
                TASK_DATA: 'VIEWS.FILTERS.CATEGORY.TASK_DATA',
            },
            EMPTY_DATA: 'VIEWS.FILTERS.EMPTY_DATA',
            FILL_DATA: 'VIEWS.FILTERS.FILL_DATA',
        },
        SETTINGS: {
            COLUMNS: 'VIEWS.SETTINGS.COLUMNS',
            CONFIRM_DELETE: 'VIEWS.SETTINGS.CONFIRM_DELETE',
            DEFAULT_LABEL: 'VIEWS.SETTINGS.DEFAULT_LABEL',
            DUPLICATE: 'VIEWS.SETTINGS.DUPLICATE',
            FILTERS: 'VIEWS.SETTINGS.FILTERS',
            MAKE_FAVORITE: 'VIEWS.SETTINGS.MAKE_FAVORITE',
            NEW: 'VIEWS.SETTINGS.NEW',
            PUBLIC: 'VIEWS.SETTINGS.PUBLIC',
            TITLE: 'VIEWS.SETTINGS.TITLE',
        },
    },
    VIEW_NAME: 'VIEW_NAME',
    VIEW_UNASSIGNED_EMAILS: 'VIEW_UNASSIGNED_EMAILS',
    WELCOME: 'WELCOME',
    WELCOME_API: 'WELCOME_API',
    WILL_BE_MERGED: {
        FINAL_DATA: 'WILL_BE_MERGED.FINAL_DATA',
        MODELS_MERGED: 'WILL_BE_MERGED.MODELS_MERGED',
        OF_OTHER_CLIENT: 'WILL_BE_MERGED.OF_OTHER_CLIENT',
        SINGLE: 'WILL_BE_MERGED.SINGLE',
        WILL_BE_ASSOCIATED_TO: 'WILL_BE_MERGED.WILL_BE_ASSOCIATED_TO',
    },
    WILL_BE_SENT: {
        EMAIL: 'WILL_BE_SENT.EMAIL',
        MULTIPLE: 'WILL_BE_SENT.MULTIPLE',
        POST: 'WILL_BE_SENT.POST',
        REGISTERED_POST: 'WILL_BE_SENT.REGISTERED_POST',
        SINGLE: 'WILL_BE_SENT.SINGLE',
        SMS: 'WILL_BE_SENT.SMS',
    },
    WITHOUT_FEES: 'WITHOUT_FEES',
    WITH_FEES: 'WITH_FEES',
    WITH_LATE_FEES: 'WITH_LATE_FEES',
    WORKFLOW: {
        ADD: {
            PAYMENT: 'WORKFLOW.ADD.PAYMENT',
        },
        EDIT: {
            PAYMENT: 'WORKFLOW.EDIT.PAYMENT',
        },
    },
    YEAR: 'YEAR',
    YES: 'YES',
    YOUR_ACCOUNT: 'YOUR_ACCOUNT',
    YOUR_EMAIL: 'YOUR_EMAIL',
};
