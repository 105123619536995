import { __assign, __read, __spreadArray, __values } from "tslib";
import { remove } from 'lodash-es';
import moment from 'moment';
import { captureMessage } from '@sentry/react';
function parseAsDateOrForwardValue(key, value) {
    var _a, _b;
    /* Leo on 2021-03-04: It's unclear to me whether this function is actually useful but I won't
    risk breaking something deep in the app by removing it as it's used a lot in mutation calls.
    We capture a message when we do actually parse a date so we can see if this is ever useful.
    Since so far the only field it attempts to parse is a false positive, I'm adding an exclusion array
    to at least silence the moment.js warning about being fed bad data.
    */
    var excludedKeys = ['currency_conversion_date'];
    var couldBeDate = typeof value === 'string' && key.includes('date') && !excludedKeys.includes(key);
    if (!couldBeDate)
        return value;
    var maybeDate = moment.utc(value);
    if (maybeDate.isValid())
        return maybeDate;
    var message = "In store/reducers.ts, expected to parse the following as date but failed.\n  This could be normal but should be used to narrow date casting rules";
    console.warn(message, (_a = {}, _a[key] = value, _a));
    captureMessage("".concat(message, ": ").concat(JSON.stringify((_b = {}, _b[key] = value, _b))));
    return value;
}
var getStateData = function (state, name) { var _a; return (!((_a = state[name]) === null || _a === void 0 ? void 0 : _a.data) ? false : state[name].data.slice()); };
// return true if same
var compareSearch = function (search1, search2) {
    var elem1 = __assign(__assign({}, search1), { page: undefined });
    var elem2 = __assign(__assign({}, search2), { page: undefined });
    return JSON.stringify(elem1) === JSON.stringify(elem2);
};
export var compareItems = function (item1, item2, key) {
    return transformValue(item1, key) === transformValue(item2, key);
};
var transformValue = function (item, key) {
    var itemValue;
    var label = key || 'value';
    if (item && item[label] !== undefined)
        itemValue = item[label];
    else
        itemValue = item;
    if (itemValue === undefined)
        return undefined;
    itemValue = !isNaN(itemValue) ? itemValue.toString() : itemValue;
    return itemValue;
};
function treatData(data, transformFunc) {
    if (transformFunc == null)
        return data;
    // Pass the item explicitly to avoid all the extra arguments that map passes being spread to transformFunc
    if (Array.isArray(data))
        return data.map(function (item) { return transformFunc(item); });
    return transformFunc(data);
}
function handleDefaultEndRequest(state, name) {
    var _a;
    return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { loaded: true, loading: false }), _a));
}
export function handleRequest(state, _a, name, reset) {
    var _b;
    var payload = _a.payload;
    var noReset = payload === null || payload === void 0 ? void 0 : payload.noReset;
    if (!noReset) {
        noReset = !reset;
    }
    var newElem = noReset
        ? __assign(__assign({}, state[name]), { loaded: false, loading: true }) : {
        loaded: false,
        loading: true,
    };
    return __assign(__assign({}, state), (_b = {}, _b[name] = newElem, _b));
}
export var handlePageRequest = function (state, action, name) {
    var _a;
    var payload = action.payload || {};
    var currentSearch = state[name] && state[name].search;
    var reset = !compareSearch(currentSearch, payload);
    var tmpState = handleRequest(state, action, name, payload.reset === false ? false : reset);
    return __assign(__assign({}, tmpState), (_a = {}, _a[name] = __assign(__assign({}, tmpState[name]), { search: payload, currentPage: (payload && payload.page) || 1 }), _a));
};
/**
 * Setter
 */
export function handleResponse(state, action, name, transformFunc) {
    var _a;
    var payload = action.payload, status = action.status, requestData = action.requestData;
    if (payload && status === 'fulfilled') {
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign(__assign({}, state[name]), payload), { requestData: requestData, data: treatData(payload.data, transformFunc), loaded: true, loading: false }), _a));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleReset(state, action, name) {
    var _a;
    return __assign(__assign({}, state), (_a = {}, _a[name] = null, _a));
}
export function handlePageResponse(state, _a, name, transformFunc, options) {
    var _b;
    var payload = _a.payload, status = _a.status, reset = _a.reset;
    var infiniteScroll = (options || {}).infiniteScroll;
    if (!payload)
        return handleDefaultEndRequest(state, name);
    var data = payload.data, metadata = payload.metadata;
    if (data && status === 'fulfilled') {
        var items = infiniteScroll
            ? {
                data: __spreadArray(__spreadArray([], __read((reset ? [] : state[name].data || [])), false), __read(treatData(data, transformFunc)), false),
            }
            : {
                pages: treatData(data, transformFunc),
            };
        return __assign(__assign({}, state), (_b = {}, _b[name] = __assign(__assign(__assign({}, state[name]), items), { metadata: __assign({}, metadata), loaded: true, loading: false }), _b));
    }
    return handleDefaultEndRequest(state, name);
}
/**
 * Any add response (list or unit)
 */
export function handleAddResponse(state, action, name, transformFunc) {
    var _a;
    var _b = action.payload, data = _b.data, metadata = _b.metadata, status = action.status;
    var list = getStateData(state, name);
    if (!list)
        return handleDefaultEndRequest(state, name);
    if (data && status === 'fulfilled') {
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { metadata: metadata, data: __spreadArray([], __read(list.concat(treatData(data, transformFunc))), false), loaded: true, loading: false }), _a));
    }
    // PAGES MODE
    return handleDefaultEndRequest(state, name);
}
export function handleUpdateMultipleResponse(state, action, name, transformFunc) {
    var e_1, _a, _b;
    var data = action.payload.data, status = action.status;
    if (data && status === 'fulfilled') {
        var list = getStateData(state, name);
        if (!list)
            return handleDefaultEndRequest(state, name);
        var _loop_1 = function (item) {
            var index = list.findIndex(function (listItem) { return listItem.id === item.id; });
            list.splice(index, 1, treatData(item, transformFunc));
        };
        try {
            for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                var item = data_1_1.value;
                _loop_1(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return __assign(__assign({}, state), (_b = {}, _b[name] = __assign(__assign({}, state[name]), { data: list, loaded: true, loading: false }), _b));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleUpdateResponse(state, action, name, transformFunc) {
    var _a;
    var data = action.payload.data, status = action.status;
    if (data && status === 'fulfilled') {
        var list = getStateData(state, name);
        if (!list)
            return handleDefaultEndRequest(state, name);
        var index = list.findIndex(function (item) { return item.id === data.id; });
        list.splice(index, 1, treatData(data, transformFunc));
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { data: list, loaded: true, loading: false }), _a));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleUpdatePaginationResponse(state, action, name, transformFunc) {
    var _a;
    var _b;
    var data = action.payload.data, status = action.status;
    if (data && status === 'fulfilled') {
        var pages = (_b = state[name]) === null || _b === void 0 ? void 0 : _b.pages;
        if (!pages)
            return handleDefaultEndRequest(state, name);
        var index = pages.findIndex(function (item) { return item.id === data.id; });
        pages.splice(index, 1, treatData(data, transformFunc));
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { pages: __spreadArray([], __read(pages), false), loaded: true, loading: false }), _a));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleUpdateMultiplePaginationResponse(state, action, name, transformFunc) {
    var e_2, _a, _b;
    var _c;
    var data = action.payload.data, status = action.status;
    if (data && status === 'fulfilled') {
        var pages = (_c = state[name]) === null || _c === void 0 ? void 0 : _c.pages;
        if (!pages)
            return handleDefaultEndRequest(state, name);
        var _loop_2 = function (item) {
            var index = pages.findIndex(function (listItem) { return listItem.id === item.id; });
            pages.splice(index, 1, treatData(item, transformFunc));
        };
        try {
            for (var data_2 = __values(data), data_2_1 = data_2.next(); !data_2_1.done; data_2_1 = data_2.next()) {
                var item = data_2_1.value;
                _loop_2(item);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (data_2_1 && !data_2_1.done && (_a = data_2.return)) _a.call(data_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return __assign(__assign({}, state), (_b = {}, _b[name] = __assign(__assign({}, state[name]), { pages: __spreadArray([], __read(pages), false), loaded: true, loading: false }), _b));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleDeleteResponse(state, action, name) {
    var _a;
    var payload = action.payload, status = action.status;
    if (payload && status === 'fulfilled') {
        var list = getStateData(state, name);
        if (!list)
            return handleDefaultEndRequest(state, name);
        remove(list, function (item) { return item.id === payload; });
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { data: list, loaded: true, loading: false }), _a));
    }
    return handleDefaultEndRequest(state, name);
}
export function handleDeletePaginationResponse(state, action, name) {
    var _a;
    var _b;
    var payload = action.payload, status = action.status;
    if (payload && status === 'fulfilled') {
        var pages = (_b = state[name]) === null || _b === void 0 ? void 0 : _b.pages;
        if (!pages)
            return handleDefaultEndRequest(state, name);
        remove(pages, function (item) { return item.id === payload; });
        return __assign(__assign({}, state), (_a = {}, _a[name] = __assign(__assign({}, state[name]), { pages: __spreadArray([], __read(pages), false), loaded: true, loading: false }), _a));
    }
    return handleDefaultEndRequest(state, name);
}
export function flattenItem(item, keysToExclude, // Keys to remove from the result if item is an object
keysToPreserve) {
    if (keysToExclude === void 0) { keysToExclude = []; }
    if (keysToPreserve === void 0) { keysToPreserve = []; }
    // Return primitives as is
    if (['boolean', 'number', 'function', 'string', 'undefined'].includes(typeof item))
        return item;
    // Remaining possible type is object, which includes array and null, take care of those
    if (Array.isArray(item))
        return item;
    if (item == null)
        return item;
    // item should now be object
    if (typeof item !== 'object')
        console.warn("".concat(item, " expected to be object but isn't"));
    var objectItem = item;
    var flattenedItem = {
        id: objectItem.id,
        type: objectItem.type,
    };
    objectItem = (objectItem.attributes || objectItem);
    Object.entries(objectItem).forEach(function (_a) {
        var e_3, _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (keysToExclude.includes(key))
            return;
        if (keysToPreserve.includes(key))
            flattenedItem[key] = value;
        if (Array.isArray(value)) {
            var flattenedArray = [];
            try {
                for (var value_1 = __values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var element = value_1_1.value;
                    var flattened = flattenItem(element);
                    flattenedArray.push(flattened);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_b = value_1.return)) _b.call(value_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
            flattenedItem["".concat(key, "_attributes")] = flattenedArray;
            return;
        }
        // extra check for null because typeof null === 'object' sadly
        if (typeof value === 'object' && value != null && 'attributes' in value) {
            flattenedItem["".concat(key, "_attributes")] = flattenItem(value);
            return;
        }
        var parsedValue = parseAsDateOrForwardValue(key, value);
        flattenedItem[key] = parsedValue;
    });
    return flattenedItem;
}
export function addDataAction(action) {
    return __assign(__assign({}, action), { payload: {
            data: action.payload,
        } });
}
export var baseReducerInfinitePage = {
    currentPage: 0,
    data: [],
    loaded: false,
    loading: false,
    metadata: {
        pagination: {
            current_page: 0,
            last_page: 0,
            page_limit: 0,
            total_objects: 0,
        },
        total: 0,
    },
    search: '',
};
export var baseReducerListPage = __assign(__assign({}, baseReducerInfinitePage), { pages: [] });
export var baseReducerData = {
    data: null,
    loading: false,
    loaded: false,
};
