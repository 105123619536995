import React from 'react';
import { useCreateContactPerson } from 'api';
import { ContactPersonForm } from 'app/Private/ContactPersons';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './AddContactPersonButton.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddContactPersonButton(_a) {
    var refetch = _a.refetch, debtorId = _a.debtorId, withMargin = _a.withMargin;
    var t = useTranslation().t;
    var createContactPersonMutation = useCreateContactPerson().mutate;
    var addContactPerson = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.CONTACT_PERSON.ADD),
            children: (React.createElement(ContactPersonForm, { inModal: true, onSubmit: function (cpData) {
                    return createContactPersonMutation({ id: debtorId, data: cpData }, {
                        onSuccess: function () {
                            refetch();
                            dialogHide(DialogShowId.CONFIRM);
                        },
                    });
                } })),
        });
    };
    return (React.createElement("div", { className: styles('add-contact-person', withMargin && 'with-margin'), onClick: addContactPerson },
        React.createElement(Icon, { name: IconName.PLUS }),
        " ",
        React.createElement("p", null, t(i18nKeys.ADD_CONTACT_PERSON))));
}
