import { __makeTemplateObject, __read } from "tslib";
import React, { useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { i18nKeys, useTranslation } from 'locales';
import { Document, Page } from 'react-pdf';
import { css } from '@emotion/css';
import { Box, Card, Center, Pagination, Tabs } from '@mantine/core';
import HTML from '../../../HTML/HTML';
var styles = {
    emailFix: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-align: left;\n    padding: 2em;\n  "], ["\n    text-align: left;\n    padding: 2em;\n  "]))),
    paginationWrapper: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: sticky;\n    bottom: 30px;\n    display: flex;\n    justify-content: center;\n\n    .mantine-Pagination-control {\n      box-shadow: var(--mantine-shadow-xs);\n    }\n  "], ["\n    position: sticky;\n    bottom: 30px;\n    display: flex;\n    justify-content: center;\n\n    .mantine-Pagination-control {\n      box-shadow: var(--mantine-shadow-xs);\n    }\n  "]))),
};
export var ReminderPreview = function (_a) {
    var pdf = _a.pdf, email = _a.email;
    var t = useTranslation().t;
    var _b = __read(useState(1), 2), pageCount = _b[0], setPageCount = _b[1];
    var _c = __read(useState(1), 2), currentPage = _c[0], setCurrentPage = _c[1];
    var blob = pdf && base64StringToBlob(pdf, 'application/pdf');
    return (React.createElement(Tabs, { defaultValue: email ? 'email' : 'pdf', mb: "xl" },
        React.createElement(Tabs.List, { my: "lg" },
            email && React.createElement(Tabs.Tab, { value: "email" }, t(i18nKeys.EMAIL)),
            pdf && React.createElement(Tabs.Tab, { value: "pdf" }, t(i18nKeys.POST))),
        email && (React.createElement(Tabs.Panel, { value: "email" },
            React.createElement(Center, null,
                React.createElement(Card, { withBorder: true, w: 900 },
                    React.createElement(HTML, { className: styles.emailFix, html: email }))))),
        pdf && (React.createElement(Tabs.Panel, { value: "pdf" },
            React.createElement(Center, null,
                React.createElement(Card, { withBorder: true },
                    React.createElement(Document, { file: blob, onLoadSuccess: function (pdfObject) { return setPageCount(pdfObject._pdfInfo.numPages); } },
                        React.createElement(Page, { renderAnnotationLayer: false, renderTextLayer: false, width: 900, pageNumber: currentPage })))),
            pageCount > 1 && (React.createElement(Box, { mt: "lg", className: styles.paginationWrapper },
                React.createElement(Pagination, { size: "xl", total: pageCount, value: currentPage, onChange: setCurrentPage })))))));
};
var templateObject_1, templateObject_2;
