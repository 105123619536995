var _a, _b;
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { EmailDeliveryStatus } from 'types/store/activity';
import { styled } from '@stitches/react';
import { Icon, IconName } from '../Icon';
var PRECEDENCE = [
    EmailDeliveryStatus.BOUNCE,
    EmailDeliveryStatus.DROPPED,
    EmailDeliveryStatus.CLICK,
    EmailDeliveryStatus.OPEN,
    EmailDeliveryStatus.DELIVERED,
    EmailDeliveryStatus.PROCESSED,
];
/**
 * Returns the highest precedence status and the corresponding timestamp.
 */
function getHighestPrecedenceStatus(statuses) {
    var availableKeys = Object.keys(statuses);
    var sortedKeys = availableKeys.sort(function (a, b) { return PRECEDENCE.indexOf(a) - PRECEDENCE.indexOf(b); });
    var highestPrecedenceKey = sortedKeys[0];
    var correspondingDates = statuses[highestPrecedenceKey];
    var correspondingDatesParsed = correspondingDates.map(function (date) { return moment(date); });
    return {
        status: highestPrecedenceKey,
        timestamp: moment.max(correspondingDatesParsed),
    };
}
var Status = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '5px;',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    variants: {
        status: (_a = {},
            _a[EmailDeliveryStatus.PROCESSED] = {
                color: '#ebac2f',
            },
            _a[EmailDeliveryStatus.DELIVERED] = {
                color: '#ebac2f',
            },
            _a[EmailDeliveryStatus.OPEN] = {
                color: '#12c8ac',
            },
            _a[EmailDeliveryStatus.CLICK] = {
                color: '#12c8ac',
            },
            _a[EmailDeliveryStatus.BOUNCE] = {
                color: '#ff7373',
            },
            _a[EmailDeliveryStatus.DROPPED] = {
                color: '#ff7373',
            },
            _a),
    },
});
var TimeStamp = styled('div', {
    fontSize: '0.7rem',
    color: '#bbb',
    fontStyle: 'italic',
    fontWeight: 'unset',
});
var ICON_FOR_STATUS = (_b = {},
    _b[EmailDeliveryStatus.PROCESSED] = IconName.LATER,
    _b[EmailDeliveryStatus.DELIVERED] = IconName.CHECK,
    _b[EmailDeliveryStatus.OPEN] = IconName.EYE,
    _b[EmailDeliveryStatus.CLICK] = IconName.CHECK,
    _b[EmailDeliveryStatus.BOUNCE] = IconName.ALERT_CIRCLE,
    _b[EmailDeliveryStatus.DROPPED] = IconName.ALERT_CIRCLE,
    _b);
export var EmailDeliveryStatusCompact = function (_a) {
    var statuses = _a.statuses;
    var t = useTranslation().t;
    var _b = getHighestPrecedenceStatus(statuses), status = _b.status, timestamp = _b.timestamp;
    var canUseEmailStatus = useSelector(function (state) { return state.account.company.data.package; }).can_use_emails_activity;
    if (!canUseEmailStatus)
        return null;
    return (React.createElement(Status, { status: status },
        React.createElement(Icon, { name: ICON_FOR_STATUS[status] }),
        " ",
        t(i18nKeys.MAILS.STATUS[status.toUpperCase()]),
        status !== EmailDeliveryStatus.BOUNCE && React.createElement(TimeStamp, null, timestamp.format('L'))));
};
