import { __assign, __rest } from "tslib";
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import { debounce } from 'lodash-es';
import { validationMessage } from 'shared/utils/validation';
import styleIdentifiers from './Input.scss';
var styles = classNames.bind(styleIdentifiers);
export var Input = function (_a) {
    var _b;
    var className = _a.className, errorClassName = _a.errorClassName, errorMessage = _a.errorMessage, inputClassName = _a.inputClassName, inputRef = _a.inputRef, label = _a.label, noBorder = _a.noBorder, noError = _a.noError, noErrorText = _a.noErrorText, noMargin = _a.noMargin, onChange = _a.onChange, onFocus = _a.onFocus, onValueChanged = _a.onValueChanged, quickSearch = _a.quickSearch, register = _a.register, shadow = _a.shadow, _c = _a.timeout, timeout = _c === void 0 ? 0 : _c, withBorder = _a.withBorder, rest = __rest(_a, ["className", "errorClassName", "errorMessage", "inputClassName", "inputRef", "label", "noBorder", "noError", "noErrorText", "noMargin", "onChange", "onFocus", "onValueChanged", "quickSearch", "register", "shadow", "timeout", "withBorder"]);
    var t = useTranslation().t;
    var handleInputFocus = function (event) {
        if (onFocus) {
            onFocus(event);
        }
    };
    var debouncedValue = useRef(debounce(function (e) {
        register === null || register === void 0 ? void 0 : register.onChange(e);
        if (onValueChanged) {
            onValueChanged(e.target.value);
        }
    }, timeout)).current;
    useEffect(function () {
        return function () {
            debouncedValue.cancel();
        };
    }, [debouncedValue]);
    var onSetValue = function (e) {
        if (timeout) {
            e.persist();
            debouncedValue(e);
        }
        else {
            register === null || register === void 0 ? void 0 : register.onChange(e);
            if (onValueChanged) {
                onValueChanged(e.target.value);
            }
        }
    };
    var showError = !noError && errorMessage;
    // ? The component was built to receive translation keys as the value of incorrectly named errorMessage.message;
    // ? In some cases, we may want to display some field errors that come translated from the backend.
    // ? To achieve this, we try to translate the error message, if it fails, we display the message as it is.
    // ? This is a limitation of react hook form, which only allows us to conform to its builtin FieldError type
    // ? and prevents us form adding, say, a `translatedError` property that we would display as is.
    // ? N.B.: If a key missing a translation is passed, the key itself will be displayed and no error will be raised.
    // ? This is relatively low risk though thanks to the finite amount of error keys that can reach here in the first place
    // ? Paths to improvement:
    // ? 1. Overload the FieldError type to add a `translatedError` property in a d.ts file
    // ? 2. Mantine
    var finalErrorMessage = '';
    try {
        finalErrorMessage = t(validationMessage[errorMessage.type] ||
            errorMessage.message ||
            validationMessage.invalid);
    }
    catch (_d) {
        finalErrorMessage = (_b = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.message) !== null && _b !== void 0 ? _b : '';
    }
    return (React.createElement("div", { className: styles('Input', noMargin && 'no-margin', className, shadow && 'shadow', quickSearch && 'with-small-border', withBorder && 'with-border', showError && 'error') },
        React.createElement("div", { className: styles('input-wrapper', !label && 'no-label', showError && 'error', showError && errorClassName) },
            label && React.createElement("div", { className: styles('label-input') }, label),
            React.createElement("input", __assign({ className: styles(inputClassName, !noBorder && 'bordered'), type: rest.type || 'text', onFocus: handleInputFocus, ref: inputRef }, rest, register, { onChange: onSetValue, "data-quick-search": quickSearch ? '1' : '0' }))),
        showError && !noErrorText && (React.createElement("div", { className: styles('error-message') }, finalErrorMessage))));
};
