import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'qs';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ListResModal from 'shared/components/DeleteModal';
import { HideIf } from 'shared/components/HideIf';
import { IconName } from 'shared/components/Icon';
import { Button, Dropdown } from 'shared/io';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import { ExportCreditNoteCsv } from './ExportCreditNoteCsv';
import styleIdentifiers from './CreditNoteListing.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CreditNoteListing(_a) {
    var _b;
    var history = _a.history;
    var creditNoteData = useSelector(function (state) { return state.creditNote.list; });
    var t = useTranslation().t;
    var _c = __read(useState([]), 2), selectedCreditNotes = _c[0], setSelectedCreditNotes = _c[1];
    var _d = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _d[0], setAreAllItemsAcrossPagesSelected = _d[1];
    var handleFilters = function (filters) {
        if (filters.debtor) {
            filters.debtor = undefined;
        }
        var newFilters = __assign({}, filters);
        // need to be linked
        if ((newFilters.total_tvac_operator && !newFilters.total_tvac) ||
            (!newFilters.total_tvac_operator && newFilters.total_tvac))
            return false;
        // need to be linked
        if ((newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
            (!newFilters.remaining_balance_operator && newFilters.remaining_balance))
            return false;
        return newFilters;
    };
    var newCreditNote = function () {
        creditNoteActions.detailReset();
        history.push('/credit-notes/create');
    };
    var data = creditNoteData || {};
    var exportCsv = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.EXPORT_CSV),
            children: React.createElement(ExportCreditNoteCsv, null),
        });
    };
    var batchAction = function (action_type, action) { return function () {
        sideMenuHide();
        var data = {};
        if (areAllItemsAcrossPagesSelected) {
            data.filter_params = queryParamParser(qs.parse(location.search));
            data.view_id = location.hash.slice(1);
        }
        else {
            data.credit_notes_ids = selectedCreditNotes.map(function (invoice) { return invoice.id; });
        }
        action(function (values) {
            creditNoteActions.batchAction({
                data: __assign(__assign(__assign({}, data), values), { action_type: action_type }),
                callback: function (data) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: data }),
                    });
                    reloadCustomView(creditNoteActions.listPageRes);
                },
            });
        });
    }; };
    var aysModal = function (action_type) { return function (onSubmit) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(selectedCreditNotes.length === 1 && !areAllItemsAcrossPagesSelected
                    ? i18nKeys.AYS["CN_ACTION_".concat(action_type.toUpperCase())]
                    : i18nKeys.AYS["CNS_ACTION_".concat(action_type.toUpperCase())]), onConfirm: function () { return onSubmit({}); } })),
        });
    }; };
    var showCreditNote = function (item) { return function () {
        history.push("/credit-notes/".concat(item.id));
    }; };
    return (React.createElement("div", { className: styles('Listing') },
        React.createElement(CustomTableViews, { title: t(i18nKeys.INVOICING.CREDIT_NOTE_BOOK), tableName: "credit_notes", onClickRow: showCreditNote, actions: selectedCreditNotes.length > 0 ? (React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), selectorContent: React.createElement(Button, { noMargin: true, iconRight: IconName.ARROW_BOTTOM_ROUNDED, label: t(i18nKeys.FORM.ACTIONS) }) },
                React.createElement("div", { className: styles('dropdown-item'), onClick: batchAction('settle', aysModal('settle')) }, t(i18nKeys.INVOICE.SETTLE)),
                React.createElement("div", { className: styles('dropdown-item'), onClick: batchAction('delete', aysModal('delete')) }, t(i18nKeys.DELETE)))) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.EXPORT_CSV), onClick: exportCsv, className: styles('action-button') }),
                React.createElement(HideIf, { connectorActive: true, isFrench: true },
                    React.createElement(Button, { noMargin: true, className: styles('action-button'), label: t(i18nKeys.INVOICING.ADD_CREDIT_NOTE), onClick: newCreditNote, iconRight: IconName.MINIMAL_RIGHT })))), isLoading: !data.loaded, pagination: (_b = data.metadata) === null || _b === void 0 ? void 0 : _b.pagination, handleFilters: handleFilters, items: data.pages || [], currentPage: data.currentPage, onChangeSelectionCallback: function (values) { return setSelectedCreditNotes(values); }, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, callbackAction: creditNoteActions.listPageRes })));
}
