import { useEffect } from 'react';
import { useLoadCompany, useProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { Environment, getEnv, isOneOfEnvs } from 'shared/utils';
export function useBootIntercom() {
    var profile = useProfile();
    var companyV2 = useLoadCompany().company;
    useEffect(function () {
        if (companyV2 != null &&
            profile != null &&
            window.Intercom != null &&
            isOneOfEnvs(Environment.PRODUCTION)) {
            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: __INTERCOM_APP_ID__,
                name: profile.name.full,
                company: {
                    id: companyV2 === null || companyV2 === void 0 ? void 0 : companyV2.id,
                    name: "".concat(companyV2.name).concat(companyV2.name.toLowerCase() === apiService.tenant ? '' : "(".concat(apiService.tenant, ")")),
                },
                user_id: profile.id,
                email: profile.email,
                environment: getEnv(),
                user_hash: profile.intercomHash,
            });
        }
    }, [profile, companyV2]);
}
