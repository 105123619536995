export var BATCH_ACTIONS;
(function (BATCH_ACTIONS) {
    BATCH_ACTIONS["PAUSE"] = "pause";
    BATCH_ACTIONS["UNPAUSE"] = "unpause";
    BATCH_ACTIONS["ASSIGN"] = "assign";
    BATCH_ACTIONS["ASSIGN_TEMPLATE"] = "assign_template";
    BATCH_ACTIONS["ASSIGN_CGV"] = "assign_cgv";
    BATCH_ACTIONS["DELETE"] = "delete";
    BATCH_ACTIONS["TASK"] = "task";
    BATCH_ACTIONS["DISPUTE"] = "dispute";
})(BATCH_ACTIONS || (BATCH_ACTIONS = {}));
