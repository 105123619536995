import { removeAttributes } from 'shared/utils/view';
import { baseReducerListPage, handlePageRequest, handlePageResponse } from 'store/reducers';
import { sessionConstants as session } from 'store/session/session.actions';
import { settingsConstants } from './settings.actions';
var initialState = {
    templateList: baseReducerListPage,
    recoveryPlanList: baseReducerListPage,
    customVariableList: baseReducerListPage,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case settingsConstants.templateListPage.request:
            return handlePageRequest(state, action, 'templateList');
        case settingsConstants.templateListPage.result:
            return handlePageResponse(state, action, 'templateList', removeAttributes);
        case settingsConstants.recoveryPlanListPage.request:
            return handlePageRequest(state, action, 'recoveryPlanList');
        case settingsConstants.recoveryPlanListPage.result:
            return handlePageResponse(state, action, 'recoveryPlanList', removeAttributes);
        case settingsConstants.customVariableListPage.request:
            return handlePageRequest(state, action, 'customVariableListPage');
        case settingsConstants.customVariableListPage.result:
            return handlePageResponse(state, action, 'customVariableList', removeAttributes);
        case session.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
