import { buildActions, makeReqCons } from 'store/actions';
var prefix = 'transaction';
export var transactionConstants = {
    getReconciliation: makeReqCons("".concat(prefix, "_GET_RECONCILIATION")),
    selectReconciliation: "".concat(prefix.toUpperCase(), "_SELECT_RECONCILIATION"),
    manualReconciliation: makeReqCons("".concat(prefix, "_MANUAL_RECONCILIATION")),
    reconciliate: makeReqCons("".concat(prefix, "_RECONCILIATE")),
    ignorePayments: makeReqCons("".concat(prefix, "_DELETE_PAYMENT")),
    unignorePayments: makeReqCons("".concat(prefix, "_UNIGNORE_PAYMENT")),
    getReconciliationPayments: makeReqCons("".concat(prefix, "_PAYMENT")),
    paymentHistory: makeReqCons("".concat(prefix, "_PAYMENT_HISTORY")),
    paymentHistoryPage: makeReqCons("".concat(prefix, "_PAYMENT_HISTORY_PAGE")),
    unmatchManualPayment: makeReqCons("".concat(prefix, "_UNMATCH_MANUAL_PAYMENT")),
    unmatchExternalPayment: makeReqCons("".concat(prefix, "_UNMATCH_EXTERNAL_PAYMENT")),
    resetReconciliationSelection: makeReqCons("".concat(prefix, "_RESET_RECONCILIATION_SELECTION")),
};
var transactionActions = buildActions('', transactionConstants, {});
export { transactionActions };
