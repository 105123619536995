import { __assign } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { omit } from 'lodash-es';
import { useSelector } from 'react-redux';
import { CustomPreferencesFieldsName, PreferencesForm } from 'shared/forms/PreferencesForm';
import { CGV, SalePreferencesFieldsName, } from 'shared/forms/PreferencesForm/PreferencesForm.constants';
import { PageTitle } from 'shared/layout/PageTitle';
import { treatCompany } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';
import { SettingsCard } from '../SettingsCard';
export var PreferenceSettings = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var defaultValues = __assign({}, company);
    var handleFilesUpdate = function (files, res) {
        var terms_and_conditions_attributes = treatCompany(res.data).terms_and_conditions_attributes;
        files.forEach(function (file) {
            accountActions.uploadTerms(__assign(__assign({}, file), terms_and_conditions_attributes.find(function (e) { return e.id === file.id || e.name === file.name; })));
        });
    };
    var handleUpdate = function (values) {
        var _a;
        var updateCompany = __assign(__assign(__assign({}, defaultValues), omit(values, [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT])), (_a = {}, _a[CGV] = values[CGV].map(function (e) { return omit(e, SalePreferencesFieldsName.PDF_BLOB); }), _a));
        accountActions.updateCompany(__assign(__assign({}, updateCompany), { callback: function (res) {
                return handleFilesUpdate(values[CGV].filter(function (e) { return e[SalePreferencesFieldsName.PDF_BLOB]; }), res);
            } }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.PREFERENCES.TITLE)),
        React.createElement(SettingsCard, null,
            React.createElement(PreferencesForm, { onSubmit: handleUpdate, company: company }))));
};
