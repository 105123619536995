import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, ButtonColor, CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './ReasonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ReasonForm = function (_a) {
    var reason = _a.reason, onSubmit = _a.onSubmit, onCancel = _a.onCancel, selectedType = _a.selectedType;
    var t = useTranslation().t;
    var reason_types = useSelector(function (state) { return state.app.constants; }).reason_types;
    var defaultValues = {
        name: reason === null || reason === void 0 ? void 0 : reason.attributes.name,
        reason_type: selectedType || (reason === null || reason === void 0 ? void 0 : reason.attributes.reason_type),
    };
    var form = useForm({
        shouldUnregister: true,
        defaultValues: defaultValues,
    });
    var register = form.register, errors = form.formState.errors, handleSubmit = form.handleSubmit;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('reason-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement(Input, { register: register('name', {
                        required: true,
                    }), errorMessage: get(errors, 'name'), noMargin: true, withBorder: true, label: t(i18nKeys.NAME) })),
            React.createElement("div", { className: styles('content') },
                React.createElement(Controller, { defaultValue: "", name: "reason_type", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: reason_types, withBorder: true, name: "reason_type", label: t(i18nKeys.FORM.TYPE), notAllowed: !!selectedType })); } })),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.CANCEL), onClick: onCancel }),
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE) })))));
};
