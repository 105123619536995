import { useEffect } from 'react';
import dayjs from 'dayjs';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { translateAction } from 'store/translate/translate.actions';
import * as Sentry from '@sentry/react';
import { useLoadProfile } from './use-load-profile';
// @ts-ignore Escape the typing because it is an invariant; The only place it can be breached
// is in the PrivateLoader which actually enforces it by leaving the app on the loading screen as long as
// profile !== null is not satisfied
export var profileAtom = atom(null);
profileAtom.debugLabel = 'Profile';
export var useProfile = function () { return useAtomValue(profileAtom); };
export function useEnforceProfile() {
    var setProfile = useSetAtom(profileAtom);
    var _a = useLoadProfile({}), profile = _a.profile, profileError = _a.profileError;
    useEffect(function () {
        if (profile) {
            setProfile(profile);
            translateAction.changeLang(profile.locale);
            moment.locale(profile.locale);
            dayjs.locale(profile.locale);
            Sentry.setTag('email', profile.email);
            Sentry.setUser({
                id: String(profile.id),
                email: profile.email,
                username: profile.name.full,
                roles: profile.roles,
                isAdmin: profile.isAdmin,
            });
        }
    }, [profile, setProfile]);
    return { profile: profile, profileError: profileError };
}
